import OutlinedIcon from "../icon/OutlinedIcon";
import IconLinkButton from "./IconLinkButton";
import React, {useState} from "react";
import {TextField} from "@mui/material";
import styles from "./SearchButton.module.scss"

export function SearchButton(props: {   onChange: (searchTerm: string) => void,
                                        defaultValue: string    }) {

    const [searchTerm, setSearchTerm] = useState<string>(props.defaultValue);
    const [showSearch, setShowSearch] = useState<boolean>(props.defaultValue.length > 0);

    if(showSearch) {
        return <TextField autoFocus
                          className={styles.TextField}
                          size={"small"}
                          value={searchTerm}
                          placeholder={"Search Term"}
                          onChange={(event) => {
                              setSearchTerm(event.target.value)
                              props.onChange(event.target.value.trim());
                          }}
                          onBlur={() => { setShowSearch(searchTerm.trim().length > 0)}}/>
    } else {
        return <IconLinkButton tooltip={"Search"} key="searchViewButton" id="searchViewButton"
                               data-action="searchView" variant={"contained"}
                               onClick={() => {
                                   setShowSearch(!showSearch)
                               }}>
            <OutlinedIcon className={styles.Icon}>search</OutlinedIcon>
        </IconLinkButton>
    }
}