import {LoremIpsum} from "lorem-ipsum";

const __lorem = new LoremIpsum({
    sentencesPerParagraph: {
        max: 5,
        min: 3
    },
    wordsPerSentence: {
        max: 5,
        min: 4
    }
});


export const lorem = {
    sentencesWithHeader: (count: number) => {
        return "<h3>" + __lorem.generateSentences(1) + "</h3><p>" + __lorem.generateSentences(count) + "</p>";
    },

    paragraphs: (count: number) => {
        return __lorem.generateParagraphs(count);
    },

    sentences: (count: number) => {
        return  __lorem.generateSentences(count);
    },

    words: (count: number) => {
        return __lorem.generateWords(count);
    },
} ;