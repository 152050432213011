import React, {useEffect, useState} from "react";
import {Box, Tab, Tabs} from "@mui/material";
import commonStyle from "../../config/Common.module.scss";
import {StyleUtil} from "../../cms/ui/setting/StyleUtil";
import {useParams} from "react-router-dom";
import {useHistory} from "react-router";
import DAM from "../dam/DAM";
import PDAM from "../dam/PDAM";
import {useOrganization} from "../../hook/useOrganization";
import PageSummary from "../pagesummary/PageSummary";

export default function Assets() {
    const { type } = useParams<any>();
    const history = useHistory();
    const orgCmd = useOrganization();

    if(!type || (type !== "dam" && type !== "pdam")) {
        history.replace("/assets/pdam");
    }

    const [tab, setTab] = useState(type ? type : "");

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        if(newValue === 1) {
            history.replace("/assets/dam");
            setTab("dam");
        } else {
            history.replace("/assets/pdam");
            setTab("pdam");
        }
    }

    useEffect(() => {
        if(tab === "") {
            setTab("pdam");
            history.replace("/assets/pdam");
        }
    },[orgCmd,history,tab]);

    return <>
        <div className={StyleUtil.combineClasses(commonStyle.ContentContainer)}>
            <PageSummary type={"dam"} title={"Digital Assets"} desc={""}/>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', margin: "auto", width: "var(--bt-cms-dam-panel-width)"}}>
                <Tabs centered={true}
                      value={tab === "dam" ? 1 : 0}
                      onChange={handleChangeTab}
                     >
                    <Tab label="Website"/>
                    <Tab label="DAM"/>
                </Tabs>
            </Box>
            { tab === "dam" &&
                <DAM/>
            }
            {tab === "pdam" &&
                <PDAM/>
            }
        </div>
    </>
}