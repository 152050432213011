// import CMSEditor, {EditorRequirement} from "../../cms/ui/shared/editable/CMSEditor";
import {useParams} from "react-router-dom";
// import {SectionInterface} from "../../cms/model/SectionInterface";
// import HttpClient from "../../datafetcher/HttpClient";
import {useEffect, useState} from "react";
import {useOrganization} from "../../hook/useOrganization";
import {removeCMSTheme} from "../../cms/ui/shared/cmstheme/CMSTheme";
import {ResetEditablePanel} from "../../cms/ui/shared/editable/EditablePanel";
// import {useHistory} from "react-router";
import CMSEditorWithFrame from "../../cms/ui/shared/editable/CMSEditorWithFrame";
import {jcr} from "../../datafetcher/JCRDataFetcher";
// import CMSEditor, {EditorRequirement} from "../../cms/ui/shared/editable/CMSEditor";

export default function View() {
    // const history = useHistory();
    const params = useParams<{vname: string, vid: string}>();
    // const [viewData, setViewData] = useState<any>([]);
    const orgCmdProp = useOrganization();
    const [hasData, setHasData] = useState<boolean>(false);
    const [views, setViews] = useState<any[]>([]);

    // const loadData = async (): Promise<any> => {
    //     try {
    //         let response = await HttpClient.get("/api/v0/jcr/org/" + orgCmdProp!.currentOrg!.orgCode + "/view/" + params.vid)
    //         let data = response.data.response;
    //         setViewData(data);
    //         if (data.viewData === null) return [];
    //         else return JSON.parse(data.viewData);
    //     } catch (e) {
    //         history.replace('/pages');
    //     }
    // }
    //
    // const saveData = async (data : SectionInterface[]) => {
    //     let viewContent = JSON.stringify(data);
    //     viewData.viewData = viewContent;
    //     if(orgCmdProp === undefined || orgCmdProp?.orgCmds === null) return;
    //     await HttpClient.post("/api/v0/jcr/org/" + orgCmdProp!.currentOrg!.orgCode + "/full/views",viewData).then((x) => {
    //         // console.log(x);
    //     })
    //     setViewData({...viewData});
    // }


    useEffect(() => {
        if (orgCmdProp === undefined || orgCmdProp?.orgCmds === null || orgCmdProp?.orgCmds.length === 0) return;
        if (hasData) {
            //history.replace("/views");
        } else {
            jcr.view.lite().then((response) => {
                setViews(response.data.response);
                setHasData(true);
            })

        }
    },[orgCmdProp,hasData]);

    useEffect(() => {
        return () => {
            removeCMSTheme();
            ResetEditablePanel();
        }
    },[])

    return <>{hasData &&  <CMSEditorWithFrame
                                    type={"Pages"}
                                    viewType={"view"}
                                     id={params.vid}
                                     name={params.vname}
                                     path={"/pages"}
                                    items={views}
                                    nameField={"viewName"}
    />}</>

    // return <>{hasData &&  <CMSEditor type={"Pages"}
    //                      id={params.vid}
    //                      requirement={EditorRequirement.storefront}
    //                      name={params.vname}
    //                      loadData={loadData}
    //                      saveData={saveData}
    //                      path={"/pages"}
    // />}</>
}