import {QueryType} from "./QueryType";


export enum DrillChangeFrequency {
    always, hourly , daily, weekly,monthly, never, none
}

export enum DrillSortBy {
    alphabet, none
}

export default interface DrillItemCmd {
    indexId: string,
    name: string
    description: string,
    active: boolean,

    // seo
    seoIndex: boolean,
    seoPageRank: number,
    seoChangeFrequency: DrillChangeFrequency,
    seoTitle?: string
    seoDescription?: string
    seoKeywords?: string

    searchType: QueryType,
    searchString: string,
    searchProfile: string, // keep search profile name

    featureType: QueryType,
    featureString: string,

    subItems: DrillItemCmd[],
    bannerImage?: string,
    categoryImage?: string,
    displayLevel: number,
    displayColumns: number,
    sortBy: DrillSortBy,
}