// import CMSEditor, {EditorRequirement} from "../../cms/ui/shared/editable/CMSEditor";
import {useParams} from "react-router-dom";
// import {SectionInterface} from "../../cms/model/SectionInterface";
// import HttpClient from "../../datafetcher/HttpClient";
import {useEffect, useState} from "react";
import {useOrganization} from "../../hook/useOrganization";
import {removeCMSTheme} from "../../cms/ui/shared/cmstheme/CMSTheme";
import {ResetEditablePanel} from "../../cms/ui/shared/editable/EditablePanel";
// import {useHistory} from "react-router";
import CMSEditorWithFrame from "../../cms/ui/shared/editable/CMSEditorWithFrame";
import {jcr} from "../../datafetcher/JCRDataFetcher";

export default function Footer() {
    const params = useParams<{ fname: string, fid: string }>();
    // const [footerData, setFooterData] = useState<any>([]);
    const orgCmdProp = useOrganization();
    // const history = useHistory();
    const [hasData, setHasData] = useState<boolean>(false);
    const [footers, setFooters] = useState<any[]>([]);
    // const loadData = async (): Promise<any> => {
    //     try {
    //         let response = await HttpClient.get("/api/v0/jcr/org/" + orgCmdProp?.currentOrg!.orgCode + "/footer/" + params.fid);
    //         let data = response.data.response;
    //         setFooterData(data);
    //         if (data.footerData === null) return [];
    //         else return JSON.parse(data.footerData);
    //     } catch (e) {
    //         history.replace('/footers');
    //     }
    // }

    // const saveData = async (data: SectionInterface[]) => {
    //     let footerContent = JSON.stringify(data);
    //     footerData.footerData = footerContent;
    //
    //     await HttpClient.post("/api/v0/jcr/org/" + orgCmdProp?.currentOrg!.orgCode + "/full/footers", footerData).then((x) => {
    //         console.log(x);
    //     })
    //     setFooterData({...footerData});
    // }

    useEffect(() => {
        if(orgCmdProp === undefined || orgCmdProp?.orgCmds === null || orgCmdProp?.orgCmds.length === 0) return;
        if(hasData) {
            //history.replace("/footers");
        } else {
            jcr.footer.lite().then((response) => {
                setFooters(response.data.response);
                setHasData(true);
            })

        }
    },[orgCmdProp,hasData]);

    useEffect(() => {
        return () => {

            removeCMSTheme();
            ResetEditablePanel();
        }
    },[])

    return <>{hasData &&  <CMSEditorWithFrame
        type={"Footers"}
        viewType={"footer"}
        id={params.fid}
        name={params.fname}
        path={"/footers"}
        items={footers}
        nameField={"footerName"}
    />}</>

    // return <>{hasData &&
    // <CMSEditor type={"Footers"}
    //            id={params.fid}
    //            requirement={EditorRequirement.theme}
    //            name={params.fname}
    //            loadData={loadData}
    //            saveData={saveData}
    //            path={"/footers"}
    // />}</>
}