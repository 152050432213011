import {IconButton, Popover, TextField} from "@mui/material";
import {useRef, useState} from "react";
import {TextFieldProps} from "@mui/material/TextField/TextField";
import OutlinedIcon from "../icon/OutlinedIcon";
import {HexToRGBAColor, isDarkColor, NamedColorToHex, RGBAColorToHex} from "./ColorUtil";
import {ChromePicker} from "react-color";

export default function ColorPickerField(props: (TextFieldProps & {onChangeColor: (hex: string) => void })) {
    const [value, setValue] = useState<string>(props.value ? props.value as string : (props.defaultValue ? props.defaultValue as string : ""));
    const [anchorEl, setAnchorEl] = useState<HTMLInputElement|null>(null)
    const textRef = useRef<any>();


    let newProps: Partial<any> = {...props};
    delete newProps['onChangeColor'];
    delete newProps['defaultValue'];

    return <>
        <TextField
                ref={textRef}
                size={"small"}
                fullWidth={true}
                style={{
                    height: "33px",
                }}
                inputProps={{
                    sx: {
                        backgroundColor: value.length <= 3 ? "unset" : "#" + RGBAColorToHex(HexToRGBAColor(value.startsWith("#") ? value : NamedColorToHex(value))),
                        fontSize: '0.8rem',
                        boxSizing: "border-box",
                        textAlign: "center",
                        "margin": "0px",
                        "padding": "0px",
                        "marginInlineStart": "2px",
                        // marginInlineStart:  value === "" ? "unset" : "5px",
                        // marginBlockStart: value === "" ? "px": "5px",
                        // marginBlockEnd:  value === "" ? "5px" : "5px",
                        // paddingInline: "3px",
                        // paddingBlock: "0px",
                        marginBlock: "4px",
                        height: "23px",
                        borderRadius: "3px",
                        color: value === "" || value.length <= 3 ? "black": (isDarkColor(HexToRGBAColor(value.startsWith("#") ? value : NamedColorToHex(value))!) ? "white" : "black")}}}
                InputLabelProps={{shrink: true}}
                InputProps={{
                       endAdornment: <IconButton tabIndex={-1} style={{padding: "3px"}} onClick={() => { setAnchorEl(textRef.current)}}><OutlinedIcon>color_lens</OutlinedIcon></IconButton>
                   }}
                {...newProps}
                label={""}
                onChange={(event) => {
                    // if(event.currentTarget.value !== "") {
                        setValue(event.currentTarget.value)
                        props.onChangeColor(event.currentTarget.value);
                    // }
                }}
                value={value}/>
        {anchorEl !== null && <Popover
            onClose={() => {
                    setAnchorEl(null);
                }
            }
            anchorEl={anchorEl}
            open={anchorEl !== null}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}>
            <ChromePicker color={NamedColorToHex(value)} onChangeComplete={(color) => {
                    let obj = HexToRGBAColor(color.hex);
                    if(obj !== undefined) {
                        obj.alpha = color.rgb.a!;
                        let newColor = "#" + RGBAColorToHex(obj);
                        props.onChangeColor(newColor);
                        setValue(newColor);
                    } else {
                        props.onChangeColor("");
                    }
                }
            }/>
        </Popover>}
    </>
}