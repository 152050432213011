import {
    Checkbox,
    Icon,
    IconButton, List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    TextField,
    Typography
} from "@mui/material";

import {InputOption} from "../../model/InputModel";
import {useState} from "react";
import OutlinedIcon from "../icon/OutlinedIcon";
import styles from "./MultiSelectionList.module.scss"
import Tooltip from '@mui/material/Tooltip';

export interface MultiSelectionListProps {
    //object: InputModel
    label: string,
    values: any,
    options: InputOption[],
    index: string
    showOrder: boolean
    onClickListItem: (indexId: string, data: string) => () => void
}

export default function MultiSelectionList(props: MultiSelectionListProps) {
    const {label,options,values,index,onClickListItem} = props;
    const [showSearch, setShowSearch] = useState<boolean>(false);
    const [searchString,setSearchString] = useState<string>("");
    return <List sx={{  width: '100%',
                        border: label && label !== "" ? "solid 1px lightgray" : 'solid 0px lightgray',
                        borderRadius: label && label !== "" ? "5px" : "0px",
                        marginTop: label && label !== "" ?  2 : 0,
                        paddingTop: label && label !== "" ?  2 : 0,
                        minHeight: "50px"
                    }}>
        <div  className={styles.SearchPanel}>
            {showSearch === false && <Tooltip title="Search" placement="top"><IconButton onClick={() => setShowSearch(true)}><Icon>search</Icon></IconButton></Tooltip>}
            {showSearch && <TextField size={"small"}
                                        label={"Search"}
                                        fullWidth={true}
                                        autoFocus={true}
                                        value={searchString}
                                        onBlur={() => setShowSearch(false)}
                                        onChange={(e) => setSearchString(e.currentTarget.value)}
                                        InputProps={{
                                            endAdornment: <IconButton tabIndex={-1} onClick={() => { setShowSearch(false); setSearchString(""); } }><OutlinedIcon>close</OutlinedIcon></IconButton>
                                        }}/> }
        </div>
        { label && label !== "" && <Typography paddingX={2} className={styles.ListTitle}>{label}</Typography> }
        {options.filter((option) => {
            if(searchString === "") return true;
            let re = new RegExp(searchString);
            return option.label.match(re) || option.value.match(re);
        }).map((option) => {
            let valueIndex = option.valueindex;
            return (
                <ListItem
                    key={option.value}
                    secondaryAction={
                        <IconButton edge="end" aria-label="comments">

                        </IconButton>
                    }
                    disablePadding
                >
                    <ListItemButton role={undefined} onClick={onClickListItem(index,option.value)} dense>
                        <ListItemIcon classes={{
                            root: styles.ListIcon
                        }}>
                            <Checkbox
                                edge="start"
                                checked={values.indexOf(option.value) >= 0}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': option.value }}
                            />
                        </ListItemIcon>
                        <ListItemText id={ option.value} primary={option.label} className={styles.ListLabel}/>
                        {props.showOrder && valueIndex >= 0 && <Typography>{valueIndex+1}</Typography>}
                    </ListItemButton>
                </ListItem>
            );
        })}
    </List>
}
