import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Icon,
    Popover,
    Tooltip
} from "@mui/material";
import styles from './NewWidget.module.scss';
import {NewOption, NewType} from "../../../model/NewOption";
import {useAppSelector} from "../../../../redux/hook";
import {selectViewState} from "../../../../redux/features/view/viewSlice";
import {DeleteOption} from "../../../model/DeleteOption";
import {SettingGroup, SettingOption} from "../../../model/SettingOption";
import {EditableProps} from "../editable/EditableProps";
import {SettingChangeFunction} from "../../setting/SettingChange";
import OutlinedIcon from "../../../../ui/icon/OutlinedIcon";
import {useState} from "react";
import ColumnIcon from "../../icon/columnicon/ColunmIcon";
import {sectionDescriptionsDataFetcher} from "../../../../datafetcher/SectionAndWidgetDataFetcher";
import {VerticalSeparator} from "../verticalseparator/VerticalSeparator";
import {GetTopCopiedObject} from "../../../../ui/editor/LiveEditor";
import WidgetIcon from "../../icon/widgeticon/WidgetIcon";
import {SectionDesc} from "../../../model/desc/SectionDesc";
import {SectionGroupType} from "../../../model/desc/SectionGroupType";


export default function NewWidget(props: EditableProps & {
                                            deleteOption: DeleteOption,
                                            settingGroups: SettingGroup[],
                                            settingOptions: SettingOption[],
                                            onOpenTemplate?: (event:React.MouseEvent<HTMLSpanElement>) => void,
                                            onChangeSettings: SettingChangeFunction|null} &
                                        {
                                            newOption: NewOption
                                            onHandleAddNewSectionOrWidget: (levelStr: string, setting: NewOption) => void
                                        }){
    const [anchorEl, setAnchorEl] = useState<HTMLSpanElement | null>(null);
    const viewState = useAppSelector(selectViewState);

    const onHandleClick = (event: React.MouseEvent) => {
        props.onHandleAddNewSectionOrWidget(props.uiLevelStr,props.newOption);
        event.stopPropagation();
    }

    const handleOnClose = () => {
        setAnchorEl(null);
    }

    const onHandleDragStart = (event: any) => {
       event.stopPropagation();
       event.preventDefault();

    };

    const handleOnClickAdd = (event: React.MouseEvent<HTMLSpanElement>) => {
        setAnchorEl(event.currentTarget);
        event.currentTarget.focus();
        event.stopPropagation();
        event.preventDefault();
    };

    const handleOnClickLibrary = (event: React.MouseEvent<HTMLSpanElement>) => {
        setOpenDialog(true)
        event.stopPropagation();
        event.preventDefault()
    }

    const handleChooseClipboard = () => {
        let clonedObject =  GetTopCopiedObject();
        if(props.onInsertSection && clonedObject) {

            let sectionDesc:SectionDesc = {
                sectionType: "",
                sectionName: "",
                sectionNickName: "",
                sectionDescription: "",
                sectionIcon: "",
                sectionGroupType: SectionGroupType.unspecfic,
                clonedObject: clonedObject
            }
            props.onInsertSection(props.uiLevelStr,sectionDesc);
        }
    }

    const [openDialog,setOpenDialog] = useState<boolean>(false);
    const handleCloseDialog = () => {
        setOpenDialog(false);
    }
    const handleChooseSection = (columns: number, columnWidths: string[]) => {
        if(props.onInsertSection) {
            let sectionDataFetcher = sectionDescriptionsDataFetcher();
            // console.log(sectionDataFetcher)
            let sectionDesc = sectionDataFetcher.find((item) => (item.sectionName === "DefaultSection"));
            if(sectionDesc) {
                let widthArray:any = {};
                columnWidths.forEach((width,index) => {
                    widthArray[`column${index+1}-width`] = columnWidths[index];
                })
                props.onInsertSection(props.uiLevelStr, { ...sectionDesc ,
                                sectionInitProps: {
                                    content: {
                                        'layout-break': 700,
                                        columns: columns,
                                        layout: "horizontal",
                                        'justify-content': 'center',
                                        'align-items': 'start',
                                        ...widthArray
                                    }
                                }});
            }
        }
    }

    if(viewState.isEdit) {
        const open = Boolean(anchorEl);
        const copiedTopObject = GetTopCopiedObject();
        return <div className={styles.Space}>
            <div className={styles.Root} onMouseDown={onHandleDragStart}>
                <div className={styles.Title}>Add a section or pick from library</div>
                <div>
                    <Tooltip title={"Add section"}>
                        <span tabIndex={-1}
                               className={styles.Icon}
                               style={anchorEl !== null ? { pointerEvents: 'none' } : {}}
                               onClick={handleOnClickAdd}
                        >
                            <OutlinedIcon>add</OutlinedIcon>
                        </span>
                    </Tooltip>
                    <VerticalSeparator/>
                    <Tooltip title={"Pick From Library"}>
                        <span tabIndex={-1}
                              className={styles.Icon}
                              style={anchorEl !== null ? { pointerEvents: 'none' } : {}}
                              onClick={props.onOpenTemplate}
                    >
                            <OutlinedIcon>cloud_upload</OutlinedIcon>
                        </span>
                    </Tooltip>
                </div>
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleOnClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}
                    className={styles.Popover}
                    // style={{pointerEvents: "none"}}
                >
                    <span className={styles.CardTitle}>Predefined Sections</span>
                    <Grid container spacing={1} className={styles.Card}>
                        <Grid item xs={3}><ColumnIcon onClick={handleChooseSection} columns={1} columnWidths={["100%"]} tooltip={"1 column"} label={"100"} /></Grid>
                        <Grid item xs={3}><ColumnIcon onClick={handleChooseSection} columns={2} columnWidths={["50%","50%"]} tooltip={"2 columns"} label={"2*50"} /></Grid>
                        <Grid item xs={3}><ColumnIcon onClick={handleChooseSection} columns={3} columnWidths={["33%","33%","33%"]}  tooltip={"3 columns"} label={"3*33"} /></Grid>
                        <Grid item xs={3}><ColumnIcon onClick={handleChooseSection} columns={4} columnWidths={["25%","25%","25%","25%"]} tooltip={"4 columns"} label={"4*25"} /></Grid>
                        <Grid item xs={3}><ColumnIcon onClick={handleChooseSection} columns={5} columnWidths={["20%","20%","20%","20%","20%"]} tooltip={"5 columns"} label={"5*20"} /></Grid>
                        <Grid item xs={3}><ColumnIcon onClick={handleChooseSection} columns={2} columnWidths={["25%","75%"]} tooltip={"2 columns"} label={"25+75"} /></Grid>
                        <Grid item xs={3}><ColumnIcon onClick={handleChooseSection} columns={2} columnWidths={["75%","25%"]} tooltip={"2 columns"} label={"75+25"} /></Grid>
                        {copiedTopObject && <Grid item xs={3}>
                            <WidgetIcon tooltip={"Clipboard"} label={"Clipboard"} icon={"content_paste"}
                                        action={"Cliboard"} onClick={handleChooseClipboard}/>
                        </Grid>}
                    </Grid>
                </Popover>
                {/*{props.newOption.type === NewType.section_only &&*/}
                {/*<Button draggable="false" className={styles.ContainerSection} onClick={onHandleAddNew}>Add a new section</Button>}*/}
                {/*{props.newOption.type !== NewType.section_only &&*/}
                {/*<Button draggable="false" className={styles.Container} onClick={onHandleClick}><Icon*/}
                {/*    className={styles.Icon}>add</Icon></Button>}*/}
            </div></div>
    } else {
        return <div className={styles.Root}>
            {props.newOption.type === NewType.section_only &&
            <Button className={styles.ContainerSection} onClick={onHandleClick}><Icon className={styles.Icon}>add</Icon></Button>}
            {props.newOption.type !== NewType.section_only &&
            <Button className={styles.Container} onClick={onHandleClick}><Icon
                className={styles.Icon}>add</Icon></Button>}
        </div>;
    }
}