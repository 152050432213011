import {EditableProps} from "../../shared/editable/EditableProps";
import {SectionInterface} from "../../../model/SectionInterface";
import {useAppSelector} from "../../../../redux/hook";
import {selectViewState} from "../../../../redux/features/view/viewSlice";
import {doViewGenerator} from "../../../util/UIFactory";
import styles from './Horizontal.module.scss';
import {NewType} from "../../../model/NewOption";
import {DeleteType} from "../../../model/DeleteOption";
import EditablePanel from "../../shared/editable/EditablePanel";
import {SettingGroup, SettingGroupType, SettingOption, SettingType} from "../../../model/SettingOption";
import {SettingChangeFunction} from "../../setting/SettingChange";
import {StyleUtil} from "../../setting/StyleUtil";
import React, {useEffect, useState} from "react";
import EmptyLabel from "../../shared/emptylabel/EmptyLabel";
import {nanoid} from "nanoid";
import useWidgetState from "../../../../hook/useWidgetState";


export default function Horizontal(props: EditableProps) {
    const sectionData:SectionInterface = props.data as SectionInterface;
    const {sectionId,sectionProps} = sectionData;
    const viewState = useAppSelector(selectViewState);
    const [refreshId,setRefreshId] = useState(nanoid());

    useWidgetState(sectionId,(event) => {
        setRefreshId(nanoid());
    })

    const [views , setViews] = useState<JSX.Element[]|null>(null);

    useEffect(() => {
        setViews(doViewGenerator(sectionData.sectionWidgets,
            props.uiLevel+1,
            props.uiLevelStr,
            props.sharedObjects,
            props.onHandleSetting,
            props.onHandleDeletion,
            props.onHandleAddNewSectionOrWidget,
            props.onHandleSwap,
            props.onHandleUpdate,
            props.onHandleClone,
            props.onHandleCopy,
            props.onHandleCut,
            props.onHandleCreateTemplate,
            props.updateWidgetData,
        ));
    },[refreshId])

    if(!sectionProps['content']) {
        sectionProps['content'] = {
            equalSpace: true,
            gap: "0px",
            "justify-content": "center",
        }
    }

    if(!sectionProps['bt_horizontal_panel']) {
        sectionProps['bt_horizontal_panel'] = {
            style: {
                default: true,
                padding: {

                }
            }
        }
    }

    const themeName = "horizontal";
    const sectionStyles = {
        theme: StyleUtil.toThemeNameByType(sectionProps, themeName),
        current: StyleUtil.toClassFromWidget(sectionId, sectionProps, "current_style"),
        panel: StyleUtil.toClassFromWidget(sectionId , sectionProps, "bt_horizontal_panel"),
        flow_panel: StyleUtil.toClassFromMap(sectionId, "flow_panel",{
            "align-items": sectionProps['content']['align-items'],
            "justify-content": sectionProps['content']['justify-content'],
            "gap" : sectionProps['content']['gap']
        })
    }



    if(views === null) return <></>

    const createHorizontal = () => <section key={refreshId}
                                            data-ui-type={props.uiType}
                                            className={sectionProps['content']['equalSpace'] ? StyleUtil.combineClasses(styles.Root,sectionStyles.theme,sectionStyles.current,sectionStyles.panel,sectionStyles.flow_panel) :
                                                StyleUtil.combineClasses(styles.RootNormal,sectionStyles.theme,sectionStyles.current,sectionStyles.panel,sectionStyles.flow_panel)}
        // style= { {...StyleUtil.toStyleFromWidget(sectionProps,'panel'),
        //         "gap" : sectionProps['content']['gap'],
        //         "justifyContent": sectionProps['content']['justify-content'],
        //         "alignItems": sectionProps['content']['align-items']
        // }}
    >
        {views.map((view,index) => <div key={`horizontal_${index}`}>{view}</div>)}
    </section>

    if(viewState.isEdit) {
        const onChangeSettings: SettingChangeFunction|null = null;
        const suggestTags: string[] = [
            "current",
        ]
        const settingOptions:SettingOption[] = [
            { title: "General ", type: SettingType.widget,  editor: "horizontalsetting", group: "content", groupType: SettingGroupType.layout},
            { type: SettingType.background, group: "bt_horizontal_panel" , groupType: SettingGroupType.style},
            { type: SettingType.margin,  group: "bt_horizontal_panel", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "bt_horizontal_panel", groupType: SettingGroupType.advanced},
        ];
        const settingGroups:SettingGroup[] = [
            {group: "content", groupName: "General"},
            {group: "bt_horizontal_panel", groupName: "Panel"}
        ]

        return (
            <EditablePanel {...props}
                           themeName={themeName}
                           newUILevelStr={props.uiLevelStr + "_" + (views.length)}
                           newOption={{ type: NewType.widget_or_section, tag: null}}
                           onHandleAddNewSectionOrWidget={props.onHandleAddNewSectionOrWidget}
                           suggestTags={suggestTags}
                           settingOptions={settingOptions}
                           settingGroups={settingGroups}
                           onChangeSettings={onChangeSettings}
                           deleteOption={{type: DeleteType.delete_with_rearrange, tag: null}}
                           >
                {createHorizontal()}
                {views && views.length === 0 && <EmptyLabel label={"Vertical Panel"}/> }
            </EditablePanel>
        )
    } else {
        return createHorizontal();
    }
}