import SettingProps from "../SettingProps";
import GenericSetting, {StyleSettingType} from "../genericsetting/GenericSetting";

export default function ObjectSetting(props: SettingProps) {
    // return <CSSSetting {...props}
    //                    title={"Object"}
    //                    type={"object"}
    //                    targets={["object-position","object-fit"]}/>
    return <GenericSetting {...props}
                           title={"Object"}
                           type={"object"}
                           targets={[
                               { name: "Position",target: 'object-position' , type: StyleSettingType.text},
                               { name: "Fit", target: 'object-fit' , type: StyleSettingType.selections,
                                   options: [
                                       {label: "fill" , value: "fill"},
                                       {label: "contain" , value: "contain"},
                                       {label: "cover" , value: "cover"},
                                       {label: "none" , value: "none"},
                                       {label: "scale-down" , value: "scale-down"},
                                       {label: "unset" , value: "unset"},
                                       {label: "inherit" , value: "inherit"},
                                   ]}
                           ]}
    />
}