// import CMSEditor, {EditorRequirement} from "../../cms/ui/shared/editable/CMSEditor";
import {useParams} from "react-router-dom";
// import {SectionInterface} from "../../cms/model/SectionInterface";
// import HttpClient from "../../datafetcher/HttpClient";
import {useEffect, useState} from "react";
import {useOrganization} from "../../hook/useOrganization";
import {removeCMSTheme} from "../../cms/ui/shared/cmstheme/CMSTheme";
import {ResetEditablePanel} from "../../cms/ui/shared/editable/EditablePanel";
// import {useHistory} from "react-router";
import CMSEditorWithFrame from "../../cms/ui/shared/editable/CMSEditorWithFrame";
import {jcr} from "../../datafetcher/JCRDataFetcher";
// import CMSEditor, {EditorRequirement} from "../../cms/ui/shared/editable/CMSEditor";

export default function Header() {
    const params = useParams<{hname: string,hid: string}>();
    // const [headerData, setHeaderData] = useState<any>([]);
    const orgCmdProp = useOrganization();
    // const history = useHistory();

    const [hasData, setHasData] = useState<boolean>(false);
    const [headers, setHeaders] = useState<any[]>([]);
    // const loadData = async (): Promise<any> => {
    //     try {
    //         let response = await HttpClient.get("/api/v0/jcr/org/" + orgCmdProp!.currentOrg!.orgCode + "/header/" + params.hid);
    //         let data = response.data.response;
    //         setHeaderData(data);
    //
    //         if (data === null || data.headerData === null) return [];
    //         else return JSON.parse(data.headerData);
    //     } catch (e) {
    //         history.replace('/headers');
    //     }
    // }

    // const saveData = async (data : SectionInterface[]) => {
    //     let headerContent = JSON.stringify(data);
    //     headerData.headerData = headerContent;
    //     if(orgCmdProp === undefined || orgCmdProp?.orgCmds === null) return;
    //     await HttpClient.post("/api/v0/jcr/org/" + orgCmdProp!.currentOrg!.orgCode + "/full/headers",headerData).then((x) => {
    //         console.log(x);
    //     })
    //     setHeaderData({...headerData});
    // }

    useEffect(() => {
        if(orgCmdProp === undefined || orgCmdProp?.orgCmds === null || orgCmdProp?.orgCmds.length === 0) return;
        if(hasData) {
            //history.replace("/headers");
        } else {
            jcr.header.lite().then((response) => {
                setHeaders(response.data.response);
                setHasData(true);
            })

        }
    },[orgCmdProp,hasData]);

    useEffect(() => {
        return () => {

            removeCMSTheme();
            ResetEditablePanel();
        }
    },[])

    return <>{hasData &&  <CMSEditorWithFrame
        type={"Headers"}
        viewType={"header"}
        id={params.hid}
        name={params.hname}
        path={"/headers"}
        items={headers}
        nameField={"headerName"}
    />}</>
    //
    // return <>{ hasData && <CMSEditor type={"Headers"}
    //                                  id={params.hid}
    //                                  requirement={EditorRequirement.theme}
    //                                  name={params.hname}
    //                                  loadData={loadData}
    //                                  saveData={saveData}
    //                                  path="/headers"
    //     />}</>
}