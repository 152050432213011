import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import {useSSO} from "../../datafetcher/HttpClient";

export default function TinyEditor(props) {
    const editorRef = useRef(null);
    const ssoCmd = useSSO();
    let plugins = []
    let toolbar = [];
    if(ssoCmd.roleName === "LSIADMIN") {
        plugins = [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks  fullscreen',
            'insertdatetime media table paste code help wordcount','autoresize',
        ];
        toolbar = 'undo redo | formatselect | image |fontsizeselect|' +
            'bold italic link forecolor backcolor | alignleft aligncenter | ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat  table | code'
    } else {
        plugins = [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks  fullscreen',
            'insertdatetime media table paste code help wordcount','autoresize'
        ];
        toolbar = 'undo redo | formatselect | image |fontsizeselect|' +
            'bold italic link forecolor backcolor | alignleft aligncenter | ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat  table | code'
    }
    return (
        <>
            <Editor
                {...props}
                init={{
                    menubar: false,
                    plugins: plugins,
                    fontsize_formats:
                        "8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
                    // autoresize_bottom_margin: 0,
                    toolbar: toolbar, //help
                    autoresize_overflow_padding: 5,
                    max_height: 500,
                    ...props.init
                }}
                tinymceScriptSrc={"https://cdnjs.cloudflare.com/ajax/libs/tinymce/5.6.2/tinymce.min.js"}
                onInit={(evt, editor) => editorRef.current = editor}
            />
        </>
    );
}
