import styles from "../menu/PageMenu.module.scss";

import {
    AppBar,
    Breadcrumbs,
    IconButton,
    Toolbar,
    Typography
} from "@mui/material";
import React, {useState} from "react";
import Link from '../button/Link';
import SolidIcon from "../icon/SolidIcon";

export interface PageMenuProps {
    type: string,
    link?: string
    name?: string,
    children?: any
}

export default function PageMenu(props: PageMenuProps) {
    // const ssoCmd = useSSO();
    const [opened] = useState<boolean>(false);
    // const sidebarRef = useRef<HTMLDivElement>(null)
    // const isAdmin = ssoCmd.roleName === "LSIADMIN";
    const handleToggleDrawer = () => {}

    // const handleToggleDrawer = () => {
    //     if(!opened) {
    //         setTimeout(() => {
    //             if(sidebarRef.current !== null) {
    //                 sidebarRef.current!.focus();
    //             }
    //         },10);
    //     }
    //     setOpened(!opened);
    // };

    // const handleClosedDrawer = () => {
    //     setOpened(false);
    // }


    return (
        <React.Fragment>
            <AppBar position="fixed" className={styles.AppBar} >
            <Toolbar className={styles.Toolbar}>
                {opened && <IconButton id="submenuDrawerMenu" data-action={"doToggleSubMenuDrawer"} edge="start" size="large">
                            <SolidIcon>close</SolidIcon>
                        </IconButton>
                }
                {!opened && <IconButton id="submenuDrawerMenu" data-action={"doToggleSubMenuDrawer"}  onClick={handleToggleDrawer} edge="start" size="large">
                        <SolidIcon>menu</SolidIcon>
                    </IconButton>
                }
                <Breadcrumbs className={styles.Breadcrumbs}>
                    {props.name !== "home" && <Link id={`homeBreadcrumb`} data-action={"openHome"} href={"/home"}><Typography className={styles.DisablePanel}>{"CMS"}</Typography></Link>}
                    {props.name && <Link id={`${props.type}Breadcrumb`} data-action={`open${props.type}`} href={"/" + (props.link ? props.link : props.type.toLowerCase())}><Typography  className={styles.DisablePanel}>{props.type}</Typography></Link>}
                    {!props.name && <Typography>{props.type}</Typography>}
                    { props.name && <Typography>{props.name}</Typography> }
                </Breadcrumbs>
                {props.children}
            </Toolbar>
            </AppBar>


            {/*<Box ref={sidebarRef} tabIndex={-1} onBlur={handleClosedDrawer} boxShadow={8} className={styles.Drawer} style={ opened ? {left: "calc(var(--bt-cms-sidebar-move-left) + 0px)"} : {left : "calc(var(--bt-cms-sidebar-move-left) - 300px)"}}>*/}
            {/*    <Box className={styles.DrawerTopicTitle}><Typography variant={"body1"}>Settings</Typography></Box>*/}
            {/*    {ssoCmd.roleName === "LSIADMIN" && <DrawerMenuItem id={"orgSubMenu"} action={"openLink"} icon={"corporate_fare"} title={"Organizations"} href={"/organizations"} doCloseDrawer={handleClosedDrawer}/>}*/}
            {/*    {*/}
            {/*        MainMenuConfig.filter(item => item.inPageMenu && item.roles.indexOf(ssoCmd.roleName) >= 0).map((item,index) =>*/}
            {/*            <DrawerMenuItem id={item.id} action={item.action} key={"pagemenu_" + index} icon={item.icon} title={item.title} href={item.href} doCloseDrawer={handleClosedDrawer}/>*/}
            {/*        )*/}
            {/*    }*/}
            {/*    <Box className={styles.DrawerTopicTitle}><Typography variant={"body1"}>Contents</Typography></Box>*/}
            {/*    <DrawerMenuItem id={"productSubMenu"} action={"openProductDiscover"} icon={"content_paste_search"} title={"Products Discover"} href={"/pim"} doCloseDrawer={handleClosedDrawer}/>*/}
            {/*    <DrawerMenuItem id={"damSubMenu"} action={"openDAM"} icon={"photo_library"} title={"Assets"} href={"/assets"} doCloseDrawer={handleClosedDrawer}/>*/}
            {/*    <DrawerMenuItem id={"drilldownSubMenu"} action={"openDrilldownEditor"} icon={"segment"} title={"Drill Downs"} href={"/drill-downs"} doCloseDrawer={handleClosedDrawer}/>*/}
            {/*    <DrawerMenuItem id={"blockSubMenu"} action={"openBlockEditor"} icon={"view_agenda"} title={"Blocks"} href={"/content/blocks"} doCloseDrawer={handleClosedDrawer}/>*/}
            {/*    <DrawerMenuItem id={"blogSubMenu"} action={"openBlogEditor"} icon={"rss_feed"} title={"Blogs"} href={"/content/blogs"} doCloseDrawer={handleClosedDrawer}/>*/}
            {/*    <DrawerMenuItem id={"faqSubMenu"} action={"openFAQEditor"} icon={"quiz"} title={"FAQs"} href={"/content/faqs"} doCloseDrawer={handleClosedDrawer}/>*/}
            {/*    <DrawerMenuItem id={"gridSubMenu"} action={"openGridEditor"} icon={"grid_view"} title={"Grids"} href={"/content/grids"} doCloseDrawer={handleClosedDrawer}/>*/}
            {/*    <DrawerMenuItem id={"smallgridSubMenu"} action={"openSmallgridEditor"} icon={"apps"} title={"Small Grids"} href={"/content/smallgrids"} doCloseDrawer={handleClosedDrawer}/>*/}
            {/*    <DrawerMenuItem id={"gallerySubMenu"} action={"openGalleryEditor"} icon={"collections"} title={"Galleries"} href={"/content/galleries"} doCloseDrawer={handleClosedDrawer}/>*/}
            {/*    <DrawerMenuItem id={"eventSubMenu"} action={"openEventEditor"} icon={"event"} title={"Events"} href={"/content/events"} doCloseDrawer={handleClosedDrawer}/>*/}
            {/*    <DrawerMenuItem id={"slideshowSubMenu"} action={"openSlideEditor"} icon={"slideshow"} title={"Slideshows"} href={"/content/slideshows"} doCloseDrawer={handleClosedDrawer}/>*/}
            {/*</Box>*/}
        </React.Fragment>
    );
}