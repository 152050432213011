import {DEFAULT_PADDING_INLINE, doViewGenerator} from "../../../util/UIFactory";
import {useAppSelector} from "../../../../redux/hook";
import {selectViewState} from "../../../../redux/features/view/viewSlice";
import {SectionInterface} from "../../../model/SectionInterface";
import EditablePanel from "../../shared/editable/EditablePanel";
import styles from './Vertical.module.scss';
import {EditableProps} from "../../shared/editable/EditableProps";
import {DeleteType} from "../../../model/DeleteOption";
import {NewType} from "../../../model/NewOption";
import {SettingGroup, SettingGroupType, SettingOption, SettingType} from "../../../model/SettingOption";
import {SettingChangeFunction} from "../../setting/SettingChange";
import {StyleUtil} from "../../setting/StyleUtil";
import React, {useEffect, useState} from "react";
import {nanoid} from "nanoid";
import useWidgetState from "../../../../hook/useWidgetState";
import NewLabel from "../../shared/newlabel/NewLabel";
import {Box} from "@mui/material";
import SectionOrWidgetPopover from "../../../../ui/popover/SectionOrWidgetPopover";
import {GetCopiedObject} from "../../../../ui/editor/LiveEditor";


export default function Vertical(props: EditableProps) {
    const [anchorEl, setAnchorEl] = useState<any|null>(null);
    const sectionData:SectionInterface = props.data as SectionInterface;
    const {sectionId,sectionProps} = sectionData;

    const viewState = useAppSelector(selectViewState);
    const [refreshId,setRefreshId] = useState(nanoid());
    const [hasSection] = useState(props.uiLevelStr.split("_").length <= 3);
    useWidgetState(sectionId,(event) => {
        setRefreshId(nanoid());
    })
    const [views , setViews] = useState<JSX.Element[]|null>(null);
    //
    // const views = doViewGenerator(sectionData.sectionWidgets,
    //                 props.uiLevel+1,
    //                 props.uiLevelStr,
    //                 props.sharedObjects,
    //                 props.onHandleSetting,
    //                 props.onHandleDeletion,
    //                 props.onHandleAddNewSectionOrWidget,
    //                 props.onHandleSwap,
    //                 props.onHandleUpdate
    //     );

    useEffect(() => {
        setViews(doViewGenerator(sectionData.sectionWidgets,
            props.uiLevel+1,
            props.uiLevelStr,
            props.sharedObjects,
            props.onHandleSetting,
            props.onHandleDeletion,
            props.onHandleAddNewSectionOrWidget,
            props.onHandleSwap,
            props.onHandleUpdate,
            props.onHandleClone,
            props.onHandleCopy,
            props.onHandleCut,
            props.onHandleCreateTemplate,
            props.updateWidgetData,
        ));
    },[refreshId])

    if(props.uiLevel == 0) {
        if (!sectionProps['bt_vertical_panel']) {
            sectionProps['bt_vertical_panel'] = {
                style: {
                    default: true,
                    padding: {
                        "padding-inline-start": DEFAULT_PADDING_INLINE,
                        "padding-inline-end": DEFAULT_PADDING_INLINE,
                        "padding-block-start": DEFAULT_PADDING_INLINE,
                        "padding-block-end": DEFAULT_PADDING_INLINE
                    }
                }
            };
        }
    }

    const themeName = "vertical";
    const sectionStyles = {
        theme: StyleUtil.toThemeNameByType(sectionProps, themeName),
        current: StyleUtil.toClassFromWidget(sectionId, sectionProps, "current_style"),
        panel: StyleUtil.toClassFromWidget(sectionId , sectionProps, "bt_vertical_panel"),
    }



    if(views === null) return <></>

    let empty: boolean = sectionData.sectionWidgets === null || sectionData.sectionWidgets.length === 0 || sectionData.sectionWidgets[sectionData.sectionWidgets.length-1] === null;

    const handleAddSectionOrWidget = (event: any) => {
        event.stopPropagation()
        event.preventDefault()
        setAnchorEl(event.currentTarget);
    }

    const handleOnClose = (event:any) => {
        setAnchorEl(null);
        event.stopPropagation();
        event.preventDefault();
    }

    const createVertical = () => <Box component={props.uiLevel === 0 ? "section" : "div"} key={refreshId} data-ui-type={props.uiType}
                                          className={StyleUtil.combineClasses(styles.Root,sectionStyles.theme,sectionStyles.current,sectionStyles.panel)}
        // style={StyleUtil.toStyleFromWidget(sectionProps,'panel')}
    >
        {views.map((view,index) => <div key={"vertical_sub_view_" + index + "_" + refreshId}>{view}</div>)}
        { viewState.isEdit && empty && <NewLabel
                         label={"+"}
                         uiLevelStr={props.uiLevelStr + "_" + (views.length)}
                         onHandleCut={props.onHandleCut}
                         onInsertSection={props.onInsertSection}
                         onInsertWidget={props.onInsertWidget}/> }
    </Box>

    if(viewState.isEdit) {
        const onChangeSettings: SettingChangeFunction|null = null;
        const suggestTags: string[] = [
            "current",
        ]
        const settingOptions:SettingOption[] = [

            { title: "", type: SettingType.widget,  editor: "verticalsetting", group: "content", groupType: SettingGroupType.layout},

            { type: SettingType.background, group: "bt_vertical_panel" , groupType: SettingGroupType.style},
            { type: SettingType.border, group: "bt_vertical_panel", groupType: SettingGroupType.style},


            { type: SettingType.margin,group: "bt_vertical_panel" , groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "bt_vertical_panel", groupType: SettingGroupType.advanced},

        ];

        const settingGroups:SettingGroup[] = [
            {group: "content", groupName: "General"},
            {group: "bt_vertical_panel", groupName: "Panel"}
        ]

        const open = Boolean(anchorEl);
        let copiedObject = GetCopiedObject();
        if(copiedObject && hasSection === false && 'sectionId' in copiedObject) {
            copiedObject = undefined;
        }

        return <EditablePanel {...props}
                                themeName={themeName}
                                newUILevelStr={props.uiLevelStr + "_" + (views.length)}
                                newOption={{ type: NewType.widget_or_section, tag: null}}
                                onHandleAddNewSectionOrWidget={props.onHandleAddNewSectionOrWidget}
                                suggestTags={suggestTags}
                                settingOptions={settingOptions}
                                settingGroups={settingGroups}
                                onChangeSettings={onChangeSettings}
                                deleteOption={{type: DeleteType.delete_with_rearrange, tag: null}}
                                className={styles.Root}
                              moreActions={[
                                  {icon: "add" , tooltip: "Add" , onClick: handleAddSectionOrWidget}
                              ]}
        >
            { open && <SectionOrWidgetPopover
                open={open}
                anchorEl={anchorEl}
                onClose={handleOnClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                className={styles.Popover}
                uiLevelStr={props.uiLevelStr + "_" + (views.length)}
                onInsertSection={props.onInsertSection}
                onInsertWidget={props.onInsertWidget}
            />}
            {createVertical()}
        </EditablePanel>
    } else {
        return createVertical();
    }
}