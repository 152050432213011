import {
    Autocomplete, Box, Button,
    Checkbox, Chip,
    FormControl,
    FormControlLabel,
    Grid, IconButton,
    InputLabel,
    MenuItem, Popover,
    Select,
    TextField
} from "@mui/material";
import React, {useEffect, useState} from "react";
import SharedObjects from "../../shared/editable/ShareObjects";
import {InputOption} from "../../../../model/InputModel";
import styles from "./AdvanceContentSetting.module.scss";
import {jcr} from "../../../../datafetcher/JCRDataFetcher";
import {AutocompleteRenderInputParams} from "@mui/material/Autocomplete/Autocomplete";
import OutlinedIcon from "../../../../ui/icon/OutlinedIcon";
import {ContentGroupCmd} from "../../../../model/jcr/ContentGroupCmd";

interface AutoCompleteOption {
    label: string,
    value: string,
    object: string,
}

interface ChipInfo {
    type: string,
    cgId: string,
    cgName:string
}

let globalTimeout:NodeJS.Timeout|null = null;

export default function AdvanceContentSetting(props: {
                                                widgetData: any,
                                                saveWidgetState: () => void,
                                                groupToContent?: any,
                                                cgIdToCgName?: any,
                                                sharedObjects?: SharedObjects
                                                widgetCallback: (action:string , value: any) => void
                                           }
                                                    // onChange: (searchType: string,
                                                //             searchData: string,
                                                //             start: number,
                                                //             rows: number,
                                                //             searchProfileId: number) => void}
) {
    const {widgetData} = props;
    const {widgetProps} = widgetData;
    const [menuAnchor,setMenuAnchor] = useState<any|null>(null);

    if(!widgetProps['content']) {
        widgetProps['content'] = {}
    }

    // <DrawerMenuItem icon={"view_agenda"} title={"Blocks"} href={"/content/blocks"} doCloseDrawer={handleClosedDrawer}/>
    // <DrawerMenuItem icon={"rss_feed"} title={"Blogs"} href={"/content/blogs"} doCloseDrawer={handleClosedDrawer}/>
    // <DrawerMenuItem icon={"quiz"} title={"FAQs"} href={"/content/faqs"} doCloseDrawer={handleClosedDrawer}/>
    // <DrawerMenuItem icon={"grid_view"} title={"Grids"} href={"/content/grids"} doCloseDrawer={handleClosedDrawer}/>
    // <DrawerMenuItem icon={"apps"} title={"Small Grids"} href={"/content/smallgrids"} doCloseDrawer={handleClosedDrawer}/>
    // <DrawerMenuItem icon={"collections"} title={"Galleries"} href={"/content/galleries"} doCloseDrawer={handleClosedDrawer}/>
    // <DrawerMenuItem icon={"event"} title={"Events"} href={"/content/events"} doCloseDrawer={handleClosedDrawer}/>
    // <DrawerMenuItem icon={"slideshow"} title={"Slideshows"} href={"/content/slideshows"} doCloseDrawer={handleClosedDrawer}/>

    const groupTypes =[
        {label: "Blogs",value: "blogs"},
        {label: "Blocks",value: "blocks"},
        {label: "FAQs",value: "faqs"},
        {label: "Grids",value: "grids"},
        {label: "Small Grids",value: "smallgrids"},
        {label: "Galleries",value: "galleries"},
        // {label: "Events",value: "event"},
        // {label: "Slideshow",value: "slideshows"}
    ]


    const [groupType,setGroupType] = useState<string>("blogs");

    const [contentGroup, setContentGroup] = useState<{ cgId: string, cgName: string}>({cgId: "", cgName: ""});
    const [contentGroups, setContentGroups] = useState<InputOption[]>([]);
    const [groupToContents, setGroupToContents] = useState<any>(props.groupToContent);
    const [cgIdToCgName, setCgIdToCgName] = useState<any>(props.cgIdToCgName);
    const [categoryPrefix,setCategoryPrefix] = useState<string>(widgetProps['content']['categoryPrefix'] ? widgetProps['content']['categoryPrefix'] : "");
    const [shopPrefix,setShopPrefix] = useState<string>(widgetProps['content']['shopPrefix'] ? widgetProps['content']['shopPrefix'] : "");
    const [titleMessage,setTitleMessage] = useState<string>(widgetProps['content']['titleMessage'] ? widgetProps['content']['titleMessage'] : "");
    const [emptyMessage,setEmptyMessage] = useState<string>(widgetProps['content']['emptyMessage'] ? widgetProps['content']['emptyMessage'] : "No content available to display");
    const [hasBreadCrumb,setHasBreadCrumb] = useState<boolean>(widgetProps['content']['hasBreadCrumb'] ? widgetProps['content']['hasBreadCrumb'] : true);
    const [breadcrumbSeparator,setBreadcrumbSeparator] = useState<String>(widgetProps['content']['breadcrumbSeparator'] !== undefined ? widgetProps['content']['breadcrumbSeparator'] : "/");
    const [breadcrumbSpace,setBreadcrumbSpace] = useState<String>(widgetProps['content']['breadcrumbSpace'] !== undefined ? widgetProps['content']['breadcrumbSpace'] : "0.1rem");
    const [items, setItems] = useState<ChipInfo[]>(widgetProps && widgetProps['content'] && widgetProps['content']['acmItems'] ? widgetProps['content']['acmItems']  : []);
    const [varName, setVarName] = useState<string>(widgetProps && widgetProps['content'] && widgetProps['content']['acmVarName'] ? widgetProps['content']['acmVarName']  : "${acmName}");
    // const [previewList,setPreviewList] = useState<AutoCompleteOption[]>([]);
    const currentLabel = props.cgIdToCgName[widgetProps && widgetProps['content'] && widgetProps['content']['previewCgId'] ? widgetProps['content']['previewCgId']  : ""];
    const [currentPreview,setCurrentPreview] = useState<AutoCompleteOption>( {
        label: currentLabel ? currentLabel : "Top Level",
        value: widgetProps && widgetProps['content'] && widgetProps['content']['previewCgId'] ? widgetProps['content']['previewCgId']  : "",
        object: widgetProps && widgetProps['content'] && widgetProps['content']['previewType'] ? widgetProps['content']['previewType']  : ""
    });

    const startGlobalWithDelay = (time:number,func: () => void ) => {
        globalTimeout = setTimeout(() => {
            globalTimeout = null;
            func();
        }, time);
    }

    const handleBlurOnChipEditor = (event:React.FocusEvent) => {
        startGlobalWithDelay(100,() => {
            setMenuAnchor(null);
        });
        event.stopPropagation()
        event.preventDefault()
    }

    const handleCancelAutoClose = (event:React.FocusEvent) => {
        if(globalTimeout !== null) {
            clearTimeout(globalTimeout);
            globalTimeout = null;
        }
        event.stopPropagation()
        event.preventDefault()
    }

    const handleChange = (value: any, key: string) => {
        widgetProps["content"][key] = value;
        props.saveWidgetState();
    }

    const handleDeleteChip = (index:number) => {
        let newItems = [...items];
        let deleteItem = items[index];
        if(deleteItem.cgId === currentPreview.value) {
            //props.onChangePreview("","");
            props.widgetCallback("preview",{type : "", cgId: ""});
            setCurrentPreview({label: "Top Level", value: "", object: ""})
        }
        newItems.splice(index,1);
        setItems(newItems);
        handleChange(newItems,'acmItems');

    }

    const handleOnClickAddItem = () => {
        let info = {type: groupType, cgId: contentGroup.cgId, cgName: contentGroup.cgName};
        let newItems = [...items]
        let index = newItems.findIndex(item => item.cgId === info.cgId)
        if(index >= 0) {
            newItems.splice(index, 1);
        }
        newItems.push(info);
        setItems(newItems);
        handleChange(newItems,'acmItems');
        setMenuAnchor(null);
    }

    useEffect(() => {
        jcr.contentGroup.list(groupType).then((response) => {
            let groups: InputOption[] = [];
            let newCgIdToCgNme = {...cgIdToCgName};
            for(let item of response.data.response) {
                let cgGroup = item as ContentGroupCmd;
                if(cgGroup.cgStatus === "published") {
                    groups.push({label: cgGroup.cgName, value: cgGroup.id});
                    newCgIdToCgNme[cgGroup.id] = cgGroup.cgName
                }
            }
            setCgIdToCgName(newCgIdToCgNme);
            setContentGroups(groups);
            if(groups.length > 0) {
                setContentGroup({cgId: groups[0].value , cgName: groups[0].label});
            } else {
                setContentGroup({cgId: "" , cgName: ""});
            }

        })
    },[groupType])

    useEffect(() => {
        if(groupType && contentGroup.cgId) {
            if(!groupToContents[contentGroup.cgId]) {
                jcr.content.fullList(groupType, contentGroup.cgId).then((response) => {
                    let newGroup = {...groupToContents}
                    newGroup[contentGroup.cgId] = response.data.response;
                    setGroupToContents(newGroup);
                })
            }
        }
    },[contentGroup])



    // useEffect(() => {
        // if(drilldown !== "") {
        //     jcr.drilldown.query(drilldown).then((response) => {
        //         let drillData:DrillItemCmd = JSON.parse(response.data.response.drillData);
        //         let collectName:AutoCompleteOption[] = [];
        //         let drillArray:{data: DrillItemCmd , depth: number}[] = [];
        //         let count:number = 0;
        //         let exitsNames: string[] = [];
        //         let previewId = widgetProps['content']['previewId'];
        //         drillArray.push({data: drillData, depth: 0});
        //         let previewOption:AutoCompleteOption|undefined = undefined;
        //         while(drillArray.length > 0) {
        //             let currentDrill = drillArray.pop();
        //             if(currentDrill && currentDrill.data.subItems.length > 0) {
        //                 currentDrill.data.subItems.forEach(item => drillArray.push({data: item , depth: currentDrill!.depth+1}));
        //                 if(exitsNames.indexOf(currentDrill.data.name) < 0) {
        //                     let option = {
        //                         label: currentDrill.data.name,
        //                         value: currentDrill.data.indexId,
        //                         depth: currentDrill.depth,
        //                         index: count++
        //                     };
        //
        //                     if(currentDrill.data.indexId === previewId) {
        //                         previewOption = option
        //                     }
        //                     collectName.push(option);
        //                     exitsNames.push(currentDrill.data.name);
        //                 }
        //             }
        //         }
        //         if(collectName.length > 0) {
        //             if(previewOption) {
        //                 setCurrentPreview(previewOption)
        //             } else {
        //                 setCurrentPreview(collectName[0])
        //                 props.onChangePreview(drilldown,collectName[0].value);
        //             }
        //         }
        //         setPreviewList(collectName);
        //
        //     });
        // }
    // },[contentGroups]);

    function createContentGroup() {
        return <FormControl size={"small"} fullWidth>
            <InputLabel id={`group_selection`}>Content Group</InputLabel>
            <Select
                label={`Content Group`}
                id={`group_selection`}
                onChange={(event) => {
                    let data = contentGroups.find(g => g.value === event.target.value);
                    if(data) {
                        setContentGroup({cgId: data.value , cgName: data.label});
                    }
                }}
                value={contentGroups.findIndex(g => g.value === contentGroup.cgId) >= 0 ? contentGroup.cgId : ""}
            >
                {
                    contentGroups.map((option,index) => {
                        return <MenuItem key={`group_selection_item_${index}`} value={option.value}>{option.label}</MenuItem>
                    })
                }
            </Select>
        </FormControl>
    }


    function createGroupType() {
        return <FormControl size={"small"} fullWidth>
            <InputLabel id={`type_selection`}>Content Type</InputLabel>
            <Select
                label={`Content Type`}
                id={`type_selection`}
                onChange={(event) => {
                   setGroupType(event.target.value);
                }}
                value={groupType}
            >
                {
                   groupTypes.map((option,index) => {
                        return <MenuItem key={`type_selection_item_${index}`} value={option.value}>{option.label}</MenuItem>
                    })
                }
            </Select>
        </FormControl>
    }

    let previewList:InputOption[] = [];
    previewList.push({label : "Top Level" , value : "", object: ""});
    items.map((item) => {
        previewList.push({label : item.cgName , value : item.cgId, object: item.type});
    })


    return <Grid container spacing={2} alignItems="center" justifyContent="center">
        {/*<Grid item xs={12}>*/}
        {/*    <TextField size={"small"}*/}
        {/*               fullWidth={true}*/}
        {/*               label={"Catalog URL Prefix"}*/}
        {/*               value={categoryPrefix}*/}
        {/*               onChange={(event) => {*/}
        {/*                   handleChange(event.target.value.trim(), "categoryPrefix")*/}
        {/*                   setCategoryPrefix(event.target.value);*/}
        {/*               }}/>*/}
        {/*</Grid>*/}
        {/*<Grid item xs={12}>*/}
        {/*    <TextField size={"small"}*/}
        {/*               fullWidth={true}*/}
        {/*               label={"Shop URL Prefix"}*/}
        {/*               value={shopPrefix}*/}
        {/*               onChange={(event) => {*/}
        {/*                   handleChange(event.target.value.trim(), "shopPrefix")*/}
        {/*                   setShopPrefix(event.target.value);*/}
        {/*               }}/>*/}
        {/*</Grid>*/}
        <Grid item xs={12}>
            <Grid item xs={12}>
                <TextField size={"small"}
                           fullWidth={true}
                           label={"Variable Name"}
                           value={varName}
                           onChange={(event) => {
                               handleChange(event.target.value.trim(), "acmVarName")
                               setVarName(event.target.value);
                           }}/>
            </Grid>
        </Grid>
        <Grid item xs={12}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={"Top Title"}
                       value={titleMessage}
                       onChange={(event) => {
                           handleChange(event.target.value.trim(), "titleMessage")
                           setTitleMessage(event.target.value);
                       }}/>
        </Grid>
        <Grid item xs={12}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={"Empty Message"}
                       value={emptyMessage}
                       onChange={(event) => {
                           handleChange(event.target.value.trim(), "emptyMessage")
                           setEmptyMessage(event.target.value);
                       }}/>
        </Grid>
        {/*<Grid item xs={12}>*/}
        {/*    { createGroupType() }*/}
        {/*</Grid>*/}
        {/*<Grid item xs={12}>*/}
        {/*    { createContentGroup() }*/}
        {/*</Grid>*/}
        <Grid item xs={12}>
                {
                    items.map((item,index) => {
                        return <Chip key={`acm_chip_${index}`} label={`${item.cgName}`}
                                className={styles.Chip}
                                data-index={index}
                                onDelete={() => handleDeleteChip(index)}
                        />
                    })
                }
            <IconButton size={"small"} onClick={(event) => {  setMenuAnchor(event.currentTarget)}}><OutlinedIcon>add</OutlinedIcon></IconButton>
            <Popover tabIndex={-1}
                     open={menuAnchor !== null}
                     onFocus={handleCancelAutoClose}
                     onBlur={handleBlurOnChipEditor}
                     anchorEl={menuAnchor}
                     onClose={() => {startGlobalWithDelay(300,() => setMenuAnchor(null))}}
                     disableEnforceFocus={true}
                     style={{pointerEvents: "none"}}
                     anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'left',
                     }}
            >
                <Grid container className={styles.Popover} rowSpacing={1}>
                    <Grid item xs={12}>
                        { createGroupType() }
                    </Grid>
                    <Grid item xs={12}>
                        { createContentGroup() }
                    </Grid>
                    <Grid item xs={12} className={styles.PopoverActionPanel}>
                        <Button size={"small"} onClick={handleOnClickAddItem} className={styles.AddButton}>Add</Button>
                    </Grid>
                </Grid>
            </Popover>
        </Grid>
        <Grid item xs={12}  style={{paddingBlockStart: "0px"}}>
            <FormControlLabel className={styles.DefaultFontColor} control={<Checkbox
                checked={hasBreadCrumb}
                onChange={(event: any,value:boolean) => {
                    handleChange(event.target.checked, "hasBreadCrumb")
                    setHasBreadCrumb(event.target.checked);
                }}/>} label="Show Breadcrumb"/>
        </Grid>
        {hasBreadCrumb && <>
            <Grid item xs={12}>
                <TextField size={"small"}
                           fullWidth={true}
                           label={"Breadcrumb separator"}
                           value={breadcrumbSeparator}
                           onChange={(event) => {
                               handleChange(event.target.value.trim(), "breadcrumbSeparator")
                               setBreadcrumbSeparator(event.target.value);
                           }}/>
            </Grid>
            <Grid item xs={12}>
                <TextField size={"small"}
                           fullWidth={true}
                           label={"Space between Breadcrumb"}
                           value={breadcrumbSpace}
                           onChange={(event) => {
                               handleChange(event.target.value.trim(), "breadcrumbSpace")
                               setBreadcrumbSpace(event.target.value);
                           }}/>
            </Grid>
            </>
        }

        <Grid item xs={12}>
            <Autocomplete
                autoHighlight
                key={currentPreview ? currentPreview.label : ""}
                fullWidth
                size={"small"}
                id="content_preview"
                options={previewList}
                value={currentPreview ? currentPreview : previewList[0]}
                getOptionLabel={(option) => {
                    return option.label;
                }}
                renderOption={(props,option) => {
                        return <Box key={"key_example_" + option.label} component="li" {...props}>
                            {option.label}
                        </Box>
                    }
                }
                isOptionEqualToValue={(option, value) => {
                    return option.value === value.value;
                }}
                onChange={(event,option: any) => {
                    if(option) {
                        setCurrentPreview(option);
                        //props.onChangePreview(option.object, option.value);
                        props.widgetCallback("preview",{type : option.object, cgId: option.value});
                    }
                }}
                renderInput={(params:AutocompleteRenderInputParams) =>
                    <TextField {...params}
                               label="Preview"
                    />
                }
            />
        </Grid>
    </Grid>
}