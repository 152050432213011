export function printLogo() {
    let logo  = "   _______   __  __  __    __                                      __     \n";
    logo += "  /       \\ /  |/  |/  |  /  |                                    /  |    \n";
    logo += "  $$$$$$$  |$$/ $$ |$$ | _$$ |_     ______   __    __   _______  _$$ |_   \n";
    logo += "  $$ |__$$ |/  |$$ |$$ |/ $$   |   /      \\ /  |  /  | /       |/ $$   |  \n";
    logo += "  $$    $$< $$ |$$ |$$ |$$$$$$/   /$$$$$$  |$$ |  $$ |/$$$$$$$/ $$$$$$/   \n";
    logo += "  $$$$$$$  |$$ |$$ |$$ |  $$ | __ $$ |  $$/ $$ |  $$ |$$      \\   $$ | __ \n";
    logo += "  $$ |__$$ |$$ |$$ |$$ |  $$ |/  |$$ |      $$ \\__$$ | $$$$$$  |  $$ |/  |\n";
    logo += "  $$    $$/ $$ |$$ |$$ |  $$  $$/ $$ |      $$    $$/ /     $$/   $$  $$/ \n";
    logo += "  $$$$$$$/  $$/ $$/ $$/    $$$$/  $$/        $$$$$$/  $$$$$$$/     $$$$/  \n";
    logo += "\n\nTip: Developer console features intended for developers only.\n\n"

    logo = logo.replaceAll("$","\u2588")
    console.log("%c" + logo,"font-family:monospace;color:#6e9d30; font-size: 12px;white-space: nowrap");
}