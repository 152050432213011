import axios, {AxiosResponse} from "axios";
import qs from "qs";
import ResponseCmd from "../cms/model/ReponseCmd";
import SSOCmd from "../model/lsi/SSOCmd";


const getPath = (path: string) => {
    //return "http://lsi-dev.2phase.net:6060" + path;
    //return "http://localhost:6060" + path;
    // console.log(process.env.REACT_APP_JCR_HOST);
    // console.log(process.env.REACT_APP_JCR_HOST);
    if(path.startsWith("http")) {
        return path;
    } else {
        return process.env.REACT_APP_JCR_HOST + path;
    }
}

const  HttpClient = () => {
    return {
        sso: (login: string, password: string) => {
            const defaultOptions = {
                headers: {
                    'Content-Type': 'application/json',
                },
                //withCredentials: true,
            };
            return axios.post(getPath("/sso_authinfo"),{ loginID: login ,password: password },{...defaultOptions}).then((response: AxiosResponse<ResponseCmd<SSOCmd>>) => {
                setHttpClientToken(response.data.response,false);
                return response;
            });
        },

        refreshSSO: () => {
            if(localStorage.getItem("refreshToken") !== null) {
                return axios.post(getPath("/refresh_authinfo"), {refreshToken: localStorage.getItem("refreshToken")}).then((response: AxiosResponse<ResponseCmd<SSOCmd>>) => {
                    setHttpClientToken(response.data.response, true);
                    return response;
                })
            }
        },


        get: <T = any, R = AxiosResponse<T>>(url: string, options = {}):Promise<R> => {
            const defaultOptions = {
                headers: {
                    'x-api-key': globalCmd.accessToken,
                    'api_key': globalCmd.accessToken,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                //withCredentials: true,
                paramsSerializer: (params:any) => {
                    return qs.stringify(params, { arrayFormat: 'repeat' })
                }
            };
            return axios.get<T,R>(getPath(url), { ...defaultOptions, ...options })
        },

        download: <T = any, R = AxiosResponse<any>>(fileName: string,url: string, options = {}):void => {
            const defaultOptions = {
                headers: {
                    'x-api-key': globalCmd.accessToken,
                    'api_key': globalCmd.accessToken,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                //withCredentials: true,
                paramsSerializer: (params:any) => {
                    return qs.stringify(params, { arrayFormat: 'repeat' })
                }
            };
            axios.get<T,any>(getPath(url), { ...defaultOptions, ...options , responseType: "arraybuffer"}).then((response) => {
                let blob = new Blob([response.data], { type: response.headers["content-type"] } );
                url = window.URL.createObjectURL(blob);
                const anchor = document.createElement("a");
                anchor.href = url;
                anchor.download = fileName;
                document.body.appendChild(anchor);
                anchor.click();
                window.URL.revokeObjectURL(url);
            });
        },

        post: <T = any, R = AxiosResponse<T>>(url: string, data: any, options = {}):Promise<R> => {
            const defaultOptions = {
                headers: {
                    'x-api-key': globalCmd.accessToken,
                    'api_key': globalCmd.accessToken
                },
                //withCredentials: true,
                paramsSerializer: (params:any) => {
                    return qs.stringify(params, { arrayFormat: 'repeat' })
                }
            };
            return axios.post<T,R>(getPath(url), data, { ...defaultOptions, ...options })
        },

        postForm: <T = any, R = AxiosResponse<T>>(url: string, data:any, options = {}):Promise<R> => {
            const defaultOptions = {
                headers: {
                    'x-api-key': globalCmd.accessToken,
                    'api_key': globalCmd.accessToken,
                },
                //withCredentials: true,
                paramsSerializer: (params:any) => {
                    return qs.stringify(params, { arrayFormat: 'repeat' })
                }
            };
            let bodyFormData = new FormData();
            for (let key in data ) {
                if(data[key] instanceof FileList) {
                    let fileList = data[key] as FileList;
                    for(let j=0;j<fileList.length;j++) {
                        bodyFormData.append(key, fileList[j]);
                    }
                } else if(Array.isArray(data[key])) {
                    for(let j=0;j<data[key].length;j++) {
                        bodyFormData.append(key, data[key][j]);
                    }
                } else {
                    bodyFormData.append(key, data[key]);
                }
            }


            return axios.post<T,R>(getPath(url), bodyFormData, { ...defaultOptions, ...options })
        },
        put: <T = any, R = AxiosResponse<T>>(url: string, data: any, options = {}):Promise<R> => {
            const defaultOptions = {
                headers: {
                    'x-api-key': globalCmd.accessToken,
                    'api_key': globalCmd.accessToken
                },
                //withCredentials: true,
                paramsSerializer: (params:any) => {
                    return qs.stringify(params, { arrayFormat: 'repeat' })
                }
            };
            return axios.put<T,R>(getPath(url), data, { ...defaultOptions, ...options })
        },
        delete: <T = any, R = AxiosResponse<T>>(url: string, options = {}):Promise<R> => {
            const defaultOptions = {
                headers: {
                    'x-api-key': globalCmd.accessToken,
                    'api_key': globalCmd.accessToken
                },
                //withCredentials: true,
                paramsSerializer: (params:any) => {
                    return qs.stringify(params, { arrayFormat: 'repeat' })
                }
            };
            return axios.delete<T,R>(getPath(url), { ...defaultOptions, ...options })
        },
    };
};

const client = HttpClient();
let globalTimer:  NodeJS.Timeout;
let globalCmd: SSOCmd;

export function hasCMSModule() : boolean {
    if(globalCmd) {
        // < 0 || sso.orgFeatures.indexOf("CMS") < 0
        if (globalCmd.roleName === "LSIADMIN") return true;
        else if (["DISTRIBUTORADMIN", "DISTRIBUTOR", "MARKETING"].indexOf(globalCmd.roleName) >= 0 &&
            globalCmd.orgFeatures.indexOf("CMS") >= 0) {
            return true;
        }
    }
    return false;
}

export function hasPIMModule() : boolean {
    if(globalCmd) {
        if (globalCmd.roleName === "LSIADMIN") return true;
        else if (["DISTRIBUTORADMIN","DISTRIBUTOR","PDM"].indexOf(globalCmd.roleName) >= 0 && globalCmd.orgFeatures.indexOf("PIM2") >= 0) {
            return true;
        }
    }
    return false;
}

export function hasMobileModule() : boolean {
    if(globalCmd) {
        if (globalCmd.roleName === "LSIADMIN") return true;
        else if (["MOBILE"].indexOf(globalCmd.roleName) >= 0 && globalCmd.orgFeatures.indexOf("Mobile") >= 0) {
            return true;
        }
    }
    return false;
}

export function useSSO() : SSOCmd {
    return globalCmd;
}

export function getGlobalSSO(): SSOCmd {
    return globalCmd;
}

const setHttpClientToken = (ssoCmd: SSOCmd, refreshRoute: boolean) => {
    globalCmd = ssoCmd;
    Object.freeze(globalCmd);

    if(globalCmd !== null) {
        if('pendo' in window) {

            // @ts-ignore
            let _pendo:any = window.pendo;
            _pendo.initialize({
                visitor: {
                    id: ssoCmd.email,
                    visitorId: ssoCmd.email,
                    email: ssoCmd.email,
                    salesforceId: ssoCmd.salesforceId,
                    userRoleName: ssoCmd.roleName,
                },
                account: {
                    accountId: ssoCmd.orgCode,
                    id: ssoCmd.orgId
                }
            });
        }

        let remain = new Date(globalCmd.accessExpiredAt).getTime() - Date.now() - 1*60*1000; // one minutes before expired, we create new request
        if(remain > 0) {
            if(globalTimer) {
                clearTimeout(globalTimer);
            }
            globalTimer = setTimeout(() => {
                updateToken(true);
            },remain);
        } else {
            updateToken(refreshRoute);
        }
    } else {
        if(globalTimer) {
            clearTimeout(globalTimer);
        }
        localStorage.clear();
        if(refreshRoute ) {
            if (window.location.href.toLowerCase().indexOf('/login') < 0) {
                window.location.href = "/home";
            }
        }
    }
}


// call automatic from timer
function updateToken(refreshRoute: boolean) {
    axios.post(getPath("/refresh_authinfo"), {refreshToken: localStorage.getItem("refreshToken")}).then(
        (axiosResponse:AxiosResponse<any>) => {
            let responseCmd:ResponseCmd<SSOCmd> = axiosResponse.data;
            let ssoCmd = responseCmd.response;
            if (ssoCmd.verify) {
                localStorage.setItem("userName", ssoCmd.name);
                localStorage.setItem("refreshToken", ssoCmd.refreshToken);
                localStorage.setItem("userProfileImage", "");
                setHttpClientToken(ssoCmd,refreshRoute);
            } else {
                localStorage.clear();
                if(window.location.href.toLowerCase().indexOf('/login') > 0) {
                    window.location.href = "/home";
                }
            }
        }
    ).catch((error) => {
        localStorage.clear();
        if(refreshRoute) {
            if (window.location.href.toLowerCase().indexOf('/login') > 0) {
                window.location.href = "/home";
            } else {
                window.location.href = "/login";
            }
        }
    })

}

export default client;
