import React, {useEffect, useRef, useState} from 'react';
import styles from "./Views.module.scss";
import commonStyle from "../../config/Common.module.scss";
import StyledTable from "../table/StyledTable";
import {ViewDataFetcher} from "../../datafetcher/ViewDataFetcher";
import {useAppDispatch} from "../../redux/hook";
import {viewResetAction} from "../../redux/features/view/viewSlice";
import InputDialog from "../dialog/InputDialog";
import {useHistory} from "react-router";
import {useParams} from "react-router-dom";
import {InputModel} from "../../model/InputModel";
import HttpClient, {hasCMSModule, useSSO} from "../../datafetcher/HttpClient";
import {useOrganization} from "../../hook/useOrganization";
import {nanoid} from "nanoid";
import SolidIcon from "../icon/SolidIcon";
import IconLinkButton from "../button/IconLinkButton";
import ReverseSolidLinkSmallButton from '../button/ReverseSolidLinkSmallButton';
import PageSummary from "../pagesummary/PageSummary";
import {CurrentISODate} from "../../util/DateUtil";
import OutlinedIcon from "../icon/OutlinedIcon";
import {jcr} from "../../datafetcher/JCRDataFetcher";
import PageTemplateDialog from "../template/PageTemplateDialog";
import {Dialog, Grid, Tab, Tabs, ToggleButtonGroup, Tooltip} from "@mui/material";
import {TemplateCmd} from "../../model/TemplateCmd";
import TemplateItem from "../templateselection/TemplateItem";
import _ from "lodash";
import ViewItem from "./ViewItem";
import {format} from "date-fns";
import { ToggleButton } from '@mui/material';
import {GetLocalItem, SetLocalItem} from "../../util/LocalStorageUtil";
import {FindTagName} from "../template/TemplateConst";
import TemplateSelection from "../templateselection/TemplateSelection";
import {SectionInterface} from "../../cms/model/SectionInterface";
import {doAssignNewIds} from "../../cms/util/UIFactory";
import {SearchButton} from "../button/SearchButton";
import AccessDenied from "../accessdenied/AccessDenied";



export default function Views() {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const ssoCmd = useSSO();
    const query = new URLSearchParams(history.location.search);
    const params:any = useParams<{vid: string}>();
    const [newOpen, setNewOpen] = useState(false);
    const [updateData, setUpdateData] = useState<any>({});
    const [updateViewId, setUpdateViewId] = useState("");
    const [formKey , setFormKey] = useState("");
    const [deleteList,setDeleteList] = useState<string[]>([]);
    const [dataFetcher, setDataFetcher] = useState<ViewDataFetcher|null>(null);
    const [tab, setTab] = useState<string>(GetLocalItem("viewCurrentTab","templates"))
    const orgCmdProp = useOrganization();
    const [globalTemplates, setGlobalTemplates] = useState<TemplateCmd[]>([]);
    const [displayType, setDisplayType] = useState<String>(GetLocalItem("viewDisplayType","gridView"));
    const [focusTemplate, setFocusTemplate] = useState<TemplateCmd>();
    const [storefronts, setStorefronts] = useState<any[]>([]);
    const apiName = "view"
    const apiNames = "views"
    const inputFile = useRef<HTMLInputElement>(null);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [templateDialog, setTemplateDialog] = useState<{
        open: boolean,
        viewIds: string[],
        viewNames: string[],
        viewData: string[],
        viewLogos: string[]
    }>();

    const handleCloseNewDialog = () => {
        setNewOpen(false);
    }

    const handleOpenNewDialog = () => {
        setNewOpen(true);
    }

    const handleUploadDialog = () => {
        if(inputFile !== null && inputFile.current !== null) {
            inputFile.current.click();
        }
    }

    const doUploadFile = () => {
        if(inputFile && inputFile.current) {
            if(inputFile.current.files) {
                jcr.view.upload(inputFile.current.files).then((response) => {
                    if(!(orgCmdProp && orgCmdProp.currentOrg !== null && orgCmdProp.orgDetail !== null)) return;
                    setDataFetcher(new ViewDataFetcher(orgCmdProp.currentOrg,() => {
                            dispatch(viewResetAction());
                        },searchTerm
                    ));
                    setFormKey(nanoid());
                })
            }
        }
    }

    const handleUpdateDialog = (values: InputModel[]) => {
        let data: any = updateData;
        values.forEach(object => {
            data[object.id] = object.defaultValue;
        })
        data['createdAt'] = updateData.createdAt;
        data['modifiedAt'] = new Date().getTime();

        HttpClient.post("/api/v0/jcr/org/" + orgCmdProp?.currentOrg!.orgCode+ "/lite/" + apiNames , data).then((response) => {
            history.push({
                pathname: "/pages",
                search: history.location.search
            });
            setUpdateData({})
            setUpdateViewId("");
            setNewOpen(false);
            setFormKey(nanoid());

        }).catch((e) => {
            console.log(e);
        })
    }

    const handleSaveDialog = (values: InputModel[]) => {
        let data: any = {};
        values.forEach(object => {
            data[object.id] = object.defaultValue;
        })
        data['createdAt'] = new Date().getTime();
        data['modifiedAt'] = new Date().getTime();

        HttpClient.post("/api/v0/jcr/org/" + orgCmdProp?.currentOrg!.orgCode + "/lite/" + apiNames , data).then((response) => {
            setUpdateViewId("");
            setUpdateData({});
            setNewOpen(false);
            setFormKey(nanoid());

            history.push({
                pathname: history.location.pathname + "/" + response.data.response.viewName + "/" + response.data.response.id ,
                search: history.location.search
            })

        }).catch((e) => {
            console.log(e);
        })

    }

    const handleDelete = () => {
        if(window.confirm("Are you sure to remove?")) {
            HttpClient.delete("/api/v0/jcr/org/" + orgCmdProp?.currentOrg!.orgCode + "/view/" + deleteList.join(",")).then((response) => {
                setDeleteList([]);
                setFormKey(nanoid());
            }).catch((e) => {
                setDeleteList([]);
            })
        }
    }

    const handleCreateTemplate = () => {

        let allRequests: Promise<any> [] = [];

        deleteList.forEach(item => allRequests.push(HttpClient.get("/api/v0/jcr/org/" + orgCmdProp?.currentOrg!.orgCode + "/view/" + item)));
        Promise.all(allRequests).then((values) => {
            let ids:string[] = [];
            let names:string[] = [];
            let data:string[] = [];
            let viewLogos:string[] = []
            values.forEach(value => {
                // console.log(value.data);
                // console.log(value.data.response);
                let {id,viewName,viewData} = value.data.response;
                ids.push(id);
                names.push(viewName);
                data.push(viewData);
                viewLogos.push("")
            })
            setTemplateDialog({
                open: true,
                viewIds: ids,
                viewNames: names,
                viewData: data,
                viewLogos
            })
        })
    }
    const handleDownloadList = () => {
        HttpClient.download(`Pages-${CurrentISODate()}.gz`,
             "/api/v0/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/download/" + apiNames + "/" + deleteList.join(","));
        setDeleteList([]);
        setFormKey(nanoid());
    }


    if (params.vid && updateViewId !== params.vid) {
        setTimeout(() => {
            setUpdateViewId(params.vid);
        }, 100);
    }

    useEffect(() => {
        if(!(orgCmdProp && orgCmdProp.currentOrg !== null && orgCmdProp.orgDetail !== null && orgCmdProp?.currentOrg!.orgCode != null)) return;
        if(updateViewId !== "") {
            HttpClient.get("/api/v0/jcr/org/" + orgCmdProp?.currentOrg!.orgCode + "/" + apiName + "/" +  updateViewId).then((response) => {
                setUpdateData(response.data.response);
            }).catch((e) => {
                console.log(e);
            })
        }
    },[orgCmdProp,updateViewId,dispatch])

    useEffect(() => {
        if(tab === "mypages") {
            if (dataFetcher !== null && dataFetcher.getTotalRecords() === 0) {
                (dataFetcher as any).load().then(() => {
                        setFormKey(nanoid());
                    })
                }
            }
        }
    ,[tab,dataFetcher]);

    useEffect(() => {
        if(!(orgCmdProp && orgCmdProp.currentOrg !== null && orgCmdProp.orgDetail !== null)) return;
        setDataFetcher(new ViewDataFetcher(orgCmdProp.currentOrg,() => {
                dispatch(viewResetAction());

            },searchTerm
        ));
        jcr.pageTemplate.globalLite().then((response) => {
            setGlobalTemplates(response.data.response);
            setFormKey(nanoid());
        })

        jcr.storefront.full().then(response => {
            setStorefronts(response.data.response.filter((storefront:any) => storefront.sfThemeId !== null &&  storefront.sfThemeId !== ""));
        })

        setFormKey(nanoid());

    },[orgCmdProp,dispatch])


    const handleCloseUpdateDialog = () => {
        setUpdateData({});
        setUpdateViewId("");
        history.push({
            pathname: '/pages',
            search: history.location.search
        });
    }

    const handleAction = (tag:string, tagObject: any) => {
        if(tag === "DUPLICATE") {
            HttpClient.get("/api/v0/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/clone/" + apiNames + "/" +  tagObject.id)
                .then((response) => {
                let id = response.data.response;
                history.push({
                    pathname: `${history.location.pathname}/${tagObject.viewName}/${id}`,
                    search: history.location.search
                })
            })
        } else if(tag === "DOWNLOAD") {
            HttpClient.download(`Page-${tagObject.viewName}-${tagObject.id}.gz`,
                 "/api/v0/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/download/" + apiNames + "/" +  tagObject.id);
        }
    }


    function createTemplateView() {
        if(globalTemplates.length === 0) return <></>;
        return <Grid container spacing={3} className={styles.TemplateRoot}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                <ViewItem id={null}
                          storefronts={storefronts}
                          type={"view"}
                          title={"Start from scratch"}
                          modifiedAt={0}
                          orgCode={orgCmdProp?.currentOrg!.orgCode!}
                          logo={"/images/view_add.png"}
                          doSelectView={ () => {}}/>
            </Grid>
            {focusTemplate && <Dialog
                className={styles.TemplateDialog}
                fullWidth={true}
                maxWidth={"xl"}
                open={true}
                onClose={() => setFocusTemplate(undefined)}
            >
                <TemplateSelection doClose={() => {
                                                    setFocusTemplate(undefined)
                                                }}
                                   doSelectTemplate={() => {}}
                                   onlyFocusTemplate={true}
                                   onNewPage={(name: string, data: string) => { // create new one and add data to new one and open to editor

                                       name = name.trim();
                                       if(name !== "") {
                                           let clonedViewObjects:SectionInterface[] = [];
                                           let clonedObject = JSON.parse(data);
                                           if (Array.isArray(clonedObject)) {
                                               let interfaces = clonedObject as SectionInterface[];
                                               for(let i = 0;i<interfaces.length;i++) {
                                                   clonedViewObjects.push(doAssignNewIds(_.cloneDeep(interfaces[i])) as SectionInterface);
                                               }
                                           } else {
                                               clonedViewObjects.push(doAssignNewIds(_.cloneDeep(clonedObject)) as SectionInterface);
                                           }

                                           let newView: any = {};
                                           newView['viewName'] = name;
                                           newView['viewData'] = JSON.stringify(clonedViewObjects);
                                           newView['createdAt'] = new Date().getTime();
                                           newView['modifiedAt'] = new Date().getTime();

                                           HttpClient.post("/api/v0/jcr/org/" + orgCmdProp?.currentOrg!.orgCode + "/full/views" , newView).then((response) => {
                                               history.push({
                                                   pathname: history.location.pathname + "/" + response.data.response.viewName + "/" + response.data.response.id ,
                                                   search: history.location.search
                                               })
                                           }).catch((e) => {
                                               console.log(e);
                                           })
                                       }
                                       setFocusTemplate(undefined);
                                   }}
                                   focusTemplate={focusTemplate}
                                   orgCode={orgCmdProp?.currentOrg!.orgCode!}/>
            </Dialog>
            }
            {   globalTemplates.filter(template => searchTerm === "" || template.title.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0).map((template,index) => <Grid item xs={12} sm={12} md={6} lg={6} xl={4} key={`template_grid_${index}`}>
                        <TemplateItem
                             data={"no_date"}
                             doSelectTemplate={(data) => {
                                 if(template.id !== null) {
                                     jcr.pageTemplate.globalQuery(template.id).then((response) => {
                                         setFocusTemplate(response.data.response);
                                     })
                                 }
                             }}
                             modifiedAt={template.modifiedAt}
                             logo={template.logo === "" ?  `${process.env.REACT_APP_WEBC_HOST}/thumbnail/${process.env.REACT_APP_GLOBAL_ORGCODE}/${template.id}-0.jpg` : template.logo}
                             title={template.title}
                             subTitle={FindTagName(template)}
                             totalLayout={template.views.length}
                             type={"page"}/>
                    </Grid>
                )
            }
        </Grid>
    }

    function createMyPageView() {
        if(!dataFetcher) return <></>
        // if(dataFetcher.getTotalRecords() === 0) return <></>;

        return <Grid key={`mypages_${formKey}`}container spacing={3} className={styles.TemplateRoot}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                <ViewItem id={null}
                          type={"view"}
                          storefronts={storefronts}
                          modifiedAt={0}
                          title={"Start from scratch"}
                          orgCode={orgCmdProp?.currentOrg!.orgCode!}
                          logo={"/images/view_add.png"}
                          doSelectView={ () => {}}/>
            </Grid>
            {   _.range(0,dataFetcher.getTotalRecords()).map(index => <Grid item xs={12} sm={12} md={6} lg={6} xl={4} key={`grid_list_${index}_${storefronts.length}`}>
                    <ViewItem
                        type={"view"}
                        storefronts={storefronts}
                        orgCode={orgCmdProp?.currentOrg!.orgCode!}
                        doSelectView={(id,title) => {
                            if(id !== null) {
                                history.push({
                                    pathname: `${history.location.pathname}/${title}/${id}`,
                                    search: history.location.search
                                })
                            }
                        }}
                        doDelete={() => {
                            setDataFetcher(new ViewDataFetcher(orgCmdProp!.currentOrg!,() => {
                                    dispatch(viewResetAction());
                                },
                                searchTerm
                            ));
                            setFormKey(nanoid());
                        }}
                        logo={`${process.env.REACT_APP_WEBC_HOST}/thumbnail/${orgCmdProp?.currentOrg!.orgCode!}/${dataFetcher?.getItemAt(index)['id']}.jpg` }
                        modifiedAt={dataFetcher?.getItemAt(index)['viewModifiedAt']}
                        id={dataFetcher?.getItemAt(index)['id']}
                        title={dataFetcher?.getItemAt(index)['viewName'] }
                        subTitle={format(new Date(dataFetcher?.getItemAt(index).viewModifiedAt),  'yyyy/MM/dd')}/>
                </Grid>
            )
            }
        </Grid>
    }

    function createGridView() {

        const searchButton = <SearchButton defaultValue={searchTerm} onChange={(value) => {
            setSearchTerm(value)
            dataFetcher?.setSearchTerm(value);
            setFormKey(nanoid());
        }}/>

        const uploadButton = <IconLinkButton tooltip={"Upload File"} key="uploadViewButton" id="uploadViewButton"
                                             data-action="uploadNewView" variant={"contained"}
                                             onClick={handleUploadDialog}>
            <OutlinedIcon className={styles.Icon}>cloud_upload</OutlinedIcon>
        </IconLinkButton>;

        return <React.Fragment key={`gridview_${formKey}`}>
            <div className={commonStyle.ContentContainer + " " + commonStyle.ContentContainerOverflowX}>
                {ssoCmd.roleName === "LSIADMIN"&& createDisplayList()}
                <PageSummary type={"pages"}/>
                <div className={styles.Root}>
                    <div className={styles.UploadPanelInGrid}>{searchButton} {uploadButton}</div>
                    <Tabs value={tab} onChange={(event,value) => {
                        localStorage.setItem("viewCurrentTab",value);
                        setTab(value)
                    }}>
                        <Tab label={"Templates"} value={"templates"}/>
                        <Tab label={"My Pages"} value={"mypages"}/>
                    </Tabs>
                    {tab === "templates" && createTemplateView()}
                    {tab === "mypages" && createMyPageView()}
                </div>
            </div>
        </React.Fragment>
    }

    function createDisplayList() {
        return <ToggleButtonGroup exclusive size="small"
                                  color={"primary"}
                                  onChange={(event, value) => {
                                      SetLocalItem("viewDisplayType",value);
                                      setDisplayType(value);
                                  }} value={displayType} className={styles.DisplayList}>
            <ToggleButton value={"listView"}><Tooltip title={"Table : Admin Only"}><span style={{lineHeight: "0px"}}><OutlinedIcon>list</OutlinedIcon></span></Tooltip></ToggleButton>
            <ToggleButton value={"gridView"}><Tooltip title={"Grid : Admin Only"}><span style={{lineHeight: "0px"}}><OutlinedIcon>grid_view</OutlinedIcon></span></Tooltip></ToggleButton>
        </ToggleButtonGroup>
    }

    function createView() {
        if(ssoCmd.roleName === "LSIADMIN") {
            if(displayType === "gridView") {
                return createGridView();
            } else {
                return createListView();
            }
        } else {
            return createGridView();
        }
    }

    function createListView() {

        const searchButton = <SearchButton defaultValue={searchTerm} onChange={(value) => {
            setSearchTerm(value)
            dataFetcher?.setSearchTerm(value);
        }}/>


        const newButton = <IconLinkButton tooltip={"Add New Page"} key="newViewButton" id="newViewButton"
                                          data-action="createNewView" variant={"contained"}
                                          onClick={handleOpenNewDialog}>
            <SolidIcon className={styles.Icon}>add_circle_outline</SolidIcon>
        </IconLinkButton>;

        const uploadButton = <IconLinkButton tooltip={"Upload File"} key="uploadViewButton" id="uploadViewButton"
                                             data-action="uploadNewView" variant={"contained"}
                                             onClick={handleUploadDialog}>
            <OutlinedIcon className={styles.Icon}>cloud_upload</OutlinedIcon>
        </IconLinkButton>;

        const deleteButton = <ReverseSolidLinkSmallButton id="deleteViewButton" data-action="deleteViews"
                                                          variant={"contained"} onClick={handleDelete}>
            <OutlinedIcon className={styles.Icon}>delete</OutlinedIcon>
            Remove ({deleteList.length})
        </ReverseSolidLinkSmallButton>;

        const downloadButton = <ReverseSolidLinkSmallButton id="downloadViewButton" data-action="downloadViews"
                                                            variant={"contained"} onClick={handleDownloadList}>
            <OutlinedIcon className={styles.Icon}>download</OutlinedIcon>Download ({deleteList.length})
        </ReverseSolidLinkSmallButton>;

        const templateButton = <ReverseSolidLinkSmallButton id="pageTemplateButton" data-action="pageTemplate"
                                                            variant={"contained"} onClick={handleCreateTemplate}>
            <OutlinedIcon className={styles.Icon}>favorite_border</OutlinedIcon>Create Pages Template ({deleteList.length})
        </ReverseSolidLinkSmallButton>;

        return <React.Fragment key={`listview_${formKey}`}>
            <div className={commonStyle.ContentContainer + " " + commonStyle.ContentContainerOverflowX}>
                {ssoCmd.roleName === "LSIADMIN" && createDisplayList()}
                <PageSummary type={"pages"}/>
                {newOpen &&
                    <InputDialog
                        id={"page"}
                        title={"New Page"}
                        handleClose={handleCloseNewDialog}
                        handleSave={handleSaveDialog}
                        open={newOpen}
                        fixHeight={false}
                        inserted={true}
                        fields={[
                            {id: "viewName", label: "Name", defaultValue: "", required: true, editable: true},
                        ]}
                        contentText={null}/>
                }
                {updateViewId !== "" && updateData.id &&
                    <InputDialog
                        id={"page"}
                        title={"Rename Page"}
                        handleClose={handleCloseUpdateDialog}
                        handleSave={handleUpdateDialog}
                        open={true}
                        fixHeight={false}
                        inserted={false}
                        fields={[
                            {
                                id: "viewName",
                                label: "Name",
                                defaultValue: updateData.viewName,
                                required: true,
                                editable: true
                            },
                        ]}
                        contentText={null}/>
                }
                <StyledTable key={formKey}
                             handleAction={handleAction}
                             handleDeleteLists={(ids) => setDeleteList(ids)}
                             hasCheckBox={true}
                             dataFetcher={dataFetcher}
                             rowsPerPage={20}
                             title={"Pages"}
                             actionComponents={[searchButton, uploadButton, newButton]}
                             deleteButton={deleteButton}
                             downloadButton={downloadButton}
                             templateButton={orgCmdProp?.currentOrg?.orgCode === process.env.REACT_APP_GLOBAL_ORGCODE && deleteList.length >= 1 ? templateButton : undefined}


                             page={parseInt((query.get("page") === null ? "0" : query.get("page")!))}
                             rowsPerPageOptions={[5, 10, 20, 40, 60, {label: "All", value: -1}]}/>
            </div>

        </React.Fragment>
    }

    if(hasCMSModule() === false) return <AccessDenied/>

    return <>
        {templateDialog && templateDialog.open && <PageTemplateDialog header={"Create Pages Template"}
                                                                      inserted={true}
                                                                      active={true}
                                                                      id={null}
                                                                      open={templateDialog ? templateDialog.open : false}
                                                                      viewIds={templateDialog ? templateDialog.viewIds: []}
                                                                      viewNames={templateDialog ? templateDialog.viewNames : []}
                                                                      viewData={templateDialog ? templateDialog.viewData : []}
                                                                      viewLogos={templateDialog ? templateDialog.viewLogos : []}
                                                                      internalComment={""}
                                                                      tags1={["all_concepts"]}
                                                                      allowOrgs={ssoCmd.roleName === "LSIADMIN" ? ["all_orgs"] : [ssoCmd.orgCode]}
                                                                      title={""}
                                                                      subTitle={""}
                                                                      logo={""}
                                                                      onClose={
                                                                          () => {

                                                                              setTemplateDialog({
                                                                                  open: false,
                                                                                  viewIds: [],
                                                                                  viewNames: [],
                                                                                  viewData: [],
                                                                                  viewLogos: []
                                                                              })
                                                                              setDeleteList([]);
                                                                              setFormKey(nanoid());
                                                                          }
                                                                      }
        />
        }
        { createView() }

        <input key={`upload_${formKey}`} type='file' id='upload-content'
               accept=".gzip,.gz"
               onChange={() => doUploadFile()}
               ref={inputFile} style={{display: 'none'}}/>
    </>

}
