import {Route} from "react-router-dom";
import React from 'react';
import {Redirect} from "react-router";
import client, {useSSO} from "../../datafetcher/HttpClient";
import {findRolesFromPath} from "../../config/CMSMainMenuConfig";
import {TrackData} from "../../util/TrackUtil";
import SSOCmd from "../../model/lsi/SSOCmd";
import ResponseCmd from "../../cms/model/ReponseCmd";
import {AxiosResponse} from "axios";
import {loginAction} from "../../redux/features/login/loginSlice";
import {useAppDispatch} from "../../redux/hook";


export default function ProtectedRoute (props:any) {
    const ssoCmd = useSSO();
    let dispatch = useAppDispatch();
    if(props.isLogin) {
        if(props.path === "/home") {
            return <Route {...props}/>;
        } else {
            let roles = findRolesFromPath(props.path);
            if (roles.indexOf(ssoCmd.roleName) >= 0) {
                return <Route {...props}/>;
            } else {
                return <Redirect to={{pathname: '/home'}}/>
            }
        }
    } else {
        if(props.location.pathname.startsWith("/live")) {
            let temp= client.refreshSSO();
            props.doShowMenu(false);
            props.doShowFooter(false);
            if(temp !== undefined) {
                temp.then((axiosResponse: AxiosResponse<any>) => {

                    let responseCmd: ResponseCmd<SSOCmd> = axiosResponse.data;
                    let ssoCmd = responseCmd.response;
                    if(ssoCmd.verify) {
                        dispatch(loginAction({
                            userName: ssoCmd.name,
                            userProfileImage: ""
                        }));
                        TrackData("Login", {
                            userId: ssoCmd.email,
                            userName: ssoCmd.name,
                            role: ssoCmd.roleName,
                            orgCode: ssoCmd.orgCode
                        })
                        props.doLogin(true);
                    }
                });
            }
            return <></>
        } else {
            return <Redirect to={{pathname: '/login', state: {from: props.location.pathname}}}/>
        }
    }
}