

export function CaptureDataToFile(data: string,name: string, callback: (file:File) => void) {
    fetch(data)
        .then(res => res.blob())
        .then(blob => {
            const file = new File([blob], name,{ type: "image/jpg" })
            callback(file);
        })

}
export function CaptureLayout(topEditor: any,callback: (data:string) => void ) {

    if(!topEditor) {
        callback("");
        return;
    }
    // @ts-ignore
    let html2canvas:any = window['html2canvas'];
    if(html2canvas) {
        let imgs = topEditor.querySelectorAll("img");
        imgs.forEach((img:any) => {
            img.setAttribute("data-img-src", img.src);
            img.src = `https://cdn.lsicloud.net/txlsi/${img.src}`;
        })
        setTimeout(() => {
            html2canvas(topEditor, {
                useCORS: true,
                scrollX: 0,
                scrollY: 0,
                logging: false,
                height: topEditor?.clientWidth
            }).then((canvas: any) => {
                let smallCanvas = document.createElement("canvas");
                const dpr = window.devicePixelRatio
                smallCanvas.width = 350 * dpr;
                smallCanvas.height = 350 * dpr;

                let ctx: any = smallCanvas.getContext("2d", {alpha: false, antialias: true});
                ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, smallCanvas.width, smallCanvas.height);

                let data = smallCanvas.toDataURL("image/jpg", 0.8);
                callback(data);

                imgs.forEach((img:any)=> {
                    img.src = img.getAttribute("data-img-src")!;
                    img.removeAttribute("data-img-src");
                })
            });
        }, 100);
    }
}