import {useAppSelector} from "../../../../redux/hook";
import {selectViewState} from "../../../../redux/features/view/viewSlice";
import styles from "./Text.module.scss";
import {useContext, useRef, useState} from "react";
import EditablePanel from "../../shared/editable/EditablePanel";
import {DeleteType} from "../../../model/DeleteOption";
import {lorem} from "../../shared/lorem/lorem";
import {StyleUtil} from "../../setting/StyleUtil";
import {SettingGroupType, SettingType} from "../../../model/SettingOption";
import {nanoid} from "nanoid";
import TinyEditor from "../../../../ui/editor/TinyEditor";
import {Dialog, DialogContent, DialogTitle, IconButton} from "@mui/material";
import useWidgetState from "../../../../hook/useWidgetState";
import OutlinedIcon from "../../../../ui/icon/OutlinedIcon";
import {DEFAULT_PADDING_INLINE,DEFAULT_PADDING_BLOCK} from "../../../util/UIFactory";
import MonacoEditor from "../../../../ui/editor/MonacoEditor";
import {useSSO} from "../../../../datafetcher/HttpClient";
import VariableContext from "../../../../context/VariableContext";
import {GetPortalHostName} from "../../../../ui/popover/PortalEnvironmentPopover";

export default function Text(props) {
    const widgetData = props.data;
    const {widgetId, widgetProps} = widgetData;
    const viewState = useAppSelector(selectViewState);
    const [opened,setOpened] = useState({active: false, html: false});
    const [refreshId,setRefreshId] = useState(nanoid());
    const textRef = useRef();
    const sso = useSSO();
    const variableContext = useContext(VariableContext);
    useWidgetState(widgetId,(event) => {
        setRefreshId(nanoid());
    })

    if(!widgetData.widgetProps['html']) { // default content
        widgetData.widgetProps['html'] = lorem.sentencesWithHeader(4);
    }

    //if(!widgetData.widgetProps['text']) { // default style
    if(StyleUtil.isDefaultStyle(widgetProps['bt_text'])) {
        widgetData.widgetProps['bt_text'] = {
            style: {
                default: true,
                padding: {
                    'padding-inline-start': DEFAULT_PADDING_INLINE,
                    'padding-inline-end': DEFAULT_PADDING_INLINE,
                    'padding-block-start': DEFAULT_PADDING_BLOCK,
                    'padding-block-end': DEFAULT_PADDING_BLOCK
                }
            }
        }
    }

    const themeName = "text";
    const widgetStyles = {
        theme: StyleUtil.toThemeNameByType(widgetProps, themeName),
        current: StyleUtil.toClassFromWidget(widgetId , widgetProps, "current_style"),
        text: StyleUtil.toClassFromWidget(widgetId , widgetProps, "bt_text")
    }

    const createText = (props,edit) => {
        let newProps = {...props};
        delete newProps.uiType;
        delete newProps.onHandleSwap;
        delete newProps.onHandleDeletion;
        delete newProps.onHandleSetting;
        delete newProps.onHandleAddNewSectionOrWidget;
        delete newProps.uiLevelStr;
        delete newProps.onHandleUpdate;
        delete newProps.uiLevel;
        delete newProps.sharedObjects;;
        delete newProps.onHandleCut;
        delete newProps.onHandleCopy;
        delete newProps.onHandleClone;
        delete newProps.onHandleCreateTemplate


        return <><div key={refreshId}
                    ref={textRef}
                    // style={viewState.isEdit ? {pointerEvents: "none"}: undefined}
                    {...newProps}
                    data-ui-type={props.uiType}
                    className={StyleUtil.combineClasses(styles.Root,widgetStyles.theme,widgetStyles.current,widgetStyles.text)}

                    dangerouslySetInnerHTML={{__html: variableContext.updateVariables(widgetProps['html'])}}>

        </div>
            {edit && <div style={{position: "absolute", top: "0px", bottom: "0px",left: "0px",right: "0px", zIndex: "1"}} onClick={handleOnClick}/>}
        </>
    }

    const handleOnClick = (event) => {
        if(!opened.active && event.detail === 2) {
            let drawer = document.getElementById("top_editor").getAttribute("data-showdrawer");
            if(drawer !== "true") {
                if (event.altKey) {
                    if (sso.roleName === "LSIADMIN") {
                        setOpened({active: true, html: true});
                    } else {
                        setOpened({active: true, html: false});
                    }
                } else {
                    setOpened({active: true, html: false});
                }
                event.stopPropagation();
                event.preventDefault()
            }
        }

    }

    const disableDoubleClickDuringEdit = (event) => {
       event.stopPropagation();
       event.preventDefault();
    }

    const GetEditorStyle = () => {
        let bodyStyle = StyleUtil.queryCMSStyle(widgetStyles.text);
        bodyStyle = bodyStyle.replaceAll("." + widgetStyles.text,"body");
        return bodyStyle;
    }

    let deleteOption = {type: DeleteType.delete_with_rearrange, tag: null};
    let suggestTags = ["current"];
    let settingOptions = [
        { type: SettingType.advance_background, group: "bt_text", groupType: SettingGroupType.style},
        { type: SettingType.dimension, group: "bt_text", groupType: SettingGroupType.style},
        { type: SettingType.typography, group: "bt_text", groupType: SettingGroupType.style},
        { type: SettingType.border, group: "bt_text", groupType: SettingGroupType.style},
        { type: SettingType.border_radius, group: "bt_text", groupType: SettingGroupType.style},
        { type: SettingType.box_shadow, group: "bt_text", groupType: SettingGroupType.style},

        { type: SettingType.margin,  group: "bt_text", groupType: SettingGroupType.advanced},
        { type: SettingType.padding, group: "bt_text", groupType: SettingGroupType.advanced},
        { type: SettingType.width, group: "bt_text", groupType: SettingGroupType.advanced},
        { type: SettingType.height, group: "bt_text", groupType: SettingGroupType.advanced},
        { type: SettingType.align,  group: "bt_text", groupType: SettingGroupType.advanced},
        { type: SettingType.position,  group: "bt_text", groupType: SettingGroupType.advanced},
        { type: SettingType.text_align,  group: "bt_text", groupType: SettingGroupType.layout},
    ];
    let settingGroups = [
        {group: "bt_text", groupName: "General"}
    ];

    if(opened.active) {
        return <EditablePanel  className={styles.Root}
                              {...props}
                                themeName={themeName}
                                suggestTags={suggestTags}
                                settingGroups={settingGroups}
                                settingOptions={settingOptions}
                                deleteOption={deleteOption}>
            <div key={refreshId} onDoubleClick={disableDoubleClickDuringEdit} onClick={disableDoubleClickDuringEdit} >
                {createText({},true)}
                {/*//{onClick: handleOnClick})}*/}
                <Dialog open={opened.active}
                        onClose={() => {setOpened({active: false,html: false})}}
                        PaperProps={{ sx: { width: "200%"} }}
                        disableEnforceFocus={true}
                >
                    <DialogTitle>
                        {opened.html && "Detail editor"}
                        {opened.html === false && "Editor"}
                        <IconButton className={styles.CloseIcon}
                                    onClick={() => { setOpened({active: false,html: false}) }}>
                            <OutlinedIcon>close</OutlinedIcon>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        {opened.html && <MonacoEditor language={"html"}
                                                      defaultValue={widgetData.widgetProps.html}
                                                      onChange={(value) => {
                                                          widgetData.widgetProps.html = value;
                                                          textRef.current.innerHTML = value;
                                                        }
                                                      }
                            />}
                        {opened.html === false && <TinyEditor initialValue={widgetData.widgetProps.html}
                                    onEditorChange={(value, editor) => {
                                        widgetData.widgetProps.html = value;
                                        textRef.current.innerHTML = value;
                                    }}
                                    init={{
                                        height: 300,
                                        content_style : GetEditorStyle(),
                                        content_css: [
                                            `https://${GetPortalHostName(variableContext.siteCode)}/themes/${variableContext.portalThemeName}/css/main.css?name=${nanoid()}`,
                                            `https://${GetPortalHostName(variableContext.siteCode)}/themes/${variableContext.portalThemeName}/css/responsive.css?name=${nanoid()}`,
                                        ],

                                        // body_class: StyleUtil.combineClasses(styles.TextEditor,widgetStyles.current,widgetStyles.text),
                                        init_instance_callback: (editor) => {
                                            // editor.on('blur', (e) => {
                                            //     setEditable(false);
                                            //     props.onHandleUpdate(widgetData.widgetId);
                                            // });
                                            editor.focus();
                                            props.onHandleUpdate(widgetData.widgetId);
                                    }}}
                        />}
                    </DialogContent>
                </Dialog>
            </div></EditablePanel>
    } else {
        if(viewState.isEdit) {
            return <EditablePanel
                                    className={styles.Root}
                                  {...props}
                                  themeName={themeName}
                                  suggestTags={suggestTags}
                                  settingGroups={settingGroups}
                                  settingOptions={settingOptions}
                                  deleteOption={deleteOption}>
                {createText({},true)}
                {/*//{onClick: handleOnClick})}*/}
            </EditablePanel>
        } else {
            return createText(props,false)
        }
    }
}
