import React, {useEffect} from 'react';
import styles from "./AppFooter.module.scss";
import {Typography} from "@mui/material";
import SalesforceLink from "../button/SalesforceLink";
import TrainingLink from "../button/TrainingLink";
import {useLocation} from "react-router";
import {isEditorPath} from "../../util/PathUtil";
import {useSSO} from "../../datafetcher/HttpClient";


export default function AppFooter() {
    const year = new Date().getFullYear();
    const location = useLocation();
    const sso = useSSO();
    useEffect(() => {
        let store = document.querySelector(':root') as HTMLElement;
        if(isEditorPath(location.pathname)) {
            store.style.setProperty('--bt-cms-footer-height', '0px');
        } else {
            store.style.setProperty('--bt-cms-footer-height', 'var(--bt-cms-org-footer-height)');
        }
    },[location]);
    return <React.Fragment>
            <footer className={styles.footer_root}>
                <div className={styles.footer_panel}>
                    <div className={styles.left}>
                        <ul>
                            <li><Typography className={styles.detail_label}> &copy; { year } Billtrust ({sso.buildVersion})</Typography></li>
                            <li><a href={"https://secure.billtrust.com/factorsystems/ig/subscriber-agreement"}  rel="noreferrer"  target={"_blank"} className={styles.version_label + " " + styles.hideable_panel}>Terms of Service</a></li>
                            <li><a href={"https://www.billtrust.com/privacy-policy/"}  rel="noreferrer"  target={"_blank"} className={styles.version_label + " " + styles.hideable_panel}>Privacy</a></li>
                        </ul>
                    </div>
                    <div className={styles.right}>
                        <ul>
                            <li><TrainingLink className={styles.version_label + " " + styles.hideable_panel} title={"Training"}/></li>
                            <li><SalesforceLink className={styles.version_label + " " + styles.hideable_panel} title={"Support"}/></li>
                            <li><a href={"mailto:cloudlsi-support@billtrust.com"}  rel="noreferrer"  className={styles.version_label + " " + styles.hideable_panel}>Contact Us</a></li>
                        </ul>
                    </div>
                </div>
            </footer>
        </React.Fragment>;
}
