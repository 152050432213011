import {TextFieldProps} from "@mui/material/TextField/TextField";
import {Autocomplete, Input, TextField} from "@mui/material";
import {InputOption} from "../../model/InputModel";
import {useState} from "react";

interface ChipProp {
    inputProps: TextFieldProps;
    chips: string[],
    options: InputOption[];
    onChange: (values: string[]) => void
}

function matchChips(options: InputOption[], chips:string[]) {
    let rValues = [];
    for(let chip of chips) {
        let index = options.findIndex(option => option.value === chip)
        rValues.push(options[index]);
    }
    return rValues;
}

export default function ChipField(props:ChipProp) {
    const [value,setValue] = useState<InputOption[]>(matchChips(props.options,props.chips));
    return <Autocomplete
                            autoHighlight
                            multiple
                            filterSelectedOptions
                            size={"small"}
                            options={props.options}
                            value={value}
                            getOptionLabel={(option) => option? option.label : ""}
                            onChange={(event,value) => {
                                    setValue(value);
                                    let rValue = [];
                                    for(let x of value) {
                                        rValue.push(x.value);
                                    }
                                    props.onChange(rValue);
                                }
                            }
                            renderInput={(params) => <TextField variant="outlined"{...params} {...props.inputProps} fullWidth/>}/>
}