import {Icon} from "@mui/material";
import {IconProps} from "@mui/material/Icon/Icon";
import styles from "./Icon.module.scss";

export default function OutlinedIcon(props:IconProps & {pointerEventEnabled?: boolean,
    flipY?: boolean, rotate?: number, flipX?: boolean}) {
    let style = styles.Root;
    if(props.pointerEventEnabled) {
        style = "";
    }
    if(props.className && style !== "") {
        style = " " + props.className;
    }
    let flipStyle:any = props.flipY ? {transform: "scaleY(-1)", verticalAlign: "bottom"} : undefined;
    if(props.flipX) {
        flipStyle = {...flipStyle, transform: "scaleX(-1)"};
    }
    let rotateStyle = props.rotate ? {transform: `rotate(${props.rotate}deg)`} : undefined;
    let newProps = {...props}
    delete newProps.flipY;
    delete newProps.flipX;
    return <Icon baseClassName={"material-icons-outlined"} {...newProps} className={style} style={{...flipStyle,...rotateStyle}}/>
}
