
const base36Chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";

export function CapitalizeString(str: string): string {
    if(str.length === 0) {
        return str;
    }
    return str.charAt(0).toUpperCase() +  str.slice(1).toLowerCase();
}

export function NumberToString36(b10:number): string {
    let ret = "";
    if (b10 < 0) {
        return ret;
    }
    while (b10 > 0.01) {
        ret = base36Chars.charAt(Math.floor(b10 % 36)) + ret;
        b10 = Math.floor(b10/36);


    }
    return ret;
}

export function String36ToNumber(b36:string):number {
    let ret:number = 0;
    let count = 1;
    for(let i=b36.length-1;i>=0;i--) {
        let character = b36.charAt(i);
        let index = base36Chars.indexOf(character);
        if(index >= 0) {
            ret += index * count;
        } else { // not support
            return 0;
        }
        count *= 36;
    }
    return ret;
}

export function FieldNameToName(name: string): string {
    if(name.endsWith("_ATT")) {
        name = name.substring(0,name.length-4);
    }
    return name.replaceAll("_"," ");

}

export function camelCase(name: string): string {
    const words = name.split(" ");
    const array:string[] = [];
    words.forEach(word => {
        if(word.length > 0) {
            array.push(word.charAt(0).toUpperCase() + word.substring(1))
        } else {
            array.push("");
        }
    })
    return array.join(" ");
}

