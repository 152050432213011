import {Grid, TextField} from "@mui/material";
import React, {useState} from "react";
import {SettingLabel} from "../../setting/settinglabel/SettingLabel";


export default function LoginSetting(props: { widgetData: any, saveWidgetState: () => void }) {
    const {widgetData} = props;
    const {widgetProps} = widgetData;
    const [errorLabel,setErrorLabel] = useState<string>(widgetProps['errorLabel'] && widgetProps['content']['errorLabel']  ? widgetProps['content']['errorLabel'] : "Your login was unsuccessful. Please try again.");
    const [passwordLabel,setPasswordLabel] = useState<string>(widgetProps['content'] && widgetProps['content']['passwordLabel']  ? widgetProps['content']['passwordLabel'] : "Password:");
    const [loginLabel,setLoginLabel] = useState<string>(widgetProps['content'] && widgetProps['content']['userLabel']  ? widgetProps['content']['userLabel'] : "User ID:");
    const [forgetLabel,setForgetLabel] = useState<string>(widgetProps['content'] && widgetProps['content']['forgetLabel']  ? widgetProps['content']['forgetLabel'] : "Forget Your Password?");
    const [forgetLinkLabel,setForgetLinkLabel] = useState<string>(widgetProps['content'] && widgetProps['content']['forgetLinkLabel']  ? widgetProps['content']['forgetLinkLabel'] : "We can help you reset it");
    const [forgetLinkURL,setForgetLinkURL] = useState<string>(widgetProps['content'] && widgetProps['content']['forgetLinkURL']  ? widgetProps['content']['forgetLinkURL'] : "/password");
    const [destinationURL,setDestinationURL] = useState<string>(widgetProps['content'] && widgetProps['content']['destinationURL']  ? widgetProps['content']['destinationURL'] : "/home");

    const handleChange = (value: any, key: string) => {
        widgetData.widgetProps["content"][key] = value;
        props.saveWidgetState();
    }

    return <Grid container spacing={2}>
        <Grid item xs={4}>
            <SettingLabel title={"Login Label"}/>
        </Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={""}
                       defaultValue={loginLabel}
                       onChange={(event) => {
                           setLoginLabel(event.target.value);
                           handleChange(event.target.value, "loginLabel")
                       }}/>
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"Password Label"}/>
        </Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={""}
                       defaultValue={passwordLabel}
                       onChange={(event) => {
                           setPasswordLabel(event.target.value);
                           handleChange(event.target.value, "passwordLabel")
                       }}/>
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"Error Label"}/>
        </Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={""}
                       defaultValue={errorLabel}
                       onChange={(event) => {
                           setErrorLabel(event.target.value);
                           handleChange(event.target.value, "errorLabel")
                       }}/>
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"Forget Label"}/>
        </Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={""}
                       defaultValue={forgetLabel}
                       onChange={(event) => {
                           setForgetLabel(event.target.value);
                           handleChange(event.target.value, "forgetLabel")
                       }}/>
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"Forget Link Label"}/>
        </Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={""}
                       defaultValue={forgetLinkLabel}
                       onChange={(event) => {
                           setForgetLinkLabel(event.target.value);
                           handleChange(event.target.value, "forgetLinkLabel")
                       }}/>
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"Forget URL"}/>
        </Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={""}
                       defaultValue={forgetLinkURL}
                       onChange={(event) => {
                           setForgetLinkURL(event.target.value);
                           handleChange(event.target.value, "forgetLinkURL")
                       }}/>
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"Destination URL"}/>
        </Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={""}
                       defaultValue={destinationURL}
                       onChange={(event) => {
                           setDestinationURL(event.target.value);
                           handleChange(event.target.value, "destinationURL")
                       }}/>
        </Grid>
    </Grid>
}