export interface TableLinkModel {
    icon: string,
    pathname: string,
    label: string,
    search: any,
    action?: { tag: string, tagObject: any  },
}
export class TableModel {

    private _title: string;
    private _id: string;
    private _link: string;
    private _subtitle: string;
    private _options?: TableLinkModel[];
    private _component?: JSX.Element;
    private _select?: (id: string) => void ;

    constructor(id: string,
                title: string,
                subTitle: string,
                link: string = "",
                select?: (id: string) => void,
                options?: TableLinkModel[]) {
        this._title = title;
        this._subtitle = subTitle;
        this._id = id;
        this._link = link;
        this._options = options;
        this._select = select;
    }

    get options(): TableLinkModel[] | undefined {
        return this._options;
    }

    get select(): ((id: string) => void) | undefined {
        return this._select;
    }

    get component(): JSX.Element | undefined {
        return this._component;
    }

    set component(value:JSX.Element | undefined) {
        this._component = value;
    }


    get link(): string {
        return this._link;
    }

    set link(value: string) {
        this._link = value;
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get subTitle(): string {
        return this._subtitle;
    }

    set subTitle(value: string) {
        this._subtitle = value;
    }


    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value;
    }
}