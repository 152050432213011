import {WidgetInterface} from "../../../model/WidgetInterface";
import {useAppSelector} from "../../../../redux/hook";
import {selectViewState} from "../../../../redux/features/view/viewSlice";
import styles from "./ProductList.module.scss";
import React, {useContext, useEffect, useRef, useState} from "react";
import {DeleteType} from "../../../model/DeleteOption";
import {SettingGroupType, SettingOption, SettingType} from "../../../model/SettingOption";
import EditablePanel from "../../shared/editable/EditablePanel";
import {lsi} from "../../../../datafetcher/LSIDataFetcher";
import {StyleUtil} from "../../setting/StyleUtil";
import useWindowSize from "../../../hook/useWindowSize";
import SVGArrow from "../../shared/svgarrow/SVGArrow";
import {nanoid} from "nanoid";
import {useOrganization} from "../../../../hook/useOrganization";
import useWidgetState from "../../../../hook/useWidgetState";
import {CardCmd} from "../../../model/CardCmd";
import VariableContext from "../../../../context/VariableContext";


export default function ProductList(props:any) {
    const widgetData:WidgetInterface = props.data as WidgetInterface;
    const {widgetId,widgetProps} = widgetData;
    const viewState = useAppSelector(selectViewState);
    const orgCmd = useOrganization();
    const variableContext = useContext(VariableContext);
    //const [searchType, setSearchType] = useState<string>("");
    const [products, setProducts] = useState<{[key: string] : any}[]>([]);
    const windowSize = useWindowSize();
    const rootRef = useRef<HTMLDivElement>(null);
    const [overFlow,setOverFlow] = useState<Boolean>(false);
    const [refreshId,setRefreshId] = useState(nanoid());

    useWidgetState(widgetId,(event) => {
        if(event && event.detail) {
            if(event.detail.action === "products") {
                setProducts(event.detail.value);
            } else {
                setRefreshId(nanoid());
            }
        } else {
            setRefreshId(nanoid());
        }
    })


    if(!widgetProps['content']) {
        widgetProps['content'] = {
            searchType: "BY_IDS",
            searchString: "",
            maxItems: "5",
            title: "",
            searchProfiledId: -1
        }
    }

    if(!widgetProps['content']['titleField']) {
        widgetProps['content']['imageField'] = "_lsi_cn_ImageThumb";
        widgetProps['content']['descField'] = "_lsi_cn_Description";
        widgetProps['content']['titleField'] = "_lsi_cn_ProductID";
        widgetProps['content']['priceField'] = "_lsi_cn_Price";
        widgetProps['content']['uomField'] = "_lsi_cn_PriceUOM";
    }

    if(StyleUtil.isDefaultStyle(widgetProps['panel'])) {
        widgetProps['panel'] = {
            style: {
                default: true,
                flex: {
                    'justify-content': 'center'
                },
                position: {
                    display: "flex",
                },
                dimension: {
                    'width': "100%"
                },
            }
        };
    }


    if(StyleUtil.isDefaultStyle(widgetProps['title'])) {
        widgetProps['title'] = {
            style: {
                default: true,
                typography: {
                    'font-size': '24px',
                    // 'font-weight': 'bold'
                },
                dimension: {
                    width: "100%"
                },
                padding: {
                    'padding-block-start': "8px",
                    'padding-block-end': "8px",
                    'padding-inline-start': '16px',
                    'padding-inline-end': '16px',
                }
            }
        };
    }

    if(StyleUtil.isDefaultStyle(widgetProps['card_panel'])) {
        widgetProps['card_panel'] = {
            style: {
                default: true,
                position: {
                    display: "flex",
                    'flex-direction': 'column'
                },
                dimension: {
                    'min-width': "200px",
                    'min-height': "270px",
                    'max-width': "200px",
                },
                background: {

                }
            }
        };
    }

    if(StyleUtil.isDefaultStyle(widgetProps['card_icon'])) {
        widgetProps['card_icon'] = {
            style: {
                default: true,
                position: {
                    display: "block"
                },
                dimension: {
                    width: "100%",
                    height: "150px",
                    'max-height': "150px",
                },
                background: {
                    'background-color': 'transparent',
                },
                object: {
                    'object-fit': 'contain'
                }
            }
        };
    }

    if(StyleUtil.isDefaultStyle(widgetProps['card_title'])) {
        widgetProps['card_title'] = {
            style: {
                default: true,
                position: {
                    display: "block"
                },
                dimension: {
                    width: "100%",
                },
                typography: {
                    'font-weight' : "bold"
                },
                padding: {
                    'padding-block-start': "8px",
                    'padding-block-end': "8px",
                    'padding-inline-start': '16px',
                    'padding-inline-end': '16px',
                }

            }
        };
    }

    if(StyleUtil.isDefaultStyle(widgetProps['card_desc'])) {
        widgetProps['card_desc'] = {
            style: {
                position: {
                    display: "block"
                },
                dimension: {
                    width: "100%",
                },
                typography: {
                    // 'font-weight' : "bold"
                },
                padding: {
                    'padding-block-start': "8px",
                    'padding-block-end': "8px",
                    'padding-inline-start': '16px',
                    'padding-inline-end': '16px',
                }
            }
        };
    }

    let datasource = widgetProps['content']['datasource'] ? widgetProps['content']['datasource'] : "CUSTOM";
    const themeName = "product_list";
    const widgetStyles = {
        theme: StyleUtil.toThemeNameByType(widgetProps, themeName),
        current: StyleUtil.toClassFromWidget(widgetId , widgetProps, "current_style"),
        panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "panel"),
        title: StyleUtil.toClassFromWidget(widgetId, widgetProps, "title"),
        card_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "card_panel"),
        card_icon: StyleUtil.toClassFromWidget(widgetId , widgetProps, "card_icon"),
        card_title: StyleUtil.toClassFromWidget(widgetId , widgetProps, "card_title"),
        card_desc: StyleUtil.toClassFromWidget(widgetId , widgetProps, "card_desc"),
    }

    useEffect(() => {
        if(windowSize && rootRef !== null && rootRef.current !== null) {
            let rootElement = rootRef.current!;
            let rootBoundary = rootElement.getBoundingClientRect();
            let expectedOverflow = false;
            for (let i = 0; i < rootElement.children.length; i++) {
                let childBoundary = rootElement.children[i].getBoundingClientRect();
                let relative_x = childBoundary.x - rootBoundary.x;
                if (relative_x + childBoundary.width > rootBoundary.width) {
                    expectedOverflow = true;
                    break;
                }
            }
            if (overFlow !== expectedOverflow) {
                setOverFlow(expectedOverflow);
            }
        }
    },[windowSize, rootRef, products]);

    useEffect(() => {
        // useEffect(() => {
        if(products.length === 0) {
            let rValue = lsi.search.widgetSearch(
                widgetProps['content']['searchType'],
                widgetProps['content']['searchString'],
                widgetProps['content']['start'],
                widgetProps['content']['maxItems'],
                widgetProps['content']['searchProfile']
            );
            if (rValue !== null) {
                rValue?.then((response) => {
                    setProducts(response.data.response.docs);
                });
            }
        }
        // },[]);
    },[]);

    function createProductsFromSearch(imageField:string, titleField:string, descField:string, priceField:string, uomField:string) {
        return  products.length > 0 && products.map((product,index) =>
            <div key={"product_" + index}>
                <div className={StyleUtil.combineClasses(styles.CardPanel,"pd_panel",widgetStyles.card_panel)}>
                    <div className={StyleUtil.combineClasses("pd_title",widgetStyles.card_title)}>{product[titleField]}</div>
                    <img className={StyleUtil.combineClasses(styles.CardImage,"pd_image",widgetStyles.card_icon)} src={variableContext.updateVariables(product[imageField])}/>
                    <div className={StyleUtil.combineClasses("pd_desc",widgetStyles.card_desc)}>{product[descField]} </div>
                    {!product[uomField] && <div className={StyleUtil.combineClasses("pd_desc",widgetStyles.card_desc)}>{product[priceField]}</div>}
                    {product[uomField] && <div className={StyleUtil.combineClasses("pd_desc",widgetStyles.card_desc)}>{product[priceField]}/{product[uomField]}</div>}
                </div>
            </div>
        );
    }

    function createProductsFromCards(cards: CardCmd[]) {
        return  cards && cards.length > 0 && cards.map((card,index) => {
                let hasImageLink =  card.image_href && card.image_href !== "";
                // did not allow click on image during edit.
                if(viewState.isEdit) {
                    hasImageLink = false;
                }
                let openLink = widgetProps['content']['openLink'];
                if(openLink === null) {
                    openLink = "_self";
                }
                return <div key={"product_" + index}>
                    <div className={StyleUtil.combineClasses(styles.CardPanel,"pd_panel",widgetStyles.card_panel)}>
                        <div className={StyleUtil.combineClasses("pd_title",widgetStyles.card_title)}>{card.title}</div>
                        { hasImageLink && <a href={variableContext.updateVariables(card.image_href)} target={openLink}><img className={StyleUtil.combineClasses(styles.CardImage,"pd_image",widgetStyles.card_icon)} src={variableContext.updateVariables(card.image)}/></a>}
                        { !hasImageLink && <img className={StyleUtil.combineClasses(styles.CardImage,"pd_image",widgetStyles.card_icon)} src={variableContext.updateVariables(card.image)}/>}
                        <div className={StyleUtil.combineClasses("pd_desc",widgetStyles.card_desc)} dangerouslySetInnerHTML={{__html: card.description}}></div>
                    </div>
                </div>
            }
        );
    }

    const createArrow = () => {
        if(overFlow) {
            return <>
                <div className={StyleUtil.combineClasses(styles.NextButton,"pl_arrow_right")}  onClick={() => {
                        let rootElement = rootRef.current!;
                        let childRect = rootElement.children[0].getBoundingClientRect();

                        rootRef.current!.scrollBy({top:0, left: childRect.width, behavior: 'smooth'})
                    }
                }
                        >
                    <SVGArrow width={30} height={70} strokeWidth={4} strokeColor={"white"} variant="right" />
                </div>
                <div className={StyleUtil.combineClasses(styles.BackButton,"pl_arrow_left")} onClick={() => {
                    let rootElement = rootRef.current!;
                    let childRect = rootElement.children[0].getBoundingClientRect();
                    rootRef.current!.scrollBy({top:0, left: -childRect.width, behavior: 'smooth'})
                }
                }
                    ><SVGArrow width={30} height={70} strokeWidth={4} strokeColor={"white"} variant={"left"}/>
                </div>
            </>;
        } else {
            return <></>;
        }
    }

    function renderProductListFromChildren(title: string, children: false | JSX.Element[], allowArrow: boolean) {
        if (!title) {
            return <div className={styles.AnchorPanel} key={refreshId}>
                <div ref={rootRef}
                     className={StyleUtil.combineClasses(styles.Root, widgetStyles.theme, widgetStyles.current, widgetStyles.panel)}
                     style={{justifyContent: overFlow ? "start" : undefined}}>
                    {children}
                    {allowArrow && createArrow()}
                </div>
            </div>
        } else {
            return <div style={{width: "100%"}} key={refreshId}>
                <div className={StyleUtil.combineClasses("pl_title", widgetStyles.title)}>{title}</div>
                <div className={styles.AnchorPanel}>
                    <div ref={rootRef}
                         className={StyleUtil.combineClasses(styles.Root, widgetStyles.theme, widgetStyles.current, widgetStyles.panel)}
                         style={{justifyContent: overFlow ? "start" : undefined}}>
                        {children}
                        {allowArrow && createArrow()}
                    </div>
                </div>
            </div>
        }
    }

    const createProductList = (allowArrow: boolean) => {

        if(datasource === "CUSTOM") {
            let title = widgetProps['content']['title'];
            let cards = widgetProps['content']['cards'];
            let children = createProductsFromCards(cards);
            return renderProductListFromChildren(title, children, allowArrow);
        } else if(datasource === "CATALOG" && products && products.length > 0) {
            let imageField = widgetProps['content']['imageField'];
            let titleField = widgetProps['content']['titleField'];
            let descField = widgetProps['content']['descField'];
            let priceField = widgetProps['content']['priceField'];
            let uomField = widgetProps['content']['uomField'];
            let title = widgetProps['content']['title'];

            let children = createProductsFromSearch(imageField, titleField, descField, priceField, uomField);

            return renderProductListFromChildren(title, children, allowArrow);
        } else {
            let title = widgetProps['content']['title'];
            if(!title) {
                return <div ref={rootRef} className={StyleUtil.combineClasses(styles.Root, widgetStyles.theme, widgetStyles.current, widgetStyles.panel)}
                            style={{justifyContent: overFlow ? "start" : "unset"}}>
                </div>
            } else {
                return <div ref={rootRef} className={StyleUtil.combineClasses(styles.Root, widgetStyles.theme, widgetStyles.current, widgetStyles.panel)}
                            style={{justifyContent: overFlow ? "start" : "unset"}}>
                    <div className={StyleUtil.combineClasses("pl_title",widgetStyles.title)}>{title}</div>
                </div>
            }
        }
    }

    if(!orgCmd || !orgCmd.orgDetail) {
        return <></>
    }

    if(viewState.isEdit) {
        let deleteOption = {type: DeleteType.delete_with_rearrange, tag: null};
        const suggestTags: string[] = [
            "current",
            "current .pl_title",
            "current .pd_panel",
            "current .pd_image",
            "current .pd_title",
            "current .pd_desc",
            "current .pl_arrow_left",
            "current .pl_arrow_right",
        ]

        let settingOptions:SettingOption[] = [
            { title: "", type: SettingType.widget,group: "content",
                editor: "productlistsetting",
                additions: {
                    displayOptions: orgCmd?.orgDetail?.displayFieldOptions,
                    imageOptions: orgCmd?.orgDetail?.imageFieldOptions
                }, groupType: SettingGroupType.layout},
            { type: SettingType.flex, group: "panel", groupType: SettingGroupType.style},
            { type: SettingType.dimension, group: "panel", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "panel", groupType: SettingGroupType.style},
            { type: SettingType.border, group: "panel", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "panel", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "panel", groupType: SettingGroupType.advanced},


            { type: SettingType.typography, group: "title", groupType: SettingGroupType.style},
            { type: SettingType.align, group: "title", groupType: SettingGroupType.style},
            { type: SettingType.dimension, group: "title", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "title", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "title", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "title", groupType: SettingGroupType.advanced},

            { type: SettingType.background, group: "card_panel", groupType: SettingGroupType.style},
            { type: SettingType.pixeldimension, group: "card_panel", groupType: SettingGroupType.style},
            { type: SettingType.border, group: "card_panel", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "card_panel", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "card_panel", groupType: SettingGroupType.advanced},


            { type: SettingType.background, group: "card_icon", groupType: SettingGroupType.style},
            { type: SettingType.dimension, group: "card_icon", groupType: SettingGroupType.style},
            { type: SettingType.object, group: "card_icon", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "card_icon", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "card_icon", groupType: SettingGroupType.advanced},



            { type: SettingType.typography, group: "card_title", groupType: SettingGroupType.style},
            { type: SettingType.align, group: "card_title", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "card_title", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "card_title", groupType: SettingGroupType.advanced},

            { type: SettingType.typography, group: "card_desc", groupType: SettingGroupType.style},
            { type: SettingType.align, group: "card_desc", groupType: SettingGroupType.style},

            { type: SettingType.margin, group: "card_desc", groupType: SettingGroupType.advanced},
        ];

        return <EditablePanel className={styles.Root}
                              {...props}
                                themeName={themeName}
                                suggestTags={suggestTags}
                                settingOptions={settingOptions}
                                settingGroups={[
                                      {group: "content", groupName: "Content", groupType: SettingGroupType.layout},
                                      {group: "panel", groupName: "Panel", groupType: SettingGroupType.style},
                                      {group: "title", groupName: "Title", groupType: SettingGroupType.style},
                                      {group: "card_panel", groupName: "Card Panel", groupType: SettingGroupType.style},
                                      {group: "card_icon", groupName: "Card Image", groupType: SettingGroupType.style},
                                      {group: "card_title", groupName: "Card Title", groupType: SettingGroupType.style},
                                      {group: "card_desc", groupName: "Card Description", groupType: SettingGroupType.style},
                                ]}
                                deleteOption={deleteOption}>
            {createProductList(false)}
        </EditablePanel>
    } else {
        return createProductList(true);
    }
}