import {Grid, MenuItem, TextField} from "@mui/material";
import {SettingLabel} from "../../setting/settinglabel/SettingLabel";
import {TextFieldProps} from "@mui/material/TextField/TextField";

export default function LocationSetting(props: TextFieldProps) {
    return <>
        <Grid item xs={4}>
            <SettingLabel title={"Location"}/>
        </Grid>
        <Grid item xs={8}>
            <TextField
                {...props}
                fullWidth={true}
                select={true}
                size={"small"}
            >
                <MenuItem value={"top"}>Top</MenuItem>
                <MenuItem value={"middle"}>Middle</MenuItem>
                <MenuItem value={"bottom"}>Bottom</MenuItem>
            </TextField>
        </Grid>
    </>
}