
import React, {useEffect, useState} from "react";
import {useOrganization} from "../../hook/useOrganization";
import {DAMFilter, DAMSearchFilter} from "./DAMFilter";
import {useHistory} from "react-router";
import {PDAMFileList} from "./PDAMFileList";
import {PDAMFolders} from "./PDAMFolders";
import {GetPortalDAMEnvironment} from "../popover/PortalEnvironmentPopover";

export interface PDAMFile {
    directory: boolean,
    fullPath: string,
    modifyTime: number,
    name: string
    parentPath: string
    size: number
}

export default function PDAM() {
    const orgCmd = useOrganization();
    const history = useHistory();

    const [currentPath,setCurrentPath] = useState<string>( "");
    const [searchFilter, setSearchFilter] = useState<DAMSearchFilter>({query: "", recursive: false, byName: true});

    const onChangePath = (path: string) => {
        setCurrentPath(path);
        history.push({
            pathname: history.location.pathname,
            search: "path=" + path
        })
    }


    // TODO: this might create a duplicate render on the same data, should revisit this problem again in the future
    useEffect(() => {
        let orgCode = orgCmd?.currentOrg?.orgCode;
        if(orgCode && orgCode !== "") {
            history.push({
                pathname: history.location.pathname,
            })
            setCurrentPath("/");
        }
    },[orgCmd?.orgDetail,orgCmd?.currentOrg]);

    useEffect(() => {

        let orgCode = orgCmd?.currentOrg?.orgCode;
        const urlSearchParams = new URLSearchParams(history.location.search);

        let path = urlSearchParams.get("path");
        if(!path) {
            path = "/";
        }
        if(orgCode && orgCode !== "" && path && path !== currentPath) {
            setCurrentPath(path);
        }
    },[orgCmd?.currentOrg?.orgCode])

    //let siteCode = orgCmd?.currentOrg?.siteCode!;
    let siteCode = orgCmd?.orgDetail?.siteCode!;
    return <div>
        { siteCode && <>
            <DAMFilter mode="edit" orgCode={siteCode} currentPath={currentPath} defaultFilter={searchFilter}
                       onSearch={setSearchFilter}/>
            <PDAMFolders
                siteCode={siteCode}
                currentPath={currentPath}
                onChangePath={onChangePath}
                portalEnvironment={GetPortalDAMEnvironment()}
                canSwitchEnvironment={true}
            />
            <PDAMFileList siteCode={siteCode}
                          portalEnvironment={GetPortalDAMEnvironment()}
                          currentPath={currentPath}
                          searchFilter={searchFilter}
                          onChangePath={onChangePath}
                          onSelectFile={(file) => console.log(file)}
            mode={"edit"}/>
        </>
        }
    </div>

}