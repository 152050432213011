import {TextFieldProps} from "@mui/material/TextField/TextField";
import styles from './Input.module.scss';
import {TextField} from "@mui/material";
import {useState} from "react";



export default function PixelInput(props: (TextFieldProps &
                                            {onChangeSize?: (value:number) => void })) {
    const [value, setValue] = useState<number>((props.value === undefined || props.value === "") ? 0  : parseInt(props.value as string) );

    const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        let newValue = parseInt(event.target.value);
        setValue(newValue);
        if(props.onChangeSize) {
            props.onChangeSize(newValue);
        }
    }

    let newProps = {...props};
    delete newProps.onChangeSize

    return <><TextField {...newProps}
                        variant={"outlined"}
                        type={"text"}
                        value={value}
                        InputLabelProps={{shrink: true}}
                        placeholder=""
                        className={styles.Input}
                        size={"small"}
                        fullWidth={true}
                        onChange={handleChange}
                        InputProps={{
                            endAdornment: <span className={styles.Separator}>
                                    <span className={styles.UnitEmpty}>px</span>
                            </span>
                        }}
    />
    </>
}