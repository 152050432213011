import SettingProps from "../SettingProps";
import {Grid} from "@mui/material";
import SuggestInput from "../../../../ui/setting/input/SuggestInput";
import {SettingLabel} from "../settinglabel/SettingLabel";


export function TextAlignSetting(props: SettingProps) {
    const data = props.settingProps;
    const group = props.settingOption.group;
    const type = "align";

    if(!data[group]['style'][type]) {
        data[group]['style'][type] = {};
    }

    const handleChange = (value: string, key: string) => {

        if(value === "" || value === null) {
            delete data[group]['style'][type][key];
        } else {
            data[group]['style'][type][key] = value;
        }

        data[group]['style']['default'] = false; // user did change the component

        props.saveWidgetState();
        if(props.onChange) props.onChange(props.settingOption,props.settingOptions,props.index);
    }

    return <Grid container>
        <Grid item xs={4} sx={{margin: "auto",color: "var(--secondary-text-color)"}}><SettingLabel title={"Text Align"}/></Grid>
        <Grid item xs={8}><SuggestInput options={[
                                            {label:"none", value: ""},
                                            {label:"center", value: "center"},
                                            {label:"left", value: "left"},
                                            {label:"righ", value: "right"},
                                            {label:"justify", value: "justify"}
                                        ]}
                                        value={data[group]['style'][type]["text-align"] ? data[group]['style'][type]["text-align"] : ""}
                                        onChange={(value) => {
                                            handleChange(value, "text-align")
                                        }}
        /></Grid>
    </Grid>
}