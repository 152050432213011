import SettingProps from "../SettingProps";
import GenericSetting, {StyleSettingType} from "../genericsetting/GenericSetting";
// import CSSSetting from "../csssetting/CSSSetting";

export default function PositionSetting(props: SettingProps,
) {
    // return <CSSSetting {...props} title={"Position"} type={"position"} targets={["display" , "float" ,"position", "top", "bottom" , "left", "right","z-index","text-align"]}/>
    return <GenericSetting {...props}
                           title={"Position"}
                           type={"position"}
                           targets={[
                                    { name: "Display",target: 'display' , type: StyleSettingType.selections, options: [
                                            {label:"none", value: ""},
                                            {label:"block", value: "block"},
                                            {label:"inline-block", value: "inline-block"},
                                            {label:"inline", value: "inline"}
                                        ]},
                                    { name: "Float", target: 'float' , type: StyleSettingType.selections, options: [
                                            {label:"none", value: ""},
                                            {label:"left", value: "left"},
                                            {label:"right", value: "right"},
                                            {label:"inline-start", value: "inline-start"},
                                            {label:"inline-end", value: "inline-end"},
                                        ]},
                                    { name: "Position", target: 'position' , type: StyleSettingType.selections, options: [
                                            {label:"none", value: ""},
                                            {label:"static", value: "static"},
                                            {label:"absolute", value: "absolute"},
                                            {label:"fixed", value: "fixed"},
                                            {label:"relative", value: "relative"},
                                            {label:"sticky", value: "sticky"},
                                        ]},
                                    { name: "Top", target: 'top' , type: StyleSettingType.default_size},
                                    { name: "Bottom", target: 'bottom' , type: StyleSettingType.default_size},
                                    { name: "Left", target: 'left' , type: StyleSettingType.default_size},
                                    { name: "Right", target: 'right' , type: StyleSettingType.default_size},
                                    { name: "Z-Index", target: 'z-index' , type: StyleSettingType.default_size},
                                    // { name: "text-align", target: 'text-align' , type: StyleSettingType.selections, options: []}
                                ]}
    />
}