import {IconButton, Tooltip} from "@mui/material";
import React, {SyntheticEvent, useContext, useState} from "react";
import styles from './EditableModule.module.scss';
import {EditableProps} from "./EditableProps";
import ConfirmDialog, {ConfirmData} from "../../../../ui/dialog/ConfirmDialog";
import {DeleteOption} from "../../../model/DeleteOption";
import {SettingGroup, SettingOption} from "../../../model/SettingOption";
import {SwapType} from "../../../model/SwapOption";
import {SectionInterface} from "../../../model/SectionInterface";
import {WidgetInterface} from "../../../model/WidgetInterface";
import {NewOption} from "../../../model/NewOption";
import Empty from "../../widget/empty/Empty";
import {SettingChangeFunction} from "../../setting/SettingChange";
import useFocusInEditable from "../../../../hook/useFocusInEditable";
import {nanoid} from "nanoid";
import {StyleUtil} from "../../setting/StyleUtil";
import {
    sectionDescriptionsDataFetcher,
    widgetDescriptionsDataFetcher
} from "../../../../datafetcher/SectionAndWidgetDataFetcher";
import OutlinedIcon from "../../../../ui/icon/OutlinedIcon";
import {CaptureLayout} from "../../../../util/LayoutCapture";
import VariableContext from "../../../../context/VariableContext";




let dragLevel = "-1";
let dragLevelStr = "";
let dragType = "";
let dragY = 0;
let overLevel = "";
let focusLevelStr = "";
enum DragOverType {
    move,
    swap,
    rearrange_up,
    rearrange_down,
    none
}

export function FocusLevelStr() {
    return focusLevelStr;
}

export function OverLevel() {
    return overLevel;
}

export function SetFocusLevelStr(value: string) {
    focusLevelStr = value;
}

export function SetOverLevel(value: string) {
    overLevel = value;
}


export function GetDragLevelStr() {
    return dragLevelStr
}

export function GetDragLevel() {
    return dragLevel
}

export function GetDragType() {
    return dragType;
}

export function ResetEditablePanel() {
    focusLevelStr = "";
    overLevel = ""
}

export default function EditablePanel(props: EditableProps &
                                            {
                                                themeName:string,
                                                newUILevelStr?: string,
                                                newOption?: NewOption,
                                                onHandleAddNewSectionOrWidget?: (levelStr: string, setting: NewOption) => void,
                                                deleteOption?: DeleteOption,
                                                suggestTags: string[],
                                                settingGroups: SettingGroup[],
                                                settingOptions: SettingOption[],
                                                onChangeSettings: SettingChangeFunction|null,
                                                moreActions?: {icon: string, onClick: (event:any) => void, tooltip: string}[]
                                            }) {
    const variableContent = useContext(VariableContext);
    const isWidget = props.uiType === "widget" || props.uiType === "new" || props.uiType === "empty";
    const [dragOverType , setDragOverType] = useState(DragOverType.none);
    const [deleted, setDelete] = useState(false);
    const [refreshId, setRefreshId] = useState(nanoid());
    const [menuId, setMenuId] = useState(nanoid());
    const hasParent = props.uiLevel > 0;
    const [showSubMenu, setShowSubMenu] = useState<boolean>(false);
    const {fireNewFocus} = useFocusInEditable((callback) => {
        let {newFocus,oldFocus} = callback.detail;
        if(props.uiLevelStr === oldFocus || props.uiLevelStr === newFocus) {

            SetFocusLevelStr(newFocus);
            SetOverLevel(newFocus);
            setMenuId(nanoid());
        }
        setShowSubMenu(false)
    });

    const [confirmData, setConfirmData] = useState<ConfirmData>({
        open: false,
        title:"Delete",
        body: "Do you want to delete this section?",
        tag: null,
        choices: [{ title: "Confirm",
                    value: "confirm",
                    color: "primary",
                    icon: "done"
                    },
                 {  title: "Cancel",
                     value: "cancel",
                    color: "secondary",
                    icon: "cancel"}]
    });

    let isFocus = FocusLevelStr() === props.uiLevelStr;

    const findSectionNickName = (name:string):string => {
        const allSections = sectionDescriptionsDataFetcher()
        for(let section of allSections) {
            if(section.sectionName === name) {
                return section.sectionNickName;
            }
        }
        return name;
    }

    const findWidgetNickName = (name:string):string => {
        const allWidgets = widgetDescriptionsDataFetcher()
        for(let section of allWidgets) {
            if(section.widgetName === name) {
                return section.widgetNickName;
            }
        }
        return name;
    }
    const onClone = (event:React.MouseEvent<HTMLButtonElement>) => {
        if(props.data !== null) {
            props.onHandleClone(props.uiLevelStr);
        }
        event.preventDefault();
        event.stopPropagation();
    }

    const onCopy = (event:React.MouseEvent<HTMLButtonElement>) => {
        if(props.data !== null) {
            props.onHandleCopy(props.uiLevelStr);
        }
        event.preventDefault();
        event.stopPropagation();
    }

    const onCut = (event:React.MouseEvent<HTMLButtonElement>) => {
        if(props.data !== null) {
            props.onHandleCut(props.uiLevelStr);
        }
        event.preventDefault();
        event.stopPropagation();
    }

    const onClickCreateTemplate = (event:React.MouseEvent<HTMLButtonElement>) => {

        let section = (event.target as any).parentElement?.parentElement?.parentElement?.querySelector("section");

        if(section) {
            // @ts-ignore
            CaptureLayout(section, (data) => {
                if(props.onHandleCreateTemplate) {
                    props.onHandleCreateTemplate(JSON.stringify({
                        data: JSON.stringify(props.data),
                        capture: data
                    }));
                }
            })
        } else {
            if(props.onHandleCreateTemplate) {
                props.onHandleCreateTemplate(JSON.stringify(
                    {
                        data: props.data,
                        capture: ""
                    }));
            }
        }

        event.stopPropagation()
    }

    const onClickSubMenu = (event:React.MouseEvent<HTMLButtonElement>) => {
        setShowSubMenu(!showSubMenu);
        event.stopPropagation();
        event.preventDefault()
    }
    const onClickSetting = (event:React.MouseEvent<HTMLButtonElement>) => {
        if(props.data !== null) {
            let data = props.data!;
            if('sectionType' in data) {
                //onHandleSetting:(levelStr: string, groups: string[], settings: SettingOption[], settingProps: any, onChange: SettingChangeFunction|null) => void;
                props.onHandleSetting(props.themeName,
                    findSectionNickName((props.data as SectionInterface).sectionName) + " Settings",
                                props.uiLevelStr,
                                props.suggestTags,
                                props.settingGroups!,
                                props.settingOptions!,
                                (props.data as SectionInterface).sectionId,
                                props.data,
                                (props.data as SectionInterface).sectionProps,
                                props.onChangeSettings,
                    true,
                    variableContent.portalThemeName,
                    variableContent.siteCode);
            } else if('widgetType' in data) {
                //(props.data as WidgetInterface).widgetProps = {"test":12};
                props.onHandleSetting(props.themeName,
                    findWidgetNickName((props.data as WidgetInterface).widgetName) + " Settings",
                    props.uiLevelStr,
                    props.suggestTags,
                    props.settingGroups!,
                    props.settingOptions!,
                    (props.data as WidgetInterface).widgetId,
                    props.data,
                    (props.data as WidgetInterface).widgetProps,
                    props.onChangeSettings,
                    true,
                    variableContent.portalThemeName,
                    variableContent.siteCode);
            }
        }
        event.stopPropagation()
    };

    const handleFocus = (event:React.FocusEvent) => {

        if(props.data !== null) {
            let data = props.data!;
            if('sectionType' in data) {
                props.onHandleSetting(props.themeName,
                    findSectionNickName((props.data as SectionInterface).sectionName) + " Settings",
                    props.uiLevelStr,
                    props.suggestTags,
                    props.settingGroups!,
                    props.settingOptions!,
                    (props.data as SectionInterface).sectionId,
                    props.data,
                    (props.data as SectionInterface).sectionProps,
                    props.onChangeSettings,
                    false,
                    variableContent.portalThemeName,
                    variableContent.siteCode);
            } else if('widgetType' in data) {
                props.onHandleSetting(props.themeName,
                    findWidgetNickName((props.data as WidgetInterface).widgetName) + " Settings",
                    props.uiLevelStr,
                    props.suggestTags,
                    props.settingGroups!,
                    props.settingOptions!,
                    (props.data as WidgetInterface).widgetId,
                    props.data,
                    (props.data as WidgetInterface).widgetProps,
                    props.onChangeSettings,
                    false,
                    variableContent.portalThemeName,
                    variableContent.siteCode);
            }
        }

        fireNewFocus(props.uiLevelStr,FocusLevelStr());
        SetFocusLevelStr(props.uiLevelStr);



        //
        //
        //

        event.preventDefault();
        event.stopPropagation();
    }

    const onClickDelete = () => {
        let lastIndex = props.uiLevelStr.lastIndexOf("_");
        let newLevelStr = props.uiLevelStr.substring(0,lastIndex);
        fireNewFocus(FocusLevelStr(),newLevelStr);
        SetFocusLevelStr(newLevelStr);
        setTimeout(() => {
            props.onHandleDeletion(props.uiLevelStr, props.deleteOption!);
        },50);
        // setConfirmData({
        //     ...confirmData,
        //     open: true,
        //     tag: props.uiLevelStr
        // })
    };

    const handleDragStart = (event:React.DragEvent) => {
        if(event.currentTarget.getAttribute("data-drag-level") !== null && event.currentTarget.getAttribute("data-ui-type") !== "empty") {
            //none" | "copy" | "copyLink" | "copyMove" | "link" | "linkMove" | "move" | "all" | "uninitialize
            dragLevel = event.currentTarget.getAttribute("data-drag-level")!;
            dragLevelStr = event.currentTarget.getAttribute("data-drag-level-str")!;
            dragY = event.currentTarget.getBoundingClientRect().y;//event.clientY;
            dragType = event.currentTarget.getAttribute("data-ui-type")!;

            event.dataTransfer.setData("text",dragLevelStr);
            event.dataTransfer.setData("type",dragType);
            event.dataTransfer.setData("data",JSON.stringify(props.data));

            event.stopPropagation();
        } else {
            event.stopPropagation();
            dragLevel = "-1";
            dragLevelStr = "";
            dragType = "";
            dragY = 0;
        }
    }



    const handleDragLeave = (event:React.DragEvent) => {
        let currentDragLevel = event.currentTarget.getAttribute("data-drag-level");
        if(currentDragLevel !== null) {
            setDragOverType(DragOverType.none);
            event.stopPropagation();
            event.preventDefault();
        }
    }

    const handleDragExit = (event:SyntheticEvent) => {
        setDragOverType(DragOverType.none);
        event.stopPropagation();
        event.preventDefault();
    }

    const handleDragEnd = (event:SyntheticEvent) => {
        dragLevel = "-1";
        dragLevelStr = "";
        setDragOverType(DragOverType.none);
        event.stopPropagation()
        event.preventDefault();
    }

    const findParent = (levelStr: string|null): string| null => {
        if(levelStr === null) return levelStr;
        return levelStr.substring(0,levelStr.lastIndexOf("_"));
    }

    const handleDragOver = (event:React.DragEvent) => {


        //let currentDragLevel = event.currentTarget.getAttribute("data-drag-level");
        let currentDragLevelString = event.currentTarget.getAttribute("data-drag-level-str");
        let currentType = event.currentTarget.getAttribute("data-ui-type");
        let currentDragY = event.currentTarget.getBoundingClientRect().y;

        if(currentDragLevelString !== dragLevelStr) {
            if (currentType === "section" && dragType === "section" && findParent(currentDragLevelString) === findParent(dragLevelStr)) { // rearrange
                if (currentDragY < dragY) {
                    if (dragOverType !== DragOverType.rearrange_up) {
                        setDragOverType(DragOverType.rearrange_up);
                    }
                } else {
                    if (dragOverType !== DragOverType.rearrange_down) {
                        setDragOverType(DragOverType.rearrange_down);
                    }
                }
                event.preventDefault();
                event.stopPropagation();
                event.dataTransfer.effectAllowed = "copyMove";
                event.dataTransfer.dropEffect = "move";
            } else if ((currentType === "section" && dragType === "widget" && findParent(currentDragLevelString) === findParent(dragLevelStr))) { // rearrange
                if(currentDragY < dragY) {
                    if(dragOverType !== DragOverType.rearrange_up) {
                        setDragOverType(DragOverType.rearrange_up);
                    }
                } else {
                    if(dragOverType !== DragOverType.rearrange_down) {
                        setDragOverType(DragOverType.rearrange_down);
                    }
                }
                event.dataTransfer.effectAllowed = "copyMove";
                event.dataTransfer.dropEffect = "move";
                event.preventDefault();
                event.stopPropagation();
            } else if (currentType === "widget" && dragType === "section" && findParent(currentDragLevelString) === findParent(dragLevelStr)) { // rearrange
                if(currentDragY < dragY) {
                    if(dragOverType !== DragOverType.rearrange_up) {
                        setDragOverType(DragOverType.rearrange_up);
                    }
                } else {
                    if(dragOverType !== DragOverType.rearrange_down) {
                        setDragOverType(DragOverType.rearrange_down);
                    }
                }
                event.dataTransfer.effectAllowed = "copyMove";
                event.dataTransfer.dropEffect = "move";
                event.preventDefault();
                event.stopPropagation();
            } else if (currentType === "widget" && dragType === "widget") { //swap
                if(findParent(currentDragLevelString) === findParent(dragLevelStr)) {
                    if(currentDragY < dragY) {
                        if(dragOverType !== DragOverType.rearrange_up) {
                            //console.log("UP " + dragOverType + " , " + DragOverType.rearrange_up + " , " + currentDragLevelString);
                            setDragOverType(DragOverType.rearrange_up);
                        }
                    } else {
                        if(dragOverType !== DragOverType.rearrange_down) {
                            setDragOverType(DragOverType.rearrange_down);
                        }
                    }
                    event.dataTransfer.effectAllowed = "copyMove";
                    event.dataTransfer.dropEffect = "move";
                    event.preventDefault();
                    event.stopPropagation();
                } else {
                    event.dataTransfer.effectAllowed = "link";
                    event.dataTransfer.dropEffect = "link";
                    setDragOverType(DragOverType.swap);
                    event.preventDefault();
                    event.stopPropagation();
                }
            } else if (currentType === "empty" && dragType === "widget") { //move
                event.dataTransfer.effectAllowed = "copy";
                event.dataTransfer.dropEffect = "copy";
                if(dragOverType !== DragOverType.move) {
                    setDragOverType(DragOverType.move);
                }
                event.preventDefault();
                event.stopPropagation();
            } else if (currentType === "empty" && dragType === "section" && currentDragLevelString!.startsWith(dragLevelStr!) === false) { //move
                event.dataTransfer.effectAllowed = "copy";
                event.dataTransfer.dropEffect = "copy";
                if(dragOverType !== DragOverType.move) {
                    setDragOverType(DragOverType.move);
                }
                event.preventDefault();
                event.stopPropagation();
            } else {
                // dragLevel = "-1";
                // dragLevelStr = "";
                // setDragOverType(DragOverType.none);
                // event.stopPropagation();
                // event.preventDefault()
            }
        } else {
            // dragLevel = "-1";
            // dragLevelStr = "";
            // setDragOverType(DragOverType.none);
            // event.stopPropagation();
            // event.preventDefault()
        }

    }

    // section can move to empty space (don't need to be in the same deep)
    // widget can move to empty space except section only space (don't need to be in same deep)
    // section can move different section with in the same deep (same deep)
    // widget can swap with other widget (don't need to be in same deep)

    const handleDrop = (event:SyntheticEvent) => {
        //console.log(event);
        //let currentDragLevel = event.currentTarget.getAttribute("data-drag-level");
        let currentDragLevelString = event.currentTarget.getAttribute("data-drag-level-str");
        let currentType = event.currentTarget.getAttribute("data-ui-type");
        if(currentDragLevelString !== dragLevelStr) {
            if (currentType === "section" && dragType === "section" && findParent(currentDragLevelString) === findParent(dragLevelStr)) {
                props.onHandleSwap(dragLevelStr,currentDragLevelString!, {type: SwapType.section_to_section, tag: null});
                setDragOverType(DragOverType.none);
                event.preventDefault();
                event.stopPropagation();
            } else if ((currentType === "empty" && dragType === "widget")) {
                props.onHandleSwap(dragLevelStr,currentDragLevelString!, {type: SwapType.widget_to_empty, tag: null});
                setDragOverType(DragOverType.none);
                event.preventDefault();
                event.stopPropagation();
            } else if ((currentType === "empty" && dragType === "section")  && currentDragLevelString!.startsWith(dragLevelStr!) === false) {
                props.onHandleSwap(dragLevelStr,currentDragLevelString!, {type: SwapType.section_to_empty, tag: null});
                setDragOverType(DragOverType.none);
                event.preventDefault();
                event.stopPropagation();
            } else if (currentType === "section" && dragType === "widget" && findParent(currentDragLevelString) === findParent(dragLevelStr)) {
                props.onHandleSwap(dragLevelStr,currentDragLevelString!, {type: SwapType.widget_to_section, tag: null});
                setDragOverType(DragOverType.none);
                event.preventDefault();
                event.stopPropagation();
            } else if (currentType === "widget" && dragType === "section" && findParent(currentDragLevelString) === findParent(dragLevelStr)) {
                props.onHandleSwap(dragLevelStr,currentDragLevelString!, {type: SwapType.section_to_widget, tag: null});
                setDragOverType(DragOverType.none);
                event.preventDefault();
                event.stopPropagation();
            } else if (currentType === "widget" && dragType === "widget") {
                if(findParent(currentDragLevelString) === findParent(dragLevelStr)) {
                    props.onHandleSwap(dragLevelStr, currentDragLevelString!, {
                        type: SwapType.widget_to_widget_rearrange,
                        tag: null
                    });
                } else {
                    props.onHandleSwap(dragLevelStr, currentDragLevelString!, {
                        type: SwapType.widget_to_widget_moved,
                        tag: null
                    });
                }
                setDragOverType(DragOverType.none);
                event.preventDefault();
                event.stopPropagation();
            }
        }

    }

    let currentStyle:any = {}
    //console.log(deleted + " ,  " + dragOver);
    if(deleted) {
        currentStyle = {display: "none"}
    } else if(dragOverType !== DragOverType.none) {
       if(dragOverType === DragOverType.move) {
            currentStyle = {position: "relative",
                outlineStyle: "solid",
                outlineColor: "var(--bc-editor-border-drag-to-move-color)",
                outlineWidth: "var(--bc-editor-border-width-2x)",
                outlineOffset: "calc(-1 * var(--bc-editor-border-width-2x))"
            };
        } else if(dragOverType === DragOverType.swap) {
            currentStyle = {position: "relative",
                outlineStyle: "solid",
                outlineColor: "var(--bc-editor-border--drag-to-swap-color)",
                outlineWidth: "var(--bc-editor-border-width-2x)",
                outlineOffset: "calc(-1 * var(--bc-editor-border-width-2x))"
            };
        } else if(dragOverType === DragOverType.rearrange_up) {
            currentStyle = {position: "relative",
                outlineStyle: "solid",
                outlineOffset: "calc(-1 * var(--bc-editor-border-width))",
                outlineColor: "var(--bc-editor-border--drag-to-rearrange-color)",
                outlineBottomWidth: "var(--bc-editor-border-width)" ,
                outlineLeftWidth: "var(--bc-editor-border-width)" ,
                outlineRightWidth: "var(--bc-editor-border-width)" ,
                outlineTopWidth: "var(--bc-editor-border-width-rearrange)" };
        } else if(dragOverType === DragOverType.rearrange_down) {
            currentStyle = {position: "relative",
                outlineStyle: "solid",
                outlineOffset: "calc(-1 * var(--bc-editor-border-width))",
                outlineColor: "var(--bc-editor-border--drag-to-rearrange-color)",
                outlineTopWidth: "var(--bc-editor-border-width)" ,
                outlineLeftWidth: "var(--bc-editor-border-width)" ,
                outlineRightWidth: "var(--bc-editor-border-width)" ,
                outlineBottomWidth: "var(--bc-editor-border-width-rearrange)"};
        }
    } else {
        if(isFocus) {
            currentStyle = {position: "relative",
                outlineStyle: props.uiType === "widget" ? "dashed" : "none",
                outlineColor: props.uiType === "widget" ?
                                            "var(--bc-editor-widget-border-focus-color)" :
                                            hasParent ? "var(--bc-editor-column-border-focus-color)" : "var(--bc-editor-border-focus-color)",
                outlineWidth: "var(--bc-editor-border-width)",
                outlineOffset: "calc(-1 * var(--bc-editor-border-width))"
            }
            currentStyle['paddingInlineStart'] = "0.0rem";
            currentStyle['paddingInlineEnd'] = "0.0rem";
            currentStyle['cursor'] = 'pointer';
        } else {
            currentStyle = {position: "relative",
                outlineStyle: "dashed",
                outlineColor: "transparent",
                outlineWidth: "var(--bc-editor-border-width)",
                outlineOffset: "calc(-1 * var(--bc-editor-border-width))"

            }
            currentStyle['cursor'] = 'pointer';
        }
    }


    //console.log(currentStyle);
    const onCloseConfirmDialog = () => {
        setConfirmData({
            ...confirmData,
            open: false
        })
    }

    const onSelectConfirmData = (tag: string, type:string) => {
        setConfirmData({
            ...confirmData,
            open: false
        })
        if(type === "confirm") {
            setDelete(true);
            let lastIndex = props.uiLevelStr.lastIndexOf("_");
            let newLevelStr = props.uiLevelStr.substring(0,lastIndex);
            fireNewFocus(FocusLevelStr(),newLevelStr);
            SetFocusLevelStr(newLevelStr);
            setTimeout(() => {
                props.onHandleDeletion(tag, props.deleteOption!);
            },50);
        }
    }

    const onClickNew = (event:React.PointerEvent) => {
        props.onHandleAddNewSectionOrWidget(props.newUILevelStr!,props.newOption!);
        event.stopPropagation();
        event.preventDefault();
    }

    const onDoubleClickEmpty = (event:React.MouseEvent) => {
        props.onHandleAddNewSectionOrWidget(props.newUILevelStr!,props.newOption!);
        event.stopPropagation();
        event.preventDefault();
    }

    //
    // const handleBlur = (event:React.FocusEvent) => {
    //     setFocus(false);
    //     event.preventDefault();
    //     event.stopPropagation();
    // }

    let tabIndex = (props.uiType === "new" || props.uiType === "empty") ? {} : { tabIndex: -1}


    const onClickGotoParent = (_: React.PointerEvent) => {
        let lastIndex = props.uiLevelStr.lastIndexOf("_");
        let parentUILevelStr = props.uiLevelStr.substring(0,lastIndex);
        let element:any = document.querySelector("div[data-drag-level-str='" + parentUILevelStr+ "']");
        if(element !== null) {
            element.focus();
            scrollToLevel(parentUILevelStr);
        }
    }


    const handleMouseOver = (event: React.MouseEvent<HTMLElement>) => {
        let currentLevel = event.currentTarget.getAttribute("data-drag-level-str");
        let currentType = event.currentTarget.getAttribute("data-ui-type");
        if(currentType === "section"
            // && overLevel !== currentLevel!
            && currentLevel !== null
        ) {

            // let newOverElement = document.querySelector("div[data-drag-level-str='" + currentLevel+ "']");//`div[data-drag-level-str='${props.uiLevelStr}']`);
            // let currentOverElement = document.querySelector("div[data-drag-level-str='" + overLevel + "']");//`div[data-drag-level-str='${props.uiLevelStr}']`);

            let parentCurrentLevel = currentLevel.substring(0,currentLevel.lastIndexOf("_"));
            let parentOverLevel = overLevel.substring(0,overLevel.lastIndexOf("_"));

            // let parentNewOverElement = document.querySelector("div[data-drag-level-str='" + parentCurrentLevel+ "']");//`div[data-drag-level-str='${props.uiLevelStr}']`);
            // let parentCurrentOverElement = document.querySelector("div[data-drag-level-str='" + parentOverLevel + "']");//`div[data-drag-level-str='${props.uiLevelStr}']`);

            // if(currentOverElement) {
            //
            //     let ele:any;
            //     if(parentCurrentOverElement) {
            //         ele = parentCurrentOverElement.querySelector("[data-type='menu']");
            //         if (ele) {
            //             ele.style.visibility = 'hidden';
            //         }
            //     }
            //
            //     ele = currentOverElement.querySelector("[data-type='menu']");
            //     if(ele) {
            //         ele.style.visibility = 'hidden';
            //         ele.parentElement.parentElement.style.outlineColor = 'var(--bc-editor-border-color)';
            //         ele.parentElement.parentElement.style.outlineWidth = "1px";
            //     }
            // }
            // if(newOverElement) {
            //     let ele:any;
            //     if(parentNewOverElement) {
            //         ele = parentNewOverElement.querySelector("[data-type='menu']");
            //         if (ele) {
            //             ele.style.visibility = 'visible';
            //         }
            //     }
            //
            //     ele = newOverElement.querySelector("[data-type='menu']");
            //     if(ele) {
            //         ele.style.visibility = 'visible';
            //         ele.parentElement.parentElement.style.outlineColor = 'var(--bc-editor-section-border-focus-color)';
            //         ele.parentElement.parentElement.style.outlineWidth = "2px";
            //         ele.parentElement.parentElement.style.outlineOffset = "-2px";
            //     }
            // }
            overLevel = currentLevel!;
            event.stopPropagation();
            event.preventDefault();
            if(FocusLevelStr() !== props.uiLevelStr) {
                fireNewFocus(props.uiLevelStr, FocusLevelStr());
                // SetFocusLevelStr(props.uiLevelStr);
            }
        } else if(currentType === "widget" && (true || isFocus) && currentLevel !== null) {

            let currentOverElement = document.querySelector("div[data-drag-level-str='" + overLevel+ "']");//`div[data-drag-level-str='${props.uiLevelStr}']`);
            if(currentOverElement) {
                let ele:any = currentOverElement.querySelector("[data-type='menu']");
                if(ele) {
                    ele.style.visibility = 'hidden';
                }
            }

            if(FocusLevelStr() !== props.uiLevelStr) {
                fireNewFocus(props.uiLevelStr, FocusLevelStr());
                // SetFocusLevelStr( props.uiLevelStr);
            }

            SetOverLevel("");
            event.stopPropagation()
            event.preventDefault();
        } else {
            event.stopPropagation();
            event.preventDefault();
        }
    }

    const handleMouseOut = (event: React.MouseEvent<HTMLElement>) => {
        // let currentType = event.currentTarget.getAttribute("data-ui-type");
        // //fireNewFocus("", FocusLevelStr());
        //
        // if(currentType === "section") {
        //     event.stopPropagation()
        //     event.preventDefault();
        // }
        event.stopPropagation();
        event.stopPropagation();
    }


    const handleDoubleClickEdit = (event: React.MouseEvent<any>) => {
        if((event.nativeEvent.target as any).classList && (event.nativeEvent.target as any).classList.contains("MuiBackdrop-root") === false) {
            onClickSetting(event);
        }
        event.stopPropagation();
        event.preventDefault();
    }

    const scrollToLevel = (uiLevelStr: string) => {
        setTimeout(() => {
            let element = document.querySelector("div[data-drag-level-str='" + uiLevelStr+ "']");//`div[data-drag-level-str='${props.uiLevelStr}']`);
            if(element !== null) {
                element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }, 100);
    }

    const uiLevelToReadableString = (uiLevelStr: string) => {
        let array = uiLevelStr.split("_");
        let level = [];
        for(let i=1;i<array.length;i++) {
            level.push(parseInt(array[i]) + 1);
        }
        return level.join(".");
    }

    const uiLevelToWidgetPosition = (uiLevelStr: string) => {
        let array = uiLevelStr.split("_");
        return parseInt(array[array.length-1])+1;
    }


    // EditablePanel need to contain in <div> tag only. Multiple place try to search by using "div" query selector
    return (
        <React.Fragment><div
            key={refreshId}
            {...tabIndex}
            style={ currentStyle}
            draggable={props.uiType === "new" ? false : true}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDragExit={handleDragExit}
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
            onDrop={handleDrop}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onMouseLeave={handleMouseOut}
            data-drag-level={props.uiLevel}
            data-drag-level-str={props.uiLevelStr}
            data-ui-type={props.uiType}
            onFocus={handleFocus}
            // onBlur={handleBlur}
            onDoubleClick={handleDoubleClickEdit}
                ><div className={props.className} style={{
            paddingInlineStart: isFocus ? "unset" : "unset",
            paddingInlineEnd: isFocus ? "unset" : "unset",
            transition: "all 0.1s ease-in",
        }}>
            {   // section settings , without parent
                isWidget === false && hasParent === false && <div className={styles.RootActionPanel}>
                    <div key={`section_${menuId}`}
                       data-type="menu"
                       style={{visibility: overLevel === props.uiLevelStr ? "visible" : "hidden"}}
                       className={StyleUtil.combineClasses(styles.RootActionMenu,
                                                            styles.SectionAction,
                                                            showSubMenu? "" : styles.CircleMenu) }>

                        {   showSubMenu === false && <Tooltip title={"Main"}><IconButton tabIndex={-1} className={styles.Icon}  size="large" onMouseOver={onClickSubMenu}><OutlinedIcon fontSize={"small"}>tune</OutlinedIcon></IconButton></Tooltip>}
                        {   showSubMenu && <>
                            {   props.moreActions && props.moreActions!.map((action, index) => {
                                    return <Tooltip key={`action_${props.uiLevelStr}_${index}`}
                                                    title={action.tooltip}><IconButton tabIndex={-1} className={styles.Icon}
                                                                                       onPointerDown={(e) => action.onClick(e)}
                                                                                       size="large"><OutlinedIcon
                                        fontSize={"small"}>{action.icon}</OutlinedIcon></IconButton></Tooltip>
                                })
                            }
                            <Tooltip title={"Section Settings"}><IconButton tabIndex={-1} className={styles.Icon} onPointerDown={onClickSetting} size="large"><OutlinedIcon fontSize={"small"}>settings</OutlinedIcon></IconButton></Tooltip>
                            <Tooltip title={"Duplicate Section"}><IconButton tabIndex={-1} className={styles.IconSmall + " " + styles.IconFlipVertical} onPointerDown={onClone} size="large"><OutlinedIcon fontSize={"small"}>library_add</OutlinedIcon></IconButton></Tooltip>
                            <Tooltip title={"Copy Section"}><IconButton tabIndex={-1} className={styles.IconSmall} onPointerDown={onCopy} size="large"><OutlinedIcon fontSize={"small"}>copy</OutlinedIcon></IconButton></Tooltip>
                            <Tooltip title={"Save Template"}><IconButton tabIndex={-1} className={styles.Icon} onPointerDown={onClickCreateTemplate} size="large"><OutlinedIcon>favorite_border</OutlinedIcon></IconButton></Tooltip>
                            <Tooltip title={"Delete"}><IconButton tabIndex={-1} className={styles.Icon} onPointerDown={onClickDelete} size="large"><OutlinedIcon fontSize={"small"}>delete</OutlinedIcon></IconButton></Tooltip>
                        </>
                        }

                    </div>
                </div>
            }
            {   // section settings , with parent
                isWidget === false && hasParent && <div className={styles.RootActionPanelWithParent }>
                    <div key={`section_${menuId}`}
                         data-type="menu"
                         style={{visibility: overLevel === props.uiLevelStr ? "visible" : "hidden"}}
                         className={StyleUtil.combineClasses(styles.ActionPanel,
                             styles.SectionAction,
                             styles.ActionPanelWithParent) }                    >
                        {props.moreActions && props.moreActions.map((action,index) => {
                            return <Tooltip key={`action_${props.uiLevelStr}_${index}`} title={action.tooltip}><IconButton tabIndex={-1} className={styles.Icon} onPointerDown={(event) => action.onClick(event)} size="large"><OutlinedIcon fontSize={"small"}>{action.icon}</OutlinedIcon></IconButton></Tooltip>
                        })}

                        <Tooltip title={"Settings"}><IconButton tabIndex={-1} className={styles.Icon} onPointerDown={onClickSetting} size="large"><OutlinedIcon fontSize={"small"}>settings</OutlinedIcon></IconButton></Tooltip>
                        {/*<Tooltip title={"Cut"}><IconButton tabIndex={-1} className={styles.IconSmall} onPointerDown={onCut} size="large"><OutlinedIcon fontSize={"small"}>cut</OutlinedIcon></IconButton></Tooltip>*/}
                        <Tooltip title={"Duplicate"}><IconButton tabIndex={-1} className={styles.IconSmall + " " + styles.IconFlipVertical} onPointerDown={onClone} size="large"><OutlinedIcon fontSize={"small"}>library_add</OutlinedIcon></IconButton></Tooltip>
                        <Tooltip title={"Copy"}><IconButton tabIndex={-1} className={styles.IconSmall} onPointerDown={onCopy} size="large"><OutlinedIcon fontSize={"small"}>copy</OutlinedIcon></IconButton></Tooltip>
                        <Tooltip title={"Delete"}><IconButton tabIndex={-1} className={styles.Icon} onPointerDown={onClickDelete} size="large"><OutlinedIcon fontSize={"small"}>delete</OutlinedIcon></IconButton></Tooltip>
                        {/*{<span className={styles.SectionLabelWithParent}>{uiLevelToReadableString(props.uiLevelStr)}</span>}*/}
                        {/*<span className={styles.SectionLabelInvisible}>{uiLevelToReadableString(props.uiLevelStr)}</span>*/}
                    </div>
                </div>
            }
            {   // widget settings
                isWidget && (isFocus && deleted === false)  && props.uiType !== "new" && (<div className={styles.RootActionPanelWidget}>
                    <div data-type="widget_menu"
                         className={StyleUtil.combineClasses(styles.ActionPanel,styles.ActionPanelWidget,styles.WidgetAction) }>
                        {/*{hasParent && <IconButton className={styles.Icon} onPointerDown={onClickGotoParent} size="large"><OutlinedIcon fontSize={"small"}>north</OutlinedIcon></IconButton>}*/}
                        {/*{<span className={styles.SectionLabel}>{`w/${uiLevelToWidgetPosition(props.uiLevelStr)}`}</span>}*/}
                        {/*<span className={styles.SectionLabelInvisible}></span>*/}
                        {props.moreActions && props.moreActions.map((action,index) => {
                            return <Tooltip key={`action_${props.uiLevelStr}_${index}`} title={action.tooltip}><IconButton tabIndex={-1} className={styles.Icon} onPointerDown={(event) => action.onClick(event)} size="large"><OutlinedIcon fontSize={"small"}>{action.icon}</OutlinedIcon></IconButton></Tooltip>
                        })}
                        <Tooltip title={"Widget Settings"}><IconButton tabIndex={-1} className={styles.Icon} onPointerDown={onClickSetting} size="large"><OutlinedIcon fontSize={"small"}>settings</OutlinedIcon></IconButton></Tooltip>
                        <Tooltip title={"Duplicate"}><IconButton tabIndex={-1} className={styles.IconSmall + " " + styles.IconFlipVertical} onPointerDown={onClone} size="large"><OutlinedIcon fontSize={"small"}>library_add</OutlinedIcon></IconButton></Tooltip>
                        {hasParent && <Tooltip title={"Copy"}><IconButton tabIndex={-1} className={styles.IconSmall} onPointerDown={onCopy} size="large"><OutlinedIcon fontSize={"small"}>copy</OutlinedIcon></IconButton></Tooltip>}
                        {/*{hasParent && <Tooltip title={"Cut"}><IconButton tabIndex={-1} className={styles.IconSmall} onPointerDown={onCut} size="large"><OutlinedIcon fontSize={"small"}>cut</OutlinedIcon></IconButton></Tooltip>}*/}
                        <Tooltip title={"Delete"}><IconButton tabIndex={-1} className={styles.Icon} onPointerDown={onClickDelete} size="large"><OutlinedIcon fontSize={"small"}>delete</OutlinedIcon></IconButton></Tooltip>
                    </div>
                </div>)}
            { props.children !== null && props.children.length === 0 && <Empty onDoubleClick={onDoubleClickEmpty} {...props} uiLevelStr={props.uiLevelStr + "_" + 0} uiType={"empty"}/> }
            {deleted === false && props.children}
            { confirmData.open && (
                <ConfirmDialog data={confirmData} onClose={onCloseConfirmDialog} onSelected={onSelectConfirmData}/>
            )}
        </div></div></React.Fragment>
    );
}