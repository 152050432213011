import SettingProps from "../SettingProps";
// import CSSSetting from "../csssetting/CSSSetting";
import GenericSetting, {StyleSettingType} from "../genericsetting/GenericSetting";

export default function PaddingSetting(props: SettingProps,
) {
    // return <CSSSetting {...props}
    //                    title={"Padding"}
    //                    type={"padding"}
    //                    targets={["padding-block-start" , "padding-block-end" ,"padding-inline-start", "padding-inline-end"]}/>
    return <GenericSetting {...props}
                           title={"Padding"}
                           type={"padding"}
                           targets={[
                                    { name: "Top",target: 'padding-block-start' , type: StyleSettingType.default_size},
                                    { name: "Bottom", target: 'padding-block-end' , type: StyleSettingType.default_size},
                                    { name: "Left", target: 'padding-inline-start' , type: StyleSettingType.default_size},
                                    { name: "Right", target: 'padding-inline-end' , type: StyleSettingType.default_size}
                                ]}
    />
}