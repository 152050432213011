import {StyleUtil} from "../../cms/ui/setting/StyleUtil";
import commonStyle from "../../config/Common.module.scss";
import {
    Autocomplete,
    Box, Button,
    createFilterOptions,
    IconButton, List, ListItem,
    ListItemButton,
    ListItemText,
    TextField, Typography
} from "@mui/material";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {jcr} from "../../datafetcher/JCRDataFetcher";
import {useHistory} from "react-router";
import {useOrganization} from "../../hook/useOrganization";
import NewPopover from "../popover/NewPopover";
import {camelCase} from "../../util/StringUtil";
import {ContentGroupCmd} from "../../model/jcr/ContentGroupCmd";
import {ContentCmd} from "../../model/jcr/ContentCmd";
import {InputLayout, InputModel, InputModelsToObject, InputOption} from "../../model/InputModel";
import styles from "./Contents.module.scss";
import OutlinedIcon from "../icon/OutlinedIcon";
import InputPanel from "../dialog/InputPanel";
import LinkButton from "../button/LinkButton";
import {ISODateToDate} from "../../util/DateUtil";
import SolidIcon from "../icon/SolidIcon";
import PageSummary from "../pagesummary/PageSummary";

export function Contents() {
    const history = useHistory();
    const orgCmdProp = useOrganization();
    const inputFile = useRef<HTMLInputElement>(null);
    const { group , groupId, itemId } = useParams<any>();
    const [groupOptions, setGroupOptions] = useState<InputOption[]>([]);
    const [roleOptions, setRoleOptions] = useState<InputOption[]>([]);
    const [contentGroups , setContentGroups] = useState<ContentGroupCmd[]>([]);
    const [contentGroup, setContentGroup] = useState<ContentGroupCmd|undefined>();
    const [contents, setContents] = useState<ContentCmd[]>([]);
    const [content, setContent] = useState<ContentCmd|undefined>();

    const [ contentGroupValues , setContentGroupValues] = useState<InputModel[]>([]);
    const [ contentValues , setContentValues] = useState<InputModel[]>([]);

    //const [saveContentGroupButton, setSaveContentGroupButton] = useState<React.ReactNode|null>(null);
    //const [saveContentButton, setSaveContentButton] = useState<React.ReactNode|null>(null);

    const updateContentGroups = useCallback((groups: ContentGroupCmd[]) => {
        let newGroupOptions: InputOption[] = [];
        groups.forEach(group => {
            newGroupOptions.push({label: group.cgName, value: group.id});
        });

       // if (groupId !== undefined) {
            if(groups.length > 0) {
                let g = groups[0];
                history.replace({
                    pathname: "/content/" + group + "/" + g.id
                });
            }
      //  }

        setContentGroups(groups);
        setGroupOptions(newGroupOptions);
    },[group,history]);

    const updateContents = useCallback((sortBy:string, contents:ContentCmd[]) => {
        if(contentGroup) {
            if(sortBy === "Date") {
                return contents.sort((obj1 , obj2) => obj1.ctCreatedDate.localeCompare(obj2.ctCreatedDate));
            } else {
                return contents.sort((obj1 , obj2) => obj1.ctTitle.localeCompare(obj2.ctTitle));
            }
        }
        return contents;
    },[contentGroup]);

    useEffect(() => {
        if(orgCmdProp && orgCmdProp.orgDetail !== null) {
            if (jcr.contentGroup.isSupported(group)) {
                jcr.contentGroup.list(group).then((response) => {
                    let groups:ContentGroupCmd[] = response.data.response as ContentGroupCmd[];

                    updateContentGroups(groups);
                });
            } else {
                history.push({
                    pathname: "/content/events"
                })
            }

            jcr.role.lite().then((response) => {
                let newRoleOptions: InputOption[] = [];
                newRoleOptions.push({value: "PUBLIC", label: "Everyone"});
                response.data.response.sort( (a:any, b:any) => {
                    let value = a.roleName.toLowerCase().localeCompare(b.roleName.toLowerCase())
                    if(value === 0) {
                        value = a.roleName.localeCompare(b.roleName)
                        if(value === 0) {
                            value = a.id.localeCompare(b.id);
                        }
                    }
                    return value;
                }).forEach((object:any) => {
                    newRoleOptions.push({value: object.id , label: object.roleName});
                })
                setRoleOptions(newRoleOptions);
            })
        }
    },[orgCmdProp,group,history,updateContentGroups]);

    useEffect(() => {
        if(orgCmdProp && orgCmdProp.orgDetail !== null && groupId && contentGroups && contentGroups.length > 0) {
            if(contentGroup && contentGroup.id === groupId) return;
            jcr.content.fullList(group, groupId).then((response) => {
                setContentGroup(contentGroups.find(group => group.id === groupId));
                if(response.data && response.data.response && response.data.response.length > 0) {
                    setContents(updateContents(contentGroup?.cgSortBy!,response.data.response as ContentCmd[]));
                } else {
                    setContents([]);
                }
            });
        }
    },[orgCmdProp,groupId,contentGroup,contentGroups,group,updateContents]);

    useEffect(() => {
        if(contents) {
            if(itemId === null) {
                setContent(undefined);
            } else {
                let content = contents.find(content => content.id === itemId);
                if(content) {
                    setContent(content);
                } else {
                    setContent(undefined);
                }
            }
        }
    },[contents,itemId])


    const handleAddNewGroup = (newName: string) => {
        return jcr.contentGroup.add(group,{
            cgName: newName,
            cgType: group,
            cgSortBy: "Title",
            cgStatus: "draft"
        }).then((response) => {
            let newGroup = response.data.response;
            let newContentGroup = [...contentGroups,newGroup];
            updateContentGroups(newContentGroup);
            history.replace({
                pathname: "/content/" + group + "/" + newGroup.id
            })
        });
    }

    const handleAddNewContent = (newName: string) => {
        let content:any = {
            ctTitle: newName,
            ctStatus: "draft",
        }

        return jcr.content.add(group,groupId,content).then((response) => {
            setContents(updateContents(contentGroup?.cgSortBy!,[...contents,response.data.response as ContentCmd]));
            return response;
        })
    }


    let title: string;
    if(group === "faqs") {
        title = "FAQs";
    } else if(group) {
        title = camelCase(group);
    } else {
        title = "";
    }


    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        ignoreCase: true,
    });

    const handleEditContent = (content:ContentCmd) => {
        history.push({
            pathname: "/content/" + group + "/" + groupId + "/" + content.id
        });
        // setContent(content);
    }

    const handleBack = () => {
        history.push({
            pathname: "/content/" + group + "/" + groupId
        });
    }

    const handleDeleteContentGroup = () => {
        if(contentGroup) {
            if(window.confirm(`Are you sure to remove "${contentGroup.cgName  }"?\n\n!! All entries in this group will be removed. !!`)) {
                let index = contentGroups.findIndex(group => group.id === contentGroup.id)
                if (index >= 0) {
                    jcr.contentGroup.remove(group,contentGroup.id).then(() => {
                        let newContentGroups = [...contentGroups]
                        newContentGroups.splice(index, 1);
                        updateContentGroups(newContentGroups);
                        if (newContentGroups.length > 0) {
                            if (index < newContentGroups.length) {
                                history.replace({
                                    pathname: "/content/" + group + "/" + newContentGroups[index].id
                                })
                            } else {
                                history.replace({
                                    pathname: "/content/" + group + "/" + newContentGroups[newContentGroups.length - 1].id
                                })
                            }
                        }
                    })
                }
            }
        }
    }

    const handleDeleteContent = (deleteContent:ContentCmd) => {
        if(window.confirm("Are you sure to remove this entry?")) {
            let index = contents.findIndex(content => content.id === deleteContent.id)
            if (index >= 0) {
                jcr.content.remove(group,groupId,deleteContent.id).then(() => {
                    let newContents = [...contents]
                    newContents.splice(index, 1);
                    setContents(updateContents(contentGroup?.cgSortBy!,newContents));
                })
            }
        }
    }

    const handleUpdateContent = (id: string, values: InputModel[],index: number) => {
        setContentValues([...values]);
    }

    const handleUpdateContentGroup = (id: string, values: InputModel[],index: number) => {
        if(index < 0) return;
        if(contentGroup && values[index].id === "cgSortBy") {
            setContents(updateContents(values[index].defaultValue, [...contents]));
        }
        setContentGroupValues([...values]);
        //console.log(id,values,index);
    }

    const handleClickUpdateContentGroup = () => {
        handleSaveContentGroup(contentGroupValues);
    }

    const handleSaveContentGroup = (values:InputModel[]) => {
        if(contentGroup) {
            let additionContentGroup = InputModelsToObject<ContentGroupCmd>(values);
            let newContentGroup: ContentGroupCmd = {...contentGroup,...additionContentGroup}
            jcr.contentGroup.update(group,newContentGroup).then(response => {
                let newContentGroups = [...contentGroups];
                let findIndex = newContentGroups.findIndex(group => group.id === groupId);
                if(findIndex >= 0) {
                    newContentGroups[findIndex] = newContentGroup;
                    let newGroupOptions:InputOption[] = [];
                    newContentGroups.forEach(group => {
                        newGroupOptions.push({label: group.cgName, value: group.id});
                    });

                    setContentGroup(newContentGroup);
                    setContentGroups(newContentGroups);
                    setGroupOptions(newGroupOptions);
                }
            });
        }
    }

    const handleClickSaveContent = () => {
        handleSaveContent(contentValues);
    }

    const handleSaveContent = (values: InputModel[]) => {
        if(content) {
            let additionContent = InputModelsToObject<ContentCmd>(values);
            let newContent: ContentCmd = {...content,...additionContent}
            jcr.content.update(group,groupId,newContent).then(response => {
                let newContents = [...contents];
                let findIndex = newContents.findIndex(content => content.id === newContent.id);
                if(findIndex >= 0) {
                    newContents[findIndex] = newContent;
                    setContents(updateContents(contentGroup?.cgSortBy!,newContents));
                    handleBack();
                }
            });
        }
    }


    const statusOptions:InputOption[] = [
        {label: "published", value: "published"},
        {label: "draft", value: "draft"},
    ]
    const cgSortByOptions:InputOption[] = [
        {label: "Date", value: "Date"},
        {label: "Title", value: "Title"},
    ]

    const createContentPanel = () => {
        if(!content || !contentGroup) return <></>;
        let fields: InputModel[] = [];
        let layouts: InputLayout[] = [];
        const {cgType} = contentGroup;

        if(cgType === "blocks") {
            fields=[
                {id: "ctTitle", label: "Name", defaultValue: content.ctTitle , required: true , editable: true},
                {id: "ctLink", label: "Link", defaultValue: content.ctLink , required: false , editable: true},
                {id: "ctName", label: "By-Line Name", defaultValue: content.ctName , required: false , editable: true},
                {id: "ctLocation", label: "Location", defaultValue: content.ctLocation , required: false , editable: true},
                {id: "ctImage", label: "Image", type: "dam", defaultValue: content.ctImage , required: false , editable: true},
                {id: "ctContent", label: "Entry Content",type:"html", defaultValue: content.ctContent , required: false , editable: true},
                {id: "ctPublishDate", label: "Publish Date", type: "date", defaultValue: content.ctPublishDate , required: false , editable: true},
                {id: "ctExpireDate", label: "Expire Date", type: "date", defaultValue: content.ctExpireDate , required: false , editable: true},
                {id: "ctCreatedDate", label: "Create Date", type: "date", defaultValue: content.ctCreatedDate , required: false , editable: true},
                {id: "ctStatus", label: "Status", type: "select", options: statusOptions, defaultValue: content.ctStatus , required: false , editable: true},
                {id: "ctAccessList", label: "User Policies", type: "multi", options: roleOptions ,defaultValue: content.ctAccessList ? content.ctAccessList : ["PUBLIC"] , required: false , editable: true},
            ]
            layouts=[
                {label: "Detail",
                    ids:["ctTitle","ctLink","ctName","ctLocation","ctImage","ctContent","ctStatus","ctPublishDate","ctExpireDate","ctCreatedDate","ctAccessList"],
                    ids_grid:[12,12,6,6,12,12,6,6,6,6,12]},
            ]
        } else if(cgType === "slideshows") {
            fields=[
                {id: "ctTitle", label: "Name", defaultValue: content.ctTitle , required: true , editable: true},
                {id: "ctDescription", label: "Description", type: "textarea", defaultValue: content.ctDescription , required: false , editable: true},
                {id: "ctImage", label: "Image", type: "dam", defaultValue: content.ctImage , required: false , editable: true},
                {id: "ctPublishDate", label: "Publish Date", type: "date", defaultValue: content.ctPublishDate , required: false , editable: true},
                {id: "ctExpireDate", label: "Expire Date", type: "date", defaultValue: content.ctExpireDate , required: false , editable: true},
                {id: "ctCreatedDate", label: "Create Date", type: "date", defaultValue: content.ctCreatedDate , required: false , editable: true},
                {id: "ctStatus", label: "Status", type: "select", options: statusOptions, defaultValue: content.ctStatus , required: false , editable: true},
                {id: "ctAccessList", label: "User Policies", type: "multi", options: roleOptions ,defaultValue: content.ctAccessList ? content.ctAccessList : ["PUBLIC"] , required: false , editable: true},
            ]
            layouts=[
                {label: "Detail",
                    ids:["ctTitle","ctDescription","ctImage","ctStatus","ctPublishDate","ctExpireDate","ctCreatedDate","ctAccessList"],
                    ids_grid:[12,12,12,12,6,6,6,6,12]},
            ]
        } else if(cgType === "grids" || cgType === "smallgrids") {
            fields=[
                {id: "ctTitle", label: "Name", defaultValue: content.ctTitle , required: true , editable: true},
                {id: "ctDescription", label: "Description", type: "textarea", defaultValue: content.ctDescription , required: false , editable: true},
                {id: "ctLink", label: "Link", defaultValue: content.ctLink , required: false , editable: true},
                {id: "ctName", label: "By-Line Name", defaultValue: content.ctName , required: false , editable: true},
                {id: "ctLocation", label: "Location", defaultValue: content.ctLocation , required: false , editable: true},
                {id: "ctImage", label: "Image", type: "dam", defaultValue: content.ctImage , required: false , editable: true},
                {id: "ctContent", label: "Entry Content", type:"html", defaultValue: content.ctContent , required: false , editable: true},
                {id: "ctPublishDate", label: "Publish Date", type: "date", defaultValue: content.ctPublishDate , required: false , editable: true},
                {id: "ctExpireDate", label: "Expire Date", type: "date", defaultValue: content.ctExpireDate , required: false , editable: true},
                {id: "ctCreatedDate", label: "Create Date", type: "date", defaultValue: content.ctCreatedDate , required: false , editable: true},
                {id: "ctStatus", label: "Status", type: "select", options: statusOptions, defaultValue: content.ctStatus , required: false , editable: true},
                {id: "ctAccessList", label: "User Policies", type: "multi", options: roleOptions ,defaultValue: content.ctAccessList ? content.ctAccessList : ["PUBLIC"] , required: false , editable: true},
            ]
            layouts=[
                {label: "Detail",
                    ids:["ctTitle","ctDescription","ctLink","ctName","ctLocation","ctImage","ctContent","ctStatus","ctPublishDate","ctExpireDate","ctCreatedDate","ctAccessList"],
                    ids_grid:[12,12,12,6,6,12,12,6,6,6,6,12]},
            ]
        } else if(cgType === "events") {
            fields=[
                {id: "ctTitle", label: "Name", defaultValue: content.ctTitle , required: true , editable: true},
                {id: "ctDescription", label: "Description", type: "textarea", defaultValue: content.ctDescription , required: false , editable: true},
                {id: "ctLink", label: "Link", defaultValue: content.ctLink , required: false , editable: true},
                {id: "ctLocation", label: "Location", defaultValue: content.ctLocation , required: false , editable: true},
                {id: "ctImage", label: "Image", type: "dam", defaultValue: content.ctImage , required: false , editable: true},
                {id: "ctContent", label: "Entry Content", type:"html", defaultValue: content.ctContent , required: false , editable: true},
                {id: "ctPublishDate", label: "Publish Date", type: "date", defaultValue: content.ctPublishDate , required: false , editable: true},
                {id: "ctExpireDate", label: "Expire Date", type: "date", defaultValue: content.ctExpireDate , required: false , editable: true},
                {id: "ctCreatedDate", label: "Create Date", type: "date", defaultValue: content.ctCreatedDate , required: false , editable: true},
                {id: "ctStatus", label: "Status", type: "select", options: statusOptions, defaultValue: content.ctStatus , required: false , editable: true},
                {id: "ctAccessList", label: "User Policies", type: "multi", options: roleOptions ,defaultValue: content.ctAccessList ? content.ctAccessList : ["PUBLIC"] , required: false , editable: true},
            ]
            layouts=[
                {label: "Detail",
                    ids:["ctTitle","ctDescription","ctLink","ctLocation","ctImage","ctStatus","ctPublishDate","ctExpireDate","ctCreatedDate","ctAccessList"],
                    ids_grid:[12,12,12,6,6,12,6,6,6,6,12]},
            ]
        } else if(cgType === "blogs" || cgType === "galleries") {
            fields=[
                {id: "ctTitle", label: "Name", defaultValue: content.ctTitle , required: true , editable: true},
                {id: "ctLink", label: "Link", defaultValue: content.ctLink , required: false , editable: true},
                {id: "ctName", label: "By-Line Name", defaultValue: content.ctName , required: false , editable: true},
                {id: "ctLocation", label: "Location", defaultValue: content.ctLocation , required: false , editable: true},
                {id: "ctImage", label: "Image", type: "dam", defaultValue: content.ctImage , required: false , editable: true},
                {id: "ctContent", label: "Entry Content", type:"html", defaultValue: content.ctContent , required: false , editable: true},
                {id: "ctPublishDate", label: "Publish Date", type: "date", defaultValue: content.ctPublishDate , required: false , editable: true},
                {id: "ctExpireDate", label: "Expire Date", type: "date", defaultValue: content.ctExpireDate , required: false , editable: true},
                {id: "ctCreatedDate", label: "Create Date", type: "date", defaultValue: content.ctCreatedDate , required: false , editable: true},
                {id: "ctStatus", label: "Status", type: "select", options: statusOptions, defaultValue: content.ctStatus , required: false , editable: true},
                {id: "ctAccessList", label: "User Policies", type: "multi", options: roleOptions ,defaultValue: content.ctAccessList ? content.ctAccessList : ["PUBLIC"] , required: false , editable: true},
            ]
            layouts=[
                {label: "Detail",
                    ids:["ctTitle","ctLink","ctName","ctLocation","ctImage","ctContent","ctStatus","ctPublishDate","ctExpireDate","ctCreatedDate","ctAccessList"],
                    ids_grid:[12,12,6,6,12,12,6,6,6,6,12]},
            ]
        } else if(cgType === "faqs") {
            fields=[
                {id: "ctTitle", label: "Name", defaultValue: content.ctTitle , required: true , editable: true},
                {id: "ctContent", label: "Entry Content", type:"html", defaultValue: content.ctContent , required: false , editable: true},
                {id: "ctPublishDate", label: "Publish Date", type: "date", defaultValue: content.ctPublishDate , required: true , editable: true},
                {id: "ctExpireDate", label: "Expire Date", type: "date", defaultValue: content.ctExpireDate , required: false , editable: true},
                {id: "ctCreatedDate", label: "Create Date", type: "date", defaultValue: content.ctCreatedDate , required: false , editable: true},
                {id: "ctStatus", label: "Status", type: "select", options: statusOptions, defaultValue: content.ctStatus , required: false , editable: true},
                {id: "ctAccessList", label: "User Policies", type: "multi", options: roleOptions ,defaultValue: content.ctAccessList ? content.ctAccessList : ["PUBLIC"] , required: false , editable: true},
            ]
            layouts=[
                {label: "Detail",
                    ids:["ctTitle","ctContent","ctStatus","ctPublishDate","ctExpireDate","ctCreatedDate","ctAccessList"],
                    ids_grid:[12,12,6,6,6,6,12]},
            ]
        }

        if(fields.length > 0 && layouts.length > 0) {
          return <div className={styles.InputPanel}>
              <InputPanel
                  // saveButton={setSaveContentButton}
                  // handleChange={handleUpdateContent}
                  // handleSave={handleSaveContent}
                  handleChange={handleUpdateContent}
                  fields={fields} layouts={layouts}/>
              <div className={styles.ActionPanel}>
                  <Button size={"small"}
                          onClick={handleClickSaveContent}
                          color="primary"
                          className={styles.Button}>
                      Update
                  </Button>
              </div>
          </div>
        }
        return <></>
    }

    const uploadFile = async () => {
        if(inputFile !== null && inputFile.current !== null) {
            inputFile.current.click();
        }
    }

    const doUploadFile = async () => {
        if(inputFile && inputFile.current) {
            if(inputFile.current.files) {
                jcr.contentGroup.upload(inputFile.current.files).then((response) => {
                    window.location.href = "/content/" + group;
                })
            }
        }
    }

    if(group === null) {
        history.replace({
            pathname: "/content/blogs"
        })
        return <></>
    }

    let singleTitle = camelCase((group === "galleries" ? "gallery" : title.substring(0,group.length-1)));
    let actionComponent = <div style={{display: "inline-block"}}>
        <LinkButton id={`upload${group}Button`} data-action={`doUpload${group}`} variant={"contained"} classes={{
            root: styles.DefaultViewMenuItem,
            label: styles.DefaultViewMenuItem
        }} onClick={() => {uploadFile()}}>
            <SolidIcon className={styles.Icon}>upload</SolidIcon>
            Upload
        </LinkButton>

        <NewPopover id={`new${group}Button`} action={`createNew${group}`} link={{icon: "add", label: "New"}}
                    title={singleTitle +  " Name"}
                    onAddNew={handleAddNewGroup}/>
    </div>;

    return <>
        {/*<PageMenu type={title}>*/}
        {/*    <div className={commonStyle.LeftPanel}>*/}
        {/*        <LinkButton id={`upload${group}Button`} data-action={`doUpload${group}`} variant={"contained"} classes={{*/}
        {/*            root: styles.DefaultViewMenuItem,*/}
        {/*            label: styles.DefaultViewMenuItem*/}
        {/*        }} onClick={() => {uploadFile()}}>*/}
        {/*            <SolidIcon className={styles.Icon}>upload</SolidIcon>*/}
        {/*            Upload*/}
        {/*        </LinkButton>*/}

        {/*        <NewPopover id={`new${group}Button`} action={`createNew${group}`} link={{icon: "add", label: "New"}}*/}
        {/*                    title={singleTitle +  " Name"}*/}
        {/*                    onAddNew={handleAddNewGroup}/>*/}
        {/*    </div>*/}
        {/*</PageMenu>*/}
        {/*{*/}
        {/*    groupOptions.length === 0 &&*/}
        {/*        <div className={StyleUtil.combineClasses(commonStyle.ContentContainer)}>*/}
        {/*            <div className={styles.EmptyPanel}>Click <Icon className={styles.EmptyAddIcon}>add</Icon> to create a new {singleTitle.toLowerCase()}</div>*/}
        {/*        </div>*/}
        {/*}*/}
        {groupOptions.length > 0 &&
            <div className={StyleUtil.combineClasses(commonStyle.ContentContainer)}>
                <PageSummary type={title} title={title} titleActions={actionComponent}/>
                <div className={styles.Root}>
                    <div className={styles.TopMenu}>
                        <Autocomplete size={"small"}
                                      className={styles.SearchMenu}
                                      {...filterOptions}
                                      value={groupOptions.find((g) => g.value === groupId)}
                                      onChange={(event,value) => {
                                          if(value !== null) {
                                              history.push({
                                                  pathname: "/content/" + group + "/" + value.value
                                              });
                                          }
                                      }}
                                      renderOption={(props, option) => {
                                          return (
                                              <li {...props} key={option.value}>
                                                  {option.label}
                                              </li>
                                          );
                                      }}
                                      renderInput={(props) => <TextField label={"Group"} {...props}/>}
                                      getOptionLabel={(option:InputOption) => option.label}
                                      options={groupOptions}/>
                        <IconButton onClick={() => handleDeleteContentGroup()} className={styles.DeleteMenu}><OutlinedIcon>delete</OutlinedIcon></IconButton>
                        {/*<div className={styles.SearchPanel}>*/}
                        {/*   */}

                        {/*</div>*/}
                        {/*<div className={styles.DeletePanel}>*/}

                        {/*</div>*/}
                    </div>
                    {
                        contentGroup && <Box key={"detail_" + contentGroup.id} sx={{boxShadow: 2, borderRadius: "5px" }} className={styles.MasterPanel}>
                            <InputPanel
                                // handleClose={handleCloseNewDialog}
                                handleChange={handleUpdateContentGroup}
                                handleSave={handleSaveContentGroup}
                                // saveButton={setSaveContentGroupButton}

                                fields={[
                                    {id: "cgName", label: "Name", defaultValue: contentGroup.cgName , required: true , editable: true},
                                    {id: "cgDescription", label: "Description", defaultValue: contentGroup.cgDescription , required: false , editable: true},
                                    {id: "cgStatus", label: "Status", type:"select", options: statusOptions, defaultValue: contentGroup.cgStatus , required: false , editable: true},
                                    {id: "cgSortBy", label: "Sort By", type:"select", options: cgSortByOptions, defaultValue: contentGroup.cgSortBy, required: false , editable: true},
                                    {id: "cgAccessList", label: "User Policies", type: "multi", options: roleOptions ,defaultValue: contentGroup.cgAccessList ? contentGroup.cgAccessList : ["PUBLIC"] , required: false , editable: true},
                                ]}
                                layouts={[
                                    {label: "Info",
                                        ids:["cgName","cgDescription","cgStatus","cgSortBy","cgAccessList"],
                                        ids_grid:[12,12,6,6,12]},
                                ]}
                             />
                            <div className={styles.ActionPanel}>
                                <Button size={"small"}
                                        color="primary"
                                        onClick={handleClickUpdateContentGroup}
                                        className={styles.Button}>
                                    Update
                                </Button>
                            </div>
                        </Box>
                    }

                    <div className={styles.DetailPanel}>
                    {
                        <div className={styles.ActionPanel}>
                            { !content && <NewPopover
                                id={"newEntryButton"}
                                action={"doNewEntry"}
                                link={{icon: "add", label: "New Entry"}}
                                                      title={singleTitle +  " Entry"}
                                                      onAddNew={handleAddNewContent}/> }
                        </div>
                    }
                    {
                        contents.length > 0 && <div>
                            <Box sx={{boxShadow: 2, borderRadius: "5px" }} className={StyleUtil.combineClasses( styles.ItemsPanel,content !== undefined ? styles.ItemsPanelOut : "")}>
                            <List dense component="nav">
                                {
                                    contents.map( (content,index) => <ListItem key={content.id}
                                        secondaryAction={<IconButton onClick={() => handleDeleteContent(content)}><OutlinedIcon>delete</OutlinedIcon></IconButton>}>
                                        <ListItemButton onClick={() => handleEditContent(content)}>
                                            <ListItemText primary={content.ctTitle}
                                                          secondary={"Created : " +  ISODateToDate(content.ctCreatedDate)}/></ListItemButton>
                                    </ListItem>)

                                }
                            </List></Box></div>
                    }
                    {
                        <Box sx={{boxShadow: 2, borderRadius: "5px"}} className={StyleUtil.combineClasses( styles.ContentPanel,content !== undefined ? styles.ContentPanelIn : "")}>
                            <div className={styles.ContentActionPanel}>
                                <IconButton onClick={handleBack}><OutlinedIcon>arrow_back</OutlinedIcon></IconButton>
                                <Typography variant={"h6"} className={styles.ContentActionTitle}>{content?.ctTitle}</Typography>
                                { createContentPanel() }

                            </div>
                        </Box>
                    }
                    </div>
                </div>
            </div>
        }
        <input type='file' id='upload-content'
               accept=".xml,.json,.csv"
               onChange={() => doUploadFile()}
               ref={inputFile} style={{display: 'none'}}/>
    </>
}