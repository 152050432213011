import {useHistory} from "react-router";
import {useAppDispatch} from "../../redux/hook";
import {logoutAction} from "../../redux/features/login/loginSlice";
import {viewResetAction} from "../../redux/features/view/viewSlice";
import React, {useEffect} from 'react';
import {themeResetAction} from "../../redux/features/theme/themeSlice";
import {cmsRouteResetAction} from "../../redux/features/cmsroute/cmdRouteSlice";

export default function Logout(props:any) {
    localStorage.clear();
    const dispatch = useAppDispatch();
    const history = useHistory();


    useEffect(() => {
        if(window.location.hostname === "localhost") {
            history.replace("/login");
        } else {
            window.location.href = `${process.env.REACT_APP_LSI_HOST}/api/logoutFromAll`;
        }
    },[history])

    useEffect(() => {

        dispatch(logoutAction());
        dispatch(viewResetAction());
        dispatch(themeResetAction());
        dispatch(cmsRouteResetAction());
        props.doLogin(false);

    },[]);

    return (<React.Fragment></React.Fragment>);
}