import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, IconButton,
} from "@mui/material";
import React, {useState} from "react";
import InputPanel from "./InputPanel";
import InputDialogProps from "./InputDialogProps";
import styles from './InputDialog.module.scss';
import {InputModel} from "../../model/InputModel";
import SolidLinkButton from "../button/SolidLinkButton";
import ReverseSolidLinkButton from "../button/ReverseSolidLinkButton";
import {StyleUtil} from "../../cms/ui/setting/StyleUtil";
import OutlinedIcon from "../icon/OutlinedIcon";

export default function InputDialog(props: InputDialogProps) {
    const [disable, setDisable] = useState<boolean>()
    const [values , setValues] = useState<InputModel[]>([]);//initDefaultValue(props));
    // const [SaveButton, setSaveButton] = useState<React.ReactNode|null>(null);

    const handleChange = (id: string, values: InputModel[],index: number) => {
        let newValue = [...values];
        if(props.handleChange) {
            props.handleChange(id, index, newValue);
        };
        setValues(newValue);
    }

    const handleClickSave = () => {
        let newValues = [...values];
        newValues.forEach(value => {
            if(typeof value.defaultValue === 'string') {
                value.defaultValue = (value.defaultValue as string).trim();
            }
        })
        if(props.handleSave) {
            props.handleSave(newValues);
        }
    }

    return (<Dialog id={`${props.id}Dialog`} open={props.open}
        onClose={props.handleClose}
        className={props.fixHeight === undefined || props.fixHeight === true? styles.DialogRootFixHeight : styles.DialogRoot}
        maxWidth={"md"}
    >
            <DialogTitle >{props.title}
                <IconButton className={styles.CloseIcon}
                            onClick={(e) => {
                                props.handleClose(e)
                            }}>
                    <OutlinedIcon>close</OutlinedIcon>
                </IconButton>
            </DialogTitle>
            <DialogContent className={styles.DialogContent}>
                {
                    props.contentText &&  <DialogContentText>
                                            {props.contentText}
                                            </DialogContentText>
                }
                <InputPanel {...props}
                            // saveButton={setSaveButton}
                            handleChange={handleChange}
                            handleDisable={setDisable}/>
            </DialogContent>
            <DialogActions>
                <ReverseSolidLinkButton id={`${props.id}DialogCancelButton`} data-action={`doCloseDialog`} onClick={props.handleClose} color="secondary" className={styles.Button}>
                    Cancel
                </ReverseSolidLinkButton>
                <SolidLinkButton id={props.inserted ? `${props.id}DialogCreateButton` : `${props.id}DialogUpdateButton`} data-action={props.inserted ? "doCreateObject" : "doUpdateObject"}  size={"small"}
                            onClick={handleClickSave}
                            disabled={disable} color="primary"
                            className={StyleUtil.combineClasses(styles.Button, styles.ButtonInlineSpace) }>
                            {props.inserted ? "Create" : "Update"}
                </SolidLinkButton>
            </DialogActions>
        </Dialog>);
}