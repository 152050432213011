import styles from "./RoleField.module.scss"
import {
    Chip,
    Grid,
    Popover,
    TextField,
    Typography,
    Divider,
    InputLabel,
    Select,
    MenuItem,
    FormControl, Autocomplete, ListItemIcon, MenuList, ListItemText, Button
} from "@mui/material";
import {BaseTextFieldProps} from "@mui/material/TextField/TextField";
import React, {useState} from "react";
import {InputOption} from "../../model/InputModel";
import OutlinedIcon from "../icon/OutlinedIcon";

export interface RoleFilter {
    field: string
    value: string
    operator: string
    isEnabled: boolean
}

interface RoleFilterInternal extends  RoleFilter{
    index: number,
}

let globalTimeout:NodeJS.Timeout|null = null;

enum ValueType {
    single, multi, hidden
}

function RoleFilterToRoleFilterInternal(value: RoleFilter[]) {
    let newValue: RoleFilterInternal[] = [];
    value.forEach(v => {
        if(v.isEnabled === undefined) {
            newValue.push({...v, index: -1, isEnabled: true});
        } else {
            newValue.push({...v, index: -1});
        }
    })
    return newValue;
}

function RoleFilterInternalToRoleFilter(value: RoleFilterInternal[]) {
    let newValue: RoleFilter[] = [];
    value.forEach(v => {
        let temp:RoleFilter = { field: v.field, value: v.value, operator: v.operator, isEnabled: v.isEnabled};
        newValue.push(temp);
    })
    return newValue;
}

export default function RoleField(props: {
    inputProps?: BaseTextFieldProps
    filters: RoleFilter[]
    onChange?: (filter: RoleFilter[]) => void
}) {
    const roleFields:InputOption[] = [
        {label: "branch" , value: "branch"},
        {label: "contact" , value: "contact"},
        {label: "contactclass" , value: "contactclass"},
        {label: "customertype" , value: "customertype"},
        {label: "email" , value: "email"},
        {label: "sitecode" , value: "sitecode"}
    ]


    const [chips,setChips] = useState<RoleFilterInternal[]>(RoleFilterToRoleFilterInternal(props.filters));
    const [filterAnchor,setFilterAnchor] = useState<Element|null>(null);
    const [menuAnchor,setMenuAnchor] = useState<Element|null>(null);
    const [value, setValues] = useState<{ type: ValueType, values: string[] }>({type: ValueType.single, values: [""]});
    const [currentFilter, setCurrentFilter] = useState<RoleFilterInternal>({
        field: "",
        value: "",
        operator: "is",
        index: -1,
        isEnabled: true
    })

    const startGlobalWithDelay = (time:number,func: () => void ) => {
        globalTimeout = setTimeout(() => {
            globalTimeout = null;
            func();
        }, time);
    }

    const stopGlobalTimer = () => {
        if(globalTimeout !== null) {
            clearTimeout(globalTimeout);
            globalTimeout = null;
        }
    }

    const handleBlurOnFilterEditor = (event:React.FocusEvent) => {
        startGlobalWithDelay(100,() => {
            setFilterAnchor(null);
            setMenuAnchor(null);
        });
        event.stopPropagation()
        event.preventDefault()
    }

    const handleBlurOnChipEditor = (event:React.FocusEvent) => {
        startGlobalWithDelay(100,() => {
            setMenuAnchor(null);
        });
        event.stopPropagation()
        event.preventDefault()
    }

    const handleCancelAutoClose = (event:React.FocusEvent) => {
        if(globalTimeout !== null) {
            clearTimeout(globalTimeout);
            globalTimeout = null;
        }
        event.stopPropagation()
        event.preventDefault()
    }

    const handleClickChip = (event:React.MouseEvent<HTMLElement>,index:number) => {
        stopGlobalTimer();
        setCurrentFilter({
            field: chips[index].field,
            value: chips[index].value,
            operator: chips[index].operator,
            index: index,
            isEnabled: chips[index].isEnabled
        });
        let operator = chips[index].operator;
        if(operator.indexOf("exists") >= 0) {
            if(value.type !== ValueType.hidden) {
                setValues({type: ValueType.hidden, values: []});
            }
        } else if(operator.indexOf("is_one_of") >= 0) {
            if(value.type !== ValueType.multi) {
                setValues({type: ValueType.multi, values: []});
            }
        } else {
            if(value.type !== ValueType.single) {
                setValues({type: ValueType.single, values: []});
            }
        }
        setMenuAnchor(event.currentTarget);
        setFilterAnchor(null);
    }

    const updateChips = (chips: RoleFilterInternal[]) => {
        if(props.onChange) {
            props.onChange(RoleFilterInternalToRoleFilter(chips));
        }
    }
    const handleDeleteChip = () => {
        let newChips = [...chips];
        newChips.splice(currentFilter.index,1);
        setChips(newChips);
        setMenuAnchor(null);
        updateChips(newChips);
    }

    const handleClickAddFilter = (event:React.MouseEvent<HTMLElement>) => {
        setCurrentFilter({
            field: "",
            value: "",
            operator: "is",
            index: -1,
            isEnabled: true
        });
        setValues({type: ValueType.single, values: [""]});
        setFilterAnchor(event.currentTarget);
        setMenuAnchor(null);
    }

    const onChangeField = (option: any) => {
        if(option === null) return;
        setCurrentFilter({...currentFilter, field: option.value});
    }

    const onChangeOperator = (value:any) => {
        if(value.indexOf("exists") >= 0) {
            if(value.type !== ValueType.hidden) {
                setValues({type: ValueType.hidden, values: []});
            }
        } else if(value.indexOf("is_one_of") >= 0) {
            if(value.type !== ValueType.multi) {
                setValues({type: ValueType.multi, values: []});
            }
        } else {
            if(value.type !== ValueType.single) {
                setValues({type: ValueType.single, values: []});
            }
        }
        setCurrentFilter({...currentFilter, operator: value});
    }

    const handleFilterValueChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        setCurrentFilter({...currentFilter, value: event.currentTarget.value});
    }

    const handleAddFilter = () => {
        let newChips = [...chips, { field: currentFilter.field,
            value: currentFilter.value,
            operator: currentFilter.operator,
            index: -1,
            isEnabled: currentFilter.isEnabled
        }]
        setChips(newChips)
        setFilterAnchor(null);
        updateChips(newChips);
    }

    const handleUpdateFilter = () => {
        let newChips = [...chips];
        newChips[currentFilter.index] = { field: currentFilter.field,
                                        value: currentFilter.value,
                                        operator: currentFilter.operator,
                                        index: currentFilter.index,
                                        isEnabled: currentFilter.isEnabled}

        setChips(newChips)
        setFilterAnchor(null);
        updateChips(newChips);
    }

    const handleEditChip = (event:React.MouseEvent<HTMLElement>) => {
        setFilterAnchor(menuAnchor);
        setMenuAnchor(null);
    }

    const handleToggleItem = () => {
        if(currentFilter.index >= 0) {
            let newChips = [...chips];
            let index = currentFilter.index;
            if(newChips[index].operator.startsWith("exclude_")) {
                newChips[index].operator = newChips[index].operator.substring(8);
            } else {
                newChips[index].operator = "exclude_" + newChips[index].operator;
            }
            setChips(newChips);
            setMenuAnchor(null);
            updateChips(newChips);
        }
    }

    const handleActiveItem = () => {
        if(currentFilter.index >= 0) {
            let newChips = [...chips];
            let index = currentFilter.index;
            newChips[index].isEnabled = !currentFilter.isEnabled;
            setChips(newChips);
            setMenuAnchor(null);
            updateChips(newChips);
        }
    }

    let inputId = "";

    function getChipLabel(chip: RoleFilter) {
        let label = chip.field + ": " + chip.value;
        if(chip.operator.startsWith("exclude")) {
            return <span><span className={styles.ChipExcludeLabel}>NOT</span> {label}</span>;
        } else {
            return <span>{label}</span>;
        }
    }

    function isChipExclude(chip: RoleFilter) {
        if(chip.operator.startsWith("exclude")) {
            return true;
        } else {
            return false;
        }
    }

    function isChipEnabled(chip: RoleFilter) {
        return chip.isEnabled && roleFields.findIndex(option => option.value === chip.field) >= 0;
    }

    function getOptionValue(value: string, searchFields: InputOption[]) {
        for(let i=0;i<searchFields.length;i++) {
            if(searchFields[i].value === value) {
                return searchFields[i];
            }
        }
        if(searchFields.length > 0) {
            return null;
        } else {
            return null;
        }
    }

    function isComplete() {
        return currentFilter.field !== "" && currentFilter.value !== ""
    }

    const fontSize = 12;
    return <div className={styles.Root}>
        <div>{
            chips && chips.length > 0 && chips.map((chip,index) => {
                    let isEnabled = isChipEnabled(chip);
                    return <Chip variant="outlined"
                          label={getChipLabel(chip)}
                          size={"small"}
                          onClick={(event) => {
                              handleClickChip(event, index)
                          }}
                          className={isEnabled ? (isChipExclude(chip) ? styles.ChipExclude : styles.Chip) : styles.ChipDisable}/>
                }
            )
        }<span onClick={handleClickAddFilter} className={styles.AddFilterLabel}>+Add Filter</span></div>
        <Popover tabIndex={-1}
                 open={menuAnchor !== null}
                 onFocus={handleCancelAutoClose}
                 onBlur={handleBlurOnChipEditor}
                 anchorEl={menuAnchor}
                 onClose={() => {startGlobalWithDelay(300,() => setMenuAnchor(null))}}
                 disableEnforceFocus={true}
                 style={{pointerEvents: "none"}}
                 anchorOrigin={{
                     vertical: 'bottom',
                     horizontal: 'left',
                 }}
        >
            <MenuList className={styles.menuPanel}>
                { currentFilter.isEnabled &&
                    <MenuItem onClick={handleEditChip}>
                        <ListItemIcon><OutlinedIcon>edit</OutlinedIcon></ListItemIcon>
                        <ListItemText>Edit filter</ListItemText>
                    </MenuItem>
                }
                {
                    currentFilter.isEnabled && !currentFilter.operator.startsWith("exclude") &&
                    <MenuItem onClick={handleToggleItem}>
                        <ListItemIcon><OutlinedIcon>remove_circle_outline</OutlinedIcon></ListItemIcon>
                        <ListItemText>Exclude results</ListItemText>
                    </MenuItem>
                }
                {
                    currentFilter.isEnabled && currentFilter.operator.startsWith("exclude") &&
                    <MenuItem  onClick={handleToggleItem}>
                        <ListItemIcon><OutlinedIcon>add_circle_outline</OutlinedIcon></ListItemIcon>
                        <ListItemText>Include results</ListItemText>
                    </MenuItem>
                }
                {
                    currentFilter.isEnabled &&
                    <MenuItem  onClick={handleActiveItem}>
                        <ListItemIcon><OutlinedIcon>not_interested</OutlinedIcon></ListItemIcon>
                        <ListItemText>Disable</ListItemText>
                    </MenuItem>
                }
                {
                    currentFilter.isEnabled === false &&
                    <MenuItem  onClick={handleActiveItem}>
                        <ListItemIcon><OutlinedIcon>add_task</OutlinedIcon></ListItemIcon>
                        <ListItemText>Enable</ListItemText>
                    </MenuItem>
                }
                <MenuItem onClick={handleDeleteChip}>
                    <ListItemIcon><OutlinedIcon>delete</OutlinedIcon></ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                </MenuItem>
            </MenuList>
        </Popover>
        <Popover
            tabIndex={-1}
            onFocus={handleCancelAutoClose}
            onBlur={handleBlurOnFilterEditor}
            open={filterAnchor !== null}
            anchorEl={filterAnchor}
            onClose={() => {startGlobalWithDelay(300,() => setFilterAnchor(null))}}
            disableEnforceFocus={true}
            style={{pointerEvents: "none"}}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Grid container spacing={1}  className={styles.filterPanel}>
                <Grid item xs={12} md={12}>
                    <Typography variant={"h6"} className={styles.filterHeader}>Edit Filter</Typography>
                    <Divider/>
                </Grid>
                <Grid item xs={6}>
                    <Autocomplete autoHighlight
                                  onFocus={handleCancelAutoClose}
                                  onBlur={handleBlurOnFilterEditor}
                                  size={"small"}
                                  options={roleFields}
                                  value={getOptionValue(currentFilter.field,roleFields)}
                                  getOptionLabel={option => option.label}
                                  onChange={(event,value) => onChangeField(value) }
                                  renderInput={(props) => <TextField label="Field" autoFocus={true} {...props}/>}
    />
                </Grid>
                <Grid item xs={6}>
                    <FormControl key={`${inputId}`} size={"small"} fullWidth>
                        <InputLabel id={`${inputId}_label`}>Operator</InputLabel>
                        <Select
                            onFocus={handleCancelAutoClose}
                            onBlur={handleBlurOnFilterEditor}
                            labelId={`${inputId}_label`}
                            label={"Operator"}
                            required={true}
                            size={"small"}
                            value={currentFilter.operator}
                            onChange={(event) => onChangeOperator(event.target.value)}
                        >
                            <MenuItem value="is">is</MenuItem>
                            <MenuItem value="exclude_is">is not</MenuItem>
                            {/*<MenuItem value="contain">contains</MenuItem>*/}
                            {/*<MenuItem value="exclude_contain">does not contains</MenuItem>*/}
                        </Select>
                    </FormControl>
                </Grid>
                { value.type !== ValueType.hidden && <Grid item xs={12} md={12}>
                        {value.type === ValueType.single &&
                            <TextField onFocus={handleCancelAutoClose}
                                       onBlur={handleBlurOnFilterEditor}
                                       size={"small"}
                                       variant={"outlined"}
                                       label={"Value"}
                                       value={currentFilter.value}
                                       onChange={handleFilterValueChange}
                                       InputProps={{style: {fontSize: fontSize+1}}}
                                       InputLabelProps={{style: {fontSize: fontSize+1}}}
                                       fullWidth/>
                        }
                        {value.type === ValueType.multi &&
                            <TextField onFocus={handleCancelAutoClose}
                                       onBlur={handleBlurOnFilterEditor}
                                       size={"small"}
                                       variant={"outlined"}
                                       value={currentFilter.value}
                                       label={"Value1, Value2 ... "}
                                       InputProps={{style: {fontSize: fontSize+1}}}
                                       InputLabelProps={{style: {fontSize: fontSize+1}}}
                                       onChange={handleFilterValueChange}
                                       fullWidth/>
                        }
                    </Grid>
                }
            </Grid>
            <Grid item xs={12} md={12} className={styles.SavePanel}>
                <Button disabled={!isComplete()}
                        onClick={currentFilter.index >= 0 ? handleUpdateFilter: handleAddFilter}>{currentFilter.index >= 0 ? "Update" : "Add"}</Button>
            </Grid>
        </Popover>
    </div>
}