import {ImageCmd} from "../../../model/ImageCmd";
import React, {useState} from "react";
import {CardCmd} from "../../../model/CardCmd";
import {nanoid} from "nanoid";
import {Grid, TextField} from "@mui/material";
import AssetField from "../../../../ui/assetfield/AssetField";

let globalTimeout:NodeJS.Timeout|null = null;
let globalRefreshId:string = "";
let globalBlur: any;
let globalData: ImageCmd;
let globalIndex: number = 0;

export default function ImageActionSetting(props: {
    value: ImageCmd,
    onChange?: (newValue: ImageCmd) => void,
    onBlur?: (newValue: ImageCmd) => void,
    index: number
}) {
    const [cmd,setCmd] = useState<ImageCmd>(props.value);
    const [refreshId] = useState<string>(nanoid());

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        globalTimeout = setTimeout(() => {
            if (globalBlur) globalBlur(globalData)
            globalTimeout = null;
        }, 300);
    }

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        if(globalTimeout) {
            clearTimeout(globalTimeout);
            globalTimeout = null;
        }
        if(globalIndex != props.index) {
            if (globalBlur) globalBlur(globalData)
        }
        globalIndex = props.index;
        if(globalRefreshId !== ""  && refreshId !== globalRefreshId) {
            if(globalBlur) globalBlur(globalData)
        }
        globalBlur = props.onBlur;
        globalData = cmd;
    }

    return <Grid container spacing={2} sx={{paddingBlockStart: "10px"}}>
        <Grid item xs={12}>
            <AssetField size={"small"}
                        InputLabelProps={{shrink: true}}
                        fullWidth label={"Image URL"}
                        value={cmd.image_url}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        onChange={(event) => {
                            let newCmd = {...cmd , image_url: event.target.value}
                            setCmd(newCmd);
                            if(props.onChange) {
                                props.onChange(newCmd);
                                globalData = newCmd;
                            }
                        }}/>
        </Grid>
        <Grid item xs={12}>
            <TextField
                size={"small"}
                fullWidth
                value={cmd.image_href}
                inputProps={{sx: {paddingBlock: "5px"}}}
                InputLabelProps={{shrink: true}}
                label={"Image Link URL"}
                onChange={(event) => {
                    let newCmd = {...cmd , image_href: event.target.value}
                    setCmd(newCmd);
                    if(props.onChange) {
                        props.onChange({...cmd , image_href: event.target.value.trim()});
                        globalData = newCmd;
                    }
                }}
                onBlur={handleBlur}
                onFocus={handleFocus}
            />
        </Grid>
        {/*<Grid item xs={12}>*/}
        {/*    <TextField*/}
        {/*        size={"small"}*/}
        {/*        fullWidth*/}
        {/*        value={cmd.image_caption ? cmd.image_caption : ""}*/}
        {/*        inputProps={{sx: {paddingBlock: "5px"}}}*/}
        {/*        InputLabelProps={{shrink: true}}*/}
        {/*        label={"Caption"}*/}
        {/*        onChange={(event) => {*/}
        {/*            let newCmd = {...cmd , image_caption: event.target.value}*/}
        {/*            setCmd(newCmd);*/}
        {/*            if(props.onChange) {*/}
        {/*                props.onChange({...cmd , image_caption: event.target.value.trim()});*/}
        {/*                globalData = newCmd;*/}
        {/*            }*/}
        {/*        }}*/}
        {/*        onBlur={handleBlur}*/}
        {/*        onFocus={handleFocus}*/}
        {/*    />*/}
        {/*</Grid>*/}
    </Grid>
}