import {Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@mui/material";
import ReverseSolidLinkButton from "../button/ReverseSolidLinkButton";
import styles from "./InputDialog.module.scss";
import SolidLinkButton from "../button/SolidLinkButton";
import OutlinedIcon from "../icon/OutlinedIcon";


export interface DefaultDialogProp {
    id: string,
    inserted: boolean,
    title: string
    children: any
    onClose: () => void
    open: boolean
    tag: string
    onSave: (inserted:boolean) => void
}

export default function DefaultDialog(props:DefaultDialogProp) {
    return (
            <Dialog
                open={props.open}
                    onClose={props.onClose}
            >
                <DialogTitle>{props.title}</DialogTitle>
                    <DialogContent style={{minWidth:"270px"}}>
                <DialogContent>
                    {props.children}
                </DialogContent>
                </DialogContent>
                <DialogActions>
                    <ReverseSolidLinkButton id={`${props.id}DialogCancelButton`}
                                            data-action={`doCloseDialog`}
                                            onClick={props.onClose}
                                            color="secondary"
                                            className={styles.Button}>
                        Cancel
                    </ReverseSolidLinkButton>
                    <SolidLinkButton id={props.inserted ? `${props.id}DialogCreateButton` : `${props.id}DialogUpdateButton`} data-action={props.inserted ? "doCreateObject" : "doUpdateObject"}  size={"small"}
                                     onClick={() => props.onSave(props.inserted)}
                                     color="primary"
                                     className={styles.Button}>
                        {props.inserted ? "Create" : "Update"}
                    </SolidLinkButton>
                </DialogActions>
            </Dialog>
        )
}