import {
    Autocomplete, Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid, IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import React, {useEffect, useState} from "react";
import SharedObjects from "../../shared/editable/ShareObjects";
import {InputOption} from "../../../../model/InputModel";
import styles from "./ProductQuerySetting.module.scss";
import {AutocompleteRenderInputParams} from "@mui/material/Autocomplete/Autocomplete";
import DrillItemCmd from "../../../model/DrillItemCmd";
import OutlinedIcon from "../../../../ui/icon/OutlinedIcon";
import {jcr} from "../../../../datafetcher/JCRDataFetcher";
import DisplayField from "../../shared/displayfield/DisplayField";
import {SettingLabel} from "../../setting/settinglabel/SettingLabel";

interface AutoCompleteOption {
    drilldown: string,
    label: string,
    value: string,
    depth: number
    index: number
}

export default function ProductQuerySetting(props: {
                                                displayOptions?: InputOption[],
                                                imageOptions?: InputOption[],
                                                widgetData: any,
                                                saveWidgetState: () => void,
                                                widgetCallback: (action:string, value: any) => void,
                                                // onChangePreview: (drilldown:string, previewId:string) => void,
                                                sharedObject?: SharedObjects
                                           }
) {
    const {widgetData} = props;
    //const {widgetProps} = widgetData;

    if(!widgetData.widgetProps['content']) {
        widgetData.widgetProps['content'] = {}
    }
    const [emptyMessage,setEmptyMessage] = useState<string>(widgetData.widgetProps['content'] && widgetData.widgetProps['content']['emptyMessage'] ? widgetData.widgetProps['content']['emptyMessage'] : "There are no stocked products for this category.");
    const [idField,setIdField] = useState<string>(widgetData.widgetProps['content']['titleField'] ? widgetData.widgetProps['content']['idField'] : "_lsi_cn_ProductID");
    const [titleField,setTitleField] = useState<string>(widgetData.widgetProps['content']['titleField'] ? widgetData.widgetProps['content']['titleField'] : "_lsi_cn_ProductID");
    const [imageField,setImageField] = useState<string>(widgetData.widgetProps['content']['imageField'] ? widgetData.widgetProps['content']['imageField'] : "_lsi_cn_ImageThumb");
    const [descField,setDescField] = useState<string>(widgetData.widgetProps['content']['descField'] ? widgetData.widgetProps['content']['descField'] : "_lsi_cn_Description");
    const [priceField,setPriceField] = useState<string>(widgetData.widgetProps['content']['priceField'] ? widgetData.widgetProps['content']['priceField'] : "_lsi_cn_Price");
    const [uomField,setUOMField] = useState<string>(widgetData.widgetProps['content']['uomField'] ? widgetData.widgetProps['content']['uomField'] : "_lsi_cn_PriceUOM");
    const [productPrefix,setProductPrefix] = useState<string>(widgetData.widgetProps['content']['productPrefix'] ? widgetData.widgetProps['content']['productPrefix'] : "");
    const [varName,setVarName] = useState<string>(widgetData.widgetProps['content']['content1_drilldown_varname'] ? widgetData.widgetProps['content']['content1_drilldown_varname'] : "");
    const [maxCol,setMaxCol] = useState<string>(widgetData.widgetProps['content']['maxCols'] ? widgetData.widgetProps['content']['maxCols'] : "4");

    const [hasFacet,setHasFacet] = useState<boolean>(widgetData.widgetProps['content']['hasFacet'] !== undefined ? widgetData.widgetProps['content']['hasFacet'] : true);
    const [fullSizeBreadcrumb,setFullSizeBreadcrumb] = useState<boolean>(widgetData.widgetProps['content']['fullSizeBreadcrumb'] !== undefined ? widgetData.widgetProps['content']['fullSizeBreadcrumb'] : false);
    const [hasBreadCrumb,setHasBreadCrumb] = useState<boolean>(widgetData.widgetProps['content']['hasBreadCrumb']  !== undefined ? widgetData.widgetProps['content']['hasBreadCrumb'] : true);
    const [breadcrumbSeparator,setBreadcrumbSeparator] = useState<String>(widgetData.widgetProps['content']['breadcrumbSeparator'] !== undefined ? widgetData.widgetProps['content']['breadcrumbSeparator'] : "/");
    const [breadcrumbSpace,setBreadcrumbSpace] = useState<String>(widgetData.widgetProps['content']['breadcrumbSpace'] !== undefined ? widgetData.widgetProps['content']['breadcrumbSpace'] : "0.1rem");

    const [numberOfItems,setNumberOfItems] = useState<number[]>(widgetData.widgetProps['content']['numberOfItems'] ? widgetData.widgetProps['content']['numberOfItems'] : [12,30,60]);
    const [defaultNumberOfItem,setDefaultNumberOfItem] = useState<number>(widgetData.widgetProps['content']['defaultNumberOfItem'] ? widgetData.widgetProps['content']['defaultNumberOfItem'] : 12);

    const [categoryPrefix1,setCategoryPrefix1] = useState<string>(widgetData.widgetProps['content']['categoryPrefix1'] ? widgetData.widgetProps['content']['categoryPrefix1'] : "");
    const [categoryPrefix2,setCategoryPrefix2] = useState<string>(widgetData.widgetProps['content']['categoryPrefix2'] ? widgetData.widgetProps['content']['categoryPrefix2'] : "");
    const [categoryPrefix3,setCategoryPrefix3] = useState<string>(widgetData.widgetProps['content']['categoryPrefix3'] ? widgetData.widgetProps['content']['categoryPrefix3'] : "");
    const [drilldown1, setDrilldown1] = useState<string>(widgetData.widgetProps['content']['content1_drilldown'] ? widgetData.widgetProps['content']['content1_drilldown'] : "");
    const [drilldown2, setDrilldown2] = useState<string>(widgetData.widgetProps['content']['content2_drilldown'] ? widgetData.widgetProps['content']['content2_drilldown'] : "");
    const [drilldown3, setDrilldown3] = useState<string>(widgetData.widgetProps['content']['content3_drilldown'] ? widgetData.widgetProps['content']['content3_drilldown'] : "");
    const [allDrillDowns, setAllDrilldowns] = useState<any>({});

    const [previewList,setPreviewList] = useState<AutoCompleteOption[]>([]);
    const [currentPreview,setCurrentPreview] = useState<AutoCompleteOption|null>();


    const handleChange = (value: any, key: string) => {
        widgetData.widgetProps["content"][key] = value;
        props.saveWidgetState();
    }

    const handleMultipleChange = (values: string[], keys: string[]) => {
        for(let i=0;i<values.length;i++) {
            widgetData.widgetProps["content"][keys[i]] = values[i];
        }
        props.saveWidgetState();
    }

    useEffect(() => {
        let collectName: AutoCompleteOption[] = [];
        let previewOption: AutoCompleteOption | undefined = undefined;
        let previewId = widgetData.widgetProps['content']['previewId'];
        let existNames: string[] = [];
        for(const [drilldown,value] of Object.entries(allDrillDowns)) {
            let response = value as any;
            let drillData: DrillItemCmd = JSON.parse(response.drillData);
            let drillArray: { data: DrillItemCmd, depth: number }[] = [];
            let count: number = 0;
            drillArray.push({data: drillData, depth: 0});
            while (drillArray.length > 0) {
                let currentDrill = drillArray.pop();
                if (currentDrill) {
                    currentDrill.data.subItems.forEach(item => drillArray.push({
                        data: item,
                        depth: currentDrill!.depth + 1
                    }));
                    if (existNames.indexOf(currentDrill.data.name) < 0) {
                        let option = {
                            drilldown: drilldown,
                            label: currentDrill.data.name,
                            value: currentDrill.data.indexId,
                            depth: currentDrill.depth,
                            index: count++
                        };

                        if (currentDrill.data.indexId === previewId) {
                            previewOption = option
                        }
                        if(currentDrill.data.subItems.length === 0) {
                            collectName.push(option);
                            existNames.push(currentDrill.data.name);
                        }
                    }
                }
            }
        }

        if (collectName.length > 0) {
            if (previewOption) {
                setCurrentPreview(previewOption)
            } else {
                if(previewId === "" || previewId === undefined) {
                    setCurrentPreview(collectName[0])
                    props.widgetCallback('preview',{
                        drilldown: collectName[0].drilldown ,
                        previewId: collectName[0].value})
                    // props.onChangePreview(collectName[0].drilldown, collectName[0].value);
                } else {
                    setCurrentPreview( {
                        drilldown: "",
                            label: previewId,
                            value: previewId,
                            depth: 0,
                        index: -1
                    });
                    //props.onChangePreview("", previewId);
                    props.widgetCallback('preview',{
                        drilldown: "" ,
                        previewId: previewId})
                }
            }
        }
        setPreviewList(collectName);
    },[allDrillDowns]);

    useEffect(() => {
        let drilldowns:string[] = [];

        if(drilldown1 && drilldown1 !== "") drilldowns.push(drilldown1);
        if(drilldown2 && drilldown2 !== "") drilldowns.push(drilldown2);
        if(drilldown3 && drilldown3 !== "") drilldowns.push(drilldown3);

        drilldowns = drilldowns.filter((value, index, currentArray) => currentArray.indexOf(value) === index);
        if(drilldowns.length > 0) {
            let allPromises:Promise<any>[] = []
            for(let i=0;i<drilldowns.length;i++) {
                if(!allDrillDowns[drilldowns[i]]) {
                    allPromises.push(jcr.drilldown.query(drilldowns[i]));
                }
            }
            if(allPromises.length > 0) {
                let newAllDrillDowns = {...allDrillDowns};
                Promise.all(allPromises).then((responses: any[]) => {
                    for (let i = 0; i < responses.length; i++) {
                        newAllDrillDowns[responses[i].data.response.id] = responses[i].data.response;
                    }
                    setAllDrilldowns(newAllDrillDowns);
                });
            }
        }
    },[drilldown1,drilldown2,drilldown3]);

    function createDrilldown(props: {
                                    label: string,
                                    name: string,
                                    value: string,
                                    options: InputOption[],
                                    setDrilldown: any}) {
        return <FormControl size={"small"} fullWidth>
            <InputLabel id={"drilldown_selection"}>{props.label}</InputLabel>
            <Select
                label={props.label}
                id={"drilldown_selection"}
                onChange={(event) => {
                    handleChange(event.target.value.trim(), props.name)
                    props.setDrilldown(event.target.value)
                }}
                value={props.value}
            >
                <MenuItem value={""}>{"None"}</MenuItem>
                {
                    props.options.map((option,index) => {
                        return <MenuItem key={`drilldown_selection_${index}`} value={option.value}>{option.label}</MenuItem>
                    })
                }
            </Select>
        </FormControl>
    }


    const createAutoComplete = (label:string,name:string,value:string,setFunction: (value:string) => void,displayOptions:InputOption[]) => {
        return <DisplayField label={label} name={name} value={value} setFunction={setFunction}
                             handleChange={handleChange} displayOptions={displayOptions}/>
    }

    function createDefaultItem() {
        return <FormControl size={"small"} fullWidth>
            <InputLabel id={"numberofitems_selection"}>Default Item</InputLabel>
            <Select
                label={""}
                id={"numberofitems_selection"}
                onChange={(event) => {
                    handleChange(event.target.value, "defaultNumberOfItem");
                    setDefaultNumberOfItem(event.target.value as number);
                }}
                value={defaultNumberOfItem}
            >
                {
                    numberOfItems.map((number,index) => {
                        return <MenuItem key={`numberofitems_selection_${index}`} value={number}>{number}</MenuItem>
                    })
                }
            </Select>
        </FormControl>
    }

    return <Grid container spacing={2}>
        <Grid item xs={4}>
            <SettingLabel title={"Variable Name"}/>
        </Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={""}
                       value={varName}
                       onChange={(event) => {
                           let value = event.target.value.trim();
                           handleMultipleChange([value,value,value], ["content1_drilldown_varname","content2_drilldown_varname","content3_drilldown_varname"])
                           setVarName(event.target.value);
                       }}/>
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"Product URL Prefix"}/>
        </Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={""}
                       value={productPrefix}
                       onChange={(event) => {
                           handleChange(event.target.value.trim(), "productPrefix")
                           setProductPrefix(event.target.value);
                       }}/>
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"1. Drilldown"}/>
        </Grid>
        <Grid item xs={8}>
            { createDrilldown({
                label: "",
                name: "content1_drilldown",
                value: drilldown1,
                setDrilldown: setDrilldown1,
                options: props.sharedObject ? props.sharedObject!.drillDownOptions : []}) }
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"1. Drill URL Prefix"}/>
        </Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={""}
                       value={categoryPrefix1}
                       onChange={(event) => {
                           handleChange(event.target.value.trim(), "categoryPrefix1")
                           setCategoryPrefix1(event.target.value);
                       }}/>
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"2. Drilldown"}/>
        </Grid>
        <Grid item xs={8}>
            { createDrilldown({
                label: "",
                name: "content2_drilldown",
                value: drilldown2,
                setDrilldown: setDrilldown2,
                options: props.sharedObject ? props.sharedObject!.drillDownOptions : []}) }
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"2. Drill URL Prefix"}/>
        </Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={""}
                       value={categoryPrefix2}
                       onChange={(event) => {
                           handleChange(event.target.value.trim(), "categoryPrefix2")
                           setCategoryPrefix2(event.target.value);
                       }}/>
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"3. Drilldown"}/>
        </Grid>
        <Grid item xs={8}>
            { createDrilldown({
                label: "",
                name: "content3_drilldown",
                value: drilldown3,
                setDrilldown: setDrilldown3,
                options: props.sharedObject ? props.sharedObject!.drillDownOptions : []}) }
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"3. Drill URL Prefix"}/>
        </Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={"3"}
                       value={categoryPrefix3}
                       onChange={(event) => {
                           handleChange(event.target.value.trim(), "categoryPrefix3")
                           setCategoryPrefix3(event.target.value);
                       }}/>
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"Columns"}/>
        </Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={""}
                       value={maxCol}
                       onChange={(event) => {
                           handleChange(event.target.value.trim(), "maxCols")
                           setMaxCol(event.target.value);
                       }}/>
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"List of items"}/>
        </Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={""}
                       defaultValue={numberOfItems.join(", ")}
                       onChange={(event) => {
                           let value = event.target.value.trim();
                           let numbers:number[] = [];
                           value.split(",").forEach(v => {
                               let n = parseInt(v);
                               if(isNaN(n) === false) {
                                   if(n <= 100) {
                                       numbers.push(n);
                                   }

                               }
                           });

                           if(numbers.length > 0) {
                               handleChange(numbers, "numberOfItems")
                               if (numbers.indexOf(defaultNumberOfItem) < 0) {
                                   handleChange(numbers, "defaultNumberOfItem")
                                   setDefaultNumberOfItem(numbers[0]);
                               }
                               numbers.sort((n1, n2) => n1 - n2);
                               numbers = numbers.filter((v, i, a) => a.indexOf(v) === i);
                               setNumberOfItems(numbers);
                           }
                       }}/>
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"Default Item"}/>
        </Grid>
        <Grid item xs={8}>
            {createDefaultItem()}
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"Full size breadcrumb"}/>
        </Grid>
        <Grid item xs={8}  style={{paddingBlockStart: "0px"}}>
            <FormControlLabel className={styles.DefaultFontColor} control={<Checkbox
                checked={fullSizeBreadcrumb}
                onChange={(event: any,value:boolean) => {
                    handleChange(event.target.checked, "fullSizeBreadcrumb")
                    setFullSizeBreadcrumb(event.target.checked);
                }}/>} label=""/>
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"Show Facet"}/>
        </Grid>
        <Grid item xs={8}  style={{paddingBlockStart: "0px"}}>
            <FormControlLabel className={styles.DefaultFontColor} control={<Checkbox
                checked={hasFacet}
                onChange={(event: any,value:boolean) => {
                    handleChange(event.target.checked, "hasFacet")
                    setHasFacet(event.target.checked);
                }}/>} label=""/>
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"Show Breadcrumb"}/>
        </Grid>
        <Grid item xs={8} style={{paddingBlockStart: "0px"}}>
            <FormControlLabel className={styles.DefaultFontColor} control={<Checkbox
                checked={hasBreadCrumb}
                onChange={(event: any,value:boolean) => {
                    handleChange(event.target.checked, "hasBreadCrumb")
                    setHasBreadCrumb(event.target.checked);
                }}/>} label=""/>
        </Grid>
        {hasBreadCrumb && <>
            <Grid item xs={4}>
                <SettingLabel title={"Breadcrumb separator"}/>
            </Grid>
            <Grid item xs={8}>
                <TextField size={"small"}
                           fullWidth={true}
                           label={""}
                           value={breadcrumbSeparator}
                           onChange={(event) => {
                               handleChange(event.target.value.trim(), "breadcrumbSeparator")
                               setBreadcrumbSeparator(event.target.value);
                           }}/>
            </Grid>
            <Grid item xs={4}>
                <SettingLabel title={"Space between Breadcrumb"}/>
            </Grid>
            <Grid item xs={8}>
                <TextField size={"small"}
                           fullWidth={true}
                           label={""}
                           value={breadcrumbSpace}
                           onChange={(event) => {
                               handleChange(event.target.value.trim(), "breadcrumbSpace")
                               setBreadcrumbSpace(event.target.value);
                           }}/>
            </Grid>
            </>
        }
        <Grid item xs={4}>
            <SettingLabel title={"Image Field"}/>
        </Grid>
        <Grid item xs={8}>
            {createAutoComplete("","imageField",imageField,setImageField,props.imageOptions!)}
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"ID Field"}/>
        </Grid>
        <Grid item xs={8}>
            {createAutoComplete("","idField",idField,setIdField,props.displayOptions!)}
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"Title Field"}/>
        </Grid>
        <Grid item xs={8}>
            {createAutoComplete("","titleField",titleField,setTitleField,props.displayOptions!)}
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"Description Field"}/>
        </Grid>
        <Grid item xs={8}>
            {createAutoComplete("","descField",descField,setDescField,props.displayOptions!)}
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"Price Field"}/>
        </Grid>
        <Grid item xs={8}>
            {createAutoComplete("","priceField",priceField,setPriceField,props.displayOptions!)}
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"UOM Field"}/>
        </Grid>
        <Grid item xs={8}>
            {createAutoComplete("","uomField",uomField,setUOMField,props.displayOptions!)}
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"Empty Message"}/>
        </Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                       fullWidth={true}
                       multiline={true}
                       maxRows={4}
                       label={""}
                       value={emptyMessage}
                       onChange={(event) => {
                           handleChange(event.target.value.trim(), "emptyMessage")
                           setEmptyMessage(event.target.value);
                       }}/>
        </Grid>
        <Grid item xs={10}>
            <Autocomplete
                autoHighlight
                key={"drilldown_preview_" + currentPreview?.label}
                fullWidth
                freeSolo={true}
                size={"small"}
                id="drilldown_preview"
                options={previewList}
                value={currentPreview?.label}
                getOptionLabel={(option) => {
                    if(typeof option === 'string') {
                        return option;
                    } else {
                        return option.label
                    }
                }}
                renderOption={(props,_option) => {
                    if(typeof _option === 'string') {
                        let option = _option as string;
                        return <Box key={"key_example_string"} component="li" {...props}>
                            <div>{option}</div>
                        </Box>
                    } else {
                        let option = _option as AutoCompleteOption;
                        return <Box key={"key_example_" + option.index} component="li" {...props}>
                            <div>{option.label}</div>
                        </Box>
                    }
                }}
                onChange={(event,option: any) => {
                    if(option != null) {
                        if (typeof option === 'string') {
                            setCurrentPreview({
                                drilldown: "",
                                label: option,
                                value: option,
                                depth: 0,
                                index: -1
                            });
                            // props.onChangePreview("", option);
                            props.widgetCallback('preview',{
                                drilldown: "" ,
                                previewId: option});
                        } else {
                            setCurrentPreview(option);
                            // props.onChangePreview(option.drilldown, option.value);
                            props.widgetCallback('preview',{
                                drilldown: option.drilldown ,
                                previewId: option.value});
                        }
                    }
                }}
                renderInput={(params:AutocompleteRenderInputParams) =>
                    <TextField {...params}
                               label="Preview"
                    />
                }
            />
        </Grid>
        <Grid item xs={2} style={{paddingInlineStart: "5px"}}>
            <IconButton onClick={() => {
                let value = widgetData.widgetProps['content']['previewId'];
                if(!value) {
                    value = "";
                }
                navigator.clipboard.writeText(value);
                document.execCommand("copy");
            }
            }><OutlinedIcon>content_copy</OutlinedIcon></IconButton>
        </Grid>
    </Grid>
}