import BaseIcon, {BaseIconProp} from "../baseicon/BaseIcon";
import styles from "./WidgetIcon.module.scss"
import OutlinedIcon from "../../../../ui/icon/OutlinedIcon";

export interface WidgetIconProp {
    icon: string,
    action: string,
    onClick: (action:string) => void
}

export default function WidgetIcon(props: BaseIconProp & WidgetIconProp) {
    return <BaseIcon tooltip={props.tooltip} label={props.label}>
        <div className={styles.Root} onClick={() => {
            props.onClick(props.action);
        }
        }>
            <OutlinedIcon>{props.icon}</OutlinedIcon>
        </div>
    </BaseIcon>
}