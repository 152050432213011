import {
    Card,
    CardContent,
    CardMedia,
    Grid,
    IconButton, List, ListItem, ListItemButton,
    Menu,
    MenuItem,
    Popover,
    TextField,
    Tooltip,
} from "@mui/material";
import styles from "./ViewItem.module.scss"
import OutlinedIcon from "../icon/OutlinedIcon";
import React, {useRef, useState} from "react";
import HttpClient from "../../datafetcher/HttpClient";
import {useHistory} from "react-router";
import {SetLocalItem} from "../../util/LocalStorageUtil";
import {jcr} from "../../datafetcher/JCRDataFetcher";
import StorefrontCmd from "../../model/jcr/StorefrontCmd";
import StorefrontPopover from "../popover/StorefrontPopover";


export default function ViewItem(props: {
                                        id: string | null,
                                        title: string,
                                        orgCode: string,
                                        subTitle?: string,
                                        type: "view"|"header"|"footer",
                                        storefronts: StorefrontCmd[],
                                        logo: string,
                                        modifiedAt: number,
                                        doDelete?: () => void,
                                        size?: "small"|"medium",
                                        doSelectView: (id: string | null, title: string) => void},
                                        ) {
    const textRef = useRef<HTMLInputElement>()
    const [newEl, setNewEl] = useState<null | HTMLElement>(null);
    const [storefrontEl, setStorefrontEl] = useState<null | HTMLElement>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const history = useHistory();
    const [edit, setEdit] = useState<boolean>(false);
    const [title, setTitle] = useState<string>(props.title)
    const updateChange = () => {
        if(title.trim() === "") {
            setTitle(props.title);
        } else {
            HttpClient.get("/api/v0/jcr/orgs/" + props.orgCode + "/views/" +  props.id).then((response) => {
                let data = response.data.response;
                data.viewName = title.trim();
                HttpClient.post("/api/v0/jcr/org/" +props.orgCode + "/lite/views" , data).then(() => {

                })
            })
        }
        setEdit(false)
    }

    const handleAddNewWhenClick = () => {
        if(textRef) {
            let value = textRef?.current?.value;
            if(value && value !== "") {
                let data: any = {};
                data['viewName'] = value;
                data['createdAt'] = new Date().getTime();
                data['modifiedAt'] = new Date().getTime();
                HttpClient.post("/api/v0/jcr/org/" + props.orgCode + "/lite/views" , data).then((response) => {
                    history.push({
                        pathname: history.location.pathname + "/" + response.data.response.viewName + "/" + response.data.response.id ,
                        search: history.location.search
                    })
                }).catch((e) => {
                    console.log(e);
                })
                setNewEl(null);
            }
        }
    }

    const handleAddNewFromKeyboard = (event:any) => {
        if(event.key === "Enter") {
            let value = event.target.value.trim();
            if(value && value !== "") {
                let data: any = {};
                data['viewName'] = value;
                data['createdAt'] = new Date().getTime();
                data['modifiedAt'] = new Date().getTime();
                HttpClient.post("/api/v0/jcr/org/" + props.orgCode + "/lite/views" , data).then((response) => {
                    history.push({
                        pathname: history.location.pathname + "/" + response.data.response.viewName + "/" + response.data.response.id ,
                        search: history.location.search
                    })
                }).catch((e) => {
                    console.log(e);
                })
                setNewEl(null);
            }

            event.stopPropagation();
            event.preventDefault()
        }
    }

    function createNewMenus() {
        const open = Boolean(newEl);

        return <><div className={styles.CircleButton} onClick={(event) => {
            setNewEl(event.currentTarget)
            event.stopPropagation()
            event.preventDefault()
        }}>
                <OutlinedIcon>chevron_right</OutlinedIcon>
            </div>
            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                sx={{ transform: "translate(15px,-15px)"}}
                anchorEl={newEl}
                open={open}
                onClose={() => setNewEl(null)}
            >
                <Grid container spacing={1} className={styles.NewLabelPanel}>
                    <Grid item xs={2}><span className={styles.NewLabelTitle}>Page Name</span></Grid>
                    <Grid item xs={8}><TextField inputRef={textRef}  autoFocus={true} size={"small"} onKeyPress={handleAddNewFromKeyboard}/></Grid>
                    <Grid item xs={2} onClick={handleAddNewWhenClick}><IconButton><OutlinedIcon>navigate_next</OutlinedIcon></IconButton></Grid>
                </Grid>
            </Popover>
        </>
    }

    function openPreview(storefront: StorefrontCmd) {
        if(props.id !== null) {
            let query: any;
            let fieldName = "";
            if (props.type === "view") {
                query = jcr.view.query(props.id)
                fieldName = "viewData";
            } else if (props.type === "header") {
                query = jcr.view.query(props.id)
                fieldName = "headerData";
            } else {
                query = jcr.view.query(props.id)
                fieldName = "footerData";
            }

            if (query !== undefined) {
                query.then((response: any) => {
                    let previewData: any = {
                        id: "previewData",
                        viewName: title,
                        viewData: response.data.response[fieldName]
                    }
                    SetLocalItem("previewData", JSON.stringify(previewData));
                    window.open(`/live/preview/orgName/view/internal/${storefront.id}`, '_blank', 'noreferrer');
                })
            }
        }
    }
    function createNormalMenus() {
        return <>
            {props.storefronts.length > 0 && <><IconButton onClick={(event) => {
                // history.push({
                //     pathname: `${history.location.pathname}/${title}/${props.id}`,
                //     search: "view=true"
                // })
                if (props.storefronts.length === 1) {
                    openPreview(props.storefronts[0]);
                } else {
                    setStorefrontEl(event.currentTarget);
                }
                event.stopPropagation();
                event.preventDefault();
            }}><OutlinedIcon>visibility</OutlinedIcon></IconButton>
                <StorefrontPopover
                    popoverProps={
                        {
                            tabIndex: -1,
                            open: Boolean(storefrontEl),
                            anchorEl: storefrontEl,
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'center',
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "center"
                            },
                            style: {
                                pointerEvents: "none"
                            },
                        }
                    }
                    onBlur={(event) => setStorefrontEl(null)}
                    storefronts={props.storefronts}
                    onChangeStorefront={openPreview}/>
            </>}
            <IconButton onClick={(event) => {
                history.push({
                    pathname: `${history.location.pathname}/${title}/${props.id}`,
                    search: history.location.search
                })
                event.stopPropagation();
                event.preventDefault();
            }
            }><OutlinedIcon>edit</OutlinedIcon></IconButton>
            <IconButton onClick={(event) => {
                setAnchorEl(event.currentTarget)
                event.stopPropagation();
                event.preventDefault()
            }}><OutlinedIcon>more_horizontal</OutlinedIcon></IconButton>
            <Menu
                style={{pointerEvents: "none"}}
                anchorEl={anchorEl}
                open={anchorEl !== null}
                onBlur={() => setAnchorEl(null)}
                hideBackdrop={true}>
                <div style={{pointerEvents: "auto"}}>
                    <MenuItem dense={true} onClick={(event) => {
                        setEdit(true);
                        event.stopPropagation();
                        event.preventDefault()
                    }}>
                        <OutlinedIcon>edit_note</OutlinedIcon> &nbsp;Rename
                    </MenuItem>
                    <MenuItem dense={true} onClick={(event) => {
                        HttpClient.get("/api/v0/jcr/orgs/" + props.orgCode + "/clone/views/" + props.id)
                            .then((response) => {
                                let id = response.data.response;
                                history.push({
                                    pathname: `${history.location.pathname}/${title}/${id}`,
                                    search: history.location.search
                                })
                            })

                        event.stopPropagation();
                        event.preventDefault();
                    }
                    }>
                        <OutlinedIcon>content_copy</OutlinedIcon> &nbsp;Duplicate
                    </MenuItem>
                    <MenuItem dense={true} onClick={(event) => {

                        HttpClient.download(`Page-${title}-${props.id}.gz`,
                            "/api/v0/jcr/orgs/" + props.orgCode + "/download/views/" + props.id);

                        event.stopPropagation();
                        event.preventDefault();
                    }


                    }>
                        <OutlinedIcon>download</OutlinedIcon> &nbsp;Download
                    </MenuItem>
                    {props.doDelete && <MenuItem dense={true} onClick={(event) => {
                        HttpClient.delete("/api/v0/jcr/orgs/" + props.orgCode + "/views/" + props.id)
                            .then((response) => {
                                if (props.doDelete) {
                                    props.doDelete();
                                }
                            })

                        event.stopPropagation();
                        event.preventDefault();
                    }
                    }>
                        <OutlinedIcon>delete</OutlinedIcon> &nbsp;Remove
                    </MenuItem>}
                </div>
            </Menu>
        </>;
    }

    return <Card className={styles.Root + " " + (anchorEl === null && newEl === null && storefrontEl === null? "" : styles.RootSelected) + " " + (props.size === "small" ? styles.RootSmall : "")} onClick={() => {
        props.doSelectView(props.id, title);
    }}>
        <CardMedia
            sx={{height: props.size === "small" ? 150 : 260, "objectPosition": "top"}}
            image={`${props.logo}?${props.modifiedAt}`}
            title={props.title}
            component="img"
            loading={"lazy"}
            className={styles.Media}
            onError={(event:any) => {
                let object:any = event.currentTarget;
                object.onerror = "";
                object.src = "/images/transparent.png";
                return true;
            }}
        />
        <CardContent className={props.size === "small" ? styles.ContentSmall : styles.Content}>
            <div className={styles.ActionPanel + " " + (anchorEl === null && newEl === null && storefrontEl === null? "" : styles.ActionPanelSelected)}>
                {props.id !== null && createNormalMenus()}
                {props.id === null && createNewMenus()}
            </div>
            <div className={props.size === "small" ? styles.TitleSmall : styles.Title}>
                {edit && <TextField autoFocus={true} value={title} size={"small"}
                                    onClick={(event) => {
                                        event.stopPropagation()
                                        event.preventDefault()
                                    }}

                                    onChange={(event) => {
                                        setTitle(event.target.value);
                                    }}

                                    onKeyPress={(event) => {
                                        if (event.key === "Enter") {
                                            updateChange()
                                        }
                                    }}

                                    onBlur={event => {
                                        updateChange();
                                    }}

                />}
                {edit === false && <Tooltip title={title}><span>{title}</span></Tooltip>}
            </div>
            {edit === false && props.subTitle && <div className={styles.SubTitle}>{props.subTitle}</div>}
        </CardContent>
    </Card>;
}
