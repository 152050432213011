export function ISODateToDate(date: string) {
    let array = date.split("-");
    if(array.length === 3) {
        return array[1] + "/" + array[2] + "/" + array[0];
    } else {
        return date;
    }
}

export function DateToISODate(date: string) {
    let array = date.split("/");
    if(array.length === 3) {
        return array[2] + "-" + array[0] + "-" + array[1];
    } else {
        return date;
    }
}

export function CurrentISODate() {
    let date = new Date().toISOString();
    let index = date.indexOf("T");
    date = date.substring(0,index);
    return date;
}

export function ToEndISODate() {
    return "2099-01-01";
}