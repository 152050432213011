import {
    Grid
} from "@mui/material";
import React, {useState} from "react";
import {nanoid} from "nanoid";
import {SettingGroup, SettingOption} from "../../../model/SettingOption";
import SectionHeightSetting from "../../setting/sectionheightsetting/SectionHeightSetting";


export default function VerticalSectionSetting(props: {
    widgetData: any,
    saveWidgetState: () => void}) {
    const widgetProps = props.widgetData.sectionProps;

    // const handleChange = (value: any, key: string) => {
    //     widgetProps["content"][key] = value;
    //     props.saveWidgetState();
    // }

    const handleChangeHeight = (height: string) => {
        props.widgetData.sectionProps['bt_vertical_panel']['style'].default = false;
        props.widgetData.sectionProps['bt_vertical_panel']['style']['dimension'] = {...props.widgetData.sectionProps['bt_vertical_panel']['style']['dimension'] , height: height}
        props.saveWidgetState();
    }

    // if(!widgetProps['content']['gap']) {
    //     widgetProps['content']['gap'] = "0.0px";
    // }

    if(!widgetProps['bt_vertical_panel']) {
        widgetProps['bt_vertical_panel'] = {}
    }

    if(!widgetProps['bt_vertical_panel']['style']) {
        widgetProps['bt_vertical_panel']['style'] = {dimension : {}}
    }

    if(!widgetProps['bt_vertical_panel']['style']['dimension']) {
        widgetProps['bt_vertical_panel']['style']['dimension'] = {height: "auto"};
    }

    return <Grid container spacing={2}>
        <SectionHeightSetting defaultValue={widgetProps['bt_vertical_panel']['style']['dimension']['height']} onChange={(value) => {
            handleChangeHeight(value);

        }}/>
        {/*<Grid item xs={labelWidth}>*/}
        {/*    Gap*/}
        {/*</Grid>*/}
        {/*<Grid item xs={objectWidth}>*/}
        {/*    <SizeInput auto={false} value={widgetProps['content'][`gap`]} onChangeSize={(value) => {*/}
        {/*        handleChange(value, `gap`);*/}
        {/*    }}/>*/}
        {/*</Grid>*/}
    </Grid>
}