import React from 'react';

export interface Variable {
    cndURL:string;
    websiteURL: string;
    siteCode: string;
    portalThemeName: string,
    updateVariables: (url: string) => string;
}

const VariableContext = React.createContext<Variable>({
    cndURL: "",
    websiteURL: "",
    siteCode: "",
    portalThemeName: "",
    updateVariables: (url: string) => {
        return url;
    }
});


export default VariableContext;