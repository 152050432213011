import {lsi} from "../../datafetcher/LSIDataFetcher";
import {useSSO} from "../../datafetcher/HttpClient";

interface TrainingButtonProp {

    className: string
    title: string
    relayUrl?: string
}

export default function TrainingLink(props: TrainingButtonProp) {
    const sso = useSSO();

    const handleClick = () => {

        lsi.training.loginToken().then((response:any) => {
            let data = response.data as any;
            let redirectURL = data['redirectURL'];
            if(redirectURL && redirectURL.length > 0) {
                window.open(redirectURL, "_self");
            }
        }).catch(error => {
            console.log(error)
        })


    }

    if(sso.showTraining) {
        return <a className={props.className} rel="noreferrer" onClick={handleClick}>{props.title}</a>
    } else {
        return <></>
    }
}