import {
    Box,
    List,
    ListItem,
    ListItemButton,
    Popover, TextField
} from "@mui/material";
import {useRef, useState} from "react";
import OutlinedIcon from "../icon/OutlinedIcon";
import TopIconButton from "../button/TopIconButton";
import {TooltipPlacement} from "../../util/ThemeUtil";
import styles from "./OrgPopover.module.scss";


let globalTimeout:NodeJS.Timeout|null = null;
export interface ItemsButtonProp {
    tooltipPlacement: TooltipPlacement;
    tooltipTitle: string
    nameField: string,
    idField: string,
    icon: string,
    items?: any[],
    selectedItem?: any,
    onChangeItem?: (item:any) => void,
}
export default function ItemsButton(props: ItemsButtonProp) {

    const textRef = useRef(null);
    const [keyword, setKeyword] = useState<string>("");
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [selectedItem, setSelectedItem] = useState<any|null>(props.selectedItem);
    const [onText, setOnText] = useState<boolean>(false);

    const handleOnClickStorefront = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
        event.preventDefault();
    };


    const handleClickOnListItem = (item: any) => {

        if(globalTimeout !== null) {
            clearTimeout(globalTimeout);
            globalTimeout = null;
        }
        let newItem = {...item};
        setSelectedItem(item);
        if(props.onChangeItem) {
            props.onChangeItem(item);
        }
    }

    const handleFocus = (event: React.FocusEvent) => {
        if(globalTimeout) {
            clearTimeout(globalTimeout);
            globalTimeout = null;
        }
    }

    const handleTextFocus = (event:React.FocusEvent) => {
        handleFocus(event);
        if(onText === false) {
            setOnText(true);
        }
    }

    const handleBlur = (event: React.FocusEvent) => {
        globalTimeout = setTimeout(() => {
            setAnchorEl(null);
            globalTimeout = null;
        },100);
    }

    const open = Boolean(anchorEl);
    let items = props.items && props.items.filter((item) => {
        if(keyword === "") return true;
        else {
            return item[props.nameField].toLowerCase().indexOf(keyword.toLowerCase()) >= 0 ||
                item[props.nameField].toLowerCase().indexOf(keyword.toLowerCase()) >= 0;
        }
    }).map((item,index) => {
            let selected = false;

            if(selectedItem !== null && props.idField) {
                selected = item[props.idField] === selectedItem[props.idField];
            }

            return <ListItem
                             disablePadding
                             tabIndex={-1}
                             onBlur={handleBlur}
                             sx={{padding: "4px"}}
                             key={"current_item_" + index}
                             value={item[props.idField]}
                             onClick={() => handleClickOnListItem(item)}
            >
                <ListItemButton selected={selected} sx={ selected ? { color: "var(--standard-jade-600)" , fontWeight: "bold" , borderRadius: "4px", width: "100%"} : {borderRadius: "4px" }}>
                    {item[props.nameField]}
                    {/*<OutlinedIcon  className={styles.Edit}>edit</OutlinedIcon>*/}
                </ListItemButton>

            </ListItem>
        }
    )

    return <>
        <TopIconButton tooltipTitle={props.tooltipTitle} tooltipPlacement={props.tooltipPlacement}
                       onClick={props.items ? handleOnClickStorefront : undefined}
                       style={{pointerEvents: open ? "none" : "auto"}}>
            <OutlinedIcon>{props.icon}</OutlinedIcon>
        </TopIconButton>
        <Popover
            tabIndex={-1}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center"
            }}
            style={{pointerEvents: "none"}}
        >
            <Box sx={{boxShadow: 3, padding: 0, maxWidth: "250px"}} style={{pointerEvents: "auto" , padding: "10px"}}>
                <TextField
                    autoFocus={true}
                    ref={textRef}
                    className={styles.TextField}
                    placeholder={"Find by name"}
                    value={keyword}
                    tabIndex={0}
                    size={"small"}
                    variant={"outlined"}
                    fullWidth
                    onBlur={handleBlur}
                    onFocus={handleTextFocus}
                    onChange={ (event) => {
                        setKeyword(event.target.value);
                    }
                    }
                />
                <List dense sx={{maxHeight: "250px",
                    overflowY: "auto",
                    paddingInlineEnd: "4px",
                    boxSizing: "border-box"}}>
                    {
                        items
                    }
                </List>
            </Box>
        </Popover>
    </>

}