
//https://github.com/ react-monaco-editor/react-monaco-editor/blob/master/src/editor.tsx

import React, {useCallback, useEffect, useRef, useState} from "react";
import styles from "./MonacoEditor.module.scss"
import SettingPanel from "../panel/SettingPanel";
import {StyleUtil} from "../../cms/ui/setting/StyleUtil";

const globalSuggestions:any = [

];

let globalInit = false;

export function addCSSVariables(varnames:string[]) {
    globalSuggestions.splice(0,globalSuggestions.length);
    // @ts-ignore
    if(varnames) {
        varnames.forEach(name => {
            globalSuggestions.push(
                {
                    label: name,
                    // @ts-ignore
                    kind: 13,
                    insertText: name
                },
            )
        })
    }
}



export default function MonacoEditor(props: {
    title: string,
    className?: string
    codeClassName?: string
    caption?: string,
    defaultValue?: string,
    language: string,
    style?: any,
    inputProps?: any,
    onChange?: (newValue:string) => void,
    height?: string
}) {

    const [ , setEditor] = useState<any>(null);
    const monacoEl = useRef<HTMLDivElement|null>(null);
    const [defaultValue] = useState(props.defaultValue);
    const [language] = useState(props.language);

    const handleChange = useCallback((newValue:string) => {
        if(props.onChange) props.onChange(newValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect( () => {

        let _editor:any = null;
        if (monacoEl && monacoEl.current && monacoEl.current !== null ) {


            //monacoEl.current.remove();
            // @ts-ignore
            window.require.config({ paths: { 'vs': '/monaco-editor/0.33.0/min/vs' }});

            // @ts-ignore
            window.require(["vs/editor/editor.main"], () => {

                // @ts-ignore
                if(globalInit === false && monaco) {
                    // Register a completion item provider for the new language
                    // @ts-ignore
                    monaco.languages.registerCompletionItemProvider('css', {
                        provideCompletionItems: (model:any,position:any) => {
                            let textUntilPosition = model.getValueInRange({
                                startLineNumber: position.lineNumber,
                                startColumn: position.lineNumber-3,
                                endLineNumber: position.lineNumber,
                                endColumn: position.column
                            });
                            let match = textUntilPosition.match(
                                /var\(/
                            );
                            if (!match) {
                                return { suggestions: [] };
                            }
                            return { suggestions: globalSuggestions };
                        }
                    });
                    globalInit = true;
                }

                if(monacoEl.current !== null) {
                    //@ts-ignore
                    setEditor(_editor = monaco.editor.create(monacoEl.current, {
                        value: defaultValue,
                        language: language,
                        theme: 'vs-lite',
                        minimap: {
                            enabled: false,
                        },
                        folding: false,
                        smoothScrolling: true,
                        trimAutoWhitespace: true,
                        wordWrap: "on",
                        wordWrapColumn: 70,
                        wrappingIndent: 'indent',
                        automaticLayout: true,
                        scrollbar: {
                            alwaysConsumeMouseWheel: false
                        },
                        wordWrapBreakAfterCharacters: ">([{]))],;}",
                    }));

                    // @ts-ignore
                    _editor.getModel().onDidChangeContent( (event) => {
                        // if(props.onChange) {
                        //     props.onChange(_editor.getValue());
                        // }
                        handleChange(_editor.getValue());
                    })
                }

            });
        }
        return () => {
            _editor?.dispose();
            setEditor(null);
        }
    },[language,defaultValue,handleChange]);


    let editorPanel = <>
        {props.caption && <div style={{color:"gray" ,textAlign:"end", padding: "8px", fontSize: "12px"}}>{props.caption}</div> }
        <div style={{paddingBlockEnd: "10px", height: "100%"}}> {/*this is special tag to prevent monaco editor go out of bound*/}
            <div {...props.inputProps}
                 className={StyleUtil.combineClasses(styles.Editor,props.codeClassName!)}
                 style={{height: props.height ? props.height : undefined}}
                 ref={monacoEl}>
            </div>
        </div>
    </>

    if(props.title === "") {
        return <div className={props.className} style={props.style ? props.style : {}}>{editorPanel}</div>
    } else {
        return <SettingPanel title={props.title} className={props.className} style={props.style ? props.style : {}}>
            {editorPanel}
        </SettingPanel>
    }

}
