import {Divider, Grid, Popover, PopoverProps} from "@mui/material";
import styles from "../../cms/ui/shared/newlabel/NewLabel.module.scss";
import {
    sectionDescriptionsDataFetcher,
    widgetDescriptionsDataFetcher
} from "../../datafetcher/SectionAndWidgetDataFetcher";
import WidgetIcon from "../../cms/ui/icon/widgeticon/WidgetIcon";
import ColumnIcon from "../../cms/ui/icon/columnicon/ColunmIcon";
import RowIcon from "../../cms/ui/icon/rowicon/RowIcon";
import React, {useState} from "react";
import {useSSO} from "../../datafetcher/HttpClient";
import {GetCopiedObject} from "../editor/LiveEditor";
import {SectionDesc} from "../../cms/model/desc/SectionDesc";
import {WidgetDesc} from "../../cms/model/desc/WidgetDesc";
import {WidgetGroupType} from "../../cms/model/desc/WidgetGroupType";

export default function SectionOrWidgetPopover(props: (PopoverProps & {
    uiLevelStr?: string,
    onHandleCut?: (uiLevelStr: string) => void,
    onInsertSection?: (uiLevelStr: string, desc: SectionDesc) => void,
    onInsertWidget?: (uiLabelStr: string, desc: WidgetDesc) => void,
    onlyWidget?: boolean,
    title?: string,
})) {
    let newProps = {...props};
    const ssoCmd = useSSO();
    const [hasSection] = useState(props.uiLevelStr!.split("_").length <= 3);

    const handleChooseClipboard = () => {
        let clonedObject = GetCopiedObject()
        if(props.onInsertWidget && clonedObject) {

            let widgetDesc:WidgetDesc = {
                widgetType: "",
                widgetName: "",
                widgetNickName: "",
                widgetDescription: "",
                widgetIcon: "",
                widgetGroupType: WidgetGroupType.unspecfic,
                widgetExperimental: false,
                clonedObject: clonedObject
            }
            props.onInsertWidget(props.uiLevelStr!,widgetDesc);
        }
    }

    const handleChooseWidget = (action: string) => {
        if(props.onInsertWidget) {
            let widgetDataFetcher = widgetDescriptionsDataFetcher();
            let widgetDesc = widgetDataFetcher.find((item) => item.widgetName === action);
            if(widgetDesc) {
                props.onInsertWidget(props.uiLevelStr!, widgetDesc);
            }
        }
    }

    const handleChooseRegularSection = (action: string) => {
        if(props.onInsertSection) {
            let sectionDataFetcher = sectionDescriptionsDataFetcher();
            let sectionDesc = sectionDataFetcher.find((item) => item.sectionName === action);
            if(sectionDesc) {
                props.onInsertSection(props.uiLevelStr!, sectionDesc);
            }
        }
    }
    const handleChooseSection = (columns: number, columnWidths: string[]) => {
        if(props.onInsertSection) {
            let sectionDataFetcher = sectionDescriptionsDataFetcher();
            let sectionDesc = sectionDataFetcher.find((item) => item.sectionName === "DefaultSection");
            if(sectionDesc) {
                let widthArray:any = {};
                columnWidths.forEach((width,index) => {
                    widthArray[`column${index+1}-width`] = columnWidths[index];
                })
                props.onInsertSection(props.uiLevelStr!, { ...sectionDesc ,
                    sectionInitProps: {
                        content: {
                            'layout-break': 300,
                            columns: columns,
                            layout: "horizontal",
                            'justify-content': 'center',
                            'align-items': 'start',
                            ...widthArray
                        }
                    }});
            }
        }
    }

    delete newProps['uiLevelStr'];
    delete newProps['onInsertSection'];
    delete newProps['onInsertWidget'];
    delete newProps['onInsertWidget'];
    delete newProps['onHandleCut'];
    delete newProps['onlyWidget'];

    let copiedObject = GetCopiedObject();
    if(copiedObject && hasSection === false && 'sectionId' in copiedObject) {
        copiedObject = undefined;
    }

    return <Popover
        {...newProps}
        onDoubleClick={(event) => {event.stopPropagation(); event.preventDefault();}}
        onClick={(event) => {event.stopPropagation(); event.preventDefault();}}
        onMouseOver={(event) => {event.stopPropagation(); event.preventDefault()}}
        onMouseMove={(event) => {event.stopPropagation(); event.preventDefault()}}
        onMouseEnter={(event) => {event.stopPropagation(); event.preventDefault()}}
    >
        {props.title && <span className={styles.CardTitle}>{props.title}</span>}
        <Grid container spacing={1} className={styles.Card}>
            {widgetDescriptionsDataFetcher().filter(data => data.widgetExperimental === false).map((data,index) => <Grid key={`newlabel_grid_${index}`}item xs={3}>
                    <WidgetIcon tooltip={data.widgetNickName} label={data.widgetNickName} icon={data.widgetIcon} action={data.widgetName} onClick={handleChooseWidget}/>
                </Grid>
            )}
            {copiedObject && <Grid item xs={3}>
                <WidgetIcon tooltip={"Clipboard"} label={"Clipboard"} icon={"content_paste"}
                            action={"Cliboard"} onClick={handleChooseClipboard}/>
            </Grid>}
        </Grid>
        {ssoCmd.roleName === "LSIADMIN" && <><Divider/>
            <span className={styles.CardTitle}>Experimental Widgets <span className={styles.CardSubTitle}>(Admin Only)</span></span>

            <Grid container spacing={1} className={styles.Card}>
                {widgetDescriptionsDataFetcher().filter(data => data.widgetExperimental === true).map((data, index) =>
                    <Grid key={`newlabel_grid_${index}`} item xs={3}>
                        <WidgetIcon tooltip={data.widgetNickName} label={data.widgetNickName} icon={data.widgetIcon}
                                    action={data.widgetName} onClick={handleChooseWidget}/>
                    </Grid>
                )}
            </Grid></>}
        { hasSection && props.onlyWidget !== true && <><Divider/>
            <span className={styles.CardTitle}>Predefined Sections</span>
            <Grid container spacing={1} className={styles.Card}>
                <Grid item xs={3}><ColumnIcon onClick={handleChooseSection} columns={1} columnWidths={["100%"]} tooltip={"1 column"} label={"100"} /></Grid>
                <Grid item xs={3}><ColumnIcon onClick={handleChooseSection} columns={2} columnWidths={["50%","50%"]} tooltip={"2 columns"} label={"2*50"} /></Grid>
                <Grid item xs={3}><ColumnIcon onClick={handleChooseSection} columns={3} columnWidths={["33%","33%","33%"]}  tooltip={"3 columns"} label={"3*33"} /></Grid>
                <Grid item xs={3}><ColumnIcon onClick={handleChooseSection} columns={2} columnWidths={["40%","60%"]} tooltip={"2 columns"} label={"40+65"} /></Grid>
                {/*<Grid item xs={3}><RowIcon onClick={() => handleChooseRegularSection("Vertical")} rows={3} rowHeights={["33%","33%","33%"]} tooltip={"Rows"} label={"Rows"} /></Grid>*/}
                {/*{sectionDescriptionsDataFetcher().filter(data => data.sectionName === "Vertical").map((data,index) => <Grid key={`newsection_grid_${index}`}item xs={3}>*/}
                {/*        <WidgetIcon tooltip={data.sectionNickName} label={data.sectionNickName} icon={data.sectionIcon} action={data.sectionName} onClick={handleChooseRegularSection}/>*/}
                {/*    </Grid>*/}
                {/*)}*/}
            </Grid></>
        }
    </Popover>
}
