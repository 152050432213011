import {Grid, GridSize, MenuItem, TextField} from "@mui/material";
import React, {useState} from "react";

export default function SectionWidthSetting(props: {
    defaultValue: string,
    onChange: (value: string) => void
}) {
    const [value, setValue] = useState<string>(props.defaultValue ? props.defaultValue : "full")
    const labelWidth:GridSize = 5;
    const objectWidth:GridSize = 7;
    return <>
        <Grid item xs={labelWidth}>
            Width
        </Grid>
        <Grid item xs={objectWidth}>
            <TextField
            size={"small"}
            fullWidth
            InputLabelProps={{shrink: true}}
            inputProps={{sx: {paddingBlock: "5px"}}}
            value={value}
            onChange={(event) => {
                setValue(event.target.value);
                props.onChange(event.target.value);
                event.stopPropagation();
                event.preventDefault();
            }} label={""}
            select
            >
                <MenuItem value={"full"}>Full</MenuItem>
                <MenuItem value={"boxed"}>Boxed</MenuItem>
            </TextField>

        </Grid>
    </>
}