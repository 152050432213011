import React, { useEffect, useRef } from "react";
import { basicSetup } from "@codemirror/basic-setup";
import {EditorState, Extension} from "@codemirror/state";
import { EditorView } from "@codemirror/view";
import styles from "./CodeMirrorEditor.module.scss";
import {StyleUtil} from "../../cms/ui/setting/StyleUtil";
import {keymap} from "@codemirror/view"
import {indentWithTab} from "@codemirror/commands"

export default function CodeMirrorEditor(props: {
    className?: string
    defaultValue?: string,
    language: Extension,
    inputProps?: any,
    onChange: (newValue:string) => void
}) {
    const editor = useRef<any>();
    const {onChange,defaultValue,language} = props;


    useEffect(() => {
        const state = EditorState.create({
            doc: defaultValue,
            extensions: [
                basicSetup,
                EditorView.lineWrapping,
                keymap.of([indentWithTab]),
                language,
                EditorView.updateListener.of((v) => {
                    if (v.docChanged) {
                        onChange(view.state.doc.toString());
                    }
                })
            ],
        });


        const view = new EditorView({
                    state,
                    parent: editor.current,
                    });

        return () => {
            view.destroy();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    return <div ref={editor} {...props.inputProps} className={StyleUtil.combineClasses(styles.Root,props.className!)}></div>;
}
