import {
    Grid
} from "@mui/material";
import React, {useState} from "react";
import MonacoEditor from "../../../../ui/editor/MonacoEditor";

export default function PluginSetting(props: {
                                                widgetData: any,
                                                saveWidgetState: () => void,
                                           }
                                                // onChange: (searchType: string,
                                                //             searchData: string,
                                                //             start: number,
                                                //             rows: number,
                                                //             searchProfileId: number) => void}
) {
    const {widgetData} = props;
    //const {widgetProps} = widgetData;

    if(!widgetData.widgetProps['content']) {
        widgetData.widgetProps['content'] = {}
    }

    const [html,setHtml] = useState<string>(widgetData.widgetProps['content']['html'] ? widgetData.widgetProps['content']['html'] : "");
    const [script,setScript] = useState<string>(widgetData.widgetProps['content']['script'] ? widgetData.widgetProps['content']['script'] : "");

    const handleChange = (value: string, key: string) => {
        widgetData.widgetProps["content"][key] = value;
        props.saveWidgetState();
    }



    return <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={12}>
            <MonacoEditor title={"html snippet"} defaultValue={html} language={"html"} onChange={(newValue) => {
                    handleChange(newValue, "html")
                    setHtml(newValue);
                }
            }/>
        </Grid>
        <Grid item xs={12}>
            <MonacoEditor title={"script"} defaultValue={script} language={"html"} onChange={(newValue) => {
                let value = newValue;
                value = value.trim()
                if(value.startsWith("<script>")) {
                    value = value.substring(8);
                }
                if(value.endsWith("</script>")) {
                    value = value.substring(0,value.length-9);
                }
                handleChange(value, "script")
                setScript(newValue);
            }
            }/>
        </Grid>
    </Grid>
}