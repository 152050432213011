

function toJSSName(name:string) {
    let names = name.split("-");
    for(let i=1;i<names.length;i++) {
        if(names[i].length > 0) {
            names[i] = names[i].charAt(0).toUpperCase() + names[i].substr(1);
        }
    }
    return names.join("");
}
function toStyleFromWidget(widgetProps:any, group: string) {

    if(widgetProps && widgetProps[group] && widgetProps && widgetProps[group]['style']) {
        let style:{[key:string] : any} = widgetProps[group]['style'];
        let rValue:any = {};
        for (let [, group] of Object.entries(style)) {
            for (let [property, value] of Object.entries(group)) {
                rValue[toJSSName(property)] = value;
            }
        }
        return rValue;
    }
    return {};
}

function toStyleFromMap(keys:any) {
    let rValue:any = {};
    for (let [property, value] of Object.entries(keys)) {
        rValue[toJSSName(property)] = value;
    }
    return rValue;
}


function toClassFromWidget(widgetName:string, widgetProps:any, group:string, postfix?:string) {
    if(widgetProps && widgetProps[group]) {
        if(group === "current_style") {
            let data = widgetProps['current_style'];
            let className: string;
            if (postfix) {
                className = widgetName + postfix;
            } else {
                className = widgetName;
            }
            if(data !== null) {
                data = data.replaceAll("current", "." + className);
                addCMSStyle(className, data);
            }

            return className;
        } else {
            for (let styleName in widgetProps[group]) {
                if (styleName.endsWith("style")) {
                    let index = styleName.indexOf("_");
                    let pseudo = "";
                    if (index > 0) {
                        pseudo = "::" + styleName.substring(0, index);
                    }
                    if (widgetProps && widgetProps[group]['style']) {
                        let buffer: string[] = [];
                        let typography_buffer: string[] = [];
                        let style: { [key: string]: any } = widgetProps[group]['style'];
                        let className: string;
                        if (postfix) {
                            className = widgetName + "_" + group + postfix;
                        } else {
                            className = widgetName + "_" + group;
                        }
                        buffer.push("." + className + pseudo + " {");
                        for (let [name, styles] of Object.entries(style)) {

                            if(name === "typography") {
                                typography_buffer.push("." + className + pseudo + " * {");
                                for (let [property, value] of Object.entries(styles)) {
                                    typography_buffer.push("\t" + property + ": " + value + ";");
                                }
                                typography_buffer.push("}");
                            }

                            for (let [property, value] of Object.entries(styles)) {
                                buffer.push("\t" + property + ": " + value + ";");
                            }
                        }
                        buffer.push("}");

                        if(typography_buffer.length > 0) {
                            buffer.push(typography_buffer.join("\n"));
                        }

                        addCMSStyle(className, buffer.join("\n"));
                        return className;
                    }
                }
            }
        }
    }
    return "";
}



function toClassFromMap(widgetName: string, groupName: string, keys:any) {
    let rValue:any = {};

    let buffer: string[] = [];
    let className = widgetName + "_" + groupName;
    buffer.push("." +  className + " {");
    for (let [property, value] of Object.entries(keys)) {
        buffer.push("\t" + property + ":" + value + ";");
    }
    buffer.push("}");
    addCMSStyle(className,buffer.join("\n"));
    return className;
}

let globalStyleElement:any = null;
let globalClasses:any = {};

function ensureCMSStyle() {
    if (globalStyleElement === null) {
        let styleEl = document.querySelector("style[id=btcms]");
        if (!styleEl) {
            styleEl = document.createElement('style');
            styleEl.id = 'btcms';
            document.body.appendChild(styleEl);
            styleEl.textContent = '';
        }
        globalStyleElement = styleEl;
    }
}

const addCMSStyle = (className: string, styledBlock:string) => {
    ensureCMSStyle();
    if(!globalClasses[className] || globalClasses[className] !== styledBlock) {
        globalClasses[className] = styledBlock;
        let string = "";
        for (let [, value] of Object.entries(globalClasses)) {
            string += value + "\n";
        }
        globalStyleElement.textContent = string;
    }
}

const queryCMSStyle = (className: string) => {
    if(globalClasses[className]) {
        return globalClasses[className]
    } else {
        return "";
    }
}


const resetCMSClasses = () => {
    ensureCMSStyle();
    globalClasses = {};
    globalStyleElement.textContent = '';
}

const combineClasses = (...rest:string[]) => {
    return rest.join(" ");
}


const isDefaultStyle = (data:any) => {
    if(!data || (data['style'] && data['style'].default)) {
        return true;
    } else {
        return false;
    }
}

const toThemeNameByType = (widgetProps:any,type:string) => {
    if(widgetProps && widgetProps['theme_disabled'] !== undefined) {
        if(widgetProps['theme_disabled']) {
            return "";
        } else {
            return "btcms_theme_" + type;
        }
    } else {
        return "btcms_theme_" + type;
    }
}

export const StyleUtil =  {
    toStyleFromWidget,
    toClassFromWidget,
    resetCMSClasses,
    toClassFromMap,
    combineClasses,
    isDefaultStyle,
    toThemeNameByType,
    queryCMSStyle
}



