import {
    Checkbox,
    FormControlLabel, FormGroup,
    Grid, GridSize, IconButton,
    MenuItem,
    Tabs,
    TextField, Tooltip
} from "@mui/material";
import styles from './InputDialog.module.scss';
import React, {useCallback, useEffect, useState} from "react";
import {InputModel} from "../../model/InputModel";
import StyledGraphQLEditor from "../editor/StyledGraphQLEditor";
import BorderLessPanel from "../panel/BorderLessPanel";
import KeyValuePanel from "../seo/KeyValuePanel";
import MultiSelectionList from "./MultiSelectionList";
import InputPanelProps from "./InputPanelProps";
import AssetField from "../assetfield/AssetField";
import {nanoid} from "nanoid";
import InputHTML from "./InputHTML";
import {DateToISODate, ToEndISODate} from "../../util/DateUtil";
import CodeMirrorCssEditor from "../editor/CodeMirrorCssEditor";
import RoleField from "../role/RoleField";
import ColorPickerField from "../colorpicker/ColorPickerField";
import { Tab } from "@mui/material";
import PasswordField from "../input/PasswordField";
import OutlinedIcon from "../icon/OutlinedIcon";
import YesNoSwitch from "../../cms/ui/setting/onoffswitch/YesNoSwitch";

const globalMargin="dense"

export default function InputPanel(props: InputPanelProps) {

    const [inputId] = useState<string>(props.id ? props.id : nanoid());
    const initDefaultValue = useCallback(() => {
        let defaultValues: InputModel[] = []
        props.fields.forEach(field => {
            defaultValues.push({id: field.id,
                                label: field.label,
                                defaultValue: field.defaultValue !== undefined ? field.defaultValue : "" ,
                                required: field.required ,
                                editable: field.editable,
                                group: field.group,
                                globalValue: field.globalValue,
                                options: field.options,
                                nullable: field.nullable,
                                type: field.type});
        })
        return defaultValues;
    },[props.fields])

    const [tab, setTab] = useState<number>(0);
    const [values , setValues] = useState<InputModel[]>([]);//initDefaultValue(props));
    const [, setDisabled] = useState(true);

    function doValidate(newValue: InputModel[]) {
        let hasEmpty = false;
        newValue.forEach(object => {
            if (typeof(object.defaultValue) === 'string' && object.defaultValue.trim() === "" && object.required) {
                hasEmpty = true;
            }
        });
        setDisabled(hasEmpty);
    }

    const onChangeValueDisable = (event: React.ChangeEvent<HTMLInputElement>) => {

    }

    const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newValues = [...values];
        let dataIndex = event.currentTarget.getAttribute("data-index");
        let index = values.findIndex(value => value.id === dataIndex);
        if(index < 0) return;
        let item = newValues[index];
        item.defaultValue = event.currentTarget.value;
        doValidate(newValues);
        setValues(newValues);
        if(props.handleChange) {
            props.handleChange(inputId,newValues,index);
        }
    };

    const onChangeToNull = (indexStr: string) => {
        let newValues = [...values];
        let index = values.findIndex(value => value.id === indexStr);
        if(index < 0) return;
        let item = newValues[index];
        item.defaultValue = null;
        doValidate(newValues);
        setValues(newValues);
        if(props.handleChange) {
            props.handleChange(inputId,newValues,index);
        }
    };

    const onChangeValueString = (indexId: string, newValue:string) => {
        let index = values.findIndex(value => value.id === indexId);
        if(index < 0) return;
        let newValues = [...values];
        let item = newValues[index];
        item.defaultValue = newValue;
        doValidate(newValues);
        setValues(newValues);
        if(props.handleChange) {
            props.handleChange(inputId,newValues,index);
        }
    }
    const onChangeTextAreaValueDisable = (event: React.ChangeEvent<HTMLTextAreaElement>) => {

    }

    const onChangeTextAreaValue = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        let newValue = [...values];
        let dataIndex = event.currentTarget.getAttribute("data-index");
        let index = values.findIndex(value => value.id === dataIndex);
        if(index < 0) return;

        let item = newValue[index];
        item.defaultValue = event.currentTarget.value;
        doValidate(newValue);
        setValues(newValue);
        if(props.handleChange) {
            props.handleChange(inputId,newValue,index);
        }
    };

    const onChangeSelectValue = (value: any, indexId: string) => {
        let index = values.findIndex(value => value.id === indexId);
        if(index < 0) return;
        let newValue = [...values];
        let item = newValue[index];
        item.defaultValue = value;
        doValidate(newValue);
        setValues(newValue);
        if(props.handleChange) {
            props.handleChange(inputId,newValue,index);
        }
    }

    const changeKeyValue = (seo: any, indexId: string) => {
        let index = values.findIndex(value => value.id === indexId);
        if(index < 0) return;
        let newValue = [...values];
        let item  = newValue[index];
        item.defaultValue = JSON.stringify(seo);
        doValidate(newValue);
        setValues(newValue);
        if(props.handleChange) {
            props.handleChange(inputId,newValue,index);
        }
    }

    const onCheckMultipleValues = (indexId: string
                                   , data: string) => () => {

        let index = values.findIndex(value => value.id === indexId);
        if(index < 0) return;

        let newValue = [...values];
        let item = newValue[index];
        let dataIndex = item.defaultValue.indexOf(data);
        if(dataIndex >= 0) {
            item.defaultValue.splice(dataIndex,1);
        } else {
            item.defaultValue.push(data);
        }
        doValidate(newValue);
        setValues(newValue);
        if(props.handleChange) {
            props.handleChange(inputId,newValue,index);
        }
    }
    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const callback = useCallback(props.handleChange ? props.handleChange : () => {},[]);

    useEffect(() => {
        if(values.length === 0) {
            setValues(initDefaultValue());
        }
    },[values,initDefaultValue]);

    useEffect(() => {
        if(values) {
            doValidate(values)
            callback("",values,-1);
        }
    },[values,callback]); // should not add prop to the dependencies

    // const handleSave = useCallback(() => {
    //     let newValues = [...values];
    //     newValues.forEach(value => {
    //         if(typeof value.defaultValue === 'string') {
    //             value.defaultValue = (value.defaultValue as string).trim();
    //         }
    //     })
    //     // if(props.handleSave) {
    //     //     props.handleSave(newValues);
    //     // }
    // },[values]);

    // useEffect(() => {
    //     if (props.saveButton) {
    //         props.saveButton(<Button size={"small"}
    //                                  disabled={disabled} color="primary"
    //                                  className={styles.Button}>
    //             {inserted ? "Create" : "Update"}
    //         </Button>);
    //     }
    // },[props.saveButton,inserted,disabled]);

    // useEffect(() => {
    //     const handleSave = () => {
    //         let newValues = [...values];
    //         newValues.forEach(value => {
    //             if(typeof value.defaultValue === 'string') {
    //                 value.defaultValue = (value.defaultValue as string).trim();
    //             }
    //         })
    //         if(props.handleSave) {
    //             props.handleSave(newValues);
    //         }
    //     }
    //     if(props.saveButton) {
    //         props.saveButton(<Button size={"small"} onClick={handleSave} disabled={disabled} color="primary" className={styles.Button}>
    //             {props.inserted ? "Create" : "Update"}
    //         </Button>);
    //     }
    // },[disabled,values,props.saveButton,props.inserted])


    function createHTMLInput(object: InputModel, index: number,values:InputModel[]) {
        return <div key={object.id} className={styles.HTMLInput}>
            <InputHTML
            // data={object.defaultValue}
            // onChange={ ( event:any, editor:any ) => {
            //     let value = editor.getData() as string;
            //     if(object.editable) {
            //         let newValue = [...values];
            //         let item = newValue[index];
            //         item.defaultValue = value;
            //         doValidate(newValue);
            //         setValues(newValue);
            //         if(props.handleChange) {
            //             props.handleChange(inputId,newValue,index);
            //         }
            //     }
            // }}
            // height="100%"
                initialValue={object.defaultValue}

                onEditorChange={(value:string, editor:any) => {
                    if(object.editable) {
                        let newValue = [...values];
                        let item = newValue[index];
                        item.defaultValue = value;
                        doValidate(newValue);
                        setValues(newValue);
                        if(props.handleChange) {
                            props.handleChange(inputId,newValue,index);
                        }
                    }
                }}
        /></div>
    }

    const createColorInput = (object: InputModel, index: number,values:InputModel[]) => {
        return <ColorPickerField
            size={"small"}
            autoComplete={"off"}
            key={object.id}
            required={object.required}
            variant={"outlined"}
            margin={globalMargin}
            id={`${inputId}_${object.id}`}
            inputProps={{'data-index': object.id}}
            disabled={!object.editable}
            onChangeColor={(color) => {
                onChangeValueString(object.id,color);
            }}
            value={values[index].defaultValue}
            // label={object.label}
            type="text"
            fullWidth
        />
    }
    const createCssInput = (object: InputModel, index: number,values:InputModel[]) => {
        return <CodeMirrorCssEditor
            title={object.label}
            key={object.id}
            defaultValue={values[index].defaultValue}
            inputProps={{'data-index': object.id}}
            onChange={(newValue:string) => {
                onChangeValueString(object.id,newValue);
            }}
        />
    }

    function createRoleInput(object: InputModel, index: number, values: InputModel[]) {
        return <RoleField
                onChange={filters => {
                    let newValues = [...values];
                    newValues[index].defaultValue = JSON.stringify(filters)
                    doValidate(newValues);
                    setValues(newValues)
                    if(props.handleChange) {
                        props.handleChange(inputId,newValues,index);
                    }
                }}
                filters={ values[index] && values[index].defaultValue && values[index].defaultValue.startsWith("[") ? JSON.parse(values[index].defaultValue) : []}
        />
    }

    function createYesNoInput(object: InputModel, index: number, values: InputModel[]) {
        return <YesNoSwitch
                onChange={(value) => {
                    onChangeSelectValue(value, object.id)
                }}
                defaultValue={object.defaultValue}
            />
    }

    function createCheckboxInput(object: InputModel, index: number, values: InputModel[]) {
        return <FormGroup>
            <FormControlLabel control={<Checkbox
                                            id={`${inputId}_${object.id}`}
                                            disabled={!object.editable}
                                            onChange={(event) => {
                                                    onChangeSelectValue(event.target.checked, object.id)
                                                }
                                            }
                                            defaultChecked={object.defaultValue}
                                            />} defaultChecked={object.defaultValue} label={object.label} className={styles.CheckBox}/>
        </FormGroup>
    }

    function createTextInput(object: InputModel, index: number,values:InputModel[],formType:boolean) {
        return <TextField
            size={"small"}
            autoComplete={"off"}
            key={object.id}
            required={object.required}
            variant={"outlined"}
            margin={globalMargin}
            id={`${inputId}_${object.id}`}
            inputProps={{'data-index': object.id, style: {paddingInline: "5px"}}}
            disabled={!object.editable}
            onChange={object.editable ? onChangeValue : onChangeValueDisable}
            value={values[index].defaultValue && values[index].defaultValue !== null ? values[index].defaultValue : ""}
            label={formType ? object.label : undefined}
            placeholder={values[index].defaultValue === null && object.globalValue ? `${object.globalValue}` : undefined}
            type={object.type ? object.type : "text"}
            multiline={object.type === "textarea"}
            maxRows={object.type === "textarea" ? 100 : 1}
            minRows={object.type === "textarea" ? 4 : 1}
            fullWidth
            InputLabelProps={{shrink: true}}
            InputProps={object.nullable && values[index].defaultValue !== null ? {
                endAdornment:
                    <Tooltip title={"Reset"}><IconButton tabIndex={-1} onClick={() => onChangeToNull(object.id)}>
                        <OutlinedIcon className={styles.IconSize}>public</OutlinedIcon>
                    </IconButton></Tooltip>
             } : undefined}
        />
    }

    function createPasswordInput(object: InputModel, index: number,values:InputModel[],formType:boolean) {
        return <PasswordField
            size={"small"}
            autoComplete={"off"}
            key={object.id}
            required={object.required}
            variant={"outlined"}
            margin={globalMargin}
            id={`${inputId}_$x{object.id}`}
            inputProps={{'data-index': object.id}}
            disabled={!object.editable}
            onChange={object.editable ? onChangeValue : onChangeValueDisable}
            value={values[index].defaultValue && values[index].defaultValue !== null ? values[index].defaultValue : ""}
            label={formType ? object.label : undefined}
            onChangeToNull={() => onChangeToNull(object.id)}
            placeholder={values[index].defaultValue === null && object.globalValue ? `${object.globalValue}` : undefined}
            nullable={object.nullable && values[index].defaultValue !== null}
            fullWidth
            InputLabelProps={{shrink: true}}
        />
    }

    function changeToISODate(date: string) {
        if(date === null || date === "") {
            return ToEndISODate();
        } else {
            return DateToISODate(date);
        }
    }

    function createDateInput(object: InputModel, index: number,values:InputModel[],formType: boolean) {
        return <TextField
            type={"date"}
            size={"small"}
            autoComplete={"off"}
            key={object.id}
            margin={globalMargin}
            variant={"outlined"}
            required={object.required}
            id={`${inputId}_${object.id}`}
            inputProps={{'data-index': object.id}}
            disabled={!object.editable}
            placeholder={object.globalValue != null ? `${object.globalValue}` : undefined}
            onChange={object.editable ? onChangeValue : onChangeValueDisable}
            value={changeToISODate(values[index].defaultValue)}
            label={formType ? object.label : undefined}
            fullWidth
            InputLabelProps={{shrink: true}}
        />

    }

    function createDAMInput(object: InputModel, index: number,values:InputModel[],formType: boolean) {
        return <AssetField
            targetAnchorId={props.id}
            key={object.id}
            required={object.required}
            variant={"outlined"}
            margin={globalMargin}
            id={`${inputId}_${object.id}`}
            inputProps={{'data-index': object.id}}
            disabled={!object.editable}
            onChange={object.editable ? onChangeValue : onChangeValueDisable}
            value={values[index].defaultValue}
            label={formType ? object.label : undefined}
            placeholder={object.globalValue ? `${object.globalValue}` : undefined}
            type="text"
            size={"small"}
            multiline={object.type === "textarea"}
            maxRows={object.type === "textarea" ? 100 : 1}
            minRows={object.type === "textarea" ? 4 : 1}
            fullWidth

        />
    }

    function createGraphQLInput(object: InputModel, index: number,values:InputModel[],fromType: boolean) {
        // console.log(object);
        return <StyledGraphQLEditor title={object.label}
                                    key={object.id}
                                    rows={5}
                                    defaultValue={values[index].defaultValue}
                                    inputProps={{'data-index': object.id}}
                                    onChange={object.editable ? onChangeTextAreaValue : onChangeTextAreaValueDisable}
        />
    }

    function createKeyValue(object: InputModel, index: number, values: InputModel[], formType: boolean) {
        return <BorderLessPanel key={object.id} title={object.label}>
            <KeyValuePanel
                id={object.id}
                defaultValue={(!object.defaultValue || object.defaultValue === null || object.defaultValue === "") ? {} : JSON.parse(object.defaultValue)}
                onChange={(seo) => { changeKeyValue(seo,object.id)}}
            />
        </BorderLessPanel>
    }

    function createMultiValuesSelectionWithOrder(object: InputModel, index: number, values: InputModel[], formType: boolean) {
        if(object.options) {
            return <MultiSelectionList showOrder={true}
                                       key={`${inputId}_${object.id}`}
                                       label={formType ? object.label : ""}
                                       values={values[index].defaultValue}
                                       options={object.options!}
                                       index={object.id}
                                       onClickListItem={onCheckMultipleValues}/>
        } else {
            return <></>
        }
    }

    function createMultiValuesSelection(object: InputModel, index: number, values: InputModel[], formType: boolean) {
        if(object.options) {
            return <MultiSelectionList showOrder={false}
                                       key={`${inputId}_${object.id}`}
                                       label={formType ? object.label : ""}
                                       values={values[index].defaultValue}
                                       options={object.options}
                // object={object}
                                       index={object.id}
                                       onClickListItem={onCheckMultipleValues}/>
        } else {
            return <></>
        }
    }

    function createSingleValueSelection(object: InputModel, index: number, values: InputModel[], formType: boolean) {
        let value = values[index].defaultValue && values[index].defaultValue !== undefined ? values[index].defaultValue : "";
        if(object) {
            if(object.options?.find(option => option.value === value) === undefined) {
                value = "";
            }
        }
        return <TextField
            size={"small"}
            autoComplete={"off"}
            key={`${inputId}_${object.id}`}
            required={object.required}
            variant={"outlined"}
            select
            margin={globalMargin}
            id={`${inputId}_${object.id}`}
            inputProps={{'data-index': object.id}}
            disabled={!object.editable}
            onChange={(event) => onChangeSelectValue(event.target.value,object.id)}
            label={formType ? object.label : undefined}
            placeholder={object.globalValue ? `${object.globalValue}` : undefined}
            type="text"
            multiline={object.type === "textarea"}
            maxRows={object.type === "textarea" ? 100 : 1}
            minRows={object.type === "textarea" ? 4 : 1}
            fullWidth
            InputLabelProps={{shrink: true}}
            value={value}
        >
                     {object.options && object.options.map((option, index) => <MenuItem key={object.id + "-" + index}
                                                                       value={option.value}>
                         {option.icon && <img alt="" src={option.icon} className={styles.SelectIcon}/>}&nbsp;{option.label}&nbsp;</MenuItem>)}
        </TextField>
    }

    function createFrom(values:InputModel[]) {
        if(props.groups) {
            return createGroupLayoutFrom(values);
        } else if(props.layouts) {
            return createLayoutFrom(values);
        } else {
            return createSimpleFrom("Settings",values);
        }
    }

    function createInput(object: InputModel | undefined, index: number,values: InputModel[],formType:boolean) {
        if(object) {
            if (!('type' in object) || object.type === "text" || object.type === "textarea" || object.type === undefined) {
                return createTextInput(object, index, values, formType);
            } else if (object.type === "password") {
                    return createPasswordInput(object, index, values, formType);
            } else if (object.type === "date") {
                return createDateInput(object, index, values, formType);
            } else if(object.type === "graphql") {
                return createGraphQLInput(object, index, values, formType);
            } else if (object.type === "select") {
                return createSingleValueSelection(object, index, values, formType);
            } else if (object.type === "multi") {
                return createMultiValuesSelection(object, index, values, formType);
            } else if (object.type === "multi_ordered") {
                return createMultiValuesSelectionWithOrder(object, index, values, formType);
            } else if(object.type === "keyvalue") {
                return createKeyValue(object, index, values, formType);
            } else if(object.type === "dam") {
                return createDAMInput(object, index, values, formType);
            } else if(object.type === "html") {
                return createHTMLInput(object, index, values);
            } else if(object.type === "css") {
                return createCssInput(object, index, values);
            } else if(object.type === "color") {
                return createColorInput(object, index, values);
            } else if(object.type === "role") {
                return createRoleInput(object, index, values);
            } else if (object.type === "checkbox") {
                return createCheckboxInput(object, index, values);
            } else if (object.type === "yesno") {
                return createYesNoInput(object, index, values);
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    function createSimpleFrom(title: string , values: InputModel[]) {
        let maxLength = 0;
        for(let value of values) {
            if(value.label) {
                if(value.label.length > maxLength) {
                    maxLength = value.label.length;
                }
            }
        }
        let size1: GridSize = 3;
        let size2: GridSize = 9;
        if(maxLength <= 15) {
            size1 = 2;
            size2 = 10;
        }
        return <BorderLessPanel id={props.id}  title={title} className={styles.FormPanel}>
            <Grid container spacing={0} rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }}
                  justifyContent="center"
                  alignItems="center">
                {values.map((object, index) => {
                    if(object.label === "") {
                        return createInput(object, index, values,false)
                    } else {
                        return <React.Fragment key={"simple_" + index}>
                            <Grid item  xs={size1} md={size1} sm={size1} spacing={0} className={styles.FormLabel}>{object.label}{object && object.required ? " *" : ""}</Grid>
                            <Grid item xs={size2} md={size2} sm={size2} spacing={0}>
                                {createInput(object, index, values, false) }
                            </Grid>
                        </React.Fragment>
                    }
                }
        )}</Grid></BorderLessPanel>;
    }

    function createGroupLayoutFrom(values: InputModel[]) {
        let groups : { [key:string] : InputModel[]} = {};
        values.forEach(value => {
            let group = value.group ? value.group : "__default__";
            if(!groups[group]) {
                groups[group] = [];
            }
            groups[group].push(value);
        })
        let keys = Object.keys(groups);

        if(keys.length === 1 ) {
            if(props.layouts) {
                return createLayoutFrom(values);
            } else {
                return createSimpleFrom("Settings",values);
            }
        } else {
            return <><Tabs value={tab}
                           onChange={handleChangeTab}
                           variant="scrollable"
                           TabIndicatorProps={{style: {height:'3px'}}}
                           // style={{borderBottom: "1px solid #e8e8e8"}}
                           scrollButtons="auto">
                { props.groups && props.groups.map(group => <Tab key={`tab_${group.id}`} label={group.name}/>) }
            </Tabs>
                { props.groups && props.groups.filter(group => groups[group.id]).map((group,index) =>  <div
                    key={`tab_${group.id}`}
                    role="tabpanel"
                    hidden={index !== tab}
                    className={styles.TabContent}
                >
                    {/*{ props.layouts && createLayoutFrom(groups[group.id])  }*/}
                    { index === tab && (props.layouts ? createLayoutFrom(groups[group.id]) : createSimpleFrom(group.name,groups[group.id])) }
                </div>)}
            </>;
        }
    }

    function createLayoutFrom(values: InputModel[]) {
        return <Grid id={props.id} container spacing={0}> {props.layouts?.map((layout, index) => <Grid  key={"input" + index}  item xs={layout.row_grid ? layout.row_grid : 12} md={layout.row_grid ? layout.row_grid : 12}>
                    <BorderLessPanel title={layout.label}>
                        <Grid container spacing={1}>
                            {
                                    layout.ids.map((id, index2) => <Grid key={"input" + index + "_" + index2} item
                                                                        md={layout.ids_grid && layout.ids_grid[index2] ? layout.ids_grid[index2] : 12}
                                                                         sm={layout.ids_grid && layout.ids_grid[index2] ? layout.ids_grid[index2] : 12}
                                                                         xs={12} >
                                    {
                                        createInput(values.find(field => field.id === id), values.findIndex(field => field.id === id), values,true)
                                    }
                                    </Grid>)

                                }
                        </Grid>
                    </BorderLessPanel>
                    </Grid>
                )}
            </Grid>
    }

    if(values.length === 0) return <></>
    return createFrom(values)
}