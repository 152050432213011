
export interface RGBAColor {
    red: number,
    green: number,
    blue: number
    alpha: number
}

export function HexToRGBAColor(h: string) : RGBAColor|undefined {
    if(h === null) return undefined;
    if(h.startsWith("#")) {
        h = h.substring(1);
    }
    let r:any = 0, g:any = 0, b:any = 0;
    let a:any = "";
    if (h.length === 3) {
        r = "0x" + h[0] + h[0];
        g = "0x" + h[1] + h[1];
        b = "0x" + h[2] + h[2];
        return {red: +r, green: +g, blue: +b, alpha: 1}
        // 6 digits
    } else if (h.length === 6) {
        r = "0x" + h[0] + h[1];
        g = "0x" + h[2] + h[3];
        b = "0x" + h[4] + h[5];
        return {red: +r, green: +g, blue: +b, alpha: 1}
    } else if (h.length === 4) {
        r = "0x" + h[0] + h[0];
        g = "0x" + h[1] + h[1];
        b = "0x" + h[2] + h[2];
        a = "0x" + h[3] + h[3];
        a = +(a / 255).toFixed(3);
        return {red: +r, green: +g, blue: +b, alpha: a}
    } else if (h.length === 8) {
        r = "0x" + h[0] + h[1];
        g = "0x" + h[2] + h[3];
        b = "0x" + h[4] + h[5];
        a = "0x" + h[6] + h[7];
        a = +(a / 255).toFixed(3);
        return {red: +r, green: +g, blue: +b, alpha: a}
    }

    return undefined;
}
export function RGBAColorToHex(color: RGBAColor|undefined) : string {
    if(color === undefined) {
        return "0x000000";
    }
    let r = Math.round(color.red).toString(16);
    let g = Math.round(color.green).toString(16);
    let b = Math.round(color.blue).toString(16);

    if(color.alpha === 1) {
        if (r.length ===  1)
            r = "0" + r;
        if (g.length ===  1)
            g = "0" + g;
        if (b.length ===  1)
            b = "0" + b;
        return (r + g + b);
    } else {
        let a = Math.round(color.alpha * 255).toString(16);
        if (r.length ===  1)
            r = "0" + r;
        if (g.length ===  1)
            g = "0" + g;
        if (b.length ===  1)
            b = "0" + b;
        if (a.length ===  1)
            a = "0" + a;

        return (r + g + b + a);
    }
}

export function NamedColorToHex(name:string){
    let ctx:any = document.createElement('canvas').getContext('2d');
    ctx.fillStyle = name;
    return ctx.fillStyle;
}

export function isDarkColor(color: RGBAColor) {
    if(color === undefined) return false;
    if(color.alpha < 0.8) return false;
    let luma = 0.2126 * color.red + 0.7152 * color.green + 0.0722 * color.blue; // per ITU-R BT.709
    if (luma < 140) {
        return true;
    } else {
        return false;
    }
}

