import {StyleUtil} from "../../cms/ui/setting/StyleUtil";
import styles from "./BorderLessPanel.module.scss";
import React from "react";

export interface SettingPanelProps {
    title: string ,
    children: any
    id?:string
    className?: string
}

export default function BorderLessPanel(props: SettingPanelProps) {
    return <fieldset id={props.id} className={StyleUtil.combineClasses(styles.BorderPanel,props.className!)}>
        {props.children}
    </fieldset>
}