import {TableDataFetcher} from "./TableDataFetcher";
import {TableModel} from "../model/TableModel";
import { format } from 'date-fns'
import HttpClient from "./HttpClient";
import OrgCmd from "../model/jcr/OrgCmd";

const NAME_COLUMN = 0;
const CREATED_DATE_COLUMN = 1;
const MODIFIED_DATE_COLUMN = 2;
const STATUS_COLUMN = 3;
const OPTION_COLUMN = 4;
const TOTAL_COLUMN = 5;
export class ThemeDataFetcher implements TableDataFetcher {

    private data: any;
    private duplicateNames: Set<string>;
    constructor(private currentOrg:OrgCmd) {
        this.duplicateNames = new Set<string>();
    }

    canDelete(): boolean {
        return false;
    }

    getItemAt(row: number): any {
        return this.data[row];
    }

    findDataById(id: string): any {
        for(let i=0;this.data.length;i++) {
            if(this.data[i].orgCode === id) {
                return this.data[i];
            }
        }
        return null;
    }

    load()  {
        return HttpClient.get("/api/v0/jcr/org/" + this.currentOrg.orgCode + "/lite/themes").then((result) => {
            this.data = result.data.response;
            let allNames = new Set<string>();
            this.data.forEach((item:any) => {
                let name = item.themeName;
                if(allNames.has(name)) {
                    this.duplicateNames.add(name);
                } else {
                    allNames.add(name);
                }
            });
        });
    }

    getIdAt(row: number) : string {
        return this.data[row].id;
    }

    getColumnAlignAt(col: number): ("left" | "right" | "center") {
        if(col === NAME_COLUMN) return "left";
        else if(col === CREATED_DATE_COLUMN) return "right"
        else if(col === OPTION_COLUMN) return "center"
        else return "right"
    }

    getDataAt(row: number, col: number): TableModel {
        let item = this.data[row];
        if(col === NAME_COLUMN) {
            return new TableModel(item.id,
                item.themeName,"",
                 "/themes/"  + item.id);
                 // "/themes/" + item.themeName + "/" + item.id);
        } else if(col === CREATED_DATE_COLUMN) {
            return new TableModel(item.id,
                format(new Date(item.themeCreatedAt),  'yyyy/MM/dd'),"");
        } else if(col === MODIFIED_DATE_COLUMN) {
            return new TableModel(item.id,
                format(new Date(item.themeModifiedAt),  'yyyy/MM/dd'),"");
        } else if(col === STATUS_COLUMN) {
            return new TableModel("APPROVED",
                "Publish","",);
        }  else if(col === OPTION_COLUMN) {
            return new TableModel(item.id,
                "Rename",
                "",
                "/themes/" + item.id,
                undefined,
                [
                    { icon: "content_copy", label: "Duplicate", pathname: "/themes/" + item.id , search: null, action: { tag: "DUPLICATE", tagObject: item } },
                    { icon: "download", label: "Download", pathname: "/themes/" + item.id , search: null, action: { tag: "DOWNLOAD", tagObject: item }},
                ]);
        } else {
            return new TableModel("",
                "","",);
        }
    }

    getTotalRecords(): number {
        if(this.data && this.data !== null) {
            return this.data.length;
        } else {
            return 0;
        }
    }

    getColumnNameAt(col: number): string {
        if(col === NAME_COLUMN) {
            return "Style Name";
        } else if(col === CREATED_DATE_COLUMN) {
            return "Create Date"
        } else if(col === MODIFIED_DATE_COLUMN) {
            return "Modified Date"
        } else if(col === STATUS_COLUMN) {
            return "Status"
        } else if(col === OPTION_COLUMN) {
            return "Options"
        }  else {
            return "";
        }
    }

    getNumberOfColumns(): number {
        return TOTAL_COLUMN;
    }



}