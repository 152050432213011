
/*eslint no-template-curly-in-string: "off"*/
function ImageUtil() {
    return {
        toPath(websiteURL:string|undefined ,image:string) {
            if(websiteURL === undefined) {
                websiteURL = "";
            }
            if(image === null) return "";
            if(image.startsWith("/")) {
                return websiteURL + image;
            } else if(image.startsWith("http")) {
                return image;
            } else if(image.startsWith("${WEBSITE_URL}")) {
                return image.replace("${WEBSITE_URL}",websiteURL);
            } else if(image.startsWith(".")) {
                return image;
            } else {
                return websiteURL + "/" + image;
            }
        }
    }
}

export const imageUtil = ImageUtil();