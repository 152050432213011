import HttpClient from "./HttpClient";
import {SearchProfileCmd} from "../model/lsi/SearchProfileCmd";
import {LiteSearchProfileCmd} from "../model/lsi/LiteSearchProfileCmd";
import AttributeGroupCmd from "../model/lsi/AttributeGroupCmd";
import LSIFieldCmd from "../model/lsi/LSIFieldCmd";
import {QueryType} from "../cms/model/QueryType";
import SearchFilter from "../model/lsi/SearchFilter";
import ProductPriceAvailabilityInterface from "../cms/model/ProductPriceAvailabilityInterface";
import CoreCmd from "../model/lsi/CoreCmd";
import MemberCoreCmd from "../model/lsi/MemberCoreCmd";
import {ProxyOrgCmd, ProxyStorefrontCmd} from "../model/proxy/cloudlsi-app";
import {GetPortalDAMEnvironment, GetPortalEnvironment} from "../ui/popover/PortalEnvironmentPopover";


export function getCurrentLsiURL() {
    if(lsi.lsiURL === null) return process.env.REACT_APP_LSI_HOST;
    if(lsi.lsiURL !== "" && !lsi.lsiURL.startsWith("http")) {
        return process.env.REACT_APP_LSI_HOST;
    }
    return lsi.lsiURL;
}

export function getCurrentMemberCore() {
    return lsi.memberCore;
}

export function getCurrentOrgCode() {
    return lsi.orgCode;
}

function LSISchema() {
    return {
        membercores: (memberCore?: string) => {
            return HttpClient.get<MemberCoreCmd[]>(`${(getCurrentLsiURL())}/api/org/${(getCurrentOrgCode())}/membercore`)
        },
        allfields: (memberCore?: string) => {
            return HttpClient.get<LSIFieldCmd[]>(`${(getCurrentLsiURL())}/api/core/${memberCore ?  memberCore : getCurrentMemberCore()}/alllsifields?includeUnpublished=true&mode=all`)
        },
        indexStatus: () => {
            return HttpClient.get(`${(getCurrentLsiURL())}/api/core/${(getCurrentMemberCore())}/indexStatus`)
        },
        datasources: () => {
            return HttpClient.get<CoreCmd[]>(`${(getCurrentLsiURL())}/api/org/${(getCurrentOrgCode())}/core/datasource`)
        }
    }
}

function LSIUtil() {
    return {
        filters2fqs: (filters: SearchFilter[]) => {
            let rValues:string[] = []
            // let map: Map<string,SearchFilter[]> = new Map();
            // for (let filter of filters) {
            //     if(filter.isEnabled === false) continue;
            //     let subFilters = map.get(filter.field);
            //     if(!subFilters) {
            //         subFilters = [];
            //         map.set(filter.field,subFilters);
            //     }
            //     subFilters.push(filter);
            // }

            for (let filter of filters) {
                if(filter.isEnabled === false) continue;
                let {operator} = filter;
                let isNegative = filter.operator.startsWith("exclude_");
                let fqStr = "";
                if(isNegative) {
                    operator = operator.substring(8);
                }

                if(operator === 'is') {
                    fqStr = `${filter.field}: ${filter.value}`
                } else if(operator === "contain") {
                    if(filter.value.startsWith("\"") && filter.value.endsWith("\"")) {
                        fqStr = `${filter.field}: ${filter.value}`
                    } else {
                        fqStr = `${filter.field}: *${filter.value}*`
                    }
                } else if(operator === "start_with") {
                    if(filter.value.startsWith("\"") && filter.value.endsWith("\"")) {
                        fqStr = `${filter.field}: ${filter.value}`
                    } else {
                        fqStr = `${filter.field}: ${filter.value}*`
                    }
                } else if(operator === "end_with") {
                    if(filter.value.startsWith("\"") && filter.value.endsWith("\"")) {
                        fqStr = `${filter.field}: ${filter.value}`
                    } else {
                        fqStr = `${filter.field}: *${filter.value}`
                    }
                } else {
                    fqStr = `${filter.field}:[* TO *]`
                }

                if(isNegative) {
                    fqStr = `-${fqStr}`
                }

                rValues.push(fqStr);
            }
            console.log(rValues);
            return rValues;
        },

        split: (string: string) => {
            if(string === null) return string;
            let array = string.split(/,/);
            let newArray:string[] = [];
            for(let i=0;i<array.length;i++) {
                array[i] = array[i].trim();
                if(array[i] === "") continue;
                newArray.push(array[i]);
            }
            return newArray;
        },

        or: (string: string) => {
          return lsi.util.escapeArray(lsi.util.split(string)).join(" OR ")
        },

        and: (string: string) => {
            return lsi.util.escapeArray(lsi.util.split(string)).join(" AND ")
        },

        escapeString: (string: string) => {
            let buffer = [];
            for (let i = 0; i < string.length; i++) {
                let ch = string.charAt(i);
                if (ch === ' ') {
                    buffer.push("\\")
                }
                buffer.push(ch);
            }
            return buffer.join("");
        },

        escapeArray: (strings: string[]) => {
            if(strings === null) return strings;

            for(let j=0;j<strings.length;j++) {
                if(strings[j] === "") continue;
                strings[j] = lsi.util.escapeString(strings[j]);
            }
            return strings;
        },
    }
}

function LSIConnector() {
    return {
        connectorTypes: () => { // query all connector types
            return HttpClient.get(`${(getCurrentLsiURL())}/api/core/${(getCurrentMemberCore())}/connectorsforcore`)
        },

        connectorNamesByTypeId: (connectorTypeId: number) => { // list connector names under connector type
            return HttpClient.get(`${(getCurrentLsiURL())}/api/core/${(getCurrentMemberCore())}/connectorprofiles?connectorId=${connectorTypeId}`)
        },

        connectorNames: (connectorType: string) => { // list connector names under connector type
            return HttpClient.get(`${(getCurrentLsiURL())}/api/core/${(getCurrentMemberCore())}/connectorprofiles?connectorName=${connectorType}`)
        },

        connectorDetail: (connectorNameId:number) => {
            return HttpClient.get(`${(getCurrentLsiURL())}/api/connectorconfigurationsbyconnectorprofile?connectorProfileId=${connectorNameId}`)
        }
    }
}

function PortalDam() {
    return {
        list: (q:string, recursive:boolean, path:string) => {
            //https://dev.lsicloud.net/api/org/68/filelist?bypassHandler=true&fl=*&path=%2F&rows=100&sort=NAME_sort_+ASC&start=0
            //${lsi.orgCode}
            if(!q) {
                q = "*"
            }
            return HttpClient.get(`${(getCurrentLsiURL())}/api/jcr/org/${lsi.siteCode}/pdam/${GetPortalDAMEnvironment()}/filelist`, {
                params: {
                    q: q,
                    recursive: recursive,
                    path: path,
                }
            })
        },
        listFolder: (path:string) => {
            return HttpClient.get(`${(getCurrentLsiURL())}/api/jcr/org/${lsi.siteCode}/pdam/${GetPortalDAMEnvironment()}/folderlist`, {
                params: {
                    path: path,
                }
            })
        },
        mkdir:  (folderPath: string) => {
            return HttpClient.postForm(`${(getCurrentLsiURL())}/api/jcr/org/${lsi.siteCode}/pdam/${GetPortalDAMEnvironment()}/newfolder`, {
                folderName: folderPath,
            })
        },
        rm: (fileName: string) => {
            //${lsi.orgCode}
            return HttpClient.postForm(`${(getCurrentLsiURL())}/api/jcr/org/${lsi.siteCode}/pdam/${GetPortalDAMEnvironment()}/deletefile`, {
                fileName: fileName
            })
        },
        rmdir: (folderName:string) => {
            return HttpClient.postForm(`${(getCurrentLsiURL())}/api/jcr/org/${lsi.siteCode}/pdam/${GetPortalDAMEnvironment()}/deletefolder`, {
                folderName: folderName
            });
        },
        upload: (filePath: string,files:File[], progressEvent?: (progressEvent:ProgressEvent) => void) => { // absolute path
            if(progressEvent) {
                const config =  {
                    onUploadProgress: progressEvent
                }
                return HttpClient.postForm(`${(getCurrentLsiURL())}/api/jcr/org/${lsi.siteCode}/pdam/${GetPortalDAMEnvironment()}/uploadfile`, {
                    path: filePath,
                    file: files,
                },config)
            } else {
                return HttpClient.postForm(`${(getCurrentLsiURL())}/api/jcr/org/${lsi.siteCode}/pdam/${GetPortalDAMEnvironment()}/uploadfile`, {
                    path: filePath,
                    file: files
                })
            }
        },
        uploadzip: (filePath: string,replace: boolean, file:File[], progressEvent?: (progressEvent:ProgressEvent) => void) => {
            if(progressEvent) {
                const config =  {
                    onUploadProgress: progressEvent
                }
                return HttpClient.postForm(`${(getCurrentLsiURL())}/api/jcr/org/${lsi.siteCode}/pdam/${GetPortalDAMEnvironment()}/uploadzip`, {
                    path: filePath,
                    replace: replace,
                    file: file,
                },config)
            } else {
                return HttpClient.postForm(`${(getCurrentLsiURL())}/api/jcr/org/${lsi.siteCode}/pdam/${GetPortalDAMEnvironment()}/uploadzip`, {
                    path: filePath,
                    replace: replace,
                    file: file,
                })
            }
        },
        rename: (fileName: string, newFileName: string) => {
            return HttpClient.postForm(`${(getCurrentLsiURL())}/api/jcr/org/${lsi.siteCode}/pdam/${GetPortalDAMEnvironment()}/renamefile`, {
                fileName: fileName,
                newFileName: newFileName
            })
        },
    }
}

function LSIDam() {
    return {
        listByID: ( q:string, path:string, imageFL:string, start:number, rows: number, recursive: boolean) => {
            //https://dev.lsicloud.net/api/core/imarkecomm/filelistById?imageFL=SM_PORTAL_IMAGE_PAIR_1,SM_PORTAL_IMAGE_PAIR_10,SM_PORTAL_IMAGE_PAIR_11,SM_PORTAL_IMAGE_PAIR_12,SM_PORTAL_IMAGE_PAIR_13,SM_PORTAL_IMAGE_PAIR_14,SM_PORTAL_IMAGE_PAIR_15,SM_PORTAL_IMAGE_PAIR_16,SM_PORTAL_IMAGE_PAIR_17,SM_PORTAL_IMAGE_PAIR_18,SM_PORTAL_IMAGE_PAIR_19,SM_PORTAL_IMAGE_PAIR_2,SM_PORTAL_IMAGE_PAIR_20,SM_PORTAL_IMAGE_PAIR_3,SM_PORTAL_IMAGE_PAIR_4,SM_PORTAL_IMAGE_PAIR_5,SM_PORTAL_IMAGE_PAIR_6,SM_PORTAL_IMAGE_PAIR_7,SM_PORTAL_IMAGE_PAIR_8,SM_PORTAL_IMAGE_PAIR_9&path=%2Fimages%2Fdelete%2F&q=1&readme=false&recursive=false&rows=100&start=0
            return HttpClient.get(`${(getCurrentLsiURL())}/api/org/${(getCurrentOrgCode())}/filelist`, {
                params: {
                    path: path,
                    q: q,
                    recursive: recursive,
                    sort: "NAME_sort_ ASC",
                    imageFL: imageFL,
                    start: start,
                    row: rows
                }
            })
        },

        list: (q:string, recursive:boolean, path:string, start: number, rows: number) => {
            //https://dev.lsicloud.net/api/org/68/filelist?bypassHandler=true&fl=*&path=%2F&rows=100&sort=NAME_sort_+ASC&start=0
            //${lsi.orgCode}
            if(!q) {
                q = "*"
            }
            return HttpClient.get(`${(getCurrentLsiURL())}/api/org/${(getCurrentOrgCode())}/filelist`, {
                params: {
                    q: q,
                    recursive: recursive,
                    path: path,
                    sort: "NAME_sort_ ASC, ORIGINAL_FILE_NAME DESC",
                    fq: "-NAME_sort_: readme",
                    start: start,
                    rows: rows
                }
            })
        },

        listFolder: (path:string) => {
            return HttpClient.get(`${(getCurrentLsiURL())}/api/org/${(getCurrentOrgCode())}/filelist`, {
                params: {
                    path: path,
                    sort: "NAME_sort_ ASC",
                    start: 0,
                    rows: 0
                }
            })
        },

        clone: (fileName: string, newFileName: string) => {
            //https://dev.lsicloud.net/api/org/1/clonefile?fileName=%2Fimages%2FDefault-736h.png&newFileName=%2Fimages%2FDefault-736h-copy.png
            return HttpClient.get(`${(getCurrentLsiURL())}/api/org/${(getCurrentOrgCode())}/clonefile`, {
                params: {
                    fileName: fileName,
                    newFileName: newFileName
                }
            })
        },

        stat: (path: string,includeRoot: boolean) => {
            //https://dev.lsicloud.net/api/org/1/filespaceconsume?includeRoot=true&path=%2Fimages%2F
            return HttpClient.get(`${(getCurrentLsiURL())}/api/org/${(getCurrentOrgCode())}/filespaceconsume`, {
                params: {
                    includeRoot: includeRoot,
                    path: path
                }
            })
        },

        // folderPath is full path from root
        mkdir: (folderPath: string) => {
            //https://dev.lsicloud.net/api/org/60/newfolder?folderName=test1%2Ftest2%2Ftest222
            return HttpClient.postForm(`${(getCurrentLsiURL())}/api/org/${(getCurrentOrgCode())}/newfolder`, {
                folderName: folderPath,
            })
        },

        rename: (fileName: string, newFileName: string) => {
            //https://dev.lsicloud.net/api/org/60/renamefile?fileName=%2Ftest%2Ffilexxx&newFileName=%2Ftest%2Ffilexxxx
            return HttpClient.postForm(`${(getCurrentLsiURL())}/api/org/${(getCurrentOrgCode())}/renamefile`, {
                fileName: fileName,
                newFileName: newFileName
            })
        },
        rm: (fileName: string) => {
            //${lsi.orgCode}
            return HttpClient.postForm(`${(getCurrentLsiURL())}/api/org/${(getCurrentOrgCode())}/deletefile`, {
                    fileName: fileName
            })
        },

        rmdir: (folderName:string) => {
            //https://dev.lsicloud.net/api/org/60/deletefolder?folderName=%2Ftest-2%2Ftest-3%2F
            return HttpClient.postForm(`${(getCurrentLsiURL())}/api/org/${(getCurrentOrgCode())}/deletefolder`, {
                folderName: folderName
            });
        },

        upload: (filePath: string,files:File[], progressEvent?: (progressEvent:ProgressEvent) => void) => { // absolute path
            if(progressEvent) {
                const config =  {
                    onUploadProgress: progressEvent
                }
                return HttpClient.postForm(`${(getCurrentLsiURL())}/api/org/${(getCurrentOrgCode())}/uploadfile`, {
                    path: filePath,
                    file: files,
                    tags: "null"
                },config)
            } else {
                return HttpClient.postForm(`${(getCurrentLsiURL())}/api/org/${(getCurrentOrgCode())}/uploadfile`, {
                    path: filePath,
                    file: files,
                    tags: "null"
                })
            }
        },
        uploadzip: (filePath: string,replace: boolean, file:File[], progressEvent?: (progressEvent:ProgressEvent) => void) => {
            //https://dev.lsicloud.net/api/org/60/uploadzip?path=/&tags=null&replace=true
            if(progressEvent) {
                const config =  {
                    onUploadProgress: progressEvent
                }
                return HttpClient.postForm(`${(getCurrentLsiURL())}/api/org/${(getCurrentOrgCode())}/uploadzip`, {
                    path: filePath,
                    replace: replace,
                    file: file,
                    tags: "null"
                    //file: binary file
                },config)
            } else {
                return HttpClient.postForm(`${(getCurrentLsiURL())}/api/org/${(getCurrentOrgCode())}/uploadzip`, {
                    path: filePath,
                    replace: replace,
                    file: file,
                    tags: "null"
                    //file: binary file
                })
            }
        }
    }
}

function LSIProxyOrganization() {
    return {
        get: () => {
            return HttpClient.get<ProxyOrgCmd>( `${(getCurrentLsiURL())}/api/proxy/orgs/${(getCurrentOrgCode())}`)
        }
    }
}
function LSIProxyStorefront() {
    return {
        get: () => { // get storefront from portal only
            return HttpClient.get<ProxyStorefrontCmd[]>( `${(getCurrentLsiURL())}/api/proxy/orgs/${(getCurrentOrgCode())}/storefronts`,{params: {includeJcr: false}})
        },
        post: (data:ProxyStorefrontCmd) => { // get storefront from portal only
            return HttpClient.post<ProxyStorefrontCmd>( `${(getCurrentLsiURL())}/api/proxy/orgs/${(getCurrentOrgCode())}/storefronts`,data, {params: {includeJcr: false}})
        }
    }
}

function LSICollection() {
    return {
        collectionTypes: () => {
            return HttpClient.get( `${(getCurrentLsiURL())}/api/collectionTypes`,{params: {orgCode: getCurrentOrgCode()}})
        },
        collectionsByType: (collectionType: string) => {
            return HttpClient.get(`${(getCurrentLsiURL())}/api/core/${(getCurrentMemberCore())}/collections?collectionType=${collectionType}`)
        },
        drilldownCollections: () => {
            return HttpClient.get(`${(getCurrentLsiURL())}/api/core/${(getCurrentMemberCore())}/collections?collectionType=DrillDown`)
        },
        featureCollections: () => {
            return HttpClient.get(`${(getCurrentLsiURL())}/api/core/${(getCurrentMemberCore())}/collections?collectionType=Featured`)
        },
        relatedCollections: () => {
            return HttpClient.get(`${(getCurrentLsiURL())}/api/core/${(getCurrentMemberCore())}/collections?collectionType=Related`)
        },
        specialCollections: () => {
            return HttpClient.get(`${(getCurrentLsiURL())}/api/core/${(getCurrentMemberCore())}/collections?collectionType=Specials`)
        },
        substituteCollections: () => {
            return HttpClient.get(`${(getCurrentLsiURL())}/api/core/${(getCurrentMemberCore())}/collections?collectionType=Substitute`)
        },
        promotionalCollections: () => {
            return HttpClient.get(`${(getCurrentLsiURL())}/api/core/${(getCurrentMemberCore())}/collections?collectionType=Promotional`)
        }

    }
}

function LSIAttributegroup() {
    return {
        groups: () => {
            return HttpClient.get<AttributeGroupCmd[]>( `${(getCurrentLsiURL())}/api/core/${(getCurrentMemberCore())}/attributegroups`)
        },
        fields: (groupName: string) => {
            return HttpClient.get<LSIFieldCmd[]>( `${(getCurrentLsiURL())}/api/core/${(getCurrentMemberCore())}/attributegroupfields`,{params: {groupNames: groupName}})
        }
    }
}

const ensureFq = (fq?: string[]) => {
    let newFq: string[]
    if(fq) {
        newFq = [...fq];
    } else {
        newFq = [];
    }

    if(lsi.ppa.includeBranchAvailability) {

    }

    if(lsi.ppa.includeProductByRecorderPriceLines) {

    }

    if(lsi.ppa.includeProductsByBranchIds) {

    }

    if(lsi.ppa.includeProductsByBuyLines) {
        newFq.push("_lsi_cn_BuyLine:" + lsi.ppa.includeProductsByBuyLines);
    }

    if(lsi.ppa.includeProductsByKeywords) {
        newFq.push("_lsi_cn_Keywords:" + lsi.ppa.excludeProductsByKeywords);
    }

    if(lsi.ppa.includeProductsByPriceLines) {
        newFq.push("_lsi_cn_PriceLine:" + lsi.ppa.includeProductsByPriceLines);

    }

    if(lsi.ppa.excludeAvailabilityFromBranch) {

    }

    if(lsi.ppa.excludeProductsByBuyLines !== "") {
        newFq.push("-_lsi_cn_BuyLine:" + lsi.ppa.includeProductsByBuyLines);
    }

    if(lsi.ppa.excludeProductsByBranchIds !== "") {

    }

    if(lsi.ppa.excludeProductsByKeywords !== "") {
        newFq.push("-_lsi_cn_Keywords:" + lsi.ppa.excludeProductsByKeywords);
    }

    if(lsi.ppa.excludeProductsByPriceLines !== "") {
        newFq.push("-_lsi_cn_PriceLine:" + lsi.ppa.excludeProductsByPriceLines);
    }

    if(lsi.ppa.excludeProductsByStockStatus !== "") {
        newFq.push("-_lsi_cn_Status:" + lsi.ppa.excludeProductsByStockStatus);
    }

    if(newFq.length === 0) {
        return undefined;
    } else {
        return newFq;
    }
}

function LSISearch() {
    return {
        queryByMerchGroup: (partNumbers: string,
                     start: number,
                     row: number,
                     searchProfile: string,
                     locationId?: string,
                     connectorType? :string,
                     connectorName?: string) => {
            let array = lsi.util.split(partNumbers);
            let qString = "_lsi_collection_names_:(" + array.join(" OR ") + ")";
            return lsi.search.query(qString, start, row, searchProfile, locationId, connectorType, connectorName);
        },

        queryByIds: (partNumbers: string,
                            start: number,
                            row: number,
                            searchProfile: string,
                            locationId?: string,
                            connectorType? :string,
                            connectorName?: string) => {
            let array = lsi.util.split(partNumbers);
            let qString = "_lsi_cn_ProductID:(" + array.join(" OR ") + ")";
            return lsi.search.query(qString, start, row, searchProfile, locationId, connectorType, connectorName);
        },

        queryAutoMerchGroup: (partNumbers: string,
                              start: number,
                              row: number,
                              searchProfile: string,
                              locationId?: string,
                              connectorType? :string,
                              connectorName?: string) => {
            let array = lsi.util.split(partNumbers);
            let qString = "_lsi_id_:(" + array.join(" OR ") + ")";
            return HttpClient.get(`${(getCurrentLsiURL())}/api/searchDistribrain`, {
                params: {
                    locationId: locationId && locationId !== "" ? locationId : getCurrentMemberCore(),
                    connector: connectorType && connectorType !== "" ? connectorType : lsi.connectorType,
                    connectorProfile: connectorName && connectorType !== "" ? connectorName : lsi.connectorName,
                    searchProfile: searchProfile && searchProfile !== "" ? searchProfile : lsi.searchProfile,
                    start: start,
                    q: qString,
                    wt: 'json',
                    rows: row
                }
            });
        },

        queryFamilies: (partNumbers: string,
                          start: number,
                          row: number,
                          searchProfile: string,
                          locationId?: string,
                          connectorType? :string,
                          connectorName?: string) => {
            let array = lsi.util.split(partNumbers);
            let qString = "_lsi_cn_Families:(" + array.join(" OR ") + ")";
            return lsi.search.query(qString, start, row, searchProfile, locationId, connectorType, connectorName);
        },

        queryByKeyWords: (partNumbers: string,
                     start: number,
                     row: number,
                     searchProfile: string,
                     locationId?: string,
                     connectorType? :string,
                     connectorName?: string) => {
            let array = lsi.util.split(partNumbers);
            let qString = "_lsi_cn_Keywords:(" + array.join(" OR ") + ")";
            return lsi.search.query(qString, start, row, searchProfile, locationId, connectorType, connectorName);
        },


        query: (q: string, start: number, row: number,
                searchProfile: string,
                locationId?: string,
                connectorType?:string,
                connectorName?:string,
                fq?:string[]) => {


                fq = ensureFq(fq);

                return HttpClient.get(`${(getCurrentLsiURL())}/api/search`, {
                    params: {
                        locationId: locationId && locationId !== "" ? locationId : getCurrentMemberCore(),
                        connector: connectorType && connectorType !== "" ? connectorType : lsi.connectorType,
                        connectorProfile: connectorName && connectorType !== "" ? connectorName : lsi.connectorName,
                        searchProfile: searchProfile && searchProfile !== "" ? searchProfile : lsi.searchProfile,
                        start: start,
                        q: q,
                        wt: 'json',
                        rows: row,
                        fq: fq && fq.length > 0 ? fq : null
                    }
                });
        },
        facetQuery: (q: string, start: number, row: number,
                searchProfile: string,
                locationId?: string,
                connectorType?:string,
                connectorName?:string,
                     fq?:string[],
                     facetField?: string) => {

            fq = ensureFq(fq);

            return HttpClient.get(`${(getCurrentLsiURL())}/api/facet_search`, {
                params: {
                    locationId: locationId && locationId !== "" ? locationId : getCurrentMemberCore(),
                    connector: connectorType && connectorType !== "" ? connectorType : lsi.connectorType,
                    connectorProfile: connectorName && connectorType !== "" ? connectorName : lsi.connectorName,
                    searchProfile: searchProfile && searchProfile !== "" ? searchProfile : lsi.searchProfile,
                    start: start,
                    q: q,
                    wt: 'json',
                    rows: row,
                    fq: fq && fq.length > 0 ? fq : null,
                    'facet.field' : facetField
                }
            });
        },

        widgetSearch: (searchType: string|QueryType, searchString:string, start: number, rows: number, searchProfile: string,
                       locationId?: string,
                       connectorType?:string,
                       connectorName?:string) => {
            // if(searchType in QueryType) {
            //     let newSearchString = "";
            //     searchString = searchString.replaceAll(/\+,/g," OR ");
            //     if(searchType === QueryType.search) {
            //         newSearchString = searchString;
            //     } else if(searchType === QueryType.merchgroup) {
            //         newSearchString = `_lsi_collection_names_:("${searchString}")`
            //     } else if(searchType === QueryType.fieldsearch) {
            //         newSearchString = searchString
            //     } else if(searchType === QueryType.keyword) {
            //         newSearchString = `_lsi_cn_Keywords: (${searchString})`;
            //     } else if(searchType === QueryType.family) {
            //         newSearchString = `_lsi_cn_Families: (${searchString})`;
            //     } else if(searchType === QueryType.partnumber) {
            //         newSearchString = `_lsi_cn_ProductID: (${searchString})`;
            //     } else {
            //         return null;
            //     }
            //     return lsi.search.query(newSearchString, start, rows, searchProfile, locationId, connectorType, connectorName)
            // } else {
                if (searchType === "BY_FAMILY" || searchType === QueryType.family) {
                    return lsi.search.queryFamilies(searchString, start, rows, searchProfile, locationId, connectorType, connectorName)
                } else if (searchType === "BY_IDS" || searchType === QueryType.partnumber) {
                    return lsi.search.queryByIds(searchString, start, rows, searchProfile, locationId, connectorType, connectorName)
                } else if (searchType === "BY_KEYWORD"|| searchType === QueryType.keyword) {
                    return lsi.search.queryByKeyWords(searchString, start, rows, searchProfile, locationId, connectorType, connectorName)
                } else if (searchType === "BY_SIMILAR") {
                    return lsi.search.queryAutoMerchGroup(searchString, start, rows, searchProfile, locationId, connectorType, connectorName)
                }  else if (searchType === "BY_FBT") {
                    return lsi.search.queryAutoMerchGroup(searchString, start, rows, searchProfile, locationId, connectorType, connectorName)
                } else if (searchType === "BY_MRG"|| searchType === QueryType.merchgroup) {
                    return lsi.search.query(`_lsi_collection_names_:("${searchString}")`, start, rows, searchProfile, locationId, connectorType, connectorName);
                } else return null;
            //
            // }
        },

        widgetFacetSearch: (searchType: string|QueryType, searchString:string, start: number, rows: number, searchProfile: string,
                       locationId?: string,
                       connectorType?:string,
                       connectorName?:string) => {
            if(searchType in QueryType) {
                let newSearchString = "";
                searchString = searchString.replaceAll(/\+,/g," OR ");
                if(searchType === QueryType.search) {
                    newSearchString = searchString;
                } else if(searchType === QueryType.merchgroup) {
                    newSearchString = `_lsi_collection_names_:("${searchString}")`
                } else if(searchType === QueryType.fieldsearch) {
                    newSearchString = searchString
                } else if(searchType === QueryType.keyword) {
                    newSearchString = `_lsi_cn_Keywords: (${searchString})`;
                } else if(searchType === QueryType.family) {
                    newSearchString = `_lsi_cn_Families: (${searchString})`;
                } else if(searchType === QueryType.partnumber) {
                    newSearchString = `_lsi_cn_ProductID: (${searchString})`;
                } else {
                    return null;
                }
                return lsi.search.facetQuery(newSearchString, start, rows, searchProfile, locationId, connectorType, connectorName)
            } else {
                if (searchType === "BY_IDS") {
                    return lsi.search.facetQuery(searchString, start, rows, searchProfile, locationId, connectorType, connectorName)
                } else if (searchType === "BY_MRG") {
                    return lsi.search.facetQuery(`_lsi_collection_names_:("${searchString}")`, start, rows, searchProfile, locationId, connectorType, connectorName);
                } else return null;

            }
        },

        searchProfile: (searchProfileId:number,locationId?: string) => {
            if(locationId) {
                return HttpClient.get<SearchProfileCmd>(`${(getCurrentLsiURL())}/api/core/${locationId}/searchprofiles?searchProfileId=${searchProfileId}`,)
            } else {
                return HttpClient.get<SearchProfileCmd>(`${(getCurrentLsiURL())}/api/core/${(getCurrentMemberCore())}/searchprofiles?searchProfileId=${searchProfileId}`,)
            }
        },
        searchProfiles: (lsiURL?: string, locationId?: string) => {
            if(lsiURL && lsiURL !== "") {
                if(!lsiURL.startsWith("http")) {
                    lsiURL = "";
                }
            }
            if(locationId) {
                return HttpClient.get<LiteSearchProfileCmd[]>(`${lsiURL ? lsiURL : getCurrentLsiURL()}/api/core/${locationId}/lite/searchprofiles`)
            } else {
                return HttpClient.get<LiteSearchProfileCmd[]>(`${lsiURL ? lsiURL : getCurrentLsiURL()}/api/core/${(getCurrentMemberCore())}/lite/searchprofiles`)
            }
        }
    }
}

function LSISalesforce() {
    return {
        loginToken: (lsiURL?: string) => {
            //https://lsicloud.net/api/salesforce/loginToken?base64=true
            return HttpClient.get<LiteSearchProfileCmd[]>(`${lsiURL ? lsiURL : getCurrentLsiURL()}/api/salesforce/loginToken?base64=true`)
        },
        communityUrl: () => "https://billtrust.my.site.com/SupportCommunity/s/article/",
        adminUrl: () => "https://billtrust.lightning.force.com/one/one.app#/articles/Knowledge/"
    }
}

function LSITraining() {
    return {
        loginToken: (lsiURL?: string) => {
            //https://lsicloud.net/api/salesforce/loginToken?base64=true
            return HttpClient.get(`${lsiURL ? lsiURL : getCurrentLsiURL()}/api/training/loginToken`)
        },
    }
}

function LSIToken() {
    return {
        loginToken:  (token:string, lsiURL?: string) => {
            return HttpClient.get(`${lsiURL ? lsiURL : getCurrentLsiURL()}/api/${token}/loginToken?base64=true`)
        }
    }
}

function LSIDataFetcher() {
    return {
        util: LSIUtil(),
        cdnURL: "",
        lsiURL: "",
        orgCode: "",
        siteCode: "",
        memberCore: "",
        connectorType:"",
        searchProfile:"",
        connectorName:"",
        ppa: {} as ProductPriceAvailabilityInterface,
        schema: LSISchema(),
        connector: LSIConnector(),
        search: LSISearch(),
        collection: LSICollection(),
        dam: LSIDam(),
        pdam: PortalDam(),
        attributegroup: LSIAttributegroup(),
        salesforce: LSISalesforce(),
        training: LSITraining(),
        token: LSIToken(),
        proxy: {
            storefront: LSIProxyStorefront(),
            organization: LSIProxyOrganization(),
        }
    }
}

export const lsi = LSIDataFetcher();
