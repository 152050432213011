// import styles from "../../cms/ui/shared/editable/CMSEditor.module.scss";
import styles from "../../cms/ui/shared/editable/CMSEditorWithFrame.module.scss";
import React, {useCallback, useEffect, useState} from "react";
import NewWidget from "../../cms/ui/shared/newable/NewWidget";
import {NewOption, NewType} from "../../cms/model/NewOption";
import {DeleteOption, DeleteType} from "../../cms/model/DeleteOption";
import CMSTheme, {LoadCMSTheme} from "../../cms/ui/shared/cmstheme/CMSTheme";
import {nanoid} from "nanoid";
import {useOrganization} from "../../hook/useOrganization";
import {viewEditAction, viewUpdateDataAction} from "../../redux/features/view/viewSlice";
import _ from "lodash";
import {SettingGroup, SettingOption} from "../../cms/model/SettingOption";
import {SettingChangeFunction} from "../../cms/ui/setting/SettingChange";
import {useAppDispatch} from "../../redux/hook";
import {
    doViewGenerator,
    doAssignNewIds,
    findParentIdFromChildId,
    findViewObjectById,
    findViewObjectByLevelString, getInitWidgetProps
} from "../../cms/util/UIFactory";
import {SectionInterface} from "../../cms/model/SectionInterface";
import {SwapOption, SwapType} from "../../cms/model/SwapOption";
import {WidgetInterface} from "../../cms/model/WidgetInterface";
import SharedObjects from "../../cms/ui/shared/editable/ShareObjects";
import {jcr} from "../../datafetcher/JCRDataFetcher";
import HttpClient from "../../datafetcher/HttpClient";
import StorefrontCmd from "../../model/jcr/StorefrontCmd";
import {useParams} from "react-router-dom";
import {
    sectionDescriptionsDataFetcher,
    widgetDescriptionsDataFetcher
} from "../../datafetcher/SectionAndWidgetDataFetcher";
import {SectionDesc} from "../../cms/model/desc/SectionDesc";
import {WidgetDesc} from "../../cms/model/desc/WidgetDesc";
import {StyleUtil} from "../../cms/ui/setting/StyleUtil";
import {
    FocusLevelStr,
    ResetEditablePanel,
    SetFocusLevelStr,
    SetOverLevel
} from "../../cms/ui/shared/editable/EditablePanel";
import {SubmitWidgetId, SubmitWidgetState} from "../../hook/useWidgetState";
import {InputOption} from "../../model/InputModel";
import useWindowSize from "../../cms/hook/useWindowSize";
import {UndoCmd} from "../../model/UndoCmd";
import {GetPortalEnvironment, GetPortalHostName, ReloadPortalEnvironment} from "../popover/PortalEnvironmentPopover";
import {SectionGroupType} from "../../cms/model/desc/SectionGroupType";
import {GetLocalItem, SetLocalItem} from "../../util/LocalStorageUtil";
import {CaptureLayout} from "../../util/LayoutCapture";
import variableContext from "../../context/VariableContext";
import {DEFAULTSECTION_MAX_COLUMNS} from "../../cms/ui/section/defaultsection/DefaultSection";
import useFocusInEditable from "../../hook/useFocusInEditable";

export interface LiveEditorProps {

}
//
// interface DrawerState {
//     drawerId: string;
//     drawerType: number,
//     themeName: string,
//     settingObjects: {level: string, title: string, suggestTags:string[], groups: SettingGroup[], options: SettingOption[],props: any,onChange:SettingChangeFunction | null, saveState: ()=>void},
//     newType: {type: NewType, uiLevelStr: string}
// }
//
// interface Action {
//     type: 'settingDrawer' | 'newDrawer' | 'reset'
//     themeName?: string
//     data: any
// }
//
// function reducer(state:DrawerState ,action:Action): DrawerState {
//     let newState:DrawerState = {...state};
//     switch(action.type) {
//         case 'settingDrawer' :
//             newState.drawerId = nanoid();
//             newState.drawerType = 1;
//             newState.themeName = action.themeName!;
//             newState.settingObjects = action.data;
//             break;
//         case 'newDrawer' :
//             newState.drawerType = 2;
//             newState.newType = action.data;
//             break;
//         case 'reset':
//         default:
//             newState.drawerType = 0;
//             break;
//     }
//     return newState;
// }

let clonedViewObjects: SectionInterface[] = [];
let currentThemeProp:any;
let redoStack:UndoCmd[] = [];
let undoStack:UndoCmd[] = [];
let copiedObject: SectionInterface | WidgetInterface | undefined = undefined;
let copiedTopObject: SectionInterface| undefined = undefined;

export function GetCopiedObject() {

    let temp = localStorage.getItem("cmsCopiedObject");
    if(temp != null) {
        copiedObject = JSON.parse(temp);
        return copiedObject;
    } else {
        return undefined;
    }

}

export function SetCopiedObject(object: SectionInterface) {
    copiedObject = object
}

export function SetTopCopiedObject(object: SectionInterface) {
    copiedTopObject = object
}

export function SaveCopiedObject() {
    if(copiedObject !== undefined) {
        localStorage.setItem("cmsCopiedObject",JSON.stringify(copiedObject));
    }
}

export function SaveTopCopiedObject() {
    if(copiedTopObject !== undefined) {
        localStorage.setItem("cmsTopCopiedObject",JSON.stringify(copiedTopObject));
    }
}

export function GetTopCopiedObject() {

    let temp = localStorage.getItem("cmsTopCopiedObject");
    if(temp != null) {
        copiedTopObject = JSON.parse(temp);
        return copiedTopObject;
    } else {
        return undefined
    }

}

export default function LiveEditor(props: LiveEditorProps) {
    const organization = useOrganization();
    const [, setSectionDescriptions] = useState<SectionDesc[]>([]);
    const [, setWidgetDescriptions] = useState<WidgetDesc[]>([]);
    const [refreshId, setRefreshId] = useState(nanoid());
    const dispatch = useAppDispatch();
    const [orgCode, setOrgCode] = useState<string>("");
    const [siteCode, setSiteCode] = useState<string>("");
    const [showDrawer, setShowDrawer] = useState<boolean>(false);
    const [themeId, setThemeId] = useState<string>("");
    const [storefrontId, setStorefrontId] = useState<string>("");
    const [viewId, setViewId] = useState<string>("");
    const [viewType, setViewType] = useState<string>("");
    const [themeInfo,setThemeInfo] = useState<{themeData:any, themeProps:any}>({themeData: {}, themeProps: {}});
    const [sharedObjects, setSharedObjects] = useState<SharedObjects>({drillDownOptions: []});
    const [viewData, setViewData] = useState<any>([]);
    const [isLoaded, setLoaded] = useState<boolean>(false);
    const params = useParams<{type:"edit"|"preview", orgCode: string, viewType: string, viewId: string, sfId: string}>();
    const [edit,setEdit]= useState(false);
    const windowSize = useWindowSize();

    useEffect(() => {
        if(organization && organization.orgDetail != null) {
            setOrgCode(organization.orgDetail.orgCode)
            setSiteCode(organization.orgDetail.siteCode);
        }
    },[organization])

    useEffect(() => {

        let viewType = params.viewType;
        let sfId = params.sfId;
        let viewId = params.viewId;

        setStorefrontId(sfId? sfId : "");
        setViewType(viewType? viewType : "");
        setViewId(viewId? viewId : "");
    },[params]);

    useEffect(() => {
        if(orgCode !== "" && storefrontId !== "") {
            HttpClient.get(`/api/v0/jcr/orgs/${orgCode}/storefronts/${storefrontId}`).then(response => {
                let storefrontCmd = response.data.response as StorefrontCmd;
                setThemeId(storefrontCmd.sfThemeId);
            });
        } else {
            setThemeId("");
        }
    },[orgCode,storefrontId])


    const loadData = (data:any) => {
        setViewData(data);
        let viewObjects;
        if (viewType === "view") {
            if (data.viewData === null)
                viewObjects = [];
            else
                viewObjects = JSON.parse(data.viewData);
        } else if (viewType === "header") {
            if (data.headerData === null)
                viewObjects = [];
            else
                viewObjects = JSON.parse(data.headerData);
        } else if (viewType === "footer") {
            if (data.footerData === null)
                viewObjects = [];
            else
                viewObjects = JSON.parse(data.footerData);
        }

        clonedViewObjects = _.cloneDeep(viewObjects);
        dispatch(viewUpdateDataAction({viewData: viewObjects}));
        setSectionDescriptions(sectionDescriptionsDataFetcher());
        setWidgetDescriptions(widgetDescriptionsDataFetcher());
        setRefreshId(nanoid());
        setLoaded(true);
        window.parent?.postMessage({
            action: "LOADED",
            value: true
        }, "*")
    }

    useEffect( () => {
        if(orgCode !== "" && viewType !== "" && viewId !== "") {
            if(viewId === "internal" || viewId === "editor") {
                if(params.type === "preview") { // should not be able to edit internal data
                    let data = GetLocalItem("previewData", "");
                    if (data !== "") {
                        loadData(JSON.parse(data));
                    }
                }
            } else {
                HttpClient.get(`/api/v0/jcr/orgs/${orgCode}/${viewType}s/${viewId}`).then(response => {
                    let data = response.data.response;
                    loadData(data);
                })
            }
        }
    }, [orgCode, viewType, viewId])

    useEffect(() => {
        if(orgCode !== "" && themeId !== "") {

            HttpClient.get(`/api/v0/jcr/org/${orgCode}/theme/${themeId}`).then((response) => {
                if(response.data.response !== null) {
                    //setThemeProps(JSON.parse(response.data.response.themeData));
                    currentThemeProp = null;
                    window.parent.postMessage({
                        action: "CHANGE_THEME_DATA",
                        value: {
                            currentThemeProp,
                            themeProps: JSON.parse(response.data.response.themeData)
                        }
                    },"*")
                    let loadedThemeInfo = {
                        themeData: response.data.response,
                        themeProps: JSON.parse(response.data.response.themeData)
                    };
                    setThemeInfo(loadedThemeInfo)
                    updateCSSFiles(loadedThemeInfo.themeData.themeName);
                    // setTimeout(() => {
                    //
                    // },100)

                }
            }).catch((e) => {
                currentThemeProp = null;
                window.parent.postMessage({
                    action: "CHANGE_THEME_DATA",
                    value: {
                        currentThemeProp,
                        themeProps: {}
                    }
                },"*")
                setThemeInfo({themeData: {}, themeProps: {}});

                //setThemeProps({});
            })
        }
    },[orgCode,themeId])

    // const [drawerState] = useReducer(reducer,{
    //     drawerId: nanoid(),
    //     drawerType: 0,
    //     themeName: "",
    //     settingObjects: {
    //         level: "",
    //         title: "",
    //         suggestTags: [],
    //         groups: [],
    //         options: [],
    //         props: {},
    //         onChange: null,
    //         saveState: () => {},
    //     },
    //     newType: {type: NewType.widget_or_section, uiLevelStr: ""}
    // })
    const [currentViews,setCurrentViews] = useState<{
        views: JSX.Element[]
        viewId: string
    }>({ views: []  , viewId: nanoid() });

    const onInsertWidget = (uiLevelStr: string, desc: WidgetDesc) => {

        SetOverLevel("");
        SetFocusLevelStr("")

        // onInsertSection R_0_0 {"sectionType":"section","sectionName":"Vertical","sectionNickName":"Rows","sectionDescription":"Row Arrangement","sectionIcon":"table_rows","sectionGroupType":0}
        let addColumn = uiLevelStr.indexOf("@");
        if(addColumn) { // change back to position
            uiLevelStr = uiLevelStr.replace('@',"_");
        }
        let [,fParentViewObject,fIndex] =  findViewObjectByLevelString(clonedViewObjects,uiLevelStr);
        if(fParentViewObject !== null) {
            addUndoStack(fParentViewObject.sectionId, null);
            let index = 0;
            if (fIndex !== null) {
                index = fIndex;
            }

            if(fParentViewObject.sectionName === "DefaultSection" && fParentViewObject.sectionWidgets[index] !== null && fParentViewObject.sectionWidgets[index] !== undefined) {
                if(!("sectionId" in fParentViewObject.sectionWidgets[index])) { // already have vertical widget
                    let prevObject = fParentViewObject.sectionWidgets[index];
                    fParentViewObject.sectionWidgets[index] = {
                        sectionId: "section_" + nanoid(),
                        sectionType: "section",
                        sectionName: "Vertical",
                        sectionProps: {},
                        sectionWidgets: [prevObject]
                    }
                }
                if(desc.clonedObject) {
                    (fParentViewObject.sectionWidgets[index] as SectionInterface).sectionWidgets[(fParentViewObject.sectionWidgets[index] as SectionInterface).sectionWidgets.length] = doAssignNewIds(_.cloneDeep(desc.clonedObject));
                } else {
                    (fParentViewObject.sectionWidgets[index] as SectionInterface).sectionWidgets[(fParentViewObject.sectionWidgets[index] as SectionInterface).sectionWidgets.length] = {
                        widgetId: "widget_" + nanoid(),
                        widgetType: desc.widgetType,
                        widgetName: desc.widgetName,
                        widgetQuery: null,
                        widgetProps: getInitWidgetProps(desc.widgetName)
                    }
                }
            } else {
                if (desc.clonedObject) {
                    fParentViewObject.sectionWidgets[index] = doAssignNewIds(_.cloneDeep(desc.clonedObject));
                } else {
                    fParentViewObject.sectionWidgets[index] = {
                        widgetId: "widget_" + nanoid(),
                        widgetType: desc.widgetType,
                        widgetName: desc.widgetName,
                        widgetQuery: null,
                        widgetProps: getInitWidgetProps(desc.widgetName)
                    }
                }
            }
            saveStateAndRefresh();
            // setRefreshId(nanoid());
            hideDrawer();

            setTimeout(() => {
                let element:any = document.querySelector("div[data-drag-level-str='" + uiLevelStr+ "']");
                if(element !== null) {
                    element.focus();
                }
            },300);
        }
    }

    const onInsertSection = (uiLevelStr: string, desc: SectionDesc) => {
        SetOverLevel("");
        SetFocusLevelStr("")
        let [,fParentViewObject,fIndex] =  findViewObjectByLevelString(clonedViewObjects,uiLevelStr);
        // console.log("onInsertSection",uiLevelStr,JSON.stringify(desc));
        if(fParentViewObject === null) { // add directly to clonedViewObject
            addUndoStack("",null)
            let index = 0;
            if(fIndex !== null) {
                index = fIndex;
            }
            if(desc.clonedObject) {
                if (Array.isArray(desc.clonedObject)) {
                    let interfaces = desc.clonedObject as SectionInterface[];
                    for(let i = 0;i<interfaces.length;i++) {
                        clonedViewObjects[index+i] = doAssignNewIds(_.cloneDeep(interfaces[i])) as SectionInterface;
                    }
                } else {
                    clonedViewObjects[index] = doAssignNewIds(_.cloneDeep(desc.clonedObject)) as SectionInterface;
                }
            } else {
                clonedViewObjects[index] = {
                    sectionId: "section_" + nanoid(),
                    sectionType: desc.sectionType,
                    sectionName: desc.sectionName,
                    sectionProps: desc.sectionInitProps ? desc.sectionInitProps : {},
                    sectionWidgets: []
                }
            }
        } else {
            addUndoStack(fParentViewObject.sectionId,null)
            let index = 0;
            if(fIndex !== null) {
                index = fIndex;
            }
            if(desc.clonedObject) {
                fParentViewObject.sectionWidgets[index] = doAssignNewIds(_.cloneDeep(desc.clonedObject));
            } else {
                fParentViewObject.sectionWidgets[index] = {
                    sectionId: "section_" + nanoid(),
                    sectionType: desc.sectionType,
                    sectionName: desc.sectionName,
                    sectionProps: desc.sectionInitProps ? desc.sectionInitProps : {},
                    sectionWidgets: []
                }
            }
        }

        saveStateAndRefresh();
        hideDrawer();
        //drawerDispatch({type: "reset",data: null});
        // drawerDispatch({type : 'newDrawer' , data: {
        //         type: drawerState.newType.type,
        //         uiLevelStr: increaseLevel(drawerState.newType.uiLevelStr),
        //     }})
        // setRefreshId(nanoid());


        setTimeout(() => {
            let element:any = document.querySelector("div[data-drag-level-str='" + uiLevelStr+ "']");
            if(element !== null) {
                element.focus();
            }
        },300);

    }
    const hideDrawer = () => {
        window.parent.postMessage({
            action: "HIDE_DRAWER",
            value: true
        },"*")
    }

    const {fireNewFocus} = useFocusInEditable((callback) => {

    });

    const doChangeTheme = (value: { drawerType: number, themeName: any , data: string }) => {
        if(currentThemeProp === null) {
            currentThemeProp = {...themeInfo.themeProps};
        }

        let data = value.data;
        let themeName = value.themeName;
        currentThemeProp[themeName] = data;
        LoadCMSTheme(currentThemeProp);
        updateCSSFiles(themeInfo.themeData.themeName);
        window.parent.postMessage({
            action: "CHANGE_THEME_DATA",
            value: {
                currentThemeProp,
                themeData: themeInfo && themeInfo.themeData ? themeInfo.themeData : {}
            }
        },"*")
    }

    const updateCSSFiles = (themeName: string) => {

        let portalEnvironment = GetPortalEnvironment();
        if(portalEnvironment === null || portalEnvironment === undefined) {
            portalEnvironment = "prod"
        }
        // console.log("updateCSSFiles",siteCode,themeInfo,portalEnvironment);
        let mainLink = document.getElementById("bt_main_css");
        let responsiveLink = document.getElementById("bt_responsive_css");;
        let head = document.getElementsByTagName("head").item(0)!;
        if(responsiveLink === null) {
            responsiveLink = document.createElement("link");
            responsiveLink.id = "bt_responsive_css"
            head.insertBefore(responsiveLink, head.lastChild);
        }

        responsiveLink.setAttribute("rel","stylesheet");
        responsiveLink.setAttribute("type","text/css");

        let siteCode = organization?.orgDetail?.siteCode;
        if(siteCode === null || siteCode === undefined) {
            siteCode = "";
        }

        // console.log("LOAD siteCode,hostName,themeName => ",siteCode,GetPortalHostName(siteCode),themeName);
        responsiveLink.setAttribute("href",`https://${GetPortalHostName(siteCode)}/themes/${themeName}/css/main.css?name=${nanoid()}`);

        if(mainLink === null) {
            mainLink = document.createElement("link");
            mainLink.id = "bt_main_css"
            head.insertBefore(mainLink, head.lastChild);
        }

        mainLink.setAttribute("rel","stylesheet");
        mainLink.setAttribute("type","text/css");
        mainLink.setAttribute("href",`https://${GetPortalHostName(siteCode)}/themes/${themeName}/css/responsive.css?name=${nanoid()}`);

    };

    // const increaseLevel = (level:string) => {
    //     let lastIndex = level.lastIndexOf("_");
    //     let prefix = level.substring(0,lastIndex);
    //     let currentLabel = level.substring(lastIndex+1);
    //     let nextLevel = parseInt(currentLabel)+1;
    //     return prefix + "_" + nextLevel;
    // }

    function saveState() {
        dispatch(viewUpdateDataAction({viewData: _.cloneDeep(clonedViewObjects)}));
    }

    function saveStateAndRefresh() {
        dispatch(viewUpdateDataAction({viewData: _.cloneDeep(clonedViewObjects)}));
        setRefreshId(nanoid());
    }

    const onHandleAddNewSectionOrWidget = (level: string, setting: NewOption) => {
        // drawerDispatch({
        //     type : 'newDrawer' , data: {
        //         type: setting.type,
        //         uiLevelStr: level
        //     }})
        window.parent.postMessage({
            action: "NEW_SECTION_OR_WIDGET",
            value: {
                type: setting.type,
                level: level
            }
        },"*")
        // if(level.split("_").length === 2) {
        //     setTimeout(() => {
        //         let element:any = document.querySelector("div[data-drag-level-str='" + level+ "']");
        //         element?.scrollIntoView({behavior: 'smooth', block: 'center'});
        //     },500);
        // }
    }
    const onOpenTemplate = (event: React.MouseEvent<HTMLSpanElement>) => {

        window.parent?.postMessage({
            action: "OPEN_TEMPLATE",
            value: true
        },"*")

        event.stopPropagation();
        event.preventDefault()
    }

    const onHandleSetting =( themeName:string,
                             title: string,
                             level: string,
                             suggestTags: string[],
                             groups: SettingGroup[],
                             settings:SettingOption[],
                             widgetId: string,
                             widgetData: any,
                             settingProps: any,
                             onChange: SettingChangeFunction|null,
                             // saveState: () => void,
                             required: boolean,
                             portalThemeName: string,
                             siteCode: string) => {
        if(required || (!required && document.getElementById("view_drawer") !== null)) {
            // console.log(settings);
            let levelElement = document.querySelector("div[data-drag-level-str='" + level+ "']");
            let x = 0;
            let y = 0;
            if(levelElement) {
                let rect = levelElement.getBoundingClientRect();
                x = rect.x;
                y = rect.y;
            }

            window.parent.postMessage({
                action: "SECTION_OR_WIDGET_SETTING",
                value: {
                    type: "settingDrawer",
                    themeName: themeName, // this is widget name
                    data: {
                        title: title,
                        level: level,
                        suggestTags: suggestTags,
                        groups: groups,
                        options: settings,
                        portalThemeName,
                        siteCode,
                        id: widgetId,
                        data: widgetData,
                        props: settingProps,
                        x: x,
                        y: y,
                    }
                }
            },"*")
        }
    }




    // allow to clone only top sections
    const onHandleClone = (level:string) => {
        SetOverLevel("");
        SetFocusLevelStr("")
        let [, parentViewObject, viewIndex] = findViewObjectByLevelString(clonedViewObjects,level);
        // expected parentViewObject to be null because we allow to clone only top level
        if(parentViewObject === null && viewIndex !== null && viewIndex !== undefined) { // clone at the root
            copiedTopObject = _.cloneDeep(clonedViewObjects[viewIndex]);
            SaveTopCopiedObject();

            doAssignNewIds(copiedTopObject)
            addUndoStack("",null);
            clonedViewObjects.splice(viewIndex,0,copiedTopObject);
            saveStateAndRefresh();
        } else if(parentViewObject !== null && viewIndex !== null && viewIndex !== undefined) { // clone with parent
            copiedObject = _.cloneDeep(parentViewObject.sectionWidgets[viewIndex]);
            SaveTopCopiedObject();
            doAssignNewIds(copiedObject)
            addUndoStack("",null);
            if(parentViewObject.sectionName === "DefaultSection") { // clone in defaultsection
                if('widgetId' in parentViewObject.sectionWidgets[viewIndex]) {
                    if (!("sectionId" in parentViewObject.sectionWidgets[viewIndex])) { // already have vertical widget
                        let prevObject = parentViewObject.sectionWidgets[viewIndex];
                        parentViewObject.sectionWidgets[viewIndex] = {
                            sectionId: "section_" + nanoid(),
                            sectionType: "section",
                            sectionName: "Vertical",
                            sectionProps: {},
                            sectionWidgets: [prevObject]
                        }
                    }

                    (parentViewObject.sectionWidgets[viewIndex] as SectionInterface).sectionWidgets[(parentViewObject.sectionWidgets[viewIndex] as SectionInterface).sectionWidgets.length] = copiedObject;
                } else {
                    if((viewIndex+1) < DEFAULTSECTION_MAX_COLUMNS && !parentViewObject.sectionWidgets[viewIndex+1]) { // still have room left
                        parentViewObject.sectionWidgets[viewIndex+1] = copiedObject;

                        if(viewIndex+1 >= parentViewObject.sectionProps['content']['columns']) {
                            parentViewObject.sectionProps['content']['columns']++;
                            if(parentViewObject.sectionProps['content']['layout'] === "horizontal") {
                                let percent = Math.floor(100/parentViewObject.sectionProps['content']['columns']);
                                for(let i=0;i<parentViewObject.sectionProps['content']['columns'];i++) {
                                    parentViewObject.sectionProps['content']['column' + (i+1) + "-width"] =  `${percent}%`;
                                }
                            } else {
                                parentViewObject.sectionProps['content'][`column${viewIndex+1}-width`] = parentViewObject.sectionProps['content'][`column${viewIndex}-width`];
                            }
                        }
                    } else if(parentViewObject.sectionProps['content']['columns']  < DEFAULTSECTION_MAX_COLUMNS) {  // always insert
                        parentViewObject.sectionWidgets.splice(viewIndex, 0, copiedObject);
                        parentViewObject.sectionProps['content']['columns']++;
                        if(parentViewObject.sectionProps['content']['layout'] === "horizontal") {
                            let percent = Math.floor(100/parentViewObject.sectionProps['content']['columns']);
                            for(let i=0;i<DEFAULTSECTION_MAX_COLUMNS;i++) {
                                parentViewObject.sectionProps['content']['column' + (i+1) + "-width"] =  `${percent}%`;
                            }
                        } else {
                            parentViewObject.sectionProps['content'][`column${viewIndex+1}-width`] = parentViewObject.sectionProps['content'][`column${viewIndex}-width`];
                        }
                    } else { // find empty space and remove it and insert at the beginning
                        if(parentViewObject.sectionWidgets.length < DEFAULTSECTION_MAX_COLUMNS) { // still can add new object
                            for(let i=parentViewObject.sectionWidgets.length;i<DEFAULTSECTION_MAX_COLUMNS;i++) {
                                (parentViewObject.sectionWidgets[i] as any) = null;
                            }
                        }

                        for (let i = 0; i < parentViewObject.sectionWidgets.length; i++) {
                            if (parentViewObject.sectionWidgets[i] === null || parentViewObject.sectionWidgets[i] === undefined) {
                                parentViewObject.sectionWidgets.splice(i, 1);
                                parentViewObject.sectionWidgets.splice(viewIndex, 0, copiedObject);
                                break;
                            }
                        }
                    }
                }
            } else if(parentViewObject.sectionName === "Vertical") {
                parentViewObject.sectionWidgets.splice(viewIndex,0,copiedObject);
            } else {
                console.log("DO NOT SUPPORT");
            }
            saveStateAndRefresh();
        }
    }

    const onHandleCopy = (level:string) => {

        let [, parentViewObject, viewIndex] = findViewObjectByLevelString(clonedViewObjects,level);

        // copy code from onHandleDelete
        if(parentViewObject !== null && viewIndex !== null && viewIndex !== undefined) {
            copiedObject = _.cloneDeep(parentViewObject.sectionWidgets[viewIndex!]);
            SaveCopiedObject();
            if('sectionId' in copiedObject) {
                copiedTopObject = copiedObject;
                SaveTopCopiedObject();
            }
        } else {
            copiedTopObject = _.cloneDeep(clonedViewObjects[viewIndex!]);
            SaveTopCopiedObject();
        }
    }


    const onHandleCut = (level:string) => {
        let [, parentViewObject, viewIndex] = findViewObjectByLevelString(clonedViewObjects,level);
        // copy code from onHandleDelete
        if(parentViewObject !== null && viewIndex !== null && viewIndex !== undefined) {

            copiedObject = _.cloneDeep(parentViewObject.sectionWidgets[viewIndex!]);
            SaveCopiedObject();
            addUndoStack(parentViewObject.sectionId,null);
            if(parentViewObject.sectionName === "DefaultSection") {
                if(parentViewObject.sectionWidgets.length === 1) {
                    parentViewObject.sectionWidgets = []
                } else {
                    delete parentViewObject.sectionWidgets[viewIndex!];
                }
            } else {
                parentViewObject.sectionWidgets.splice(viewIndex!, 1);
            }
            saveStateAndRefresh();
        }
    }

    const onHandleDeletion = (level: string, setting: DeleteOption) => {
        SetOverLevel("");
        SetFocusLevelStr("")
        let [, parentViewObject, viewIndex] = findViewObjectByLevelString(clonedViewObjects,level);

        if(setting.type === DeleteType.delete_with_rearrange) {
            // when change on this, please fix onHandleCut also
            if(parentViewObject !== null) {
                addUndoStack(parentViewObject.sectionId,null);
                if(parentViewObject.sectionName === "DefaultSection") {
                    if(parentViewObject.sectionWidgets.length === 1) {
                        parentViewObject.sectionWidgets = []
                    } else {
                        if('sectionId' in parentViewObject.sectionWidgets[viewIndex!]) { // delete sub panel

                            parentViewObject.sectionWidgets.splice(viewIndex!,1);
                            parentViewObject.sectionProps['content']['columns']--;
                            let vertical: boolean = parentViewObject.sectionProps['content']['layout'] === "vertical";
                            let value = parentViewObject.sectionProps['content']['columns'];
                            if (vertical) {
                                parentViewObject.sectionProps["content"][`column1-width`] = "100%";
                                parentViewObject.sectionProps["content"][`column2-width`] = "100%";
                                parentViewObject.sectionProps["content"][`column3-width`] = "100%";
                                parentViewObject.sectionProps["content"][`column4-width`] = "100%";
                                parentViewObject.sectionProps["content"][`column5-width`] = "100%";
                            } else {
                                if (value === 1) {
                                    parentViewObject.sectionProps["content"][`column1-width`] = "100%";
                                } else if (value === 2) {
                                    parentViewObject.sectionProps["content"][`column2-width`] = "50%";
                                    parentViewObject.sectionProps["content"][`column1-width`] = "50%";
                                } else if (value === 3) {
                                    parentViewObject.sectionProps["content"][`column3-width`] = "33%";
                                    parentViewObject.sectionProps["content"][`column1-width`] = "33%";
                                    parentViewObject.sectionProps["content"][`column2-width`] = "33%";
                                } else if (value === 4) {
                                    parentViewObject.sectionProps["content"][`column4-width`] = "25%";
                                    parentViewObject.sectionProps["content"][`column1-width`] = "25%";
                                    parentViewObject.sectionProps["content"][`column2-width`] = "25%";
                                    parentViewObject.sectionProps["content"][`column3-width`] = "25%";
                                } else if (value === 5) {
                                    parentViewObject.sectionProps["content"][`column5-width`] = "20%";
                                    parentViewObject.sectionProps["content"][`column1-width`] = "20%";
                                    parentViewObject.sectionProps["content"][`column2-width`] = "20%";
                                    parentViewObject.sectionProps["content"][`column3-width`] = "20%";
                                    parentViewObject.sectionProps["content"][`column4-width`] = "20%";
                                }
                            }
                        } else { // delete widget
                            delete parentViewObject.sectionWidgets[viewIndex!];
                        }

                    }
                } else {
                    if(parentViewObject.sectionWidgets.length === 2) {
                        let parentLabel = level.substring(0,level.lastIndexOf("_"));
                        let [,anotherParentViewObject, anotherViewIndex] = findViewObjectByLevelString(clonedViewObjects,parentLabel);
                        parentViewObject.sectionWidgets.splice(viewIndex!, 1);
                        if(anotherParentViewObject != null && anotherParentViewObject.sectionName === "DefaultSection" && anotherViewIndex !== null) {
                            anotherParentViewObject.sectionWidgets[anotherViewIndex] = parentViewObject.sectionWidgets[0];
                        }
                    } else {
                        parentViewObject.sectionWidgets.splice(viewIndex!, 1);
                    }
                }
            } else {
                addUndoStack("",null);
                clonedViewObjects.splice(viewIndex!,1);
            }
        } else if(setting.type === DeleteType.delete_in_place) {
            // This might be obsolete in the future because delete_with_rearrang is the default behavior just only DefaultSection have in-place deletion

        }
        saveStateAndRefresh();

    }

    const onHandleSwap = (fromLevel: string, toLevel: string, setting: SwapOption) => {
        let [fViewObject,fParentViewObject,fIndex] = findViewObjectByLevelString(clonedViewObjects,fromLevel);
        let [tViewObject,tParentViewObject,tIndex] = findViewObjectByLevelString(clonedViewObjects,toLevel);

        if(fViewObject !== null && tViewObject !== null) {
            if (setting.type === SwapType.widget_to_widget_moved) {
                addUndoStack( fParentViewObject!.sectionId,tParentViewObject!.sectionId);
                fParentViewObject!.sectionWidgets[fIndex!] = tViewObject!;
                tParentViewObject!.sectionWidgets[tIndex!] = fViewObject!;
                saveStateAndRefresh();
            } else if (setting.type === SwapType.section_to_section ||
                setting.type === SwapType.section_to_widget ||
                setting.type === SwapType.widget_to_section ||
                setting.type === SwapType.widget_to_widget_rearrange) { // always in the same section
                if(fParentViewObject != null) {
                    addUndoStack( fParentViewObject!.sectionId,null);
                }
                let sectionWidgets: (SectionInterface|WidgetInterface)[];
                if(fParentViewObject !== null) { // swap at some parent node
                    sectionWidgets = fParentViewObject!.sectionWidgets;
                } else { // swap at the root
                    sectionWidgets = clonedViewObjects;
                }
                sectionWidgets.splice(fIndex!,1);
                sectionWidgets.splice(tIndex!,0,fViewObject);
                saveStateAndRefresh();
            }
        } else if(fViewObject !== null && tViewObject === null && tParentViewObject !== null) {
            if(setting.type === SwapType.widget_to_empty) {
                addUndoStack(fParentViewObject!.sectionId,tParentViewObject!.sectionId);
                tParentViewObject!.sectionWidgets.push(fViewObject);
                fParentViewObject!.sectionWidgets.splice(tIndex!,1);
                saveStateAndRefresh();
            } else if(setting.type === SwapType.section_to_empty) {
                addUndoStack(fParentViewObject!.sectionId,tParentViewObject!.sectionId);
                tParentViewObject!.sectionWidgets.push(fViewObject);
                if(fParentViewObject === null) {
                    clonedViewObjects.splice(tIndex!,1);
                    saveStateAndRefresh();
                } else {
                    fParentViewObject!.sectionWidgets.splice(tIndex!,1);
                    saveStateAndRefresh();
                }
            }
        }
    }

    const onHandleCreateTemplate = (templateData: string) => {
        window.parent?.postMessage({
            action: "CREATE_TEMPLATE",
            value: templateData
        },"*")
    }

    const onHandleUpdate = (widgetId: string) => {
        addUndoStack( widgetId,null);
        saveState();
    }

    useEffect(() => {
        StyleUtil.resetCMSClasses();
        setCurrentViews({views: doViewGenerator(
                clonedViewObjects,
                0,
                "R",
                sharedObjects,
                onHandleSetting,
                onHandleDeletion,
                onHandleAddNewSectionOrWidget,
                onHandleSwap,
                onHandleUpdate,
                onHandleClone,
                onHandleCopy,
                onHandleCut,
                onHandleCreateTemplate,
                updateWidgetData,
                onInsertSection,
                onInsertWidget),
            viewId: nanoid()});
    },[refreshId]);

    const doPostUndoRedoState = () => {
        window.parent?.postMessage({
            action: "UNDO_REDO_STATE",
            value: {redoLength: redoStack.length, undoLength: undoStack.length }
        },"*")
    }

    const resetUndoStack = () => {
        undoStack = [];
        redoStack = []
        doPostUndoRedoState();
    }

    /**
     * Add to undo stack before update clonedViewObjects
     */
    const addUndoStack = (updateId:string,updateId2: string | null) => {
        if(undoStack.length > 0) {
            let lastUpdate = undoStack[undoStack.length-1];
            if(lastUpdate.updateId.startsWith("widget") && lastUpdate.updateId === updateId) {
                return;
            }
        }

        undoStack.push({
            data: _.cloneDeep(clonedViewObjects),
            updateId,
            updateId2
        });
        redoStack.splice(0,redoStack.length);
        doPostUndoRedoState();
    }

    function doRestore(prevState: UndoCmd | undefined) {
        if(prevState) {
            clonedViewObjects = _.cloneDeep(prevState.data); //restore to previous state
            doPostUndoRedoState()
            hideDrawer();
            saveStateAndRefresh();

            if (prevState.updateId) {
                let parentId = findParentIdFromChildId(clonedViewObjects, prevState.updateId);
                if (parentId !== "") {
                    let topParentId = findParentIdFromChildId(clonedViewObjects, parentId);
                    if(topParentId !== "") {
                        SubmitWidgetId(topParentId);
                    } else {
                        SubmitWidgetId(parentId);
                    }
                } else {
                    SubmitWidgetId(prevState.updateId);
                }
            }
            if (prevState.updateId2) {
                let parentId = findParentIdFromChildId(clonedViewObjects, prevState.updateId2);
                if (parentId !== "") {
                    let topParentId = findParentIdFromChildId(clonedViewObjects, parentId);
                    if(topParentId !== "") {
                        SubmitWidgetId(topParentId);
                    } else {
                        SubmitWidgetId(parentId);
                    }
                } else {
                    SubmitWidgetId(prevState.updateId2);
                }
            }


        }
    }

    const doUndo = () => {
        if(undoStack.length > 0) {

            let prevState = undoStack.pop();
            if(prevState) {
                redoStack.push({
                    data: _.cloneDeep(clonedViewObjects),
                    updateId: prevState.updateId,
                    updateId2: prevState.updateId
                }); // keep current state in redo stack
            }
            doRestore(prevState);
            window.parent?.postMessage({
                action: "UNDO_SIZE_UPDATE",
                value: undoStack.length
            }, "*")
        }
    }

    const doRedo = () => {
        if(redoStack.length > 0) {

            let prevState = redoStack.pop();
            if(prevState) {
                undoStack.push({
                    data: _.cloneDeep(clonedViewObjects),
                    updateId: prevState.updateId,
                    updateId2: prevState.updateId2
                });// keep current state in redo stack
            }
            doRestore(prevState);
            window.parent?.postMessage({
                action: "UNDO_SIZE_UPDATE",
                value: undoStack.length
            }, "*")
        }
    }

    const updateWidgetData = (value: {widgetId: string, widgetData: any , action: string|null, value: any}) => {
        let view = findViewObjectById(clonedViewObjects, value.widgetId);
        if (view !== null) {
            addUndoStack(value.widgetId,null);
            if ('sectionType' in view) {
                let section = view as SectionInterface;
                section.sectionProps = value.widgetData.sectionProps
                section.sectionWidgets = value.widgetData.sectionWidgets
                //section.sectionProps = value.widget.sectionProps;
            } else {
                let widget = view as WidgetInterface;
                widget.widgetProps = value.widgetData.widgetProps;
            }
            SubmitWidgetState(value.widgetId, value.widgetData, value.action, value.value);
        }
    }



    useEffect(() => {
        const listener = (event:MessageEvent<{action: string, value: any}>) => {
            if(event.isTrusted) {
                let payload = event.data;
                let {action,value} = payload;
                if(action === "DRAWER_STATE") {
                    document.getElementById("top_editor")?.setAttribute("data-showdrawer",value)
                    setShowDrawer(value);
                } else if(action === "PREVIEW") {

                    let previewData: any = {
                        id: "previewData",
                        viewName: params.viewId,
                        viewData: JSON.stringify(clonedViewObjects)
                    }

                    SetLocalItem("previewData", JSON.stringify(previewData));
                    window.open(`/live/preview/orgName/view/editor/${storefrontId}`, '_blank', 'noreferrer');

                } else if(action === "WRAPPER_STATE") {
                    let topEditor = document.getElementById("top_editor");
                    if(value) {
                        topEditor?.classList.remove("wrap")
                    } else {
                        topEditor?.classList.add("wrap")
                    }
                } else if(action === "START_SAVE_TEMPLATE") {
                    let topEditor = document.getElementById("top_editor");
                    CaptureLayout(topEditor, (capture) => {
                        window.parent?.postMessage({
                            action: "CREATE_TEMPLATE",
                            value: JSON.stringify({
                                data: JSON.stringify(clonedViewObjects),
                                capture: capture
                            })    },"*")
                    })


                } else if(action === "ADD_TEMPLATE") {
                    if(value !== "") {
                        try {
                            let uiLabelStr = "R_" + clonedViewObjects.length;
                            let desc: SectionDesc = {
                                sectionType: "",
                                sectionName: "",
                                sectionNickName: "",
                                sectionDescription: "",
                                sectionIcon: "",
                                sectionGroupType: SectionGroupType.unspecfic,
                                clonedObject: JSON.parse(value) // wh
                            }
                            onInsertSection(uiLabelStr, desc)
                        } catch {

                        }
                    }
                } else if(action === "EDIT_MODE") {
                    currentThemeProp = null;
                    dispatch(viewEditAction({isEdit: payload.value}));
                    ResetEditablePanel();
                    // window.scrollTo({top:0, behavior: 'smooth'});
                    setEdit(payload.value);
                } else if(action === "CHANGE_STOREFRONT") {
                    currentThemeProp = null;
                    setStorefrontId(payload.value);
                } else if(action === "SAVE") {
                    doSave();
                    resetUndoStack();
                } else if(action === "SAVE_STATE") {
                    saveState();
                } else if(action === "INSERT_WIDGET") {
                    onInsertWidget(value.uiLevelStr, value.desc);
                } else if(action === "INSERT_SECTION") {
                    onInsertSection(value.uiLevelStr, value.desc);
                } else if(action === "CHANGE_ENVIRONMENT") {
                    ReloadPortalEnvironment();
                    updateCSSFiles(themeInfo.themeData.themeName)
                    setRefreshId(nanoid());
                } else if(action === "CHANGE_THEME") {
                    doChangeTheme(value);
                } else if(action === "UPDATE_WIDGET") {
                    updateWidgetData(value);
                } else if(action === "UNDO") {
                    doUndo();
                } else if(action === "REDO") {
                    doRedo();
                }
            }
        }
        window.addEventListener("message",listener);

        return () => {
            window.removeEventListener("message",listener);
        }

    },[viewData,viewType,orgCode,themeInfo]);


    useEffect(() => {
        //if(currentOrgCode === "") {
        if(organization && organization.currentOrg!) {

            HttpClient.get(`/api/v0/jcr/org/${organization?.currentOrg!.orgCode}/lite/drilldowns`).then((response:any) => {
                let newDrilldownOptions: InputOption[] = [];
                response.data.response.forEach((item:any) => {
                    newDrilldownOptions.push({label: item.drillName , value : item.id, object: item})
                })
                setSharedObjects({
                    drillDownOptions: newDrilldownOptions
                })
                setSectionDescriptions(sectionDescriptionsDataFetcher());
                setWidgetDescriptions(widgetDescriptionsDataFetcher());
                setRefreshId(nanoid());
            })
        }

    },[organization?.orgDetail])

    const doSave = useCallback(() => {
        saveState();
        if (currentThemeProp !== null) {
            HttpClient.get(`/api/v0/jcr/org/${orgCode}/theme/${themeId}`).then((response) => {
                if(response.data.response !== null) {
                    let newThemeData = {...response.data.response};
                    newThemeData.themeData = JSON.stringify(currentThemeProp);
                    jcr.theme.update(newThemeData).then((response) => {
                        let newThemeInfo = {...themeInfo};
                        newThemeInfo.themeProps = currentThemeProp;
                        setThemeInfo(newThemeInfo);
                        saveData(clonedViewObjects);
                    })
                }
            }).catch((e) => {

            })

        } else {
            saveData(clonedViewObjects);
        }
    },[viewType,orgCode,viewData]);

    const saveData = useCallback((data : SectionInterface[]) => {
        if(orgCode === "") return;
        let viewContent = JSON.stringify(data);

        if(viewType === "view") {
            viewData.viewData = viewContent;
        } else if(viewType === "header") {
            viewData.headerData = viewContent;
        } else if(viewType === "footer") {
            viewData.footerData = viewContent;
        }

        // save data
        HttpClient.post(`/api/v0/jcr/org/${orgCode}/full/${viewType}s`,viewData)
            .then(x => {
                setViewData({...viewData});
            })


        // generate thumbnails
        // @ts-ignore
        let html2canvas:any = window['html2canvas'];
        if(html2canvas) {
            let topEditor = document.getElementById("top_editor");
            if(topEditor) {
                //
                let imgs = topEditor.querySelectorAll("img");
                imgs.forEach(img => {
                    img.setAttribute("data-img-src",img.src);
                    img.src = `https://cdn.lsicloud.net/txlsi/${img.src}`;
                })
                setTimeout(() => {
                    html2canvas(topEditor, {
                        useCORS: true,
                        scrollX: 0,
                        scrollY: 0,
                        logging: false,
                        height: topEditor?.clientWidth
                    }).then((canvas: any) => {
                        let smallCanvas = document.createElement("canvas");
                        const dpr = window.devicePixelRatio
                        smallCanvas.width = 350*dpr;
                        smallCanvas.height = 350*dpr;

                        let ctx:any = smallCanvas.getContext("2d", { alpha: false , antialias : true});
                        ctx.drawImage(canvas,0,0,canvas.width,canvas.height,0,0,smallCanvas.width,smallCanvas.height);

                        let data = smallCanvas.toDataURL("image/jpg",0.8);

                        fetch(data)
                            .then(res => res.blob())
                            .then(blob => {
                                const file = new File([blob], viewData.id + ".jpg",{ type: "image/jpg" })

                                if(viewType === "view") {
                                    jcr.view.thumbnail(file).then((response) => {

                                    })
                                } else if(viewType === "header") {
                                    jcr.header.thumbnail(file).then((response) => {

                                    })
                                } else if(viewType === "footer") {
                                    jcr.footer.thumbnail(file).then((response) => {

                                    })
                                }

                            })

                        imgs.forEach(img => {
                            img.src = img.getAttribute("data-img-src")!;
                            img.removeAttribute("data-img-src");
                        })
                    });
                },100);

            }
        }

    },[viewType,orgCode,viewData]);

    // useEffect(() => {
    //   if(document.body) {
    //       document.body.style.backgroundColor = "var(--standard-smoke-500)";
    //   }
    // },[])

    let portalURL:string = (organization && organization.orgDetail) ? organization.orgDetail.portalURL : "";
    let websiteURL:string = (organization && organization.orgDetail) ? organization.orgDetail.website : "";
    let cdnURL:string = (organization && organization.orgDetail) ? organization.orgDetail.cdnURL : "";
    let _siteCode:string = (organization && organization.orgDetail) ? organization.orgDetail.siteCode : "";
    let themeName: string = themeInfo.themeData.themeName;

    return <CMSTheme key={refreshId}
                     themeProps={currentThemeProp ? currentThemeProp : themeInfo.themeProps}
                     siteCode={_siteCode}
                     portalThemeName={themeName}
                     websiteURL={websiteURL}
                     cdnURL={cdnURL}>
            <div id="top_editor" data-showdrawer={showDrawer} className={params.viewType === "internal" ? "" : StyleUtil.combineClasses(params.type === "preview" ? "" : styles.TopPanel,GetLocalItem("previewWrapper",""))}>
        {/*<Suspense key={currentViews.viewId} fallback={<div>Lo∂ading...</div>}>*/}
        {currentViews.views}
            </div>
        {edit && isLoaded && storefrontId !== "" &&
            <div  style={{height: `${windowSize.height}px`}} className={styles.NewSectionPanel}
                onMouseEnter={(event) => {
                    fireNewFocus("",FocusLevelStr());

                    event.stopPropagation()
                    event.preventDefault()
                }}>
                <NewWidget uiLevelStr={"R_" + currentViews.views.length}
                                                               uiLevel={0}
                                                               uiType={"new"}
                                                               children={null}
                                                               newOption={{
                                                                   type: NewType.section_only,
                                                                   tag: null
                                                               }}
                                                               sharedObjects={sharedObjects}
                                                               onHandleAddNewSectionOrWidget={onHandleAddNewSectionOrWidget}
                                                               data={null}
                                                               deleteOption={{type: DeleteType.nothing, tag: null}}
                                                               settingGroups={[]}
                                                               settingOptions={[]}
                                                               onChangeSettings={null}
                                                               onHandleUpdate={onHandleUpdate}
                                                               onHandleDeletion={onHandleDeletion}
                                                               onHandleSwap={onHandleSwap}
                                                               onHandleCreateTemplate={onHandleCreateTemplate}
                                                               onHandleSetting={onHandleSetting}
                                                               onHandleClone={onHandleClone}
                                                               onHandleCopy={onHandleCopy}
                                                               onHandleCut={onHandleCut}
                                                               onOpenTemplate={onOpenTemplate}
                                                               updateWidgetData={updateWidgetData}
                                                               onInsertSection={onInsertSection}
                                                               onInsertWidget={onInsertWidget}
                                                               saveWidgetState={saveState}


                />
            </div>

        }

        {/*</Suspense>*/}
    </CMSTheme>
}
