
export enum SwapType {
    widget_to_widget_rearrange,
    widget_to_widget_moved,
    widget_to_empty ,
    widget_to_section,
    section_to_empty,
    section_to_widget,
    section_to_section,
}
//
export interface SwapOption {
    type: SwapType,
    tag: any|null
}