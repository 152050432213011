import React, {useRef, useState} from "react";
import styles from "../../shared/editable/EditableModule.module.scss";
import {StyleUtil} from "../../setting/StyleUtil";
import {IconButton, Tooltip} from "@mui/material";
import OutlinedIcon from "../../../../ui/icon/OutlinedIcon";
import useFocusInEditable from "../../../../hook/useFocusInEditable";
import {nanoid} from "nanoid";
import {FocusLevelStr, OverLevel, SetFocusLevelStr, SetOverLevel} from "../../shared/editable/EditablePanel";
import {useAppSelector} from "../../../../redux/hook";
import {selectViewState} from "../../../../redux/features/view/viewSlice";
import SectionOrWidgetPopover from "../../../../ui/popover/SectionOrWidgetPopover";
import {SectionDesc} from "../../../model/desc/SectionDesc";
import {WidgetDesc} from "../../../model/desc/WidgetDesc";

export default function DefaultColumn(props: (React.HTMLAttributes<HTMLDivElement>
                    & {handleDeleteColumn? : () => void,
                        handleCloneColumn? : () => void,
                        handleCopyColumn? : () => void,
                        handleAddColumn? : () => void ,
                        handleColumnSetting? : () => void ,
                        column?: number
                        enabledSetting?: boolean
                        onInsertSection?: (uiLevelStr: string, desc: SectionDesc) => void,
                        onInsertWidget?: (uiLevelStr: string, desc: WidgetDesc) => void
                    uiLevelStr? :string }
    )) {
    const divRef = useRef<any>();
    const viewState = useAppSelector(selectViewState);
    const [menuId, setMenuId] = useState<string>(nanoid());
    const [anchorEl, setAnchorEl] = useState<any|null>(null);

    const handleOnClose = (event:any) => {
        SetOverLevel("")
        SetFocusLevelStr("")
        setMenuId(nanoid())
        setAnchorEl(null);
        event.stopPropagation();
        event.preventDefault();
    }

    const {fireNewFocus} = useFocusInEditable((callback) => {
        let {newFocus,oldFocus} = callback.detail;

        if(props.uiLevelStr === newFocus || props.uiLevelStr === oldFocus) {
            SetFocusLevelStr(newFocus);
            SetOverLevel(newFocus);
            setMenuId(nanoid());
        }
    });

    const handleMouseOver = (event: React.MouseEvent<HTMLElement>) => {

        event.stopPropagation();
        event.preventDefault();

        if(props.uiLevelStr && FocusLevelStr() !== props.uiLevelStr) {
            SetOverLevel(props.uiLevelStr);
            fireNewFocus(props.uiLevelStr, FocusLevelStr());
        }

    }

    const handleMouseOut = (event: React.MouseEvent<HTMLElement>) => {
        fireNewFocus("", FocusLevelStr()); // very importance, will make column highlight go away and restore highlight on the object
        event.stopPropagation()
        event.preventDefault();
    }

    let newProps = {...props}
    delete newProps['handleCloneColumn']
    delete newProps['handleDeleteColumn']
    delete newProps['handleAddColumn']
    delete newProps['uiLevelStr']
    delete newProps['handleColumnSetting']
    delete newProps['onInsertSection']
    delete newProps['onInsertWidget']
    delete newProps['column'];
    delete newProps['enabledSetting']
    delete newProps['handleCopyColumn']

    const open = Boolean(anchorEl);
    const enabledSetting = props.enabledSetting ? props.enabledSetting : false;

    return <div {...newProps}
                draggable={false}
                style={ {
                    position: "relative",
                    outline: OverLevel() === props.uiLevelStr ? "1px dashed var(--bc-editor-column-border-focus-color)" : undefined,
                    outlineOffset: "-1px"
                }}
                onMouseOver={(viewState.isEdit && enabledSetting) ? handleMouseOver : undefined}
                onMouseOut={(viewState.isEdit && enabledSetting) ? handleMouseOut : undefined}
    >

        <div ref={divRef} className={styles.RootActionPanelWithParent}>&nbsp;</div>
        { open && <SectionOrWidgetPopover
            open={open}
            anchorEl={anchorEl}
            onlyWidget={true}
            title={props.column ? "Add widget to column #" + (props.column+1) : "Add widget"}
            onClose={handleOnClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center"
            }}
            className={styles.Popover}
            uiLevelStr={props.uiLevelStr}
            onInsertSection={props.onInsertSection}
            onInsertWidget={props.onInsertWidget}
        />}
        { enabledSetting && <div key={`column_${menuId}`} className={styles.RootActionPanelWithParent}  style={{zIndex: 199}}>
            <div
                style={{visibility: OverLevel() === props.uiLevelStr ? "visible" : "hidden"}}
                className={StyleUtil.combineClasses(styles.ActionPanel,
                    styles.SectionAction,
                    styles.ActionPanelWithParent)}>
                {/*{<Tooltip title={"Add Widget"}><IconButton tabIndex={-1} className={styles.Icon}*/}
                {/*                                                                    onPointerDown={(event) => {*/}
                {/*                                                                        if(props.handleAddColumn) props.handleAddColumn()*/}
                {/*                                                                        event.stopPropagation()*/}
                {/*                                                                        event.preventDefault()*/}
                {/*                                                                    }} size="large"><OutlinedIcon fontSize={"small"}>add</OutlinedIcon></IconButton></Tooltip>*/}
                {/*}*/}
                {<><Tooltip title={"Add Widget"}><IconButton tabIndex={-1} className={styles.Icon}
                                                             onClick={(event) => {
                                                                 event.stopPropagation();
                                                                 event.preventDefault()
                                                             }}

                                                            onPointerDown={(event) => {
                                                               event.stopPropagation()
                                                               event.preventDefault()

                                                               setAnchorEl(divRef.current);

                                                               setTimeout(()=> {
                                                                   fireNewFocus(props.uiLevelStr!,FocusLevelStr());
                                                                   SetOverLevel(props.uiLevelStr!)
                                                               },100);

                                                           }} size="large"><OutlinedIcon
                    fontSize={"small"}>add</OutlinedIcon></IconButton></Tooltip>
                </>
                }

                <Tooltip title={"Setting"}><IconButton tabIndex={-1} className={styles.Icon}
                                                               onPointerDown={(event) => {
                                                                   if (props.handleColumnSetting) props.handleColumnSetting()
                                                                   event.stopPropagation()
                                                                   event.preventDefault()
                                                               }
                                                               } size="large"><OutlinedIcon
                    fontSize={"small"}>settings</OutlinedIcon></IconButton></Tooltip>
                {props.handleCloneColumn && <Tooltip title={"Duplicate"}><IconButton tabIndex={-1}
                                                                                            className={styles.IconSmall + " " + styles.IconFlipVertical}
                                                                                            onPointerDown={(event) => {
                                                                                                if (props.handleCloneColumn) props.handleCloneColumn()
                                                                                                event.stopPropagation()
                                                                                                event.preventDefault()
                                                                                            }}
                                                                                            size="large"><OutlinedIcon
                    fontSize={"small"}>library_add</OutlinedIcon></IconButton></Tooltip>}
                {props.handleCopyColumn &&
                    <Tooltip title={"Copy"}><IconButton tabIndex={-1} className={styles.IconSmall}
                                                               onPointerDown={(event) => {
                                                                   if (props.handleCopyColumn) props.handleCopyColumn()
                                                                   event.stopPropagation()
                                                                   event.preventDefault()
                                                               }} size="large"><OutlinedIcon
                        fontSize={"small"}>copy</OutlinedIcon></IconButton></Tooltip>}
                {props.handleDeleteColumn &&
                    <Tooltip title={"Delete"}><IconButton tabIndex={-1} className={styles.Icon}
                                                                 onPointerDown={(event) => {
                                                                     if (props.handleDeleteColumn) props.handleDeleteColumn()
                                                                     event.stopPropagation()
                                                                     event.preventDefault()
                                                                 }} size="large"><OutlinedIcon
                        fontSize={"small"}>delete</OutlinedIcon></IconButton></Tooltip>}
            </div>
        </div>}
        {props.children}
    </div>
}