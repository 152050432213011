import SettingProps from "../SettingProps";
// import CSSSetting from "../csssetting/CSSSetting";
import GenericSetting, {StyleSettingType} from "../genericsetting/GenericSetting";

export default function MarginSetting(props: SettingProps) {

    // return <CSSSetting {...props} title={"Margin"}  type={"margin"} targets={["margin-block-start" , "margin-block-end" ,"margin-inline-start", "margin-inline-end"]}/>
    return <GenericSetting {...props}
                           title={"Margin"}
                           type={"margin"}
                           targets={[
                                    { name: "Top",target: 'margin-block-start' , type: StyleSettingType.default_and_auto_size},
                                    { name: "Bottom", target: 'margin-block-end' , type: StyleSettingType.default_and_auto_size},
                                    { name: "Left", target: 'margin-inline-start' , type: StyleSettingType.default_and_auto_size},
                                    { name: "Right", target: 'margin-inline-end' , type: StyleSettingType.default_and_auto_size}
                                ]}
    />
}