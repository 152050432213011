import DrillItemCmd, {DrillChangeFrequency, DrillSortBy} from "../../cms/model/DrillItemCmd";
import styles from "./Drilldown.module.scss";
import InputPanel from "../dialog/InputPanel";
import React, {useState} from "react";
import {InputModel, InputOption} from "../../model/InputModel";
import {QueryType} from "../../cms/model/QueryType";

const yesnoOptions: InputOption[] = [  {label: "Yes", value: true},
    {label: "No", value: false}];
// searchProfile
const changeOptions: InputOption[] = [
    {label: "always", value: DrillChangeFrequency.always},
    {label: "hourly", value: DrillChangeFrequency.hourly},
    {label: "daily", value: DrillChangeFrequency.daily},
    {label: "weekly", value: DrillChangeFrequency.weekly},
    {label: "monthly", value: DrillChangeFrequency.monthly},
    {label: "never", value: DrillChangeFrequency.never},
    {label: "none", value: DrillChangeFrequency.none}
];

const priorityOptions: InputOption[] = [
    {label: "0.0", value: 0.0},
    {label: "0.1", value: 0.1},
    {label: "0.2", value: 0.2},
    {label: "0.3", value: 0.3},
    {label: "0.4", value: 0.4},
    {label: "0.5", value: 0.5},
    {label: "0.6", value: 0.6},
    {label: "0.7", value: 0.7},
    {label: "0.8", value: 0.8},
    {label: "0.9", value: 0.9},
    {label: "1.0", value: 1.0},
]

const sortOptions: InputOption[] = [
    {label: "none", value: DrillSortBy.none},
    {label: "alphabet", value: DrillSortBy.alphabet},
];

const featureOptions: InputOption[] = [
    {label: "part number", value: QueryType.partnumber},
    {label: "keyword", value: QueryType.keyword},
    {label: "family", value: QueryType.family},
    {label: "search", value: QueryType.search},
    {label: "merch group", value: QueryType.merchgroup},
];

const searchOptions: InputOption[] = [
    {label: "part number", value: QueryType.partnumber},
    {label: "keyword", value: QueryType.keyword},
    {label: "family", value: QueryType.family},
    {label: "search", value: QueryType.search},
    {label: "merch group", value: QueryType.merchgroup},
    {label: "URL", value: QueryType.url},
    {label: "field search", value: QueryType.fieldsearch},
];

const levelOptions: InputOption[] = [
    {label: "use default", value: 0},
    {label: "1", value: 1},
    {label: "2", value: 2},
    {label: "3", value: 3},
    {label: "4", value: 4},
    {label: "5", value: 5},
    {label: "6", value: 6},
];

export interface DrillInfoProp {cmd:DrillItemCmd
    id?:string
    handleChange: (inputId: string, values: InputModel[],index?: number) => void
    searchProfileOptions: InputOption[]
    featureGroupOptions: InputOption[]
    drillGroupOptions: InputOption[]
    roleOptions: InputOption[]
}

export default function DrillInfo(props: DrillInfoProp)
{
    const {searchProfileOptions,drillGroupOptions,roleOptions,featureGroupOptions} = props;
    const [cmd,setCmd] = useState<any>(props.cmd);

    const handleChange = (inputId: string, values: InputModel[],index: number) => {

        if(index < 0) return;
        props.handleChange(inputId, values,index);
        let id = values[index].id;
        if(id === "searchType" || id === "featureType") {
        }
        let newCmd = {...cmd}
        newCmd[id] = values[index].defaultValue;
        setCmd(newCmd);
    }

    let searchObject;
    if(cmd.searchType === QueryType.merchgroup) {
        searchObject = {
            id: "searchString",
            label: "From",
            type: "select",
            defaultValue: cmd.searchString,
            required: false,
            editable: true,
            options: drillGroupOptions
        }
    } else {
        searchObject = {
            id: "searchString",
            label: "Search String",
            type: "text",
            defaultValue: cmd.searchString,
            required: false,
            editable: true
        }
    }

    let featureObject;
    if(cmd.featureType === QueryType.merchgroup) {
        featureObject = {
            id: "featureString",
            label: "From",
            type: "select",
            defaultValue: cmd.featureString,
            required: false,
            editable: true,
            options: featureGroupOptions
        };
    } else {
        featureObject = {
            id: "featureString",
            label: "Feature String",
            type: "text",
            defaultValue: cmd.featureString,
            required: false,
            editable: true
        };
    }
    return <div className={styles.DrilldownInfo}><InputPanel
        id={props.id}
        handleChange={handleChange}
        handleDisable={(value) => {}}
        fields={[
            {id: "indexId", label: "Id", defaultValue: cmd.indexId, required: true , editable: true},
            {id: "name", label: "Name", defaultValue: cmd.name, required: true , editable: true},
            {id: "description", label: "Description", type: "textarea", defaultValue: cmd.description, required: true , editable: true},
            {id: "active", label: "Active", type: "select", defaultValue: cmd.active, required: false , editable: true, options: yesnoOptions},
            {id: "seoChangeFrequency", label: "Change Frequency", type: "select", defaultValue: cmd.seoChangeFrequency, required: false , editable: true, options: changeOptions},
            {id: "seoPageRank", label: "Sitemap Priority", type: "select", defaultValue: cmd.seoPageRank, required: false , editable: true, options: priorityOptions},
            {id: "seoIndex", label: "SEO Index", type: "select", defaultValue: cmd.seoIndex , required: false , editable: true, options: yesnoOptions},
            {id: "seoTitle", label: "SEO Title", defaultValue: cmd.seoTitle, required: false , editable: true},
            {id: "seoKeywords", label: "SEO Keywords", defaultValue: cmd.seoKeywords, required: false , editable: true},
            {id: "seoDescription", label: "SEO Description", type: "textarea", defaultValue: cmd.seoDescription, required: false , editable: true},
            {id: "accessList", type: "multi", label: "User Policies", defaultValue: cmd.accessList ? cmd.accessList: ["PUBLIC"], required: false , editable: true, options: roleOptions},

            featureObject,
            searchObject,
            {id: "featureType", label: "Feature Products", type: "select", defaultValue: cmd.featureType, required: false , editable: true, options: featureOptions},
            {id: "searchType", label: "Search Products", type: "select", defaultValue: cmd.searchType, required: false , editable: true, options: searchOptions},
            {id: "bannerImage", label: "Banner URL", type: "dam", defaultValue: cmd.bannerImage, required: false , editable: true},
            {id: "categoryImage", label: "Category URL", type: "dam", defaultValue: cmd.categoryImage, required: false , editable: true},
            {id: "displayColumns", label: "Display Columns", type: "select", defaultValue: cmd.displayColumns, required: false , editable: true, options: levelOptions},
            {id: "displayLevel", label: "Display Level", type: "select", defaultValue: cmd.displayLevel, required: false , editable: true, options: levelOptions},
            {id: "sortBy", label: "sortBy",type: "select", defaultValue: cmd.sortBy, required: false , editable: true, options: sortOptions},
            {id: "searchProfile", label: "Search Profile",type: "select", defaultValue: cmd.searchProfile, required: false , editable: true, options: searchProfileOptions},
        ]}
            layouts={[
                    {label: "", ids: [
                            "indexId","name",
                            "description",
                            "active","seoIndex",
                            "sitePriority","changeFrequency",
                            "seoTitle",
                            "seoKeywords",
                            "seoDescription",

                            "accessList",

                            "searchType","searchString",
                            "featureType",

                            "displayLevel","displayColumns",

                            "bannerImage","categoryImage",
                            "sortBy","searchProfile"
                        ],
                        ids_grid: [
                            6,6, //"indexId","name",
                            12, // "description",
                            6,6, //"active","seoIndex",
                            6,6, // "sitePriority","changeFrequency",
                            12,12,12, //"seoTitle","ssoKeywords","ssoDescription"
                            12, // accessList
                            3,9, // searchType , searchString
                            6,6, // displayLevel, displayCOlumns
                            12,12, //"bannerImage","categoryImage",
                            6,6] //"sortBy","searchProfile"
                    },
        ]}
    /></div>
}