import React from 'react';

export interface GoogleTagContextInterface {

}


const GoogleTagContext = React.createContext<GoogleTagContextInterface>({

})

export default GoogleTagContext;