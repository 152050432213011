import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import {SectionInterface} from "../../../cms/model/SectionInterface";

interface ViewState {
    isEdit: boolean;
    viewData: SectionInterface[] | null;
}

const initialState:ViewState = {
    isEdit: false,
    viewData: null
}

export const viewSlice = createSlice( {
    name: 'view',
    initialState,
    reducers: {
        viewEditAction: (state: ViewState, action: PayloadAction<{ isEdit: boolean}>) => {
            state.isEdit = action.payload.isEdit;
        },
        viewUpdateDataAction: (state: ViewState , action: PayloadAction<{viewData:SectionInterface[] | null}>) => {
            state.viewData = action.payload.viewData;
        },
        viewResetAction: state => {
            state.isEdit = false;
        }
    }

})

export const { viewEditAction, viewResetAction, viewUpdateDataAction } = viewSlice.actions

export const selectViewState = (state: RootState) => state.viewReducerState;

export default viewSlice.reducer;