import styles from './StyledEditor.module.scss';
import {useEffect, useRef} from "react";
import hljs from "highlight.js";
import 'highlight.js/styles/stackoverflow-light.css';
import {Grid} from "@mui/material";
import SettingPanel from "../panel/SettingPanel";

export interface StyledEditorProps {
    title: string,
    types: string[],
    className?: string,
    value?: string,
    defaultValue?: string,
    rows: number,
    inputProps?: any,
    onChange?: React.ChangeEventHandler<HTMLTextAreaElement>
}

export default function StyledEditor(props: StyledEditorProps) {
    const codeRef = useRef<any>(null);
    const editorRef = useRef<any>(null);

    const syncScroll = () => {
        codeRef.current!.scrollTop = editorRef.current!.scrollTop;
        codeRef.current!.scrollLeft = editorRef.current!.scrollLeft;
    }

    const syncContent = () => {
        if(codeRef.current !== null) {
            codeRef.current!.innerHTML = hljs.highlightAuto(editorRef.current!.value  , props.types).value;
        }
    }
    const onHandleInput = () => {
        syncContent();
        syncScroll();

    }

    const checkingTab = (event: React.KeyboardEvent) => {
        if(codeRef.current !== null && editorRef.current !== null) {
            if (event.key === "Tab") {
                event.preventDefault();
                let value = editorRef.current.value;
                let start = editorRef.current.selectionStart;
                value = value.slice(0,start) + "  " + value.slice(start,value.length);
                editorRef.current.value = value;
                editorRef.current.selectionStart = start+2;
                editorRef.current.selectionEnd = start+2;
                syncContent();
            }
        }
    }

    useEffect(() => {
        codeRef.current!.innerHTML = hljs.highlightAuto(editorRef.current!.value, props.types).value;
    },[props.defaultValue,props.value,props.types]);

    return <SettingPanel title={props.title}>
        <Grid container spacing={0}>
            <Grid item xs={12}>
            <div  className={styles.Root} style={{height: `calc(35px * ${props.rows})`}}>
                <textarea ref={editorRef}
                          spellCheck={false}
                          className={styles.Editor}
                          onInput={onHandleInput}
                          onScroll={syncScroll}
                          onKeyDown={checkingTab}
                          rows={props.rows}
                          defaultValue={props.defaultValue}
                          value={props.value}
                          onChange={props.onChange}
                            {...props.inputProps}
                ></textarea>
                <pre>
                    <code className={styles.Highlighting} ref={codeRef}></code>
                </pre>
            </div>
        </Grid>
    </Grid></SettingPanel>
}