import Link from "../button/Link";
import {Breadcrumbs, Typography} from "@mui/material";
import React from "react";
import styles from "./PageSummary.module.scss";
import OutlinedIcon from "../icon/OutlinedIcon";
import {CMSMainMenuConfig} from "../../config/CMSMainMenuConfig";
import {CapitalizeString} from "../../util/StringUtil";

export interface PageSummaryProps {
    type: string,
    title?: string,
    desc?: string,
    link?: string
    name?: string,
    children?: any
    titleActions?: JSX.Element[] | JSX.Element,
}

export default function PageSummary(props: PageSummaryProps) {
    const searchHref = "/" + props.type;
    const configs = CMSMainMenuConfig.filter(config => config.href === searchHref);
    let pageTitle: string = "";
    let desc: string = "";
    let title: string = "";


    if(configs && configs.length > 0) {
        title = configs[0].title;

        if(props.title !== undefined) {
            pageTitle = props.title;
        } else {
            pageTitle = configs[0].pageTitle;
        }

        if(props.desc !== undefined) {
            desc = props.desc;
        } else {
            if(configs[0].detailDesc) {
                desc = configs[0].detailDesc;
            } else {
                desc = configs[0].desc;
            }
        }
    } else {
        title = props.title !== undefined ? props.title : CapitalizeString(props.type);
        pageTitle = props.title !== undefined ? props.title : CapitalizeString(props.type);
        desc = props.desc !== undefined ? props.desc : "";
    }

    return <div className={styles.Root}>
        <Breadcrumbs className={styles.Breadcrumbs}
                     separator={<OutlinedIcon className={styles.BreadcrumbSeparator}>navigate_next</OutlinedIcon>}
        >
            { props.name !== "home" && <Link id={`homeBreadcrumb`} data-action={"openHome"} href={"/home"}><Typography className={styles.DisablePanel}>{"CMS"}</Typography></Link>}
            { props.name && <Link id={`${props.type}Breadcrumb`} data-action={`open${props.type}`} href={"/" + (props.link ? props.link : props.type.toLowerCase())}><Typography  className={styles.DisablePanel}>{title}</Typography></Link>}
            { !props.name && <Typography>{pageTitle}</Typography> }
            { props.name && props.name !== "" && <Typography>{props.name}</Typography> }
        </Breadcrumbs>
        <div className={styles.Title}><span>{pageTitle}</span> <span>{props.titleActions}</span> </div>
        <div className={styles.Desc}>{desc}</div>
    </div>;
}