import {useEffect} from "react";

export default function useWidgetState<T>(name:string, callback: (event:CustomEvent<{widgetData: any,
                                                                                        action: string|null,
                                                                                        value: any}>) => void) {
    useEffect(() => {
        document.addEventListener("ws_" + name , callback as (evt: Event) => void)
        return () => {
            document.removeEventListener("ws_" + name, callback as (evt: Event) => void);
        }
    },[name,callback]);

    return true;
}

export function SubmitWidgetState(widgetId: string, widgetData: any, action: string|null, value: any) {
    document.dispatchEvent(new CustomEvent("ws_" + widgetId,{ detail: {
            widgetData, action, value
        }} ));
}

export function SubmitWidgetId(widgetId: string) {
    document.dispatchEvent(new CustomEvent("ws_" + widgetId,undefined ));
}
