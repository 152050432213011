import styles from "./SVGArrow.module.scss"

export default function SVGArrow(props: {width: number,
                                        height: number,
                                        strokeWidth: number,
                                        strokeColor: string,
                                        variant: 'up'|'down'|'left'|'right'} & React.SVGProps<SVGSVGElement>) {
    const {strokeWidth,height,strokeColor,width} = props;
    let newProps:any = {...props};
    delete newProps.strokeColor;
    delete newProps.strokeWidth;
    delete newProps.variant;
    return <svg {...newProps} className={styles.Root}>
        { props.variant === "up" && <line x1={strokeWidth} y1={height-strokeWidth} x2="50%" y2={strokeWidth} stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin={"round"}/> }
        { props.variant === "up" && <line x1={"50%"} y1={strokeWidth} x2={width - strokeWidth} y2={height-strokeWidth} stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin={"round"}/> }

        { props.variant === "down" && <line x1={strokeWidth} y1={strokeWidth} x2="50%" y2={height - strokeWidth} stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin={"round"} /> }
        { props.variant === "down" && <line x1={width - strokeWidth} y1={strokeWidth} x2="50%" y2={height - strokeWidth} stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin={"round"}/> }

        { props.variant === "right" && <line x1={strokeWidth} y1={strokeWidth} x2={width - strokeWidth} y2="50%" stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin={"round"}/> }
        { props.variant === "right" && <line x1={width - strokeWidth} y1="50%" x2={strokeWidth} y2={height - strokeWidth} stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin={"round"}/> }

        { props.variant === "left" && <line x1={width -strokeWidth} y1={strokeWidth} x2={strokeWidth} y2="50%" stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin={"round"}/> }
        { props.variant === "left" && <line x1={strokeWidth} y1="50%" x2={width-strokeWidth} y2={height - strokeWidth} stroke={strokeColor} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin={"round"}/> }
    </svg>
}