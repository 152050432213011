import {useEffect, useRef, useState} from "react";
import styles from "./OrgPopover.module.scss"
import OutlinedIcon from "../icon/OutlinedIcon";
import {Box, List, ListItem, ListItemButton, Popover, TextField, Tooltip} from "@mui/material";
import OrgCmd from "../../model/jcr/OrgCmd";
import {TooltipPlacement} from "../../util/ThemeUtil";

let globalTimeout:NodeJS.Timeout|null = null;
export interface OrgPopoverProps {
    tooltipPlacement: TooltipPlacement;
    tooltipTitle: string
    orgs?: any[];
    value: OrgCmd;
    onChangeOrg?: (storefront:OrgCmd) => void,
}



export default function OrgPopover(props: OrgPopoverProps) {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    // const [selectedOrg, setSelectedOrg] = useState<OrgCmd|null>(props.value);
    const textRef = useRef(null);
    const [keyword, setKeyword] = useState<string>("");
    const [onText, setOnText] = useState<boolean>(false);
    const handleOnClickOrganization = (event: React.MouseEvent<HTMLDivElement>) => {
        setKeyword("");
        setOnText(false);
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
        event.preventDefault();
    };

    const handleFocus = (event: React.FocusEvent) => {
        if(globalTimeout) {
            clearTimeout(globalTimeout);
            globalTimeout = null;
        }
    }

    const handleTextFocus = (event:React.FocusEvent) => {
        handleFocus(event);
        if(onText === false) {
            setOnText(true);
        }
    }

    const handleBlur = (event: React.FocusEvent) => {
        globalTimeout = setTimeout(() => {
            setAnchorEl(null);
            globalTimeout = null;
        },100);
    }


    const handleClickOnListItem = (org: OrgCmd) => {

        if(globalTimeout !== null) {
            clearTimeout(globalTimeout);
            globalTimeout = null;
        }
        let newOrg = {...org};
        if(props.onChangeOrg) {
            props.onChangeOrg(newOrg);
        }
        setAnchorEl(null);
    }

    const open = Boolean(anchorEl);
    useEffect(() => {

        if(anchorEl !== null) {
            setTimeout(() => {
                let a = (textRef.current as any).querySelector("input[type=text]");
                a.focus();
            },100)

        }
    },[anchorEl])

    if(!props.orgs || !props.value) return <></>;

    let items = props.orgs && props.orgs.filter((org) => {
        if(keyword === "") return true;
        else {
            return org.orgName.toLowerCase().indexOf(keyword.toLowerCase()) >= 0 ||
                    org.orgCode.toLowerCase().indexOf(keyword.toLowerCase()) >= 0;
        }
    }).map((org,index) => {
            let selected = false;
            if(props.value === null && index === 0) {
                selected = true;
            } else {
                if(props.value !== null) {
                    selected = org.orgCode === props.value.orgCode;
                }
            }
            return <ListItem
                             autoFocus={onText ? false : (selected ? selected : false)}
                             disablePadding
                             tabIndex={-1}
                             onBlur={handleBlur}
                             key={"current_storefront_" + index}
                             value={org.orgCode}
                             className={styles.ListItem}
                             onClick={() => handleClickOnListItem(org)}
            ><ListItemButton selected={selected} sx={ selected ? { color: "var(--standard-jade-600)" , fontWeight: "bold", borderRadius: "4px"} : {borderRadius: "4px" }}>{org.orgName}</ListItemButton></ListItem>
        }
    )

    return <>
        <div className={props.orgs && props.orgs.length > 1 ? styles.Root : styles.RootWithOutHover}
             onClick={props.orgs && props.orgs.length > 1 ? handleOnClickOrganization: undefined}
             style={{pointerEvents: open ? "none" : "auto"}}>

            <div className={styles.Org1}>
                {props.value.orgName.length > 2 ? props.value.orgName.substring(0,2) : props.value.orgName}
            </div>

            <div className={styles.Org2}>
                <Tooltip title={props.value.orgName}>
                    <span>{props.value.orgName}</span></Tooltip>
            </div>
        </div>
        <Popover
            tabIndex={-1}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center"
            }}
            style={{pointerEvents: "none"}}>
            <Box sx={{boxShadow: 3, padding: 10, maxWidth: "300px", width: "300px"}}
                 style={{pointerEvents: "auto" , padding: "10px"}}>
                <TextField
                    ref={textRef}
                    className={styles.TextField}
                    placeholder={"Find company by name"}
                    value={keyword}
                    tabIndex={0}
                    size={"small"}
                    variant={"outlined"}
                    fullWidth
                    onBlur={handleBlur}
                    onFocus={handleTextFocus}
                    onChange={ (event) => {
                            setKeyword(event.target.value);
                        }
                    }
                />
                <List dense className={styles.List}>
                    {
                        items
                    }
                </List>
            </Box>
        </Popover>
    </>
}