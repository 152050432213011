import {IconButton, Tooltip} from "@mui/material";
import styles from "./EditablePageMenu.module.scss";
import React, {useState} from "react";
import EditorFloatMenu from "./EditorFloatMenu";
import {EditablePageProps} from "./EditablePageProps";
import OutlinedIcon from "../icon/OutlinedIcon";
import SolidIcon from "../icon/SolidIcon";
import {GetLocalItem, SetLocalItem} from "../../util/LocalStorageUtil";
import SymbolIcon from "../icon/SymbolIcon";

export default function EditablePageMenu(props: EditablePageProps) {
    const edited = props.edit;
    const [display, setDisplay] = useState<"laptop"|"stay_current_portrait"|"stay_current_landscape">("laptop");
    const [fit, setFitScreen] = useState<boolean>(GetLocalItem("previewWrapper","") === "");
    const handleChangeFit = () => {
        if(props.onChangeFit) {
            props.onChangeFit(!fit);
        }
        setFitScreen(!fit);
    }

    const handleChangeDisplay = () => {
        if(props.onChangeDisplay) {
            if (display === "laptop") {
                setDisplay("stay_current_portrait");
                props.onChangeDisplay("portrait");
            } else if (display === "stay_current_portrait") {
                setDisplay("stay_current_landscape")
                props.onChangeDisplay("landscape");
            } else {
                setDisplay("laptop")
                props.onChangeDisplay("desktop");
            }
        }
    }

    return (
        <EditorFloatMenu type={props.parentName} name={props.currentName} style={props.style} >
            <div onMouseOut={(event) => {event.stopPropagation(); event.preventDefault()}}
                 onMouseEnter={(event) => {event.stopPropagation(); event.preventDefault()}}
                 onMouseOver={(event) => {event.stopPropagation(); event.preventDefault()}}>
                {edited && props.onUndo && <Tooltip title={"Undo"} onClick={props.onUndo} arrow>
                    <IconButton className={styles.IconCenter} onClick={props.onUndo} disabled={props.undoInfo === undefined || props.undoInfo.undoLength === 0}>
                        <OutlinedIcon flipX={true}>refresh</OutlinedIcon>
                </IconButton></Tooltip>}
                {edited &&  props.onRedo && <Tooltip title={"Redo"} arrow>
                    <IconButton className={styles.IconCenter} onClick={props.onRedo} disabled={props.undoInfo === undefined || props.undoInfo.redoLength === 0}>
                        <OutlinedIcon>refresh</OutlinedIcon>
                </IconButton></Tooltip>}
                {edited && <Tooltip title={"Add To Template"} arrow>
                    <IconButton className={styles.IconCenter} onClick={props.onCreateSectionTemplate}>
                        <OutlinedIcon>favorite_border</OutlinedIcon>
                </IconButton></Tooltip>}
                {props.onChangeDisplay && <Tooltip title={"Change Aspect Ratio"} arrow>
                    <IconButton  className={styles.IconCenter} onClick={handleChangeDisplay}>
                            <OutlinedIcon>{display}</OutlinedIcon>
                </IconButton></Tooltip>
                }
                {edited && <Tooltip title={"Preview"}  arrow>
                    <IconButton  className={styles.IconCenter} onClick={props.onCancelEdit}>
                        <OutlinedIcon>visibility</OutlinedIcon>
                </IconButton></Tooltip>}
                {!edited && <Tooltip title={"Edit"} arrow><IconButton className={styles.IconCenter} onClick={props.onEdit}>
                    <OutlinedIcon>edit</OutlinedIcon>
                </IconButton></Tooltip>}
                {edited &&  <Tooltip title={"Save"} arrow><IconButton className={styles.IconCenter} onClick={props.onSave}>
                    <OutlinedIcon>save</OutlinedIcon>
                </IconButton></Tooltip>
                }
            </div>
            {/*<Grid container*/}
            {/*      spacing={1}*/}
            {/*      direction="row"*/}
            {/*      alignItems="center"*/}
            {/*      justifyContent="center"*/}
            {/*      classes={{*/}
            {/*          root: styles.SceneEditActionPanel*/}
            {/*      }}>*/}

            {/*    <Grid item>*/}
            {/*        { props.menus }*/}
            {/*    </Grid>*/}
                {/*{*/}
                {/*    edited &&*/}
                {/*    <React.Fragment>*/}
                {/*        <Grid item>*/}
                {/*            <Tooltip title={"Preview"}><IconButton onClick={props.onCancelEdit}>*/}
                {/*                <Icon className={styles.Icon}>preview</Icon>*/}
                {/*            </IconButton></Tooltip>*/}
                {/*            /!*<ReverseSolidLinkButton variant={"contained"} onClick={props.onCancelEdit}>*!/*/}
                {/*            /!*    <Icon className={styles.Icon}>preview</Icon>Preview*!/*/}
                {/*            /!*</ReverseSolidLinkButton>*!/*/}
                {/*        </Grid>*/}
                {/*        <Grid item>*/}
                {/*            <SolidLinkButton variant={"contained"} onClick={props.onSave}>*/}
                {/*                <Icon className={styles.Icon}>save</Icon>*/}
                {/*                Save*/}
                {/*            </SolidLinkButton>*/}
                {/*        </Grid>*/}
                {/*    </React.Fragment>*/}
                {/*}*/}
                {/*<Grid item>*/}
                {/*    {!edited && <SolidLinkButton variant={"contained"} onClick={props.onEdit}><Icon className={styles.Icon}>edit</Icon>Edit</SolidLinkButton>}*/}
                {/*</Grid>*/}
            {/*</Grid>*/}
            {/*<IconButton*/}
            {/*    classes={{root: styles.SceneMenu}}*/}
            {/*    aria-controls="long-menu"*/}
            {/*    aria-haspopup="true"*/}
            {/*    onClick={onClickSceneMenu}*/}
            {/*    size="large">*/}
            {/*    <Icon>more_vert</Icon>*/}
            {/*</IconButton>*/}
            {/*<Menu*/}
            {/*    anchorEl={menuTarget}*/}
            {/*    open={Boolean(menuTarget)}*/}
            {/*    keepMounted*/}
            {/*    onClose={onSceneMenuClose}*/}
            {/*    TransitionComponent={Fade}*/}
            {/*    anchorOrigin={{*/}
            {/*        vertical: 'top',*/}
            {/*        horizontal: 'left',*/}
            {/*    }}*/}
            {/*    sx={{ "paddingInline" : "20px"}}*/}
            {/*>*/}
            {/*    { edited && <MenuItem color={"primary"} onClick={(event) => {  setMenuTarget(null); props.onCancelEdit()}} classes={{root: styles.DefaultViewMenuItem}}><Icon className={styles.Icon}>preview</Icon>Preview</MenuItem>}*/}
            {/*    { edited && <MenuItem color={"primary"} onClick={(event) => {  setMenuTarget(null); props.onSave()}} classes={{root: styles.DefaultViewMenuItem}}><Icon className={styles.Icon} >save</Icon>Save</MenuItem>}*/}
            {/*    { !edited && <MenuItem color={"primary"} onClick={(event) => {  setMenuTarget(null); props.onEdit()}} classes={{root: styles.DefaultViewMenuItem}}><Icon className={styles.Icon} >edit</Icon>Edit</MenuItem> }*/}
            {/*</Menu>*/}
        </EditorFloatMenu>
    );
}