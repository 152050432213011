import {TextFieldProps} from "@mui/material/TextField/TextField";
import styles from './Input.module.scss';
import {IconButton, Menu, MenuItem, TextField} from "@mui/material";
import {useState} from "react";
import OutlinedIcon from "../../icon/OutlinedIcon";


interface  SizeValue {
    value: string,
    unit: string
}
export function extractValue(value:string):SizeValue {
    let numbers:string[] = [];
    let unit:string = "";
    if(value === "auto") {
        return {
            value: numbers.join(""),
            unit: "auto"
        }
    } else {
        if(value.startsWith("var(")) {
            numbers.push(value);
            unit = "var";
        } else {
            for (let i = 0; i < value.length; i++) {
                let ch = value.charAt(i);
                if (ch === '-' || (ch >= '0' && ch <= '9') || ch === '.') {
                    numbers.push(ch);
                } else {
                    if (ch === 'r') {
                        unit = "rem";
                    } else if (ch === '%') {
                        unit = "%";
                    } else if (ch === 'p') {
                        unit = "px";
                    }
                }
            }
        }
        return {
            value: numbers.join(""),
            unit: unit === "" ? "px" : unit
        }
    }
}

export function toSizeStr(value: SizeValue) {
    if(value && value.unit === "auto") {
        return "auto";
    } else if(!value || !value.value || value.value === "") {
        return "";
    } else {
        return value.value + value.unit;
    }
}

export default function SizeInput(props: (TextFieldProps &
                                            {   auto: boolean,
                                                onChangeSize: (value:string) => void
                                                allowPercent?: boolean,
                                                allowPixel?: boolean})) {
    const [anchorEl, setAnchorEl] = useState<HTMLElement|null>(null)
    const [value, setValue] = useState<SizeValue>(extractValue(props.value ? props.value as string : ""));

    const handleUnit = (unit: string) => {
        let newValue:any;
        if(unit === "auto") {
            newValue = {...value, unit: unit};
        } else {
            newValue = {...value, unit: unit};
        }
        setValue(newValue)
        setAnchorEl(null);
        props.onChangeSize(toSizeStr(newValue));
    };

    const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {

        let newValue = extractValue(event.currentTarget.value + value.unit);
        if(newValue.unit === "") {
            newValue.unit = value.unit;
        }
        setValue({value:newValue.value, unit: newValue.unit});
        props.onChangeSize(toSizeStr(newValue));
    }

    let newProps:any = {...props}
    if(newProps['onChangeSize']) {
        delete newProps['onChangeSize'];
        delete newProps['auto'];
        delete newProps['allowPixel']
        delete newProps['allowPercent']
    }

    return <><TextField {...newProps}
                        variant={"outlined"}
                        type={"text"}
                        value={value.unit === "auto" ? "auto" : value.value}
                        InputLabelProps={{shrink: true}}
                        placeholder=""
                        className={styles.Input}
                        size={"small"}
                        fullWidth={true}
                        disabled={value.unit === "auto"}
                        onChange={handleChange}
                        InputProps={{
                            endAdornment: <span className={styles.Separator} onClick={(event) => { setAnchorEl(event.currentTarget)}}>
                                <IconButton tabIndex={-1} className={styles.UnitButton} disableTouchRipple={true} disableFocusRipple={true} disableRipple={true}>
                                    <span className={styles.Unit}>{value.unit}</span>
                                    <OutlinedIcon className={styles.Caret}>expand_more</OutlinedIcon>
                                </IconButton>
                            </span>
                        }}
    />
        {anchorEl !== null && <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={anchorEl !== null}
            onClose={() => {setAnchorEl(null)}}
        >
            {(props.allowPixel === undefined || props.allowPixel) && <MenuItem onClick={() => {handleUnit("px")}}>px</MenuItem> }
            {(props.allowPercent === undefined || props.allowPercent) && <MenuItem onClick={() => {handleUnit("%")}}>%</MenuItem>}
            {props.auto && <MenuItem onClick={() => {handleUnit("auto")}}>auto</MenuItem>}
        </Menu>}
    </>
}