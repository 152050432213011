import {Autocomplete, createFilterOptions, TextField} from "@mui/material";
import React from "react";
import {InputOption} from "../../../../model/InputModel";

export default function DisplayField(props: {
    label:string,
    name:string,
    value:string,
    setFunction: (value:string) => void,
    handleChange: (value:string, name: string) => void,
    handleRemove?: (name: string) => void,
    displayOptions: InputOption[]
}) {
    const {displayOptions,value, label, setFunction, handleChange, name, handleRemove} = props;
    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        ignoreCase: true,
    });

    return <Autocomplete size={"small"}
                         autoHighlight
                         {...filterOptions}
                         options={displayOptions}
                         onChange={(event,value) => {
                             if(value !== null) {
                                 handleChange(value.value, name)
                                 setFunction(value.value);
                             } else {
                                 if(handleRemove) {
                                     handleRemove(name);
                                 }
                             }
                         }}
                         value={displayOptions.find(obj => obj.value === value)}
                         getOptionLabel={(option:InputOption) => option.label}
                         renderInput={(props) => <TextField
                            label={label}
                            {...props}/>}/>
}