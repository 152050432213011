import React, {useCallback, useEffect, useRef, useState} from 'react';
import styles from "./PageTemplate.module.scss";
import commonStyle from "../../config/Common.module.scss";
import StyledTable from "../table/StyledTable";
import {PageTemplateDataFetcher} from "../../datafetcher/PageTemplateDataFetcher";
import {useHistory} from "react-router";
import {useParams} from "react-router-dom";
import HttpClient from "../../datafetcher/HttpClient";
import {nanoid} from "nanoid";
import {useOrganization} from "../../hook/useOrganization";
import OutlinedIcon from "../icon/OutlinedIcon";
import IconLinkButton from "../button/IconLinkButton";
import ReverseSolidLinkSmallButton from '../button/ReverseSolidLinkSmallButton';
import PageSummary from '../pagesummary/PageSummary';
import {jcr} from "../../datafetcher/JCRDataFetcher";
import {CurrentISODate} from "../../util/DateUtil";
import PageTemplateDialog from "./PageTemplateDialog";
import {TemplateCmd} from "../../model/TemplateCmd";

export default function PageTemplate() {
    const  orgCmdProp = useOrganization();
    const history = useHistory();
    const query = new URLSearchParams(history.location.search);
    const params: any = useParams();
    const [updateData, setUpdateData] = useState<TemplateCmd>();
    const [templateId, setTemplateId] = useState("");
    const [formKey , setFormKey] = useState("");
    const [dataFetcher, setDataFetcher] = useState<PageTemplateDataFetcher|null>(null);
    const [deleteList,setDeleteList] = useState<string[]>([]);
    const inputFile = useRef<HTMLInputElement>(null);


    const handleUploadDialog = () => {
        if(inputFile !== null && inputFile.current !== null) {
            inputFile.current.click();
        }
    }

    const doUploadFile = () => {
        if(inputFile && inputFile.current) {
            if(inputFile.current.files) {
                jcr.pageTemplate.upload(inputFile.current.files).then((response) => {
                    if(!(orgCmdProp && orgCmdProp.currentOrg !== null && orgCmdProp.orgDetail !== null)) return;
                    setDataFetcher(new PageTemplateDataFetcher(orgCmdProp.currentOrg));
                    setFormKey(nanoid());
                })
            }
        }
    }

    const handleCloseUpdateDialog = () => {
        setUpdateData(undefined);
        setTemplateId("");
        history.push({
            pathname: '/pagestemplate',
            search: history.location.search});
    }

    const handleUpdateDialog = () => {

        dataFetcher!.load().then((xxxx) => {
            resetScreen();
        });

    }


    const handleDelete = () => {

        if(window.confirm("Are you sure to remove?")) {
            HttpClient.delete("/api/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/template/pages/" + deleteList.join(",")).then((response) => {
                resetScreen();
                setDeleteList([]);
            }).catch((e) => {
                setDeleteList([]);
            })
        }
    }

    const handleDownloadList = () => {
        HttpClient.download(`PagesTemplate-${CurrentISODate()}.gz`,
            "/api/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/template/download/pages/" + deleteList.join(","));
        setDeleteList([]);
        setFormKey(nanoid());
    }

    const handleAction = (tag:string, tagObject: any) => {
        if(tag === "DUPLICATE") {
            HttpClient.get( "/api/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/template/clone/pages/" +  tagObject.id)
                .then((response) => {
                    let id = response.data.response;
                    history.push({
                        pathname: `${history.location.pathname}/${id}`,
                        search: history.location.search
                    })
                    if(!(orgCmdProp && orgCmdProp.currentOrg !== null && orgCmdProp.orgDetail !== null)) return;
                    setDataFetcher(new PageTemplateDataFetcher(orgCmdProp.currentOrg));
                    setFormKey(nanoid());
                })
        } else if(tag === "DOWNLOAD") {
            HttpClient.download(
                `PagesTemplate-${tagObject.title}-${tagObject.id}.gz`,
                "/api/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/template/download/pages/" +  tagObject.id);
        }
    }
    


    if (params.evid && templateId !== params.evid) {
        setTimeout(() => {
            setTemplateId(params.evid);
        }, 100);
    }

    const resetScreen = useCallback(() => {
        history.replace({
            pathname: '/pagestemplate',
            search: history.location.search
        });
        setTemplateId("");
        setUpdateData(undefined);
        setFormKey(nanoid());
    },[history])

    useEffect(() => {
        if(templateId !== "" &&  orgCmdProp && orgCmdProp.currentOrg !== null) {
            ///api/jcr/orgs/{orgCode}/template/{generic}s/{genericId}
            HttpClient.get("/api/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/template/pages/" + templateId).then((response) => {
                if(response.data.response !== null) {
                    setUpdateData(response.data.response);
                } else {
                    resetScreen();
                }
            }).catch((e) => {
                resetScreen();
            })
        }

    },[templateId,orgCmdProp,resetScreen])


    useEffect(() => {
        if(!(orgCmdProp && orgCmdProp.currentOrg !== null && orgCmdProp.orgDetail !== null)) return;
        setDataFetcher(new PageTemplateDataFetcher(orgCmdProp.currentOrg));
        setFormKey(nanoid());


    }, [orgCmdProp]);


    const uploadButton = <IconLinkButton tooltip={"Upload File"} key="uploadTemplateButton" id="uploadTemplatetButton" data-action="uploadNewTemplate" variant={"contained"} onClick={handleUploadDialog}>
        <OutlinedIcon className={styles.Icon} >cloud_upload</OutlinedIcon>
    </IconLinkButton>;

    const deleteButton = <ReverseSolidLinkSmallButton id="deleteTemplateButton" data-action="deleteTemplates" variant={"contained"} onClick={handleDelete}>
        <OutlinedIcon className={styles.Icon} >delete</OutlinedIcon>
        Remove ({deleteList.length})
    </ReverseSolidLinkSmallButton>

    const downloadButton = <ReverseSolidLinkSmallButton id="downloadTemplateButton" data-action="downloadTemplates" variant={"contained"} onClick={handleDownloadList}>
        <OutlinedIcon className={styles.Icon} >download</OutlinedIcon>Download ({deleteList.length})
    </ReverseSolidLinkSmallButton>;

    return <React.Fragment>
        {updateData && <PageTemplateDialog allowOrgs={updateData.allowOrgs}
                                           header={"Update Pages Template"}
                                           title={updateData.title}
                                           subTitle={updateData.subTitle}
                                           viewData={updateData.viewCaches}
                                           viewNames={updateData.views}
                                           id={updateData.id}
                                           inserted={false}
                                           active={updateData.active}
                                           internalComment={updateData.internalComment}
                                           logo={updateData.logo}
                                           open={true} tags1={updateData.tags1}
                                           onClose={(value) => {
                                               if(value) {
                                                   handleUpdateDialog();
                                               } else {
                                                   handleCloseUpdateDialog();
                                               }
                                           }}
                                           viewLogos={updateData.viewLogos}
        />}
        <div className={commonStyle.ContentContainer + " " + commonStyle.ContentContainerOverflowX}>
            <PageSummary type={"pages Template"}/>
            <StyledTable key={formKey}

                         handleAction={handleAction}
                         handleDeleteLists={(ids) => setDeleteList(ids)}
                         hasCheckBox={true}
                         dataFetcher={dataFetcher}

                         title={"Template"}
                         actionComponents={[uploadButton]}
                         deleteButton={deleteButton}
                         downloadButton={downloadButton}

                         rowsPerPage={20}
                         page={parseInt((query.get("page") === null ? "0" : query.get("page")!))}
                         rowsPerPageOptions={[5,10,20,40,60,{label: "All" , value: -1}]}/>
            <input key={`upload_${formKey}`} type='file' id='upload-content'
                   accept=".gzip,.gz"
                   onChange={() => doUploadFile()}
                   ref={inputFile} style={{display: 'none'}}/>
        </div>

    </React.Fragment>
}