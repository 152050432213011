import React, {useEffect, useRef, useState} from 'react';
import styles from "./Themes.module.scss";
import commonStyle from "../../config/Common.module.scss";
import StyledTable from "../table/StyledTable";
import {ThemeDataFetcher} from "../../datafetcher/ThemeDataFetcher";
import {useHistory, useLocation} from "react-router";
import {useParams} from "react-router-dom";
import {InputModel} from "../../model/InputModel";
import HttpClient from "../../datafetcher/HttpClient";
import {nanoid} from "nanoid";
import InputDialog from "../dialog/InputDialog";
import {useOrganization} from "../../hook/useOrganization";
import IconLinkButton from "../button/IconLinkButton";
import ReverseSolidLinkSmallButton from '../button/ReverseSolidLinkSmallButton';
import PageSummary from "../pagesummary/PageSummary";
import OutlinedIcon from "../icon/OutlinedIcon";
import DefaultDialog from "../dialog/DefaultDialog";
import ThemeEditor from "./ThemeEditor";
import {CurrentISODate} from "../../util/DateUtil";
import SolidIcon from "../icon/SolidIcon";
import {jcr} from "../../datafetcher/JCRDataFetcher";

export default function Themes() {

    const history = useHistory();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const params: any = useParams();
    const [newOpen, setNewOpen] = useState(false);
    const [updateData, setUpdateData] = useState<any>({});
    const [themeId, setThemeId] = useState("");
    const [formKey , setFormKey] = useState("");
    const [dataFetcher, setDataFetcher] = useState<ThemeDataFetcher|null>(null);
    const [deleteList,setDeleteList] = useState<string[]>([]);
    const  orgCmdProp = useOrganization();

    // for update
    const [editTheme, setEditTheme] = useState<boolean>(false);
    const [themeInfo, setThemeInfo] = useState<any>({});
    const [themeProps, setThemeProps] = useState<{[key:string]: string}>({});
    const inputFile = useRef<HTMLInputElement>(null);

    const handleCloseNewDialog = () => {
        setNewOpen(false);
    }

    const handleOpenNewDialog = () => {
        setNewOpen(true);
    }

    const handleUploadDialog = () => {
        if(inputFile !== null && inputFile.current !== null) {
            inputFile.current.click();
        }
    }

    const doUploadFile = () => {
        if(inputFile && inputFile.current) {
            if(inputFile.current.files) {
                jcr.theme.upload(inputFile.current.files).then((response) => {
                    if(!(orgCmdProp && orgCmdProp.currentOrg !== null && orgCmdProp.orgDetail !== null)) return;
                    setDataFetcher(new ThemeDataFetcher(orgCmdProp.currentOrg));
                    setFormKey(nanoid());
                })
            }
        }
    }

    const handleCloseUpdateDialog = () => {
        setUpdateData({});
        setThemeId("");
        history.push({
                        pathname: '/themes',
                        search: location.search});
    }

    const handleUpdateDialog = (values: InputModel[]) => {
        let data: any = updateData;
        values.forEach(object => {
            data[object.id] = object.defaultValue;
        })
        data['createdAt'] = updateData.createdAt;
        data['modifiedAt'] = new Date().getTime();

        HttpClient.post("/api/v0/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/full/themes", data).then((response) => {
            dataFetcher!.load().then((xxxx) => {
                resetScreen();
            });
        }).catch((e) => {
        })
    }

    const handleSaveDialog = (values: InputModel[]) => {
        let data: any = {};
        values.forEach(object => {
            data[object.id] = object.defaultValue;
        })
        data['createdAt'] = new Date().getTime();
        data['modifiedAt'] = new Date().getTime();

        HttpClient.post("/api/v0/jcr/org/" + orgCmdProp?.currentOrg!.orgCode + "/full/themes", data).then((response) => {
            history.push({
                pathname: history.location.pathname + "/" + response.data.response.id ,
                search: history.location.search
            })
            //
        }).catch((e) => {

        })
        setNewOpen(false);
    }

    const handleDelete = () => {
        if(window.confirm("Are you sure to remove?")) {
            HttpClient.delete("/api/v0/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/themes/" + deleteList.join(",")).then((response) => {
                resetScreen();
                setDeleteList([]);
            }).catch((e) => {
                setDeleteList([]);
            })
        }
    }

    const handleDownloadList = () => {
        HttpClient.download(`Themes-${CurrentISODate()}.gz`,
            "/api/v0/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/download/themes/" + deleteList.join(","));
        setDeleteList([]);
        setFormKey(nanoid());
    }

    const handleAction = (tag:string, tagObject: any) => {
        if(tag === "DUPLICATE") {
            HttpClient.get( "/api/v0/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/clone/themes/" +  tagObject.id)
                .then((response) => {
                let id = response.data.response;
                history.push({
                    pathname: `${history.location.pathname}/${id}`,
                    search: history.location.search
                })
                if(!(orgCmdProp && orgCmdProp.currentOrg !== null && orgCmdProp.orgDetail !== null)) return;
                setDataFetcher(new ThemeDataFetcher(orgCmdProp.currentOrg));
                setFormKey(nanoid());
            })
        } else if(tag === "DOWNLOAD") {
            HttpClient.download(
                `Theme-${tagObject.themeName}-${tagObject.id}.gz`,
                "/api/v0/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/download/themes/" +  tagObject.id);
        }
    }


    if (params.tid && themeId !== params.tid) {
        setTimeout(() => {
            setThemeId(params.tid);
        }, 100);
    }

    function resetScreen() {
        history.replace({
            pathname: '/themes',
            search: location.search
        });
        setThemeId("");
        setUpdateData({});
        setFormKey(nanoid());
    }

    useEffect(() => {
        if(!(orgCmdProp && orgCmdProp.currentOrg !== null && orgCmdProp.orgDetail !== null)) return;
        if(themeId !== "") {
            HttpClient.get("/api/v0/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/themes/" + themeId).then((response) => {
                if(response.data.response !== null) {
                    setUpdateData(response.data.response);
                } else {
                    //resetScreen();
                }

            }).catch((e) => {
               // resetScreen();
            })
        }
        setDataFetcher(new ThemeDataFetcher(orgCmdProp.currentOrg));
        setFormKey(nanoid());
    },[themeId,orgCmdProp])

    useEffect(() => {
        if(orgCmdProp === undefined || orgCmdProp?.orgCmds === null || orgCmdProp?.currentOrg === null|| orgCmdProp?.orgCmds.length === 0 || params.tid === undefined) return;
        if(editTheme === false && params.tid) {
            HttpClient.get("/api/v0/jcr/orgs/" + orgCmdProp!.currentOrg!.orgCode + "/themes/" + params.tid).then(response => {
                let data = response.data.response;
                setThemeInfo(data);
                setThemeProps(JSON.parse(data.themeData));
                setEditTheme(true);
            }).catch(() => {
                history.replace({
                    pathname: '/themes',
                    search: history.location.search
                });
            });
        }

    },[orgCmdProp,editTheme,history,params.tid]);

    const newButton = <IconLinkButton tooltip={"Add New Style"} key="newThemeButton" id="newThemeButton" data-action="createNewTheme" variant={"contained"} onClick={handleOpenNewDialog}>
        <OutlinedIcon className={styles.Icon} >add_circle_outline</OutlinedIcon></IconLinkButton>;

    const uploadButton = <IconLinkButton tooltip={"Upload File"} key="uploadThemeButton" id="uploadThemeButton" data-action="uploadNewTheme" variant={"contained"} onClick={handleUploadDialog}>
        <OutlinedIcon className={styles.Icon} >cloud_upload</OutlinedIcon>
    </IconLinkButton>;

    const deleteButton = <ReverseSolidLinkSmallButton variant={"contained"} id="deleteThemeButton" data-action="deleteThemes" onClick={handleDelete}>
        <OutlinedIcon className={styles.Icon} >delete</OutlinedIcon>
        Remove ({deleteList.length})
    </ReverseSolidLinkSmallButton>;

    const downloadButton = <ReverseSolidLinkSmallButton id="downloadThemeButton" data-action="downloadThemes" variant={"contained"} onClick={handleDownloadList}>
        <SolidIcon className={styles.Icon} >download</SolidIcon>Download ({deleteList.length})
    </ReverseSolidLinkSmallButton>;

    const handleCloseEditTheme = () => {
        let path = location.pathname;
        let index = path.lastIndexOf("/");
        history.replace({
            pathname: path.substring(0,index),
            search: location.search
        });
        setEditTheme(false);
    }

    const handleSaveEditTheme = (inserted: boolean) => {
        let element = document.getElementById("idSaveTheme")
        if(element) {
            element.click();
        }
    }

    const handleSaveThemeData = (themeName: string, data:any) => {
        let themeContent = JSON.stringify(data);
        themeInfo.themeData = themeContent;
        let changeName = themeInfo.themeName !== themeName;
        themeInfo.themeName = themeName;
        if(orgCmdProp === undefined || orgCmdProp?.orgCmds === null) return;
        HttpClient.post("/api/v0/jcr/orgs/" + orgCmdProp!.currentOrg!.orgCode + "/full/themes",themeInfo).then((response) => {
            let path = location.pathname;
            let index = path.lastIndexOf("/");

            history.replace({
                pathname: path.substring(0,index),
                search: location.search
            });
            setEditTheme(false);
            if(changeName) {
                if(!(orgCmdProp && orgCmdProp.currentOrg !== null && orgCmdProp.orgDetail !== null)) return;
                setDataFetcher(new ThemeDataFetcher(orgCmdProp.currentOrg));
                setFormKey(nanoid());
            }
        })
    }

    return <React.Fragment>
        <div className={commonStyle.ContentContainer}>
            <PageSummary type={"themes"}/>
            { editTheme && <DefaultDialog
                            id={"theme"}
                            onSave={handleSaveEditTheme}
                            onClose={handleCloseEditTheme}
                            open={editTheme}
                            tag={"adjust_style"}
                            title={`Adjust Styling (${themeInfo.themeName})`}
                            inserted={false}>
                <ThemeEditor  id={params.tid}
                              name={themeInfo.themeName}
                              themePros={themeProps}
                              saveData={handleSaveThemeData}
                              path={"/themes"}
                              type={"Themes"}/>
            </DefaultDialog> }
            { newOpen && <InputDialog
                id={"theme"}
                fixHeight={false}
                title={newOpen? "Add New Style" : "Update Style"}
                handleClose={newOpen ? handleCloseNewDialog : handleCloseUpdateDialog}
                handleSave={newOpen ? handleSaveDialog : handleUpdateDialog}
                open={newOpen || (updateData.id ? true : false)}
                inserted={newOpen}
                fields={[
                    {id: "themeName", label: "Name", defaultValue: updateData.id? updateData.name : "", required: true , editable: true},
                ]}
                layouts={[
                    {label: "Main Info", ids:["themeName"]},
                ]}
                contentText={null}/> }

            <StyledTable key={formKey}

                         handleAction={handleAction}
                         handleDeleteLists={(ids) => setDeleteList(ids)}
                         hasCheckBox={true}
                         dataFetcher={dataFetcher}

                         title={"Styles"}
                         actionComponents={[uploadButton,newButton]}
                         deleteButton={deleteButton}
                         downloadButton={downloadButton}

                         rowsPerPage={20}
                         page={parseInt((query.get("page") === null ? "0" : query.get("page")!))}
                         rowsPerPageOptions={[5,10,20,40,60,{label: "All" , value: -1}]}/>
            <input key={`upload_${formKey}`} type='file' id='upload-content'
                   accept=".gzip,.gz"
                   onChange={() => doUploadFile()}
                   ref={inputFile} style={{display: 'none'}}/>
        </div>

    </React.Fragment>
}