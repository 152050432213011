import SettingProps from "../SettingProps";
// import CSSSetting from "../csssetting/CSSSetting";
import GenericSetting, {StyleSettingType} from "../genericsetting/GenericSetting";

export default function HeightSetting(props: SettingProps) {
    // return <CSSSetting {...props} title={"Dimension"}  type={"dimension"} targets={["max-width" , "max-height" ,"min-width", "min-height","width","height"]}/>
    return <GenericSetting {...props}
                           title={"Height"}
                           type={"height"}
                           targets={[
                                    { name: "Max Height", target: 'max-height' , type: StyleSettingType.default_and_auto_size},
                                    { name: "Min Height", target: 'min-height' , type: StyleSettingType.default_and_auto_size},
                                    { name: "Height", target: 'height' , type: StyleSettingType.default_and_auto_size}
                                ]}
        />
}