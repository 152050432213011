import {SettingGroup, SettingGroupType, SettingOption, SettingType} from "../../../model/SettingOption";


export function DefaultColumnSettingGenerator(col: number) {
    const settingOptions:SettingOption[] = [

    ];

    const settingGroups:SettingGroup[] = [
    ]

    settingOptions.push(
        { type: SettingType.advance_background, group: `bt_ds_col${col}` , groupType: SettingGroupType.style},
        { type: SettingType.box_shadow, group:  `bt_ds_col${col}` , groupType: SettingGroupType.style},
        { type: SettingType.border, group: `bt_ds_col${col}` , groupType: SettingGroupType.style},
        { type: SettingType.border_radius, group:  `bt_ds_col${col}` , groupType: SettingGroupType.style},
        // { type: SettingType.dimension, group:`bt_ds_col${col}`, groupType: SettingGroupType.style},


        { type: SettingType.padding, group:  `bt_ds_col${col}`, groupType: SettingGroupType.advanced},
        { type: SettingType.margin,  group:  `bt_ds_col${col}`, groupType: SettingGroupType.advanced},

    )
    settingGroups.push(
        {group: `bt_ds_col${col}`, groupName: `Column ${col+1}`}
    )

    return {
        settingOptions,
        settingGroups
    }
}

export function DefaultSettingGenerator(columns: number) {
    const settingOptions:SettingOption[] = [
        { title: "", type: SettingType.widget,  editor: "defaultsectionsetting", group: "content", groupType: SettingGroupType.layout},
        { type: SettingType.advance_background, group: "bt_defaultsection" , groupType: SettingGroupType.style},
        // { type: SettingType.dimension, group: "bt_defaultsection", groupType: SettingGroupType.style},
        { type: SettingType.box_shadow, group: "bt_defaultsection" , groupType: SettingGroupType.style},
        { type: SettingType.border, group: "bt_defaultsection" , groupType: SettingGroupType.style},
        { type: SettingType.border_radius, group: "bt_defaultsection" , groupType: SettingGroupType.style},
        { type: SettingType.margin,  group:  `bt_defaultsection`, groupType: SettingGroupType.advanced},
        { type: SettingType.padding, group: "bt_defaultsection", groupType: SettingGroupType.advanced},
    ];

    const settingGroups:SettingGroup[] = [
        {group: "content", groupName: "General"},
        {group: "bt_defaultsection", groupName: "General"}
    ]

    // for(let col = 1;col <= columns;col++) { // start col from 1
    //     settingOptions.push(
    //         { type: SettingType.advance_background, group: `bt_ds_col${col}` , groupType: SettingGroupType.style},
    //         { type: SettingType.border, group: `bt_ds_col${col}` , groupType: SettingGroupType.style},
    //         { type: SettingType.dimension, group:`bt_ds_col${col}`, groupType: SettingGroupType.style},
    //         { type: SettingType.border_radius, group:  `bt_ds_col${col}` , groupType: SettingGroupType.style},
    //         { type: SettingType.box_shadow, group:  `bt_ds_col${col}` , groupType: SettingGroupType.style},
    //         { type: SettingType.padding, group:  `bt_ds_col${col}`, groupType: SettingGroupType.advanced},
    //         { type: SettingType.margin,  group:  `bt_ds_col${col}`, groupType: SettingGroupType.advanced},
    //     )
    //     settingGroups.push(
    //         {group: `bt_ds_col${col}`, groupName: `Column ${col}`}
    //     )
    // }

    return {
        settingOptions,
        settingGroups
    }
}