import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { createTheme, ThemeProvider,  StyledEngineProvider } from "@mui/material/styles";
import {Provider} from "react-redux";
import { store } from './redux/store';

//
// const theme = createTheme({
//     palette: {
//         primary: {
//             // main: "#003035",
//             main:"#008658",
//             // main: "#000000",
//         },
//     },
//     typography: {
//         fontFamily: 'Inter'//'Fira Sans',//Roboto',
//     },
//     components: {
//         MuiMenuItem: {
//             styleOverrides: {
//                 root: {
//                     border: "1px solid transparent",
//                     borderRadius: "6px",
//                     backgroundColor: "transparent",
//                     textTransform: "none",
//                     color: "#444446",
//                     "&:hover": {
//                         transition: "background-color .3s,border-color .3s,box-shadow .3s,color .3s",
//                         // main: "#00764a",
//                         main: "var(--standard-spruce-500)",//"#005693",
//                         backgroundColor: "#fff",
//                         borderColor: "var(--bt-cms-color-primary)",
//                         boxShadow: "0 1px 2.5px 0 rgb(0 0 0 / 26%), 0 1px 5px 0 rgb(0 0 0 / 16%)",
//                         textTransform: "none",
//                     }
//                 }
//             }
//         },
//         MuiLink: {
//             styleOverrides: {
//                 root: {
//                     textDecoration: "none",
//                     "&:hover": {
//                         textDecoration: "underline",
//                         color: "var(--standard-jade-500)",
//                     }
//                 }
//             }
//         },
//         MuiDialogTitle: {
//             styleOverrides: {
//                 root: {
//                     backgroundColor: "#F4F4F4",
//                 }
//             }
//         },
//         MuiDialogActions: {
//           styleOverrides: {
//               root: {
//                   borderBlockStart: "1px solid #0000001F",
//                   height: "50px",
//               }
//           }
//         },
//         MuiTab: {
//             styleOverrides: {
//                 root: {
//                     textTransform: "none"
//                 }
//             }
//         },
//         MuiIcon: {
//             styleOverrides: {
//                 root: {
//                     color: "inherit"
//                 }
//             }
//         },
//         MuiOutlinedInput: {
//             styleOverrides: {
//                 root: {
//                     paddingRight: "2px",
//                     paddingLeft: "2px"
//                 }
//             }
//         },
//         MuiCheckbox: {
//             styleOverrides: {
//                 root: {
//                     color: "var(--bt-cms-color-primary-normal)",
//                     "&$checked": {
//                         color: "var(--bt-cms-color-primary-normal)"
//                     }
//                 },
//             }
//         },
//         MuiTooltip: {
//             styleOverrides: {
//                 tooltip: {
//                     fontSize: "13px",
//                     color: "white",
//                     height: "20px",
//                     lineHeight: "20px",
//                     backgroundColor: "var(--standard-spruce-500)",
//
//                 },
//                 arrow: {
//                     color: "var(--standard-spruce-500)",
//                 }
//             }
//         },
//         MuiButton: {
//             styleOverrides: {
//                 root: {
//                     backgroundColor: "var(--bt-cms-color-primary)",
//                     color: "white",
//                     "&:hover": {
//                         backgroundColor: "var(--bt-cms-color-primary-dark)",
//                     },
//                     "&:disabled": {
//                         color: 'rgba(255,255,255,0.5)'
//                     }
//                 },
//                 contained: {
//                     backgroundColor: "var(--bt-cms-color-primary)",
//                     color: "white",
//                     "&:hover": {
//                         backgroundColor: "var(--bt-cms-color-primary-dark)",
//                     },
//                 },
//                 textPrimary: {
//                     backgroundColor: "var(--bt-cms-color-primary)",
//                     color: "white",
//                     "&:hover": {
//                         color: "white",
//                         backgroundColor: "var(--bt-cms-color-sidebar-active-dark)",
//                     },
//                     "&:disabled": {
//                         backgroundColor: "gray"
//                     }
//                 },
//                 textSecondary: {
//                     backgroundColor: "var(--bt-cms-color-secondary)",
//                     color: "white",
//                     "&:hover": {
//                         color: "white",
//                         backgroundColor: "var(--bt-cms-color-secondary-dark)",
//                     }
//                 },
//                 containedPrimary: {
//                     backgroundColor: "var(--bt-cms-color-primary)",
//                     color: "white",
//                     "&:hover": {
//                         color: "white",
//                         backgroundColor: "var(--bt-cms-color-primary-dark)",
//                     }
//                 }
//             }
//         }
//     },
// });

ReactDOM.render(
    <React.StrictMode>
        {/*<StyledEngineProvider injectFirst>*/}
            <Provider store={store}>
                {/*<ThemeProvider theme={theme}>*/}
                <App />
                {/*</ThemeProvider>*/}
            </Provider>
        {/*</StyledEngineProvider>*/}
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
