import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import loginReducer from './features/login/loginSlice';
import viewReducer from './features/view/viewSlice';
import cmsRouteReducer from './features/cmsroute/cmdRouteSlice';
import themeReducer from './features/theme/themeSlice';

export const store = configureStore({
    reducer: {
        loginReducerState: loginReducer,
        viewReducerState: viewReducer,
        cmsRouteReducerState: cmsRouteReducer,
        themeReducerState: themeReducer
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
    >;