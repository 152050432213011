import {Grid, MenuItem, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import MerchandisingGroupCmd from "../../../../model/lsi/MerchandisingGroupCmd";
import {SearchProfileCmd} from "../../../../model/lsi/SearchProfileCmd";
import MerchandisingItemCmd from "../../../../model/lsi/MerchandisingItemCmd";
import {useOrganization} from "../../../../hook/useOrganization";
import {lsi} from "../../../../datafetcher/LSIDataFetcher";
import {parseInt} from "lodash";
import {SettingLabel} from "../settinglabel/SettingLabel";

export default function ProductSearchSetting(props: {widgetData: any,
                                                        saveWidgetState: () => void ,
                                                        widgetCallback: (action: string, value: any) => void,
                                                        // setProducts: (detail: {[key: string] : any}[]) => void
                            }) {

    const organization = useOrganization();
    const {widgetData} = props;
    const [merchGroup, setMerchGroup] = useState<string>(widgetData.widgetProps['content']['merchGroup'] ? widgetData.widgetProps['content']['merchGroup'] : "");
    const [merchGroupItems, setMerchGroupItems] = useState<MerchandisingGroupCmd[]>([]);
    const [merchItem, setMerchItem] = useState<string>(widgetData.widgetProps['content']['searchString'] ? widgetData.widgetProps['content']['searchString'] : "");
    const [searchProfile, setSearchProfile] = useState<string>(widgetData.widgetProps['content']['searchProfile'] ? widgetData.widgetProps['content']['searchProfile'] : "_lsi_Default_");
    const [searchProfiles, setSearchProfiles] = useState<SearchProfileCmd[]>([]);
    const [merchItems, setMerchItems] = useState<MerchandisingItemCmd[]>([]);
    const [searchType, setSearchType] = useState<string>(widgetData.widgetProps['content']['searchType'] ? widgetData.widgetProps['content']['searchType'] : "BY_SEARCH");
    const [searchString, setSearchString] = useState<string>(widgetData.widgetProps['content']['searchString'] ? widgetData.widgetProps['content']['searchString'] : "");
    const [maxItems, setMaxItems] = useState<number>(widgetData.widgetProps['content']['maxItems'] ? widgetData.widgetProps['content']['maxItems'] : 5);
    const [start, setStart] = useState<number>(widgetData.widgetProps['content']['start'] ? widgetData.widgetProps['content']['start'] : 0);

    const handleChange = (value: string, key: string) => {
        widgetData.widgetProps["content"][key] = value;
        props.saveWidgetState();
    }

    const loadMerchItem = (mergeName: string) => {
        if(mergeName !== "") {
            lsi.search.query(`_lsi_collection_names_:("${mergeName}")`,start,maxItems,searchProfile).then((response) => {
                // props.setProducts(response.data.response.docs);
                props.widgetCallback("products",response.data.response.docs);
            });
        }
    }

    // const loadByKeywords = (string: string) => {
    //
    // }
    //
    // const loadSearchString = (search: string) => {
    //     if(search) {
    //         lsi.search.query(search,start,maxItems, searchProfile).then((response) => {
    //             props.setProducts(response.data.response.docs);
    //         });
    //     }
    // }

    const onChangeSearch = (searchType: string, searchString:string, start: number, rows: number, searchProfile: string) => {
        setSearchType(searchType);
        widgetData.widgetQuery = JSON.stringify({
            searchType: searchType,
            searchString: searchString,
            start: start,
            rows: rows,
            searchProfile:searchProfile
        })

        lsi.search.widgetSearch(searchType,searchString,start,rows,searchProfile)?.then((response) => {
            //props.setProducts(response.data.response.docs);
            props.widgetCallback("products",response.data.response.docs);
        });

        // if(searchType === "BY_IDS") {
        //
        //     widgetData.widgetQuery = JSON.stringify({
        //         searchType: searchType,
        //         searchString: searchString,
        //         start: start,
        //         rows: rows,
        //         searchProfile:searchProfile
        //     })
        //     loadSearchString(searchString);
        // } else if(searchType === "BY_MRG") {
        //
        //     widgetData.widgetQuery = JSON.stringify({
        //         searchType: searchType,
        //         searchString: searchString,
        //         start: start,
        //         rows: rows,
        //         searchProfile:searchProfile
        //     })
        //     loadMerchItem(searchString);
        // }  else {
        //     widgetData.widgetQuery = null;
        // }
    }

    const loadMerchandising = () => {
        if(organization !== null) {
            lsi.collection?.collectionTypes().then((response) => {
                let mGroups: MerchandisingGroupCmd[] = response.data as MerchandisingGroupCmd[];
                setMerchGroupItems(mGroups);
                if(merchGroup === "") {
                    setMerchGroup("DrillDown");
                }
            })
        }
    }

    const loadMerchandingGroup = (type: string) => {
        if(organization !== null) {
            lsi.collection.collectionsByType(type).then((response) => {
                let mItems: MerchandisingItemCmd[] = response.data.collections as MerchandisingItemCmd[];
                setMerchItems(mItems);
                if(mItems.length > 0) {
                    let found = false;
                    for(let i=0;i<mItems.length;i++) {
                        if(mItems[i].name === merchItem) {
                            found = true;
                        }
                    }
                    if(found === false) {
                        setMerchItem(mItems[0].name);
                    }
                }
            })
        }
    }

    const loadSearchProfile = () => {
        if(organization !== null) {
            lsi.search.searchProfiles().then((response) => {
                let data: any[] = [];
                let defaultProfile:string = "";
                data.push({id: "", name: "None"});
                response.data.forEach((searchProfile,index: number) => {
                    if(searchProfile.defaultProfile) {
                        defaultProfile = searchProfile.name;
                    }
                    data.push({id: searchProfile.id , name: searchProfile.name});
                })
                setSearchProfiles(data);
                setSearchProfile(defaultProfile);
            })
        }
    }

    const handleChangeSearchType = (searchType: string) => {
        widgetData.widgetProps["content"]['searchType'] = searchType;
        props.saveWidgetState();
        setSearchType(searchType);
    }

    useEffect(() => {
        if(searchType !== "BY_MRG") {
            onChangeSearch(searchType,searchString,start,maxItems,searchProfile);
        }
    },[searchString,start,maxItems,searchProfile])

    useEffect(() => {
        if(merchGroup !== "") {
            setMerchItems([]);
            loadMerchandingGroup(merchGroup);
        }
    },[merchGroup]);

    useEffect(() => {
        if(searchType === "BY_MRG") {
            if (merchItem !== "") {
                handleChange(merchItem.toString(), "searchString")
                onChangeSearch("BY_MRG", merchItem.toString(), start, maxItems, searchProfile);
            }
        }
    },[merchItem,start,maxItems,searchProfile])

    useEffect(() => {
        if(searchType === "BY_MRG") {
            loadMerchandising();
        } else {
            setSearchString(searchString);
            // onChangeSearch(searchType,searchString,start,maxItems,searchProfileId);
        }
    },[searchType]);

    useEffect(() => {
        loadSearchProfile();
    },[organization]);

    // useEffect(() => {
    //     handleChangeSearchType(widgetProps['content']['searchType'],
    //         widgetProps['content']['searchString'],
    //         widgetProps['content']['start'],
    //         widgetProps['content']['maxItems'])
    // },[]);

    return <>
        <Grid item xs={4}><SettingLabel title={"Search Type"}/></Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                       fullWidth
                       select={true}
                       id={"search_type"}
                       label={""}
                       value={searchType}
                       onChange={(event) => handleChangeSearchType(event.target.value)}
            >
                <MenuItem value={"BY_SEARCH"}>General Search</MenuItem>
                <MenuItem value={"BY_IDS"}>Part Numbers</MenuItem>
                <MenuItem value={"BY_MRG"}>Merchandising Group</MenuItem>
                <MenuItem value={"BY_KEYWORD"}>Keywords</MenuItem>
                <MenuItem value={"BY_FAMILY"}>Family</MenuItem>
                <MenuItem value={"BY_SIMILAR"}>Similar Products</MenuItem>
                <MenuItem value={"BY_FBT"}>FBT Products</MenuItem>
            </TextField>
        </Grid>
        {
            searchType === "BY_MRG" && <>
                <Grid item xs={4}><SettingLabel title={"Name"}/></Grid>
                <Grid item xs={8}>

                    <TextField size={"small"}
                               fullWidth
                               select={true}
                               id={"merchGroup"}
                               label={""}
                               value={merchGroup}
                               onChange={(event) => setMerchGroup(event.target.value)}
                    >
                        {
                            merchGroupItems.length > 0 && merchGroupItems.map((item) => <MenuItem key={"MG_" + item.id} value={item.name}>{item.name}</MenuItem>)
                        }
                    </TextField>
                </Grid>
                <Grid item xs={4}><SettingLabel title={"Item Name"}/></Grid>
                <Grid item xs={8}>
                    {merchItem !== "" && merchItems.length > 0 && <TextField size={"small"}
                                                                             fullWidth
                                                                             select={true}
                                                                             id={"merch_item"}
                                                                             label={""}
                                                                             value={merchItem === "" ? "" : merchItem}
                                                                             onChange={(event) => {
                                                                                setMerchItem(event.target.value);
                                                                            }}
                        >
                            {
                                merchItems.length > 0 && merchItems.map((item) => <MenuItem key={"MI_" + item.id}
                                                                                            value={item.name}>{item.displayName}</MenuItem>)
                            }
                        </TextField>
                    }
                    {
                        (merchItem === "" || merchItems.length === 0) && <TextField size={"small"}
                                                                                  fullWidth={true}
                                                                                  label={""}
                                                                                  value={"--"}
                                                                                  contentEditable={false}
                                                                                  onChange={(event) => {
                                                                                      setSearchString(event.target.value);
                                                                                      handleChange(event.target.value, "searchString")
                                                                                  }}/>
                    }
                </Grid>
            </>
        }
        { searchType !== "BY_MRG" &&  <>
            <Grid item xs={4}><SettingLabel title={"Search String"}/></Grid>
            <Grid item xs={8}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={""}
                       value={searchString}
                       onChange={(event) => {
                           setSearchString(event.target.value);
                           handleChange(event.target.value, "searchString")
                       }}/>
        </Grid></>
        }
        <Grid item xs={4}><SettingLabel title={"Search Profile"}/></Grid>
        <Grid item xs={8}>

                <TextField size={"small"} fullWidth
                           select={true}
                        id={"search_profile"}
                        label={""}
                        value={searchProfile}
                        onChange={(event) => {
                            setSearchProfile(event.target.value);
                        }}
                >
                    {
                        searchProfiles.length > 0 && searchProfiles.map((item) => <MenuItem key={"SP_" + item.id}
                                                                                            value={item.name}>&nbsp;{item.name}&nbsp;</MenuItem>)
                    }
                </TextField>
        </Grid>
        <Grid item xs={4}><SettingLabel title={"Start"}/></Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={""}
                       value={start}
                       onChange={(event) => {
                           handleChange(event.target.value.trim(), "start")
                           let start = parseInt(event.target.value);
                           if(isNaN(start)) return;
                           setStart(start);
                       }}/>
        </Grid>
        <Grid item xs={4}><SettingLabel title={"Number of items"}/></Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={""}
                       value={maxItems}
                       onChange={(event) => {
                           handleChange(event.target.value.trim(), "maxItems")
                           let rows = parseInt(event.target.value);
                           if(isNaN(rows)) return;
                           setMaxItems(rows);
                       }}/>
        </Grid>
    </>
}