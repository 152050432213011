
import React, {useState} from "react";
import {useOrganization} from "../../hook/useOrganization";
import {Dialog, DialogContent, DialogTitle, IconButton} from "@mui/material";
import PublishSteps from "../publish/PublishSteps";
import HttpClient from "../../datafetcher/HttpClient";
import styles from "./BuildButton.module.scss";
import OutlinedIcon from "../icon/OutlinedIcon";
import TopIconButton from "./TopIconButton";

export default function BuildButton() {
    const orgCmd = useOrganization();
    const [enabled, setEnabled] = useState<boolean>(true);
    const [showPublish, setShowPublish] = useState<boolean>(false);
    const onHandleBuild = (event: React.MouseEvent<HTMLElement>) => {
        setEnabled(false)
        HttpClient.get("/api/jcr/orgs/" + orgCmd?.currentOrg?.orgCode + "/buildstatus").then((response) => {
            if(response.data.response.running === false) {
                setShowPublish(true);
            }
            setEnabled(true);
        }).catch(error => {
            setEnabled(true);
        });
    }
    if(!orgCmd?.orgDetail?.deployments || orgCmd?.orgDetail?.deployments.length === 0)
        return <></>
    return  <>
        {/*<SolidLinkButton id="buildButton" data-action={"doBuild"} disabled={!enabled} variant={"contained"} onClick={onHandleBuild}>*/}
        {/*    <OutlinedIcon sx={{paddingInlineEnd: "0.5rem"}}>auto_fix_high</OutlinedIcon>Publish*/}
        {/*</SolidLinkButton>*/}
        <TopIconButton tooltipTitle="Publish" id="buildButton" data-action={"doBuild"} disabled={!enabled} onClick={onHandleBuild}>
            <OutlinedIcon>auto_fix_high</OutlinedIcon>
        </TopIconButton>
        <Dialog
            open={showPublish}
            onClose={() => {setShowPublish(false)}}
            scroll={"paper"}
            fullWidth={true}
            maxWidth={"sm"}
        >
            <DialogTitle>Publish
                <IconButton className={styles.CloseIcon}
                            onClick={() => { setShowPublish(false) }}>
                    <OutlinedIcon>close</OutlinedIcon>
                </IconButton>
            </DialogTitle>
            <DialogContent dividers={true} className={styles.DialogContent}>
                <PublishSteps deployments={orgCmd && orgCmd.orgDetail ? orgCmd.orgDetail.deployments : ["dev"]} orgCode={orgCmd && orgCmd.orgDetail ? orgCmd.orgDetail.orgCode : ""} onClose={() => {setShowPublish(false)}}/>
            </DialogContent>
        </Dialog>
    </>
}