import React, {useCallback, useEffect, useRef, useState} from 'react';
import styles from "./PageTemplate.module.scss";
import commonStyle from "../../config/Common.module.scss";
import StyledTable from "../table/StyledTable";
import {useHistory} from "react-router";
import {useParams} from "react-router-dom";
import HttpClient from "../../datafetcher/HttpClient";
import {nanoid} from "nanoid";
import {useOrganization} from "../../hook/useOrganization";
import OutlinedIcon from "../icon/OutlinedIcon";
import IconLinkButton from "../button/IconLinkButton";
import ReverseSolidLinkSmallButton from '../button/ReverseSolidLinkSmallButton';
import PageSummary from '../pagesummary/PageSummary';
import {jcr} from "../../datafetcher/JCRDataFetcher";
import {CurrentISODate} from "../../util/DateUtil";
import SectionTemplateDialog from "./SectionTemplateDialog";
import {TemplateCmd} from "../../model/TemplateCmd";
import {SectionTemplateDataFetcher} from "../../datafetcher/SectionTemplateDataFetcher";

export default function SectionTemplate() {
    const  orgCmdProp = useOrganization();
    const history = useHistory();
    const query = new URLSearchParams(history.location.search);
    const params: any = useParams();
    const [updateData, setUpdateData] = useState<TemplateCmd>();
    const [templateId, setTemplateId] = useState("");
    const [formKey , setFormKey] = useState("");
    const [dataFetcher, setDataFetcher] = useState<SectionTemplateDataFetcher|null>(null);
    const [deleteList,setDeleteList] = useState<string[]>([]);
    const inputFile = useRef<HTMLInputElement>(null);

    const handleUploadDialog = () => {
        if(inputFile !== null && inputFile.current !== null) {
            inputFile.current.click();
        }
    }

    const doUploadFile = () => {
        if(inputFile && inputFile.current) {
            if(inputFile.current.files) {
                jcr.sectionTemplate.upload(inputFile.current.files).then((response) => {
                    if(!(orgCmdProp && orgCmdProp.currentOrg !== null && orgCmdProp.orgDetail !== null)) return;
                    setDataFetcher(new SectionTemplateDataFetcher(orgCmdProp.currentOrg));
                    setFormKey(nanoid());
                })
            }
        }
    }

    const handleCloseUpdateDialog = () => {
        setUpdateData(undefined);
        setTemplateId("");
        history.push({
            pathname: '/sectiontemplate',
            search: history.location.search});
    }

    const handleUpdateDialog = () => {

        dataFetcher!.load().then((xxxx) => {
            resetScreen();
        });

    }


    const handleDelete = () => {

        if(window.confirm("Are you sure to remove?")) {
            HttpClient.delete("/api/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/template/sections/" + deleteList.join(",")).then((response) => {
                resetScreen();
                setDeleteList([]);
            }).catch((e) => {
                setDeleteList([]);
            })
        }
    }

    const handleDownloadList = () => {
        HttpClient.download(`SectionTemplate-${CurrentISODate()}.gz`,
            "/api/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/template/download/sections/" + deleteList.join(","));
        setDeleteList([]);
        setFormKey(nanoid());
    }

    const handleAction = (tag:string, tagObject: any) => {
        if(tag === "DUPLICATE") {
            HttpClient.get( "/api/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/template/clone/sections/" +  tagObject.id)
                .then((response) => {
                    let id = response.data.response;
                    history.push({
                        pathname: `${history.location.pathname}/${id}`,
                        search: history.location.search
                    })
                    if(!(orgCmdProp && orgCmdProp.currentOrg !== null && orgCmdProp.orgDetail !== null)) return;
                    setDataFetcher(new SectionTemplateDataFetcher(orgCmdProp.currentOrg));
                    setFormKey(nanoid());
                })
        } else if(tag === "DOWNLOAD") {
            HttpClient.download(
                `SectionTemplate-${tagObject.title}-${tagObject.id}.gz`,
                "/api/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/template/download/sections/" +  tagObject.id);
        }
    }



    if (params.evid && templateId !== params.evid) {
        setTimeout(() => {
            setTemplateId(params.evid);
        }, 100);
    }

    const resetScreen = useCallback(() => {
        history.replace({
            pathname: '/sectiontemplate',
            search: history.location.search
        });
        setTemplateId("");
        setUpdateData(undefined);
        setFormKey(nanoid());
    },[history])

    useEffect(() => {
        if(templateId !== "" &&  orgCmdProp && orgCmdProp.currentOrg !== null) {
            ///api/jcr/orgs/{orgCode}/template/{generic}s/{genericId}
            HttpClient.get("/api/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/template/sections/" + templateId).then((response) => {
                if(response.data.response !== null) {
                    setUpdateData(response.data.response);
                } else {
                    resetScreen();
                }
            }).catch((e) => {
                resetScreen();
            })
        }

    },[templateId,orgCmdProp,resetScreen])


    useEffect(() => {
        if(!(orgCmdProp && orgCmdProp.currentOrg !== null && orgCmdProp.orgDetail !== null)) return;
        setDataFetcher(new SectionTemplateDataFetcher(orgCmdProp.currentOrg));
        setFormKey(nanoid());


    }, [orgCmdProp]);


    const uploadButton = <IconLinkButton tooltip={"Upload File"} key="uploadTemplateButton" id="uploadTemplatetButton" data-action="uploadNewTemplate" variant={"contained"} onClick={handleUploadDialog}>
        <OutlinedIcon className={styles.Icon} >cloud_upload</OutlinedIcon>
    </IconLinkButton>;

    const deleteButton = <ReverseSolidLinkSmallButton id="deleteTemplateButton" data-action="deleteTemplates" variant={"contained"} onClick={handleDelete}>
        <OutlinedIcon className={styles.Icon} >delete</OutlinedIcon>
        Remove ({deleteList.length})
    </ReverseSolidLinkSmallButton>

    const downloadButton = <ReverseSolidLinkSmallButton id="downloadTemplateButton" data-action="downloadTemplates" variant={"contained"} onClick={handleDownloadList}>
        <OutlinedIcon className={styles.Icon} >download</OutlinedIcon>Download ({deleteList.length})
    </ReverseSolidLinkSmallButton>;

    return <React.Fragment>
        {updateData && <SectionTemplateDialog allowOrgs={updateData.allowOrgs}
                                              header={"Update Section Template"}
                                              title={updateData.title}
                                              subTitle={updateData.subTitle}
                                              viewData={updateData.viewCaches[0]}
                                              captureData={""}
                                              id={updateData.id}
                                              inserted={false}
                                              active={updateData.active}
                                              internalComment={updateData.internalComment}
                                              logo={updateData.logo}
                                              open={true}
                                              tags1={updateData.tags1}
                                              tags2={updateData.tags2}
                                              onClose={(value) => {
                                               if(value) {
                                                   handleUpdateDialog();
                                               } else {
                                                   handleCloseUpdateDialog();
                                               }
                                           }}
         />}
        <div className={commonStyle.ContentContainer + " " + commonStyle.ContentContainerOverflowX}>
            <PageSummary type={"section Template"}/>
            <StyledTable key={formKey}

                         handleAction={handleAction}
                         handleDeleteLists={(ids) => setDeleteList(ids)}
                         hasCheckBox={true}
                         dataFetcher={dataFetcher}

                         title={"Template"}
                         actionComponents={[uploadButton]}
                         deleteButton={deleteButton}
                         downloadButton={downloadButton}

                         rowsPerPage={20}
                         page={parseInt((query.get("page") === null ? "0" : query.get("page")!))}
                         rowsPerPageOptions={[5,10,20,40,60,{label: "All" , value: -1}]}/>
            <input key={`upload_${formKey}`} type='file' id='upload-content'
                   accept=".gzip,.gz"
                   onChange={() => doUploadFile()}
                   ref={inputFile} style={{display: 'none'}}/>
        </div>

    </React.Fragment>
}