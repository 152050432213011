import {
    Box,
    List,
    ListItem,
    ListItemButton,
    Popover, PopoverProps,
} from "@mui/material";
import {useState} from "react";
import StorefrontCmd from "../../model/jcr/StorefrontCmd";

let globalTimeout:NodeJS.Timeout|null = null;

export default function StorefrontPopover(props: { popoverProps: PopoverProps,
                                                    storefronts: StorefrontCmd[]
                                                    onChangeStorefront: (selected: StorefrontCmd) => void
                                                    onBlur: (event: React.FocusEvent) => void
                                                }) {

    const [selectedStorefront, setSelectedStorefront] = useState<StorefrontCmd|null>(null);


    const handleOnClickStorefront = (storefront: StorefrontCmd) => {
        if(globalTimeout !== null) {
            clearTimeout(globalTimeout);
            globalTimeout = null;
        }
        let newStorefront = {...storefront};
        setSelectedStorefront(newStorefront);
        if(props.onChangeStorefront) {
            props.onChangeStorefront(newStorefront);
        }
    }

    const handleBlur = (event: React.FocusEvent) => {
        if(props.onBlur) {
            props.onBlur(event);
        }
        event.stopPropagation();
        event.preventDefault();
    }

    return <Popover
            {...props.popoverProps}
        >
            <Box sx={{boxShadow: 3, padding: 0, maxWidth: "250px"}} style={{pointerEvents: "auto" , padding: "0px"}}>
                <List dense sx={{maxHeight: "250px",
                    overflowY: "auto"}}>
                    {
                        props.storefronts && props.storefronts.map((storefront,index) => {
                                let selected = false;
                                if(selectedStorefront === null && index === 0) {
                                    selected = true;
                                } else {
                                    if(selectedStorefront !== null) {
                                        selected = storefront.id === selectedStorefront.id;
                                    }
                                }
                                return <ListItem autoFocus={index === 0}
                                          disablePadding
                                          tabIndex={-1}
                                          onBlur={handleBlur}
                                          key={"current_storefront_" + index}
                                          value={storefront.id}
                                          onClick={() => handleOnClickStorefront(storefront)}
                                ><ListItemButton selected={selected} sx={ selected ? { color: "var(--standard-jade-600)" , fontWeight: "bold"} : undefined}>{storefront.sfName}</ListItemButton></ListItem>
                            }
                        )
                    }
                </List>
            </Box>
        </Popover>

}