import { Grid,  TextField} from "@mui/material";
import React from "react";
import {SettingLabel} from "../../setting/settinglabel/SettingLabel";

export default function JumbotronSetting(props: { widgetData: any, saveWidgetState: () => void }) {
    let {widgetData} = props;

    const handleChange = (value: any, key: string) => {
        widgetData.widgetProps["content"][key] = value;
        props.saveWidgetState();
    }

    return <Grid container spacing={2}>
        <Grid item xs={4}>
            <SettingLabel title={"Background URL"}/>
        </Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={""}
                       defaultValue={widgetData.widgetProps['content']['image_url']}
                       onChange={(event) => {
                           handleChange(event.target.value, "image_url")
                       }}/>
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"Title"}/>
        </Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={""}
                       defaultValue={widgetData.widgetProps['content']['title']}
                       multiline
                       rows={2}
                       onChange={(event) => {
                           handleChange(event.target.value, "title")
                       }}/>
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"Description"}/>
        </Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={""}
                       multiline
                       rows={4}
                       defaultValue={widgetData.widgetProps['content']['desc']}
                       onChange={(event) => {
                           handleChange(event.target.value, "desc")
                       }}/>
        </Grid>

    </Grid>
}