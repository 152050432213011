
import UploadContext, {UploadProgress} from "./UploadContext";
import {useState} from "react";

const uploadList:{[key: string] : UploadProgress} = {};
export function UploadProvider(props: {children: any}) {
    const appendUpload = (id:string, title:string ) => {
        uploadList[id] = {title, loaded: 0, total: 0};
        setUploadList({...uploadList});
        let func = (event:ProgressEvent) => {
            if(event.loaded !== 0 && event.total === event.loaded) {
                delete uploadList[id];
            } else {
                uploadList[id] = { title: uploadList[id].title, loaded: event.loaded , total: event.total };
            }
            setUploadList({...uploadList});
        }
        return func;
    }

    const [stateUploadList,setUploadList] = useState<{[key: string] : UploadProgress}>({});

    return <UploadContext.Provider value={{
        uploadList: stateUploadList,
        appendUpload
    }}>
            {props.children}
        </UploadContext.Provider>
}