import {Grid, MenuItem, TextField} from "@mui/material";
import React, {useState} from "react";
import CMSMenuItem from "../../shared/settings/menu/CMSMenuItem";
import styles from './HeaderSetting.module.scss';
import MenuSetting from "../../shared/settings/menu/MenuSetting";
import AssetField from "../../../../ui/assetfield/AssetField";
import {InputOption} from "../../../../model/InputModel";
import {SettingLabel} from "../../setting/settinglabel/SettingLabel";
import YesNoSwitch from "../../setting/onoffswitch/YesNoSwitch";

export default function HeaderSetting(props: { widgetData: any,
                                                saveWidgetState: () => void
                                                drillDownOptions?:InputOption[]}) {
    let {widgetData} = props;
    const [hasProductMenu, setHasProductMenu] = useState<boolean>(widgetData.widgetProps["content"] && widgetData.widgetProps["content"]['hasProductMenu']);
    const [hasMfrMenu, setHasMfrMenu] = useState<boolean>(widgetData.widgetProps["content"] && widgetData.widgetProps["content"]['hasMfrMenu']);
    const [hasSearch, setHasSearch] = useState<boolean>(widgetData.widgetProps["content"] && widgetData.widgetProps["content"]['hasSearch']);
    const [hasShopcart, setHasShopcart] = useState<boolean>(widgetData.widgetProps["content"] && widgetData.widgetProps["content"]['hasShopcart']);
    const [hasLogin,setHasLogin] = useState<boolean>(widgetData.widgetProps["content"] && widgetData.widgetProps["content"]['hasLogin']);
    const drillDownOptions = props.drillDownOptions;

    const handleChange = (value: any, key: string) => {
        widgetData.widgetProps["content"][key] = value;
        props.saveWidgetState();
    }

    const handleSearch = (value: any, key: string) => {
        widgetData.widgetProps["search"][key] = value;
        props.saveWidgetState();
    }

    const handleChangeMfrDrilldown = (value:any) => {
        widgetData.widgetProps['content']['mfr_drilldown'] = value;
        props.saveWidgetState();
    }

    const handleChangeProductDrilldown = (value:any) => {
        widgetData.widgetProps['content']['product_drilldown'] = value;
        props.saveWidgetState();
    }

    
    const onMainMenuChange = (topMenu: CMSMenuItem[]) => {
        if(!props.widgetData.widgetProps['content']) {
            props.widgetData.widgetProps['content'] = {};
        }
        props.widgetData.widgetProps['content']['addedMainMenus'] = topMenu
        props.saveWidgetState();
    }

    return <Grid container spacing={2}>
        <Grid item xs={4}>
            <SettingLabel title={"Logo URL"}/>
        </Grid>
        <Grid item xs={8}>
            <AssetField size={"small"}
                        fullWidth={true}
                        label={""}
                        defaultValue={widgetData.widgetProps['content']['logo_url']}
                        onChange={(event) => {
                           handleChange(event.target.value, "logo_url")
                           event.preventDefault();
                           event.stopPropagation();
                           props.saveWidgetState();
                        }}/>
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"Has Search"}/>
        </Grid>
        <Grid item xs={8}>
            <YesNoSwitch defaultValue={widgetData.widgetProps['content']['hasSearch']} onChange={(value) => {
                handleChange(value, "hasSearch")
                setHasSearch(value);
            }}/>
        </Grid>

        {hasSearch && <>
            <Grid item xs={4}>
                <SettingLabel title={"Placeholder"}/>
            </Grid>
            <Grid item xs={8}>
                <TextField size={"small"}
                           fullWidth={true}
                           label={""}
                           defaultValue={widgetData.widgetProps['content']['search_placeholder']}
                           onChange={(event) => {
                               handleChange(event.target.value, "search_placeholder")
                               event.preventDefault();
                               props.saveWidgetState();
                           }}/>
            </Grid>
            <Grid item xs={4}>
                <SettingLabel title={"Has Barcode"}/>
            </Grid>
            <Grid item xs={8}>
                <YesNoSwitch defaultValue={widgetData.widgetProps['content']['hasBarcode']} onChange={(value) => {
                    handleChange(value, "hasBarcode")
                    setHasSearch(value);
                }}/>
            </Grid>
        </>}

        <Grid item xs={4}>
            <SettingLabel title={"Has Shopping cart"}/>
        </Grid>
        <Grid item xs={8}>
            <YesNoSwitch defaultValue={widgetData.widgetProps['content']['hasShopcart']} onChange={(value) => {
                handleChange(value, "hasShopcart")
                setHasShopcart(value);
            }}/>
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"Has Login"}/>
        </Grid>
        <Grid item xs={8}>
            <YesNoSwitch defaultValue={widgetData.widgetProps['content']['hasLogin']} onChange={(value) => {
                handleChange(value, "hasLogin")
                setHasLogin(value);
            }}/>
        </Grid>

        {hasLogin && <>
        <Grid item xs={4}>
            <SettingLabel title={"New Customer"}/>
        </Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
               fullWidth={true}
               label={""}
               defaultValue={widgetData.widgetProps['content']['new_customer_label'] ? widgetData.widgetProps['content']['new_customer_label'] : "New Customer?"}
               onChange={(event) => {
                   handleChange(event.target.value, "new_customer_label")
                   event.preventDefault();
                   props.saveWidgetState();
               }}/>
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"New Customer URL"}/>
        </Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={""}
                       defaultValue={widgetData.widgetProps['content']['login_url'] ? widgetData.widgetProps['content']['new_customer_url'] : "/register"}
                       onChange={(event) => {
                           handleChange(event.target.value, "new_customer_url")
                           event.preventDefault();
                           props.saveWidgetState();
                       }}/>
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"Log in Label"}/>
        </Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={""}
                       defaultValue={widgetData.widgetProps['content']['login_label'] ? widgetData.widgetProps['content']['login_label']  : "Log in"}
                       onChange={(event) => {
                           handleChange(event.target.value, "login_label")
                           event.preventDefault();
                           props.saveWidgetState();
                       }}/>
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"Path to login"}/>
        </Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={""}
                       defaultValue={widgetData.widgetProps['content']['login_url'] ? widgetData.widgetProps['content']['login_url'] : "/login"}
                       onChange={(event) => {
                           handleChange(event.target.value, "login_url")
                           event.preventDefault();
                           props.saveWidgetState();
                       }}/>
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"Log out Label"}/>
        </Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={""}
                       defaultValue={widgetData.widgetProps['content']['logout_label'] ? widgetData.widgetProps['content']['login_label']  : "Log out"}
                       onChange={(event) => {
                           handleChange(event.target.value, "logout_label")
                           event.preventDefault();
                           props.saveWidgetState();
                       }}/>
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"Path to login"}/>
        </Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={""}
                       defaultValue={widgetData.widgetProps['content']['logout_url'] ? widgetData.widgetProps['content']['logout_url'] : "/logout"}
                       onChange={(event) => {
                           handleChange(event.target.value, "logout_url")
                           event.preventDefault();
                           props.saveWidgetState();
                       }}/>
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"Welcome Message"}/>
        </Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                           fullWidth={true}
                           label={""}
                           defaultValue={widgetData.widgetProps['content']['welcome_label'] ? widgetData.widgetProps['content']['welcome_label'] : "Welcome {firstName}"}
                           onChange={(event) => {
                               handleChange(event.target.value, "welcome_label")
                               event.preventDefault();
                               props.saveWidgetState();
                           }}/>
        </Grid>
        </>}
        <Grid item xs={4}>
            <SettingLabel title={"Include Product Menu"}/>
        </Grid>
        <Grid item xs={8}>
            <YesNoSwitch defaultValue={widgetData.widgetProps['content']['hasProductMenu']} onChange={(value) => {
                handleChange(value, "hasProductMenu")
                setHasProductMenu(value);
            }}/>
        </Grid>

        {hasProductMenu && <>
        <Grid item xs={4}>
            <SettingLabel title={"Product Label"}/>
        </Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                      fullWidth={true}
                      label={""}
                      defaultValue={widgetData.widgetProps['content']['product_label']}
                      onChange={(event) => {
                          handleChange(event.target.value, "product_label")
                          event.preventDefault();
                          props.saveWidgetState();
                      }}/>
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"Product Drilldown"}/>
        </Grid>
        <Grid item xs={8}>
            <TextField
                size={"small"}
                select={true}
                fullWidth={true}
                defaultValue={widgetData.widgetProps['content']['product_drilldown'] ? widgetData.widgetProps['content']['product_drilldown'] : ""}
                label={""}
                onChange={(event) => handleChangeProductDrilldown(event.target.value)}
            >
                {drillDownOptions && drillDownOptions.map((option, index) => <MenuItem key={"mfr_item_" + index}
                                                                   value={option.value}>{option.label}</MenuItem>)}
            </TextField>
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"Products Root URL"}/>
        </Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={""}
                       defaultValue={widgetData.widgetProps['content']['product_path']}
                       onChange={(event) => {
                           handleChange(event.target.value, "product_path")
                           event.preventDefault();
                           props.saveWidgetState();
                       }}/>
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"Product Drilldown URL Prefix"}/>
        </Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={""}
                       defaultValue={widgetData.widgetProps['content']['product_menu_prefix']}
                       onChange={(event) => {
                           handleChange(event.target.value, "product_menu_prefix")
                           event.preventDefault();
                           props.saveWidgetState();
                       }}/>
        </Grid>
        </>
        }
        <Grid item xs={4}>
            <SettingLabel title={"Include Mfr Menu"}/>
        </Grid>
        <Grid item xs={8}>
            <YesNoSwitch defaultValue={widgetData.widgetProps['content']['hasMfrMenu']} onChange={(value) => {
                handleChange(value, "hasMfrMenu")
                setHasMfrMenu(value);
            }}/>
        </Grid>

        {hasMfrMenu && <>
            <Grid item xs={4}>
                <SettingLabel title={"Mfr Label"}/>
            </Grid>
            <Grid item xs={8}>
                <TextField size={"small"}
                          fullWidth={true}
                          label={""}
                          defaultValue={widgetData.widgetProps['content']['mfr_label']}
                          onChange={(event) => {
                              handleChange(event.target.value, "mfr_label")
                              event.preventDefault();
                              props.saveWidgetState();
                          }}/>
            </Grid>
            <Grid item xs={4}>
                <SettingLabel title={"Mfr Drilldown"}/>
            </Grid>
            <Grid item xs={8}>
                <TextField
                    size={"small"}
                    fullWidth={true}
                    select={true}
                    defaultValue={widgetData.widgetProps['content']['mfr_drilldown'] ? widgetData.widgetProps['content']['mfr_drilldown'] : ""}
                    label={""}
                    onChange={(event) => handleChangeMfrDrilldown(event.target.value)}
                >
                    {drillDownOptions && drillDownOptions.map((option, index) => <MenuItem key={"mfr_item_" + index}
                                                                       value={option.value}>{option.label}</MenuItem>)}
                </TextField>
            </Grid>
            <Grid item xs={4}>
                <SettingLabel title={"Mfr Root URL"}/>
            </Grid>
            <Grid item xs={8}>
                <TextField size={"small"}
                           fullWidth={true}
                           label={""}
                           defaultValue={widgetData.widgetProps['content']['mfr_path']}
                           onChange={(event) => {
                               handleChange(event.target.value, "mfr_path")
                               event.preventDefault();
                               props.saveWidgetState();
                           }}/>
            </Grid>
            <Grid item xs={4}>
                <SettingLabel title={"Mfr Drilldown URL Prefix"}/>
            </Grid>
            <Grid item xs={8}>
                <TextField size={"small"}
                           fullWidth={true}
                           label={""}
                           defaultValue={widgetData.widgetProps['content']['mfr_menu_prefix']}
                           onChange={(event) => {
                               handleChange(event.target.value, "mfr_menu_prefix")
                               event.preventDefault();
                               props.saveWidgetState();
                           }}/>
            </Grid>
        </>
        }

        <Grid item xs={12} className={styles.DefaultFontColor}>
            <MenuSetting initMenu={(widgetData.widgetProps['content'] && widgetData.widgetProps['content']['addedMainMenus']) ?
                widgetData.widgetProps['content']['addedMainMenus']
                : [{label: "", link: "", menuItems: []}]} onChange={onMainMenuChange}/>
        </Grid>
    </Grid>
}