import SettingProps from "../SettingProps";
import GenericSetting, {StyleSettingType} from "../genericsetting/GenericSetting";

export default function BorderSetting(props: SettingProps) {
    return <GenericSetting {...props}
                           title={"Border"}
                           type={"border"}
                           targets={[
                                    { name: "Border",target: 'border' , type: StyleSettingType.text},
                                    { name: "Style", target: 'border-style' , type: StyleSettingType.selections, options: [
                                            {label: "none", value: ""},
                                            {label: "hidden", value: "hidden"},
                                            {label: "dotted", value: "dotted"},
                                            {label: "dashed", value: "dashed"},
                                            {label: "solid", value: "solid"},
                                            {label: "double", value: "double"},
                                            {label: "groove", value: "groove"},
                                            {label: "ridge", value: "ridge"},
                                            {label: "inset", value: "inset"},
                                            {label: "outset", value: "outset"},
                                        ]},
                                    { name: "Top",target: 'border-block-start' , type: StyleSettingType.default_size},
                                    { name: "Bottom", target: 'border-block-end' , type: StyleSettingType.default_size},
                                    { name: "Left", target: 'border-inline-start' , type: StyleSettingType.default_size},
                                    { name: "Right", target: 'border-inline-end' , type: StyleSettingType.default_size},
                                    // { name: "Border Radius", target: 'border-radius' , type: StyleSettingType.text},
                                    // { name: "Shadow", target: 'box-shadow' , type: StyleSettingType.text}
                                ]}
    />
}