import {useAppSelector} from "../../../../redux/hook";
import {selectViewState} from "../../../../redux/features/view/viewSlice";
import styles from "./Plugin.module.scss";
import React, {useContext, useState} from "react";
import EditablePanel from "../../shared/editable/EditablePanel";
import {DeleteType} from "../../../model/DeleteOption";
import {StyleUtil} from "../../setting/StyleUtil";
import {SettingGroupType, SettingType} from "../../../model/SettingOption";
import {nanoid} from "nanoid";
import useWidgetState from "../../../../hook/useWidgetState";
import VariableContext from "../../../../context/VariableContext";



export default function Plugin(props:any) {
    const widgetData = props.data;
    const {widgetId, widgetProps} = widgetData;
    const viewState = useAppSelector(selectViewState);
    const [refreshId,setRefreshId] = useState(nanoid());
    const variableContext = useContext(VariableContext);
    useWidgetState(widgetId, (event) => {
        setRefreshId(nanoid());
    })


    if(!widgetData.widgetProps['content']) {
        widgetData.widgetProps['content'] = {}
    }

    if(!widgetData.widgetProps['content']['html']) { // default content
        widgetData.widgetProps['content']['html'] = ""
    }

    if(StyleUtil.isDefaultStyle(widgetProps['bt_plugin_panel'])) {
        widgetData.widgetProps['bt_plugin_panel'] = {
            style: {
                default: true,
                dimension: {
                    'min-height': '100px'
                },
                padding: {
                    'padding-inline-start': "0px",
                    'padding-inline-end': "0px",
                    'padding-block-start': "0px",
                    'padding-block-end': "0px"
                }
            }
        }
    }

    const themeName = "plugin";
    const widgetStyles = {
        theme: StyleUtil.toThemeNameByType(widgetProps, themeName),
        current: StyleUtil.toClassFromWidget(widgetId , widgetProps, "current_style"),
        panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "bt_plugin_panel")
    }

    const createPlugin = (props:any) => {
        let newProps = {...props};
        delete newProps.uiType;
        delete newProps.onHandleSwap;
        delete newProps.onHandleDeletion;
        delete newProps.onHandleSetting;
        delete newProps.onHandleAddNewSectionOrWidget;
        delete newProps.uiLevelStr;
        delete newProps.onHandleUpdate;
        delete newProps.uiLevel;
        delete newProps.onHandleCut;
        delete newProps.onHandleCopy;
        delete newProps.onHandleClone;
        delete newProps.onHandleCreateTemplate

        return <div key={refreshId}
                    {...newProps}
                    data-ui-type={props.uiType}
                    style={{pointerEvents: viewState.isEdit ? "none" : "unset"}}
                    className={StyleUtil.combineClasses(styles.Root,widgetStyles.theme,widgetStyles.current,widgetStyles.panel)}
                    dangerouslySetInnerHTML={{__html: variableContext.updateVariables(widgetProps['content']['html'])}}></div>
    }

    let deleteOption = {type: DeleteType.delete_with_rearrange, tag: null};
    let suggestTags: string[] = ["current"];
    let settingOptions = [
        { type: SettingType.dimension, group: "bt_plugin_panel", groupType: SettingGroupType.style},
        // { type: SettingType.position,  group: "bt_plugin_panel" , groupType: SettingGroupType.style},
        { type: SettingType.margin,  group: "bt_plugin_panel", groupType: SettingGroupType.advanced},
        { type: SettingType.padding,  group: "bt_plugin_panel", groupType: SettingGroupType.advanced},
        { title: "", type: SettingType.widget,group: "content",
            editor: "pluginsetting", groupType: SettingGroupType.layout

        // <PluginSetting widgetData={widgetData}
        //                            sharedObject={props.sharedObjects}
        //                            saveWidgetState={saveWidgetState}
        //                            onChangePreview={(drilldown,previewId) => {
        //                                widgetData.widgetProps['html'] = "";
        //                                saveWidgetState();
        //                            }}
        //     />
        },
    ];
    let settingGroups = [

        {group: "content", groupName: "Content", groupType: SettingGroupType.layout},
        {group: "bt_plugin_panel", groupName: "Panel", groupType: SettingGroupType.style}
    ];

    if(viewState.isEdit) {
        return <EditablePanel className={styles.Root}
                              {...props}
                              themeName={themeName}
                              suggestTags={suggestTags}
                              settingGroups={settingGroups}
                              settingOptions={settingOptions}
                              deleteOption={deleteOption}>
            {   createPlugin(props)   }
        </EditablePanel>
    } else {
        return createPlugin(props)
    }

}