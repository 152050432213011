import React, {useState} from "react";
import styles from "./Login.module.scss"
import {WidgetInterface} from "../../../model/WidgetInterface";
import {useAppSelector} from "../../../../redux/hook";
import {selectViewState} from "../../../../redux/features/view/viewSlice";
import {nanoid} from "nanoid";
import {DeleteType} from "../../../model/DeleteOption";
import {SettingGroupType, SettingOption, SettingType} from "../../../model/SettingOption";
import EditablePanel from "../../shared/editable/EditablePanel";
import {StyleUtil} from "../../setting/StyleUtil";
import useWidgetState from "../../../../hook/useWidgetState";

export default function Login(props:any) {

    const widgetData:WidgetInterface = props.data as WidgetInterface;
    const viewState = useAppSelector(selectViewState);
    const [refreshId,setRefreshId] = useState(nanoid());
    const {widgetId,widgetProps} = widgetData;
    useWidgetState(widgetId,(event:CustomEvent) => {
        setRefreshId(nanoid());
    });

    if(!widgetProps["content"]) {
        widgetProps["content"] = {}
    }

    //if(!widgetProps['panel']) {
    if(StyleUtil.isDefaultStyle(widgetProps['panel'])) {
        widgetProps['panel'] = {
            style: {
                default: true,
                dimension: {
                    width: "300px",
                    height: "auto",
                }
            }
        };
    }

    //if(!widgetProps['login_input']) {
    if(StyleUtil.isDefaultStyle(widgetProps['login_input'])) {
        widgetProps['login_input'] = {

            style: {
                default: true,
            }
        };
    }

    //if(!widgetProps['login_panel']) {
    if(StyleUtil.isDefaultStyle(widgetProps['login_panel'])) {
        widgetProps['login_panel'] = {

            style: {
                default: true,
            }
        };
    }

    //if(!widgetProps['login_label']) {
    if(StyleUtil.isDefaultStyle(widgetProps['login_label'])) {
        widgetProps['login_label'] = {

            style: {
                default: true,
            }
        };
    }


    const themeName = "login";
    const widgetStyle = {
        theme: StyleUtil.toThemeNameByType(widgetProps, themeName),
        current: StyleUtil.toClassFromWidget(widgetId , widgetProps, "current_style"),
        panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "panel"),
        login_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "login_panel"),
        login_label: StyleUtil.toClassFromWidget(widgetId , widgetProps, "login_label"),
        login_input: StyleUtil.toClassFromWidget(widgetId , widgetProps, "login_input"),
        forget_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "forget_panel"),
        error_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "error_panel"),
    }

    const userLabel:string = widgetProps['content'] && widgetProps['content']['userLabel']  ? widgetProps['content']['userLabel'] : "User ID:";
    const passwordLabel:string = widgetProps['content'] && widgetProps['content']['passwordLabel']  ? widgetProps['content']['passwordLabel'] : "Password:";
    const errorLabel:string = widgetProps['content'] && widgetProps['content']['errorLabel']  ? widgetProps['content']['errorLabel'] : "Your login was unsuccessful. Please try again.";
    const forgetLabel:string = widgetProps['content'] && widgetProps['content']['forgetLabel']  ? widgetProps['content']['forgetLabel'] : "Forget Your Password?";
    const forgetLinkLabel:string = widgetProps['content'] && widgetProps['content']['forgetLinkLabel']  ? widgetProps['content']['forgetLinkLabel'] : "We can help you reset it";
    const forgetLinkURL:string = widgetProps['content'] && widgetProps['content']['forgetLinkURL']  ? widgetProps['content']['forgetLinkURL'] : "/password";
    const destinationURL:string = widgetProps['content'] && widgetProps['content']['destinationURL']  ? widgetProps['content']['destinationURL'] : "/home"


    const createLogin = () => {
        return <>
            <div className={StyleUtil.combineClasses(styles.Panel,widgetStyle.theme,widgetStyle.current,widgetStyle.panel)}>
                <div className={StyleUtil.combineClasses(styles.ErrorPanel,widgetStyle.error_panel,"li_error_panel")}>
                    {errorLabel}
                </div>
                <div className={StyleUtil.combineClasses(styles.LoginPanel,widgetStyle.login_panel,"li_login_panel")}>
                    <fieldset>
                        <label htmlFor="login" className={StyleUtil.combineClasses(styles.LoginLabel,widgetStyle.login_label)}>{userLabel}</label>
                        <input type="text" id="login" name="login" autoComplete={"off"} className={StyleUtil.combineClasses(styles.LoginInput,widgetStyle.login_input)}/>
                    </fieldset>
                    <fieldset>
                        <label htmlFor="password" className={StyleUtil.combineClasses(styles.LoginLabel,widgetStyle.login_label)}>{passwordLabel}</label>
                        <input type="password" id="login" name="password" autoComplete={"off"} className={StyleUtil.combineClasses(styles.LoginInput,widgetStyle.login_input)}/>
                        <a>Go <i className="fa fa-caret-right"></i></a>
                    </fieldset>
                </div>
                <div className={StyleUtil.combineClasses(styles.ForgetPanel,widgetStyle.forget_panel,"li_forget_panel")}> <span>{forgetLabel}</span> <a>{forgetLinkLabel}</a>
                </div>
            </div>
        </>
    }

    if(viewState.isEdit) {
        let deleteOption = {type: DeleteType.delete_with_rearrange, tag: null};
        const suggestTags: string[] = [
            "current",
            "current .li_error_panel",
            "current .li_login_panel",
            "current .li_forget_panel",
            "current .li_forget_panel a",
            "current fieldset",
            "current fieldset label",
            "current fieldset input",
            "current fieldset a",
        ]

        let settingOptions:SettingOption[] = [
            { title: "", type: SettingType.widget,  editor: "loginsetting" , group: "content", groupType: SettingGroupType.layout},
            { type: SettingType.background, group: "panel" , groupType: SettingGroupType.style},
            { type: SettingType.dimension, group: "panel", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "panel", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "panel", groupType: SettingGroupType.advanced},

            { type: SettingType.background, group: "login_panel" , groupType: SettingGroupType.style},
            { type: SettingType.dimension, group: "login_panel", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "login_panel", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "login_panel", groupType: SettingGroupType.advanced},

            { type: SettingType.background, group: "login_label", groupType: SettingGroupType.style },
            { type: SettingType.dimension, group: "login_label", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "login_label", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "login_label", groupType: SettingGroupType.advanced},

            { type: SettingType.background, group: "login_input", groupType: SettingGroupType.style },
            { type: SettingType.dimension, group: "login_input", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "login_input", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "login_input", groupType: SettingGroupType.advanced},

        ];

        return <EditablePanel className={styles.Root} {...props}
                              settingOptions={settingOptions}
                              themeName={themeName}
                              suggestTags={suggestTags}
                              settingGroups={[
                                  {group: "content", groupName: "Content", groupType: SettingGroupType.layout},
                                  {group: "panel", groupName: "Root", groupType: SettingGroupType.style},
                                  {group: "error_panel", groupName: "Error Panel", groupType: SettingGroupType.style},
                                  {group: "login_panel", groupName: "Login Panel", groupType: SettingGroupType.style},
                                  {group: "login_label", groupName: "Label", groupType: SettingGroupType.style},
                                  {group: "login_input", groupName: "Input", groupType: SettingGroupType.style},
                                  {group: "forget_panel", groupName: "Bottom Panel", groupType: SettingGroupType.style},
                              ]
                              }
                              deleteOption={deleteOption}>
            {createLogin()}
        </EditablePanel>
    } else {
        return createLogin();
    }
}
