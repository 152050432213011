import {useState, useEffect, useCallback} from "react";
// Define general type for useWindowSize hook, which includes width and height
interface Location {
    x: number;
    y: number;
    target: any;

}

// Hook will work correct if scrolling happen in the object that in the same iframe
export default function useScrolling(): Location {
    const [location, setLocation] = useState<Location>({
        x: 0,
        y: 0,
        target: null
    });

    useEffect(() => {
        const handleScroll = (event:any) => {
            setLocation((l) => {
                return {
                    x: event.target.scrollLeft,
                    y: event.target.scrollTop,
                    target: event.target
                }
            })
        }
        window.addEventListener("scroll", handleScroll,true);
        return () => window.removeEventListener("scroll", handleScroll,true);
    }, []); // Empty array ensures that effect is only run on mount

    return location;
}