import {EditableProps} from "../../shared/editable/EditableProps";
import {WidgetInterface} from "../../../model/WidgetInterface";
import {useAppSelector} from "../../../../redux/hook";
import {selectViewState} from "../../../../redux/features/view/viewSlice";
import {SettingGroupType, SettingOption, SettingType} from "../../../model/SettingOption";
import EditablePanel from "../../shared/editable/EditablePanel";
import {DeleteType} from "../../../model/DeleteOption";
import styles from "./Header.module.scss";
import {SettingChangeFunction} from "../../setting/SettingChange";
import React, {useContext, useEffect, useRef, useState} from "react";
import HeaderSetting from "./HeaderSetting";
import {StyleUtil} from "../../setting/StyleUtil";
import CMSMenuItem from "../../shared/settings/menu/CMSMenuItem";
import {nanoid} from "nanoid";
import VariableContext from "../../../../context/VariableContext";
import cartIcon from "./shopping_cart.svg"
import useWindowSize from "../../../hook/useWindowSize";
import useScrolling from "../../../hook/useScrolling";
import "./HamburgerMenu.css";
import useWidgetState from "../../../../hook/useWidgetState";


export default function Header(props: EditableProps) {

    const widgetData:WidgetInterface = props.data as WidgetInterface;
    const {widgetId, widgetProps} = widgetData;
    const variableContext = useContext(VariableContext);
    const viewState = useAppSelector(selectViewState);

    const [currentMenu, setCurrentMenu] = useState<string>("");
    const [refreshId,setRefreshId] = useState(nanoid());
    useWidgetState(widgetId,(event:CustomEvent) => {
        setRefreshId(nanoid());
    });

    const windowSize = useWindowSize();
    const scrollLocation = useScrolling();
    const ref = useRef<any>();
    const smallMenuRef = useRef<any>()
    const [isSmallScreen, setSmallScreen] = useState<boolean>(false);
    const [hasScroll, setHasScroll] = useState<boolean>(false);
    const [showSmallMenu, setShowSmallMenu] = useState<boolean>(false);

    if(!widgetProps['content']) {
        widgetProps['content'] = {
            hasSearch: true,
            hasShopcart: true,
            hasLogin: true,
            search_placeholder: "Keywords, Product ID, Brand",
            hasProductMenu: true,
            hasMfrMenu: true,
            product_label: "Products",
            mfr_label: "Brands",
            product_path: "/products",
            product_menu_prefix: "/catalogs/products",
            mfr_path: "/brands",
            mfr_menu_prefix: "/catalogs/shop",
            welcome_label: "Welcome {firstName}",
            new_customer_label: "New Customer?",
            new_customer_url: "/register",
            login_url: "/login",
            login_label: "Log in",
            logout_url: "/logout",
            logout_label: "Log out",

        };
    }

    if(!widgetProps['logo']) {
        widgetProps['logo'] = {
            location: "middle",
            order: 1,
            style : {
                default: true
            }
        };
    }


    if(!widgetProps['login']) {
        widgetProps['login'] = {
            location: "top",
            order: 2,
            style : {
                default: true
            }
        };
    }

    if(!widgetProps['shopcart']) {
        widgetProps['shopcart'] = {
            location: "top",
            order: 1,
            style : {
                default: true
            }
        };
    }

    if(!widgetProps['mainMenu']) {
        widgetProps['mainMenu'] = {
            location: "middle",
            order: 3,
            style : {
                default: true
            }
        };
    }

    if(!widgetProps['search']) {
        widgetProps['search'] = {};
        widgetProps['search']['hasBarcode'] = true;
        widgetProps['search']['location'] = "middle";
        widgetProps['search']['order'] = 2;
        widgetProps['search']['style'] = { default: true};
    }


    if(StyleUtil.isDefaultStyle(widgetProps['top_panel'])) {
        widgetProps['top_panel'] = {
            ...widgetProps['top_panel'],
            style: {
                default: true,
                margin: {
                    'margin-inline-start': "5%",
                    'margin-inline-end': "5%",
                }
            }
        };
    }

    if(StyleUtil.isDefaultStyle(widgetProps['logo'])) {
        if(widgetProps['logo']) {
            delete widgetProps['logo']['style'];
        }
        widgetProps['logo'] = {
            ...widgetProps['logo'],
            style: {
                default: true,
                position: {
                    'float': "left",
                    // display: 'inline-block'
                },
                margin: {
                    'margin-block-start': '-13px'
                }
            }
        };
    }

    if(StyleUtil.isDefaultStyle(widgetProps['search'])) {
        if(widgetProps['search']) {
            delete widgetProps['search']['style'];
        }
        widgetProps['search'] = {
            ...widgetProps['search'],
            style: {
                default: true,
                position: {
                    'float': "right",
                }
            }
        };
    }

    if(StyleUtil.isDefaultStyle(widgetProps['mainMenu'])) {
        if(widgetProps['mainMenu']) {
            delete widgetProps['mainMenu']['style'];
        }
        widgetProps['mainMenu'] = {
            ...widgetProps['mainMenu'],
            style: {
                default: true,
                position: {

                }
            }
        };
    }

    if(StyleUtil.isDefaultStyle(widgetProps['top_panel'])) {
        if(widgetProps['top_panel']) {
            delete widgetProps['top_panel']['style'];
        }
        widgetProps['top_panel'] = {
            ...widgetProps['top_panel'],
            style: {
                default: true,
                position: {
                    'text-align' :  'right'
                },
                margin: {
                    'margin-inline-start': '5%',
                    'margin-inline-end': '5%',
                    'margin-block-end': '10px',
                }
            }
        }
    }

    if(StyleUtil.isDefaultStyle(widgetProps['middle_panel'])) {
        if(widgetProps['middle_panel']) {
            delete widgetProps['middle_panel']['style'];
        }
        widgetProps['middle_panel'] = {
            ...widgetProps['middle_panel'],
            style: {
                default: true,
                position: {
                    // 'float': "left",
                    // 'min-height': '70px',
                },
                margin: {
                    'margin-inline-start': '5%',
                    'margin-inline-end': '5%'
                },
                padding: {
                    'padding-block-start': '3px',
                    'padding-block-end': '8px',
                }
            }
        };
    }

    const themeName = "header";
    const widgetStyles = {
        theme: StyleUtil.toThemeNameByType(widgetProps, themeName),
        current: StyleUtil.toClassFromWidget(widgetId, widgetProps, "current_style"),
        top_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "top_panel"),
        middle_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "middle_panel"),
        bottom_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "bottom_panel"),
        logo: StyleUtil.toClassFromWidget(widgetId , widgetProps, "logo"),
        login: StyleUtil.toClassFromWidget(widgetId , widgetProps, "login"),
        shopcart: StyleUtil.toClassFromWidget(widgetId , widgetProps, "shopcart"),
        search: StyleUtil.toClassFromWidget(widgetId , widgetProps, "search"),
        main_menu: StyleUtil.toClassFromWidget(widgetId,widgetProps,"mainMenu"),
        popup_panel: StyleUtil.toClassFromWidget(widgetId,widgetProps,'popup_panel'),
        menu_item: StyleUtil.toClassFromWidget(widgetId,widgetProps,'menu_item'),
    }

    // useEffect( () => {
    //     if(ref.current && scrollLocation.target !== null) {
    //         if(scrollLocation.y < 50 && hasScroll) {
    //             setHasScroll(false);
    //         } else if(scrollLocation.y > 50 && hasScroll === false) {
    //             setHasScroll(true);
    //         }
    //         // if(showSmallMenu) {
    //         //     setShowSmallMenu(false);
    //         // }
    //     }
    // },[ref,scrollLocation,hasScroll]);

    useEffect(() => {
        if(ref.current && windowSize.width >= 0 && windowSize.height >= 0) {
            let rect = ref.current.getBoundingClientRect();
            if(rect.width < 950) { //smallscreen
                if(isSmallScreen === false) {
                    setSmallScreen(true);
                }
            } else {
                if(isSmallScreen) { // bigscreen
                    setRefreshId(nanoid());
                    setSmallScreen(false);
                }
            }
        }
    },[isSmallScreen,ref,windowSize]);

    const createLogo = () => {
        if(widgetProps["content"] && widgetProps["content"]['logo_url']) {
            let src = widgetProps["content"]['logo_url'];
            if(src) {
                if(src.indexOf("${CDN_URL}") >= 0) {
                    src = src.replaceAll("${CDN_URL}",variableContext.cndURL);
                }
                if(src.indexOf("${WEBSITE_URL}") >= 0) {
                    src = src.replaceAll("${WEBSITE_URL}", variableContext.websiteURL);
                }
            }
            return <img key="logo"
                        className={StyleUtil.combineClasses("h_logo",widgetStyles.logo)}
                        src={src}/>
        } else {
            return null;
        }
    }

    const createLogin = () => {
        if (widgetProps["content"] && widgetProps['content']['hasLogin']) {
            return <div  key="login" className={StyleUtil.combineClasses("h_login",widgetStyles.login,styles.LoginPanel)}>
                <a href={""}>{widgetProps['content']['new_customer_label'] ? widgetProps['content']['new_customer_label'] : "New Customer?"}</a>
                <a href={""}>{widgetProps['content']['login_label'] ? widgetProps['content']['login_label'] : "Log in"}</a>
            </div>
        } else {
            return null;
        }
    }

    const createShopcart = () => {
        if (widgetProps["content"] && widgetProps['content']['hasShopcart']) {
            return <div  key="shopcart" className={StyleUtil.combineClasses("h_shopcart",widgetStyles.shopcart,styles.ShopcartPanel)}>
                <a href={""}><img src={cartIcon}/><span>0 items / $0.00</span></a>
                <a href={""}>Checkout</a>
            </div>
        } else {
            return null;
        }
    }

    const createSearch = () => {
        if (widgetProps["content"] && widgetProps['content']['hasSearch']) {
            return <div key="search" className={StyleUtil.combineClasses("h_search",widgetStyles.search,styles.Search)} >
                        <input type={"text"} className={styles.SearchTextField} placeholder={widgetProps['content']['search_placeholder']}/>
                        <span className="fa fa-barcode" style={{visibility: widgetProps['search']['hasBarcode'] ? "visible" : "hidden"}}>&nbsp;</span>
                        <span className="fa fa-search">&nbsp;</span>
                    </div>
        } else {
            return null;
        }
    }

    const createMainMenu = () => {
        let menus: string[] = [];
        let withSubMenu: {[key: string] : CMSMenuItem[]} = {};
        if(widgetProps['content']['hasProductMenu']) {
            menus.push(widgetProps['content']['product_label'] ? widgetProps['content']['product_label'] : "PRODUCTS");
        }

        if(widgetProps['content']['hasMfrMenu']) {
            menus.push(widgetProps['content']['product_label'] ? widgetProps['content']['mfr_label'] : "BRANDS");
        }

        if(widgetProps['content']['addedMainMenus']) {
            let addedMenus = widgetProps['content']['addedMainMenus'];
            addedMenus.forEach((addedMenu:CMSMenuItem) => {
                menus.push(addedMenu.label);
                withSubMenu[addedMenu.label] = addedMenu.menuItems;
            })
        }
        return  <div key="mainMenu" className={StyleUtil.combineClasses("h_menu",widgetStyles.main_menu)}>
            <ul>
            {
                menus.map((menu, index) => <li key={'menu1_' + index} className={styles.Menu }
                                                onMouseEnter={(event) => {setCurrentMenu(menu)}}>
                    <a href={""}>{menu}</a>
                    <ul className={StyleUtil.combineClasses("h_popup_panel",widgetStyles.popup_panel)}>
                        { withSubMenu[menu] && withSubMenu[menu].map((menuItem, index) => <li className={widgetStyles.menu_item}><a href={menuItem.link}>{menuItem.label}</a></li>) }
                        { !withSubMenu[menu] && <li className={widgetStyles.menu_item}>--auto--generate--</li>}
                    </ul>
                </li>)
            }
            </ul>
        </div>
    }

    const handleClickHeader = (event:React.MouseEvent<HTMLDivElement>) => {
        event.currentTarget.classList.toggle("is-active")
        setShowSmallMenu(event.currentTarget.classList.contains("is-active"));
        event.preventDefault()
        event.stopPropagation()
    }

    const createSmallHeader = () => {
        return <div key={"small" + refreshId} ref={ref} className={StyleUtil.combineClasses(widgetStyles.theme,widgetStyles.current)} style={{position: "relative"}}>
            <div className={StyleUtil.combineClasses("h_small_bar",hasScroll ? styles.SmallBarFixed : styles.SmallBar)}>
                <div className={StyleUtil.combineClasses("h_small_hamburger","hamburger","hamburger--squeeze",styles.SmallHamburgerMenuPanel)} onMouseDown={viewState.isEdit ? undefined : handleClickHeader}>
                    <span
                            className={StyleUtil.combineClasses("h_small_hamburger","hamburger-box",styles.SmallHamburgerMenu)}
                            style={{"backgroundColor": "transparent"}}>
                        <span className={StyleUtil.combineClasses("h_small_hamburger","hamburger-inner",styles.SmallHamburgerMenu)}></span>
                    </span>
                </div>
                { !hasScroll && <div className={styles.SmallLogo}>
                        {createLogo()}
                </div>}
                <div className={styles.SmallCart}><a href="shopcart">
                    <i className={StyleUtil.combineClasses("h_small_cart_icon", "fa","fa-shopping-cart",styles.SmallCartIcon)}>&nbsp;</i>
                    <span className={StyleUtil.combineClasses("h_small_cart_label",styles.SmallCartLabel)}>0</span></a>
                </div>
                {
                    widgetProps["content"] && widgetProps['content']['hasSearch'] && <div key="search" className={StyleUtil.combineClasses("h_small_search",hasScroll ? styles.SmallSearchFixed: styles.SmallSearch)} >
                    <input type={"text"} className={styles.SearchTextField} placeholder={widgetProps['content']['search_placeholder']}/>
                    <span className="fa fa-barcode" style={{visibility: widgetProps['search']['hasBarcode'] ? "visible" : "hidden"}}>&nbsp;</span>
                    <span className="fa fa-search">&nbsp;</span>
                </div> }
                {
                    !(widgetProps["content"] && widgetProps['content']['hasSearch']) && <div className={styles.SmallSearchHidden}></div>
                }
            </div>

            <div ref={smallMenuRef} className={StyleUtil.combineClasses("h_small_menu", styles.SmallMenu)} style={{maxHeight: showSmallMenu ? (smallMenuRef.current ? smallMenuRef.current?.children[0].clientHeight : "0px") : "0px"}}>
                {   createSmallMainMenu()   }
            </div>
        </div>
    }

    const createSmallMainMenu = () => {
        let menus: string[] = [];
        let withSubMenu: {[key: string] : CMSMenuItem[]} = {};
        if(widgetProps['content']['hasProductMenu']) {
            menus.push(widgetProps['content']['product_label'] ? widgetProps['content']['product_label'] : "PRODUCTS");
        }

        if(widgetProps['content']['hasMfrMenu']) {
            menus.push(widgetProps['content']['product_label'] ? widgetProps['content']['mfr_label'] : "BRANDS");
        }

        if(widgetProps['content']['addedMainMenus']) {
            let addedMenus = widgetProps['content']['addedMainMenus'];
            addedMenus.forEach((addedMenu:CMSMenuItem) => {
                menus.push(addedMenu.label);
                withSubMenu[addedMenu.label] = addedMenu.menuItems;
            })
        }
        return  <ul>
                {
                    menus.map((menu, index) => <li key={'menu1_' + index} onMouseEnter={(event) => {setCurrentMenu(menu)}}>
                        <a>{menu}</a>
                    </li>)
                }
            </ul>
    }

    const createHeader = () => {
        let topView: any[] = [];
        let bottomView: any[] = [];
        let middleView: any[] = [];
        let views = [
                            {id: 'logo' , view: createLogo()},
                            {id: 'shopcart', view: createShopcart()},
                            {id: 'search', view: createSearch()},
                            {id: 'login', view: createLogin()},
                            {id: 'mainMenu', view: createMainMenu()}];

        views.sort((view1,view2) => {
            let label1 = view1.id;
            let label2 = view2.id;
            if (widgetProps[label1] && widgetProps[label2] && widgetProps[label1]['order'] && widgetProps[label2]['order']) {
                let compare = widgetProps[label1].location.localeCompare(widgetProps[label2].location);
                if(compare === 0) {
                    return widgetProps[label1].order - widgetProps[label2].order
                } else {
                    return compare;
                }
            } else {
                return -1;
            }
        }).forEach(view => {
            let label = view.id;
            if(widgetProps[label] && widgetProps[label]['location']) {
                let location = widgetProps[label]['location'];

                if(location === "top") {
                    topView.push(view.view)
                } else if(location === "bottom") {
                    bottomView.push(view.view)
                } else {
                    middleView.push(view.view)
                }
            } else {
                middleView.push(view.view)
            }
        })
        return <div key={refreshId} ref={ref}>
            <div className={StyleUtil.combineClasses(widgetStyles.theme,widgetStyles.current)}>
                <div className={StyleUtil.combineClasses("h_top_panel",widgetStyles.top_panel, styles.Section, styles.TopSection)}>
                    { topView }
                </div>
                <div className={StyleUtil.combineClasses("h_middle_panel",widgetStyles.middle_panel, styles.Section, styles.MiddleSection)}>
                    { middleView }
                    <span className={styles.ClearSection}></span>
                </div>
                <div className={StyleUtil.combineClasses("h_bottom_panel",widgetStyles.bottom_panel, styles.Section, styles.BottomSection)}>
                    { bottomView }
                </div>

            </div>
        </div>
    }

    // const handleChange = (value: string, key: string) => {
    //     widgetProps["content"][key] = value;
    //     saveState();
    // }

    if(viewState.isEdit) {
        const deleteOption = {type: DeleteType.delete_with_rearrange, tag: null};
        const onChangeSettings:SettingChangeFunction = (settingOption: SettingOption,
                                                        settingOptions: SettingOption[],
                                                        index: number) => {

            // console.log("Inside ", widgetProps);
            //widgetProps[settingOption.group]= {widgetData.widget};
        }
        const suggestTags: string[] = [
            "current",
            "current .h_top_panel",
            "current .h_middle_panel",
            "current .h_bottom_panel",
            "current .h_logo",
            "current .h_shopcart",
            "current .h_login",
            "current .h_search",
            "current .h_menu",
            "current .h_menu ul",
            "current .h_menu li,a",
            "current .h_popup_panel",
            "current .h_popup_panel li,a",
            "current .h_small_bar",
            "current .h_small_hamburger > span,.h_small_hamburger::before,.h_small_hamburger::after",
            "current .h_small_cart_icon",
            "current .h_small_cart_label",
            "current .h_small_search",
            "current .h_small_menu",
        ]
        let settingOptions:SettingOption[] = [
            // <HeaderSetting
            //     widgetData={widgetData}
            //     saveWidgetState={() => {}}
            //     drillDownOptions={props.sharedObjects.drillDownOptions}/>
            { title: "" , type: SettingType.widget,  editor: "headersetting", additions: {drillDownOptions: props.sharedObjects.drillDownOptions}, group: "content", groupType: SettingGroupType.layout},
            { type: SettingType.position,  group: "top_panel" , groupType: SettingGroupType.style},
            { type: SettingType.dimension,  group: "top_panel" , groupType: SettingGroupType.style},
            { type: SettingType.margin,  group: "top_panel", groupType: SettingGroupType.advanced},
            { type: SettingType.padding,  group: "top_panel" , groupType: SettingGroupType.advanced},


            { type: SettingType.position,  group: "middle_panel" , groupType: SettingGroupType.style},
            { type: SettingType.dimension,  group: "middle_panel" , groupType: SettingGroupType.style},
            { type: SettingType.padding,  group: "middle_panel" , groupType: SettingGroupType.advanced},
            { type: SettingType.margin,  group: "middle_panel", groupType: SettingGroupType.advanced},

            { type: SettingType.position,  group: "bottom_panel" , groupType: SettingGroupType.style},
            { type: SettingType.dimension,  group: "bottom_panel" , groupType: SettingGroupType.style},
            { type: SettingType.margin,  group: "bottom_panel", groupType: SettingGroupType.advanced},
            { type: SettingType.padding,  group: "bottom_panel" , groupType: SettingGroupType.advanced},


            { title: "" , type: SettingType.widget,  editor: "searchsetting" , group: "search", groupType: SettingGroupType.layout},
            { type: SettingType.position,  group: "search" , groupType: SettingGroupType.style},
            { type: SettingType.dimension,  group: "search" , groupType: SettingGroupType.style},
            { type: SettingType.margin,  group: "search", groupType: SettingGroupType.advanced},
            { type: SettingType.padding,  group: "search" , groupType: SettingGroupType.advanced},

            { title: "" , type: SettingType.widget,  editor: "logosetting" , group: "logo", groupType: SettingGroupType.layout},
            { type: SettingType.position,  group: "logo" , groupType: SettingGroupType.style},
            { type: SettingType.dimension,  group: "logo" , groupType: SettingGroupType.style},
            { type: SettingType.background,  group: "logo" , groupType: SettingGroupType.style},
            { type: SettingType.margin,  group: "logo", groupType: SettingGroupType.advanced},
            { type: SettingType.padding,  group: "logo" , groupType: SettingGroupType.advanced},

            { title: "" , type: SettingType.widget,  editor: "shopcartsetting" , group: "shopcart", groupType: SettingGroupType.layout},
            { type: SettingType.position,  group: "shopcart" , groupType: SettingGroupType.style},
            { type: SettingType.dimension,  group: "shopcart" , groupType: SettingGroupType.style},
            { type: SettingType.margin,  group: "shopcart", groupType: SettingGroupType.advanced},
            { type: SettingType.padding,  group: "shopcart" , groupType: SettingGroupType.advanced},

            { title: "" , type: SettingType.widget,  editor: "headerloginsetting" , group: "login", groupType: SettingGroupType.layout},
            { type: SettingType.position,  group: "login" , groupType: SettingGroupType.style},
            { type: SettingType.dimension,  group: "login", groupType: SettingGroupType.style },
            { type: SettingType.margin,  group: "login", groupType: SettingGroupType.advanced},
            { type: SettingType.padding,  group: "login" , groupType: SettingGroupType.advanced},

            { title: "" , type: SettingType.widget,  editor: "mainmenusetting" , group: "mainMenu", groupType: SettingGroupType.layout},
            { type: SettingType.position,  group: "mainMenu" , groupType: SettingGroupType.style},
            { type: SettingType.dimension,  group: "mainMenu" , groupType: SettingGroupType.style},
            { type: SettingType.background,  group: "mainMenu" , groupType: SettingGroupType.style},
            { type: SettingType.margin,  group: "mainMenu", groupType: SettingGroupType.advanced},
            { type: SettingType.padding,  group: "mainMenu" , groupType: SettingGroupType.advanced},


            { type: SettingType.background,  group: "popup_panel" , groupType: SettingGroupType.style},
            { type: SettingType.border,  group: "popup_panel" , groupType: SettingGroupType.style},
            { type: SettingType.position,  group: "popup_panel" , groupType: SettingGroupType.style},
            { type: SettingType.dimension,  group: "popup_panel" , groupType: SettingGroupType.style},
            { type: SettingType.margin,  group: "popup_panel", groupType: SettingGroupType.advanced},
            { type: SettingType.padding,  group: "popup_panel" , groupType: SettingGroupType.advanced},

            { type: SettingType.background,  group: "menu_item" , groupType: SettingGroupType.style},
            { type: SettingType.position,  group: "menu_item" , groupType: SettingGroupType.style},
            { type: SettingType.dimension,  group: "menu_item" , groupType: SettingGroupType.style},
            { type: SettingType.margin,  group: "menu_item", groupType: SettingGroupType.advanced},
            { type: SettingType.padding,  group: "menu_item" , groupType: SettingGroupType.advanced},

        ];
        let settingGroups = [
            {group: "content", groupName: "Content",groupType: SettingGroupType.layout},
            {group:"top_panel", groupName: "Top Panel",groupType: SettingGroupType.style},
            {group:"middle_panel", groupName: "Middle Panel",groupType: SettingGroupType.style},
            {group:"bottom_panel", groupName: "Bottom Panel",groupType: SettingGroupType.style},
            {group:"logo", groupName: "Logo",groupType: SettingGroupType.style},
            {group:"search", groupName: "Search",groupType: SettingGroupType.style},
            {group:"shopcart", groupName: "ShopCart",groupType: SettingGroupType.style},
            {group:"login", groupName: "Login",groupType: SettingGroupType.style},
            {group:"mainMenu", groupName: "Menu",groupType: SettingGroupType.style},
            {group:"popup_panel", groupName: "Popup Panel",groupType: SettingGroupType.style},
            {group:"menu_item", groupName: "Menu Item",groupType: SettingGroupType.style},
        ];
        return (
            <EditablePanel className={styles.Root}
                           {...props}
                           themeName={themeName}
                           suggestTags={suggestTags}
                           settingGroups={settingGroups}
                           settingOptions={settingOptions}
                           onChangeSettings={onChangeSettings}
                           deleteOption={deleteOption}
                           >
                    {isSmallScreen === false && createHeader()}
                    {isSmallScreen && createSmallHeader()}
            </EditablePanel>
        )
    } else {
        return <>
        {isSmallScreen === false && createHeader()}
        {isSmallScreen && createSmallHeader()}
        </>
    }

}