import {Grid, MenuItem, TextField, ToggleButtonGroup, ToggleButton} from "@mui/material";
import React, {useState} from "react";
import ProductSearchSetting from "../../setting/productsearchsetting/ProductSearchSetting";
import {InputOption} from "../../../../model/InputModel";
import DisplayField from "../../shared/displayfield/DisplayField";
import {SettingLabel} from "../../setting/settinglabel/SettingLabel";
import {CardCmd} from "../../../model/CardCmd";
import CardActionSetting from "../../shared/cardactionsetting/CardActionSetting";


export default function ProductListSetting(props: {
                                                displayOptions?: InputOption[],
                                                imageOptions?: InputOption[],
                                                widgetData: any,
                                                saveWidgetState: () => void,
                                                widgetCallback: (action: string, value: any) => void,
                                           }
                                                    // onChange: (searchType: string,
                                                    //             searchData: string,
                                                    //             start: number,
                                                    //             rows: number,
                                                    //             searchProfileId: number) => void}
) {
    const {widgetData} = props;
    const {widgetProps} = widgetData;
    const [buyPrefix,setBuyPrefix] = useState<string>(widgetData.widgetProps['content']['buyPrefix'] ? widgetData.widgetProps['content']['buyPrefix'] : "");
    const [idField,setIdField] = useState<string>(widgetData.widgetProps['content']['idField'] ? widgetData.widgetProps['content']['idField'] : "_lsi_cn_ProductID");
    const [titleField,setTitleField] = useState<string>(widgetData.widgetProps['content']['titleField'] ? widgetData.widgetProps['content']['titleField'] : "_lsi_cn_ProductID");
    const [imageField,setImageField] = useState<string>(widgetData.widgetProps['content']['imageField'] ? widgetData.widgetProps['content']['imageField'] : "_lsi_cn_ImageThumb");
    const [descField,setDescField] = useState<string>(widgetData.widgetProps['content']['descField'] ? widgetData.widgetProps['content']['descField'] : "_lsi_cn_Description");
    const [priceField,setPriceField] = useState<string>(widgetData.widgetProps['content']['priceField'] ? widgetData.widgetProps['content']['priceField'] : "_lsi_cn_Price");
    const [uomField,setUOMField] = useState<string>(widgetData.widgetProps['content']['uomField'] ? widgetData.widgetProps['content']['uomField'] : "_lsi_cn_PriceUOM");
    const [title,setTitle] = useState<string>(widgetData.widgetProps['content']['title'] ? widgetData.widgetProps['content']['title'] : "");
    const [openLink,setOpenLink] = useState<string>(widgetData.widgetProps['content']['openLink'] ? widgetData.widgetProps['content']['openLink'] : "_self");
    const [datasource, setDatasource] = useState<string>(widgetData.widgetProps['content']['datasource'] ? widgetData.widgetProps['content']['datasource'] : "CUSTOM");
    const [cards,setCards] = useState<CardCmd[]>(widgetData.widgetProps['content']['cards'] ? widgetData.widgetProps['content']['cards'] : []);

    const handleChange = (value: string, key: string) => {
        widgetData.widgetProps["content"][key] = value;
        props.saveWidgetState();
    }

    const handleChangeValue = (index: number,cmd:CardCmd,updated:boolean) => {
        let newCards = [...cards];
        newCards[index] = cmd;
        if(cmd.title === "" && cmd.description === "" && cmd.image === "") {
            newCards.splice(index,1);
        }
        widgetProps["content"]['cards'] = newCards;
        if(updated) {
            setCards(newCards);
        }
        props.saveWidgetState();
    }

    // const filterOptions = createFilterOptions({
    //     matchFrom: 'start',
    //     ignoreCase: true,
    // });

    const createAutoComplete = (label:string,name:string,value:string,setFunction: (value:string) => void,displayOptions:InputOption[]) => {
        return <DisplayField label={label} name={name} value={value} setFunction={setFunction} handleChange={handleChange} displayOptions={displayOptions}/>
        // return <Autocomplete size="small"
        //                      {...filterOptions}
        //                      options={displayOptions}
        //                      value={displayOptions.find(obj => obj.value === value)}
        //                      onChange={(event,value) => {
        //                          if(value !== null) {
        //                              handleChange(value.value, name)
        //                              setFunction(value.value);
        //                          }
        //                      }}
        //                      getOptionLabel={(option:InputOption) => option.label}
        //                      renderInput={(props) =>
        //                          <TextField
        //                              label={label}
        //                              {...props}/>}/>
    }


    return <Grid container spacing={2}>
        <Grid item xs={4}><SettingLabel title={"Title"}/></Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={""}
                       value={title}
                       onChange={(event) => {
                           handleChange(event.target.value.trim(), "title")
                           setTitle(event.target.value);
                           // props.onChange(searchType, searchString, start, maxItems,searchProfileId);
                       }}/>
        </Grid>
        <Grid item xs={4}><SettingLabel title={"Open Link In"}/></Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                       fullWidth={true}
                       select={true}
                       label={""}
                       value={openLink}
                       onChange={(event) => {
                           handleChange(event.target.value.trim(), "openLink")
                           setOpenLink(event.target.value);
                           // props.onChange(searchType, searchString, start, maxItems,searchProfileId);
                       }}>
                <MenuItem value={"_self"}>Current Window</MenuItem>
                <MenuItem value={"_blank"}>New Window</MenuItem>
            </TextField>
        </Grid>
        {/*<Grid item xs={12}>*/}
        {/*    <ToggleButtonGroup*/}
        {/*        fullWidth*/}
        {/*        value={datasource}*/}
        {/*        exclusive*/}
        {/*        onChange={(event,value) => {*/}
        {/*            setDatasource(value)*/}
        {/*            handleChange(value,"datasource");*/}
        {/*        }}*/}
        {/*        size={"small"}*/}
        {/*    >*/}
        {/*        <ToggleButton value="CATALOG">*/}
        {/*            Catalog*/}
        {/*        </ToggleButton>*/}
        {/*        <ToggleButton value="CUSTOM">*/}
        {/*            Static*/}
        {/*        </ToggleButton>*/}
        {/*    </ToggleButtonGroup>*/}
        {/*</Grid>*/}
        {   datasource === "CUSTOM" && <>
            { cards.map((card,index) => <React.Fragment >
                <Grid item xs={3}><SettingLabel title={`Card #${index+1}`}/></Grid>
                <Grid item xs={9}><CardActionSetting
                                              value={card}
                                              index={index}
                                              key={`card_${index}_${cards.length}`}
                                              onChange={(newValue) => {
                                                  handleChangeValue(index, newValue, true);
                                              }}/></Grid>
            </React.Fragment>)
            }
            <Grid item xs={3}><SettingLabel title={`Card #${cards.length+1}`}/></Grid>
            <Grid item xs={9}><CardActionSetting value={{title: "", image: "", image_href: "", description: "",actions: []}}
                                                 index={cards.length}
                                                 key={`card_${cards.length}_${cards.length}`}
                                                 onChange={(newValue) => {
                                                     handleChangeValue(cards.length, newValue,false);
                                                 }}
                                                 onBlur={(newValue) => {
                                                    handleChangeValue(cards.length, newValue, true);
                                                 }}/></Grid></>
        }
        { datasource === "CATALOG" && <>
            <Grid item xs={4}><SettingLabel title={"URL Prefix"}/></Grid>
            <Grid item xs={8}>
                <TextField size={"small"}
                           fullWidth={true}
                           label={""}
                           value={buyPrefix}
                           InputLabelProps={{shrink: true}}
                           onChange={(event) => {
                               handleChange(event.target.value.trim(), "buyPrefix")
                               setBuyPrefix(event.target.value);
                           }}/>
            </Grid>
            <ProductSearchSetting widgetData={widgetData}
                                  saveWidgetState={props.saveWidgetState}
                                  widgetCallback={props.widgetCallback}/>
            <Grid item xs={4}><SettingLabel title={"Image Field"}/></Grid>
            <Grid item xs={8}>
                {createAutoComplete("","imageField",imageField,setImageField,props.imageOptions!)}
            </Grid>
            <Grid item xs={4}><SettingLabel title={"ID Field"}/></Grid>
            <Grid item xs={8}>
                {createAutoComplete("","idField",idField,setIdField,props.displayOptions!)}
            </Grid>
            <Grid item xs={4}><SettingLabel title={"Title Field"}/></Grid>
            <Grid item xs={8}>
                {createAutoComplete("","titleField",titleField,setTitleField,props.displayOptions!)}
            </Grid>
            <Grid item xs={4}><SettingLabel title={"Description Field"}/></Grid>
            <Grid item xs={8}>
                {createAutoComplete("","descField",descField,setDescField,props.displayOptions!)}
            </Grid>
            <Grid item xs={4}><SettingLabel title={"Price Field"}/></Grid>
            <Grid item xs={8}>
                {createAutoComplete("","priceField",priceField,setPriceField,props.displayOptions!)}
            </Grid>
            <Grid item xs={4}><SettingLabel title={"UOM Field"}/></Grid>
            <Grid item xs={8}>
                {createAutoComplete("","uomField",uomField,setUOMField,props.displayOptions!)}
            </Grid></>}
    </Grid>
}