import {Checkbox, FormControlLabel, Grid, TextField} from "@mui/material";
import React, {useState} from "react";
import styles from "../../widget/header/HeaderSetting.module.scss";

export default function HorizontalSetting(props: { widgetData: any, saveWidgetState: () => void }) {
    let widgetProps = props.widgetData.sectionProps;


    const handleChange = (value: any, key: string) => {
        widgetProps["content"][key] = value;
        props.saveWidgetState();
    }

    if(!(widgetProps['content'])) {
        widgetProps['content'] = {
            equalSpace: true,
            gap: "0px",
            "justify-content": "center",
            "align-content": "top",
        }
    }
    if(!widgetProps['content']['gap']) {
        widgetProps['content']['gap'] = "0.0px";
    }

    const [evenLayout, setEvenLayout] = useState<boolean>(widgetProps['content']['equalSpace']);


    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <FormControlLabel className={styles.DefaultFontColor} control={<Checkbox defaultChecked={evenLayout}
                                                                                     onChange={(event: any) => {
                                                                                         setEvenLayout(event.target.checked);
                                                                                         handleChange(event.target.checked, "equalSpace")
                                                                                     }}/>} label="Equal space"/>
        </Grid>
        <Grid item xs={12}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={"justify-content"}
                       defaultValue={widgetProps['content']['justify-content']}
                       onChange={(event) => {
                           handleChange(event.target.value, "justify-content")
                       }}/>
        </Grid>
        <Grid item xs={12}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={"align-content"}
                       defaultValue={widgetProps['content']['align-items']}
                       onChange={(event) => {
                           handleChange(event.target.value, "align-items")
                       }}/>
        </Grid>
        <Grid item xs={12}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={"gap"}
                       defaultValue={widgetProps['content']['gap']}
                       onChange={(event) => {
                           handleChange(event.target.value, "gap")
                           event.preventDefault();
                           props.saveWidgetState();
                       }}/>

        </Grid>
    </Grid>
}