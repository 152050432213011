import {IconButton, IconButtonProps, Tooltip} from "@mui/material";
import styles from "./TopIconButton.module.scss"
import {TooltipPlacement} from "../../util/ThemeUtil";
export default function TopIconButton(props: IconButtonProps &
    {
        tooltipTitle?: string ,
        tooltipPlacement?: TooltipPlacement,
        regularIcon?: boolean
    }) {
    let newProps = {...props}
    delete newProps['tooltipPlacement']
    delete newProps['tooltipTitle']
    delete newProps['regularIcon']

    return <Tooltip title={props.tooltipTitle!} placement={props.tooltipPlacement} arrow>
        <IconButton {...newProps} className={props.regularIcon ? "" : styles.TopIconButton}>{props.children}</IconButton>
    </Tooltip>
}