import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import ThemeInterface from "../../../cms/model/ThemeInterface";



interface ThemeState {
    isEdit: boolean;
    themeData: ThemeInterface | null;
}

const initialState:ThemeState = {
    isEdit: false,
    themeData: null
}

export const themeSlice = createSlice( {
    name: 'theme',
    initialState,
    reducers: {
        themeEditAction: (state: ThemeState, action: PayloadAction<{ isEdit: boolean}>) => {
            state.isEdit = action.payload.isEdit;
        },
        themeUpdateDataAction: (state: ThemeState , action: PayloadAction<{themeData:ThemeInterface | null}>) => {
            state.themeData = action.payload.themeData;
        },
        themeResetAction: state => {
            state.isEdit = false;
        }
    }

})

export const { themeEditAction, themeResetAction, themeUpdateDataAction } = themeSlice.actions

export const selectThemeState = (state: RootState) => state.themeReducerState;

export default themeSlice.reducer;