import styles from "./AccessDenied.module.scss";
import OutlinedIcon from "../icon/OutlinedIcon";
import React from "react";
import commonStyle from "../../config/Common.module.scss";

export default function AccessDenied() {
    return <div className={commonStyle.ContentContainer + " " + styles.PermissionPanel}>
        <div>
            <div className={styles.PermissionIcon}><OutlinedIcon>sync_problem</OutlinedIcon></div>
            <div className={styles.PermissionMessage}>Your account does not have permission to access "<b>Content Management System</b>".
                If you think you have reached this message in error, please contact an administrator</div>
        </div>
    </div>
}