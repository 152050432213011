import styles from "./OnOffSwitch.module.scss"
import {useState} from "react";
import OutlinedIcon from "../../../../ui/icon/OutlinedIcon";

export interface OnOffSwitchProp {
    defaultValue: boolean,
    onChange: (newValue:boolean) => void
    onString?: string,
    offString?: string,
}

export default function OnOffSwitch(props:OnOffSwitchProp) {
    const [on, setOn] = useState<boolean>(props.defaultValue);

    return <div className={styles.Root} onClick={(event) => { props.onChange(!on); setOn(!on); event.preventDefault(); event.stopPropagation()}}>
        <div className={styles.OnLabel + " " + (!on ? styles.HideLabel : "")}>{props.onString ? props.onString : "On"}</div>
        <div className={styles.OffLabel + " " + (on ? styles.HideLabel : "")}>{props.offString ? props.offString : "Off"}</div>
        <div className={styles.Indicator + " " + (on ? styles.IndicatorActive : "")}>
            <OutlinedIcon>done</OutlinedIcon>
        </div>

    </div>
}