import CodeMirrorEditor from "./CodeMirrorEditor";
import SettingPanel from "../panel/SettingPanel";
import {css} from "./CodeMirrorCssCustom";

export interface CordMirrorCssEditorProps {
    title: string,
    value?: string,
    defaultValue?: string
    className?: string,
    inputProps?: any,
    onChange: (newValue:string) => void
    codeClassName?: string
    caption?: string;
}

export default function CodeMirrorCssEditor(props:CordMirrorCssEditorProps) {

    return <SettingPanel title={props.title} className={props.className}>
        {props.caption && <div style={{color:"gray" ,textAlign:"end", padding: "8px", fontSize: "12px"}}>{props.caption}</div> }
        <CodeMirrorEditor
            inputProps={props.inputProps}
            defaultValue={props.defaultValue}
            className={props.codeClassName}
            language={css()}
            onChange={props.onChange}
        />
    </SettingPanel>
}