import { Button } from "@mui/material";
import styles from "./LinkButton.module.scss";
import withStyles from '@mui/styles/withStyles';

const ButtonCustom  =  withStyles({
    root: {
        border: "1px solid transparent",
        borderRadius: "6px",
        backgroundColor: "transparent",
        color: "var(--bt-cms-color-primary-normal)",
        textTransform: "none",
        fontSize: "14px",
        height: "32px",
        "&:hover": {
            transition: "background-color .3s,border-color .3s,box-shadow .3s,color .3s",
            backgroundColor: "#fff",
            borderColor: "var(--bt-cms-color-primary-normal)",
            boxShadow: "0 1px 2.5px 0 rgb(0 0 0 / 26%), 0 1px 5px 0 rgb(0 0 0 / 16%)",
            textTransform: "none",
        },
        "&:disabled": {
            color: "gray",
            borderColor: "transparent"
        }
    },
    label: {

    }
})(Button);



export default function LinkButton(props) {
    return <ButtonCustom {...props} variant="outlined" className={props.className? props.className + " " + styles.Root : styles.Root}>{props.children}</ButtonCustom>
}