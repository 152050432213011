import styles from "./Slick.module.scss"
import React, {useCallback, useEffect, useState} from "react";
import useWindowSize from "../../../hook/useWindowSize";
import {StyleUtil} from "../../setting/StyleUtil";
import SVGArrow from "../svgarrow/SVGArrow";
import {nanoid} from "nanoid";


export interface SlickArrowProps {
    width: number,
    height: number,
    strokeSize: number,
    color: string,
    inlineMargin: string,
    blockMargin: string,
}

export interface SlickProps {
    infinite: boolean,
    targetItemWidth: number,
    targetItemHeight?: number,
    contentInlinePadding: number,
    itemsPerPage: number,
    scrollItems: number,
    autoplaySpeed: number,
    animationSpeedInMs: number,
    animationType: "slide"|"fade",
    children: JSX.Element|JSX.Element[],
    arrowProps: SlickArrowProps
    slickStyle: string;
}

interface SLickView {
    element: JSX.Element;
    index: number;
}

export default function Slick(props: SlickProps) {
    const {itemsPerPage,contentInlinePadding,targetItemWidth,targetItemHeight,infinite,animationSpeedInMs,animationType} = props;
    const scrollItems = Math.min(itemsPerPage,props.scrollItems);
    const totalItems = props.children ? (Array.isArray(props.children) ? props.children.length : 1) : 0;
    const [currentSlick, setCurrentSlick] = useState<any>();
    const [isActive, setActive] = useState<boolean>(true);
    const [overflow, setOverflow] = useState<boolean>(false);
    const windowSize = useWindowSize();
    const [currentItem, setCurrentItem] = useState<number>(0);
    const [currentViews, setCurrentViews] = useState<SLickView[]>([]);
    const [refreshId,setRefreshId] = useState<string>(nanoid());
    const [itemWidth,setItemWidth] = useState<number>(0);

    const slickRef = useCallback((node) => {
        setCurrentSlick((l:any) => {
            if(node !== l) {
                return node;
            } else {
                return l;
            }
        });
    },[]);

    useEffect(() => {
        if(currentSlick) {
            let parent = currentSlick;
            let parentWidth = parent.clientWidth;

            let subWidth = (parentWidth / (Math.min(itemsPerPage,totalItems)));
            if(targetItemWidth > 0) {
                subWidth = targetItemWidth;
            }
            let childWidth = Array.isArray(props.children) ? props.children.length * subWidth : subWidth;
            let newOverflow = parentWidth < childWidth;
            if(newOverflow && overflow === false) {
                setOverflow(true);
            } else if(newOverflow === false && overflow) {
                setOverflow(false);
            }
            setItemWidth(subWidth);

        }
    },[windowSize,currentSlick,overflow,props.children])

    useEffect(() => {
        if(overflow && Array.isArray(props.children)) {
            let newArray = [];
            for(let i=-itemsPerPage;i<itemsPerPage*2;i++) {
                let currentIndex = i+currentItem;
                if(currentIndex < 0) {
                    let index = Math.abs( (totalItems + currentIndex) % totalItems);
                    newArray.push({
                        index: i,
                        element: props.children[index]
                    })
                } else {
                    let index = currentIndex % totalItems;
                    newArray.push({
                        index: i,
                        element: props.children[index]
                    })
                }
            }
            setCurrentViews(newArray);
        } else {
            if(Array.isArray(props.children)) {
                let newArray = [];
                for(let i=0;i<props.children.length;i++) {
                    newArray.push({
                        index: i,
                        element: props.children[i]
                    });
                }
                setCurrentViews(newArray);
            } else {
                setCurrentViews([{
                    element: props.children,
                    index: 0
                }]);
            }
        }
    },[refreshId,currentItem,isActive,props.children,overflow]);

    // useEffect(() => {
    //     let resize_ob = new ResizeObserver(function(entries) {
    //         if(currentSlick) {
    //
    //             let parent = currentSlick;
    //             console.log("HELLO",parent.clientWidth);
    //             let slickPanel = currentSlick.firstChild;
    //             let subWidth = (parent.clientWidth / (itemsPerPage+2));
    //             console.log("subWidth",subWidth);
    //             let width = subWidth + "px";
    //             slickPanel.style.width = (slickPanel.children.length * subWidth)  + "px";
    //             for(let i=0;i<slickPanel.children.length;i++) {
    //                 slickPanel.children[i].style.width = width;
    //             }
    //         }
    //     });
    //
    //     if(currentSlick) {
    //         resize_ob.observe(currentSlick);
    //     }
    //     return () => {
    //         if(currentSlick) {
    //             resize_ob.unobserve(currentSlick)
    //         }
    //        // resize_ob.disconnect()
    //     }
    // },[currentSlick])

    const doTransition = (nextItem:number) => {
        setActive(false);
        if(animationType === "slide") {
            let scrollPanel = currentSlick.firstChild;

            let move = itemsPerPage * itemWidth;
            let ship = scrollItems * itemWidth;
            move = nextItem < currentItem ? move - ship : move + ship;

            for (let i = 0; i < scrollPanel.children.length; i++) {
                scrollPanel.children[i].style.transitionDuration = `${animationSpeedInMs}ms`;
                scrollPanel.children[i].style.transform = `translate(-${move}px, 0)`;
            }
        } else {
            let scrollPanel = currentSlick.firstChild;
            if(nextItem < currentItem) {
                for (let i = 0; i < scrollPanel.children.length; i++) {
                    if(i >=0 && i < itemsPerPage) {
                        scrollPanel.children[i].style.opacity = 1;
                        scrollPanel.children[i].style.zIndex = 999;
                    } else {
                        scrollPanel.children[i].style.opacity = 0;
                        scrollPanel.children[i].style.zIndex = undefined;
                    }
                }
            } else {
                for (let i = 0,len = scrollPanel.children.length; i < len; i++) {
                    if(i >= len-itemsPerPage) {
                        scrollPanel.children[i].style.opacity = 1;
                        scrollPanel.children[i].style.zIndex = 999;
                    } else {
                        scrollPanel.children[i].style.opacity = 0;
                        scrollPanel.children[i].style.zIndex = undefined;
                    }
                }
            }
        }

        setTimeout(() => {
            setCurrentItem(nextItem);
            setActive(true);
            updatePosition();
        }, 350);
    }

    const updatePosition = () => {
        setRefreshId(nanoid());
    }

    const handleNext = (event:React.MouseEvent<any>) => {
        event.preventDefault();
        event.stopPropagation();
        if(isActive === false || overflow === false) {
            return;
        }
        if(infinite) {
            doTransition(currentItem + scrollItems);
        } else {
            doTransition(Math.min(totalItems-1,currentItem + scrollItems));
        }


    }

    const handleNothing = (event:React.MouseEvent<any>) => {
        event.stopPropagation();
        event.preventDefault();
    }

    const handleBack = (event:React.MouseEvent<any>) => {
        event.preventDefault();
        event.stopPropagation();
        if(isActive === false || overflow === false) {
            return;
        }

        if(infinite) {
            doTransition(currentItem - scrollItems);
        } else {
            doTransition(Math.max(0,currentItem - scrollItems));
        }




    }

    const handleMouseUp = (event: React.PointerEvent<any>) => {
        if(event.currentTarget.getAttribute("data-slick-drag") === "true") {
            event.currentTarget.setAttribute("data-slick-drag", "false");
            event.stopPropagation();
            event.preventDefault();

            let x = parseInt(event.currentTarget.getAttribute("data-slick-x"))
            let diffX = event.clientX - x;
            let moveBack = itemsPerPage * -itemWidth;
            let scale = Math.abs(diffX / itemWidth);
            if (scale < 0.5) { // move back
                for (let i = 0; i < currentSlick.firstChild.children.length; i++) {
                    currentSlick.firstChild.children[i].style.transitionDuration = `${animationSpeedInMs * scale}ms`;
                    currentSlick.firstChild.children[i].style.transform = `translate(${moveBack}px, 0)`;
                }
            } else { // move next
                if (diffX < 0) {
                    if (infinite) {
                        doTransition(currentItem + scrollItems);
                    } else {
                        doTransition(Math.max(0, currentItem + scrollItems));
                    }
                } else {
                    if (infinite) {
                        doTransition(currentItem - scrollItems);
                    } else {
                        doTransition(Math.max(0, currentItem - scrollItems));
                    }
                }
            }
        }

    }

    const handleMouseDown = (event: React.PointerEvent<any>) => {
        event.stopPropagation();
        event.preventDefault();
        if(isActive === false || overflow === false) {
            return;
        }
        event.currentTarget.setAttribute("data-slick-drag","true");
        event.currentTarget.setAttribute("data-slick-x",event.clientX.toFixed(0));
    }

    const handleMouseMove = (event: React.PointerEvent<any>) => {
        event.stopPropagation();
        event.preventDefault();

        if(event.currentTarget.getAttribute("data-slick-drag") === "true") {
            let x = parseInt(event.currentTarget.getAttribute("data-slick-x"))
            let diffX = event.clientX - x;

            let moveBack = itemsPerPage * -itemWidth + diffX;
            for(let i=0;i<currentSlick.firstChild.children.length;i++) {
                currentSlick.firstChild.children[i].style.transitionDuration = "0s";
                currentSlick.firstChild.children[i].style.transform = `translate(${moveBack}px, 0)`;
            }
        }
    }

    const createArrow = () => {
        const {arrowProps} = props;
        if(overflow) {
            return <>
                <div className={StyleUtil.combineClasses(styles.NextButton)} style={{"right": arrowProps.inlineMargin, "top": arrowProps.blockMargin}} onMouseUp={handleNothing} onMouseDown={handleNothing} onClick={handleNext}>
                    <SVGArrow width={arrowProps.width} height={arrowProps.height} strokeWidth={arrowProps.strokeSize} strokeColor={arrowProps.color} variant={"right"} />
                </div>
                <div className={StyleUtil.combineClasses(styles.BackButton)} style={{"left": arrowProps.inlineMargin, "top": arrowProps.blockMargin}} onMouseUp={handleNothing} onMouseDown={handleNothing} onClick={handleBack}>
                    <SVGArrow width={arrowProps.width} height={arrowProps.height} strokeWidth={arrowProps.strokeSize} strokeColor={arrowProps.color} variant={"left"}/>
                </div>
            </>;
        } else {
            return <></>;
        }
    }


    const createFadeUI = (currentViews: SLickView[]) => {
        return currentViews.map((child,index) => {
                return <div key={`slick_${index}`}
                            className={styles.Panel}
                            style={{
                                transitionDuration: `${animationSpeedInMs}ms`,
                                transform: `translate(${(itemsPerPage * -itemWidth) * Math.floor(index / itemsPerPage)}px)`,
                                width: itemWidth + "px",
                                opacity: child.index >=0 && child.index < itemsPerPage ? 1 : 0,
                                zIndex: child.index >=0 && child.index < itemsPerPage ? 999 : "unset"
                            }}>
                    {child.element}
                </div>
            }
        )
    }

    const createSlideUI = (currentViews: SLickView[]) => {
        let moveBack = `${itemsPerPage * -itemWidth}px`;
        let defaultTransform = overflow ?  `translate(${moveBack}, 0)` : undefined;
        return currentViews.map((child,index) => {
                    return <div key={`slick_${index}`}
                                className={styles.Panel}
                                style={{
                                    transitionDuration: `${animationSpeedInMs}ms`,
                                    transform: defaultTransform,
                                    width: itemWidth + "px",
                                }}>
                        {child.element}
                    </div>
                }
            )
    }


    return <div className={StyleUtil.combineClasses(styles.Root,props.slickStyle)} style={{minHeight: targetItemHeight ? targetItemHeight + "px" : undefined, position: "relative"}}>
        <div  key={refreshId}  ref={slickRef} className={styles.Container}
              style={{
                    left: `${contentInlinePadding}px`,
                    right: `${contentInlinePadding}px`,

        }}>
            <div tabIndex={-1} className={styles.ScrollPanel}
                        onPointerUp={animationType === "slide" ? handleMouseUp : undefined}
                        onPointerLeave={animationType === "slide" ? handleMouseUp : undefined}
                        onPointerDown={animationType === "slide" ?handleMouseDown : undefined}
                        onPointerMove={animationType === "slide" ?handleMouseMove : undefined}>
            {
                animationType === 'slide' && createSlideUI(currentViews)
            }
            {
                animationType === 'fade' && createFadeUI(currentViews)
            }
            </div>

        </div>
        {   createArrow()   }
    </div>
}