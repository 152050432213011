import {useAppSelector} from "../../../../redux/hook";
import {selectViewState} from "../../../../redux/features/view/viewSlice";
import EditablePanel from "../../shared/editable/EditablePanel";
import styles from "../empty/Empty.module.scss";
import {EditableProps} from "../../shared/editable/EditableProps";

export default function Empty(props:EditableProps & {onDoubleClick: (event:React.MouseEvent) => void}) {
    let viewState = useAppSelector(selectViewState);

    if(viewState.isEdit) {
        return <EditablePanel {...props} themeName={""} suggestTags={[]} settingOptions={[]} settingGroups={[]} onChangeSettings={null}>
            <div className={styles.Root} >
                &pi;
            </div></EditablePanel>
    } else {
        return <div className={styles.Root} data-ui-type={props.uiType}>
            &pi;
        </div>;
    }
}