import {NewType} from "../../cms/model/NewOption";
import {SectionDesc} from "../../cms/model/desc/SectionDesc";
import {WidgetDesc} from "../../cms/model/desc/WidgetDesc";
import {Avatar, Divider, Icon, List, ListItem, ListItemAvatar, ListItemText} from "@mui/material";

export interface NewSettingProps  {
    newType: NewType,
    uiLevelStr: string
    sectionDescriptions: SectionDesc[],
    widgetDescriptions: WidgetDesc[],
    onInsertSection: (uiLevelStr: string, desc: SectionDesc) => void,
    onInsertWidget: (uiLevelStr: string, desc: WidgetDesc) => void,
}

export function NewSetting(props: NewSettingProps) {
    const handleSectionClick = (event:React.MouseEvent,index:number) => {
        props.onInsertSection(props.uiLevelStr,props.sectionDescriptions[index]);
    }

    const handleWidgetClick = (event:React.MouseEvent,index:number) => {
        props.onInsertWidget(props.uiLevelStr,props.widgetDescriptions[index]);
    }

    let sectionListItem;
    let widgetListItem;
    let {newType} = props;
    let hasSection = false;
    let hasWidget = false;


    if(newType === NewType.section_only|| newType === NewType.widget_or_section) {
        hasSection = true;
        sectionListItem = (<List style={{"color": "rgba(0, 0, 0, 0.6)"}}>
            {
                props.sectionDescriptions.map((item,index) => {
                    return (<ListItem key={index} button onClick={(event) => { handleSectionClick(event,index)} }>
                        <ListItemAvatar>
                            <Avatar>
                                <Icon>{item.sectionIcon}</Icon>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText  primary={item.sectionNickName} secondary={item.sectionDescription} />
                    </ListItem>)
                })

            }
        </List>);
    }

    if(newType === NewType.widget_only || newType === NewType.widget_or_section) {
        hasWidget = true;
        widgetListItem = (<List>
            {
                props.widgetDescriptions.map((item,index) => {
                    return (<ListItem key={index} button onClick={(event) => {handleWidgetClick(event,index)} }>
                        <ListItemAvatar>
                            <Avatar>
                                <Icon>{item.widgetIcon}</Icon>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={item.widgetNickName} secondary={item.widgetDescription}/>
                    </ListItem>);
                })

            }
        </List>);
    }
    return <div>
        { widgetListItem }
        { hasWidget && hasSection && <Divider /> }
        { sectionListItem }

            </div>
}