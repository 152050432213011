
import GoogleTagContext from "./GoogleTagContext";
import {useEffect} from "react";

export default function GoogleTagProvider(props: {children: any}) {

    useEffect(() => {
        if(process.env.REACT_APP_GOOGLE_TAG) {
            let head = document.getElementsByTagName("head")[0];
            let script = document.createElement("script");
            script.innerText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${process.env.REACT_APP_GOOGLE_TAG}');`
            head.append(script)
            return () => {
                script.remove();
            }
        }
    },[]);
    return <GoogleTagContext.Provider value={{}}>
        {props.children}
    </GoogleTagContext.Provider>
}