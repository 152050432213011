import styles from "./EditorFloatMenu.module.scss";

import React from "react";
import {DefaultBreadcrumbProps} from "./EditorBreadcrumbs";

export interface PageMenuProps {
    // type: string,
    // title?: string,
    // desc?: string,
    // link?: string
    // name?: string,
    children?: any
    style?: React.CSSProperties;
}

export default function EditorFloatMenu(props: PageMenuProps & DefaultBreadcrumbProps) {
    // let pageTitle: string = "";
    // // let desc: string = "";
    // let title: string = "";
    //
    // const searchHref = "/" + props.type.toLowerCase();
    // const configs = CMSMainMenuConfig.filter(config => config.href === searchHref);
    // if(configs && configs.length > 0) {
    //     title = configs[0].title;
    //
    //     if(props.title !== undefined) {
    //         pageTitle = props.title;
    //     } else {
    //         pageTitle = configs[0].pageTitle;
    //     }
    // } else {
    //     title = props.title ? props.title : CapitalizeString(props.type);
    //     pageTitle = props.title ? props.title : CapitalizeString(props.type);
    // }
    return (
        <React.Fragment>
            <div className={styles.FloatBar} style={props.style}>
                {/*<DefaultBreadcrumbs {...props} small={false}/>*/}
                {/*<Breadcrumbs className={styles.Breadcrumbs}*/}
                {/*             separator={<OutlinedIcon className={styles.BreadcrumbSeparator}>navigate_next</OutlinedIcon>}*/}
                {/*>*/}
                {/*    { props.name !== "home" && <Link id={`homeBreadcrumb`} data-action={"openHome"} href={"/home"}><Typography className={styles.DisablePanel}>{"CMS"}</Typography></Link>}*/}
                {/*    { props.name && <Link id={`${props.type}Breadcrumb`} data-action={`open${props.type}`} href={"/" + (props.link ? props.link : props.type.toLowerCase())}><Typography  className={styles.DisablePanel}>{title}</Typography></Link>}*/}
                {/*    { !props.name && <Typography>{pageTitle}</Typography> }*/}
                {/*    { props.name && props.name !== "" && <Typography>{props.name}</Typography> }*/}
                {/*</Breadcrumbs>*/}
                {props.children}
            </div>
        </React.Fragment>
    );
}