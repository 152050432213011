import {Button, Tooltip} from "@mui/material";
import styles from "./LinkButton.module.scss";
import withStyles from '@mui/styles/withStyles';

const ButtonCustom  =  withStyles({
    root: {

        borderRadius: "6px",
        backgroundColor: "transparent",
        color: "var(--standard-555555)",
        textTransform: "none",
        fontSize: "14px",
        height: "32px",
        width: "32px",
        maxWidth: "32px",
        minWidth: "32px",
        padding: "0px",
        borderColor: "var(--standard-coal-300)",
        "&:hover": {
            transition: "background-color .3s,border-color .3s,box-shadow .3s,color .3s",
            backgroundColor: "#fff",
            boxShadow: "0px 2px 6px #00000029",
            color: "var(--standard-jade-500)",
            borderColor: "var(--standard-coal-300)",
        },
        "&:disabled": {
            color: "gray",
            borderColor: "transparent"
        }
    },
    label: {

    }
})(Button);



export default function IconLinkButton(props) {
    if(props.tooltip) {
        return <Tooltip key={props.id} title={props.tooltip} arrow placement={"top"}>
            <ButtonCustom {...props} variant="outlined"
                                                                                    className={props.className ? props.className + " " + styles.Root : styles.Root}>{props.children}</ButtonCustom>
        </Tooltip>
    } else {
        return <ButtonCustom key={props.id} {...props} variant="outlined"
                             className={props.className ? props.className + " " + styles.Root : styles.Root}>{props.children}</ButtonCustom>
    }
}