
import {lsi} from "../../datafetcher/LSIDataFetcher";
import {DAMFile} from "./DAM";
import {InfiniteTableDataFetcher} from "../../datafetcher/InfiniteTableDataFetcher";
import {ListChildComponentProps} from "react-window";
import DAMCellRenderer from "./DAMCellRenderer";
import {nanoid} from "nanoid";
import {DAMSearchFilter} from "./DAMFilter";


export class DAMDataFetcher implements InfiniteTableDataFetcher {

    private data: { [key:number] : DAMFile }
    private deleteData: {[key:number]: boolean}
    private numFound: number;
    private batchSize: number;

    constructor(private path:string,
                private searchFilter:DAMSearchFilter,
                private onChangePath: (path:string) => void,
                private onSelectFile: (path:string) => void,
                private mode: "edit"|"select") {
        this.batchSize = 100;
        this.numFound = 0;
        this.data = {};
        this.deleteData = {};
    }

    getCurrentPath(): string {
        return this.path;
    }
    getBatchSize(): number {
        return this.batchSize;
    }

    getTotalRecord(): number {
        return this.numFound;
    }

    isItemLoaded(row:number): boolean {
        if(this.data[row]) return true;
        else return false;
    }

    getCellHeightAt(index:number) {
        if(this.deleteData[index]) return 0;
        else if(this.searchFilter.recursive) {
            return 60;
        } else {
            return 40;
        }
    }

    getCellRendererAt(reloadFunc: (auto:boolean,itemCount: number) => void ,props: ListChildComponentProps): JSX.Element {
        let {index} = props;
        let temp = this.data[index];
        if(temp) {
            return <DAMCellRenderer mode={this.mode} key={nanoid()} style={props.style} damFile={temp} showSubFolder={this.searchFilter.recursive}
                                    onDelete={() => {
                                            let index = temp.ORIGINAL_FILE_NAME.indexOf("/"); // remove orgcode name
                                            lsi.dam.rm(temp.ORIGINAL_FILE_NAME.substring(index+1)).then(() => {
                                                this.data = {};
                                                this.numFound = this.numFound-1;
                                                reloadFunc(true,this.numFound);
                                            });
                                        }
                                    }
                                    onSelectFile={this.onSelectFile}
                                    onChangePath={this.onChangePath}
                                    onRename={(oldName:string,newName: string) => {
                                            if(oldName === newName) return;
                                            let index = temp.ORIGINAL_FILE_NAME.indexOf("/");
                                            let path = temp.ORIGINAL_FILE_NAME.substring(index);
                                            index = path.lastIndexOf("/");
                                            path = path.substring(0,index+1);
                                            lsi.dam.rename(path + oldName,path + newName).then(() => {
                                               this.data = {};
                                               reloadFunc(true,this.numFound);
                                            });
                                        }
                                    }/>
        } else {
            return <DAMCellRenderer mode={this.mode} style={props.style} showSubFolder={this.searchFilter.recursive} onChangePath={() => {}}/>
        }
    }

    getDataAt(index: number): DAMFile {
        return this.data[index];
    }

    load() {
        let parent = this;
        return lsi.dam.list(this.searchFilter.query,this.searchFilter.recursive,this.path,0,this.batchSize).then((response) => {
                let files:DAMFile[] = response.data.response.docs;
                this.numFound = response.data.response.numFound;
                for(let i=0;i<files.length;i++) {
                    parent.data[i] = files[i]
                }
                return response;
            });
    }

    loadNext(startIndex:number, endIndex: number): Promise<any> | null {
        let parent = this;
        return lsi.dam.list(this.searchFilter.query,this.searchFilter.recursive,this.path,startIndex,(endIndex-startIndex)+1).then((response) => {
            let files:DAMFile[] = response.data.response.docs;
            for(let i=0;i<files.length;i++) {
                parent.data[startIndex+i] = files[i]
            }
            return response;
        });
    }
    //
    // getColumnNameAt(col: number): string {
    //     if(col === NAME_COLUMN) {
    //         return "File";
    //     } else if(col === PREVIEW_COLUMN) {
    //         return "Preview"
    //     } else if(col === LAST_MODIFY_COLUMN) {
    //         return "Last Modified"
    //     } else if(col === SIZE_COLUMN) {
    //         return "Size"
    //     } else if(col === CLONE_COLUMN) {
    //         return "Actions"
    //     } else {
    //         return "";
    //     }
    // }
    //
    // getNumberOfColumns(): number {
    //     return TOTAL_COLUMN;
    // }


}