import React, {useEffect, useRef, useState} from 'react';
import styles from "./Footers.module.scss";
import commonStyle from "../../config/Common.module.scss";
import StyledTable from "../table/StyledTable";
import {FooterDataFetcher} from "../../datafetcher/FooterDataFetcher";
import {useAppDispatch} from "../../redux/hook";
import {useHistory} from "react-router";
import {useParams} from "react-router-dom";
import {viewResetAction} from "../../redux/features/view/viewSlice";
import {InputModel} from "../../model/InputModel";
import {useOrganization} from "../../hook/useOrganization";
import HttpClient from "../../datafetcher/HttpClient";
import {nanoid} from "nanoid";
import InputDialog from "../dialog/InputDialog";
import SolidIcon from "../icon/SolidIcon";
import PageSummary from "../pagesummary/PageSummary";
import IconLinkButton from '../button/IconLinkButton';
import ReverseSolidLinkSmallButton from '../button/ReverseSolidLinkSmallButton';
import {CurrentISODate} from "../../util/DateUtil";
import OutlinedIcon from "../icon/OutlinedIcon";
import {jcr} from "../../datafetcher/JCRDataFetcher";

export default function Footers() {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const query = new URLSearchParams(history.location.search);
    const params:any = useParams<{fid: string}>();
    const [newOpen, setNewOpen] = useState(false);
    const [updateData, setUpdateData] = useState<any>({});
    const [footerId, setFooterId] = useState("");
    const [formKey , setFormKey] = useState("");
    const [deleteList,setDeleteList] = useState<string[]>([]);
    const [dataFetcher, setDataFetcher] = useState<FooterDataFetcher|null>(null);
    const apiName = "footer"
    const apiNames = "footers"
    const  orgCmdProp = useOrganization();
    const inputFile = useRef<HTMLInputElement>(null);

    const handleCloseNewDialog = () => {
        setNewOpen(false);
    }

    const handleOpenNewDialog = () => {
        setNewOpen(true);
    }

    const handleUploadDialog = () => {
        if(inputFile !== null && inputFile.current !== null) {
            inputFile.current.click();
        }
    }

    const doUploadFile = () => {
        if(inputFile && inputFile.current) {
            if(inputFile.current.files) {
                jcr.footer.upload(inputFile.current.files).then((response) => {
                    if(!(orgCmdProp && orgCmdProp.currentOrg !== null && orgCmdProp.orgDetail !== null)) return;
                    setDataFetcher(new FooterDataFetcher(orgCmdProp.currentOrg,() => {
                            dispatch(viewResetAction());
                        }
                    ));
                    setFormKey(nanoid());
                })
            }
        }
    }

    const handleUpdateDialog = (values: InputModel[]) => {
        let data: any = updateData;
        values.forEach(object => {
            data[object.id] = object.defaultValue;
        })
        data['createdAt'] = updateData.createdAt;
        data['modifiedAt'] = new Date().getTime();

        HttpClient.post("/api/v0/jcr/org/" +  orgCmdProp?.currentOrg!.orgCode+ "/lite/" + apiNames , data).then((response) => {

            history.push({
                pathname: "/" + apiNames,
                search: history.location.search
            });
            setUpdateData({})
            setFooterId("");
            setNewOpen(false);
            setFormKey(nanoid());


        }).catch((e) => {
            console.log(e);
        })
    }

    const handleSaveDialog = (values: InputModel[]) => {
        let data: any = {};
        values.forEach(object => {
            data[object.id] = object.defaultValue;
        })
        data['createdAt'] = new Date().getTime();
        data['modifiedAt'] = new Date().getTime();

        HttpClient.post("/api/v0/jcr/org/" + orgCmdProp?.currentOrg!.orgCode + "/lite/" + apiNames , data).then((response) => {
            setFooterId("");
            setUpdateData({});
            setNewOpen(false);
            setFormKey(nanoid());

            history.push({
                pathname: history.location.pathname + "/" + response.data.response.footerName + "/" + response.data.response.id ,
                search: history.location.search
            })
        }).catch((e) => {
            console.log(e);
        })

    }

    const handleDelete = () => {
        if(window.confirm("Are you sure to remove?")) {
            HttpClient.delete("/api/v0/jcr/org/" + orgCmdProp?.currentOrg!.orgCode + "/footer/" + deleteList.join(",")).then((response) => {
                setDeleteList([]);
                setFormKey(nanoid());
            }).catch((e) => {
                setDeleteList([]);
            })
        }
    }

    const handleDownloadList = () => {
        HttpClient.download(`Footers-${CurrentISODate()}.gz`,
            "/api/v0/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/download/footers/" + deleteList.join(","));
        setDeleteList([]);
        setFormKey(nanoid());
    }


    if (params.fid && footerId !== params.fid) {
        setTimeout(() => {
            setFooterId(params.fid);
        }, 100);
    }

    useEffect(() => {
        if(!(orgCmdProp && orgCmdProp.currentOrg !== null && orgCmdProp.orgDetail !== null)) return;
        if(footerId !== "") {
            HttpClient.get("/api/v0/jcr/org/" + orgCmdProp?.currentOrg!.orgCode + "/" + apiName + "/" +  footerId).then((response) => {
                setUpdateData(response.data.response);
                setFormKey(nanoid());
            }).catch((e) => {
                console.log(e);
            })
        }


    },[footerId,orgCmdProp,dispatch])

    useEffect(() => {
        if(!(orgCmdProp && orgCmdProp.currentOrg !== null && orgCmdProp.orgDetail !== null)) return;
        setDataFetcher(new FooterDataFetcher(orgCmdProp.currentOrg,() => {
                dispatch(viewResetAction());
            }
        ));
        setFormKey(nanoid());
    },[orgCmdProp,dispatch])

    const handleCloseUpdateDialog = () => {
        setUpdateData({});
        setFooterId("");
        history.push({
            pathname: '/pages',
            search: history.location.search
        });
    }

    const handleAction = (tag:string, tagObject: any) => {
        if(tag === "DUPLICATE") {
            HttpClient.get( "/api/v0/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/clone/" + apiNames + "/" +  tagObject)
                .then((response) => {
                    let id = response.data.response;
                    history.push({
                        pathname: `${history.location.pathname}/${tagObject.viewName}/${id}`,
                        search: history.location.search
                    })
                })
        } else if(tag === "DOWNLOAD") {
            HttpClient.download(
                `Footer-${tagObject.footerName}-${tagObject.id}.gz`,
                "/api/v0/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/download/routes/" +  tagObject.id,
            );
        }
    }

    const newButton = <IconLinkButton tooltip={"Add New Footer"} key="newFooterButton" id="newFooterButton" data-action="createNewFooter" variant={"contained"} onClick={handleOpenNewDialog}>
        <SolidIcon className={styles.Icon} >add_circle_outline</SolidIcon>
    </IconLinkButton>;

    const uploadButton = <IconLinkButton tooltip={"Upload File"} key="uploadFooterButton" id="uploadFooterButton" data-action="uploadNewFooter" variant={"contained"} onClick={handleUploadDialog}>
        <OutlinedIcon className={styles.Icon} >cloud_upload</OutlinedIcon>
    </IconLinkButton>;

    const deleteButton = <ReverseSolidLinkSmallButton id="deleteFooterButton" data-action="deleteFooters" variant={"contained"} onClick={handleDelete}>
        <OutlinedIcon className={styles.Icon} >delete</OutlinedIcon>
        Remove ({deleteList.length})
    </ReverseSolidLinkSmallButton>;

    const downloadButton = <ReverseSolidLinkSmallButton id="downloadFooterButton" data-action="downloadFooters" variant={"contained"} onClick={handleDownloadList}>
        <OutlinedIcon className={styles.Icon} >download</OutlinedIcon>Download ({deleteList.length})
    </ReverseSolidLinkSmallButton>;

    return <React.Fragment>
        <div className={commonStyle.ContentContainer + " " + commonStyle.ContentContainerOverflowX}>
            <PageSummary type={"footers"}/>
            {   newOpen &&
                <InputDialog
                    id={"footer"}
                    title={"New Footer"}
                    handleClose={handleCloseNewDialog}
                    handleSave={handleSaveDialog}
                    open={newOpen}
                    inserted={true}
                    fixHeight={false}
                    fields={[
                        {id: "footerName", label: "Name", defaultValue: "", required: true , editable: true},
                    ]}
                    contentText={null}/>
            }
            {footerId !== "" && updateData.id &&
                <InputDialog
                    id={"footer"}
                    title={"Rename Footer"}
                    handleClose={handleCloseUpdateDialog}
                    handleSave={handleUpdateDialog}
                    open={true}
                    inserted={false}
                    fixHeight={false}
                    fields={[
                        {id: "footerName", label: "Name", defaultValue: updateData.footerName, required: true , editable: true},
                    ]}
                    contentText={null}/>
            }
            <StyledTable  key={formKey}
                          handleDeleteLists={(ids) => setDeleteList(ids)}
                          handleAction={handleAction}
                          hasCheckBox={true}
                          dataFetcher={dataFetcher}
                          rowsPerPage={20}

                          title={"Footers"}
                          actionComponents={[uploadButton,newButton]}
                          deleteButton={deleteButton}
                          downloadButton={downloadButton}

                          page={parseInt((query.get("page") === null ? "0" : query.get("page")!))}
                          rowsPerPageOptions={[5,10,20,40,60,{label: "All" , value: -1}]}/>
            <input key={`upload_${formKey}`} type='file' id='upload-content'
                   accept=".gzip,.gz"
                   onChange={() => doUploadFile()}
                   ref={inputFile} style={{display: 'none'}}/>
        </div>

    </React.Fragment>
}