
import React, {useEffect, useState} from "react";
import {nanoid} from "nanoid";
import {DAMMetrics} from "./DAMMetrics";
import {useOrganization} from "../../hook/useOrganization";
import {DAMFolders} from "./DAMFolders";
import {DAMFileList} from "./DAMFileList";
import {DAMFilter, DAMSearchFilter} from "./DAMFilter";
import {useHistory} from "react-router";

export interface DAMFile {
    _lsi_id_:string,
    ORG: string,
    CONTENT_TYPE: string,
    SIZE: number,
    LAST_MODIFIED_DATE: number
    NAME: string,
    tag: string[],
    FILE_PATH: string;
    ORIGINAL_FILE_NAME: string,
}

export default function DAM() {
    const orgCmd = useOrganization();
    const history = useHistory();

    const [currentPath,setCurrentPath] = useState<string>( "");
    const [searchFilter, setSearchFilter] = useState<DAMSearchFilter>({query: "", recursive: false, byName: true});
    const [dashboardId,setDashboardId] = useState<string>(nanoid());

    const onChangePath = (path: string) => {
        history.push({
            pathname: history.location.pathname,
            search: "path=" + path
        })
        setCurrentPath(path);
    }

    // TODO: this might create a duplicate render on the same data, should revisit this problem again in the future
    useEffect(() => {
        let orgCode = orgCmd?.orgDetail?.orgCode;
        if(orgCode && orgCode !== "") {
            history.push({
                pathname: history.location.pathname,
            })
            setCurrentPath("/");
        }
    },[orgCmd?.orgDetail,history]);

    useEffect(() => {
        let orgCode = orgCmd?.orgDetail?.orgCode;
        const urlSearchParams = new URLSearchParams(history.location.search);
        let path = urlSearchParams.get("path");
        if(!path) {
            path = "/";
        }
        if(orgCode && orgCode !== "" && path && path !== currentPath) {
            setCurrentPath(path);
        }
    },[orgCmd?.orgDetail,history,currentPath])

    let orgCode = orgCmd?.orgDetail?.orgCode!;
    return <div>
        <DAMFilter mode="edit" orgCode={orgCode} currentPath={currentPath} defaultFilter={searchFilter} onSearch={setSearchFilter}/>
        <DAMMetrics key={dashboardId} orgCode={orgCode} currentPath={currentPath}/>
        <DAMFolders orgCode={orgCode}
                    currentPath={currentPath}
                    onChangePath={onChangePath}
        />
        <DAMFileList orgCode={orgCode}
                     currentPath={currentPath}
                     searchFilter={searchFilter}
                     onChangePath={onChangePath}
                     onSelectFile={(file) => console.log(file)}
                     mode={"edit"}
                     onUpdated={() => { setDashboardId(nanoid());/*updateDashboard();*/ }}/>
    </div>

}