import {
    Button,
    Checkbox, IconButton, Menu, MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Theme,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import {TableDataFetcher} from "../../datafetcher/TableDataFetcher";
import {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router";
import _ from 'lodash';
import Link from '../button/Link';
import {nanoid} from "nanoid";
import OutlinedIcon from "../icon/OutlinedIcon";
import styles from "./StyledTable.module.scss";
import {TableLinkModel} from "../../model/TableModel";

const StyledTableCell = withStyles((theme:Theme) => ({
    head: {
        backgroundColor: "var(--standard-color-white)",
        color: "var(--text-color-light-gray)",//theme.palette.common.white,
        padding: "0px",
        height: "40px",
        borderTop: "1px solid rgb(224, 224, 224)",
        fontWeight: 600,
    },
    body: {
        fontSize: 14,
        padding: "0px",
        height: "40px",
        backgroundColor: "var(--standard-color-white)",
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.action.hover,
        // },
    },
}))(TableRow);

const StyledEmptyCell = withStyles((theme) => ({
    root: {
        height: "3rem",
        textAlign: "center"
    },
}))(TableCell);


const StyledHeaderTableRow = withStyles((theme) => ({
    root: {

    },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    container: {
        width: "80%",
        minWidth: 600,
        maxWidth: "80%",
        margin: "auto",
        padding: "16px",
        boxShadow: "0px 2px 6px #00000029",
        borderRadius: "6px",
        boxSizing: "border-box",
        marginBlockEnd: "30px"
    },

    table: {
        minWidth: 530,
    },

    title: {
        color: "var(--text-color-coal-500)",
        fontSize: "20px",
        fontWeight: 500,
        lineHeight: "32px",
        paddingBlockEnd: "20px",
        display: "inline-block",
        width: "70%",
    },
    actionPanel: {
        display: "inline-flex",
        justifyContent: "end",
        lineHeight: "32px",
        width: "30%",
        gap: "5px"
    },
    checkbox: {
        color: "var(--standard-coal-300)",
        borderRadius: "10px",
        '&$checked': {
            color:  "var(--standard-jade-500)",
        },
        '&$indeterminate': {
            color:  "var(--standard-jade-500)",
        },
    },
    checked: {},
}));


// function createData(name: string,
//                     calories: number,
//                     fat: number,
//                     carbs: number,
//                     protein: number) {
//     return { name, calories, fat, carbs, protein };
// }

export interface StyledTableProps {
    hasCheckBox: boolean,
    rowsPerPageOptions: (number|{label:string , value: number})[]
    rowsPerPage: number
    dataFetcher: TableDataFetcher | null,
    page?: number
    title?: string,
    actionComponents?: JSX.Element[],
    deleteButton?: JSX.Element,
    downloadButton?: JSX.Element,
    templateButton?: JSX.Element,
    handleDeleteLists?: (ids: string[]) => void,
    handleAction?: (tag:string, tagObject:any) => void,
}

export default function StyledTable(props: StyledTableProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [menuOptions,setMenuOptions] = useState<TableLinkModel[]>([]);
    const history = useHistory();
    let classes = useStyles();
    const [loadPage,setLoadPage] = useState(false);
    //const [totalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(props.page ? props.page : 0);
    const [rowsPerPage, setRowsPerPage] = useState(props.rowsPerPage);
    const numberOfColumns = props.dataFetcher === null ? 0: props.dataFetcher.getNumberOfColumns();
    const [checkedList, setCheckedList] = useState<number[]>([]);
    const title = props.title ? props.title : "";
    const location = useLocation();
    const totalRecord = props.dataFetcher?.getTotalRecords() ? props.dataFetcher?.getTotalRecords() : 0;

    const handleChangePage = (event:any, newPage:number) => {
        setPage(newPage);

        history.replace({
            pathname: location.pathname,
            search: `?page=${newPage}&rows=${rowsPerPage}`
        });
    };

    const handleChangeRowsPerPage = (event:any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        history.replace({
            pathname: location.pathname,
            search: `?page=${page}&rows=${rowsPerPage}`
        });
    };

    useEffect(() => {
        if(props.dataFetcher !== null) {
            props.dataFetcher!.load()!.then(() => {
                // setTotalRecord(props.dataFetcher!.getTotalRecords());
                setLoadPage(true);
            })
        }
    },[props.dataFetcher]);

    if(loadPage === false) {
        return <div></div>
    }

    const handleOnCheckboxChange = (item: number) => () => {
        let index = checkedList.indexOf(item);

        if(index >= 0) { // remove
            let newCheckList = checkedList.filter((data) => data !== item);
            setCheckedList(newCheckList);
            processCheckList(newCheckList);
        } else { // add
            let newCheckList = [...checkedList];
            newCheckList.push(item);
            setCheckedList(newCheckList);
            processCheckList(newCheckList);
        }

    }

    const handleCheckedAll = () => {
        if(checkedList.length > 0) {
            setCheckedList([]);
            processCheckList([]);
        } else {
            let checked = _.range(0,totalRecord);
            setCheckedList(checked)
            processCheckList(checked);
        }
    }

    const processCheckList = (indexes: number[]) => {
        if(props.handleDeleteLists !== null && props.dataFetcher !== null) {
            let ids: string[] = [];
            indexes.forEach(index => {
                    let data = props.dataFetcher!.getItemAt(index);
                    if (data !== null) {
                        if (data['id']) {
                            ids.push(data.id);
                        } else if(data['orgCode']) {
                            ids.push(data.orgCode);
                        }
                    }
                }
            )
            props.handleDeleteLists!(ids);
        }
    }

    // const handleMenu = (event:any) => {
    //     event.stopPropagation();
    //     event.preventDefault();
    // }

    return (
        <TableContainer component={Paper} classes={{root: classes.container}} className={styles.SmallPanel} >
            { <Menu
                style={{pointerEvents: "none"}}
                anchorEl={anchorEl}
                open={anchorEl !== null}
                onBlur={() => setAnchorEl(null)}
                hideBackdrop={true}>
                <div style={{pointerEvents: "auto"}}>
                    {menuOptions.map((option,index) => <MenuItem dense={true} key={`styledtable_menuitem_${index}`} onClick={(e) => {
                        if(option.action) {
                            if (props.handleAction) {
                                props.handleAction(option.action.tag, option.action.tagObject);
                            }
                        } else {
                            history.push({pathname: option.pathname,
                                search: option.search === null ? "": new URLSearchParams(option.search).toString()});
                        }

                    }}>
                        <OutlinedIcon className={styles.IconSize}>{option.icon}</OutlinedIcon> &nbsp;{option.label}
                    </MenuItem>)}
                </div>
            </Menu>}
            <div className={classes.title}>
                {title}
            </div>
            <div className={classes.actionPanel}>
                {props.actionComponents}
            </div>
            <div className={styles.DeletePanel + " " + (checkedList.length > 0 ? styles.ShowDeletePanel : styles.HideDeletePanel)}>
                <span>{ checkedList.length === 1 ? "1 Item Selected:" : `${checkedList.length} Items Selected:`}</span>
                {props.deleteButton} {props.downloadButton} {props.templateButton}
            </div>
            <Table className={classes.table} aria-label="customized table">
                <TableHead>
                    <StyledHeaderTableRow>
                        {props.hasCheckBox &&
                            <StyledTableCell padding="checkbox" >
                                <Checkbox
                                indeterminate={checkedList.length > 0 && checkedList.length !== totalRecord}

                                classes={{
                                    root: classes.checkbox,
                                    checked: classes.checked,
                                    indeterminate: classes.checked
                                }}
                                checked={checkedList.length === totalRecord}
                                onChange={handleCheckedAll}
                                inputProps={{ 'aria-label': 'select all' }}
                            /></StyledTableCell>
                        }
                        {
                            _.range(0,numberOfColumns).map((index:number) => {
                                let colName = props.dataFetcher!.getColumnNameAt(index);
                                let colAlign = props.dataFetcher!.getColumnAlignAt(index);
                                return (<StyledTableCell key={nanoid()} align={colAlign}>{colName}</StyledTableCell>)
                            })
                        }
                    </StyledHeaderTableRow>
                </TableHead>
                <TableBody>
                    {totalRecord === 0 && <StyledTableRow >
                        <StyledEmptyCell colSpan={ props.hasCheckBox ? numberOfColumns+1 : numberOfColumns}>No Record</StyledEmptyCell>
                        </StyledTableRow>}
                    {_.range(Math.min(page*rowsPerPage,Math.max(0,totalRecord-rowsPerPage)),Math.min(totalRecord,(page+1)*rowsPerPage)).map((rowNumber: number) => {
                        return (rowNumber < totalRecord && <StyledTableRow key={"row" + rowNumber}>
                                {props.hasCheckBox &&
                                    <StyledTableCell padding="checkbox">
                                        <Checkbox
                                            indeterminate={false}
                                            checked={checkedList.indexOf(rowNumber) >= 0}
                                            classes={{
                                                    root: classes.checkbox,
                                                    checked: classes.checked,
                                                    indeterminate: classes.checked
                                            }}
                                            // style={{color: "green"}}
                                            // className={styles.CheckBox}
                                            onChange={handleOnCheckboxChange(rowNumber)}
                                        /></StyledTableCell>}
                                {
                                _.range(0,numberOfColumns).map((colNumber:number) => {
                                    let id = props.dataFetcher!.getIdAt(rowNumber);
                                    let data = props.dataFetcher!.getDataAt(rowNumber,colNumber);
                                    let colAlign = props.dataFetcher!.getColumnAlignAt(colNumber);
                                    let subTitle:any = undefined;
                                    if(data.subTitle && data.subTitle !== "") {
                                        subTitle = <div>{data.subTitle}</div>;
                                    }
                                    if(data.options) {
                                        return <StyledTableCell key={id + colNumber} align={colAlign}>
                                            {
                                                data.options.filter((item,index) => index < 2).map((option,optionIndex) => <IconButton id={`iconbutton_${rowNumber}_${optionIndex}`} key={`iconbutton_${rowNumber}_${optionIndex}`} data-action={"openLink"} onClick={() => {
                                                    if(data.select) {
                                                        data.select(data.id);
                                                    }
                                                    if(option.action) {
                                                        if (props.handleAction) {
                                                            props.handleAction(option.action.tag, option.action.tagObject);
                                                        }
                                                    } else {
                                                        history.push({
                                                            pathname: option.pathname,
                                                            search: option.search === null ? new URLSearchParams(history.location.search).toString() : new URLSearchParams(option.search + "&" + history.location.search).toString()
                                                        });
                                                    }
                                                }}><OutlinedIcon className={styles.IconSize}>{option.icon}</OutlinedIcon></IconButton>)
                                            }
                                            {
                                                data.options.length > 2 && <>
                                                    <IconButton onClick={(event) => {
                                                        if(data.options) {
                                                            setMenuOptions(data.options.filter((item,index) => index >= 2))
                                                        } else {
                                                            setMenuOptions([]);
                                                        }
                                                        setAnchorEl(event.currentTarget);
                                                    }
                                                    }><OutlinedIcon>more_horizontal</OutlinedIcon></IconButton>
                                                </>
                                            }
                                        </StyledTableCell>
                                    } else if(data.link !== "") {
                                        if (data.select === undefined) {
                                            return (<StyledTableCell key={id + colNumber} align={colAlign}>
                                                <div><Link id={`link_${rowNumber}_${colNumber}`} data-action={`openLink`} to={data.link + location.search}>{data.title}</Link></div>
                                                {subTitle}
                                            </StyledTableCell>)
                                        } else {
                                            return (<StyledTableCell key={id + colNumber} align={colAlign}>
                                                <div><Link id={`link_${rowNumber}_${colNumber}`} data-action={`openLink`} to={data.link + location.search}
                                                           onClick={() => data.select!(data.id)}>{data.title}</Link></div>
                                                {subTitle}
                                            </StyledTableCell>)
                                        }
                                    } else if(data.select && !data.options) {
                                        return (<StyledTableCell key={id+ colNumber} align={colAlign}><Button size={"small"} onClick={() => {data.select!(id)}}>{data.title}</Button></StyledTableCell>)
                                    } else {
                                        return (<StyledTableCell key={id+ colNumber} align={colAlign}>
                                            <div>{data.component ? data.component : data.title}</div>
                                            {subTitle}
                                        </StyledTableCell>)
                                    }
                                })
                            }
                        </StyledTableRow>)

                    })}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        {totalRecord > props.rowsPerPage &&
                            <TablePagination
                                rowsPerPageOptions={props.rowsPerPageOptions}
                                colSpan={ numberOfColumns + (props.hasCheckBox ? 1 : 0)}
                                count={totalRecord}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                // SelectProps={{
                                //     inputProps: {'aria-label': 'rows per page'},
                                //     native: true,
                                // }}
                            />
                        }
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
}