import {useEffect, useState} from "react"
import {
    Box,
    Divider,
    Grid, GridSize,
    List,
    ListItem, ListItemButton, ListItemIcon,
    ListItemText,
    Step,
    StepLabel,
    Stepper, TextField
} from "@mui/material"
import styles from "./PublishSteps.module.scss"
import LinkButton from "../button/LinkButton";
import OutlinedButton from "../button/OutlinedButton";
import {BuildType} from "../../model/jcr/BuildType";
import {jcr} from "../../datafetcher/JCRDataFetcher";
import BuildVersionCmd from "../../model/jcr/BuildVersionCmd";
import VersionCmd, {
    getNextMajorVersion,
    getNextMinorVersion,
    getNextPatchVersion,
    getStartVersion, VersionEquals
} from "../../model/jcr/VersionCmd";
import OutlinedIcon from "../icon/OutlinedIcon";
import HttpClient from "../../datafetcher/HttpClient";

const steps = ['Select an environment', 'Select version', 'Summary'];

export interface PublishStepsProps {
    orgCode: string;
    onClose: () => void
    deployments: string[]
}

export default function PublishSteps(prop: PublishStepsProps) {
    const [activeStep, setActiveStep] = useState<number>(0);
    const [confirmRelease, setConfirmRelease] = useState<string>("");
    const [userConfirmRelease, setUserConfirmRelease] = useState<string>("");
    const [currentNote, setCurrentNote] = useState<string>("");
    const [currentEnvironment, setCurrentEnvironment] = useState<BuildType>(-1);
    const [buildVersions, setBuildVersion] = useState<BuildVersionCmd[]>([]);
    const [currentVersion, setCurrentVersion] = useState<{
        version: VersionCmd,
        shouldCreated: boolean,
        title: string,
    }>();
    const [publishPageList,setPublishPageList]= useState<string[]>([]);

    const handleSubmit = () => {
        if(!currentVersion) return;
        let buildEnvironment = "dev"
        if(currentEnvironment === BuildType.prod) {
            buildEnvironment = "prod"
        } else if(currentEnvironment === BuildType.staging) {
            buildEnvironment = "staging"
        }
        let buildVersion = `${currentVersion.version.major}.${currentVersion.version.minor}.${currentVersion.version.patch}`

        if(currentVersion.shouldCreated) { // build
            HttpClient.get("/api/jcr/orgs/" + prop.orgCode + `/build?buildEnvironment=${buildEnvironment}&buildVersion=${buildVersion}&buildNote=${encodeURIComponent(currentNote)}`).then((response) => {

            })
        } else { // deploy
            HttpClient.get("/api/jcr/orgs/" + prop.orgCode + `/deploy?buildEnvironment=${buildEnvironment}&buildVersion=${buildVersion}&buildNote=${encodeURIComponent(currentNote)}`).then((response) => {

            })
        }
        prop.onClose();
    }

    const handleNext = () => {
        setActiveStep((l) => l+1);
    }

    const handleBack = () => {
        setActiveStep((l) => l-1);
    }

    const handleChangeNote = (event:React.ChangeEvent<HTMLTextAreaElement>) => {
        setCurrentNote(event.currentTarget.value)
    }

    const handleSelectEnvironment = (buildType: BuildType) => {
        if(buildType !== currentEnvironment) {
            setCurrentVersion(undefined);
            setCurrentEnvironment(buildType);
        } else {
            setTimeout(() => {setActiveStep(1)},300);
        }
    }

    const handleSelectVersion = (version: VersionCmd, shouldCreated: boolean, title: string) => {
        setCurrentVersion({
            version: version,
            shouldCreated: shouldCreated,
            title: title
        })

        if(currentEnvironment === 0) {
            setConfirmRelease("");
            setUserConfirmRelease("");
        } else {
            setConfirmRelease(`release ${version.major}.${version.minor}.${version.patch}`);
            setUserConfirmRelease("");
        }
        setTimeout(() => {setActiveStep(2)},300);
    }

    useEffect(() => {
        if(currentEnvironment >= 0) {
            jcr.publish.buildVersions(currentEnvironment).then((response) => {
                setBuildVersion(response.data.response);
                setActiveStep(1)
            });
            let pageList: string[] = [];
            jcr.route.lite().then((response) => {
                if(response.data.response !== null) {
                    let viewlist: Set<string> = new Set([]);
                    //console.log(response.data.response);
                    response.data.response.forEach((object: any) => {
                        let buildenv: string ="";
                        switch(currentEnvironment){
                            case 0:{
                                buildenv = "dev";
                                break;
                            }
                            case 1:{
                                buildenv = "staging";
                                break;
                            }
                            case 2:{
                                buildenv = "prod";
                                break;
                            }
                        }
                        //console.log(buildenv);
                        if(object.routeEnvs.includes(buildenv)){
                            if(object.viewIds.length>0){
                                let viewIdsArr: string[] = object.viewIds;
                                for (const viewId of viewIdsArr) {
                                    //console.log(viewId);
                                    viewlist.add(viewId);
                                }
                            }
                        }
                    });
                   // console.log(currentEnvironment);
                    for (const viewId of Array.from(viewlist)) {
                       // console.log("viewId:"+viewId);
                        jcr.view.query(viewId).then((viewresponse) => {
                            if(viewresponse.data){
                                //console.log(viewresponse.data.response.viewName);
                                pageList.push(viewresponse.data.response.viewName);
                                //console.log(pageList);
                                setPublishPageList(pageList);
                            }
                        }).catch((e) => {

                        })
                    }

                }
            });
        }
    },[currentEnvironment])

    const createEnvironmentSelection = () => {
        let itemsWidth = 12 / prop.deployments.length;
        return <Grid container className={styles.EnvironmentPanel}>
            { prop.deployments.indexOf("dev") >= 0 && <Grid item xs={12} sm={itemsWidth as GridSize} md={itemsWidth as GridSize}
                   className={styles.EnvironmentSubPanel}>
                <OutlinedButton id="publishDevelopmentButton" data-action={"deployToDevelopment"} onClick={() => handleSelectEnvironment(BuildType.dev)}
                                className={currentEnvironment === 0 ? styles.EnvironmentButtonSelected : styles.EnvironmentButton}>DEVELOPMENT</OutlinedButton>
            </Grid>}
            { prop.deployments.indexOf("staging") >= 0 && <Grid item xs={12} sm={itemsWidth as GridSize} md={itemsWidth as GridSize}
                   className={styles.EnvironmentSubPanel}>
                <OutlinedButton id="publishStageButton" data-action={"deployToStage"} onClick={() => handleSelectEnvironment(BuildType.staging)}
                                className={currentEnvironment === 1 ? styles.EnvironmentButtonSelected : styles.EnvironmentButton}>STAGING</OutlinedButton>
            </Grid>}
            { prop.deployments.indexOf("prod") >= 0 && <Grid item xs={12} sm={itemsWidth as GridSize} md={itemsWidth as GridSize}
                   className={styles.EnvironmentSubPanel}>
                <OutlinedButton id="publishProductionButton" data-action={"deployToProduction"} onClick={() => handleSelectEnvironment(BuildType.prod)}
                                className={currentEnvironment === 2 ? styles.EnvironmentButtonSelected : styles.EnvironmentButton}>PRODUCTION</OutlinedButton>
            </Grid>}
        </Grid >
    }

    const VersionListItem = (title: string, version: BuildVersionCmd|VersionCmd , index:number, shouldCreate: boolean) => {
        let selectedClassName:string = "";

        if(currentVersion !== undefined) {
            if("imageName" in  version) {
                if (shouldCreate === currentVersion.shouldCreated &&
                    VersionEquals(currentVersion.version, version.version)) {
                    selectedClassName = styles.VersionItemSelected + " ";
                }
            } else {
                if (shouldCreate === currentVersion.shouldCreated &&
                    VersionEquals(currentVersion.version, version)) {
                    selectedClassName = styles.VersionItemSelected + " ";
                }
            }
        }

        if("imageName" in  version) {
            return <ListItem disableGutters key={'list_item_' + index} className={selectedClassName + (version.deployed ? styles.VersionItemDeployed : undefined)}>
                { version.deployed === false && <ListItemButton id={`versionItem`}  data-action={`${version.version.major}.${version.version.minor}.${version.version.patch}`} onClick={() => handleSelectVersion(version.version, shouldCreate, title)}  className={styles.VersionListItemButton}>
                    <ListItemIcon><OutlinedIcon>rocket</OutlinedIcon></ListItemIcon>
                    <ListItemText
                        primary={<span>{`${title}`}
                            <b>{`${version.version.major}.${version.version.minor}.${version.version.patch}`}</b></span>}
                    />
                </ListItemButton>}
                { version.deployed === true && <ListItemButton id={`versionItem`} data-action={`${version.version.major}.${version.version.minor}.${version.version.patch}`} onClick={() => {}}  className={styles.VersionListItemButton}><ListItemIcon><OutlinedIcon>rocket_launch</OutlinedIcon></ListItemIcon>
                    <ListItemText
                    primary={<span>{`${title}`}
                    <b>{`${version.version.major}.${version.version.minor}.${version.version.patch}`}</b></span>}
                    secondary={version.lastRecordedPullTime ? `Deployed at ${version.lastRecordedPullTime}` : ""}
                    /></ListItemButton>
                }
            </ListItem>
        } else {
            return <ListItem disableGutters key={'list_item_' + index} className={selectedClassName}>
                <ListItemButton  id={`versionItem`}  data-action={`${version.major}.${version.minor}.${version.patch}`} onClick={() => handleSelectVersion(version,shouldCreate,title)} className={styles.VersionListItemButton}>
                    <ListItemIcon><OutlinedIcon>rocket</OutlinedIcon></ListItemIcon>
                    <ListItemText primary={<span>{`${title}`} <b>{`${version.major}.${version.minor}.${version.patch}`}</b></span>}/>
                </ListItemButton>
            </ListItem>
        }
    }

    const createVersionSelection = () => {
        return <Grid container className={styles.VersionPanel}>
            <Grid item xs={12}>
                <span className={styles.VersionTitle}>
                    Select <b>{ currentEnvironment=== 0 ? "Development" : (currentEnvironment === 1 ? "Staging" : "Product") }</b> version
                </span>
            </Grid>

            <Grid item xs={12}>
                <List className={styles.VersionList}>
                    {
                        buildVersions.length > 0 && VersionListItem("Update and Deploy to latest release ",buildVersions[0],-1, true)
                    }
                    {
                        buildVersions.length === 0 && VersionListItem("Create and Deploy initial release ", getStartVersion(),-2, true)
                    }
                    {
                        buildVersions.length > 0 && VersionListItem("Create and Deploy patch release  ", getNextPatchVersion(buildVersions[0].version),-3, true)
                    }
                    {
                        buildVersions.length > 0 && VersionListItem("Create and Deploy minor release  ", getNextMinorVersion(buildVersions[0].version),-4, true)
                    }
                    {
                        buildVersions.length > 0 && VersionListItem("Create and Deploy major release  ", getNextMajorVersion(buildVersions[0].version),-5, true)
                    }
                    {
                        buildVersions.filter((version,index) => index > 0).map((version,index) => VersionListItem((version.deployed ? "Current release " : "Rollback to "),version,index, false))
                    }
                </List>
            </Grid>
            <Grid item xs={12} className={styles.NotePanel}>
                <TextField variant={"outlined"}
                            value={currentNote}
                           onChange={handleChangeNote}
                           maxRows={3}
                           multiline={true}
                           fullWidth
                           InputLabelProps={{ shrink: true }} label={"Release Note"}/>
            </Grid>
        </Grid>
    }

    const createSummary = () => {
        if(currentVersion === undefined) return <></>
        return <Grid container className={styles.SummaryPanel} spacing={2}>
                <Grid item xs={12} sm={12}>
                    <span className={styles.SummaryTitle}>
                        Summary
                    </span>
                    <Divider/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    Environment
                </Grid>
                <Grid item xs={12} sm={8}>
                    {currentEnvironment === 0 ? "DEVELOPMENT" : (currentEnvironment === 1 ? "STAGING" :"PRODUCTION")}
                </Grid>
                <Grid item xs={12} sm={4}>
                    Type
                </Grid>
                <Grid item xs={12} sm={8}>
                    {currentVersion.title}
                </Grid>
                <Grid item xs={12} sm={4}>
                    Version
                </Grid>
                <Grid item xs={12} sm={8}>
                    {`${currentVersion.version.major}.${currentVersion.version.minor}.${currentVersion.version.patch}`}
                </Grid>
            <Grid item xs={12} sm={4}>
                Page Names
            </Grid>
            <Grid item xs={12} sm={8}>
                {
                    publishPageList.map((str, index) => (
                        <li key={index}>{str}</li>
                    ))
                }
            </Grid>

                { confirmRelease && <Grid item xs={12} sm={12}>
                        <TextField label={`Type "${confirmRelease}" to confirm`}
                                   fullWidth size={"small"}
                                   value={userConfirmRelease}
                                   onChange={(event) => setUserConfirmRelease(event.target.value)}
                                   InputLabelProps={{shrink: true}}/>
                    </Grid> }
            </Grid>
    }

    let submitDisabled = false;
    if(activeStep === 0) {
        submitDisabled = currentEnvironment < 0
    } else if(activeStep === 1) {
        submitDisabled = currentVersion === undefined
    } else if(activeStep === 2) {
        if(confirmRelease !== "") {
            submitDisabled = userConfirmRelease !== confirmRelease;
        }
    }
    //activeStep === 2 ? !canSubmit : (activeStep === 0 ? currentEnvironment < 0 : confirmRelease !== "" && confirmRelease === userConfirmRelease


    return <Box className={styles.Container}>
            <Stepper activeStep={activeStep}>
                {
                    steps.map((label,index) => {
                        return <Step key={`step_${index}_${label}`}>
                            <StepLabel>{label}</StepLabel></Step>
                    })
                }
            </Stepper>
            {
                activeStep === 0 && createEnvironmentSelection()
            }
            {
                activeStep === 1 && createVersionSelection()
            }
            {
                activeStep === 2 && createSummary()
            }

            <Box className={styles.ActionPanel}>
                <LinkButton id={`prevPublishButton`} data-action={`moveToStep${activeStep-1}`} size={"small"} disabled={activeStep === 0} disableElevation={activeStep === 0} variant={activeStep === 0 ?  "outlined" : "outlined"} onClick={handleBack} className={styles.BackButton}>
                    Back
                </LinkButton>
                <LinkButton id={`nextPublishButton`} data-action={activeStep === 2 ?
                    `doPublish,` + ((currentEnvironment === 0 || currentEnvironment === undefined) ? "DEVELOPMENT" : (currentEnvironment === 1 ? "STAGING" :"PRODUCTION")) + "," + (currentVersion ? `${currentVersion.version.major}.${currentVersion.version.minor}.${currentVersion.version.patch}`: '' ):
                    `moveToStep${activeStep+1}`} size={"small"} disabled={submitDisabled} onClick={activeStep === 2 ? handleSubmit : handleNext} className={styles.NextButton}>
                    {activeStep === 2 ? "Submit" : "Next"}
                </LinkButton>
            </Box>
        </Box>
}
