import SettingProps from "../SettingProps";
// import CSSSetting from "../csssetting/CSSSetting";
import GenericSetting, {StyleSettingType} from "../genericsetting/GenericSetting";

export default function AlignSetting(props: SettingProps) {
    return <GenericSetting {...props}
                           title={"Alignment"}
                           type={"align"}
                           targets={[
                                    { name: "Text Align",target: 'text-align' , type: StyleSettingType.selections, options: [
                                            {label:"none", value: ""},
                                            {label:"left", value: "left"},
                                            {label:"right", value: "right"},
                                            {label:"center", value: "center"},
                                            {label:"justify", value: "justify"}
                                        ]},
                                    { name: "Vertical Align", target: 'vertical-align' , type: StyleSettingType.selections, options: [
                                            {label:"none", value: ""},
                                            {label:"baseline", value: "baseline"},
                                            {label:"sub", value: "sub"},
                                            {label:"super", value: "super"},
                                            {label:"text-top", value: "text-top"},
                                            {label:"text-bottom", value: "text-bottom"},
                                            {label:"middle", value: "middle"},
                                            {label:"bottom", value: "bottom"},
                                            {label:"unset", value: "unset"}
                                        ]}
                                ]}
    />
    // return <CSSSetting {...props}
    //                    title={"Alingment"}
    //                    type={"align"}
    //                    targets={["text-align","vertical-align"]}/>
}