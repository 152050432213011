
const pageRegEx = /\/pages\/.+\/.*/;
const headerRegEx = /\/headers\/.+\/.*/;
const footerRegEx = /\/footers\/.+\/.*/;

export const isEditorPath = (pathname:string) => {
    if(pageRegEx.test(pathname) || headerRegEx.test(pathname) || footerRegEx.test(pathname)) {
        return true
    } else {
        return false;
    }
}

export const isPageEditorPath = (pathname:string) => {
    if(pageRegEx.test(pathname)) {
        return true
    } else {
        return false;
    }
}

export const isHeaderEditorPath = (pathname:string) => {
    if(headerRegEx.test(pathname)) {
        return true
    } else {
        return false;
    }
}

export const isFooterEditorPath = (pathname:string) => {
    if(footerRegEx.test(pathname)) {
        return true
    } else {
        return false;
    }
}