import {WidgetInterface} from "../../../model/WidgetInterface";
import {useAppSelector} from "../../../../redux/hook";
import {selectViewState} from "../../../../redux/features/view/viewSlice";
import {useOrganization} from "../../../../hook/useOrganization";
import React, {useContext, useEffect, useRef, useState} from "react";
import useWindowSize from "../../../hook/useWindowSize";
import {StyleUtil} from "../../setting/StyleUtil";
import {DeleteType} from "../../../model/DeleteOption";
import {SettingGroupType, SettingOption, SettingType} from "../../../model/SettingOption";
import EditablePanel from "../../shared/editable/EditablePanel";
import styles from "../imagelist/ImageList.module.scss";
import {nanoid} from "nanoid";
import {lsi} from "../../../../datafetcher/LSIDataFetcher";
import SVGArrow from "../../shared/svgarrow/SVGArrow";
import Images from "../../shared/images/Images";
import useWidgetState from "../../../../hook/useWidgetState";
import {ImageCmd} from "../../../model/ImageCmd";
import VariableContext from "../../../../context/VariableContext";

export default function ImageList(props:any) {
    const widgetData:WidgetInterface = props.data as WidgetInterface;
    const {widgetId,widgetProps} = widgetData;
    const viewState = useAppSelector(selectViewState);
    const orgCmd = useOrganization();
    const [products, setProducts] = useState<{[key: string] : any}[]>([]);
    const windowSize = useWindowSize();
    const rootRef = useRef<HTMLDivElement>(null);
    const [overFlow,setOverFlow] = useState<Boolean>(false);
    const [refreshId,setRefreshId] = useState(nanoid());
    const variableContext = useContext(VariableContext);
    useWidgetState(widgetId,(event) => {
        if(event && event.detail) {
            if(event.detail.action === "products") {
                setProducts(event.detail.value);
            } else {
                setRefreshId(nanoid());
            }
        } else {
            setRefreshId(nanoid());
        }
    })


    if(!widgetProps['content']) {
        widgetProps['content'] = {
            searchType: "BY_IDS",
            searchString: "",
            maxItems: "5",
            title: "Image List",
        }
    }

    if(StyleUtil.isDefaultStyle(widgetProps['panel'])) {
        widgetProps['panel'] = {
            style: {
                default: true,
                position: {
                    display: "flex",
                },
                dimension: {
                    'width': "100%"
                },
            }
        };
    }

    if(StyleUtil.isDefaultStyle(widgetProps['title'])) {
        widgetProps['title'] = {
            style: {
                default: true,
                typography: {
                    'font-size': '24px',
                    'font-weight': 'bold'
                },
                dimension: {
                    width: "100%"
                },
                padding: {
                    'padding-block-start': "8px",
                    'padding-block-end': "8px",
                    'padding-inline-start': '16px',
                    'padding-inline-end': '16px',
                }
            }
        };
    }



    if(StyleUtil.isDefaultStyle(widgetProps['image'])) {
        widgetProps['image'] = {
            style: {
                default: true,
                object : {
                    'object-fit': "cover"
                }
            }
        };
    }




    let displayType = widgetProps['content']['displayType'];
    let datasource = widgetProps['content']['datasource'] ? widgetProps['content']['datasource'] : "CUSTOM";
    let isCarousel = displayType === "CAROUSEL";
    let isWoven = displayType === "WOVEN";
    let isQuilted = displayType === "QUILTED";
    let imageWidth = isCarousel ? "100%" : "100px";
    let imageHeight = "auto";
    let numberOfColumns = 3;
    let numberOfRows = 2.5;
    let gap = "4px";
    if(displayType === "CAROUSEL") {
        if(widgetProps['content']['carouselWidth']) {
            imageWidth = widgetProps['content']['carouselWidth'];
        }
        if(widgetProps['content']['carouselHeight']) {
            imageHeight = widgetProps['content']['carouselHeight'];
        }
    } else {
        if(widgetProps['content']['columnWidth']) {
            imageWidth = widgetProps['content']['columnWidth'];
        }
        if(widgetProps['content']['columnHeight']) {
            imageHeight = widgetProps['content']['columnHeight'];
        }
        if(widgetProps['content']['columns']) {
            numberOfColumns = widgetProps['content']['columns'];
        }
        if(widgetProps['content']['rows']) {
            numberOfRows = widgetProps['content']['rows'];
        }
    }

    if(widgetProps['content']['gap']) {
        gap = widgetProps['content']['gap'];
    }

    if(isQuilted) { // always 4 columns
        numberOfColumns = 4;
    }


    const themeName = "image_list";
    let widgetStyles:any = {
        theme: StyleUtil.toThemeNameByType(widgetProps, themeName),
        current: StyleUtil.toClassFromWidget(widgetId, widgetProps, "current_style"),
        root: StyleUtil.toClassFromWidget(widgetId , widgetProps, "root"),
        panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "panel"),
        title: StyleUtil.toClassFromWidget(widgetId , widgetProps, "title"),

        sub_panel: isCarousel ? StyleUtil.toClassFromMap(widgetId, "sub_panel", {
            'flex-shrink' : '0',
            'width': imageWidth,
            'height': imageHeight
        }) : StyleUtil.toClassFromMap(widgetId, "sub_panel", {
            'display' : 'flex',
        })
            ,
        grid_panel: StyleUtil.toClassFromMap(widgetId, "grid_panel", {
            'display': 'grid',
            'grid-template-columns': `repeat(${numberOfColumns}, 1fr)`,
            'grid-auto-rows': imageHeight,
            'max-width': `calc(${imageWidth} * ${numberOfColumns} + (${gap} * (${numberOfColumns} - 1)))`,
            'max-height': `calc(${imageHeight} * ${numberOfRows} + (${gap} * (${numberOfColumns} - 1)))`,
            'overflow-y': 'auto',
            'overflow-x': 'hidden',
            'gap': `${gap}`,
        }),
        image_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "image_panel"),
        image_title: StyleUtil.toClassFromWidget(widgetId, widgetProps, "image_title"),
        image: StyleUtil.toClassFromWidget(widgetId, widgetProps, "image"),
    }


    if(isQuilted) {
        widgetStyles = {...widgetStyles,
            quilted_two_by_two: StyleUtil.toClassFromMap(widgetId, "quilted_two_by_two", {
                'grid-column-end':'span 2',
                'grid-row-end': 'span 2'
            }),
            quilted_one_by_one: StyleUtil.toClassFromMap(widgetId, "quilted_one_by_one", {
                'grid-column-end':'span 1',
                'grid-row-end': 'span 1'
            }),
            quilted_two_by_one: StyleUtil.toClassFromMap(widgetId, "quilted_two_by_one", {
                'grid-column-end':'span 2',
                'grid-row-end': 'span 1'
            })
        }
    }

    const createImagesFromUrls = (displayType: string, images: ImageCmd[]) => {
        let openLink = widgetProps['content']['openLink']
        if(!openLink || openLink === "") {
            openLink = "_self";
        }
        if(isCarousel) {
            return images.map((image, index) => {
                    let src: string[] = [];
                    src.push(variableContext.updateVariables(image.image_url))
                    let hasHref = image.image_href != null && image.image_href !== undefined && image.image_href != ""
                    return <div key={"image_" + index} className={StyleUtil.combineClasses(widgetStyles.sub_panel)}>
                        <div className={StyleUtil.combineClasses(styles.ImageCarouselPanel,"item_panel",widgetStyles.image_panel)}>
                            <div className={StyleUtil.combineClasses(styles.AnchorPanel,"item_image_panel",styles.Image)} style={viewState.isEdit ? {pointerEvents: "none"}: undefined}>
                                { hasHref && <a href={variableContext.updateVariables(image.image_href)} target={openLink}><Images src={src} width={"100%"} className={StyleUtil.combineClasses(styles.Image,"item_image",widgetStyles.image)}/></a> }
                                { hasHref === false && <Images src={src} width={"100%"} className={StyleUtil.combineClasses(styles.Image,"item_image",widgetStyles.image)}/> }
                            </div>
                        </div>
                    </div>
                }
            );
        } else {
            return images.map((image, index) => {
                    let src: string[] = [];
                    src.push(variableContext.updateVariables(image.image_url))
                    let hasHref = image.image_href != null && image.image_href !== undefined && image.image_href != ""
                    let additionalClass = "";
                    if(isQuilted) {
                        let remain  = index % 8;
                        if(remain === 0 || remain === 5) {
                            additionalClass = widgetStyles.quilted_two_by_two;
                        } else if(remain === 3 || remain === 4) {
                            additionalClass = widgetStyles.quilted_two_by_one;
                        } else {
                            additionalClass = widgetStyles.quilted_one_by_one;
                        }
                    } else if(isWoven) {
                        additionalClass = styles.ImageWovenPanel
                    }

                    return <div key={"image_" + index} className={StyleUtil.combineClasses(widgetStyles.sub_panel,additionalClass)}>
                        <div className={StyleUtil.combineClasses(styles.ImageRegularPanel,"item_panel",widgetStyles.image_panel)}>
                            <div className={StyleUtil.combineClasses(styles.AnchorPanel,"item_image_panel",styles.Image)} style={viewState.isEdit ? {pointerEvents: "none"}: undefined}>
                                { hasHref && <a href={variableContext.updateVariables(image.image_href)} target={openLink}><Images src={src} className={StyleUtil.combineClasses(styles.Image,"item_image",widgetStyles.image)}/></a> }
                                { hasHref === false &&<Images src={src} className={StyleUtil.combineClasses(styles.Image,"item_image",widgetStyles.image)}/> }
                            </div>
                        </div>
                    </div>
                }
            );
        }
    }

    const createImagesFromSearch = (hasCaption:boolean, captionField:string, displayType: string, ...imagesFields: string[]) => {

        if(isCarousel) {
            return products.length > 0 && products.map((product, index) => {
                    let src: string[] = [];
                    imagesFields.forEach(imageField => {
                        let data: string = product[imageField];
                        src.push(variableContext.updateVariables(data));
                    })
                    return <div key={"image_" + index} className={StyleUtil.combineClasses(widgetStyles.sub_panel)}>
                        <div className={StyleUtil.combineClasses(styles.ImageCarouselPanel,"item_panel",widgetStyles.image_panel)} style={viewState.isEdit ? {pointerEvents: "none"}: undefined}>
                            <div className={StyleUtil.combineClasses(styles.AnchorPanel,"item_image_panel",styles.Image)}>
                            <Images src={src} width={"100%"} className={StyleUtil.combineClasses(styles.Image,"item_image",widgetStyles.image)}/>
                             {hasCaption && <div className={StyleUtil.combineClasses(styles.Caption,"item_caption",widgetStyles.image_title)}>{product[captionField]}</div>}
                            </div>
                        </div>

                    </div>
                }
            );
        } else {
            return products.length > 0 && products.map((product, index) => {
                    let src: string[] = [];
                    imagesFields.forEach(imageField => {
                        let data: string = product[imageField];
                        src.push(variableContext.updateVariables(data));
                    })
                    let additionalClass = "";
                    if(isQuilted) {
                        let remain  = index % 8;
                        if(remain === 0 || remain === 5) {
                            additionalClass = widgetStyles.quilted_two_by_two;
                        } else if(remain === 3 || remain === 4) {
                            additionalClass = widgetStyles.quilted_two_by_one;
                        } else {
                            additionalClass = widgetStyles.quilted_one_by_one;
                        }
                    } else if(isWoven) {
                        additionalClass = styles.ImageWovenPanel
                    }

                    return <div key={"image_" + index} className={StyleUtil.combineClasses(widgetStyles.sub_panel,additionalClass)}>
                        <div className={StyleUtil.combineClasses(styles.ImageRegularPanel,"item_panel",widgetStyles.image_panel)}>
                            <div className={StyleUtil.combineClasses(styles.AnchorPanel,"item_image_panel",styles.Image)} style={viewState.isEdit ? {pointerEvents: "none"}: undefined}>
                            <Images src={src} className={StyleUtil.combineClasses(styles.Image,"item_image",widgetStyles.image)}/>
                                {hasCaption && <div  className={StyleUtil.combineClasses(styles.Caption,"item_caption",widgetStyles.image_title)}>{product[captionField]}</div>}
                            </div>
                        </div>
                    </div>
                }
            );
        }
    }


    const renderList = () => {

    }

    function renderImageListFromChildren(title:string, children: any, allowArrow: boolean) {
        return <div key={refreshId} className={widgetStyles.root + " il_root"}>
            { title && title !== "" && <div className={StyleUtil.combineClasses("il_title", widgetStyles.title)}>{title}</div>}
            <div className={styles.AnchorPanel + " " + widgetStyles.panel + " il_panel"}>
                <div ref={rootRef}
                     className={isCarousel ? StyleUtil.combineClasses(styles.Root, widgetStyles.theme, widgetStyles.current) :
                         StyleUtil.combineClasses(styles.RootGrid, widgetStyles.theme, widgetStyles.current, widgetStyles.grid_panel)}
                     style={{justifyContent: overFlow ? "start" : undefined}}>
                    {children}
                    {allowArrow && createArrow()}
                </div>
            </div>
        </div>
    }

    const createImageList = (allowArrow: boolean) => {
        if(datasource === "CUSTOM") {
            let images = widgetProps['content']['images'] ? widgetProps['content']['images'] : [];
            let title = widgetProps['content']['title'];
            let displayType = widgetProps['content']['displayType'];
            let children = createImagesFromUrls(displayType, images);
            return renderImageListFromChildren(title, children, allowArrow);
        } else if(datasource === "CATALOG" && products && products.length > 0) {
            let imageField0 = widgetProps['content']['imageField1'];
            let imageField1 = widgetProps['content']['imageField2'];
            let imageField2 = widgetProps['content']['imageField3'];
            let captionField = widgetProps['content']['captionField'];
            let title = widgetProps['content']['title'];
            let displayType = widgetProps['content']['displayType'];
            let hasCaption = widgetProps['content']['hasCaption'];

            let children:any  = undefined;

            if(!captionField) {
                captionField = "_lsi_cn_ProductID";
            }

            if(!imageField0) {
                imageField0 = "_lsi_cn_ImageThumb";
            }

            children = createImagesFromSearch(hasCaption,captionField,displayType,imageField0,imageField1,imageField2);

            return renderImageListFromChildren(title, children, allowArrow);
        } else {
            let title = widgetProps['content']['title'];
            if(!title) {
                return <div ref={rootRef} className={StyleUtil.combineClasses(styles.Root, widgetStyles.theme, widgetStyles.current, widgetStyles.panel)}
                            style={{justifyContent: overFlow ? "start" : "unset"}}></div>
            } else {
                return <div ref={rootRef} className={StyleUtil.combineClasses(styles.Root, widgetStyles.theme, widgetStyles.current, widgetStyles.panel)}
                            style={{justifyContent: overFlow ? "start" : "unset"}}>
                    <div className={StyleUtil.combineClasses("il_title",widgetStyles.title)}>{title}</div>
                </div>
            }
        }
    }

    const createArrow = () => {
        if(overFlow) {
            return <>
                <div className={StyleUtil.combineClasses(styles.NextButton,"il_arrow_right")}  onClick={() => {
                    let rootElement = rootRef.current!;
                    let childRect = rootElement.children[0].getBoundingClientRect();

                    rootRef.current!.scrollBy({top:0, left: childRect.width, behavior: 'smooth'})
                }
                }
                >
                    <SVGArrow width={30} height={70} strokeWidth={4} strokeColor={"white"} variant="right" />
                </div>
                <div className={StyleUtil.combineClasses(styles.BackButton,"il_arrow_left")} onClick={() => {
                    let rootElement = rootRef.current!;
                    let childRect = rootElement.children[0].getBoundingClientRect();
                    rootRef.current!.scrollBy({top:0, left: -childRect.width, behavior: 'smooth'})
                }
                }
                ><SVGArrow width={30} height={70} strokeWidth={4} strokeColor={"white"} variant={"left"}/>
                </div>
            </>;
        } else {
            return <></>;
        }
    }

    useEffect(() => {
        if(windowSize && rootRef !== null && rootRef.current !== null) {
            let rootElement = rootRef.current!;
            let rootBoundary = rootElement.getBoundingClientRect();
            let expectedOverflow = false;
            for (let i = 0; i < rootElement.children.length; i++) {
                let childBoundary = rootElement.children[i].getBoundingClientRect();
                let relative_x = childBoundary.x - rootBoundary.x;
                if (relative_x + childBoundary.width > rootBoundary.width) {
                    expectedOverflow = true;
                    break;
                }
            }
            if (overFlow !== expectedOverflow) {
                setOverFlow(expectedOverflow);
            }
        }
    },[windowSize, rootRef, products]);

    useEffect(() => {
        if(products.length === 0) {
            let rValue = lsi.search.widgetSearch(
                widgetProps['content']['searchType'],
                widgetProps['content']['searchString'],
                widgetProps['content']['start'],
                widgetProps['content']['maxItems'],
                widgetProps['content']['searchProfile']
            );
            if (rValue !== null) {
                rValue?.then((response) => {
                    setProducts(response.data.response.docs);
                });
            }
        }
    },[]);

    if(!orgCmd || !orgCmd.orgDetail) {
        return <></>
    }

    if(viewState.isEdit) {
        let deleteOption = {type: DeleteType.delete_with_rearrange, tag: null};
        const suggestTags: string[] = [
            "current",
            "current .il_root",
            "current .il_title",
            "current .il_arrow_right",
            "current .il_arrow_left",
            "current .il_panel",
            "current .item_panel",
            "current .item_image_panel",
            "current .item_image",
            "current .item_caption",
        ]
        let settingOptions:SettingOption[] = [
            { title: "", type: SettingType.widget,group: "content",
                editor: "imagelistsetting",
                additions: {
                    displayOptions: orgCmd?.orgDetail?.displayFieldOptions,
                    imageOptions: orgCmd?.orgDetail?.imageFieldOptions
                }, groupType: SettingGroupType.layout
            },

            { type: SettingType.dimension, group: "root", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "root", groupType: SettingGroupType.style},
            { type: SettingType.border, group: "root", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "root", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "root", groupType: SettingGroupType.advanced},

            { type: SettingType.flex, group: "panel", groupType: SettingGroupType.style},
            { type: SettingType.dimension, group: "panel", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "panel", groupType: SettingGroupType.style},
            { type: SettingType.border, group: "panel", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "panel", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "panel", groupType: SettingGroupType.advanced},


            { type: SettingType.typography, group: "title", groupType: SettingGroupType.style},
            { type: SettingType.align, group: "title", groupType: SettingGroupType.style},
            { type: SettingType.dimension, group: "title", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "title", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "title", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "title", groupType: SettingGroupType.advanced},


            { type: SettingType.background, group: "image_panel", groupType: SettingGroupType.style},
            { type: SettingType.dimension, group: "image_panel", groupType: SettingGroupType.style},
            { type: SettingType.border, group: "image_panel", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "image_panel", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "image_panel", groupType: SettingGroupType.advanced},


            { type: SettingType.typography, group: "image_title", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "image_title", groupType: SettingGroupType.style},
            { type: SettingType.align, group: "image_title", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "image_title", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "image_title", groupType: SettingGroupType.advanced},



            { type: SettingType.object, group: "image", groupType: SettingGroupType.style},

        ];

        return <EditablePanel className={styles.Root}
                                {...props}
                                themeName={themeName}
                                suggestTags={suggestTags}
                                settingOptions={settingOptions}
                                settingGroups={[
                                    {group: "content", groupName: "Content", groupType: SettingGroupType.layout},
                                    {group: "root", groupName: "Panel", groupType: SettingGroupType.style},
                                    {group: "title", groupName: "Title", groupType: SettingGroupType.style},
                                    {group: "panel", groupName: "Image Panel", groupType: SettingGroupType.style},
                                    {group: "image_panel", groupName: "Item Panel", groupType: SettingGroupType.style},
                                    {group: "image_title", groupName: "Item Caption", groupType: SettingGroupType.style},
                                    {group: "image", groupName: "Item Image", groupType: SettingGroupType.style},
                                ]}
                                deleteOption={deleteOption}>
            {createImageList(isCarousel)}
        </EditablePanel>
    } else {
        return createImageList(isCarousel);
    }

}