import axios, {AxiosResponse} from "axios";
import qs from "qs";
import {getGlobalSSO} from "./HttpClient";

import {getCurrentLsiURL} from "./LSIDataFetcher";
import {PortalResponse} from "../model/portal/PortalResponse";
import PortalLoginResponseDTO from "../model/portal/dto/api/PortalLoginResponseDTO";
import PortalUserResourceDTO from "../model/portal/dto/core/PortalUserResourceDTO";
import PortalTokenDTO from "../model/portal/dto/core/PortalTokenDTO";



const getPath = (path: string) => {
    if(path.startsWith("http")) {
        return path;
    } else {
        return getCurrentLsiURL() + "/portal/" + portalClient.portalOrg + path;
    }
}

const  PortalClient = () => {

    return  {
        portalDomain: "",
        portalProtocol: "",
        portalOrg: "",

        isReady: () => {
            return portalClient.portalDomain !== "" && portalClient.portalDomain !== undefined && portalClient.portalDomain !== null
        },

        setPortalBackEndURL: (path:string, orgCode:string) => {
            if(path === null) return;
            let index = path.indexOf("://")
            portalClient.portalOrg = orgCode
            portalClient.portalProtocol = path.substring(0,index)
            portalClient.portalDomain = path.substring(index+3)
        },

        anonymousSSO: (storefrontId: number) => {
            let globalSSO = getGlobalSSO()
            if(globalSSO === null) return null;
            const data = { 'storefrontId': storefrontId};
            const defaultOptions = {
                headers: {
                    'api_key': globalSSO.accessToken,
                    'content-type': 'application/x-www-form-urlencoded',
                    "x-portal-domain": portalClient.portalDomain,
                    "x-portal-protocol": portalClient.portalProtocol,
                },
                //withCredentials: true,
            };
            return axios.post(getPath("/api/user/anonuser/login"),qs.stringify(data), {...defaultOptions}).then((response: AxiosResponse<PortalResponse<PortalLoginResponseDTO>>) => {
                // console.log(response);
                setHttpClientToken(response.data.dto!, true);
                return response;
            });
        },

        refreshSSO: (refreshToken: string) => {
            let globalSSO = getGlobalSSO()
            const defaultOptions = {
                headers: {
                    'api_key': globalSSO.accessToken,
                    'content-type': 'application/x-www-form-urlencoded',
                    "x-portal-domain": portalClient.portalDomain,
                    "x-portal-protocol": portalClient.portalProtocol,
                },
                //withCredentials: true,
            };

            let data = { 'aRefreshToken': refreshToken};

            return axios.post(getPath("/api/oauth/refreshToken"), qs.stringify(data), {...defaultOptions}).then((response: AxiosResponse<PortalTokenDTO>) => {
                let newGlobalCmd = {...globalCmd, tokenDTO: response.data};
                setHttpClientToken(newGlobalCmd,globalIsAnonymous);
                return response;
            })
        },

        sso: (login: string, password: string) => {
            let globalSSO = getGlobalSSO()
            const defaultOptions = {
                headers: {
                    'api_key': globalSSO.accessToken,
                    'content-type': 'application/json',
                    "x-portal-domain": portalClient.portalDomain,
                    "x-portal-protocol": portalClient.portalProtocol,
                },
                //withCredentials: true,
            };

            const data = { 'username': login, 'password': password};
            //qs.stringify(data)
            return axios.post(getPath("/api/user/login"),data, {...defaultOptions}).then((response: AxiosResponse<PortalResponse<PortalLoginResponseDTO>>) => {
                setHttpClientToken(response.data.dto!, false);
                return response;
            });
        },



        get: <T = any, R = AxiosResponse<PortalResponse<T>>>(url: string, options = {}):Promise<R> => {
            let globalSSO = getGlobalSSO()
            const defaultOptions = {
                headers: {
                    "api_key": globalSSO.accessToken,
                    'Authorization': `Bearer ${globalCmd.tokenDTO.access_token}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                    "x-portal-domain": portalClient.portalDomain,
                    "x-portal-protocol": portalClient.portalProtocol,
                },
                //withCredentials: true,
                paramsSerializer: (params:any) => {
                    return qs.stringify(params, { arrayFormat: 'repeat' })
                }
            };
            return axios.get<T,R>(getPath(url), { ...defaultOptions, ...options })
        },

        post: <T = any, R = AxiosResponse<PortalResponse<T>>>(url: string, data: any, options = {}):Promise<R> => {
            let globalSSO = getGlobalSSO()
            const defaultOptions = {
                headers: {
                    "api_key": globalSSO.accessToken,
                    'Authorization': `Bearer ${globalCmd.tokenDTO.access_token}`,
                    "x-portal-domain": portalClient.portalDomain,
                    "x-portal-protocol": portalClient.portalProtocol,
                },
                //withCredentials: true,
                paramsSerializer: (params:any) => {
                    return qs.stringify(params, { arrayFormat: 'repeat' })
                }
            };
            return axios.post<T,R>(getPath(url), data, { ...defaultOptions, ...options })
        },

        postForm: <T = any, R = AxiosResponse<PortalResponse<T>>>(url: string, data:any, options = {}):Promise<R> => {
            let globalSSO = getGlobalSSO()
            const defaultOptions = {
                headers: {
                    "api_key": globalSSO.accessToken,
                    'Authorization': `Bearer ${globalCmd.tokenDTO.access_token}`,
                    "x-portal-domain": portalClient.portalDomain,
                    "x-portal-protocol": portalClient.portalProtocol,
                },
                //withCredentials: true,
                paramsSerializer: (params:any) => {
                    return qs.stringify(params, { arrayFormat: 'repeat' })
                }
            };

            return axios.post<T,R>(getPath(url), qs.stringify(data), { ...defaultOptions, ...options })
        },
        put: <T = any, R = AxiosResponse<PortalResponse<T>>>(url: string, data: any, options = {}):Promise<R> => {
            let globalSSO = getGlobalSSO()
            const defaultOptions = {
                headers: {
                    "api_key": globalSSO.accessToken,
                    'Authorization': `Bearer ${globalCmd.tokenDTO.access_token}`,
                    "x-portal-domain": portalClient.portalDomain,
                    "x-portal-protocol": portalClient.portalProtocol,
                },
                //withCredentials: true,
                paramsSerializer: (params:any) => {
                    return qs.stringify(params, { arrayFormat: 'repeat' })
                }
            };
            return axios.put<T,R>(getPath(url), data, { ...defaultOptions, ...options })
        },
        delete: <T = any, R = AxiosResponse<PortalResponse<T>>>(url: string, options = {}):Promise<R> => {
            let globalSSO = getGlobalSSO()
            const defaultOptions = {
                headers: {
                    "api_key": globalSSO.accessToken,
                    'Authorization': `Bearer ${globalCmd.tokenDTO.access_token}`,
                    "x-portal-domain": portalClient.portalDomain,
                    "x-portal-protocol": portalClient.portalProtocol,
                },
                //withCredentials: true,
                paramsSerializer: (params:any) => {
                    return qs.stringify(params, { arrayFormat: 'repeat' })
                }
            };
            return axios.delete<T,R>(getPath(url), { ...defaultOptions, ...options })
        },
    };
};

const portalClient = PortalClient();
let globalTimer:  NodeJS.Timeout;
let globalCmd: PortalLoginResponseDTO;
let globalResources: PortalUserResourceDTO;
let globalIsAnonymous: boolean;

//TODO convert to react state
export function usePortalSSO() : PortalLoginResponseDTO {
    return globalCmd;
}

export function getPortalResources() : PortalUserResourceDTO {
    return globalResources;
}

const setHttpClientToken = (ssoCmd: PortalLoginResponseDTO, isAnonymous: boolean) => {
    globalCmd = ssoCmd;
    globalIsAnonymous = isAnonymous;
    if(globalCmd !== null && globalCmd !== undefined) {
        let allQuery:any = []
        allQuery.push(portalClient.get("/api/website/all"));
        allQuery.push(portalClient.get("/api/branch/all"));
        allQuery.push(portalClient.get("/api/storefront/all"));
        Promise.all(allQuery).then( results => {
            globalResources = {} as PortalUserResourceDTO;
            globalResources.profiles = (results[0] as any).data.dtos;
            globalResources.branches = (results[1] as any).data.dtos;
            globalResources.storefronts = (results[2] as any).data.dtos;
            globalResources.defaultBranchId = globalCmd.userDTO.defaultBranchId;
            globalResources.defaultShipToId = globalCmd.userDTO.defaultShipToId;
            if((globalResources.defaultBranchId === undefined || globalResources.defaultBranchId === null) && globalResources.branches && globalResources.branches.length > 0) {
                globalResources.defaultBranchId = globalResources.branches[0].id;
            }
        });

        // portalClient.get("/api/user/resources").then((response) => {
        //     globalResources = response.data.dto!;
        //     if(globalResources.defaultBranchId === null && globalResources.branches && globalResources.branches.length > 0) {
        //         globalResources.defaultBranchId = globalResources.branches[0].id;
        //     }
        // })
        globalCmd.tokenDTO.expired_at = new Date().getTime() + globalCmd.tokenDTO.expires_in*1000;
        globalCmd.tokenDTO.refresh_expired_at = new Date().getTime() + globalCmd.tokenDTO.refresh_expires_in*1000;
        let remain = new Date(globalCmd.tokenDTO.expired_at).getTime() - Date.now() - 1*60*1000; // one minutes before expired, we create new request
        if(remain > 0) {
            if(globalTimer) {
                clearTimeout(globalTimer);
            }
            globalTimer = setTimeout(() => {
                updateToken();
            },remain);
        } else {
            updateToken();
        }
    } else {
        if(globalTimer) {
            clearTimeout(globalTimer);
        }
    }
}


function updateToken() {

    if(globalCmd !== null) {
        // console.log("refreshSSO");
        portalClient.refreshSSO(globalCmd.tokenDTO.refresh_token).then((response) => {

        })
        // portalClient.sso(globalCmd.userDTO.ecomUsername, globalCmd.userDTO.ecomPassword).then((response) => {
        //
        // });
    }

}

export default portalClient;
