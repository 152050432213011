import BaseIcon, {BaseIconProp} from "../baseicon/BaseIcon";
import _ from "lodash";
import styles from "./ColumnIcon.module.scss"

export interface ColumnIconProp {
    columns: number
    columnWidths: string[]
    onClick: (columns: number, columnWidths: string[]) => void
}

export default function ColumnIcon(props: BaseIconProp & ColumnIconProp) {
    return <BaseIcon tooltip={props.tooltip} label={props.label}>
        <div className={styles.Root} onClick={() => {
                props.onClick(props.columns,props.columnWidths);
            }
        }>
            {
                _.range(0,props.columns).map(index => <div key={`column_icon_${index}`} style={{width: `calc(${props.columnWidths[index]} - ${index%3}px)`}} className={index+1 >= props.columns ? styles.NoBar : styles.Bar}></div>)
            }
        </div>
    </BaseIcon>
}