import {ListChildComponentProps, VariableSizeList} from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import {useEffect, useRef, useState} from "react";
import {nanoid} from "nanoid";
import {InfiniteTableDataFetcher} from "../../datafetcher/InfiniteTableDataFetcher";

export interface InfiniteTableProps {
    hasCheckBox: boolean,
    dataFetcher: InfiniteTableDataFetcher | undefined,
    handleDeleteLists?: (ids: string[]) => void,
    height?: number
}

export default function InfiniteTable(props: InfiniteTableProps) {
    const {dataFetcher} = props;
    const [refreshId,setRefreshId] = useState<string|null>(null);
    const infiniteRef = useRef<any>();
    const [itemCount, setItemCount] = useState<number>(0);

    useEffect(() => {
        if(dataFetcher) {
            dataFetcher!.load()?.then(() => {
                setItemCount(dataFetcher.getTotalRecord());
                setRefreshId(nanoid());
            });
        }
    },[dataFetcher]);



    if(refreshId === null) {
        return <div></div>
    }

    const reloadFunc = (auto: boolean,newItemCount:number) => {
        if(infiniteRef.current !== null) {
            infiniteRef.current.resetloadMoreItemsCache(auto);
            if(itemCount !== newItemCount) {
                setItemCount(newItemCount);
            }
        }
    }

    const Row = (props: ListChildComponentProps ) : JSX.Element => {
        if(dataFetcher) {
            return dataFetcher.getCellRendererAt(reloadFunc,props);
        } else {
            return <div style={props.style}>&nbsp;</div>
        }
    };


    return (
        <InfiniteLoader
            key={refreshId}
            ref={infiniteRef}
            isItemLoaded={(index: number) => {
                if(dataFetcher) {
                    return dataFetcher.isItemLoaded(index);
                } else {
                    return false;
                }
            }}
            minimumBatchSize={dataFetcher ? dataFetcher.getBatchSize() : 100}
            itemCount={itemCount}
            loadMoreItems={(startIndex: number, stopIndex: number) => {
                return dataFetcher?.loadNext(startIndex,stopIndex)!;
            }}
        >
            {({ onItemsRendered, ref }) => {
                return <VariableSizeList
                    height={props.height ? props.height : 500}
                    width={"100%"}
                    itemCount={itemCount}
                    itemSize={(index:number) => dataFetcher ? dataFetcher.getCellHeightAt(index) : 40 }
                    onItemsRendered={onItemsRendered}
                    ref={ref}>
                    {Row}
                </VariableSizeList>
            }}
        </InfiniteLoader>
    )
}