import {InputOption} from "../../model/InputModel";
import {TemplateCmd} from "../../model/TemplateCmd";

export const Tag1Options:InputOption[] = [
    {label: "All Concepts" , value: "all_concepts"},
    {label: "Industrial Tools" , value: "industrial_tools"},
    {label: "Manufacturing" , value: "manufacturing"},
    {label: "Appliances" , value: "appliances"},
    {label: "Corporate" , value: "corporate"},
];

export const Tag2Options:InputOption[] = [
    {label: "All Categories" , value: "all_categories"},
    {label: "Maps" , value: "maps"},
    {label: "Testimonials" , value: "testimonials"},
    {label: "Text Content" , value: "text_content"},
];

export function Tag1ToName() {
    let temp:{[key: string] : string} = {}
    Tag1Options.forEach(item => temp[item.value] = item.label)
    return temp;
}

export function Tag2ToName() {
    let temp:{[key: string] : string} = {}
    Tag2Options.forEach(item => temp[item.value] = item.label)
    return temp;
}

const tag1ToName = Tag1ToName();
const tag2ToName = Tag2ToName();

export function FindTagName(template: TemplateCmd) {
    if(template.type === "page") {
        if(template.tags1.length > 0) {
            return tag1ToName[template.tags1[template.tags1.length - 1]] + ", PAGE";
        } else {
            return template.subTitle;
        }
    } else if(template.type === "section") {
        if(template.tags2.length > 0 && template.tags1.length > 0) {

            return tag1ToName[template.tags1[template.tags1.length - 1]] + ", " + tag2ToName[template.tags2[template.tags2.length - 1]];
        } else {
            return template.subTitle;
        }
    }
}


Object.seal(Tag1Options)
Object.seal(Tag2Options)