import {WidgetInterface} from "../../../model/WidgetInterface";
import {useAppSelector} from "../../../../redux/hook";
import {selectViewState} from "../../../../redux/features/view/viewSlice";
import styles from "./FieldGroup.module.scss";
import React, {useEffect, useRef, useState} from "react";
import {DeleteType} from "../../../model/DeleteOption";
import {SettingGroupType, SettingOption, SettingType} from "../../../model/SettingOption";
import EditablePanel from "../../shared/editable/EditablePanel";
import {lsi} from "../../../../datafetcher/LSIDataFetcher";
import {StyleUtil} from "../../setting/StyleUtil";
import useWindowSize from "../../../hook/useWindowSize";
import {nanoid} from "nanoid";
import {useOrganization} from "../../../../hook/useOrganization";
import {QueryType} from "../../../model/QueryType";
import useWidgetState from "../../../../hook/useWidgetState";


export default function FieldGroup(props:any) {
    const widgetData:WidgetInterface = props.data as WidgetInterface;
    const {widgetId,widgetProps} = widgetData;
    const viewState = useAppSelector(selectViewState);
    const orgCmd = useOrganization();
    const [products, setProducts] = useState<{[key: string] : any}[]>([]);
    const windowSize = useWindowSize();
    const rootRef = useRef<HTMLDivElement>(null);
    const [overFlow,setOverFlow] = useState<Boolean>(false);
    const [refreshId,setRefreshId] = useState(nanoid());
    const [groups,setGroups] = useState<string[]>(widgetProps['content'] && widgetProps['content']['attributeGroups'] ?  widgetProps['content']['attributeGroups'] : []);
    const [groupToFields,setGroupToFields] = useState<any>({});
    const [currentTab,setCurrentTab] = useState<number>(0);
    useWidgetState(widgetId, (event) => {
        if(event && event.detail) {
            if(event.detail.action === "products") {
                setProducts(event.detail.value);
            } else if(event.detail.action === "groups") {
                setGroups(event.detail.value);
                loadGroups(event.detail.value);
            } else {
                setRefreshId(nanoid());
            }
        } else {
            setRefreshId(nanoid())
        }
    })

    if(!widgetProps['content']) {
        widgetProps['content'] = {
            displayType: "TAB",
            varName: "",
            maxItems: "5",
            title: "",
            searchProfiledId: -1
        }
    }


    if(StyleUtil.isDefaultStyle(widgetProps['panel'])) {
        widgetProps['panel'] = {
            style: {
                default: true,
            }
        };
    }


    if(StyleUtil.isDefaultStyle(widgetProps['title'])) {
        widgetProps['title'] = {
            style: {
                default: true,
                typography: {
                    'font-size': '1.5rem',
                    'font-weight': 'bold'
                },
                dimension: {
                    width: "100%"
                },
                padding: {
                    'padding-block-start' : '0.5rem',
                    'padding-block-end' : '0.5rem',
                    'padding-inline-start' : '0.5rem',
                    'padding-inline-end' : '0.5rem',
                }
            }
        };
    }
    

    const themeName = "fieldgroup";
    const widgetStyles = {
        theme: StyleUtil.toThemeNameByType(widgetProps, themeName),
        current: StyleUtil.toClassFromWidget(widgetId , widgetProps, "current_style"),
        panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "panel"),
        title: StyleUtil.toClassFromWidget(widgetId, widgetProps, "title"),
        item: StyleUtil.toClassFromWidget(widgetId, widgetProps, "item"),
    }

    const loadGroups = (groups:string[]) => {
        let _groupToFields = {...groupToFields}
        for(let group of groups) {
            if(!(group in _groupToFields)) {
                lsi.attributegroup.fields(group).then((response) => {
                    _groupToFields[group] = [];
                    for(let field of response.data) {
                        _groupToFields[group].push(field.externalName);
                    }
                    setGroupToFields({..._groupToFields})
                })
            }
        }
    }
    useEffect(() => {
        loadGroups(groups)
    },[]);

    useEffect(() => {
        if(windowSize && rootRef !== null && rootRef.current !== null) {
            let rootElement = rootRef.current!;
            let rootBoundary = rootElement.getBoundingClientRect();
            let expectedOverflow = false;
            for (let i = 0; i < rootElement.children.length; i++) {
                let childBoundary = rootElement.children[i].getBoundingClientRect();
                let relative_x = childBoundary.x - rootBoundary.x;
                if (relative_x + childBoundary.width > rootBoundary.width) {
                    expectedOverflow = true;
                    break;
                }
            }
            if (overFlow !== expectedOverflow) {
                setOverFlow(expectedOverflow);
            }
        }
    },[windowSize, rootRef, products]);

    useEffect(() => {
        // useEffect(() => {
        if(products.length === 0) {
            let rValue = lsi.search.widgetSearch(
                QueryType.partnumber,
                widgetProps['content']['previewPartNumber'] ? widgetProps['content']['previewPartNumber'] : "",
                0,
                1,
                ""
            );
            if (rValue !== null) {
                rValue?.then((response) => {
                    setProducts(response.data.response.docs);
                });
            }
        }
        // },[]);
    },[]);


    const createFieldGroup = () => {
        if((products && products.length > 0)) {
            let product = products[0];
            if(widgetProps['content']['displayType'] === "TAB") {
                return createFieldGroupTab(product)
            } else {
                return createFieldGroupStack(product);
            }
        } else {
            return <></>
        }
    }

    const createFieldGroupTab = (product: any) => {
        if(product === null) return <></>;
        return <>
            <div className={StyleUtil.combineClasses(styles.Tab,"")}>
            {
                groups.map((group,index) => <button key={`tab_${index}`} className={StyleUtil.combineClasses("")}
                                            onClick={() => setCurrentTab(index)}
                >{group}</button>)
            }
            </div>
            {
                groups.filter((group,index) => index === currentTab).map(group => {
                    return <div className={StyleUtil.combineClasses(styles.TabContent, widgetStyles.theme, widgetStyles.current, widgetStyles.panel)}>
                        <ul>face

                            {Object.keys(product).filter(field => groupToFields[group] && groupToFields[group].indexOf(field) >= 0).map(field => {
                                return <li className={StyleUtil.combineClasses(styles.Item,"item",widgetStyles.item)}>{product[field]}</li>
                            })
                            }
                        </ul>
                    </div>
                })
            }
        </>
    }
    const createFieldGroupStack = (product: any) => {
        if(product === null) return <></>;
        return <>
            {
                groups.map(group => {
                    return <div className={StyleUtil.combineClasses(styles.Root, widgetStyles.theme, widgetStyles.current, widgetStyles.panel)}>
                        <div className={StyleUtil.combineClasses(styles.Title,"title",widgetStyles.title)}>{group}</div>
                        <ul>
                            {Object.keys(product).filter(field => groupToFields[group] && groupToFields[group].indexOf(field) >= 0).map(field => {
                                    return <li className={StyleUtil.combineClasses(styles.Item,"item",widgetStyles.item)}>{product[field]}</li>
                                })
                            }
                        </ul>
                    </div>
                })
            }
        </>
    }

    if(!orgCmd || !orgCmd.orgDetail) {
        return <></>
    }

    if(viewState.isEdit) {
        let deleteOption = {type: DeleteType.delete_with_rearrange, tag: null};
        const suggestTags: string[] = [
            "current",
            "current .panel",
            "current .title",
            "current .item",
        ]

        let settingOptions:SettingOption[] = [
            { title: "", type: SettingType.widget,group: "content",
                editor: "fieldgroupsetting",
                additions: {
                    attributeGroupOptions: orgCmd?.orgDetail?.attributeGroupOptions,
                }, groupType: SettingGroupType.layout
                //     <FieldGroupSetting
                //                             attributeGroupOptions={orgCmd?.orgDetail?.attributeGroupOptions}
                //                             widgetData={widgetData}
                //                             saveWidgetState={saveWidgetState}
                //                             setGroups={(groups) => {
                //                                 setGroups(groups);
                //                                 loadGroups(groups);
                //                             }}
                //                             setProducts={(data) => {
                //                                 saveCustomState({products: data});
                //                             }}
                //                             // onChange={handleChangeSearchType}
                // />
            },
            { type: SettingType.flex, group: "panel", groupType: SettingGroupType.style},
            { type: SettingType.dimension, group: "panel", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "panel", groupType: SettingGroupType.style},
            { type: SettingType.border, group: "panel", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "panel", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "panel", groupType: SettingGroupType.advanced},

            { type: SettingType.typography, group: "title", groupType: SettingGroupType.style},
            { type: SettingType.align, group: "title", groupType: SettingGroupType.style},
            { type: SettingType.dimension, group: "title", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "title", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "title", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "title", groupType: SettingGroupType.advanced},


            { type: SettingType.typography, group: "item", groupType: SettingGroupType.style},
            { type: SettingType.align, group: "item", groupType: SettingGroupType.style},
            { type: SettingType.dimension, group: "item", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "item", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "item", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "item", groupType: SettingGroupType.advanced},
        ];

        return <EditablePanel className={styles.Root}
                              {...props}
                                themeName={themeName}
                                suggestTags={suggestTags}
                                settingOptions={settingOptions}
                                settingGroups={[
                                    {group: "content", groupName: "Content",groupType:  SettingGroupType.layout},
                                    {group: "panel", groupName: "Panel",groupType:  SettingGroupType.style},
                                    {group: "title", groupName: "Title",groupType:  SettingGroupType.style},
                                    {group: "item", groupName: "Item",groupType:  SettingGroupType.style},
                                ]}
                                deleteOption={deleteOption}>
            {createFieldGroup()}
        </EditablePanel>
    } else {
        return createFieldGroup();
    }
}