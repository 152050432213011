import HttpClient from "./HttpClient";
import {BuildType} from "../model/jcr/BuildType";

function JcrGeneric(generic:string) {
    return {
        lite: () => HttpClient.get(`/api/v0/jcr/orgs/${jcr.orgCode}/lite/${generic}s`),
        full: () => HttpClient.get(`/api/v0/jcr/orgs/${jcr.orgCode}/full/${generic}s`),
        query: (id:string) => HttpClient.get(`/api/v0/jcr/orgs/${jcr.orgCode}/${generic}s/${id}`),
        update: (obj: any) => HttpClient.post(`/api/v0/jcr/orgs/${jcr.orgCode}/full/${generic}s`, obj),
        remove: (id: string) => HttpClient.delete(`/api/v0/jcr/orgs/${jcr.orgCode}/full/${generic}s/${id}`),
        upload: (file:FileList) => {
            return HttpClient.postForm(`/api/v0/jcr/orgs/${jcr.orgCode}/upload/${generic}s`, {
                file: file
            })
        },
        thumbnail: (file:File) => {
            return HttpClient.postForm(`/api/v0/jcr/orgs/${jcr.orgCode}/thumbnail/${generic}s`, {
                file: file
            })
        }
    }
}

function JcrTemplate(generic:string) {
    return {
        thumbnail: (file:File) => {
            return HttpClient.postForm(`/api/jcr/orgs/${jcr.orgCode}/template/thumbnail/${generic}s`, {
                file: file
            })
        },
        globalLite: () => HttpClient.get(`/api/jcr/orgs/global/template/lite/${generic}s`),
        globalFull: () => HttpClient.get(`/api/jcr/orgs/global/template/full/${generic}s`),
        globalQuery: (id:string) => HttpClient.get(`/api/jcr/orgs/global/template/${generic}s/${id}`),
        lite: () => HttpClient.get(`/api/jcr/orgs/${jcr.orgCode}/template/lite/${generic}s`),
        full: () => HttpClient.get(`/api/jcr/orgs/${jcr.orgCode}/template/full/${generic}s`),
        query: (id:string) => HttpClient.get(`/api/jcr/orgs/${jcr.orgCode}/template/${generic}s/${id}`),
        update: (obj: any) => HttpClient.post(`/api/jcr/orgs/${jcr.orgCode}/template/full/${generic}s`, obj),
        remove: (id: string) => HttpClient.delete(`/api/jcr/orgs/${jcr.orgCode}/template/full/${generic}s/${id}`),
        upload: (file:FileList) => {
            return HttpClient.postForm(`/api/jcr/orgs/${jcr.orgCode}/template/upload/${generic}s`, {
                file: file
            })
        }
    }
}


function JcrContentGroup() {
    return {
        isSupported: (string: string) => {
          switch (string) {
              case 'events':
              case 'blocks':
              case 'faqs':
              case 'galleries':
              case 'slideshows':
              case 'blogs':
              case 'grids':
              case 'smallgrids':
                return true
          }
          return false;
        },
        list: (type:string) => {
            return HttpClient.get(`/api/jcr/orgs/${jcr.orgCode}/content/${type}`)
        },
        info: (type:string,cgId: string) => {
            // console.log(`/api/jcr/orgs/${jcr.orgCode}/content/${type}/${cgId}`);
            return HttpClient.get(`/api/jcr/orgs/${jcr.orgCode}/content/${type}/${cgId}`)
        },
        add: (type:string,obj: any) => HttpClient.post(`/api/jcr/orgs/${jcr.orgCode}/content/${type}`, obj),
        upload: (file:FileList) => {
            return HttpClient.postForm(`/api/jcr/orgs/${jcr.orgCode}/content/upload`, {
                file: file
            })
        },
        update: (type:string,obj: any) => HttpClient.post(`/api/jcr/orgs/${jcr.orgCode}/content/${type}`, obj),
        remove: (type:string,id: string) => HttpClient.delete(`/api/jcr/orgs/${jcr.orgCode}/content/${type}/${id}`),

    }
}

function JcrContent() {
    return {
        fullList: (type:string,cgId:string) => HttpClient.get(`/api/jcr/orgs/${jcr.orgCode}/content/${type}/${cgId}/full/items`),
        liteList: (type:string,cgId:string) => HttpClient.get(`/api/jcr/orgs/${jcr.orgCode}/content/${type}/${cgId}/lite/items`),
        update: (type:string,cgId:string, obj: any) => HttpClient.post(`/api/jcr/orgs/${jcr.orgCode}/content/${type}/${cgId}/items`, obj),
        add: (type:string,cgId: string, obj: any) => HttpClient.post(`/api/jcr/orgs/${jcr.orgCode}/content/${type}/${cgId}/items`, obj),
        remove: (type:string,cgId:string,itemId: string) => HttpClient.delete(`/api/jcr/orgs/${jcr.orgCode}/content/${type}/${cgId}/items/${itemId}`),
    }
}

function JcrPublish() {
    return {
        buildVersions: (buildType:BuildType) => {
            let buildTypeStr = "dev";
            if(buildType === BuildType.staging) {
                buildTypeStr = "staging";
            } else if(buildType === BuildType.prod) {
                buildTypeStr = "prod"
            }
            return HttpClient.get(`/api/jcr/orgs/${jcr.orgCode}/buildversions/${buildTypeStr}`)
        }
    }
}

function JCRDataFetcher() {
    return {
        orgCode: "",
        publish: JcrPublish(),
        pageTemplate: JcrTemplate("page"),
        sectionTemplate: JcrTemplate("section"),
        drilldown: JcrGeneric("drilldown"),
        footer: JcrGeneric("footer"),
        header: JcrGeneric("header"),
        view: JcrGeneric("view"),
        theme: JcrGeneric("theme"),
        storefront: JcrGeneric("storefront"),
        route: JcrGeneric("route"),
        role: JcrGeneric("role"),
        contentGroup: JcrContentGroup(),
        content: JcrContent(),
    }
}

export const jcr = JCRDataFetcher();