import {createTheme} from "@mui/material/styles";

export type TooltipPlacement = 'bottom-end'
                | 'bottom-start'
                | 'bottom'
                | 'left-end'
                | 'left-start'
                | 'left'
                | 'right-end'
                | 'right-start'
                | 'right'
                | 'top-end'
                | 'top-start'
                | 'top';

export const createLightTheme = () => createTheme({
    palette: {
        primary: {
            // main: "#003035",
            main:"#008658",
            // main: "#000000",
        },
        mode: false ? "dark" : "light",
    },
    typography: {
        fontFamily: 'Inter'//'Fira Sans',//Roboto',
    },
    components: {
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    border: "1px solid transparent",
                    borderRadius: "0px",
                    backgroundColor: "transparent",
                    textTransform: "none",
                    color: "#444446",
                    "&:hover": {
                        transition: "background-color .3s,border-color .3s,box-shadow .3s,color .3s",
                        // main: "#00764a",
                        color: "var(--standard-jade-500)",
                        backgroundColor: "#f0f0f0",
                        //borderColor: "var(--bt-cms-color-primary)",
                        //boxShadow: "0 1px 2.5px 0 rgb(0 0 0 / 26%), 0 1px 5px 0 rgb(0 0 0 / 16%)",
                        textTransform: "none",
                    }
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    textDecoration: "none",
                    "&:hover": {
                        textDecoration: "underline",
                        color: "var(--standard-jade-500)",
                    }
                }
            }
        },
        MuiDialog: {
          styleOverrides: {
              paper: {
                  maxWidth: "778px"
              }
          }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    backgroundColor: "#F4F4F4",
                }
            }
        },
        MuiDialogContent :{
            styleOverrides: {
                root: {
                    padding: "8px",
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    borderBlockStart: "1px solid #0000001F",
                    height: "50px",
                }
            }
        },
        MuiTabs: {
          styleOverrides: {
              root: {
                  borderBottom: '1px solid  #e8e8e8',
              },
              indicator: {
                  height: "3px",
                  borderRadius: "3px"
              }
          }
        },
        MuiFormHelperText: {
          styleOverrides: {
                root: {
                    textAlign: "end",
                    fontSize: "12px",
                    paddingInlineEnd: "10px",
                    margin: "0px",
                    fontWeight: 500
                },
          }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textAlign: "start",
                    textTransform: "none",
                    borderBottom: 'unset',
                },
            }
        },
        MuiIcon: {
            styleOverrides: {
                root: {
                    color: "inherit"
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    paddingRight: "2px",
                    paddingLeft: "2px"
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: "var(--bt-cms-color-primary-normal)",
                    "&$checked": {
                        color: "var(--bt-cms-color-primary-normal)"
                    }
                },
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: "13px",
                    color: "white",
                    height: "20px",
                    lineHeight: "20px",
                    backgroundColor: "var(--standard-spruce-500)",

                },
                arrow: {
                    color: "var(--standard-spruce-500)",
                }
            }
        },
        // MuiIconButton: {
        //     styleOverrides: {
        //         root: {
        //             color: "var(--standard-smoke-800)",
        //             fontWeight: norm
        //         }
        //     }
        // },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    "&.Mui-selected, &.Mui-selected:hover" : {
                        color: "var(--standard-jade-500)",
                        fontWeight: 500
                    }
                }

            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    backgroundColor: "var(--bt-cms-color-primary)",
                    color: "white",
                    "&:hover": {
                        backgroundColor: "var(--bt-cms-color-primary-dark)",
                    },
                    "&:disabled": {
                        color: 'rgba(255,255,255,0.5)'
                    }
                },
                contained: {
                    backgroundColor: "var(--bt-cms-color-primary)",
                    color: "white",
                    "&:hover": {
                        backgroundColor: "var(--bt-cms-color-primary-dark)",
                    },
                },
                textPrimary: {
                    backgroundColor: "var(--bt-cms-color-primary)",
                    color: "white",
                    "&:hover": {
                        color: "white",
                        backgroundColor: "var(--bt-cms-color-sidebar-active-dark)",
                    },
                    "&:disabled": {
                        backgroundColor: "gray"
                    }
                },
                textSecondary: {
                    backgroundColor: "var(--bt-cms-color-secondary)",
                    color: "white",
                    "&:hover": {
                        color: "white",
                        backgroundColor: "var(--bt-cms-color-secondary-dark)",
                    }
                },
                containedPrimary: {
                    backgroundColor: "var(--bt-cms-color-primary)",
                    color: "white",
                    "&:hover": {
                        color: "white",
                        backgroundColor: "var(--bt-cms-color-primary-dark)",
                    }
                }
            }
        }
    },
});