import SettingProps from "../SettingProps";
// import CSSSetting from "../csssetting/CSSSetting";
import GenericSetting, {StyleSettingType} from "../genericsetting/GenericSetting";

export default function BackgroundSetting(props: SettingProps) {
    // return <CSSSetting {...props}
    //                    title={"Background"}
    //                    type={"background"}
    //                    targets={["background-color" , "background-blend-mode" ,"background-position", "background-repeat","background-image","background-clip"]}/>

    return <GenericSetting {...props}
                           title={"Background"}
                           type={"background"}
                           targets={[
                                    { name: "Border",target: 'background-color' , type: StyleSettingType.text},
                                    { name: "Top",target: 'background-blend-mode' , type: StyleSettingType.default_size},
                                    { name: "Position", target: 'background-position' , type: StyleSettingType.default_size},
                                    { name: "Repeaat", target: 'background-repeat' , type: StyleSettingType.default_size},
                                    { name: "Image", target: 'background-image' , type: StyleSettingType.default_size},
                                    { name: "Clip", target: 'background-clip' , type: StyleSettingType.text}
                                ]}
    />
}