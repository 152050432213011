import {WidgetInterface} from "../../../model/WidgetInterface";
import {useAppSelector} from "../../../../redux/hook";
import {selectViewState} from "../../../../redux/features/view/viewSlice";
import React, {useCallback, useEffect, useState} from "react";
import {nanoid} from "nanoid";
import {DeleteType} from "../../../model/DeleteOption";
import {SettingGroupType, SettingOption, SettingType} from "../../../model/SettingOption";
import EditablePanel from "../../shared/editable/EditablePanel";
import styles from "./ProductDrilldown.module.scss";
import {jcr} from "../../../../datafetcher/JCRDataFetcher";
import DrillItemCmd, {DrillSortBy} from "../../../model/DrillItemCmd";
import {StyleUtil} from "../../setting/StyleUtil";
import useWindowSize from "../../../hook/useWindowSize";
import useWidgetState from "../../../../hook/useWidgetState";

export default function ProductDrilldown(props:any) {
    const widgetData:WidgetInterface = props.data as WidgetInterface;
    const {widgetId,widgetProps} = widgetData;
    const viewState = useAppSelector(selectViewState);
    const [imagePrefix,setImagePrefix] = useState<string>("");
    const windowSize = useWindowSize();
    const [currentDrill,setCurrentDrill] = useState<{
        root: DrillItemCmd,
        current: DrillItemCmd,
        path: DrillItemCmd[],
    }|null>(null);
    const [drillInfo, setDrillInfo] = useState<{
        drilldown : string,
        previewId: string
    }>({
            drilldown: widgetData.widgetProps && widgetData.widgetProps['content'] && widgetData.widgetProps['content']['widget_drilldown'] ? widgetData.widgetProps['content']['widget_drilldown'] : "",
            previewId: widgetData.widgetProps && widgetData.widgetProps['content'] && widgetData.widgetProps['content']['previewId'] ? widgetData.widgetProps['content']['previewId'] : ""
        });
    const [refreshId,setRefreshId] = useState(nanoid());
    useWidgetState(widgetId, (event) => {
        if(event && event.detail) {
            if(event.detail.action === "preview") {

                widgetData.widgetProps["content"]['widget_drilldown'] = event.detail.value.drilldown;
                widgetData.widgetProps["content"]['widget_drilldown_depth'] = 1;
                widgetData.widgetProps["content"]['previewId'] = event.detail.value.previewId;

                setDrillInfo({drilldown: event.detail.value.drilldown, previewId: event.detail.value.previewId})
            } else {
                setRefreshId(nanoid());
            }
        } else {
            setRefreshId(nanoid());
        }
    })

    if(StyleUtil.isDefaultStyle(widgetProps['panel'])) {
        widgetProps['panel'] = {
            style: {
                default: true,
                margin: {
                    "margin-inline-start": "6px",
                    "margin-inline-end": "6px"
                }
            }
        };
    }

    if(StyleUtil.isDefaultStyle(widgetProps["catalog_list_panel"])) {
        widgetProps["catalog_list_panel"] = {
            style: {
                default: true,
                flex: {
                    "justify-content": "center"
                }
            }
        };
    }

    if(StyleUtil.isDefaultStyle(widgetProps["empty_panel"])) {
        widgetProps["empty_panel"] = {
            style: {
                default: true,
                typography: {
                    'font-size': "1rem"
                },
                align: {
                    "text-align": "start"
                },
                padding: {
                    "padding-block-start": "1rem",
                    "padding-block-end": "1rem",
                    "padding-inline-start": "1rem",
                    "padding-inline-end": "1rem",
                }
            }
        };
    }

    if(StyleUtil.isDefaultStyle(widgetProps["title"])) {
        widgetProps["title"] = {
            style: {
                default: true,
                padding: {
                    "padding-inline-start": "0.5rem",
                    "padding-inline-end": "0.5rem",
                    "padding-block-start":"0.5rem",
                    "padding-block-end":"0.5rem",
                },
                typography: {
                    "font-size": "24px",
                    "font-weight": "bold"
                },
            }
        };
    }
    if(StyleUtil.isDefaultStyle(widgetProps["catalog_panel"])) {
        widgetProps["catalog_panel"] = {
            style: {
                default: true,
                dimension: {
                    "min-width": "200px"
                },
                padding: {
                    "padding-inline-start": "0.5rem",
                    "padding-inline-end": "0.5rem",
                    "padding-block-start":"0.5rem",
                    "padding-block-end":"0.5rem",
                }
            }
        };
    }

    if(StyleUtil.isDefaultStyle(widgetProps["image_panel"])) {
        widgetProps["image_panel"] = {
            style: {
                default: true,
                dimension: {
                    'height': "120px"
                },
                align : {
                    "text-align": "center"
                },
                padding: {
                    "padding-block-start": "0.5rem",
                    "padding-block-end": "0.5rem",
                }
            }
        };
    }

    if(StyleUtil.isDefaultStyle(widgetProps["drilldown_panel"])) {
        widgetProps["drilldown_panel"] = {
            style: {
                default: true,
                align : {
                    "text-align": "center"
                },
                typography: {
                    "font-size": "13px"
                },
                padding: {
                    "padding-block-start": "0.5rem",
                    "padding-block-end": "0.5rem",
                },
                border: {
                    'border-block-start': "solid 1px lightgray",
                }
            }
        };
    }

    if(StyleUtil.isDefaultStyle(widgetProps["drilldown_small_level1"])) {
        widgetProps["drilldown_small_level1"] = {
            style: {
                default: true,
                typography: {
                    'line-height': '1.5rem',
                },
                dimension: {
                    'min-height': '1.5rem',
                },
                border: {
                    'border': "solid 1px lightgray",
                },
                cursor: {
                    'cursor': 'pointer'
                }
            }
        };
    }

    if(StyleUtil.isDefaultStyle(widgetProps["drilldown_items_panel"])) {
        widgetProps['drilldown_items_panel'] = {
            style: {
                default: true,
                align : {
                    "text-align": "center"
                },
                typography: {
                    "font-size": "12px"
                },
                margin: {
                    "margin-block-start": "0.5rem",
                    "margin-block-end": "0.5rem",
                }
            }
        };
    }

    if(StyleUtil.isDefaultStyle(widgetProps["drilldown_link"])) {
        widgetProps['drilldown_link'] = {
            style: {
                default: true,
                padding: {
                    "padding-block-start": "5px",
                    "padding-block-end": "5px",
                    "padding-inline-start": "5px",
                    "padding-inline-end": "5px",
                }
            }
        };
    }

    if(StyleUtil.isDefaultStyle(widgetProps["breadcrumb_panel"])) {
        widgetProps['breadcrumb_panel'] = {
            style: {
                default: true,
                typography: {
                    "font-size": "0.8rem",
                    "line-height": "1.8rem"
                },
                margin: {
                    "margin-block-start":"20px",
                    "margin-block-end": "15px",
                }
            }
        };
    }

    if(StyleUtil.isDefaultStyle(widgetProps['image'])) {
        widgetProps['image'] = {
            style: {
                default: true,
                dimension: {
                    "width": "auto",
                    "height": "auto",
                    "max-width": "95%",
                    "max-height": "95%"
                },
                object : {
                    'object-fit': "contain",
                }
            }
        };
    }

    const handleClickTitle = (event:React.MouseEvent<HTMLDivElement>) => {
        if(event.currentTarget.getAttribute("data-can-click") === "true") {
            let drillId = event.currentTarget.getAttribute("data-drill-id");
            document.querySelectorAll(`[id^="drilldown_item_panel_${drillId}"]`)
                .forEach((item:any) => {
                    if(item.style.maxHeight === '0px') {
                        item.style.maxHeight = item.style.height;
                    } else {
                        item.style.maxHeight = '0px';
                    }
                })
        }
    }


    const cols:number = widgetProps['content'] && widgetProps['content']['maxCols']  ? parseInt(widgetProps['content']['maxCols'])+1 : 4+1;
    const showSubDrilldown:boolean = widgetProps['content'] && widgetProps['content']['showSubDrilldown'] !== undefined  ? widgetProps['content']['showSubDrilldown'] : true;
    const hasBreadCrumb:boolean = widgetProps['content'] && widgetProps['content']['hasBreadCrumb'] !== undefined ? widgetProps['content']['hasBreadCrumb']: true;
    const breadcrumbSeparator:string = widgetProps['content'] && widgetProps['content']['breadcrumbSeparator'] !== undefined ? widgetProps['content']['breadcrumbSeparator']: "/";
    const breadcrumbSpace:string = widgetProps['content'] && widgetProps['content']['breadcrumbSpace'] !== undefined ? widgetProps['content']['breadcrumbSpace']: "0.5rem";
    const emptyMessage = widgetProps['content'] && widgetProps['content']['emptyMessage'] !== undefined ? widgetProps['content']['emptyMessage'] : "There are no stocked products for this category.";

    const themeName = "product_drilldown";
    let widgetStyles:any = {
        theme: StyleUtil.toThemeNameByType(widgetProps, themeName),
        current: StyleUtil.toClassFromWidget(widgetId , widgetProps, "current_style"),
        panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "panel"),
        breadcrumb_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "breadcrumb_panel"),
        catalog_list_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "catalog_list_panel"),
        item_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "catalog_panel"),
        image_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "image_panel"),
        image: StyleUtil.toClassFromWidget(widgetId , widgetProps, "image"),
        banner_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "banner_panel"),
        banner_image: StyleUtil.toClassFromWidget(widgetId , widgetProps, "banner_image"),
        title: StyleUtil.toClassFromWidget(widgetId , widgetProps, "title"),
        description: StyleUtil.toClassFromWidget(widgetId , widgetProps, "description"),
        drilldown_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "drilldown_panel"),
        drilldown_items_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "drilldown_items_panel"),
        drilldown_link: StyleUtil.toClassFromWidget(widgetId , widgetProps, "drilldown_link"),
        drilldown_title: StyleUtil.toClassFromWidget(widgetId , widgetProps, "drilldown_title"),
        drilldown_items_link: StyleUtil.toClassFromWidget(widgetId , widgetProps, "drilldown_items_link"),
        drilldown_small_level1: StyleUtil.toClassFromWidget(widgetId , widgetProps, "drilldown_small_level1"),
        empty_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "empty_panel"),
        item_panel_custom: StyleUtil.toClassFromMap(widgetId, "item_panel_custom", {
            'flex': `1 1 calc(100% / ${cols} + 1px)`,
            'box-sizing': 'border-box',
            'min-width': '200px'
        }),
        breadcrumb_style1: StyleUtil.toClassFromMap(widgetId, "breadcrumb_panel li::after", {
            'content': "'" + breadcrumbSeparator + "'",
            'padding-inline': breadcrumbSpace
        }),
        breadcrumb_style2: StyleUtil.toClassFromMap(widgetId, "breadcrumb_panel li:last-child::after", {
            'content': "unset",
            'padding-inline': 'unset'
        })

    }


    const createItemPanel = (item:DrillItemCmd,hasImage: boolean) => {
        let array = item.subItems;
        if(item.sortBy === DrillSortBy.alphabet && showSubDrilldown) {
            array = [...array].sort((a,b) => a.name.localeCompare(b.name) );
        }
        array = array.filter((item) => item.active);
        return <div key={`catalog_${item.indexId}`} className={StyleUtil.combineClasses("dd_catalog_panel",widgetStyles.item_panel,widgetStyles.item_panel_custom)}>
            {
                hasImage && <div className={StyleUtil.combineClasses("dd_catalog_image_panel",widgetStyles.image_panel)}>
                    {item.categoryImage && <img src={item.categoryImage.startsWith("http") ? item.categoryImage : imagePrefix + "/" + item.categoryImage} className={widgetStyles.image}/>}
                </div>
            }
            <div className={StyleUtil.combineClasses("dd_catalog_detail_panel",widgetStyles.drilldown_panel)}>
                <div id={`drilldown_title_${item.indexId}`} data-drill-id={item.indexId} onClick={handleClickTitle}>
                    <a className={StyleUtil.combineClasses("dd_catalog_detail_title",widgetStyles.drilldown_link)}>{item.name}</a></div>
                {
                    showSubDrilldown && <ul id={`drilldown_item_panel_${item.indexId}`} className={StyleUtil.combineClasses(styles.Level1,"dd_sub_item_panel",widgetStyles.drilldown_items_panel)}>
                        {
                            array.map((item,index) => {
                                return <li key={`catalog_${item.indexId}_${index}`} className={StyleUtil.combineClasses(styles.Level2,widgetStyles.drilldown_items_link)}>
                                    {item.name}
                                </li>
                            })
                        }
                    </ul>
                }
            </div>
        </div>
    }

    const createBreadCrumb = (path: DrillItemCmd[]) => {
        return <ul className={StyleUtil.combineClasses(styles.BreadCrumb,"dd_breadcrumb",widgetStyles.breadcrumb_panel,widgetStyles.breadcrumb_style1,widgetStyles.breadcrumb_style2)}>
            {
                path.map((item,index) => <li key={`breadcrumb_${index}`}><a href={"javascript:void()"}>{item.name}</a></li>)
            }
        </ul>
    }

    const createProductDrilldown = () => {
        if(currentDrill !== null) {
            let current = currentDrill.current;
            let array = current.subItems;
            if(current.sortBy === DrillSortBy.alphabet) {
                array = [...array].sort((a,b) => a.name.localeCompare(b.name) );
            }
            array = array.filter((item) => item.active);
            let hasImage = false;
            let total = 0;
            for(let i=0;i<array.length;i++) {
                if(array[i].categoryImage) hasImage = true;
                total += array[i].subItems.length;
            }
            let hasEmpty = array.length === 0;
            let countStr = widgetProps['content']['count'];
            let count = Number.MAX_VALUE;
            if(countStr !== "" && countStr !== undefined) {
                count = parseInt(countStr);
            }

            return <div ref={rootRef} className={StyleUtil.combineClasses(widgetStyles.theme,widgetStyles.current,widgetStyles.panel)}>
                {hasBreadCrumb && createBreadCrumb(currentDrill.path)}
                <div className={StyleUtil.combineClasses("dd_title",widgetStyles.title)}>{current.name}</div>
                {current.description && current.description !== "" && <h3 className={StyleUtil.combineClasses("dd_desc",widgetStyles.description)}>{current.description}</h3>}
                {current.bannerImage && current.bannerImage !== "" && <div className={StyleUtil.combineClasses("dd_banner_panel",widgetStyles.banner_panel)}><img src={current.bannerImage.startsWith("http") ? current.bannerImage : imagePrefix + "/" + current.bannerImage} className={widgetStyles.banner_image}/></div>}
                <div className={StyleUtil.combineClasses("dd_catalog_list_panel",widgetStyles.catalog_list_panel,styles.Root)}>
                {
                    array.filter((item,index) => index < count).map((item) => {
                        return createItemPanel(item,hasImage);
                    })
                }
                </div>
                {
                    hasEmpty && <div className={StyleUtil.combineClasses("dd_empty_panel",widgetStyles.empty_panel)}>{emptyMessage}</div>
                }
            </div>
        } else {
            return <div className={styles.Root}></div>
        }
    }

    const findDrillDown = (drillData: DrillItemCmd) => {
        let drillArray: {data:DrillItemCmd,path:DrillItemCmd[]}[] = [];
        drillArray.push({data: drillData, path: [drillData]});
        let found:boolean = false;
        while (drillArray.length > 0) {
            let item = drillArray.pop();
            if (item) {
                if (item.data.indexId === drillInfo.previewId) {
                    found = true;
                    setCurrentDrill({root: drillData, current: item.data, path: item.path});
                    break;
                } else {
                    item.data.subItems.forEach(i => drillArray.push({data: i, path: [...item!.path, i]}));
                }
            }
        }
        if(found === false) {
            setCurrentDrill({root: drillData, current: drillData, path: [drillData]});
        }
    }

    const loadDrill = () => {
        if(drillInfo.drilldown) {
            jcr.drilldown.query(drillInfo.drilldown).then((response) => {
                setImagePrefix(response.data.response.drillImageURL);
                let drillData: DrillItemCmd = JSON.parse(response.data.response.drillData);
                findDrillDown(drillData);
            });
        }
    }

    useEffect(() => {

        if(currentDrill === null) {
            loadDrill();
        } else if(currentDrill.root.indexId === drillInfo.drilldown) {
            findDrillDown(currentDrill.root);
        } else {
            loadDrill();
        }

    },[drillInfo])

    const rootRef = useCallback((node) => {
        if(windowSize && node) {
            let rootElement = node;
            let rootBoundary = rootElement.getBoundingClientRect();
            if(rootBoundary.width < 400) {
                if(rootElement.getAttribute("data-panel-size") !== "small") { // change to small
                    rootElement.setAttribute("data-panel-size", "small")
                    node.querySelectorAll('[id^="drilldown_item_panel_"]').forEach((item:any) => {
                        item.style.maxHeight = '0px';
                    })

                    node.querySelectorAll('[id^="drilldown_title_"]').forEach((item:any) => {
                        item.classList.add(widgetStyles.drilldown_small_level1);
                        item.setAttribute("data-can-click","true");
                    })
                }
            } else {
                if(rootElement.getAttribute("data-panel-size") !== "large") { // change to large
                    rootElement.setAttribute("data-panel-size", "large")
                    node.querySelectorAll('[id^="drilldown_item_panel_"]').forEach((item:any) => {
                        item.style.maxHeight = 'unset';
                        item.style.height = 'unset';
                    })

                    node.querySelectorAll('[id^="drilldown_title_"]').forEach((item:any) => {
                        item.classList.remove(widgetStyles.drilldown_small_level1);
                        item.setAttribute("data-can-click","false");
                    })
                }
            }
        }
    },[windowSize]);



    if(viewState.isEdit) {
        let deleteOption = {type: DeleteType.delete_with_rearrange, tag: null};
        const suggestTags: string[] = [
            "current",
            "current .dd_title",
            "current .dd_desc",
            "current .dd_banner_panel",
            "current .dd_banner_panel img",
            "current .dd_catalog_list_panel",
            "current .dd_empty_panel",
            "current .dd_breadcrumb",
            "current .dd_breadcrumb li,a",
            "current .dd_catalog_panel",
            "current .dd_catalog_image_panel",
            "current .dd_catalog_image_panel img",

            "current .dd_catalog_detail_panel",
            "current .dd_catalog_detail_title",

            "current .dd_sub_item_panel",
            "current .dd_sub_item_panel li,a",
        ]

        let settingOptions:SettingOption[] = [
            { title: "Settings", type: SettingType.widget,group: "content",
                editor: "productdrilldownsetting",
                additions: {
                    sharedObject: props.sharedObjects,
                }, groupType: SettingGroupType.layout
                //     <ProductDrilldownSetting widgetData={widgetData}
                //                                  sharedObject={props.sharedObjects}
                //                                  saveWidgetState={saveWidgetState}
                //                                  onChangePreview={(drilldown,previewId) => {
                //                                      widgetData.widgetProps["content"]['widget_drilldown'] = drilldown;
                //                                      widgetData.widgetProps["content"]['widget_drilldown_depth'] = 1;
                //                                      widgetData.widgetProps["content"]['previewId'] = previewId;
                //                                      saveWidgetState();
                //                                      setDrillInfo({drilldown: drilldown, previewId: previewId})
                //
                //                                  }}
                // />
    },
            { type: SettingType.dimension, group: "panel", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "panel", groupType: SettingGroupType.style},
            { type: SettingType.border, group: "panel", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "panel", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "panel", groupType: SettingGroupType.advanced},

            { type: SettingType.dimension, group: "empty_panel", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "empty_panel", groupType: SettingGroupType.style},
            { type: SettingType.border, group: "empty_panel", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "empty_panel", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "empty_panel", groupType: SettingGroupType.advanced},

            { type: SettingType.flex, group: "catalog_list_panel", groupType: SettingGroupType.style},
            { type: SettingType.dimension, group: "catalog_list_panel", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "catalog_list_panel", groupType: SettingGroupType.style},
            { type: SettingType.border, group: "catalog_list_panel", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "catalog_list_panel", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "catalog_list_panel", groupType: SettingGroupType.advanced},

            { type: SettingType.dimension, group: "catalog_panel", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "catalog_panel", groupType: SettingGroupType.style},
            { type: SettingType.border, group: "catalog_panel", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "catalog_panel", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "catalog_panel", groupType: SettingGroupType.advanced},

            { type: SettingType.dimension, group: "image_panel", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "image_panel", groupType: SettingGroupType.style},
            { type: SettingType.border, group: "image_panel", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "image_panel", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "image_panel", groupType: SettingGroupType.advanced},

            { type: SettingType.dimension, group: "image", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "image", groupType: SettingGroupType.style},
            { type: SettingType.border, group: "image", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "image", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "image", groupType: SettingGroupType.advanced},

            { type: SettingType.dimension, group: "banner_panel", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "banner_panel", groupType: SettingGroupType.style},
            { type: SettingType.border, group: "banner_panel", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "banner_panel", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "banner_panel", groupType: SettingGroupType.advanced},

            { type: SettingType.dimension, group: "banner_image", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "banner_image", groupType: SettingGroupType.style},
            { type: SettingType.border, group: "banner_image", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "banner_image", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "banner_image", groupType: SettingGroupType.advanced},

            { type: SettingType.dimension, group: "feature", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "feature", groupType: SettingGroupType.style},
            { type: SettingType.border, group: "feature", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "feature", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "feature", groupType: SettingGroupType.advanced},

            { type: SettingType.typography, group: "title", groupType: SettingGroupType.style},
            { type: SettingType.align, group: "title", groupType: SettingGroupType.style},
            { type: SettingType.dimension, group: "title", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "title", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "title", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "title", groupType: SettingGroupType.advanced},

            { type: SettingType.typography, group: "description", groupType: SettingGroupType.style},
            { type: SettingType.align, group: "description", groupType: SettingGroupType.style},
            { type: SettingType.dimension, group: "description", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "description", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "description", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "description", groupType: SettingGroupType.advanced},

            { type: SettingType.typography, group: "breadcrumb_panel", groupType: SettingGroupType.style},
            { type: SettingType.dimension, group: "breadcrumb_panel", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "breadcrumb_panel", groupType: SettingGroupType.style},
            { type: SettingType.border, group: "breadcrumb_panel", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "breadcrumb_panel", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "breadcrumb_panel", groupType: SettingGroupType.advanced},


            { type: SettingType.dimension, group: "drilldown_panel", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "drilldown_panel", groupType: SettingGroupType.style},
            { type: SettingType.border, group: "drilldown_panel", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "drilldown_panel", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "drilldown_panel", groupType: SettingGroupType.advanced},


            { type: SettingType.dimension, group: "drilldown_items_panel", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "drilldown_items_panel", groupType: SettingGroupType.style},
            { type: SettingType.border, group: "drilldown_items_panel", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "drilldown_items_panel", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "drilldown_items_panel", groupType: SettingGroupType.advanced},


            { type: SettingType.typography, group: "drilldown_link", groupType: SettingGroupType.style},
            { type: SettingType.align, group: "drilldown_link", groupType: SettingGroupType.style},
            { type: SettingType.dimension, group: "drilldown_link", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "drilldown_link", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "drilldown_link", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "drilldown_link", groupType: SettingGroupType.advanced},

            { type: SettingType.typography, group: "drilldown_items_link", groupType: SettingGroupType.style},
            { type: SettingType.dimension, group: "drilldown_items_link", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "drilldown_items_link", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "drilldown_items_link", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "drilldown_items_link", groupType: SettingGroupType.advanced},

            { type: SettingType.typography, group: "drilldown_small_level1", groupType: SettingGroupType.style},
            { type: SettingType.align, group: "drilldown_small_level1", groupType: SettingGroupType.style},
            { type: SettingType.dimension, group: "drilldown_small_level1", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "drilldown_small_level1", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "drilldown_small_level1", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "drilldown_small_level1", groupType: SettingGroupType.advanced},

        ];

        return <EditablePanel
                              {...props}
                              themeName={themeName}
                              suggestTags={suggestTags}
                              settingOptions={settingOptions}
                              settingGroups={[
                                  {group: "content", groupName: "Content", groupType: SettingGroupType.layout},
                                  {group: "panel", groupName: "Top Panel", groupType: SettingGroupType.style},
                                  {group: "breadcrumb_panel", groupName: "Breadcrumb Panel", groupType: SettingGroupType.style},
                                  {group: "title", groupName: "Title", groupType: SettingGroupType.style},
                                  {group: "description", groupName: "Description", groupType: SettingGroupType.style},
                                  {group: "banner_panel", groupName: "Banner Panel", groupType: SettingGroupType.style},
                                  {group: "banner_image", groupName: "Banner Image", groupType: SettingGroupType.style},
                                  {group: "catalog_list_panel", groupName: "Catalog List Panel", groupType: SettingGroupType.style},
                                  {group: "catalog_panel", groupName: "Catalog Panel", groupType: SettingGroupType.style},
                                  {group: "image_panel", groupName: "Image Panel", groupType: SettingGroupType.style},
                                  {group: "image", groupName: "Image", groupType: SettingGroupType.style},
                                  {group: "drilldown_panel", groupName: "Drilldown Panel Level 1", groupType: SettingGroupType.style},
                                  {group: "drilldown_items_panel", groupName: "Drilldown Panel Leve 2", groupType: SettingGroupType.style},
                                  {group: "drilldown_link", groupName: "Drilldown Link Level 1", groupType: SettingGroupType.style},
                                  {group: "drilldown_items_link", groupName: "Drilldown Link Level 2", groupType: SettingGroupType.style},
                                  {group: "drilldown_small_level1", groupName: "Drilldown Panel (Small screen)", groupType: SettingGroupType.style},
                                  {group: "empty_panel", groupName: "Empty Panel", groupType: SettingGroupType.style},
                              ]}
                              deleteOption={deleteOption}>
            {createProductDrilldown()}
        </EditablePanel>
    } else {
        return createProductDrilldown();
    }
}