import {HTMLAttributeReferrerPolicy, useReducer} from "react";
import SharedObjects from "../editable/ShareObjects";

interface ImageState {
    index: number,
    stop: boolean,
    src: string | undefined,
    srcSet: string | undefined;
    srcArray: string[] | undefined;
    srcSetArray: string[] | undefined;
}

interface Action {
    type: 'next'
}

function reducer(state:ImageState, action:Action) : ImageState {
    let newState = {...state};
    switch (action.type) {
        case 'next':
            let index = newState.index+1;
            if(newState.srcArray) {
                if(index >= newState.srcArray.length) {
                    newState.src = undefined;
                    newState.stop = true;
                } else {
                    newState.src = newState.srcArray[index];
                }
            } else {
                newState.src = undefined;
                newState.stop = true;
            }

            if(newState.srcSetArray) {
                if (index >= newState.srcSetArray.length) {
                    newState.srcSet = undefined;
                } else {
                    newState.srcSet = newState.srcSetArray[index];
                }
            } else {
                newState.srcSet = undefined;
            }
            break;
    }
    return newState;
}

export default function Images(props: {
    alt?: string | undefined
    crossOrigin?: "anonymous" | "use-credentials" | "" | undefined;
    decoding?: "async" | "auto" | "sync" | undefined;
    height?: number | string | undefined;
    loading?: "eager" | "lazy" | undefined;
    referrerPolicy?: HTMLAttributeReferrerPolicy | undefined;
    sizes?: string | undefined;
    src: string[] | undefined;
    srcSet?: string[] | undefined;
    useMap?: string | undefined;
    width?: number | string | undefined,
    className?: string,
    sharedObjects?: SharedObjects}) {

    let newProps = {...props};
    delete newProps.src;
    delete newProps.srcSet;
    delete newProps.sharedObjects;

    const [imageState, imageDispatch] = useReducer(reducer, {
        index: 0,
        stop: props.src ? props.src.length === 0 : true,
        src: props.src && props.src.length > 0 ? props.src[0] : undefined,
        srcSet: props.srcSet && props.srcSet.length > 0 ? props.srcSet[0] : undefined,
        srcArray: props.src,
        srcSetArray: props.srcSet,
    });

    const handleError = () => {
        imageDispatch({type: 'next'});
    }
    return <img {...newProps}
                src={imageState.src}
                srcSet={imageState.srcSet}
                onError={imageState.stop? undefined : handleError} loading={"lazy"} />
}
