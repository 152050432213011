import {EditableProps} from "../../shared/editable/EditableProps";
import {SectionInterface} from "../../../model/SectionInterface";
import {useAppSelector} from "../../../../redux/hook";
import {selectViewState} from "../../../../redux/features/view/viewSlice";
import {doViewGenerator} from "../../../util/UIFactory";
import styles from './TwoColumns.module.scss';
import {NewType} from "../../../model/NewOption";
import {DeleteType} from "../../../model/DeleteOption";
import EditablePanel from "../../shared/editable/EditablePanel";
import {SettingGroup, SettingGroupType, SettingOption, SettingType} from "../../../model/SettingOption";
import {SettingChangeFunction} from "../../setting/SettingChange";
import {StyleUtil} from "../../setting/StyleUtil";
import React, {useCallback, useEffect, useState} from "react";
import EmptyLabel from "../../shared/emptylabel/EmptyLabel";
import {nanoid} from "nanoid";
import useWindowSize from "../../../hook/useWindowSize";
import useWidgetState from "../../../../hook/useWidgetState";


export default function TwoColumns(props: EditableProps) {
    const windowSize = useWindowSize();
    const sectionData:SectionInterface = props.data as SectionInterface;
    const {sectionId,sectionProps} = sectionData;
    const viewState = useAppSelector(selectViewState);
    const [refreshId,setRefreshId] = useState(nanoid());

    useWidgetState(sectionId,(event) => {
        setRefreshId(nanoid());
    })


    const [views , setViews] = useState<JSX.Element[]|null>(null);
    // const views:JSX.Element[] = doViewGenerator(sectionData.sectionWidgets,
    //     props.uiLevel+1,
    //     props.uiLevelStr,
    //     props.sharedObjects,
    //     props.onHandleSetting,
    //     props.onHandleDeletion,
    //     props.onHandleAddNewSectionOrWidget,
    //     props.onHandleSwap,
    //     props.onHandleUpdate
    // );

    useEffect(() => {
        setViews(doViewGenerator(sectionData.sectionWidgets,
            props.uiLevel+1,
            props.uiLevelStr,
            props.sharedObjects,
            props.onHandleSetting,
            props.onHandleDeletion,
            props.onHandleAddNewSectionOrWidget,
            props.onHandleSwap,
            props.onHandleUpdate,
            props.onHandleClone,
            props.onHandleCopy,
            props.onHandleCut,
            props.onHandleCreateTemplate,
            props.updateWidgetData,
        ));
    },[refreshId])

    if(!(sectionProps['content'])) {
        sectionProps['content'] = {
            'column1-width': "30%",
            'column2-width': "1fr",
            gap: "0px",
        }
    }

    if(!sectionProps['content']['gap']) {
        sectionProps['content']['gap'] = "0.0px";
    }

    const themeName = "two_columns";
    const sectionStyles = {
        theme: StyleUtil.toThemeNameByType(sectionProps, themeName),
        current: StyleUtil.toClassFromWidget(sectionId, sectionProps, "current_style"),
        panel: StyleUtil.toClassFromWidget(sectionId , sectionProps, "bt_twocolumns_panel"),
        flow_panel: StyleUtil.toClassFromMap(sectionId, "flow_panel",{
            "grid-auto-flow": "column",
            "grid-template-columns" : sectionProps['content']['column1-width'] + " " + sectionProps['content']['column2-width'],
            "gap" : sectionProps['content']['gap']
        })
    }

    const ensureScreen = (node:any) => {
        let rootElement = node;
        let rootBoundary = rootElement.getBoundingClientRect();
        if(rootBoundary.width !== 0) {
            if (rootBoundary.width < 450) {
                rootElement.style.gridTemplateColumns = '100%'
            } else {
                rootElement.style.gridTemplateColumns = null;
            }
        } else {
            setTimeout(() => {
                ensureScreen(node);
            },100);
        }
    }
    const rootRef = useCallback((node) => {
        if(windowSize && node) {
            ensureScreen(node);
        }
    },[windowSize]);

    const createTwoColumn = (views:JSX.Element[]) => <section
                                            key={refreshId}
                                            ref={rootRef}
                                            className={
                                                StyleUtil.combineClasses(styles.Root,sectionStyles.theme,sectionStyles.current,sectionStyles.panel,sectionStyles.flow_panel)
                                            }
    >
            <div className={"col1"}>{views.length >= 1 ? views.filter((view,index) => index % 2 ===  0) : <EmptyLabel label={"1st column"}/>}</div>
            <div className={"col2"}>{views.length >= 2 ? views.filter((view,index) => index % 2 === 1) : <EmptyLabel label={"2nd column"}/>}</div>
        </section>

    if(views === null) return <></>
    if(viewState.isEdit) {
        const onChangeSettings: SettingChangeFunction|null = null;
        const suggestTags: string[] = [
            "current",
            "current .col1",
            "current .col2",
        ]
        const settingOptions:SettingOption[] = [
            { title: "General", type: SettingType.widget,  editor: "twocolumnssetting" , group: "content" , groupType: SettingGroupType.layout},
            { type: SettingType.background, group: "bt_twocolumns_panel" , groupType: SettingGroupType.style},
            { type: SettingType.margin,  group: "bt_twocolumns_panel" , groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "bt_twocolumns_panel" , groupType: SettingGroupType.advanced},

        ];
        const settingGroups:SettingGroup[] = [
            {group: "content", groupName: "General"},
            {group: "bt_twocolumns_panel", groupName: "Panel"}
        ]

        return (
            <EditablePanel {...props}
                           themeName={themeName}
                           newUILevelStr={props.uiLevelStr + "_" + (views.length)}
                           newOption={{ type: NewType.widget_or_section, tag: null}}
                           onHandleAddNewSectionOrWidget={props.onHandleAddNewSectionOrWidget}
                           suggestTags={suggestTags}
                           settingOptions={settingOptions}
                           settingGroups={settingGroups}
                           onChangeSettings={onChangeSettings}
                           deleteOption={{type: DeleteType.delete_with_rearrange, tag: null}}
                           >
                {createTwoColumn(views)}
            </EditablePanel>
        )
    } else {
        return createTwoColumn(views);
    }
}