
export function GetLocalItem(item:string , defaultValue: string):string {
    let value = localStorage.getItem(item);
    if(value === null) return defaultValue;
    else return value;
}

export function SetLocalItem(item: string, value: string) {
    if(value === null || value === "") {
        localStorage.removeItem(item);
    } else {
        localStorage.setItem(item, value);
    }
}

export function GetSessionItem(item: string, defaultValue: string): string {
    let value = sessionStorage.getItem(item);
    if(value === null) return defaultValue
    else return value;
}

export function SetSessionItem(item: string, value: string) {
    if(value === null || value === "") {
        sessionStorage.removeItem(item);
    } else {
        sessionStorage.setItem(item, value);
    }
}

