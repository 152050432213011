import styles from "./DAM.module.scss"
import InfiniteTable from "../table/InfiniteTable";
import {DAMDataFetcher} from "./DAMDataFetcher";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {lsi} from "../../datafetcher/LSIDataFetcher";
import {useContext, useEffect, useRef, useState} from "react";
import UploadContext from "../../context/UploadContext";
import {nanoid} from "nanoid";
import {DAMSearchFilter} from "./DAMFilter";


export function DAMFileList(props: {    currentPath: string,
                                        orgCode: string,
                                        searchFilter: DAMSearchFilter,
                                        mode: "edit"|"select",
                                        onUpdated?: () => void
                                        onChangePath: (path:string) => void
                                        onSelectFile: (filepath:string) => void
                                    }) {
    const [dataFetcher,setDataFetcher] = useState<DAMDataFetcher|undefined>(undefined);
    const [zipFiles,setZipFiles] = useState<File[]>([]);
    const uploadContext = useContext(UploadContext);
    const boxRef = useRef<HTMLDivElement>(null);
    const handleOnDrag = (event:React.DragEvent) => {
        if(boxRef.current !== null) {
            boxRef.current.classList.add(styles.DAMPanelDragOver);
        }
        event.preventDefault();
        event.stopPropagation();
    }

    const handleOnDragLeave = (event:React.DragEvent) => {
        if(boxRef.current !== null) {
            boxRef.current.classList.remove(styles.DAMPanelDragOver);
        }
        event.preventDefault();
        event.stopPropagation();
    }

    function generateTitle(files: File[]) {
        let title = "Upload " + files.length + " file(s)";
        if (files.length < 3) {
            title = "Upload ";
            for (let i = 0; i < files.length; i++) {
                if (i > 0) {
                    title += ", ";
                }
                title += files[i].name;
            }
        }
        return title;
    }

    const doUpdate = () => {
        setDataFetcher(new DAMDataFetcher(props.currentPath,props.searchFilter,props.onChangePath,props.onSelectFile,props.mode));
        if(props.onUpdated) props.onUpdated!();
    }

    const uploadZipFiles = (replace: boolean) => {
        if(zipFiles.length === 0) return;
        let files:File[] = [...zipFiles];
        if(files === null || files.length > 0) {
            let title = generateTitle(files);
            let progress = uploadContext.appendUpload(nanoid(), title);
            lsi.dam.uploadzip(dataFetcher!.getCurrentPath()!,
                replace,
                files,
                progress
            ).then((response) => {
                doUpdate();
            })
        }
        setZipFiles([]);
    }

    const uploadFiles = (files: File[]) => {
        if(files === null || files.length > 0) {
            let title = generateTitle(files);
            let progress = uploadContext.appendUpload(nanoid(), title);
            lsi.dam.upload(dataFetcher!.getCurrentPath()!,
                files,
                progress
            ).then((response) => {
                doUpdate();
            })
        }
    }
    const handleOnDrop = (event:React.DragEvent) => {
        if(boxRef.current !== null) {
            boxRef.current.classList.remove(styles.DAMPanelDragOver);
        }
        event.preventDefault();
        event.stopPropagation();
        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {

            let regularFiles:File[] = [];
            let zipFiles:File[] = [];
            for (let i = 0; i < event.dataTransfer.files.length; i++) {
                let file = event.dataTransfer.files.item(i);
                if(file !== null) {
                    if (file.name.toLowerCase().endsWith(".zip")) {
                        zipFiles.push(file);
                    } else {
                        regularFiles.push(file);
                    }
                }
            }

            uploadFiles(regularFiles);
            setZipFiles(zipFiles);
        }
    }

    const handleCloseZipFile = () => {
        setZipFiles([]);
    }

    const uploadAsIs = () => {
        uploadFiles([...zipFiles]);
        setZipFiles([]);
    }

    useEffect(() => {
        if(props.currentPath !== "") {
            setDataFetcher(new DAMDataFetcher(props.currentPath, props.searchFilter, props.onChangePath, props.onSelectFile, props.mode))
        }
    },[props.searchFilter,props.orgCode,props.onChangePath,props.currentPath,props.onSelectFile,props.mode])


    return <>
        <Box ref={boxRef} sx={{boxShadow: 2 }} className={styles.DAMPanel}
                    onDragOver={handleOnDrag}
                    onDragLeave={handleOnDragLeave}
                    onDrop={handleOnDrop}>
            <InfiniteTable hasCheckBox={true} dataFetcher={dataFetcher} height={props.mode === "select" ? 300: 500}/>
        </Box>
        <Dialog
            open={zipFiles.length > 0}
            onClose={handleCloseZipFile}
        >
            <DialogTitle>
                {"Upload zip file(s)"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="">
                    {"Select the option you want to process the zip file(s)"}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => uploadAsIs()} autoFocus={false}>
                    Upload as is
                </Button>
                <Button onClick={() => {uploadZipFiles(false)}} autoFocus={false}>
                    Unzip (ignore existing files)
                </Button>
                <Button onClick={() => {uploadZipFiles(true)}} autoFocus={false}>
                    Unzip (replace all)
                </Button>
            </DialogActions>
        </Dialog>
    </>
}