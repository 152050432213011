import {useOrganization} from "../../hook/useOrganization";
import React, {useEffect, useState} from "react";
import PageMenu from "../menu/PageMenu";
import styles from "./Drilldown.module.scss";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Breadcrumbs,
    Grid,
    IconButton,
    Tooltip,
    Typography
} from "@mui/material";
import LinkButton from "../button/LinkButton";
import Icon from "@mui/material/Icon";
import OutlinedIcon from "../icon/OutlinedIcon";
import {InputModelsToObject, InputModel, InputOption} from "../../model/InputModel";
import Link from "../button/Link";
import DrillItemCmd, {DrillChangeFrequency, DrillSortBy} from "../../cms/model/DrillItemCmd";
import {QueryType} from "../../cms/model/QueryType";
import _ from "lodash";
import {lsi} from "../../datafetcher/LSIDataFetcher";
import DrillitemEditor from "./DrillItemEditor";
import DrillInfo from "./DrillInfo";
import {NumberToString36, String36ToNumber} from "../../util/StringUtil";
import MerchandisingGroupCmd from "../../model/lsi/MerchandisingGroupCmd";
import {jcr} from "../../datafetcher/JCRDataFetcher";


interface DrilldownInternalState {
    expanded: boolean;
    subItems: DrilldownInternalState[];
}

export interface DrilldownEditorProps {
    abbr: string,
    path: string,
    type: string,
    id: string,
    name: string,
    // loadData: () => Promise<any>,
    drillData: any,
    saveData: (data: any) => void,
    uploadFile: () => void
}

const createNewDrilldownInternalState = (expanded: boolean): DrilldownInternalState => {
    return {
        expanded: expanded,
        subItems: []
    }
}

const createNewDrilldownCmd = (name: string):DrillItemCmd => {
    return {
        indexId: "",
        name: name,
        description: "",
        active: true,
        seoIndex: false,
        seoPageRank: 0.5,
        seoChangeFrequency: DrillChangeFrequency.never,
        searchType: QueryType.partnumber,
        searchString: "",
        featureType: QueryType.partnumber,
        featureString: "",
        bannerImage: "",
        categoryImage: "",
        searchProfile: "",
        subItems: [],
        displayLevel: 0,
        displayColumns: 1,
        sortBy: DrillSortBy.none
    }
}

interface DrillState {
    cmd: DrillItemCmd;
    state: DrilldownInternalState;
    levels: number[];
}

function countIndex(cmd: DrillItemCmd) {
    let string = "";
    if(cmd.indexId) {
        let index = cmd.indexId.indexOf("-");
        if(index > 0) {
            string = cmd.indexId.substring(index+1);
        }
    }
    let count = Math.max(String36ToNumber(string),0);
    for(let i=0;i<cmd.subItems.length;i++) {
        count = Math.max(countIndex(cmd.subItems[i]),count);
    }
    return count;
}

function fillMissingIndexId(abbr:string , cmd: DrillItemCmd,index: number) {
    if(cmd.indexId === "" || !cmd.indexId) {
        cmd.indexId = abbr + "-" + NumberToString36(++index);
    }
    cmd.subItems.forEach(subItem => {
        fillMissingIndexId(abbr,subItem,index);
    })
    return cmd;
}

function initInternalStateFromDrilldownCmd(cmd:DrillItemCmd):DrilldownInternalState {
    let internalState = createNewDrilldownInternalState(true);
    cmd.subItems.forEach(subItem => {
        internalState.subItems.push(initInternalStateFromDrilldownCmd(subItem));
    })
    return internalState;
}

export default function DrilldownEditor(props: DrilldownEditorProps ) {

    const {abbr} = props;
    const [drillState,setDrillState] = useState<DrillState|null>(null);
    const orgCmdProps = useOrganization();
    const [searchProfileOptions,setSearchProfileOptions] = useState<InputOption[] | null>(null);
    const [roleOptions, setRoleOptions] = useState<InputOption[]|null>(null);
    const [featureGroupOptions,setFeatureGroupOptions] = useState<InputOption[] | null>(null);
    const [drillGroupOptions,setDrillGroupOptions] = useState<InputOption[] | null>(null);
    const [drillData] = useState<any>(props.drillData);



    // const fetchAPI = useCallback(async () =>  {
    //     let data = await props.loadData();
    //     if(data !== null && 'name' in data) {
    //         let cmd = data as DrillItemCmd;
    //         setDrillState({
    //                         cmd: fillMissingIndexId(cmd,countIndex(cmd)),
    //                         state : initInternalStateFromDrilldownCmd(cmd),
    //                         levels: [0]});
    //     } else {
    //         setDrillState({
    //             cmd : fillMissingIndexId(createNewDrilldownCmd(props.name),0),
    //             state : createNewDrilldownInternalState(true),
    //             levels: [0]}
    //         );
    //     }
    // },[]);

    // useEffect(() => {
    //     fetchAPI();
    // },[fetchAPI]);

    useEffect(() => {
        if(drillData !== null && 'name' in drillData) {
            let cmd = drillData as DrillItemCmd;
            setDrillState({
                cmd: fillMissingIndexId(abbr,cmd,countIndex(cmd)),
                state : initInternalStateFromDrilldownCmd(cmd),
                levels: [0]});
        } else {
            setDrillState({
                cmd : fillMissingIndexId(abbr,createNewDrilldownCmd(props.name),0),
                state : createNewDrilldownInternalState(true),
                levels: [0]}
            );
        }
    },[abbr,drillData,props.name])


    useEffect(() => {
        if (orgCmdProps?.orgDetail !== null) {

            jcr.role.lite().then((response) => {
                let newRoleOptions: InputOption[] = [];
                newRoleOptions.push({value: "PUBLIC", label: "Everyone"});
                response.data.response.sort( (a:any, b:any) => {
                    let value = a.roleName.toLowerCase().localeCompare(b.roleName.toLowerCase())
                    if(value === 0) {
                        value = a.roleName.localeCompare(b.roleName)
                        if(value === 0) {
                            value = a.id.localeCompare(b.id);
                        }
                    }
                    return value;
                }).forEach((object:any) => {
                    newRoleOptions.push({value: object.id , label: object.roleName});
                })
                setRoleOptions(newRoleOptions);
            })
            lsi.search.searchProfiles().then((response) => {
                let data: InputOption[] = [];
                data.push({label: "None", value: ""})
                response.data.forEach((searchProfile, index: number) => {
                    data.push({label: searchProfile.name, value: searchProfile.name});
                })
                setSearchProfileOptions(data);
            });

            lsi.collection.featureCollections().then((response) => {
                let data: InputOption[] = [];
                data.push({label: "None", value: ""});
                let group:MerchandisingGroupCmd = response.data as MerchandisingGroupCmd;
                group.collections.forEach((collection, index: number) => {
                    data.push({label: collection.displayName, value: collection.name});
                })
                setFeatureGroupOptions(data);
            });
            lsi.collection.drilldownCollections().then((response) => {
                let data: InputOption[] = [];
                data.push({label: "None", value: ""});
                let group:MerchandisingGroupCmd = response.data as MerchandisingGroupCmd;
                group.collections.forEach((collection, index: number) => {
                    data.push({label: collection.displayName, value: collection.name});
                })
                // console.log(data);
                setDrillGroupOptions(data);
            });
        }
    },[orgCmdProps?.orgDetail]);


    const DrillMenu = () => {
        return <PageMenu type={props.type} link="drill-downs" name={props.name}>
            <React.Fragment>
                <Grid container spacing={1} direction="row"
                      alignItems="center"
                      classes={{
                          root: styles.SceneEditActionPanel
                      }}>
                    <Grid item>
                        <LinkButton variant={"contained"} classes={{
                            root: styles.DefaultViewMenuItem,
                            label: styles.DefaultViewMenuItem
                        }} onClick={() => {props.uploadFile()}}>
                            <Icon className={styles.Icon}>upload</Icon>
                            Upload
                        </LinkButton>
                        {drillState &&
                            <LinkButton variant={"contained"} classes={{
                                root: styles.DefaultViewMenuItem,
                                label: styles.DefaultViewMenuItem
                            }} onClick={() => props.saveData(drillState.cmd)}>
                                <Icon className={styles.Icon}>save</Icon>
                                Save
                            </LinkButton>
                        }
                    </Grid>
                </Grid>
            </React.Fragment>
        </PageMenu>
    }

    const handleChange = (inputId: string, values: InputModel[]) => {
        if(drillState) {
            let current:any = InputModelsToObject<DrillItemCmd>(values);
            let currentNode:any = findCurrentNode(drillState);
            if(inputId === "topDrillDown") {
                let title = document.getElementById("topDrillDownTitle");
                if(title !== null) {
                    title.innerText = current.name;
                }
                for (const property in current) {
                    currentNode[property] = current[property];
                }
            } else if(inputId.startsWith("subDrillDown")) {
                let title = document.getElementById(`${inputId}Title`);
                if(title !== null) {
                    title.innerText = current.name;
                }
                let index = parseInt(inputId.substring(12));
                if(index < currentNode.subItems.length) {
                    let temp:any = currentNode.subItems[index];
                    for (const property in current) {
                        temp[property] = current[property];
                    }
                }
            }
        }
    }

    const findCurrentNode = (drillState: DrillState):DrillItemCmd => {
        if (drillState && drillState.levels.length === 1) {
            return drillState.cmd;
        } else {
            let currentNode = drillState.cmd;
            let levels = drillState.levels;
            for (let i = 0; i < levels.length - 1; i++) {
                currentNode = currentNode.subItems[levels[i+1]];
            }
            return currentNode;
        }
    }

    const findCurrentState = (drillState: DrillState):DrilldownInternalState => {
        if (drillState && drillState.levels.length === 1) {
            return drillState.state;
        } else {
            let currentNode = drillState.state;
            let levels = drillState.levels;
            for (let i = 0; i < levels.length - 1; i++) {
                currentNode = currentNode.subItems[levels[i+1]];
            }
            return currentNode;
        }
    }

    const scrollToMenu = (index: number) => {
        setTimeout(() => {
            let element = document.getElementById(`subDrillDown${index}Summary`);
            element?.scrollIntoView({behavior: 'smooth', block: 'center'});
        }, 100);
    }

    const scrollToTop = () => {
        setTimeout(() => {
            let element = document.getElementById(`topDrillDown`);
            element?.scrollIntoView({behavior: 'smooth', block: 'center'});
        }, 100);
    }





    const handleAddSubCatalog = (fromIndex: number) => {
        if(drillState) {
            let newDrillState = _.cloneDeep(drillState);
            let currentNode = findCurrentNode(newDrillState);
            let currentState = findCurrentState(newDrillState);

            if(fromIndex < 0) {
                currentState.subItems = [createNewDrilldownInternalState(false),...currentState.subItems];
                currentNode.subItems = [createNewDrilldownCmd(currentNode.name + "-" + (currentNode.subItems.length+1)),
                    ...currentNode.subItems];
            } else {
                currentState.subItems = [
                    ...currentState.subItems.slice(0, fromIndex+1),
                    createNewDrilldownInternalState(false),
                    ...currentState.subItems.slice(fromIndex+1)
                ];
                currentNode.subItems =  [
                    ...currentNode.subItems.slice(0, fromIndex+1),
                    createNewDrilldownCmd(currentNode.name + "-" + ( currentNode.subItems.length+1)),
                    ...currentNode.subItems.slice(fromIndex+1)
                ]
            }

            fillMissingIndexId(abbr,newDrillState.cmd,countIndex(newDrillState.cmd));
            setDrillState(newDrillState);
            scrollToMenu(fromIndex+1);
        }
    }

    // const handleClickOnAccordion = (fromIndex: number) => {
    //     if(drillState) {
    //         let newDrillState = _.cloneDeep(drillState);
    //         let currentState = findCurrentState(newDrillState);
    //         currentState.subItems[fromIndex].expanded = !currentState.subItems[fromIndex].expanded;
    //         setDrillState(newDrillState);
    //     }
    // }

    const handleDeleteSubMenu = (fromIndex: number) => {
        if(drillState) {
            let newDrillState = _.cloneDeep(drillState);
            let currentNode = findCurrentNode(newDrillState);
            let currentState = findCurrentState(newDrillState);
            currentNode.subItems.splice(fromIndex,1);
            currentState.subItems.splice(fromIndex,1);
            setDrillState(newDrillState);
            scrollToMenu(fromIndex);
        }
    }

    const handleMoveDown = (fromIndex: number) => {
        if(drillState) {
            let newDrillState = _.cloneDeep(drillState);
            let currentNode = findCurrentNode(newDrillState);
            let currentState = findCurrentState(newDrillState);
            if(fromIndex+1 < currentState.subItems.length) {
                let tempNode = currentNode.subItems[fromIndex];
                currentNode.subItems[fromIndex] = currentNode.subItems[fromIndex+1];
                currentNode.subItems[fromIndex+1] = tempNode;

                let tempState = currentState.subItems[fromIndex];
                currentState.subItems[fromIndex] = currentState.subItems[fromIndex+1];
                currentState.subItems[fromIndex+1] = tempState;
                setDrillState(newDrillState);
                scrollToMenu(fromIndex+1);
                setTimeout(() => {
                    let element = document.getElementById("subDrillDown" + (fromIndex+1) + "Summary");
                    if(element != null && element.getAttribute("aria-expanded") === "false") {
                        element.click();
                    }
                },500);
            }
        }
    }

    const handleMoveUp = (fromIndex: number) => {
        if(drillState) {
            let newDrillState = _.cloneDeep(drillState);
            let currentNode = findCurrentNode(newDrillState);
            let currentState = findCurrentState(newDrillState);
            if(fromIndex > 0) {
                let tempNode = currentNode.subItems[fromIndex];
                currentNode.subItems[fromIndex] = currentNode.subItems[fromIndex-1];
                currentNode.subItems[fromIndex-1] = tempNode;

                let tempState = currentState.subItems[fromIndex];
                currentState.subItems[fromIndex] = currentState.subItems[fromIndex-1];
                currentState.subItems[fromIndex-1] = tempState;
                setDrillState(newDrillState);
                scrollToMenu(fromIndex-1);

                setTimeout(() => {
                    let element = document.getElementById("subDrillDown" + (fromIndex-1) + "Summary");
                    if(element != null && element.getAttribute("aria-expanded") === "false") {
                        element.click();
                    }
                },500);
            }
        }
    }

    const handleGotoSubMenu = (fromIndex: number) => {
        if(drillState) {
            let newDrillState = _.cloneDeep(drillState);
            newDrillState.levels.push(fromIndex);
            setDrillState(newDrillState);
            scrollToTop();
        }
    }

    const handleJumpToLevel = (level: number) => {
        if(drillState) {
            let newDrillState = _.cloneDeep(drillState);
            newDrillState.levels = newDrillState.levels.slice(0,level+1);
            setDrillState(newDrillState);
        }
    }

    const createBreadCrumb = (level: number,name:string,linkable:boolean) => {
        if(linkable) {
            return <Link key={level} href={"#"} onClick={() => {
                handleJumpToLevel(level);
            }}>{name}</Link>;
        } else {
            return <Typography key={level}>{name}</Typography>;
        }
    }


    if(drillState === null || roleOptions === null || searchProfileOptions === null || featureGroupOptions === null || drillGroupOptions === null) {
        return <PageMenu type={props.type} link="drill-downs" name={props.name}></PageMenu>
    }

    let currentNode:DrillItemCmd;
    let currentState:DrilldownInternalState;
    let breadcrumbs = [];
    let subNodes:DrillItemCmd[];
    //let subStates:DrilldownInternalState[];
    if(drillState.levels.length === 1) {
        currentNode = drillState.cmd;
        currentState = drillState.state;
        subNodes = currentNode.subItems;
        //subStates = currentState.subItems;
        breadcrumbs.push(createBreadCrumb(0,currentNode.name + " Drill Down",false));
    } else {
        currentNode = drillState.cmd;
        currentState = drillState.state;
        let levels = drillState.levels;
        for(let i=0;i<levels.length-1;i++) {
            if(i === 0) {
                breadcrumbs.push(createBreadCrumb(i, currentNode.name + " Drill Down",true));
            } else {
                breadcrumbs.push(createBreadCrumb(i, currentNode.name,true));
            }

            currentNode = currentNode.subItems[levels[i+1]];
            currentState = currentState.subItems[levels[i+1]];
        }

        breadcrumbs.push(createBreadCrumb(levels.length, currentNode.name,false));
        subNodes = currentNode.subItems;
        //subStates = currentState.subItems;
    }


    return <><DrillMenu/>
        <div id="drilldownEditor" className={styles.Root}>
            <div className={styles.Container}>
                <Breadcrumbs className={styles.Breadcrumb} separator={">"}>
                    <Link href={"/"}>CMS</Link>
                    <Link href={"/drill-downs"}>Drill-Downs</Link>
                    {breadcrumbs}
                </Breadcrumbs>
                <Accordion color={"primary"} TransitionProps={{ unmountOnExit: true }} >
                    <AccordionSummary
                        expandIcon={<OutlinedIcon>expand_more</OutlinedIcon>}
                    >
                        <Typography id="topDrillDownTitle" variant={"h5"}>{currentNode.name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={styles.DrilldownAction}>
                            <Tooltip title={"Add Menu"}  placement={"right"}>
                                <IconButton className={styles.DrilldownActionItem} onClick={() => handleAddSubCatalog(-1)}><Icon>add</Icon></IconButton>
                            </Tooltip>
                            {currentNode.subItems.length > 0 &&
                                <Tooltip title="Next" placement={"right"}>
                                    <IconButton className={styles.DrilldownActionItem}
                                                onClick={() => scrollToMenu(0)}><Icon>skip_next</Icon></IconButton>
                                </Tooltip>
                            }
                        </div>
                        <DrillInfo id={"topDrillDown"}
                                   cmd={currentNode} handleChange={handleChange}
                                   searchProfileOptions={searchProfileOptions}
                                   featureGroupOptions={featureGroupOptions}
                                   roleOptions={roleOptions}
                                   drillGroupOptions={drillGroupOptions}/>
                    </AccordionDetails>
                </Accordion>
            </div>
            {
                subNodes &&
                <div className={styles.Container2}><div className={styles.SubContainer}>
                    {subNodes.map((subNode,index) =>
                        <DrillitemEditor subNode={subNode}
                                         index={index}
                                         key={"drill_menu" + index}
                                         length={subNodes.length}
                                         handleAddSubCatalog={handleAddSubCatalog}
                                         handleGotoSubMenu={handleGotoSubMenu}
                                         scrollToMenu={scrollToMenu}
                                         handleMoveUp={handleMoveUp}
                                         handleMoveDown={handleMoveDown}
                                         scrollToTop={scrollToTop}
                                         handleDeleteSubMenu={handleDeleteSubMenu}
                                         handleChange={handleChange}
                                         roleOptions={roleOptions}
                                         searchProfileOptions={searchProfileOptions}
                                         featureGroupOptions={featureGroupOptions}
                                         drillGroupOptions={drillGroupOptions}
                        />
                    )
                    }
                </div></div>
            }
            <div className={styles.EmptyPanel}></div>
        </div>
    </>
}