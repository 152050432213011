import React, {CSSProperties, useState} from "react";
import {DAMFile} from "./DAM";
import styles from "./DAMCellRenderer.module.scss"
import {IByteToString} from "../../util/BytesUtil";
import {Icon, IconButton, TextField, Tooltip, Typography} from "@mui/material";
import Link from "../button/Link";
import {format} from "date-fns";
import OutlinedIcon from "../icon/OutlinedIcon";
import {StyleUtil} from "../../cms/ui/setting/StyleUtil";

function getFileName(name: string) {
    let index = name.lastIndexOf("/");
    return name.substring(index+1);
}

function getFolderName(name: string) {
    let lastIndex = name.lastIndexOf("/");
    let firstIndex = name.indexOf("/");
    let folder = name.substring(firstIndex,lastIndex);
    if(folder === "") return "/"
    else return folder;
}

export default function DAMCellRenderer(props: {
        mode: string,
        style:CSSProperties,
        damFile?: DAMFile,
        showSubFolder: boolean,
        onDelete?: () => void,
        onRename?: (oldName:string, newName:string) => void,
        onChangePath: (path:string) => void
        onSelectFile?: (file:string) => void
    }) {
    let {style, damFile} = props;
    const [rename, setRename] = useState(false);
    const [name,setName] = useState(getFileName(damFile ? damFile.ORIGINAL_FILE_NAME : ""));
    const [errorMessage,setErrorMessage] = useState<string>("");

    const handleRename = () => {
        if(props.onRename) {
            props.onRename(getFileName(damFile ? damFile.ORIGINAL_FILE_NAME : ""),name);
        }
    }

    const validator = (value:string): string => {
        if(value === "") {
            return "Name is required";
        } else if(!value.match(/^[A-Za-z0-9_ !-_.*'()&$@=;+,?:]+$/)) {
            return "Name must contain only [a-z ][A-Z][0-9][ !-_.*'()&$@=;+,?:]"
        } else {
            return "";
        }
    }

    const handleCancelRename = () => {
        setName(getFileName(damFile ? damFile.ORIGINAL_FILE_NAME : ""));
        setRename(false);
    }

    const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        let newValue = event.currentTarget.value;
        newValue = newValue.trim();
        setName(newValue);
        setErrorMessage(validator(newValue))
        event.preventDefault();
        event.stopPropagation();
    }

    const handleSelect = (file:string) => {
        if(props.onSelectFile) {
            let index = file.indexOf("/"); // remove the orgCode name
            props.onSelectFile(file.substring(index));
        }
    }

    if(damFile) {
        let canModify = name !== "readme";
        return <div style={style} className={styles.Root}>
            <div className={StyleUtil.combineClasses(styles.FileName,props.mode === "select" ? styles.FileNameFull : "")}>
                {rename && <TextField autoFocus={true} value={name}
                                      variant={"standard"}
                                      sx={{fontSize: "0.5rem"}}
                                      onChange={handleChange}
                                      error={errorMessage !== ""}
                                      fullWidth
                                      InputProps={{endAdornment: <>
                                                                    {errorMessage === "" && name !== getFileName(damFile.ORIGINAL_FILE_NAME) && <IconButton tabIndex={-1} onClick={handleRename}><OutlinedIcon>done</OutlinedIcon></IconButton>}
                                                                    <IconButton tabIndex={-1} onClick={handleCancelRename}><OutlinedIcon>close</OutlinedIcon></IconButton>
                                                                </>}}
                                      />}
                {!rename && <>
                    <Link href="#" onClick={() => handleSelect(damFile ? damFile.ORIGINAL_FILE_NAME : "")}><Typography sx={{fontSize: "0.9rem", display:"inline"}}>{name}</Typography></Link>
                    {canModify && props.mode === "edit" &&  <IconButton onClick={() => { setErrorMessage(""); setRename(true)}}><OutlinedIcon className={styles.RenameIcon}>drive_file_rename_outline</OutlinedIcon></IconButton> }
                    </>
                }
                {
                    props.showSubFolder && <div><i><b>Folder :</b></i> <Link href="#" onClick={() => {props.onChangePath(getFolderName(damFile!.ORIGINAL_FILE_NAME))}}>{getFolderName(damFile.ORIGINAL_FILE_NAME)}</Link></div>
                }
            </div>
            {props.mode === "edit" && <>
                <div className={styles.Size}>
                    <Typography className={styles.FontSize} sx={{lineHeight: props.showSubFolder ? "60px": undefined}}>{IByteToString(damFile.SIZE,2)}</Typography>
                </div>
                <div className={styles.Date}>
                    <Tooltip title={format(new Date(damFile.LAST_MODIFIED_DATE), 'yyyy/MM/dd hh:mm:ss')}>
                        <Typography className={styles.FontSize} sx={{lineHeight: props.showSubFolder ? "60px":undefined}}>{format(new Date(damFile.LAST_MODIFIED_DATE), 'yyyy/MM/dd')}</Typography>
                    </Tooltip>
                </div>
                <div className={styles.Action}  style={{lineHeight: props.showSubFolder ? "60px":undefined}}>
                    {canModify && <IconButton onClick={props.onDelete}><Tooltip title={"Delete"} placement={"right"}><Icon className={styles.Icon}>delete_outline</Icon></Tooltip></IconButton> }
                </div>
            </>}
        </div>
    } else {
        return <div style={style}></div>
    }
}