import {Accordion, AccordionDetails, AccordionSummary, Icon, IconButton, Tooltip, Typography} from "@mui/material";
import OutlinedIcon from "../icon/OutlinedIcon";
import React, {useState, useEffect} from "react";
import DrillItemCmd from "../../cms/model/DrillItemCmd";
import styles from "./Drilldown.module.scss";
import {InputModel, InputOption} from "../../model/InputModel";
import DrillInfo from "./DrillInfo";

export interface DrillItemEditorProp {
    subNode: DrillItemCmd,
    index: number,
    length:number,
    handleAddSubCatalog: (index:number) => void,
    handleGotoSubMenu: (index:number) => void
    scrollToMenu: (index:number) => void
    handleMoveUp: (index: number) => void
    handleMoveDown: (index: number) => void
    scrollToTop: () => void
    handleDeleteSubMenu: (index:number) => void
    handleChange: (inputId: string, values: InputModel[]) => void
    searchProfileOptions: InputOption[]
    drillGroupOptions: InputOption[]
    featureGroupOptions: InputOption[]
    roleOptions: InputOption[]
}

export default function DrillItemEditor(props: DrillItemEditorProp) {
    const {subNode,index,length,roleOptions,searchProfileOptions,featureGroupOptions,drillGroupOptions,
        handleAddSubCatalog,handleGotoSubMenu,scrollToMenu,
        handleMoveUp,scrollToTop,handleMoveDown,handleDeleteSubMenu} = props;
    const [showDetail , setShowDetail] = useState<boolean>(false);
    const [title,setTitle] = useState<string[]>([subNode.name,subNode.indexId]);

    const handleChange = (inputId: string, values: InputModel[]) => {
        props.handleChange(inputId, values);
        let allValue = values.filter(value => value.id === "name" || value.id === "indexId");
        if(allValue) {
            setTitle([allValue[1].defaultValue , allValue[0].defaultValue ]);
        }
    }

    useEffect(() => {
        setTitle([props.subNode.name,props.subNode.indexId])
    },[props.subNode])

    return <Accordion key={"accordion_" + index} defaultExpanded={false}
                      onChange={() => {
                            setShowDetail(!showDetail);
                        }
                      }
                      id={`subDrillDown${index}Summary`}
                      TransitionProps={{ unmountOnExit: true }}
    >
        <AccordionSummary
            expandIcon={<OutlinedIcon>expand_more</OutlinedIcon>}
        >
            <Typography id={`subDrillDown${index}Title`} variant={"subtitle1"}><b>{title[0]}</b> ({title[1]})</Typography>
        </AccordionSummary>
        {showDetail === false && <div id={`subDrillDown${index}`}></div>}
        {showDetail && <AccordionDetails>
            <div className={styles.DrilldownAction}>
                <Tooltip title="Add Menu" placement={"right"}>
                    <IconButton className={styles.DrilldownActionItem}
                                onClick={() => handleAddSubCatalog(index)}><Icon>add</Icon></IconButton>
                </Tooltip>
                <Tooltip title="Sub menus" placement={"right"}>
                    <IconButton className={styles.DrilldownActionItem}
                                onClick={() => handleGotoSubMenu(index)}><Icon>segment</Icon></IconButton>
                </Tooltip>
                {index !== 0 && <>
                    <Tooltip title="Previous" placement={"right"}>
                        <IconButton className={styles.DrilldownActionItem}
                                    onClick={() => scrollToMenu(index - 1)}><Icon>skip_previous</Icon></IconButton>
                    </Tooltip>
                    <Tooltip title="Move Up" placement={"right"}>
                        <IconButton className={styles.DrilldownActionItem}
                                    onClick={() => handleMoveUp(index)}><Icon>move_up</Icon></IconButton>
                    </Tooltip>
                </>
                }
                {
                    index === 0 &&
                    <Tooltip title="Previous" placement={"right"}>
                        <IconButton className={styles.DrilldownActionItem}
                                    onClick={() => scrollToTop()}><Icon>skip_previous</Icon></IconButton>
                    </Tooltip>
                }
                {index + 1 !== length && <>
                    <Tooltip title="Move Down" placement={"right"}>
                        <IconButton className={styles.DrilldownActionItem}
                                    onClick={() => handleMoveDown(index)}><Icon>move_down</Icon></IconButton>
                    </Tooltip>
                    <Tooltip title="Next" placement={"right"}>
                        <IconButton className={styles.DrilldownActionItem}
                                    onClick={() => scrollToMenu(index + 1)}><Icon>skip_next</Icon></IconButton>
                    </Tooltip>
                </>
                }
                <Tooltip title="Delete" placement={"right"}>
                    <IconButton className={styles.DrilldownActionItem}
                                onClick={() => handleDeleteSubMenu(index)}><Icon>delete</Icon></IconButton>
                </Tooltip>
            </div>
            <DrillInfo id={`subDrillDown${index}`} cmd={subNode} handleChange={handleChange}
                       searchProfileOptions={searchProfileOptions}
                       featureGroupOptions={featureGroupOptions}
                       drillGroupOptions={drillGroupOptions}
                       roleOptions={roleOptions}
            />
            </AccordionDetails>
        }
    </Accordion>
}