import {
    Grid,
    MenuItem,
    TextField, ToggleButtonGroup, ToggleButton
} from "@mui/material";
import React, {useEffect, useState} from "react";
import ProductSearchSetting from "../../setting/productsearchsetting/ProductSearchSetting";
import {parseInt} from "lodash";
import {InputOption} from "../../../../model/InputModel";
import DisplayField from "../../shared/displayfield/DisplayField";
import {SettingLabel} from "../../setting/settinglabel/SettingLabel";
import OnOffSwitch from "../../setting/onoffswitch/OnOffSwitch";
import {ImageCmd} from "../../../model/ImageCmd";
import ImageActionSetting from "../../shared/imageactionsetting/ImageActionSetting";
import SizeInput from "../../../../ui/setting/input/SizeInput";

export default function ImageListSetting(props: {
                                                    displayOptions?: InputOption[],
                                                    imageOptions?: InputOption[],
                                                    widgetData: any,
                                                    saveWidgetState: () => void ,
                                                    widgetCallback: (action: string, value: any) => void
}) {
    const {widgetData} = props;
    const {widgetProps} = widgetData;

    const [title,setTitle] = useState<string>(widgetProps['content']['title'] ? widgetProps['content']['title'] : "");
    const [captionField,setCaptionField] = useState<string>(widgetProps['content']['captionField'] ? widgetProps['content']['captionField'] : "_lsi_cn_ProductID");
    const [columns, setColumns] = useState<number>(widgetProps['content']['columns'] ? widgetProps['content']['columns'] : 3);
    const [gap, setGap] = useState<string>(widgetProps['content']['columns'] ? widgetProps['content']['gap'] : "4px");
    const [rows, setRows] = useState<number>(widgetProps['content']['rows'] ? widgetProps['content']['rows'] : 2.5);
    const [columnWidth, setColumnWidth] = useState<string>(widgetProps['content']['columnWidth'] ? widgetProps['content']['columnWidth'] : "200px");
    const [columnHeight, setColumnHeight] = useState<string>(widgetProps['content']['columnHeight'] ? widgetProps['content']['columnHeight'] : "auto");
    const [carouselWidth, setCarouselWidth] = useState<string>(widgetProps['content']['carouselWidth'] ? widgetProps['content']['carouselWidth'] : "100%");
    const [carouselHeight, setCarouselHeight] = useState<string>(widgetProps['content']['carouselHeight'] ? widgetProps['content']['carouselHeight'] : "auto");
    const [carouselTimeout, setCarouselTimeout] = useState<number>(widgetProps['content']['carouselTimeout'] ? widgetProps['content']['carouselTimeout'] : 0);
    const [carouselTransition,setCarouselTransition] = useState<string>(widgetProps['content']['carouselTransition'] ? widgetProps['content']['carouselTransition'] : "slide");
    const [hasCaption,setHasCaption] = useState<boolean>(widgetProps['content']['hasCaption']);
    const [displayType, setDisplayType] = useState<string>(widgetProps['content']['displayType']? widgetProps['content']['displayType'] : "STANDARD");
    const [imageFields, setImageFields] = useState<string[]>([]);
    const [datasource, setDatasource] = useState<string>(widgetProps['content']['datasource'] ? widgetProps['content']['datasource'] : "CUSTOM");
    const [openLink,setOpenLink] = useState<string>(widgetData.widgetProps['content']['openLink'] ? widgetData.widgetProps['content']['openLink'] : "_self");
    //const [urls, setURLs] = useState<string[]>(widgetProps['content']['urls'] ? widgetProps['content']['urls'] : []);
    const [images,setImages] = useState<ImageCmd[]>(widgetData.widgetProps['content']['images'] ? widgetData.widgetProps['content']['images'] : []);


    useEffect(()=> {
        let fieldNames:string[] = [];
        for(let i=1;i<=3;i++) {
            let data = widgetProps['content']['imageField' +i];
            if(data) {
                fieldNames[i-1] = data;
            } else {
                if (i === 1) {
                    fieldNames[0] = "_lsi_cn_ImageThumb";
                } else {
                    fieldNames[i - 1] = "";
                }
            }
        }
        setImageFields(fieldNames);
    }, []);


    const handleChange = (value: string|number|boolean, key: string|number) => {
        widgetProps["content"][key] = value;
        props.saveWidgetState();
    }

    const handleChangeValue = (index: number,image:ImageCmd,updated:boolean) => {
        let newImages = [...images];
        newImages[index] = image;
        if(image.image_href === "" && image.image_url === "") {
            newImages.splice(index,1);
        }
        widgetProps["content"]['images'] = newImages;
        if(updated) {
            setImages(newImages)
        }
        props.saveWidgetState();
    }


    const createAutoComplete = (label:string,
                                name:string,
                                value:string,
                                setFunction: (value:string) => void,
                                displayOptions: InputOption[]) => {
        return <DisplayField label={label} name={name} value={value} setFunction={setFunction} handleChange={handleChange} displayOptions={displayOptions}/>
    }

    return <Grid container spacing={1}>
        <Grid item xs={4}>
            <SettingLabel title={"Title"}/>
        </Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={""}
                       value={title}
                       onChange={(event) => {
                           handleChange(event.target.value.trim(), "title")
                           setTitle(event.target.value);
                       }}/>
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"Display"}/>
        </Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                        id={"display_type"}
                        label={""}
                        fullWidth={true}
                        value={displayType}
                       select={true}
                        onChange={(event) => {
                            handleChange(event.target.value.trim(), "displayType")
                            setDisplayType(event.target.value);
                        }}
                >
                    <MenuItem value={"STANDARD"}>Standard</MenuItem>
                    <MenuItem value={"QUILTED"}>Quilted (4+ images)</MenuItem>
                    <MenuItem value={"WOVEN"}>Woven (6+ images)</MenuItem>
                    <MenuItem value={"CAROUSEL"}>Carousel</MenuItem>
            </TextField>
        </Grid>
        { displayType === "CAROUSEL" && <>
                <Grid item xs={4}>
                    <SettingLabel title={"Carousel Image Width"}/>
                </Grid>
                <Grid item xs={8}>
                    <SizeInput size={"small"}
                               fullWidth={true}
                               label={""}
                               value={carouselWidth}
                               allowPixel={false}
                               onChangeSize={(value) => {
                                   handleChange(value, "carouselWidth")
                                   setCarouselWidth(value);
                               }}
                               // onChange={(event) => {
                               //     handleChange(event.target.value, "carouselWidth")
                               //     setCarouselWidth(event.target.value);
                               // }}
                     auto={false}/>
                </Grid>
                <Grid item xs={4}>
                    <SettingLabel title={"Carousel Image Height"}/>
                </Grid>
                <Grid item xs={8}>
                    <SizeInput size={"small"}
                               fullWidth={true}
                               label={""}
                               value={carouselHeight}
                               onChangeSize={(value) => {
                                   handleChange(value, "carouselHeight")
                                   setCarouselWidth(value);
                               }}
                        // onChange={(event) => {
                        //     handleChange(event.target.value, "carouselWidth")
                        //     setCarouselWidth(event.target.value);
                        // }}
                               auto={true}/>
                </Grid>
            </>
        }
        { displayType !== "CAROUSEL" && <> { displayType !== "QUILTED" && <>
            <Grid item xs={4}>
                <SettingLabel title={"Columns"}/>
            </Grid>
            <Grid item xs={8}>
                <TextField size={"small"}
                           fullWidth={true}
                           label={""}
                           defaultValue={columns}
                           onChange={(event) => {
                               let value = parseInt(event.target.value);
                               if(isNaN(value) === false) {
                                   handleChange(value, "columns")
                                   setColumns(value);
                               }

                           }}/>

            </Grid></>}
            <Grid item xs={4}>
                <SettingLabel title={"Rows"}/>
            </Grid>
            <Grid item xs={8}>
                <TextField size={"small"}
                           fullWidth={true}
                           label={""}
                           defaultValue={rows}
                           onChange={(event) => {
                               let value = parseFloat(event.target.value);
                               if(isNaN(value) === false) {
                                   handleChange(value, "rows")
                                   setRows(value);
                               }

                           }}/>
            </Grid>
            <Grid item xs={4}>
                <SettingLabel title={"Gap"}/>
            </Grid>
            <Grid item xs={8}>
                <SizeInput size={"small"}
                           fullWidth={true}
                           label={""}
                           value={gap}
                           onChangeSize={(value) => {
                               handleChange(value, "gap")
                               setGap(value);
                           }}
                    // onChange={(event) => {
                    //     handleChange(event.target.value.trim(), "gap")
                    //     setGap(event.target.value);
                    // }}
                           auto={false}/>
            </Grid>
            <Grid item xs={4}>
                <SettingLabel title={"Column Width"}/>
            </Grid>
            <Grid item xs={8}>
                <SizeInput size={"small"}
                           fullWidth={true}
                           label={""}
                           value={columnWidth}
                           onChangeSize={(value) => {
                               handleChange(value, "columnWidth")
                               setColumnWidth(value);
                           }}
                           auto={true}/>
            </Grid>
            <Grid item xs={4}>
                <SettingLabel title={"Column Height"}/>
            </Grid>
            <Grid item xs={8}>
                <SizeInput size={"small"}
                           fullWidth={true}
                           label={""}
                           value={gap}
                           onChangeSize={(value) => {
                               handleChange(value, "columnHeight")
                               setColumnHeight(value);
                           }}
                           auto={true}/>

            </Grid></>}
        <Grid item xs={4}><SettingLabel title={"Open Link In"}/></Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                       fullWidth={true}
                       select={true}
                       label={""}
                       value={openLink}
                       onChange={(event) => {
                           handleChange(event.target.value.trim(), "openLink")
                           setOpenLink(event.target.value);
                           // props.onChange(searchType, searchString, start, maxItems,searchProfileId);
                       }}>
                <MenuItem value={"_self"}>Current Window</MenuItem>
                <MenuItem value={"_blank"}>New Window</MenuItem>
            </TextField>
        </Grid>
        {/*<Grid item xs={12}>*/}
        {/*    <ToggleButtonGroup*/}
        {/*        fullWidth*/}
        {/*        value={datasource}*/}
        {/*        exclusive*/}
        {/*        onChange={(event,value) => {*/}
        {/*            setDatasource(value)*/}
        {/*            handleChange(value,"datasource");*/}
        {/*        }}*/}
        {/*        size={"small"}*/}
        {/*    >*/}
        {/*        <ToggleButton value="CATALOG">*/}
        {/*            Catalog*/}
        {/*        </ToggleButton>*/}
        {/*        <ToggleButton value="CUSTOM">*/}
        {/*            Static*/}
        {/*        </ToggleButton>*/}
        {/*    </ToggleButtonGroup>*/}
        {/*</Grid>*/}
        {   datasource === "CUSTOM" && <>
            { images.map((image,index) => <React.Fragment key={`image_action_${index}`} >
                    <Grid item xs={3}><SettingLabel title={`Image #${index+1}`}/></Grid>
                    <Grid item xs={9}><ImageActionSetting
                        key={`image_cmd_${index}_${images.length}`}
                        value={image}
                        index={index}
                        onChange={(newValue) => {
                            handleChangeValue(index, newValue,true);
                        }}/>
                    </Grid>
                </React.Fragment>)
            }
            <React.Fragment >
                <Grid item xs={3}><SettingLabel title={`Image #${images.length+1}`}/></Grid>
                <Grid item xs={9}><ImageActionSetting
                                        key={`image_cmd_${images.length}_${images.length}`}
                                        value={{image_url: "", image_href: "", image_caption: ""}}
                                        index={images.length}
                                        onChange={(newValue) => {
                                            handleChangeValue(images.length, newValue,false);
                                        }}
                                        onBlur={(newValue) => {
                                            handleChangeValue(images.length, newValue,true);
                                        }}/>
            </Grid>
            </React.Fragment>
            </>
        }
        { datasource === "CATALOG" && <>
            <ProductSearchSetting widgetData={widgetData} saveWidgetState={props.saveWidgetState} widgetCallback={props.widgetCallback}/>
            { imageFields.map((fieldName,fieldIndex) => {
                return <React.Fragment key={`il_field_${fieldIndex}`}>
                    <Grid item xs={4}>
                        <SettingLabel title={"Image Field #" + (fieldIndex+1)}/>
                    </Grid>
                    <Grid item xs={8} key={fieldIndex}>
                    {createAutoComplete("","imageField" + (fieldIndex+1),fieldName,
                        (value: string) => {
                            let newFields = [...imageFields];
                            newFields[fieldIndex] = value;
                            setImageFields(newFields)
                        }
                        ,props.imageOptions!)}
                </Grid></React.Fragment>
            })}
            <Grid item xs={4}>
                <SettingLabel title={"Has Caption"}/>
            </Grid>
            <Grid item xs={8}>
                <OnOffSwitch defaultValue={hasCaption} onChange={(value) => {
                    handleChange(value, "hasCaption")
                    setHasCaption(value);
                    }
                }/>
            </Grid>
            { hasCaption && <>
                <Grid item xs={4}>
                    <SettingLabel title={"Caption Field"}/>
                </Grid>
                <Grid item xs={8}>
                    {createAutoComplete("","captionField",captionField,setCaptionField,props.displayOptions!)}
                </Grid></>
            }
        </>
    }
    </Grid>

}