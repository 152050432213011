import styles from "./SettingPanel.module.scss"
import React from "react";
import {StyleUtil} from "../../cms/ui/setting/StyleUtil";

export interface SettingPanelProps {
    title: string ,
    children: any
    id?:string
    className?: string
    style?: any,
}

export default function SettingPanel(props: SettingPanelProps) {
    return <fieldset id={props.id}
                     className={StyleUtil.combineClasses(
                         styles.EmptyBorderPanel,
                         // styles.BorderPanel,
                         props.className!)}
                     style={props.style}>
        { props.title !== "Widget" && <div className={styles.Title}>{props.title}</div> }
        {/*    <legend className={styles.BorderPanelLegend}>*/}
        {/*        {props.title}*/}
        {/*    </legend>*/}
        {/*}*/}
        {props.children}
    </fieldset>
}
