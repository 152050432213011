import SettingProps from "../SettingProps";
import GenericSetting, {StyleSettingType} from "../genericsetting/GenericSetting";

export default function BoxShadowSetting(props: SettingProps) {
    const handleAfterUpdate = (data:any,value:any,key:string) => {
        let color = data['box-shadow-color'];
        let horizontal = data['box-shadow-horizontal'];
        let vertical = data['box-shadow-vertical'];
        let blur = data['box-shadow-blur'];
        let spread = data['box-shadow-spread'];
        console.log("X",color,horizontal,vertical,blur,spread);
        if((color === "" || color === undefined) &&
            (horizontal === "" || horizontal === undefined) &&
            (vertical === "" || vertical === undefined) &&
            (blur === "" || blur === undefined) &&
            (spread === "" || spread === undefined)) {
            delete data['box-shadow'];
        } else {
            if(color === "" || color === undefined) {
                color = "transparent"
            }
            if(horizontal === "" || horizontal === undefined) {
                horizontal = "0px"
            }
            if(vertical === "" || vertical === undefined) {
                vertical = "0px"
            }
            if(blur === "" || blur === undefined) {
                blur = "0px"
            }
            if(spread === "" || spread === undefined) {
                spread = "0px"
            }
            data['box-shadow'] = `${color} ${horizontal} ${vertical} ${blur} ${spread}`
        }
    }
    return <GenericSetting {...props}
                           title={"Box Shadow"}
                           type={"boxshadow"}
                           afterUpdate={handleAfterUpdate}
                           targets={[
                                    { name: "Color",target: 'box-shadow-color' , type: StyleSettingType.color},
                                    { name: "Horizontal",target: 'box-shadow-horizontal' , type: StyleSettingType.default_size},
                                    { name: "Vertical",target: 'box-shadow-vertical' , type: StyleSettingType.default_size},
                                    { name: "Blur", target: 'box-shadow-blur' , type: StyleSettingType.default_size},
                                    { name: "Spread", target: 'box-shadow-spread' , type: StyleSettingType.default_size},
                                ]}
    />
}