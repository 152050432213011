import {Grid, Box} from "@mui/material";
import styles from "./SingleMetric.module.scss";
import OutlinedIcon from "../icon/OutlinedIcon";

export interface SingleMetricProps {
    title: string
    value: string
    color: string
    icon: string
}
export default  function SingleMetric(props: SingleMetricProps) {
    return <Box sx={{ boxShadow: 3, margin: "10px", backgroundColor: props.color}} className={styles.Panel}>
        <Grid container  justifyContent="center">
            <Grid item xs={3}  className={styles.IconPanel}>
                <OutlinedIcon className={styles.IconSize}>{props.icon}</OutlinedIcon>
            </Grid>
            <Grid item xs={9}>
                <Grid container>
                <Grid item xs={12} md={12} className={styles.Value}>
                    {props.value}
                </Grid>
                <Grid item xs={12} md={12} className={styles.Title}>
                    {props.title}
                </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Box>
}