import {InputModel} from "../model/InputModel";

export function InputModelsToJson(values: InputModel[]): any {
    let data = {};
    values.forEach(object => {
        let names = object.id.split(".");
        let current:any = data;
        for(let j=0;j<names.length-1;j++) {
            let name = names[j];
            if(name in  current) {
                current = current[name]
            } else {
                current[name] = {}
                current = current[name]
            }
        }
        current[names[names.length-1]] = object.defaultValue;
    })
    return data;
}
