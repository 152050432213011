import {Card, CardContent, CardMedia, Tooltip, Typography} from "@mui/material";
import styles from "./TemplateItem.module.scss"


export default function TemplateItem(props: {
                                        title: string,
                                        subTitle?: string,
                                        selected?: boolean
                                        type: string,
                                        logo: string,
                                        modifiedAt: number,
                                        data: string,
                                        totalLayout: number
                                        size?: "small"|"medium",
                                        doSelectTemplate: (data: string) => void,
                                        doFocusTemplate?: () => void}) {

    return <Card className={styles.Root  + " " + (props.selected ? styles.RootSelected : "")+ " " + (props.size === "small" ? styles.RootSmall : "")} onClick={() => {
            if(props.doFocusTemplate) {
                props.doFocusTemplate();
            } else {
                props.doSelectTemplate(props.data);
            }
        }}>
        <CardMedia
            sx={{height: props.size === "small" ? 150 : 260, "objectPosition": "top"}}
            image={`${props.logo}?${props.modifiedAt}`}
            title={props.title}
            component="img"
            loading={"lazy"}
            className={styles.Media}
            onError={(event:any) => {
                let object:any = event.currentTarget;
                object.onerror = "";
                object.src = "/images/transparent.png";
                return true;
            }}
        />
        <CardContent className={props.size === "small" ? styles.ContentSmall : styles.Content}>
            {props.type === "page" && props.totalLayout === 1 && <div className={props.size === "small" ? styles.LayoutTitleSmall : styles.LayoutTitle}> {props.totalLayout} layout</div> }
            {props.type === "page" && props.totalLayout > 1 && <div className={props.size === "small" ? styles.LayoutTitleSmall : styles.LayoutTitle}> {props.totalLayout} layouts</div> }
            <div className={props.size === "small" ? styles.TitleSmall : styles.Title}>
                <Tooltip title={props.title}><span>{props.title}</span></Tooltip>
            </div>
            { props.subTitle && <div className={styles.SubTitle}>{props.subTitle}</div>}
        </CardContent>
    </Card>;
}