import {Box, Button, Grid, IconButton, Popover, TextField} from "@mui/material";
import {useRef, useState} from "react";
import OutlinedIcon from "../icon/OutlinedIcon";
import LinkButton from "../button/LinkButton";


let globalTimeout:NodeJS.Timeout|null = null;
export interface NewPopoverProps {
    id: string,
    action: string,
    icon?: string,
    link?: {icon: string , label: string}
    title: string ,
    onAddNew: (value:string) => Promise<any>
    validator?: (value:string) => string
}
export default function NewPopover(props: NewPopoverProps) {

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [newValue, setNewValue] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const textInput = useRef<HTMLInputElement>(null);

    const validator = props.validator ? props.validator : (value:string) : string => {
            if(value === "") {
                return "";
            } else if(!value.match(/^[A-Za-z0-9_ !\-_.*'()&$@=;+,:]+$/)) { //  /^[A-Za-z0-9_ !-_.*'()/&$@=;+,:]+$/
                return "Name must contain only [a-z ][A-Z][0-9][ !-_.*'()/&$@=;+,?:]"
            } else {
                return "";
            }
        }

    const doValidate = (inputValue:string) => {
        let errorMsg = validator(inputValue);
        setNewValue(inputValue);
        if(errorMsg !== null && errorMsg !== "") {
            setErrorMessage(errorMsg);
        } else {
            setErrorMessage("");
        }
    }

    const handleOnClickAdd = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setErrorMessage("");
    };
    const handleOnClose = () => {
        setAnchorEl(null);
    }

    const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        doValidate(event.currentTarget.value.trim());
    }

    const doAdd = () => {
        if(globalTimeout !== null) {
            clearTimeout(globalTimeout);
            globalTimeout = null;
        }

        props.onAddNew(newValue).then(() => {
            setAnchorEl(null);
        })
    }
    const handleClickAdd = (event:React.MouseEvent<HTMLButtonElement>) => {
        doAdd();
    }

    const handleOnBlur = (event:React.FocusEvent<HTMLInputElement>) => {
        if(textInput !== null && textInput.current !== null) {
            globalTimeout = setTimeout(() => {
                setAnchorEl(null);
                globalTimeout = null;
            }, 100);
        }
        event.stopPropagation();
        event.preventDefault();
    }

    const handleClickOnGrid = (event:React.MouseEvent<HTMLDivElement>) => {
        if(globalTimeout !== null) {
            clearTimeout(globalTimeout);
            globalTimeout = null;
        }
        if(textInput !== null && textInput.current !== null) {
            textInput.current.focus();
        }
        event.preventDefault();
        event.stopPropagation();
    }

    const handleKeyDown = (event:React.KeyboardEvent) => {
        if(event.key === "Enter") {
            if(!(errorMessage !== "" || newValue === "")) {
                doAdd();
            }
        }
    }

    const open = Boolean(anchorEl);
    return <>
        {!props.link && props.icon && <IconButton onClick={handleOnClickAdd}><OutlinedIcon>{props.icon}</OutlinedIcon></IconButton>}
        {props.link && <LinkButton id={props.id} data-action={props.action} variant={"contained"} onClick={handleOnClickAdd}><OutlinedIcon>{props.link.icon}</OutlinedIcon>{props.link.label}</LinkButton>}
        <Popover
            tabIndex={-1}
            open={open}
            anchorEl={anchorEl}
            onClose={handleOnClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center"
            }}
            style={{pointerEvents: "none"}}
        >
            <Box sx={{boxShadow: 3, padding: 2, maxWidth: "250px"}} style={{pointerEvents: "auto"}}>
                <Grid container spacing={2} justifyContent={"right"} onClick={handleClickOnGrid}>
                    <Grid item xs={12}>
                        <TextField
                            inputRef={textInput}
                            defaultValue=""
                            variant="outlined"
                            label={props.title}
                            size="small"
                            autoFocus={true}
                            onBlur={handleOnBlur}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            error={errorMessage !== ""}
                            helperText={errorMessage}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            disabled={errorMessage !== "" || newValue === ""}
                            variant={"text"} size={"small"}
                            onClick={handleClickAdd}>Add</Button>
                    </Grid>
                    <Grid item xs={1}/>
                </Grid>
            </Box>
        </Popover>
    </>

}