import {
    Box,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    Popover,
    Tooltip
} from "@mui/material";
import {useState} from "react";
import OutlinedIcon from "../icon/OutlinedIcon";
import StorefrontCmd from "../../model/jcr/StorefrontCmd";
import TopIconButton from "../button/TopIconButton";
import {TooltipPlacement} from "../../util/ThemeUtil";


let globalTimeout:NodeJS.Timeout|null = null;
export interface StorefrontPopoverProps {
    tooltipPlacement: TooltipPlacement;
    tooltipTitle: string
    storefronts?: any[];
    onChangeStorefront?: (storefront:StorefrontCmd) => void,
}
export default function StorefrontButton(props: StorefrontPopoverProps) {

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [selectedStorefront, setSelectedStorefront] = useState<StorefrontCmd|null>(null);


    const handleOnClickStorefront = (event: React.MouseEvent<HTMLButtonElement>) => {
        if(props.storefronts && props.storefronts.length > 0) {
            setAnchorEl(event.currentTarget);
        }
        event.stopPropagation();
        event.preventDefault();
    };


    const handleClickOnListItem = (storefront: StorefrontCmd) => {

        if(globalTimeout !== null) {
            clearTimeout(globalTimeout);
            globalTimeout = null;
        }
        let newStorefront = {...storefront};
        setSelectedStorefront(newStorefront);
        if(props.onChangeStorefront) {
            props.onChangeStorefront(newStorefront);
        }
    }

    const handleBlur = (event: React.FocusEvent) => {
        setAnchorEl(null)
        event.stopPropagation();
        event.preventDefault();
    }

    const open = Boolean(anchorEl);
    return <>
        <TopIconButton tooltipTitle={props.tooltipTitle} tooltipPlacement={props.tooltipPlacement}
                       onClick={props.storefronts ? handleOnClickStorefront : undefined}
                       style={{pointerEvents: open ? "none" : "auto"}}>
            <OutlinedIcon>storefront</OutlinedIcon>
        </TopIconButton>
        <Popover
            tabIndex={-1}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center"
            }}
            style={{pointerEvents: "none"}}
        >
            <Box sx={{boxShadow: 3, padding: 0, maxWidth: "250px"}} style={{pointerEvents: "auto" , padding: "0px"}}>
                <List dense sx={{maxHeight: "250px",
                    overflowY: "auto"}}>
                    {
                        props.storefronts && props.storefronts.map((storefront,index) => {
                                let selected = false;
                                if(selectedStorefront === null && index === 0) {
                                    selected = true;
                                } else {
                                    if(selectedStorefront !== null) {
                                        selected = storefront.id === selectedStorefront.id;
                                    }
                                }
                                return <ListItem autoFocus={index === 0}
                                          disablePadding
                                          tabIndex={-1}
                                                 sx={{padding: "4px"}}
                                          onBlur={handleBlur}
                                          key={"current_storefront_" + index}
                                          value={storefront.id}
                                          onClick={() => handleClickOnListItem(storefront)}
                                >
                                    <ListItemButton selected={selected} sx={ selected ? { color: "var(--standard-jade-600)" , fontWeight: "bold", borderRadius: "4px"} : {borderRadius: "4px" }}>{storefront.sfName}</ListItemButton>
                                </ListItem>
                            }
                        )
                    }
                </List>
            </Box>
        </Popover>
    </>

}
