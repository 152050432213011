import hljs from "highlight.js";
import 'highlight.js/styles/stackoverflow-light.css';
import StyledEditor from "./StyledEditor";
import graphQLHighlighter from "../../highlightjs/graphql";

hljs.registerLanguage('graphql', graphQLHighlighter);

export interface GraphQLEditorProps {
    title: string,
    value?: string,
    defaultValue?: string,
    className?: string,
    inputProps?: any,
    rows: number,
    onChange?: React.ChangeEventHandler<HTMLTextAreaElement>
}

export default function StyledGraphQLEditor(props: GraphQLEditorProps) {
    return <StyledEditor {...props} types={["graphql"]}/>
}