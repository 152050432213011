import styles from "./LinkButton.module.scss";
import withStyles from '@mui/styles/withStyles';
import {Button} from "@mui/material";


const ButtonCustom  =  withStyles({
    root: {
        border: "1px solid var(--standard-coal-300)",
        borderRadius: "6px",
        color: "var(--standard-jade-500)",
        backgroundColor: "white",
        textTransform: "none",
        fontSize: "14px",
        height: "33px",
        padding: "4px 20px",
        boxShadow: "unset",
        "&:hover": {
            transition: "background-color .3s,border-color .3s,box-shadow .3s,color .3s else-in",
            backgroundColor: "var(--standard-jade-500)",
            borderColor: "var(--standard-jade-500)",
            color: "var(--standard-white)",
            boxShadow: "0px 1px 3px #0000003D",
            textTransform: "none",
        },
        "&:disabled": {
            color: "gray",
            borderColor: "transparent"
        }
    },
    label: {

    }
})(Button);


export default function ReverseSolidLinkButton(props) {
    return <ButtonCustom {...props} className={props.className? props.className + " " + styles.Root : styles.Root}>{props.children}</ButtonCustom>
}