import React, {useCallback, useEffect, useRef, useState} from 'react';
import styles from "./Storefronts.module.scss";
import commonStyle from "../../config/Common.module.scss";
import StyledTable from "../table/StyledTable";
import {StorefrontDataFetcher} from "../../datafetcher/StorefrontDataFetcher";
import {useHistory} from "react-router";
import {useParams} from "react-router-dom";
import {InputModel, InputOption} from "../../model/InputModel";
import HttpClient from "../../datafetcher/HttpClient";
import {nanoid} from "nanoid";
import InputDialog from "../dialog/InputDialog";
import {useOrganization} from "../../hook/useOrganization";
import {lsi} from "../../datafetcher/LSIDataFetcher";
import {LiteSearchProfileCmd} from "../../model/lsi/LiteSearchProfileCmd";
import OutlinedIcon from "../icon/OutlinedIcon";
import IconLinkButton from "../button/IconLinkButton";
import ReverseSolidLinkSmallButton from '../button/ReverseSolidLinkSmallButton';
import PageSummary from '../pagesummary/PageSummary';
import {jcr} from "../../datafetcher/JCRDataFetcher";
import {ProxyOrgCmd, ProxyStorefrontCmd} from "../../model/proxy/cloudlsi-app";
import {CurrentISODate} from "../../util/DateUtil";

export default function Storefronts() {

    const history = useHistory();
    const query = new URLSearchParams(history.location.search);
    const params: any = useParams();
    const [newOpen, setNewOpen] = useState(false);
    const [updateData, setUpdateData] = useState<any>({});
    const [storefrontId, setStorefrontId] = useState("");
    const [formKey , setFormKey] = useState("");
    const [dataFetcher, setDataFetcher] = useState<StorefrontDataFetcher|null>(null);
    const [deleteList,setDeleteList] = useState<string[]>([]);
    const [footers, setFooters] = useState<InputOption[]>();
    const [headers, setHeaders] = useState<InputOption[]>();
    const [googleTagOutputOptions] = useState<InputOption[]>(
                [
                    {value: "", label: "None"},
                    {value: "UA", label: "Universal Analytics (UA)"},
                    {value: "GA4", label: "Google Analytics 4"},
                    {value: "UA_GA4", label: "Universal Analytics + Google Analytics 4"},
                ])
    const [searchProfiles,setSearchProfiles] = useState<InputOption[]>();
    const [portalStorefronts,setPortalStorefronts] = useState<InputOption[]>();
    const [proxyOrg,setProxyOrg] = useState<ProxyOrgCmd>();
    const [themes, setThemes] = useState<InputOption[]>();
    const  orgCmdProp = useOrganization();
    const inputFile = useRef<HTMLInputElement>(null);

    const handleCloseNewDialog = () => {
        setNewOpen(false);
    }

    const handleOpenNewDialog = () => {
        setNewOpen(true);
    }

    const handleUploadDialog = () => {
        if(inputFile !== null && inputFile.current !== null) {
            inputFile.current.click();
        }
    }

    const doUploadFile = () => {
        if(inputFile && inputFile.current) {
            if(inputFile.current.files) {
                jcr.storefront.upload(inputFile.current.files).then((response) => {
                    if(!(orgCmdProp && orgCmdProp.currentOrg !== null && orgCmdProp.orgDetail !== null)) return;
                    setDataFetcher(new StorefrontDataFetcher(orgCmdProp.currentOrg));
                    setFormKey(nanoid());
                })
            }
        }
    }

    const handleChangeDialog = (id: string , index: number, newValues: InputModel[]) => {
        if(newValues) {
            let changeValue = newValues[index];
            if(changeValue && changeValue.id === "sfPortalId") {
                if(changeValue.defaultValue !== "") {
                    let currentInput = portalStorefronts?.find(sf => sf.object? sf.object.portalId === changeValue.defaultValue : false);
                    if(currentInput) {
                        let currentSf: ProxyStorefrontCmd = currentInput.object as ProxyStorefrontCmd;
                        let value = newValues.find(value => value.id === 'sfURL');
                        if(value) value.defaultValue = currentSf.domain;
                        value = newValues.find(value => value.id === 'slfSprestURL');
                        if(value) value.defaultValue = currentSf.erpConfig.url;
                        value = newValues.find(value => value.id === 'sfSprestAPI');
                        if(value) value.defaultValue = currentSf.erpConfig.apiKey;
                        value = newValues.find(value => value.id === 'sfSiteUser');
                        if(value) value.defaultValue = currentSf.erpConfig.siteUser;
                        value = newValues.find(value => value.id === 'sfSitePassword');
                        if(value) value.defaultValue = currentSf.erpConfig.sitePassword;
                        value = newValues.find(value => value.id === 'sfAnonymousUser');
                        if(value) value.defaultValue = currentSf.erpConfig.anonymousUser;
                        value = newValues.find(value => value.id === 'sfAnonymousPassword');
                        if(value) value.defaultValue = currentSf.erpConfig.anonymousPassword;
                        value = newValues.find(value => value.id === 'sfLsiURL');
                        if(value) value.defaultValue = currentSf.lsiConfig.searchURL;
                        value = newValues.find(value => value.id === 'sfLsiAPIKey');
                        if(value) value.defaultValue = currentSf.lsiConfig.apiKey;
                        value = newValues.find(value => value.id === 'sfMemberCore');
                        if(value) value.defaultValue = currentSf.lsiConfig.memberCore;
                        value = newValues.find(value => value.id === 'sfConnectorName');
                        if(value) value.defaultValue = currentSf.lsiConfig.connectorName;
                        value = newValues.find(value => value.id === 'sfConnectorType');
                        if(value) value.defaultValue = currentSf.lsiConfig.connectorType;
                    }
                }
            }
        }

    }

    const handleCloseUpdateDialog = () => {
        setUpdateData({});
        setStorefrontId("");
        history.push({
            pathname: '/sites',
            search: history.location.search});
    }

    const handleUpdateDialog = (values: InputModel[]) => {
        let data: any = updateData;
        values.forEach(object => {
            data[object.id] = object.defaultValue;
        })
        data['createdAt'] = updateData.createdAt;
        data['modifiedAt'] = new Date().getTime();

        let portalId = values.find(value => value.id === 'sfPortalId');
        if(portalId !== undefined) {
            let id = portalId.defaultValue;
            let currentInput = portalStorefronts?.find(sf => sf.object ? sf.object.portalId === id : false);

            if (currentInput) {
                let currentProxySf: ProxyStorefrontCmd = currentInput.object as ProxyStorefrontCmd;

                currentProxySf.erpConfig.apiKey = data.sfSprestAPI
                currentProxySf.erpConfig.sitePassword = data.sfSitePassword
                currentProxySf.erpConfig.siteUser = data.sfSiteUser
                currentProxySf.erpConfig.anonymousPassword = data.sfAnonymousPassword
                currentProxySf.erpConfig.anonymousUser = data.sfAnonymousUser
                currentProxySf.erpConfig.url = data.sfSprestURL

                currentProxySf.lsiConfig.apiKey = data.sfLsiAPIKey
                currentProxySf.lsiConfig.searchURL = data.sfLsiURL
                currentProxySf.lsiConfig.connectorType = data.sfConnectorType
                currentProxySf.lsiConfig.connectorName = data.sfConnectorName
                currentProxySf.lsiConfig.searchProfile = data.sfSearchProfile
                currentProxySf.lsiConfig.memberCore = data.sfMemberCore

                currentProxySf.domain = data.sfURL

                lsi.proxy.storefront.post(currentProxySf);
            }
        }

        HttpClient.post("/api/v0/jcr/org/" + orgCmdProp?.currentOrg!.orgCode + "/full/storefronts", data).then((response) => {
            dataFetcher!.load().then((xxxx) => {
                //setNumberOfRecords(dataFetcher.getTotalRecords);
                resetScreen();

            });
        }).catch((e) => {

        })




        // lsi.proxy.storefront.post().then(() => {
        //
        // });

    }

    const handleNewDialog = (values: InputModel[]) => {
        let data: any = {};
        values.forEach(object => {
            data[object.id] = object.defaultValue;
        })
        data['createdAt'] = new Date().getTime();
        data['modifiedAt'] = new Date().getTime();
        HttpClient.post("/api/v0/jcr/org/" + orgCmdProp?.currentOrg!.orgCode + "/full/storefronts", data).then((response) => {
            resetScreen();

            history.push({
                pathname: history.location.pathname + "/" + response.data.response.id ,
                search: history.location.search
            })
        }).catch((e) => {

        })
        setNewOpen(false);
    }

    const handleDelete = () => {

        if(window.confirm("Are you sure to remove?")) {
            HttpClient.delete("/api/v0/jcr/org/" + orgCmdProp?.currentOrg!.orgCode + "/storefront/" + deleteList.join(",")).then((response) => {
                resetScreen();
                setDeleteList([]);
            }).catch((e) => {
                setDeleteList([]);
            })
        }
    }

    const handleDownloadList = () => {
        HttpClient.download(`Sites-${CurrentISODate()}.gz`,
            "/api/v0/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/download/storefronts/" + deleteList.join(","));
        setDeleteList([]);
        setFormKey(nanoid());
    }

    const handleAction = (tag:string, tagObject: any) => {
        if(tag === "DUPLICATE") {
            HttpClient.get( "/api/v0/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/clone/storefronts/" +  tagObject.id)
                .then((response) => {
                    let id = response.data.response;
                    history.push({
                        pathname: `${history.location.pathname}/${id}`,
                        search: history.location.search
                    })
                    if(!(orgCmdProp && orgCmdProp.currentOrg !== null && orgCmdProp.orgDetail !== null)) return;
                    setDataFetcher(new StorefrontDataFetcher(orgCmdProp.currentOrg));
                    setFormKey(nanoid());
                })
        } else if(tag === "DOWNLOAD") {
            HttpClient.download(
                `Site-${tagObject.sfName}-${tagObject.id}.gz`,
                 "/api/v0/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/download/storefronts/" +  tagObject.id);
        }
    }

    const getDefault = (currentValue: string | null | undefined, defaultValue: any) => {

        if(((currentValue === "" || currentValue === undefined || currentValue === defaultValue) && defaultValue)) { // will remove value if the value are match with the default
            return "";
        } else {
            return currentValue;
        }
    }


    if (params.evid && storefrontId !== params.evid) {
        setTimeout(() => {
            setStorefrontId(params.evid);
        }, 100);
    }

    const resetScreen = useCallback(() => {
        history.replace({
            pathname: '/sites',
            search: history.location.search
        });
        setStorefrontId("");
        setUpdateData({});
        setFormKey(nanoid());
    },[history])

    useEffect(() => {
        if(storefrontId !== "" &&  orgCmdProp && orgCmdProp.currentOrg !== null) {
            HttpClient.get("/api/v0/jcr/org/" + orgCmdProp?.currentOrg!.orgCode + "/storefront/" + storefrontId).then((response) => {
                if(response.data.response !== null) {
                    setUpdateData(response.data.response);
                } else {
                    resetScreen();
                }
            }).catch((e) => {
                resetScreen();
            })
        }

    },[storefrontId,orgCmdProp,resetScreen])


    const handleSyncFromPortal = () => {
        HttpClient.get("/api/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/load/storefronts").then((response) => {
            resetScreen();
        }).catch((e) => {
            resetScreen();
        })
    }

    useEffect(() => {
        if(!(orgCmdProp && orgCmdProp.currentOrg !== null && orgCmdProp.orgDetail !== null)) return;


        //HttpClient.get("/api/v0/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/lite/headers")
        jcr.header.lite().then((response) => {
            if(response.data) {
                let newHeaderOptions: InputOption[] = [];
                newHeaderOptions.push({value: "" , label: "None"});
                response.data.response.forEach((object:any) => {
                    newHeaderOptions.push({value: object.id , label: object.headerName});
                })
                setHeaders(newHeaderOptions);
            }
        });
        //HttpClient.get("/api/v0/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/lite/footers")
        jcr.footer.lite().then((response) => {
            if(response.data) {
                let newFooterOptions: InputOption[] = [];
                newFooterOptions.push({value: "" , label: "None"});
                response.data.response.forEach((object:any) => {
                    newFooterOptions.push({value: object.id , label: object.footerName});
                })
                setFooters(newFooterOptions);
            }
        })
        //HttpClient.get("/api/v0/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/lite/themes")
        jcr.theme.lite().then((response) => {
            if(response.data) {
                let newThemeOptions: InputOption[] = [];
                newThemeOptions.push({value: "" , label: "None"});
                response.data.response.forEach((object:any) => {
                    newThemeOptions.push({value: object.id , label: object.themeName});
                })
                setThemes(newThemeOptions);
            }
        })

        if(lsi.memberCore !== "" && lsi.memberCore !== null) {
            lsi.search.searchProfiles().then((response) => {
                if (response.data) {
                    let data: LiteSearchProfileCmd[] = response.data;
                    let newSearchProfileOptions: InputOption[] = [];
                    newSearchProfileOptions.push({value: null, label: "Global"});
                    newSearchProfileOptions.push({value: "", label: "None"});
                    data.forEach((object: any) => {
                        newSearchProfileOptions.push({value: object.name, label: object.name});
                    })
                    setSearchProfiles(newSearchProfileOptions);
                }
            })
        } else {
            setSearchProfiles([]);
        }

        lsi.proxy.storefront.get().then(response => {
            if(response.data) {
                let data:ProxyStorefrontCmd[] = response.data;
                let newPortalStorefront: InputOption[] = [];
                newPortalStorefront.push({value: "" , label: "None"});
                data.forEach((object:any) => {
                    newPortalStorefront.push({value: object.portalId , label: object.name, object: object});
                })
                setPortalStorefronts(newPortalStorefront);
            }
        })

        lsi.proxy.organization.get().then(response => {
            if(response.data) {
                let data:ProxyOrgCmd = response.data;
                setProxyOrg(data);
            }
            if(!(orgCmdProp && orgCmdProp.currentOrg !== null && orgCmdProp.orgDetail !== null)) return;

        })

        setDataFetcher(new StorefrontDataFetcher(orgCmdProp.currentOrg));
        setFormKey(nanoid());


    }, [orgCmdProp]);

    const newButton = <IconLinkButton tooltip={"Add New Site"} key="newStorefrontButton" id="newStorefrontButton" data-action="createNewStorefront" variant={"contained"} onClick={handleOpenNewDialog}>
            <OutlinedIcon className={styles.Icon} >add_circle_outline</OutlinedIcon>
        </IconLinkButton>

    const syncButton = <IconLinkButton tooltip={"Load from portal"} key="newSyncButton" id="newSyncButton" data-action="newSyncButton" variant={"contained"} onClick={handleSyncFromPortal}>
        <OutlinedIcon className={styles.Icon} >sync</OutlinedIcon>
    </IconLinkButton>

    const uploadButton = <IconLinkButton tooltip={"Upload File"} key="uploadStorefrontButton" id="uploadStorefrontButton" data-action="uploadNewStorefront" variant={"contained"} onClick={handleUploadDialog}>
        <OutlinedIcon className={styles.Icon} >cloud_upload</OutlinedIcon>
    </IconLinkButton>;

    const deleteButton = <ReverseSolidLinkSmallButton id="deleteStorefrontButton" data-action="deleteStorefronts" variant={"contained"} onClick={handleDelete}>
        <OutlinedIcon className={styles.Icon} >delete</OutlinedIcon>
        Remove ({deleteList.length})
    </ReverseSolidLinkSmallButton>

    const downloadButton = <ReverseSolidLinkSmallButton id="downloadStorefrontButton" data-action="downloadStorefronts" variant={"contained"} onClick={handleDownloadList}>
        <OutlinedIcon className={styles.Icon} >download</OutlinedIcon>Download ({deleteList.length})
    </ReverseSolidLinkSmallButton>;

    const booleanOptions: InputOption[] = [
        { label: "true", value: true},
        { label: "false", value: false},
    ]

    const unitDigitTypes: InputOption[] = [
        { label: "2", value: 2},
        { label: "3", value: 3},
        { label: "4", value: 4},
        { label: "5", value: 5},
    ]

    const availabilitySortTypes: InputOption[] = [
        {label: "Total", value: "Total"},
        {label: "Branch", value: "Branch"},
    ]

    const availabilityQtyTypes: InputOption[] = [
        {label: "Total", value: "Total"},
        {label: "Branch", value: "Branch"},
        {label: "Both", value: "Both"},
    ]

    const defaultSortTypes: InputOption[] = [
        {label: "Closest Match", value: "Closest Match"},
        {label: "Description", value: "Description"},
        {label: "Price", value: "Price"},
        {label: "Most Popular", value: "Most Popular"},
        {label: "Availability", value: "Availability"},
    ]

    return <React.Fragment>
        <div className={commonStyle.ContentContainer + " " + commonStyle.ContentContainerOverflowX}>
            <PageSummary type={"sites"}/>
            {   proxyOrg !== undefined && newOpen &&
                <InputDialog
                    id={"storefront"}
                    title={"New Site"}
                    handleClose={handleCloseNewDialog}
                    handleSave={handleNewDialog}
                    open={newOpen}
                    inserted={true}
                    fields={[
                        {id: "sfName", label: "Name", defaultValue: "", required: true , editable: true},
                        {id: "sfURL", label: "URL", defaultValue: "", required: true , editable: true},
                        {id: "sfThemeId", type: "select", label: "Theme", defaultValue: "", required: false , editable: true, options: themes},
                        {id: "sfHeaderId", type: "select", label: "Header", defaultValue: "", required: false , editable: true, options: headers},
                        {id: "sfFooterId", type: "select", label: "Footer", defaultValue: "", required: false , editable: true, options: footers},
                    ]}
                    contentText={null}/>
            }
            {   proxyOrg !== undefined &&
                storefrontId !== "" && updateData.id  &&
                portalStorefronts !== undefined &&
                searchProfiles && themes && headers && footers &&
                <InputDialog
                    id={"storefront"}
                    title={"Update Site"}
                    handleClose={handleCloseUpdateDialog}
                    handleSave={handleUpdateDialog}
                    handleChange={handleChangeDialog}
                    open={true}
                    inserted={false}
                    fields={[
                        {id: "sfName", group:"info", label: "Name", defaultValue: updateData.sfName, required: true , editable: true},
                        {id: "sfURL", group:"info", label: "Domain Name", defaultValue: newOpen ? "" : updateData.sfURL, required: false , editable: true},
                        {id: "sfPortalId", group: "info", label: "Portal Storefront", type:"select",options: portalStorefronts, defaultValue: updateData.sfPortalId, required: false, editable: true},
                        {id: "sfCdnURL", group:"info", label: "CDN URL", defaultValue: updateData.sfCdnURL, required: false , editable: true},

                        {id: "slfSprestURL", group:"sprest", label: "SPRest URL", defaultValue: newOpen ? "" : getDefault(updateData.sfSprestURL,proxyOrg.erpConfig.url), nullable: true, globalValue: proxyOrg.erpConfig.url, required: false , editable: true},
                        {id: "sfSprestAPI", group:"sprest", label: "SPRest API Key", defaultValue: newOpen ? "" : getDefault(updateData.sfSprestAPI,proxyOrg.erpConfig.apiKey), nullable: true, globalValue: proxyOrg.erpConfig.apiKey, required: false , editable: true},
                        {id: "sfSiteUser", group:"sprest", label: "Site User", defaultValue: newOpen ? "" : getDefault(updateData.sfSiteUser,proxyOrg.erpConfig.siteUser), nullable: true, globalValue: proxyOrg.erpConfig.siteUser, required: false , editable: true},
                        {id: "sfSitePassword",group:"sprest", type: "password",  label: "Site Password", defaultValue: newOpen ? "" : getDefault(updateData.sfSitePassword,proxyOrg.erpConfig.sitePassword), nullable: true, globalValue: proxyOrg.erpConfig.sitePassword, required: false , editable: true},
                        {id: "sfAnonymousUser", group:"sprest", label: "Anonymous User", defaultValue: newOpen ? "" : getDefault(updateData.sfAnonymousUser,proxyOrg.erpConfig.anonymousUser), nullable: true, globalValue: proxyOrg.erpConfig.anonymousUser, required: false , editable: true},
                        {id: "sfAnonymousPassword", group:"sprest",type: "password", label: "Anonymous Password", defaultValue: newOpen ? "" : getDefault(updateData.sfAnonymousPassword,proxyOrg.erpConfig.anonymousPassword), nullable: true, globalValue: proxyOrg.erpConfig.anonymousPassword, required: false , editable: true},

                        {id: "sfLsiURL", group:"lsi", label: "Lsi URL", defaultValue: newOpen ? "" : getDefault(updateData.sfLsiURL,proxyOrg?.lsiConfig.searchURL), nullable: true, globalValue: proxyOrg?.lsiConfig.searchURL , required: false , editable: true},
                        {id: "sfLsiAPIKey", group:"lsi", label: "Lsi API Key", defaultValue: newOpen ? "" : getDefault(updateData.sfLsiAPIKey,proxyOrg?.lsiConfig.apiKey), nullable: true, globalValue: proxyOrg?.lsiConfig.apiKey ,  required: false , editable: true},
                        {id: "sfMemberCore", group:"lsi", label: "Member Core", defaultValue: newOpen ? "" : getDefault(updateData.sfMemberCore,proxyOrg?.lsiConfig.memberCore), nullable: true, globalValue: proxyOrg?.lsiConfig.memberCore ,  required: false , editable: true},
                        {id: "sfConnectorType", group:"lsi", label: "Connector Type", defaultValue: newOpen ? "" : getDefault(updateData.sfConnectorType,proxyOrg?.lsiConfig.connectorType), nullable: true, globalValue: proxyOrg?.lsiConfig.connectorType ,  required: false , editable: true},
                        {id: "sfConnectorName", group:"lsi", label: "Connector Name", defaultValue: newOpen ? "" : getDefault(updateData.sfConnectorName,proxyOrg?.lsiConfig.connectorName), nullable: true, globalValue: proxyOrg?.lsiConfig.connectorName ,  required: false , editable: true},
                        {id: "sfSearchProfile", group:"lsi", type: "select", label: "Search Profile", defaultValue: getDefault(updateData.sfSearchProfile,proxyOrg?.lsiConfig.searchProfile), nullable: true, globalValue: proxyOrg?.lsiConfig.searchProfile ,  required: false , editable: true, options: searchProfiles},

                        {id: "sfPortalURL", group:"portal", label: "Server URL", defaultValue: updateData.sfPortalURL, nullable: true, globalValue: proxyOrg.portalURL, required: false , editable: true},
                        {id: "sfPortalSiteUser", group:"portal", label: "Site User", defaultValue: updateData.sfPortalSiteUser, nullable: true, globalValue: proxyOrg.portalUser,required: false , editable: true},
                        {id: "sfPortalSitePassword", group:"portal",type: "password", label: "Site Password", defaultValue: updateData.sfPortalSitePassword, required: false , editable: true},

                        {id: "sfThemeId", group:"info", type: "select", label: "Theme", defaultValue: updateData.sfThemeId, required: false , editable: true, options: themes},
                        {id: "sfHeaderId", group:"info", type: "select", label: "Header", defaultValue: updateData.sfHeaderId, required: false , editable: true, options: headers},
                        {id: "sfFooterId", group:"info", type: "select", label: "Footer", defaultValue: updateData.sfFooterId, required: false , editable: true, options: footers},
                        {id: "googleTagId", group:"info", label: "Google Tag", defaultValue: updateData.googleTagId, required: false , editable: true},
                        {id: "googleTagOutput", group:"info", label: "Google Tag Output", type: "select", defaultValue: updateData.googleTagOutput, required: false , editable: true, options: googleTagOutputOptions},
                        
                        // products
                        {id: "sfPpa.mainBranchId", group:"ppa",label: "Main Branch ID", defaultValue: newOpen ? "" : getDefault(updateData.sfPpa.mainBranchId,proxyOrg.ppaConfig.mainBranchId), nullable: true, globalValue: proxyOrg.ppaConfig.mainBranchId, required: false , editable: true},
                        {id: "sfPpa.excludeProductsByStockStatus", group:"ppa",label: "Exclude Status", defaultValue: newOpen ? "" : getDefault(updateData.sfPpa.excludeProductsByStockStatus,proxyOrg.ppaConfig.excludeProductsByStockStatus), nullable: true, globalValue: proxyOrg.ppaConfig.excludeProductsByStockStatus, required: false , editable: true},
                        {id: "sfPpa.excludeAvailabilityFromBranch", group:"ppa",label: "Exclude Availability", defaultValue: newOpen ? "" : getDefault(updateData.sfPpa.excludeAvailabilityFromBranch,proxyOrg.ppaConfig.excludeAvailabilityFromBranch), nullable: true, globalValue: proxyOrg.ppaConfig.excludeAvailabilityFromBranch, required: false , editable: true},
                        {id: "sfPpa.includeProductsByKeywords", group:"ppa",label: "Include Keywords", defaultValue: newOpen ? "" : getDefault(updateData.sfPpa.includeProductsByKeywords,proxyOrg.ppaConfig.includeProductsByKeywords), nullable: true, globalValue: proxyOrg.ppaConfig.includeProductsByKeywords, required: false , editable: true},
                        {id: "sfPpa.excludeProductsByKeywords", group:"ppa",label: "Exclude Keywords", defaultValue: newOpen ? "" : getDefault(updateData.sfPpa.excludeProductsByKeywords,proxyOrg.ppaConfig.excludeProductsByKeywords), nullable: true, globalValue: proxyOrg.ppaConfig.excludeProductsByKeywords, required: false , editable: true},
                        {id: "sfPpa.includeProductsByBranchIds", group:"ppa",label: "Include Branch IDs", defaultValue: newOpen ? "" : getDefault(updateData.sfPpa.includeProductsByBranchIds,proxyOrg.ppaConfig.includeProductsByBranchIds), nullable: true, globalValue: proxyOrg.ppaConfig.includeProductsByBranchIds, required: false , editable: true},
                        {id: "sfPpa.excludeProductsByBranchIds", group:"ppa",label: "Exclude Branch IDs", defaultValue: newOpen ? "" : getDefault(updateData.sfPpa.excludeProductsByBranchIds,proxyOrg.ppaConfig.excludeProductsByBranchIds), nullable: true, globalValue: proxyOrg.ppaConfig.excludeProductsByBranchIds, required: false , editable: true},
                        {id: "sfPpa.includeProductsByPriceLines", group:"ppa",label: "Include Price Lines", defaultValue: newOpen ? "" : getDefault(updateData.sfPpa.includeProductsByPriceLines,proxyOrg.ppaConfig.includeProductsByPriceLines), nullable: true, globalValue: proxyOrg.ppaConfig.includeProductsByPriceLines, required: false , editable: true},
                        {id: "sfPpa.excludeProductsByPriceLines", group:"ppa",label: "Exclude Price Lines", defaultValue: newOpen ? "" : getDefault(updateData.sfPpa.excludeProductsByPriceLines,proxyOrg.ppaConfig.excludeProductsByPriceLines), nullable: true, globalValue: proxyOrg.ppaConfig.excludeProductsByPriceLines, required: false , editable: true},
                        {id: "sfPpa.includeProductsByBuyLines", group:"ppa",label: "Include Buy Lines", defaultValue: newOpen ? "" : getDefault(updateData.sfPpa.includeProductsByBuyLines,proxyOrg.ppaConfig.includeProductsByBuyLines), nullable: true, globalValue: proxyOrg.ppaConfig.includeProductsByBuyLines, required: false , editable: true},
                        {id: "sfPpa.excludeProductsByBuyLines", group:"ppa",label: "Hide Buy Lines", defaultValue: newOpen ? "" : getDefault(updateData.sfPpa.excludeProductsByBuyLines,proxyOrg.ppaConfig.excludeProductsByBuyLines), nullable: true, globalValue: proxyOrg.ppaConfig.excludeProductsByBuyLines, required: false , editable: true},
                        {id: "sfPpa.includeProductByRecorderPriceLines", group:"ppa",label: "Include Reorder Price Lines", defaultValue: newOpen ? "" :getDefault( updateData.sfPpa.includeProductByRecorderPriceLines,proxyOrg.ppaConfig.includeProductByRecorderPriceLines), nullable: true, globalValue: proxyOrg.ppaConfig.includeProductByRecorderPriceLines, required: false , editable: true},
                        {id: "sfPpa.excludeCatalogProducts", type:"select", options: booleanOptions, group:"ppa",label: "Exclude Catalog Products", defaultValue: newOpen ? false : getDefault(updateData.sfPpa.excludeCatalogProducts,proxyOrg.ppaConfig.excludeCatalogProducts), nullable: true, globalValue: proxyOrg.ppaConfig.excludeCatalogProducts, required: false , editable: true},
                        {id: "sfPpa.loadExtendData", type:"select", options: booleanOptions, group:"ppa",label: "Load Extended Data (SellPkgQty)", defaultValue: newOpen ? false : getDefault(updateData.sfPpa.loadExtendData,proxyOrg.ppaConfig.loadExtendData), nullable: true, globalValue: proxyOrg.ppaConfig.loadExtendData, required: false , editable: true},
                        {id: "sfPpa.includePWDData", type:"select", options: booleanOptions, group:"ppa",label: "Include PDW Data/Search", defaultValue: newOpen ? false : getDefault(updateData.sfPpa.includePWDData,proxyOrg.ppaConfig.includePWDData), nullable: true, globalValue: proxyOrg.ppaConfig.includePWDData, required: false , editable: true},
                        {id: "sfPpa.includeCategorySearch", type:"select", options: booleanOptions, group:"ppa",label: "Include Category Search", defaultValue: newOpen ? false : getDefault(updateData.sfPpa.includeCategorySearch,proxyOrg.ppaConfig.includeCategorySearch), nullable: true, globalValue: proxyOrg.ppaConfig.includeCategorySearch, required: false , editable: true},
                        {id: "sfPpa.defaultSortBy", type:"select", options: defaultSortTypes, group:"ppa",label: "Default SortBy", defaultValue: newOpen ? "" : getDefault(updateData.sfPpa.defaultSortBy,proxyOrg.ppaConfig.defaultSortBy), nullable: true, globalValue: proxyOrg.ppaConfig.defaultSortBy, required: false , editable: true},
                        {id: "sfPpa.useAlternateProductDescription", type:"select", options: booleanOptions, group:"ppa",label: "Use Alternate Product Description", defaultValue: newOpen ? false : getDefault(updateData.sfPpa.useAlternateProductDescription,proxyOrg.ppaConfig.useAlternateProductDescription), nullable: true, globalValue: proxyOrg.ppaConfig.useAlternateProductDescription, required: false , editable: true},
                        {id: "sfPpa.useShipToCustomerPartNumber", type:"select", options: booleanOptions, group:"ppa",label: "Use ShipTo Customer Part Numbers", defaultValue: newOpen ? false : getDefault(updateData.sfPpa.useShipToCustomerPartNumber,proxyOrg.ppaConfig.useShipToCustomerPartNumber), nullable: true, globalValue: proxyOrg.ppaConfig.useShipToCustomerPartNumber, required: false , editable: true},
                        {id: "sfPpa.lsiDrillDownDisplay", type:"select", options: booleanOptions, group:"ppa",label: "LSI Drilldown Display", defaultValue: newOpen ? false : getDefault(updateData.sfPpa.lsiDrillDownDisplay,proxyOrg.ppaConfig.lsiDrillDownDisplay), nullable: true, globalValue: proxyOrg.ppaConfig.lsiDrillDownDisplay, required: false , editable: true},
                        {id: "sfPpa.lsiMultiSelectFacet", type:"select", options: booleanOptions, group:"ppa",label: "LSI Multi-Select Facets", defaultValue: newOpen ? false : getDefault(updateData.sfPpa.lsiMultiSelectFacet,proxyOrg.ppaConfig.lsiMultiSelectFacet), nullable: true, globalValue: proxyOrg.ppaConfig.lsiMultiSelectFacet, required: false , editable: true},
                        {id: "sfPpa.displayCategoryProductCount", type:"select", options: booleanOptions, group:"ppa",label: "Display Category Product Count", defaultValue: newOpen ? false : getDefault(updateData.sfPpa.displayCategoryProductCount,proxyOrg.ppaConfig.displayCategoryProductCount), nullable: true, globalValue: proxyOrg.ppaConfig.displayCategoryProductCount, required: false , editable: true},
                        {id: "sfPpa.displayCategorySEO", type:"select", options: booleanOptions, group:"ppa",label: "Display Category SEO Text", defaultValue: newOpen ? false : getDefault(updateData.sfPpa.displayCategorySEO,proxyOrg.ppaConfig.displayCategorySEO), nullable: true, globalValue: proxyOrg.ppaConfig.displayCategorySEO, required: false , editable: true},
                        {id: "sfPpa.priorityERPPartNumberMatch", type:"select", options: booleanOptions, group:"ppa",label: "Import P/O - Prioritize ERP PartNumber Match", defaultValue: newOpen ? false : getDefault(updateData.sfPpa.priorityERPPartNumberMatch,proxyOrg.ppaConfig.priorityERPPartNumberMatch), nullable: true, globalValue: proxyOrg.ppaConfig.priorityERPPartNumberMatch, required: false , editable: true},

                        // pricing
                        {id: "sfPpa.displayListPrice", type:"select", options: booleanOptions, group:"ppa",label: "Display List Price", defaultValue: newOpen ? false : getDefault(updateData.sfPpa.displayListPrice,proxyOrg.ppaConfig.displayListPrice), nullable: true, globalValue: proxyOrg.ppaConfig.displayListPrice, required: false , editable: true},
                        {id: "sfPpa.unitPriceDisplayDigit", type:"select", options: unitDigitTypes, group:"ppa",label: "Unit Price Display Digits", defaultValue: newOpen ? false : getDefault(updateData.sfPpa.unitPriceDisplayDigit,proxyOrg.ppaConfig.unitPriceDisplayDigit), nullable: true, globalValue: proxyOrg.ppaConfig.unitPriceDisplayDigit, required: false , editable: true},
                        {id: "sfPpa.requireLoginForPricing", type:"select", options: booleanOptions, group:"ppa",label: "Require Login for PPricing", defaultValue: newOpen ? false : getDefault(updateData.sfPpa.requireLoginForPricing,proxyOrg.ppaConfig.requireLoginForPricing), nullable: true, globalValue: proxyOrg.ppaConfig.requireLoginForPricing, required: false , editable: true},
                        {id: "sfPpa.priceFromERP", type:"select", options: booleanOptions, group:"ppa",label: "Get Price from ERP", defaultValue: newOpen ? false : getDefault(updateData.sfPpa.priceFromERP,proxyOrg.ppaConfig.priceFromERP), nullable: true, globalValue: proxyOrg.ppaConfig.priceFromERP, required: false , editable: true},
                        {id: "sfPpa.allowSwitchBranch", type:"select",options: booleanOptions, group:"ppa",label: "Allow User Branch Selection", defaultValue: newOpen ? true : getDefault(updateData.sfPpa.allowSwitchBranch,proxyOrg.ppaConfig.allowSwitchBranch), nullable: true, globalValue: proxyOrg.ppaConfig.allowSwitchBranch, required: false , editable: true},
                        {id: "sfPpa.enabledMoneySaverAlert", type:"select",options: booleanOptions, group:"ppa",label: "Enable Money Saver Alerts", defaultValue: newOpen ? true : getDefault(updateData.sfPpa.enabledMoneySaverAlert,proxyOrg.ppaConfig.enabledMoneySaverAlert), nullable: true, globalValue: proxyOrg.ppaConfig.enabledMoneySaverAlert, required: false , editable: true},

                        // availability
                        {id: "sfPpa.availabilitySortType", type:"select", options: availabilitySortTypes, group:"ppa",label: "Availability Sort Type", defaultValue: newOpen ? "Total" : getDefault(updateData.sfPpa.availabilitySortType,proxyOrg.ppaConfig.availabilitySortType), nullable: true, globalValue: proxyOrg.ppaConfig.availabilitySortType, required: false , editable: true},
                        {id: "sfPpa.displayProductAvailability", type:"select", options: booleanOptions, group:"ppa",label: "Display Product Availability", defaultValue: newOpen ? true : getDefault(updateData.sfPpa.displayProductAvailability,proxyOrg.ppaConfig.displayProductAvailability), nullable: true, globalValue: proxyOrg.ppaConfig.displayProductAvailability, required: false , editable: true},
                        {id: "sfPpa.requireLoginForAvailability", type:"select",options: booleanOptions, group:"ppa",label: "Require Login For Availability", defaultValue: newOpen ? true : getDefault(updateData.sfPpa.requireLoginForAvailability,proxyOrg.ppaConfig.requireLoginForAvailability), nullable: true, globalValue: proxyOrg.ppaConfig.requireLoginForAvailability, required: false , editable: true},
                        {id: "sfPpa.displayAvailableQty", type:"select", options: booleanOptions, group:"ppa",label: "Display Available Qty", defaultValue: newOpen ? true : getDefault(updateData.sfPpa.displayAvailableQty,proxyOrg.ppaConfig.displayAvailableQty), nullable: true, globalValue: proxyOrg.ppaConfig.displayAvailableQty, required: false , editable: true},
                        {id: "sfPpa.availableQtyType", type:"select", options: availabilityQtyTypes, group:"ppa",label: "Available Qty Type", defaultValue: newOpen ? "Total" : getDefault(updateData.sfPpa.availableQtyType,proxyOrg.ppaConfig.availableQtyType), nullable: true, globalValue: proxyOrg.ppaConfig.availableQtyType, required: false , editable: true},
                        {id: "sfPpa.displayDeliveryBranch", type:"select", options: booleanOptions, group:"ppa",label: "Display Delivery Branch Availability", defaultValue: newOpen ? true : getDefault(updateData.sfPpa.displayDeliveryBranch,proxyOrg.ppaConfig.displayDeliveryBranch), nullable: true, globalValue: proxyOrg.ppaConfig.displayDeliveryBranch, required: false , editable: true},

                        {id: "sfPpa.includeBranchAvailability", type:"select",options: booleanOptions, group:"ppa",label: "Include Branch Availability Detail", defaultValue: newOpen ? true : getDefault(updateData.sfPpa.includeBranchAvailability,proxyOrg.ppaConfig.includeBranchAvailability), nullable: true, globalValue: proxyOrg.ppaConfig.includeBranchAvailability, required: false , editable: true},
                        {id: "sfPpa.displayAvailabilityStockDate", type:"select",options: booleanOptions, group:"ppa",label: "Display Availability Stock Date", defaultValue: newOpen ? true : getDefault(updateData.sfPpa.displayAvailabilityStockDate,proxyOrg.ppaConfig.displayAvailabilityStockDate), nullable: true, globalValue: proxyOrg.ppaConfig.displayAvailabilityStockDate, required: false , editable: true},
                        {id: "sfPpa.useHomeBranchStockDate", type:"select",options: booleanOptions, group:"ppa",label: "Use Home Branch Stock Date", defaultValue: newOpen ? true : getDefault(updateData.sfPpa.useHomeBranchStockDate,proxyOrg.ppaConfig.useHomeBranchStockDate), nullable: true, globalValue: proxyOrg.ppaConfig.useHomeBranchStockDate, required: false , editable: true},
                        {id: "sfPpa.useBOPlentyDateOnly", type:"select",options: booleanOptions, group:"ppa",label: "Use B/O Plenty Date Only", defaultValue: newOpen ? true : getDefault(updateData.sfPpa.useBOPlentyDateOnly,proxyOrg.ppaConfig.useBOPlentyDateOnly), nullable: true, globalValue: proxyOrg.ppaConfig.useBOPlentyDateOnly, required: false , editable: true},
                        {id: "sfPpa.displayBODayRange", type:"select",options: booleanOptions, group:"ppa",label: "Display Availability B/O Day Range", defaultValue: newOpen ? true : getDefault(updateData.sfPpa.displayBODayRange,proxyOrg.ppaConfig.displayBODayRange), nullable: true, globalValue: proxyOrg.ppaConfig.displayBODayRange, required: false , editable: true},
                        {id: "sfPpa.showProductImageStatusFlag", type:"select",options: booleanOptions, group:"ppa",label: "Show Product Image Status Flags", defaultValue: newOpen ? true : getDefault(updateData.sfPpa.showProductImageStatusFlag,proxyOrg.ppaConfig.showProductImageStatusFlag), nullable: true, globalValue: proxyOrg.ppaConfig.showProductImageStatusFlag, required: false , editable: true},

                        {id: "sfPpa.useCloudLSIForPricingAndAvailabilityForAnonymous", type:"select",options: booleanOptions, group:"ppa",label: "Show Pricing and Availability From Anonymous", defaultValue: newOpen ? true : getDefault(updateData.sfPpa.useCloudLSIForPricingAndAvailabilityForAnonymous,proxyOrg.ppaConfig.useCloudLSIForPricingAndAvailabilityForAnonymous), nullable: true, globalValue: proxyOrg.ppaConfig.useCloudLSIForPricingAndAvailabilityForAnonymous, required: false , editable: true},

                        {id: "sfPpa.backOrderText",  group:"ppa",label: "backOrderText", defaultValue: newOpen ? true :getDefault(updateData.sfPpa.backOrderText,proxyOrg.ppaConfig.backOrderText), nullable: true, globalValue: proxyOrg.ppaConfig.backOrderText, required: false , editable: true},
                        {id: "sfPpa.dropShipText", group:"ppa",label: "backOrderText", defaultValue: newOpen ? true : getDefault(updateData.sfPpa.dropShipText,proxyOrg.ppaConfig.dropShipText), nullable: true, globalValue: proxyOrg.ppaConfig.dropShipText, required: false , editable: true},
                    ]}
                    groups={[
                        {id: "info", name: "General"},
                        {id: "lsi", name: "LSI Server"},
                        {id: "sprest", name: "SPRest Server"},
                        {id: "portal", name: "Portal Server"},
                        {id: "ppa", name: "Products, Pricing, & Availability"},
                    ]}
                    contentText={null}/>
            }
            <StyledTable key={formKey}

                         handleAction={handleAction}
                         handleDeleteLists={(ids) => setDeleteList(ids)}
                         hasCheckBox={true}
                         dataFetcher={dataFetcher}

                         title={"Sites"}
                         actionComponents={[syncButton,uploadButton,newButton]}
                         deleteButton={deleteButton}
                         downloadButton={downloadButton}

                         rowsPerPage={20}
                         page={parseInt((query.get("page") === null ? "0" : query.get("page")!))}
                         rowsPerPageOptions={[5,10,20,40,60,{label: "All" , value: -1}]}/>
            <input key={`upload_${formKey}`} type='file' id='upload-content'
                   accept=".gzip,.gz"
                   onChange={() => doUploadFile()}
                   ref={inputFile} style={{display: 'none'}}/>
        </div>

    </React.Fragment>
}
