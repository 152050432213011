import {GridSize} from "@mui/material";
import {ToEndISODate} from "../util/DateUtil";

export interface InputOption {
    label: string;
    value: any;
    object?: any;
    icon?: string,
    valueindex?: any,
}

export interface InputModel {
    id:string,
    label:string ,
    defaultValue: any ,
    required:boolean,
    editable: boolean,
    globalValue?: any,
    nullable?: boolean
    type?: string,
    group?: string,
    options?: InputOption[];
}

export interface GroupInputModel {
    id: string,
    name: string,
}
export interface InputLayout {
    label: string,
    ids: string[],
    ids_grid?: GridSize[],
    row_grid?: GridSize,
}

export function InputModelsToObject<T>(values: InputModel[]): T {
    let object:{[key: string] : any} = {};
    values.forEach(value => {
        if(value.type === "date") {
            if(value.defaultValue === "") {
                object[value.id] =  undefined;
            } else if(value.defaultValue === ToEndISODate()) {
                object[value.id] =  undefined;
            } else {
                object[value.id] = value.defaultValue;
            }
        } else {
            object[value.id] = value.defaultValue;
        }
    })
    return object as T;
}
