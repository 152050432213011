import SettingProps from "../SettingProps";
import GenericSetting, {StyleSettingType} from "../genericsetting/GenericSetting";

export default function BorderRadiusSetting(props: SettingProps) {
    return <GenericSetting {...props}
                           title={"Border Radius"}
                           type={"border"}
                           targets={[
                                    { name: "Top Right",target: 'border-top-right-radius' , type: StyleSettingType.default_size},
                                    { name: "Top Left", target: 'border-top-left-radius' , type: StyleSettingType.default_size},
                                    { name: "Bottom Right", target: 'border-bottom-right-radius' , type: StyleSettingType.default_size},
                                    { name: "Bottom Left", target: 'border-bottom-left-radius' , type: StyleSettingType.default_size},
                                ]}
    />
}