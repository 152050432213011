import {WidgetInterface} from "../../../model/WidgetInterface";
import {useAppSelector} from "../../../../redux/hook";
import {selectViewState} from "../../../../redux/features/view/viewSlice";
import React, {useCallback, useEffect, useState} from "react";
import {nanoid} from "nanoid";
import {DeleteType} from "../../../model/DeleteOption";
import {SettingGroupType, SettingOption, SettingType} from "../../../model/SettingOption";
import EditablePanel from "../../shared/editable/EditablePanel";
import styles from "./AdvanceContent.module.scss";
import {jcr} from "../../../../datafetcher/JCRDataFetcher";
import {StyleUtil} from "../../setting/StyleUtil";
import {ContentGroupCmd} from "../../../../model/jcr/ContentGroupCmd";
import {ContentCmd} from "../../../../model/jcr/ContentCmd";
import {useOrganization} from "../../../../hook/useOrganization";
import {imageUtil} from "../../../../util/ImageUtil";
import {htmlUtil} from "../../../../util/HTMLUtil";
import {ScreenSize} from "../../shared/screensize/ScreenSize";
import Slick from "../../shared/slick/Slick";
import useWidgetState from "../../../../hook/useWidgetState";

export default function AdvanceContent(props:any) {
    const widgetData:WidgetInterface = props.data as WidgetInterface;
    const {widgetId,widgetProps} = widgetData;
    const viewState = useAppSelector(selectViewState);
    const orgCmd = useOrganization();
    const [groupToContents, setGroupToContents] = useState<any>({"" : [] });
    const [cgIdToCgName, setCgIdToCgName] = useState<any>({"": "Top Level"});
    const [cgIdToContentGroup,setCgIdToContentGroup] = useState<undefined| {[key:string]: ContentGroupCmd}>();
    const [previewInfo, setPreviewInfo] = useState<{
        type : string,
        cgId: string
    }>({
            type: widgetProps && widgetProps['content'] && widgetProps['content']['previewType'] ? widgetProps['content']['previewType'] : "",
            cgId: widgetProps && widgetProps['content'] && widgetProps['content']['previewCgId'] ? widgetProps['content']['previewCgId'] : ""
        });
    const [refreshId,setRefreshId] = useState(nanoid());
    const [currentGrid, setCurrentGrid] = useState<any>();
    const [screenSize, setScreenSize] = useState<ScreenSize>(ScreenSize.lg);
    const gridRef = useCallback((node) => {
        setCurrentGrid((l:any) => {
            if(node !== l) {
                return node;
            } else {
                return l;
            }
        });
    },[]);

    useWidgetState(widgetId, (event) => {
        if(event && event.detail) {
            if(event.detail.action === "preview") {
                widgetProps["content"]['previewType'] = event.detail.value.type;
                widgetProps["content"]['previewCgId'] = event.detail.value.cgId;
                setPreviewInfo({type: event.detail.value.type, cgId: event.detail.value.cgId})
            } else {
                setRefreshId(nanoid());
            }
        } else {
            setRefreshId(nanoid());
        }
    })

    if(StyleUtil.isDefaultStyle(widgetProps['panel'])) {
        widgetProps['panel'] = {
            style: {
                default: true,
                margin: {
                    "margin-inline-start": "5%",
                    "margin-inline-end": "5%"
                }
            }
        };
    }

    if(StyleUtil.isDefaultStyle(widgetProps["catalog_list_panel"])) {
        widgetProps["catalog_list_panel"] = {
            style: {
                default: true,
                flex: {
                    "justify-content": "center"
                }
            }
        };
    }

    if(StyleUtil.isDefaultStyle(widgetProps["empty_panel"])) {
        widgetProps["empty_panel"] = {
            style: {
                default: true,
                typography: {
                    'font-size': "1rem"
                },
                align: {
                    "text-align": "start"
                },
                padding: {
                    "padding-block-start": "1rem",
                    "padding-block-end": "1rem",
                    "padding-inline-start": "1rem",
                    "padding-inline-end": "1rem",
                }
            }
        };
    }



    if(StyleUtil.isDefaultStyle(widgetProps["breadcrumb_panel"])) {
        widgetProps['breadcrumb_panel'] = {
            style: {
                default: true,
                typography: {
                    "font-size": "0.8rem",
                    "line-height": "1.8rem"
                },
                margin: {
                    "margin-block-start":"20px",
                    "margin-block-end": "15px",
                }
            }
        };
    }


    const hasBreadCrumb:boolean = widgetProps['content'] && widgetProps['content']['hasBreadCrumb'] !== undefined ? widgetProps['content']['hasBreadCrumb']: true;
    const breadcrumbSeparator:string = widgetProps['content'] && widgetProps['content']['breadcrumbSeparator'] !== undefined ? widgetProps['content']['breadcrumbSeparator']: "/";
    const breadcrumbSpace:string = widgetProps['content'] && widgetProps['content']['breadcrumbSpace'] !== undefined ? widgetProps['content']['breadcrumbSpace']: "0.5rem";
    const emptyMessage = widgetProps['content'] && widgetProps['content']['emptyMessage'] !== undefined ? widgetProps['content']['emptyMessage'] : "No content available to display";
    const titleMessage = widgetProps['content'] && widgetProps['content']['titleMessage'] !== undefined ? widgetProps['content']['titleMessage'] : "";
    const items:any[] = widgetProps['content'] && widgetProps['content']['acmItems'] !== undefined ? widgetProps['content']['acmItems'] : [];
    const previewType = widgetProps['content'] && widgetProps['content']['previewType'] !== undefined ? widgetProps['content']['previewType'] : "";
    const previewCgId = widgetProps['content'] && widgetProps['content']['previewCgId'] !== undefined ? widgetProps['content']['previewCgId'] : "";
    //console.log(previewType,previewCgId);

    useEffect( () => {
        let allContentPromises:Promise<any>[] = [];
        let allGroupPromises:Promise<any>[] = [];
        for(let item of items) {
            for(let item of items) {
                if(!groupToContents[item.cgId]) {
                    allContentPromises.push(jcr.content.fullList(item.type, item.cgId));
                }
                if(!cgIdToCgName[item.cgId]) {
                    allGroupPromises.push(jcr.contentGroup.info(item.type, item.cgId));
                }
            }
        }

        if(allGroupPromises.length > 0) {
            Promise.all(allGroupPromises).then((responses) => {
                let newCgIdToCgName = {...cgIdToCgName};
                let newCgIdToContentGroup = {...cgIdToContentGroup};
                for (let response of responses) {
                    if(response.data.message.code === 200) {
                        let array = response.config.url.split("/");
                        let cgId = array[array.length - 1];
                        newCgIdToCgName[cgId] = response.data.response.cgName;
                        newCgIdToContentGroup[cgId] = response.data.response;
                    }
                }
                setCgIdToCgName(newCgIdToCgName);
                setCgIdToContentGroup(newCgIdToContentGroup);
            })
        }

        if(allContentPromises.length > 0) {
            Promise.all(allContentPromises).then((responses) => {
                let newGroupToContents = {...groupToContents};
                for (let response of responses) {
                    if(response.data.message.code === 200) {
                        let array = response.config.url.split("/");
                        let cgId = array[array.length - 3];
                        let type = array[array.length - 4];
                        newGroupToContents[cgId] = response.data.response;
                    }
                }
                setGroupToContents(newGroupToContents);
            }).catch((error) => {
                console.log(error);
            });
        }
    },[refreshId]);


    let columns = 4;
    if(previewType === "grids") {
        if(screenSize <= ScreenSize.md) {
            columns = 2;
        }
    } else if(previewType === "smallgrids") {
        if(screenSize <= ScreenSize.md) {
            columns = 2;
        } else {
            columns = 6;
        }
    } else if(previewType === "galleries") {
        if(screenSize <= ScreenSize.md) {
            if(screenSize === ScreenSize.xs) {
                columns = 2;
            }  else {
                columns = 4;
            }
        } else {
            columns = 8;
        }
    }


    const themeName = "advance_content";
    let widgetStyles:any = {
        theme: StyleUtil.toThemeNameByType(widgetProps, themeName),
        current: StyleUtil.toClassFromWidget(widgetId , widgetProps, "current_style"),
        panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "panel"),
        title: StyleUtil.toClassFromWidget(widgetId , widgetProps, "title_panel"),
        description: StyleUtil.toClassFromWidget(widgetId , widgetProps, "description_panel"),

        grid_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "grid_panel"),
        smallgrid_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "smallgrid_panel"),
        blog_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "blog_panel"),
        faq_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "faq_panel"),
        block_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "block_panel"),

        breadcrumb_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "breadcrumb_panel"),
        catalog_list_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "group_panel"),
        empty_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "empty_panel"),
        breadcrumb_style1: StyleUtil.toClassFromMap(widgetId, "breadcrumb_panel li::after", {
            'content': "'" + breadcrumbSeparator + "'",
            'padding-inline': breadcrumbSpace
        }),
        breadcrumb_style2: StyleUtil.toClassFromMap(widgetId, "breadcrumb_panel li:last-child::after", {
            'content': "unset",
            'padding-inline': 'unset'
        }),

        custom_grid_panel: StyleUtil.toClassFromMap(widgetId, "custom_grid_panel",{
            "display": "grid",
            "grid-template-columns" : `repeat(${columns},minmax(0, 1fr))`,
            "column-gap" : "2%",
            "row-gap": "10px"
        })
    }

    const createBreadCrumb = (path: string[]) => {
        return <ul className={StyleUtil.combineClasses(styles.BreadCrumb,"ac_breadcrumb",widgetStyles.breadcrumb_panel,widgetStyles.breadcrumb_style1,widgetStyles.breadcrumb_style2)}>
            {
                path.map((item,index) => <li key={`breadcrumb_${index}`}><a>{item}</a></li>)
            }
        </ul>
    }

    const createTopLevel = () => {
        return <>
            { titleMessage !== "" && <h2 className={StyleUtil.combineClasses("ac_title",widgetStyles.title)}>{titleMessage}</h2> }
            <ul>
            {
                items.map((item,index) => {
                    let contents = groupToContents[item.cgId];
                    if (contents) {
                        return <li key={`acm_group_${index}`}>{item.cgName} ({contents.length})</li>
                    } else {
                        return <li key={`acm_group_${index}`}>{item.cgName}</li>
                    }
                })
            }
            </ul>
        </>
    }

    function sortContent(contentGroup: ContentGroupCmd, contents: ContentCmd[]) {
        if (contentGroup.cgSortBy === "Title") {
            contents.sort((obj1, obj2) => obj1.ctTitle.toLowerCase().localeCompare(obj2.ctTitle.toLowerCase()))
        } else {
            contents.sort((obj1, obj2) => obj1.ctCreatedDate.localeCompare(obj2.ctCreatedDate))
        }
    }

    const createBlogContent = () => {
        let item = items.find((item) => item.cgId === previewCgId);
        if(!item) return <></>
        let contents = groupToContents[item.cgId] as ContentCmd[];
        if(!contents) return <></>
        if(!cgIdToContentGroup) return <></>
        let contentGroup = cgIdToContentGroup[item.cgId];
        if(!contentGroup) return <></>

        sortContent(contentGroup, contents);

        return <>
            { contentGroup.cgName !== "" && <h1 className={StyleUtil.combineClasses("ac_title",widgetStyles.title)}>{contentGroup.cgName}</h1> }
            { contentGroup.cgDescription !== "" && <h3 className={StyleUtil.combineClasses("ac_description",widgetStyles.description)}>{contentGroup.cgDescription}</h3> }
            <div className={StyleUtil.combineClasses("ac_blog_panel",widgetStyles.blog_panel)}>
                {
                    contents.map(item => {
                        return <div className={StyleUtil.combineClasses("ac_blog_item")}>
                            <div className={StyleUtil.combineClasses("title")}>{item.ctTitle}</div>
                            <div className={StyleUtil.combineClasses("date")}>{item.ctPublishDate}</div>
                            <div className={StyleUtil.combineClasses("copy")}
                                 dangerouslySetInnerHTML={{__html: htmlUtil.process(orgCmd?.orgDetail?.portalURL,item.ctContent)}}>
                            </div>
                            {item.ctImage !== "" && <div className={StyleUtil.combineClasses("image", styles.GridItemImage)}>
                                <img src={imageUtil.toPath(orgCmd?.orgDetail?.portalURL, item.ctImage)}
                                     loading={"lazy"}/>
                            </div>
                            }
                        </div>
                            })
                }
            </div>
        </>
    }

    const createGalleryContent = () => {
        let item = items.find((item) => item.cgId === previewCgId);
        if(!item) return <></>
        let contents = groupToContents[item.cgId] as ContentCmd[];
        if(!contents) return <></>
        if(!cgIdToContentGroup) return <></>
        let contentGroup = cgIdToContentGroup[item.cgId];
        if(!contentGroup) return <></>

        return <>
            { contentGroup.cgName !== "" && <h1 className={StyleUtil.combineClasses("ac_title",widgetStyles.title)}>{contentGroup.cgName}</h1> }
            { contentGroup.cgDescription !== "" && <h3 className={StyleUtil.combineClasses("ac_description",widgetStyles.description)}>{contentGroup.cgDescription}</h3> }
            { createSlick() }
            { createGridContent("gallery") }
        </>
    }

    const createSlick = () => {
        let item = items.find((item) => item.cgId === previewCgId);
        if(!item) return <></>
        let contents = groupToContents[item.cgId] as ContentCmd[];
        if(!contents) return <></>
        if(!cgIdToContentGroup) return <></>
        let contentGroup = cgIdToContentGroup[item.cgId];
        if(!contentGroup) return <></>

        sortContent(contentGroup, contents);

        return <Slick infinite={true}
                      itemsPerPage={10}
                      scrollItems={2}
                      autoplaySpeed={100}
                      animationSpeedInMs={300}
                      animationType={"slide"}
                      targetItemWidth={100}
                      targetItemHeight={100}
                      contentInlinePadding={40}
                      slickStyle={styles.SlickPanel}
                      arrowProps={ {width: 10,
                                    height: 20,
                                    strokeSize: 2,
                                    color: "white",
                                    inlineMargin: "10px",
                                    blockMargin: "30%" }}
        >
            {
                contents.map((item,index) => {
                    return <div key={`slick_item_${index}`} className={StyleUtil.combineClasses(`ac_slick_item`,styles.SlickItem)} >
                            <img src={imageUtil.toPath(orgCmd?.orgDetail?.portalURL, item.ctImage)}
                                 loading={"lazy"}/>
                    </div>
                })
            }
        </Slick>
    }

    const createGridContent = (prefix: string) => {

        let item = items.find((item) => item.cgId === previewCgId);
        if(!item) return <></>
        let contents = groupToContents[item.cgId] as ContentCmd[];
        if(!contents) return <></>
        if(!cgIdToContentGroup) return <></>
        let contentGroup = cgIdToContentGroup[item.cgId];
        if(!contentGroup) return <></>

        sortContent(contentGroup, contents);

        return <>
            {/*{ contentGroup.cgName !== "" && <h1 className={StyleUtil.combineClasses("ac_title",widgetStyles.title)}>{contentGroup.cgName}</h1> }*/}
            {/*{ contentGroup.cgDescription !== "" && <h3 className={StyleUtil.combineClasses("ac_description",widgetStyles.description)}>{contentGroup.cgDescription}</h3> }*/}
            <div ref={gridRef} className={StyleUtil.combineClasses(`ac_${prefix}grid_panel`,widgetStyles.grid_panel,widgetStyles.custom_grid_panel,styles.GridPanel)}>
                {
                    contents.map((item,index) => {
                        return <div key={`grid_item_${index}`} className={StyleUtil.combineClasses(`ac_${prefix}grid_item`)}>
                            {item.ctImage !== "" && <div className={StyleUtil.combineClasses("ac_img", styles.GridItemImage)}>
                                    <img src={imageUtil.toPath(orgCmd?.orgDetail?.portalURL, item.ctImage)}
                                         loading={"lazy"}/>
                                </div>
                            }
                            <div className={StyleUtil.combineClasses("ac_title")}>
                                <h4> {item.ctTitle}</h4>
                            </div>
                            <div className={StyleUtil.combineClasses("ac_desc")}>
                                {item.ctDescription}
                            </div>
                        </div>
                    })
                }
            </div>
        </>
    }

    const createFAQContent = () => {
        let item = items.find((item) => item.cgId === previewCgId);
        if(!item) return <></>
        let contents = groupToContents[item.cgId] as ContentCmd[];
        if(!contents) return <></>
        if(!cgIdToContentGroup) return <></>
        let contentGroup = cgIdToContentGroup[item.cgId];
        if(!contentGroup) return <></>

        sortContent(contentGroup, contents);

        return <>
            { contentGroup.cgName !== "" && <h1 className={StyleUtil.combineClasses("ac_title",widgetStyles.title)}>{contentGroup.cgName}</h1> }
            { contentGroup.cgDescription !== "" && <h3 className={StyleUtil.combineClasses("ac_description",widgetStyles.description)}>{contentGroup.cgDescription}</h3> }

            <div className={StyleUtil.combineClasses("ac_faq_panel",widgetStyles.faq_panel)}>
                {
                    contents.map((item,index) => {
                        return <ul key={`faq_topic_${index}`} className={StyleUtil.combineClasses("ac_faq_item")}>
                            <li><a href={"#" + item.ctTitle}>{item.ctTitle}</a></li>
                        </ul>
                    })
                }
                {
                    contents.map((item,index) => {
                        return <div key={`faq_detail_${index}`} className={StyleUtil.combineClasses("ac_faq_item",styles.FAQItem)}>
                            <div className={StyleUtil.combineClasses("title",styles.FAQItemTitle)}>{item.ctTitle}</div>
                            <div className={StyleUtil.combineClasses("date")}>{item.ctPublishDate}</div>
                            <div className={StyleUtil.combineClasses("copy")}
                                 dangerouslySetInnerHTML={{__html: htmlUtil.process(orgCmd?.orgDetail?.portalURL,item.ctContent)}}></div>
                            { item.ctImage !== "" && <div className={StyleUtil.combineClasses("image",styles.FAQItemImage)}>
                                <img src={imageUtil.toPath(orgCmd?.orgDetail?.portalURL,item.ctImage)} data-text={item.ctImage} loading={"lazy"}/>
                            </div> }
                        </div>
                    })
                }
            </div>
        </>
    }


    const createActiveContent = () => {
        let hasEmpty =  items && items.length > 0 ? false : true;

        return <div key={refreshId} className={styles.Root}>
            <div className={StyleUtil.combineClasses(widgetStyles.theme,widgetStyles.current,widgetStyles.panel,styles.Container)}>
            {/*{hasBreadCrumb && createBreadCrumb(currentPath)}*/}
            {
                hasEmpty === false && previewType === "" && createTopLevel()
            }
            {
                hasEmpty === false && (previewType === "blocks" || previewType === "blogs") && createBlogContent()
            }
            {
                hasEmpty === false && (previewType === "faqs") && createFAQContent()
            }
            {
                hasEmpty === false && (previewType === "grids") && createGridContent("")
            }
            {
                hasEmpty === false && (previewType === "smallgrids") && createGridContent("small")
            }
            {
                hasEmpty === false && (previewType === "galleries") && createGalleryContent()
            }
            { hasEmpty && <div className={StyleUtil.combineClasses("ac_empty_panel",widgetStyles.empty_panel)}>{emptyMessage}</div>}
        </div></div>

    }


    useEffect(() => {
        let resize_ob = new ResizeObserver(function(entries) {
            if(currentGrid) {
                if(currentGrid.clientWidth < 400) {
                    if(screenSize !== ScreenSize.xs) {
                        setScreenSize(ScreenSize.xs);
                    }
                } else if(currentGrid.clientWidth < 600) {
                    if(screenSize !== ScreenSize.md) {
                        setScreenSize(ScreenSize.md);
                    }
                } else {
                    if(screenSize != ScreenSize.lg) {
                        setScreenSize(ScreenSize.lg);
                    }
                }

                let divs = currentGrid.children;
                if (divs && divs.length > 0) {
                    for (let i = 0; i < divs.length; i++) {
                       divs[i].firstChild.style.height = (divs[i].clientWidth*0.9) + "px";
                       //divs[i].firstChild.style.width = (divs[i].clientWidth) + "px";
                    }
                }
            }
        });

        if(currentGrid) {
            resize_ob.observe(currentGrid);
        }
        return () => {
            if(currentGrid) {
                resize_ob.unobserve(currentGrid)
            }
            resize_ob.disconnect()
        }
    },[currentGrid,screenSize,previewType])



    if(viewState.isEdit) {
        let deleteOption = {type: DeleteType.delete_with_rearrange, tag: null};

        let suggestTags: string[] = [
            "current",
            "current .ac_panel",
            "current .ac_title",
            "current .ac_content_panel",
            "current .ac_empty_panel",
            "current .ac_breadcrumb",
            "current .ac_breadcrumb li,a"
            ];

        if(items.findIndex((item) => item.cgType === "faqs") >= 0) {
            suggestTags = [...suggestTags,
                "current .ac_faq_panel",
                "current .ac_faq_item",
                "current .ac_faq_item a",
                "current .ac_faq_item .title",
                "current .ac_faq_item .date",
                "current .ac_faq_item .copy"];
        }

        if(items.findIndex((item) => item.cgType === "blogs" || item.cgType === "blocks") >= 0) {
            suggestTags = [...suggestTags,
                "current .ac_blog_panel",
                "current .ac_blog_item",
                "current .ac_blog_item .image",
                "current .ac_blog_item .title",
                "current .ac_blog_item .date",
                "current .ac_blog_item .copy"]
        }

        if(items.findIndex((item) => item.cgType === "grids") >= 0) {
            suggestTags = [...suggestTags,
                "current .ac_grid_panel",
                "current .ac_grid_item",
                "current .ac_grid_item .image",
                "current .ac_grid_item .title",
                "current .ac_grid_item .desc"]
        }

        if(items.findIndex((item) => item.cgType === "smallgrids") >= 0) {
            suggestTags = [...suggestTags,
                "current .ac_smallgrid_panel",
                "current .ac_smallgrid_item",
                "current .ac_smallgrid_item .image",
                "current .ac_smallgrid_item .title",
                "current .ac_smallgrid_item .desc",
            ]
        }

        let settingOptions:SettingOption[] = [
            { title: "Settings", type: SettingType.widget,group: "content",
                editor: "advancecontentsetting",
                additions: {
                    sharedObjects: props.sharedObjects,
                    groupToContent: groupToContents,
                    cgIdToCgName: cgIdToCgName
                }, groupType: SettingGroupType.layout
                //     <AdvanceContentSetting widgetData={widgetData}
                //                                sharedObjects={props.sharedObjects}
                //                                saveWidgetState={saveWidgetState}
                //                                groupToContent={groupToContents}
                //                                cgIdToCgName={cgIdToCgName}
                //                                onChangePreview={(type: string, cgId) => {
                //                                      widgetProps["content"]['previewType'] = type;
                //                                      widgetProps["content"]['previewCgId'] = cgId;
                //                                      saveWidgetState();
                //                                      setPreviewInfo({type: type, cgId: cgId})
                //                                  }}
                // />
            },
            { type: SettingType.dimension, group: "panel" , groupType: SettingGroupType.style},
            { type: SettingType.background, group: "panel" , groupType: SettingGroupType.style},
            { type: SettingType.border, group: "panel", groupType: SettingGroupType.style},

            { type: SettingType.margin, group: "panel", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "panel", groupType: SettingGroupType.advanced},

            { type: SettingType.dimension, group: "empty_panel", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "empty_panel", groupType: SettingGroupType.style},
            { type: SettingType.border, group: "empty_panel", groupType: SettingGroupType.style},

            { type: SettingType.margin, group: "empty_panel", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "empty_panel", groupType: SettingGroupType.advanced},

            { type: SettingType.dimension, group: "content_panel", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "content_panel", groupType: SettingGroupType.style},
            { type: SettingType.border, group: "content_panel", groupType: SettingGroupType.style},

            { type: SettingType.margin, group: "content_panel", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "content_panel", groupType: SettingGroupType.advanced},


            { type: SettingType.typography, group: "breadcrumb_panel", groupType: SettingGroupType.style},
            { type: SettingType.dimension, group: "breadcrumb_panel", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "breadcrumb_panel", groupType: SettingGroupType.style},
            { type: SettingType.border, group: "breadcrumb_panel", groupType: SettingGroupType.style},

            { type: SettingType.margin, group: "breadcrumb_panel", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "breadcrumb_panel", groupType: SettingGroupType.advanced},


        ];

        return <EditablePanel
                              {...props}
                              themeName={themeName}
                              suggestTags={suggestTags}
                              settingOptions={settingOptions}
                              settingGroups={[
                                  {group: "content", groupName: "Content", groupType: SettingGroupType.layout},
                                  {group: "panel", groupName: "Top Panel", groupType: SettingGroupType.style},
                                  {group: "breadcrumb_panel", groupName: "Breadcrumb Panel", groupType: SettingGroupType.style},
                                  {group: "content_panel", groupName: "Content Panel", groupType: SettingGroupType.style},
                                  {group: "empty_panel", groupName: "Empty Panel", groupType: SettingGroupType.style},
                              ]}
                              deleteOption={deleteOption}>
            {createActiveContent()}
        </EditablePanel>
    } else {
        return createActiveContent();
    }
}