import {TableDataFetcher} from "./TableDataFetcher";
import {TableModel} from "../model/TableModel";
import { format } from 'date-fns'
import HttpClient from "./HttpClient";
import OrgCmd from "../model/jcr/OrgCmd";
import {Chip} from "@mui/material";

const NAME_COLUMN = 0;
const ENVIRONMENT_COLUMN = 1;
const MODIFIED_DATE_COLUMN = 2;
const STATUS_COLUMN = 3;
const OPTION_COLUMN = 4;
const TOTAL_COLUMN = 5;
export class CMSRouteDataFetcher implements TableDataFetcher {

    private data: any;
    private duplicateNames: Set<string>;
    constructor(private currentOrg:OrgCmd) {
        this.duplicateNames = new Set<string>();
    }

    canDelete(): boolean {
        return true;
    }

    getItemAt(row: number): any {
        return this.data[row];
    }

    findDataById(id: string): any {
        for(let i=0;this.data.length;i++) {
            if(this.data[i].orgCode === id) {
                return this.data[i];
            }
        }
        return null;
    }

    load(){
        return HttpClient.get("/api/v0/jcr/org/" + this.currentOrg.orgCode + "/lite/routes").then((result) => {
            this.data = result.data.response;
            let allNames = new Set<string>();
            this.data.forEach((item:any) => {
                let name = item.routeName;
                if(allNames.has(name)) {
                    this.duplicateNames.add(name);
                } else {
                    allNames.add(name);
                }
            });
        });
    }

    getIdAt(row: number): string {
        return this.data[row].id;
    }

    getColumnAlignAt(col: number): ("left" | "right" | "center") {
        if(col === NAME_COLUMN) return "left";
        else if(col === ENVIRONMENT_COLUMN) return "center"
        else if(col === OPTION_COLUMN) return "center"
        else return "right";
    }

    getDataAt(row: number, col: number): TableModel {
        let item = this.data[row];
        if(col === NAME_COLUMN) {
            return new TableModel(item.id,
                item.routeName,
                item.routePattern,
                "/routes/" + item.id);
        } else if(col === ENVIRONMENT_COLUMN) {
            let buf: JSX.Element[] = [];
            if(item.routeEnvs) {
                if(item.routeEnvs && item.routeEnvs.length === 3) {
                    buf.push(<Chip color={"primary"} variant={"outlined"} size={"small"} sx={{margin: "4px"}}
                                   key={`${item.id}_all`} label={"All"}/>)
                } else {
                    item.routeEnvs.forEach((env: string) => {
                        if (env === "prod") {
                            buf.push(<Chip color={"primary"} variant={"outlined"} size={"small"} sx={{margin: "4px"}}
                                           key={`${item.id}_prod`} label={"prod"}/>)
                        } else if (env === "dev") {
                            buf.push(<Chip color={"primary"} variant={"outlined"} size={"small"} sx={{margin: "4px"}}
                                           key={`${item.id}_dev`} label={"dev"}/>)
                        } else {
                            buf.push(<Chip color={"primary"} variant={"outlined"} size={"small"} sx={{margin: "4px"}}
                                           key={`${item.id}_staging`} label={"staging"}/>)
                        }
                    })
                }
            }
            let tableModel = new TableModel(item.id,
                buf.join(", "),"");
            tableModel.component = <>{buf}</>
            return tableModel;
        } else if(col === MODIFIED_DATE_COLUMN) {
            return new TableModel(item.id,
                format(new Date(item.routeModifiedAt),  'yyyy/MM/dd'),"");
        } else if(col === STATUS_COLUMN) {
            return new TableModel("ACTIVE",
                item.active ? "Active" : "Inactive","");
        } else if(col === OPTION_COLUMN) {
            return new TableModel(item.id,
                "",
                "",
                "/routes/" + item.id,
                undefined,
                [
                    { icon: "content_copy", label: "Duplicate", pathname: "/routes/" + item.id , search: null, action: { tag: "DUPLICATE", tagObject: item } },
                    { icon: "download", label: "Download", pathname: "/routes/" + item.id , search: null, action: { tag: "DOWNLOAD", tagObject: item }},
                ]);
        }else {
            return new TableModel("",
                "","");
        }
    }

    getTotalRecords(): number {
        if(this.data && this.data !== null) {
            return this.data.length;
        } else {
            return 0;
        }
    }

    getColumnNameAt(col: number): string {
        if(col === NAME_COLUMN) {
            return "Route";
        } else if(col === ENVIRONMENT_COLUMN) {
            return "Environment"
        } else if(col === MODIFIED_DATE_COLUMN) {
            return "Modified Date"
        } else if(col === STATUS_COLUMN) {
            return "Status"
        } else if(col === OPTION_COLUMN) {
            return "Options"
        } else {
            return "";
        }
    }

    getNumberOfColumns(): number {
        return TOTAL_COLUMN;
    }



}