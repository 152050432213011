import {TableDataFetcher} from "./TableDataFetcher";
import {TableModel} from "../model/TableModel";
import { format } from 'date-fns'
import HttpClient from "./HttpClient";
import {ProxyOrgLiteCmd} from "../model/proxy/cloudlsi-app";

const NAME_COLUMN = 0;
const CREATED_DATE_COLUMN = 1;
const MODIFIED_DATE_COLUMN = 2;
const BUILD_DATA_COLUMN = 100;
const TOTAL_COLUMN = 3;

export class OrganizationDataFetcher implements TableDataFetcher {

    private data: any;
    private orgData: ProxyOrgLiteCmd[] = [];
    private _search: string = "";

    constructor(private buildFunction: (id: string) => void) {
    }

    canDelete(): boolean {
        return false;
    }

    getItemAt(row: number): any {
        return this.data[row]
    }

    findDataById(id: string): any {
        for(let i=0;this.data.length;i++) {
            if(this.data[i].orgCode === id) {
                return this.data[i];
            }
        }
        return null;
    }

    load() {
        return HttpClient.get("/api/proxy/orgs").then((result) => {
            this.orgData = result.data;
            this.innerLoad();
        });
    }

    search(search:string) {
        this._search = search;
        this.innerLoad();
    }

    private innerLoad() {
        if(this._search && this._search !== "") {
            let temp = this._search.toUpperCase();
            //|| org.name.toUpperCase().indexOf(temp)>=0
            this.data = this.orgData.filter(org => org.orgCode.toUpperCase().indexOf(temp)>=0 || org.orgName.toUpperCase().indexOf(temp)>=0 || org.siteCode.toUpperCase().indexOf(temp)>=0);
        } else {
            this.data = this.orgData;
        }
    }

    getIdAt(row: number) : string {
        return this.data[row].orgCode;
    }

    getColumnAlignAt(col: number): ("left" | "right" | "center") {
        if(col === 0) return "left";
        else if(col === 1) return "right"
        else return "right";
    }

    getDataAt(row: number, col: number): TableModel {
        let item;
        if(this.data !== null && row < this.data.length) {
            item = this.data[row];
        } else {
            item = null;
        }
        if(item !== null) {
            if(col === NAME_COLUMN) {
                return new TableModel("Org " + (row + 1),
                    item === null ? "" :  item.orgName + " (" + item.orgCode + ")",
                    "",
                    "/organization/" + item.orgCode);
            } else if(col === CREATED_DATE_COLUMN) {
                return new TableModel("Org " + (row + 1),  format(new Date(item.createdAt),  'yyyy/MM/dd'),"");
            } else if(col === MODIFIED_DATE_COLUMN) {
                return new TableModel("Org " + (row + 1),
                                    format(new Date(item.modifiedAt), 'yyyy/MM/dd'),
                                    "");
            } else if(col === BUILD_DATA_COLUMN) {
                return new TableModel("Org " + (row + 1),"build" , "","", this.buildFunction);
            } else {
                return new TableModel("",
                    "","",);
            }
        } else {
            return new TableModel("",
                "","",);
        }

    }

    getTotalRecords(): number {
        if(this.data && this.data !== null) {
            return this.data.length;
        } else {
            return 0;
        }

    }

    getColumnNameAt(col: number): string {
        if(col === NAME_COLUMN) {
            return "Organization Name";
        } else if(col === CREATED_DATE_COLUMN) {
            return "Create Date"
        } else if(col === MODIFIED_DATE_COLUMN) {
            return "Modified Date"
        } else if(col === BUILD_DATA_COLUMN) {
            return ""
        } else {
            return "";
        }
    }

    getNumberOfColumns(): number {
        return TOTAL_COLUMN;
    }



}