import {Grid, MenuItem, TextField} from "@mui/material";
import React from "react";
import AssetField from "../../../../ui/assetfield/AssetField";
import {SettingLabel} from "../../setting/settinglabel/SettingLabel";

export default function ImageSetting(props: { widgetData: any, saveWidgetState: () => void }) {
    let {widgetData} = props;

    const handleChange = (value: any, key: string) => {
        widgetData.widgetProps["content"][key] = value;
        props.saveWidgetState();
    }

    return <Grid container spacing={2}>
        <Grid item xs={4}>
            <SettingLabel title={"Image URL"}/>
        </Grid>
        <Grid item xs={8}>
            <AssetField size={"small"}
                        fullWidth={true}
                        label={""}
                        defaultValue={widgetData.widgetProps['content']['src_url']}
                        onChange={(event) => {
                           handleChange(event.target.value, "src_url")
                           event.preventDefault();
                           event.stopPropagation();
                           props.saveWidgetState();
                        }}/>
        </Grid>

        <Grid item xs={4}>
            <SettingLabel title={"Image Link URL"}/>
        </Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                        fullWidth={true}
                        label={""}
                        defaultValue={widgetData.widgetProps['content']['href']}
                        onChange={(event) => {
                            handleChange(event.target.value, "href")
                            event.preventDefault();
                            event.stopPropagation();
                            props.saveWidgetState();
                        }}/>
        </Grid>
        <Grid item xs={4}><SettingLabel title={"Open Link In"}/></Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                       fullWidth={true}
                       select={true}
                       value={widgetData.widgetProps['content']['openLink'] ? widgetData.widgetProps['content']['openLink'] : "_self"}
                       label={""}
                       onChange={(event) => {
                           handleChange(event.target.value.trim(), "openLink")
                           event.preventDefault();
                           event.stopPropagation();
                           props.saveWidgetState();
                           // props.onChange(searchType, searchString, start, maxItems,searchProfileId);
                       }}>
                <MenuItem value={"_self"}>Current Window</MenuItem>
                <MenuItem value={"_blank"}>New Window</MenuItem>
            </TextField>
        </Grid>
    </Grid>
}