import {Grid, TextField} from "@mui/material";
import React, {useState} from "react";
import {InputOption} from "../../../../model/InputModel";
import DisplayField from "../../shared/displayfield/DisplayField";
import {SettingLabel} from "../../setting/settinglabel/SettingLabel";

export default function FieldGroupSetting(props: {
                                                attributeGroupOptions?: InputOption[],
                                                widgetData: any,
                                                saveWidgetState: () => void,
                                                widgetCallback: (action: string, value: any) => void
                                                // setGroups: (groups: string[]) => void,
                                                // setProducts: (detail: { [key: string]: any }[]) => void
                                           }
                                                    // onChange: (searchType: string,
                                                    //             searchData: string,
                                                    //             start: number,
                                                    //             rows: number,
                                                    //             searchProfileId: number) => void}
) {
    const {widgetData} = props;
    //const {widgetProps} = widgetData;

    const [varName, setVarName] = useState<string>(widgetData.widgetProps['content']['varName'] ? widgetData.widgetProps['content']['varName'] : "${partNumber}");
    const [displayType, setDisplayType] = useState<string>(widgetData.widgetProps['content']['displayType'] ? widgetData.widgetProps['content']['displayType'] : "TAB");
    const [attributeGroups,setAttributeGroups] = useState<string[]>(widgetData.widgetProps['content']['attributeGroups'] ? widgetData.widgetProps['content']['attributeGroups'] : []);
    const [previewPartNumber, setPreviewPartNumber] = useState<string>(widgetData.widgetProps['content']['previewPartNumber'] ? widgetData.widgetProps['content']['previewPartNumber'] : "");

    const handleChange = (value: string, key: string) => {
        widgetData.widgetProps["content"][key] = value;
        props.saveWidgetState();
    }

    const handleRemove = (name: string) => {
        if(name != null) {
            let newAttributeGroups = [...attributeGroups]
            let index = parseInt(name.substring(5)); // remove prefix "group"
            newAttributeGroups.splice(index,1);
            setAttributeGroups(newAttributeGroups);
            widgetData.widgetProps["content"]['attributeGroups'] = newAttributeGroups;
            props.saveWidgetState();
        }
    }
    const handleDoNotChange = (value: string, key: string) => {

    }

    const addAttributeGroup = (group: string,index: number) => {
        if(index < 0) {
            let newAttributeGroups = [...attributeGroups]
            if (newAttributeGroups.indexOf(group) < 0) {
                newAttributeGroups.push(group)
            }
            setAttributeGroups(newAttributeGroups);
            props.widgetCallback("groups",newAttributeGroups);
            // props.setGroups(newAttributeGroups);
            widgetData.widgetProps["content"]['attributeGroups'] = newAttributeGroups;
        } else {
            let newAttributeGroups = [...attributeGroups]
            newAttributeGroups[index] = group
            newAttributeGroups = newAttributeGroups.filter((value,index) => newAttributeGroups.indexOf(value) === index);
            setAttributeGroups(newAttributeGroups)
            widgetData.widgetProps["content"]['attributeGroups'] = newAttributeGroups;
            // props.setGroups(newAttributeGroups);
            props.widgetCallback("groups",newAttributeGroups);
        }
        props.saveWidgetState();
    }

    const createAutoComplete = (label:string,name:string,value:string,setFunction: (value:string) => void,displayOptions:InputOption[]) => {
        return <DisplayField key={name} label={label} name={name}
                             value={value}
                             setFunction={setFunction}
                             handleChange={handleDoNotChange}
                             handleRemove={handleRemove}
                             displayOptions={displayOptions}/>
    }

    return <Grid container spacing={2}>
        <Grid item xs={4}>
            <SettingLabel title={"Variable Name"}/>
        </Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={""}
                       value={varName}
                       onChange={(event) => {
                           handleChange(event.target.value.trim(), "varName")
                           setVarName(event.target.value);
                       }}/>
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"Preview Part Number"}/>
        </Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={""}
                       value={previewPartNumber ? previewPartNumber : ""}
                       onChange={(event) => {
                           handleChange(event.target.value.trim(), "previewPartNumber")
                           setPreviewPartNumber(event.target.value);
                       }}/>
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"Display Type"}/>
        </Grid>
        <Grid item xs={8}>
            { createAutoComplete("","displayType",displayType,(value) => {
                setDisplayType(value)
                handleChange(value, "displayType")

            },[
                { label: "Tab" , value: "TAB"},
                { label: "Stack" , value: "STACK"}
            ])}
        </Grid>
        {
            attributeGroups.map((group,index) => {
                return <><Grid item xs={4}>
                        <SettingLabel title={`Group #${index+1}`}/>
                    </Grid>
                    <Grid item xs={8} >
                        {createAutoComplete("",`group${index}`,group,(group) => {
                            addAttributeGroup(group,index);
                        },props.attributeGroupOptions!)}
                </Grid></>
            })
        }
        <Grid item xs={4}>
            <SettingLabel title={`Group #${attributeGroups.length+1}`}/>
        </Grid>
        <Grid item xs={8} >
            {createAutoComplete("",`group${attributeGroups.length}`,'',(group) => {
                addAttributeGroup(group,-1);
            },props.attributeGroupOptions!)}
        </Grid>
    </Grid>
}