import styles from "../menu/UserMenu.module.scss";
import {
    AppBar,
    Box,
    Divider, Grid,
    IconButton, LinearProgress,
    MenuItem,
    Popover, Snackbar,
    Toolbar, Typography, Alert,
} from "@mui/material";

import React, {useContext, useEffect, useRef, useState} from "react"
import {useAppSelector} from "../../redux/hook"
import {selectLoginState} from "../../redux/features/login/loginSlice"
import {useHistory, useLocation} from "react-router"
import {hasCMSModule, useSSO} from "../../datafetcher/HttpClient"
import {useOrganization} from "../../hook/useOrganization"
import cropped_logo from "../../assets/cropped_logo.svg"
import IconMenuItem from "./IconMenuItem"
import UploadContext from "../../context/UploadContext"
import axios from "axios"
import SolidIcon from "../icon/SolidIcon"
import {TrackData} from "../../util/TrackUtil"
import BuildButton from "../button/BuildButton";
import DrawerMenuItem from "./DrawerMenuItem";
import {
    CMSMainMenuConfig,
    LSICatalogAdminConfig,
    LSIAdminMenuConfig, LSIDAMMenuConfig,
    LSIDistributorAdminMenuConfig,
    LSIMerchandisingGroupConfig, LSIProductDataModelConfig, LSIReportModelConfig,
    MobileAnalyticConfig,
    MobileMessageConfig,
    PortalConfig, LSIProduceInformationConfig
} from "../../config/CMSMainMenuConfig";
import OutlinedIcon from "../icon/OutlinedIcon";
import SalesforceLink from "../button/SalesforceLink";
import {CMSContentMenuConfig} from "../../config/CMSMainMenuConfig";
import TrainingLink from "../button/TrainingLink";
import {isEditorPath, isFooterEditorPath, isHeaderEditorPath} from "../../util/PathUtil";
import {EditorBreadcrumbs} from "./EditorBreadcrumbs";
import {VerticalSeparator} from "../../cms/ui/shared/verticalseparator/VerticalSeparator";
import TopIconButton from "../button/TopIconButton";
import OrgPopover from "../popover/OrgPopover";

//@ts-ignore
const CMS_MENU = 1, PORTAL_MENU = 2, MOBILE_MENU = 3 , NO_MENU = 0 , LSI_MENU = 4;

export default function UserMenu(props) {
    const uploadContext = useContext(UploadContext);
    const loginState = useAppSelector(selectLoginState);
    const userName = loginState.userName;
    const userProfileImage = loginState.userProfileImage;
    const history = useHistory();
    const location = useLocation();
    const ssoCmd = useSSO();
    const [userMenu, setUserMenu] = useState(null);
    const orgCmdProps = useOrganization();
    const [finalOrgCmds,setFinalOrgCmds] = useState(null);
    const [showSideBar,setShowSidebar] = useState(localStorage.getItem("sideBar") === null || localStorage.getItem("sideBar") === "true");
    const [errorModels, setErrorModels] = useState([]);
    const [breadCrumb,setBreadCrumb] = useState({
       type: "",
       name: "",

    });
    const [opened, setOpened] = useState(NO_MENU);
    const sidebarRef = useRef(null);

    const handleToggleDrawer = (event) => {
        let attribute = parseInt(event.target.getAttribute("data-menu-index"));
        if(opened === NO_MENU) {
            setTimeout(() => {
                if(sidebarRef.current) {
                    sidebarRef.current.focus();
                }
            },10);

            setOpened(attribute);
        } else if(attribute !== opened) {
            setTimeout(() => {
                if(sidebarRef.current) {
                    sidebarRef.current.focus();
                }
            },10);
            setOpened(attribute);
        } else {
            setOpened(NO_MENU);
        }
    };

    const handleClosedDrawer = (event) => {
        setOpened(NO_MENU);
    }

    const onClickUserMenu = (event) => {
        if(userMenu !== event.currentTarget) {
            setUserMenu(event.currentTarget);
        }
    }

    const onUserMenuClose = () => {
        setUserMenu(null)
    }

    const onClickLogout = (event) => {
        TrackData("Logout",{
            userId: undefined,
            orgCode: undefined,
            role: undefined
        })
        history.push("/logout");
    }
    
    const onClickProfile = (event) => {
        window.location.href = process.env.REACT_APP_LSI_HOST + "#/userprofile"
    }

    const handleChangeOrg = (selectedOrg) => {
        if(selectedOrg !== null) {
            orgCmdProps.setCurrentOrg(selectedOrg);
        }
    }

    let userLetterName = null;
    if(userProfileImage === null || userName.length > 0 ) {
        let array = userName.split(" ");
        if(array.length >= 2 && array[0].length > 0 && array[1].length > 0) {
            userLetterName = array[0].charAt(0).toUpperCase() + array[1].charAt(0).toUpperCase();
        } else {
            userLetterName = userName.charAt(0).toUpperCase();
        }
    }

    useEffect(() => {
        if(!orgCmdProps) return;
        if(ssoCmd.roleName === "LSIADMIN") {
            setFinalOrgCmds(orgCmdProps.orgCmds);
        } else if(orgCmdProps.orgCmds !== null) {
            let data = orgCmdProps.orgCmds.filter(orgCmd => orgCmd.orgCode === ssoCmd.orgCode);
            if(data.length > 0) {
                setFinalOrgCmds(data);
                orgCmdProps.setCurrentOrg(data[0]);
            } else {
                setFinalOrgCmds([]);
                orgCmdProps.setCurrentOrg(null);
            }
        }
    },[orgCmdProps,ssoCmd]);


    useEffect(() => {
        if(showSideBar) {
            document.documentElement.style.setProperty("--bt-cms-sidebar-move-left","var(--bt-cms-sidebar-main-width)");
        } else {
            document.documentElement.style.setProperty("--bt-cms-sidebar-move-left","0px")
        }
    },[showSideBar]);

    useEffect(() => {
        let pathname = location.pathname;
        if(isEditorPath(pathname)) {
            let data = pathname.split("/");
            if(isHeaderEditorPath(pathname)) {
                setBreadCrumb({
                    type: "Headers",
                    name: data[2],
                })
            } else if(isFooterEditorPath(pathname)) {
                setBreadCrumb({
                    type: "Footers",
                    name: data[2],
                })
            } else {
                setBreadCrumb({
                    type: "Pages",
                    name: data[2],
                })
            }
        } else {
            setBreadCrumb({
                type: "",
                name: "",
            })
        }
    },[location])

    useEffect(() => {

        let data = axios.interceptors.response.use((response) => {
            return response
        }, (reject) => {
            if(reject.response !== undefined && reject.response.data !== undefined ) {
                try {
                    if ('message' in reject.response.data && 'body' in reject.response.data?.message) {
                        setErrorModels([
                            {errorMessage: reject.response.data?.message?.body, errorCode: reject.response.status}]);
                    } else {
                        setErrorModels([
                            {errorMessage: reject.response.data?.message, errorCode: reject.response.status}]);
                    }
                } catch (e) {
                    setErrorModels([
                        {errorMessage: reject.response.data?.message, errorCode: reject.response.status}]);
                }
            }
            return Promise.reject(reject);
        });
        return () => {
            axios.interceptors.response.eject(data);
        }
    },[]);

    const getPosition = () => {
        if(opened === CMS_MENU) return "calc(395px + 142px)";
        if(opened === PORTAL_MENU) return "calc(108px + 142px)";
        if(opened === MOBILE_MENU) return "calc(252px + 142px)";
        return undefined;
    }
    
    const getMenuUrl = () => {
        if(opened === PORTAL_MENU) return process.env.REACT_APP_PORTAL_HOST;
        if(opened === MOBILE_MENU) return process.env.REACT_APP_MOBILE_HOST;
        if(opened === LSI_MENU) return process.env.REACT_APP_LSI_HOST + "/#" ;
        return "";
    }

    const handleOnClickLogo = (event) => {
        let newShowSideBar = !showSideBar;
        localStorage.setItem("sideBar",newShowSideBar.toString());
        setShowSidebar(newShowSideBar);
        if(newShowSideBar) {
            document.documentElement.style.setProperty("--bt-cms-sidebar-move-left","var(--bt-cms-sidebar-main-width)");
        } else {
            document.documentElement.style.setProperty("--bt-cms-sidebar-move-left","0px")
        }
    }

    const handleCloseErrorMessage = () => {
        setErrorModels((l) => {
            return l.slice(1,l.length);
        });
    }

    let newMenuBar = true;


    let allMenus = [];
    let allTitles = [];

    if(opened === CMS_MENU) {
        if(hasCMSModule()) {
            let cmsSettingMenus = CMSMainMenuConfig.filter(item => item.inPageMenu && item.roles.indexOf(ssoCmd.roleName) >= 0);
            let cmsContentMenus = CMSContentMenuConfig.filter(item => item.roles.indexOf(ssoCmd.roleName) >= 0);
            if (cmsSettingMenus.length > 0) {
                if (ssoCmd.roleName === "LSIADMIN") {
                    allMenus.push([
                        {
                            id: "orgSubMenu",
                            action: "openLink",
                            icon: "corporate_fare",
                            title: "Organizations",
                            href: "/organizations",
                            roles: ["LSIADMIN"]
                        },
                        ...cmsSettingMenus]);
                    allTitles.push("SETTINGS")
                } else {
                    allMenus.push(cmsSettingMenus);
                    allTitles.push("SETTINGS")
                }
            }
            if (cmsContentMenus.length > 0) {
                if (ssoCmd.roleName === "LSIADMIN") {
                    allMenus.push(cmsContentMenus);
                    allTitles.push("CONTENT")
                }
            }
        } else {
            setOpened(NO_MENU);
        }
    } else if(opened === MOBILE_MENU) {
        let menu1 = MobileAnalyticConfig.filter(item => item.roles.indexOf(ssoCmd.roleName) >= 0);
        let menu2 = MobileMessageConfig.filter(item => item.roles.indexOf(ssoCmd.roleName) >= 0);
        if(menu1.length > 0) {
            allMenus.push(menu1);
            allTitles.push("ANALYTICS")
        }
        if(menu2.length > 0) {
            allMenus.push(menu2);
            allTitles.push("MESSAGING")
        }

        if(allMenus.length == 0) {
            window.location.href = getMenuUrl();
            setOpened(NO_MENU);
        }
    } else if(opened === PORTAL_MENU) {
        let menu1 = PortalConfig.filter(item => item.roles.indexOf(ssoCmd.roleName) >= 0);
        if(menu1.length > 0) {
            allMenus.push(menu1);
            allTitles.push("")
        }
    } else if(opened === LSI_MENU) {
        let menu1 = LSIAdminMenuConfig.filter(item => item.roles.indexOf(ssoCmd.roleName) >= 0);
        let menu2 = LSIDistributorAdminMenuConfig.filter(item => item.roles.indexOf(ssoCmd.roleName) >= 0);
        let menu3 = LSICatalogAdminConfig.filter(item => item.roles.indexOf(ssoCmd.roleName) >= 0);
        let menu3_1 = LSIProduceInformationConfig.filter(item => item.roles.indexOf(ssoCmd.roleName) >= 0);
        let menu4 = LSIMerchandisingGroupConfig.filter(item => item.roles.indexOf(ssoCmd.roleName) >= 0);
        let menu5 = LSIProductDataModelConfig.filter(item => item.roles.indexOf(ssoCmd.roleName) >= 0);
        let menu6 = LSIDAMMenuConfig.filter(item => item.roles.indexOf(ssoCmd.roleName) >= 0);
        let menu7 = LSIReportModelConfig.filter(item => item.roles.indexOf(ssoCmd.roleName) >= 0);

        if(menu1.length > 0) {
            allMenus.push(menu1);
            allTitles.push("System Administration")
        }
        if(menu2.length > 0) {
            allMenus.push(menu2);
            allTitles.push("Distributor Administration")
        }
        if(menu3.length > 0) {
            allMenus.push(menu3);
            allTitles.push("Catalog Administration")
        }
        if(menu3_1.length > 0) {
            allMenus.push(menu3_1);
            allTitles.push("Product Information Management")
        }
        if(menu4.length > 0) {
            allMenus.push(menu4);
            allTitles.push("Merchandising Group")
        }
        if(menu5.length > 0) {
            allMenus.push(menu5);
            allTitles.push("Product Data Model")
        }
        if(menu6.length > 0) {
            allMenus.push(menu6);
            allTitles.push("Digital Asset Management")
        }
        if(menu7.length > 0) {
            allMenus.push(menu7);
            allTitles.push("Reports")
        }
        if(allMenus.length == 0) {
            window.location.href = getMenuUrl();
            setOpened(NO_MENU);
        }
    }

    let hasMultiOrgs = finalOrgCmds && finalOrgCmds.length > 1
    return (
        <React.Fragment>
            {errorModels.length > 0 && errorModels.filter((errorModel,index) => index === 0).map((errorModel,index) => {
                return <Snackbar
                    key={`snackbar_${index}`}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    autoHideDuration={6000}
                    severity="error"
                    open={errorModels.length > 0}
                    onClose={handleCloseErrorMessage}
                >
                    <Alert severity={errorModel.errorCode > 299 ? "error" : "info"} onClose={handleCloseErrorMessage}>{errorModel.errorMessage ? errorModel.errorMessage.substring(0,100) : ""} ({errorModels.length})</Alert>
                </Snackbar>
            })}
            { newMenuBar && <AppBar elevation={0} position="fixed" className={styles.AppBar}>
                <Toolbar className={styles.Toolbar}>
                    <div className={styles.NewCompanyLogoPanel} onClick={handleOnClickLogo}>
                        <img src={cropped_logo} className={styles.SidebarCompanyLogo} alt="logo"/>
                    </div>
                    <div className={styles.MenuLoginPanel}>
                        <span className={breadCrumb.type !== "" ? "" : ""}>eComm 360°</span>
                        { breadCrumb.type !== "" && <EditorBreadcrumbs className={styles.BreadCrumb} small={true} type={breadCrumb.type} name={breadCrumb.name}/> }
                        {opened !== NO_MENU && <IconButton className={styles.MenuButton} id="submenuDrawerMenu" data-action={"doToggleSubMenuDrawer"} edge="start" size="large" disableRipple>
                            <SolidIcon>close</SolidIcon>
                        </IconButton>
                        }
                        {opened === NO_MENU && <IconButton id="submenuDrawerMenu" className={styles.MenuButton} data-action={"doToggleSubMenuDrawer"}  onClick={handleToggleDrawer} edge="start" size="large" disableRipple>
                            <SolidIcon>menu</SolidIcon>
                        </IconButton>
                        }
                    </div>
                    <div className={styles.PublishMenuPanel}>
                        <span className={styles.GlobalBuildButton}><BuildButton/></span>
                        <VerticalSeparator/>
                    </div>
                    {finalOrgCmds &&
                        <Grid container className={styles.NewOrgChoice}>
                            <Grid item xs={12}>
                                {finalOrgCmds && <OrgPopover orgs={finalOrgCmds}
                                                             value={orgCmdProps ? (orgCmdProps.currentOrg) : null}
                                                             onChangeOrg={(value) => handleChangeOrg(value)}
                                /> }
                                {/*{finalOrgCmds && hasMultiOrgs &&*/}
                                {/*    <Autocomplete*/}
                                {/*        fullWidth*/}
                                {/*        disablePortal*/}
                                {/*        autoHighlight*/}
                                {/*        size={"small"}*/}
                                {/*        className={styles.OrgChoiceText}*/}
                                {/*        value={orgCmdProps ? (orgCmdProps.currentOrg) : null}*/}
                                {/*        getOptionLabel={(option) => option.orgName}*/}
                                {/*        options={finalOrgCmds === null ? [] : finalOrgCmds}*/}

                                {/*        sx={{width: 300, backgroundColor: "white", borderRadius: "0.3rem"}}*/}
                                {/*        onChange={(event, value) => handleChangeOrg(value)}*/}
                                {/*        renderOption={(props, option) => {*/}
                                {/*            return <li {...props} key={option.orgCode}>{option.orgName}</li>*/}
                                {/*        }}*/}
                                {/*        renderInput={(params) => <TextField  {...params} InputProps={{*/}
                                {/*            ...params.InputProps,*/}
                                {/*            startAdornment: (*/}
                                {/*                <InputAdornment position="start">*/}
                                {/*                    <Icon className={styles.OrgLogo}>corporate_fare</Icon>*/}
                                {/*                </InputAdornment>*/}
                                {/*            ),*/}
                                {/*        }} label=""/>}*/}
                                {/*    />*/}
                                {/*}*/}
                                {/*{finalOrgCmds && finalOrgCmds.length === 1 && <Typography className={styles.OrgChoiceText} variant={"normal"}*/}
                                {/*                sx={{color: "white", fontSize: "12px"}}*/}
                                {/*                component={"span"}>*/}
                                {/*                <span className={styles.OrgChoiceIcon}><Icon className={styles.OrgLogo}>corporate_fare</Icon></span>*/}
                                {/*                <span className={styles.OrgChoiceLabel}>{finalOrgCmds[0].orgName}</span>*/}
                                {/*    </Typography>*/}
                                {/*}*/}
                            </Grid>
                        </Grid>
                    }
                    <div className={styles.NewUserActionPanel}>
                        <TopIconButton
                            tooltipTitle={"Account"}
                            onClick={onClickUserMenu}
                            size="small"
                            id="userBadgeButton"
                            className={styles.UserButton}
                            data-action={"toggleUserBadge"}
                            style={userMenu === null ? undefined : {pointerEvents: "none"}}>
                            <OutlinedIcon>manage_accounts</OutlinedIcon>
                        </TopIconButton>
                        <Popover
                            className={styles.PopOver}
                            open={userMenu !== null}
                            anchorEl={userMenu}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            style={{pointerEvents: "none"}}
                            // onClose={}
                        >
                            <div style={{pointerEvents: "auto"}}>
                                <div className={styles.NameInfoPanel}>
                                    {ssoCmd.name}
                                </div>
                                <div className={styles.OrgInfoPanel}>
                                    {ssoCmd.orgName}
                                </div>
                                <MenuItem id="userProfileMenu" data-action="openUserProfile" autoFocus={true}
                                          onBlur={onUserMenuClose} className={styles.MenuItem} onClick={onClickProfile}>
                                    <OutlinedIcon className={styles.Icon}>manage_accounts</OutlinedIcon>
                                    Profile
                                </MenuItem>

                                {/*<Divider className={styles.LinkDivider}/>*/}

                                {/*<a className={styles.Link} href={"#Support"} rel="noreferrer" >Support</a>*/}
                                <TrainingLink className={styles.Link} title={"Training"}/>
                                <SalesforceLink className={styles.Link} title={"Support"}/>
                                <Divider/>
                                <a href={"mailto:cloudlsi-support@billtrust.com"} className={styles.Link}>Contact Us</a>
                                <a href={"https://www.billtrust.com/privacy-policy/"} rel="noreferrer"  target={"_blank"} className={styles.Link}>Privacy</a>
                                <a href={"https://secure.billtrust.com/factorsystems/ig/subscriber-agreement"} rel="noreferrer"  target={"_blank"} className={styles.Link}>Terms of Service</a>
                                <Divider/>
                                <MenuItem id="logoutMenu" data-action="doLogout" className={styles.MenuItem}
                                          onClick={onClickLogout}><SolidIcon className={styles.Icon}>power_settings_new</SolidIcon>Log out</MenuItem>
                                {
                                    Object.entries(uploadContext.uploadList).map(([key, value], index) => {
                                        return <><Divider/>
                                            <div key={`upload_${value.title}`}>
                                                <div className={styles.DownloadPanel}>{value.title}</div>
                                                {/*<div className={styles.DownloadPanel}>{(value.loaded*100/value.total).toFixed(0) + "%"}</div>*/}
                                                <div className={styles.DownloadPanel}><LinearProgress
                                                    variant="determinate" value={value.loaded * 100 / value.total}/>
                                                </div>
                                            </div>
                                        </>
                                    })
                                }
                            </div>
                        </Popover>
                    </div>
                </Toolbar>

            </AppBar>}
            <div className={styles.AppBarShadow}></div>
            <Box className={styles.Sidebar}>
                <IconMenuItem id={"dashboardMenu"} active={false} icon={"home"} title={"Dashboard"} action="openDashboardApp" href={process.env.REACT_APP_DASHBOARD_HOST} ssoExchanged={"dashboard"}/>
                <IconMenuItem selected={opened === LSI_MENU} active={opened === LSI_MENU} className={opened === LSI_MENU ? styles.PointerEventOff : undefined} id={"lsiMenu"} icon={"extension"} title={"LSI+PIM"} action={"openLSIApp"}
                              // href={`${process.env.REACT_APP_CATALOG_HOST}?orgCode=${orgCmdProps?.currentOrg?.orgCode}`}
                              onClick={ () => {
                                  let element = document.getElementById("submenuDrawerMenu");
                                  if(element) {
                                      element.setAttribute("data-menu-index",`${LSI_MENU}`);
                                      element.click();
                                  }
                              }
                              }
                />
                <IconMenuItem selected={opened === MOBILE_MENU} active={opened === MOBILE_MENU} className={opened === MOBILE_MENU ? styles.PointerEventOff : undefined} id={"mobileMenu"} icon={"stay_current_portrait"} title={"Mobile"} action={"openMobileSubMenu"}
                              onClick={ () => {
                                      let element = document.getElementById("submenuDrawerMenu");
                                      if(element) {
                                          element.setAttribute("data-menu-index",`${MOBILE_MENU}`);
                                          element.click();
                                      }
                                  }
                              }

                />
                <IconMenuItem id={"pimMenu"} active={false} icon={"inventory_2"} title={"PIM"} action={"openPimApp"} href={process.env.REACT_APP_PIM2_HOST}  ssoExchanged={"pim2"}/>
                <IconMenuItem selected active={opened === CMS_MENU} className={opened === CMS_MENU ? styles.PointerEventOff : undefined} id={"cmsMenu"} icon={"settings"} title={"CMS"} action={"openCMSSubMenu"}
                              onClick={ () => {
                                  let element = document.getElementById("submenuDrawerMenu");
                                  if(element) {
                                      element.setAttribute("data-menu-index",`${CMS_MENU}`);
                                      element.click();
                                  }
                                }
                              }

                />
            </Box>

            {    allMenus.length > 0 && <Box ref={sidebarRef} tabIndex={-1} onBlur={handleClosedDrawer}
                     className={styles.Drawer}
                     style={ opened !== NO_MENU ?   {left: "calc(var(--bt-cms-sidebar-move-left) + 0px)", opacity: "1",  pointerEvents: "all", transition: "opacity 0.3s ease-out"} :
                                        {left: "calc(var(--bt-cms-sidebar-move-left) - 0px)", opacity: "0", pointerEvents: "none", transition: "opacity 0.0s ease-out"}
                     }>
                    <Box className={styles.DrawerTopAction}>
                        <IconButton onClick={handleToggleDrawer}><OutlinedIcon>arrow_back_ios_new</OutlinedIcon></IconButton>
                    </Box>
                    <Box className={styles.DrawerType}>
                        { opened === CMS_MENU && "CMS" }
                        { opened === PORTAL_MENU && "PORTAL" }
                        { opened === MOBILE_MENU && "MOBILE" }
                        { opened === LSI_MENU && "LSI+PIM" }
                    </Box>
                    { allMenus.length > 0 && allMenus.map((sectionMenu,section) => <React.Fragment key={"section_" + section}>
                        { allTitles[section] !== "" && <Box className={styles.DrawerTopicTitle + " " + styles.ContentTitle}>
                            <Typography variant={"body1"}>{allTitles[section]}</Typography>
                        </Box> }
                        <ul>
                            {sectionMenu.map((item,index) => <DrawerMenuItem id={item.id}
                                                                            key={"menu" + section + "_" + index}
                                                                            action={item.action}
                                                                            icon={item.icon}
                                                                            title={item.title}
                                                                             href={getMenuUrl() + item.href + (opened === LSI_MENU ? `?orgCode=${orgCmdProps?.currentOrg?.orgCode}` : "")}
                                                                            doCloseDrawer={handleClosedDrawer}/>)}
                        </ul>
                    </React.Fragment>)
                    }
                </Box>
            }
        </React.Fragment>
    );
}
