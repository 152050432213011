import styles from "./PIM.module.scss"
import commonStyle from "../../config/Common.module.scss";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import ProductSearchField from "../productsearchfield/ProductSearchField";
import OutlinedIcon from "../icon/OutlinedIcon";
import {useOrganization} from "../../hook/useOrganization";
import {Divider, IconButton, LinearProgress, MenuItem, Popover, TextField, Tooltip} from "@mui/material";
import HttpClient from "../../datafetcher/HttpClient";
import {lsi} from "../../datafetcher/LSIDataFetcher";
import LSIFieldCmd from "../../model/lsi/LSIFieldCmd";
import {nanoid} from "nanoid";
import {InputOption} from "../../model/InputModel";
import SearchFilter from "../../model/lsi/SearchFilter";
import {portal} from "../../datafetcher/PortalDataFetcher";
import {AxiosResponse} from "axios";
import PortalProductDTO from "../../model/portal/product/PortalProductDTO";
import PageSummary from "../pagesummary/PageSummary";
import MemberCoreCmd from "../../model/lsi/MemberCoreCmd";


interface FacetStat {
    name: string,
    count: number,
    percent: number,
    field: LSIFieldCmd,
    total: number,
}

export default function PIM() {
    const  orgCmdProp = useOrganization();
    const [columns, setColumns] = useState<string[]>([]);
    const [memberCores,setMemberCores] = useState<string[]>([]);
    const [currentCore, setCurrentCore] = useState<string>("");
    const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set<string>()); // show detail
    const [lsiFields, setLsiFields] = useState<LSIFieldCmd[]>([]);
    const [fieldEl, setFieldEl] = useState<HTMLElement|null>(null);
    const [currentFacet, setCurrentFacets] = useState<FacetStat[]>([]);
    const [searchParams, setSearchParams] = useState<undefined| {
        searchId: string
        generalSearch: string,
        filters:SearchFilter[]}>();
    const [searchFields, setSearchFields] = useState<{
        fields: Set<string>
        options: InputOption[]}>({
                            fields: new Set(),
                            options: []
        }
    );
    const [fieldFilter, setFieldFilter] = useState<{ field: string, regex: RegExp}>({
        field: "",
        regex: new RegExp("")
    });
    const tableRef = useRef<any>();

    const [erpData,setErpData] = useState<Map<string,any>>(new Map());
    const [loadedData, setLoadedData] = useState<undefined | {
        numFound: number,
        numLoaded: number,
        docs: any[],
        id: string,
    }>();
    const [more,setMore] = useState<any>();

    const loadRef = useCallback((node) => {
        setMore(node);
    },[])

    const loadMore = useCallback(() => {
        if(searchParams && loadedData) {
            lsi.search.query(searchParams.generalSearch, loadedData.numLoaded, 200, "", currentCore, "ec2PWeb", "ec2PWeb",lsi.util.filters2fqs(searchParams.filters)).then((response) => {
                let array = [...loadedData.docs];
                //array.push(response.data.response.docs);
                for(let d of response.data.response.docs) {
                    array.push(d);
                }
                more.setAttribute("data-loading","false")
                setLoadedData({
                    docs: array,
                    numFound: response.data.response.numFound,
                    numLoaded: array.length,
                    id: nanoid(),
                })
            })
        }

    },[currentCore,loadedData,more,searchParams]);


    useEffect(() => {
        if(more === undefined || more === null) return;
        let options = {
            root: document.getElementById("pim"),
            rootMargin: '0px',
            threshold: 1.0
        }
        let observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if(entry.isIntersecting) {
                    if(more.getAttribute("data-loading") !== "true") {
                        more.setAttribute("data-loading","true");
                        loadMore();
                    }
                }
            });
        }, options);
        observer.observe(more);
        return () => {
            observer.disconnect();
        }
    },[more,loadMore])


    useEffect(() => {

        if(orgCmdProp && orgCmdProp.orgDetail) {
            HttpClient.get("/api/v0/jcr/org/" + orgCmdProp?.currentOrg!.orgCode + "/lite/storefronts").then((response) => {
                if(response.data) {
                    let memberCores = new Set<string>();

                    let asyncArray = [];

                    if(orgCmdProp.orgDetail?.lsiConfig.memberCore) {
                        memberCores.add(orgCmdProp.orgDetail?.lsiConfig.memberCore);
                    }
                    //let newSfOptions: InputOption[] = [];
                    response.data.response.forEach((object:any) => {
                        if(object.sfMemberCore && object.sfMemberCore !== "") {
                            memberCores.add(object.sfMemberCore);
                            memberCores.add(object.sfMemberCore);
                        }
                    })
                    // let array = Array.from(memberCores)
                    // if(array.length > 0) {
                    //     array.push(array[0]);
                    // }
                    // if(array.length > 0 && currentCore === "") {
                    //     setCurrentCore(array[0]);
                    // }
                    // setMemberCores(array);
                    let memberCoress: any[] = [];
                    lsi.schema.membercores().then((response) => {
                        initObjects();
                        memberCoress = response.data;
                        let memberArray: any = [];
                        console.log(response.data);
                        memberCoress.forEach((object:any) => {
                            if(object.type === 'member'){
                                memberArray.push(object.externalId);
                            }
                        })
                        if(memberArray.length > 0 && currentCore === "") {
                            setCurrentCore(memberArray[0]);
                        }
                        setMemberCores(memberArray);
                    });
                }
            });
        }
    },[orgCmdProp,currentCore]);

    function initObjects() {
        setFieldFilter({
            field: "",
            regex: new RegExp("")
        })
        setColumns(["_portal_id_plain_", "_lsi_cn_Name", "_lsi_cn_Description"])
        setSelectedItems(new Set());
    }

    const searchOptionToSet = (searchOptions: InputOption[]) => {
        let rValue = new Set<string>();
        for (let searchOption of searchOptions) {
           rValue.add(searchOption.value);
        }
        return rValue;
    }

    useEffect(() => {
        if(currentCore !== "") {
            if(currentCore !== lsi.memberCore) {
                lsi.schema.allfields(currentCore).then((response) => {
                    initObjects();
                    setLsiFields(response.data); // TODO setSearchFields

                });
            } else {
                if(orgCmdProp?.orgDetail) {
                    if(orgCmdProp?.orgDetail.lsiFields) {
                        initObjects();
                        setSearchFields({
                            fields: searchOptionToSet(orgCmdProp!.orgDetail!.searchFieldOptions),
                            options: orgCmdProp!.orgDetail!.searchFieldOptions});
                        setLsiFields(orgCmdProp?.orgDetail.lsiFields)
                    }
                }
            }
        }
    },[orgCmdProp,currentCore])




    useEffect(() => {

        const resize_ob = new ResizeObserver(function(entries) {
            // since we are observing only a single element, so we access the first element in entries array
            let rect = entries[0].contentRect;

            // current width & height
            let width = rect.width;
            let parent = document.getElementById("pim_scroller_panel");
            if(parent !== null && tableRef.current) {
                parent.style.width = tableRef.current.parentElement.clientWidth + "px";
            }
            let scroller = document.getElementById("pim_scroller");
            if(scroller !== null ) {
                scroller.style.width = width + "px";
            }

        });

        if(tableRef && tableRef.current) {
            resize_ob.observe(tableRef.current);
        }
        let currentRef = tableRef;
        return () => {
            if(currentRef && currentRef.current) resize_ob.unobserve(currentRef.current);
        }
    },[tableRef])

    const handleEnterAddField = (event:React.MouseEvent<HTMLElement>) => {
        event.currentTarget.querySelector(`span:nth-of-type(2)`)!.querySelectorAll("span").forEach((element) => {
            element!.style.opacity = "1.0";
        })
    }

    const handleLevelAddField = (event:React.MouseEvent<HTMLElement>) => {
        event.currentTarget.querySelector(`span:nth-of-type(2)`)!.querySelectorAll("span").forEach((element) => {
            element!.style.opacity = "0";
        })
    }

    const handleEnterTableTitle = (event:React.MouseEvent<HTMLTableHeaderCellElement>) => {
        event.currentTarget.querySelector(`span`)!.querySelectorAll("span").forEach((element) => {
            element!.style.opacity = "1.0";
        })
    }

    const handleLeaveTableTitle = (event:React.MouseEvent<HTMLTableHeaderCellElement>) => {
        event.currentTarget.querySelector(`span`)!.querySelectorAll("span").forEach((element) => {
            element!.style.opacity = "0";
        })
    }

    const handleClickClear = (event:React.MouseEvent<HTMLSpanElement>) => {
        let index = parseInt(event.currentTarget.getAttribute("data-index")!);
        let newColumns = [...columns];
        newColumns.splice(index,1);
        setColumns(newColumns);
    }

    const handleClickMoveFieldNext = (event:React.MouseEvent<HTMLSpanElement>) => {
        let index = parseInt(event.currentTarget.getAttribute("data-index")!);
        let newColumns = [...columns];
        let next = index + 1;
        if(next < columns.length) {
            let temp = newColumns[index];
            newColumns[index] = newColumns[next];
            newColumns[next] = temp;
        }
        setColumns(newColumns)
    }

    const handleClickMoveFieldBack = (event:React.MouseEvent<HTMLSpanElement>) => {
        let index = parseInt(event.currentTarget.getAttribute("data-index")!);
        let newColumns = [...columns];
        let next = index - 1;
        if(next < columns.length) {
            let temp = newColumns[index];
            newColumns[index] = newColumns[next];
            newColumns[next] = temp;
        }
        setColumns(newColumns)
    }

    const handleAddFilter = (lsiField: LSIFieldCmd , value: string) => {
        let newSearchParams = {
            searchId: nanoid(),
            generalSearch: searchParams!.generalSearch,
            filters: [...searchParams!.filters,{
                field: lsiField.name,
                value: `"${value.replaceAll("\"","\\\"")}"`,
                operator: "is",
                isEnabled: true
            }]
        }
        setSelectedItems(new Set());
        setSearchParams(newSearchParams)
        lsi.search.query(newSearchParams.generalSearch,0,200,"",currentCore,"ec2PWeb","ec2PWeb", lsi.util.filters2fqs(newSearchParams.filters)).then((response) => {
            setLoadedData({
                numFound: response.data.response.numFound,
                numLoaded: response.data.response.docs.length,
                docs: response.data.response.docs,
                id: nanoid(),
            })
        })
    }

    const handleAddFieldToFilter = (event: React.MouseEvent<HTMLElement>) => {
        let key:string = event.currentTarget.getAttribute("data-key") as string;
        let index:number = parseInt(event.currentTarget.getAttribute("data-index") as string);
        let value = loadedData!.docs[index][key];
        let newSearchParams;
        if(Array.isArray(value)) {
            let newValue = "";
            for(let v of value) {
                if(newValue.length > 0) {
                    newValue += " OR "
                }
                newValue += `"${v.replaceAll("\"", "\\\"")}"`
            }
            newSearchParams = {
                searchId: nanoid(),
                generalSearch: searchParams!.generalSearch,
                filters: [...searchParams!.filters, {
                    field: key,
                    value: newValue,
                    operator: "is",
                    isEnabled: true
                }]
            }
        } else {
            newSearchParams = {
                searchId: nanoid(),
                generalSearch: searchParams!.generalSearch,
                filters: [...searchParams!.filters, {
                    field: key,
                    value: `"${value.replaceAll("\"", "\\\"")}"`,
                    operator: "is",
                    isEnabled: true
                }]
            }
        }
        setSelectedItems(new Set());
        setSearchParams(newSearchParams)
        lsi.search.query(newSearchParams.generalSearch,0,200,"",currentCore,"ec2PWeb","ec2PWeb", lsi.util.filters2fqs(newSearchParams.filters)).then((response) => {
            setLoadedData({
                numFound: response.data.response.numFound,
                numLoaded: response.data.response.docs.length,
                docs: response.data.response.docs,
                id: nanoid(),
            })
        })
    }

    const handleAddFieldToTable = (event: React.MouseEvent<HTMLElement>) => {
        let key:string = event.currentTarget.getAttribute("data-key") as string;
        if(columns.indexOf(key)  < 0) {
            setColumns([...columns,key]);
        }
    }






    const handleAddToSelectedList = (data: string) => {
        if(selectedItems.has(data)) {
            let newSelectedItems = new Set<string>(selectedItems);
            newSelectedItems.delete(data);
            setSelectedItems(newSelectedItems);
        } else {
            let newSelectedItems = new Set<string>(selectedItems);
            newSelectedItems.add(data);
            setSelectedItems(newSelectedItems);
        }
    }

    const handleScrollFromScroller = (event:any) => {
        tableRef.current.parentElement.scrollLeft = event.currentTarget.scrollLeft;
    }

    const handleScrollFromParent = (event: any) => {
        document.getElementById("pim_scroller_panel")!.scrollLeft = event.currentTarget.scrollLeft;
    }

    const showFields = useMemo(() => {

        const handleAddField = (event:React.MouseEvent<HTMLElement>,lsiField:LSIFieldCmd) => {

            event.stopPropagation();
            event.preventDefault();

            setColumns([...columns,lsiField.name]);
        }

        const handleFieldInfo = (event:React.MouseEvent<HTMLElement>, lsiField:LSIFieldCmd) => {

            event.stopPropagation();
            event.preventDefault();

            let target = event.currentTarget;
            if(searchParams) {
                lsi.search.facetQuery(searchParams.generalSearch,
                    0,
                    0,
                    "",
                    currentCore,
                    "ec2PWeb",
                    "ec2PWeb",
                    lsi.util.filters2fqs(searchParams.filters),
                    lsiField.name).then((response) => {
                    let data = response.data.facet_counts.facet_fields[lsiField.name];
                    if(data) {
                        let newData:FacetStat[] = [];
                        let total = 0;
                        for(let i=1; i < data.length;i += 2) {
                            total += data[i]
                        }
                        for(let i=0;i<20 && i < data.length;i+=2) {
                            newData.push({
                                name: data[i],
                                count: data[i+1],
                                percent: data[i+1]/total*100,
                                field: lsiField,
                                total: total
                            })
                        }
                        setCurrentFacets(newData);
                        setFieldEl(target);
                    }
                })
            }
        }

        let data = lsiFields.filter((field,index) => fieldFilter.field !== "" &&
            field.mode !== "dynamic" &&
            field.name.toLowerCase().match(fieldFilter.regex));
        const maxItems = 200;
        let tooMany = data.length > maxItems;
        if(tooMany) {
            data = data.slice(0,maxItems);
        }

        return <>{
            data.map((field, index) => {
                    return <li key={`add_field_${index}`} className={styles.AddPanel}
                               onClick={(event) => handleFieldInfo(event, field)} onMouseEnter={handleEnterAddField}
                               onMouseLeave={handleLevelAddField}>
                        <Tooltip disableInteractive={true} title={field.name}><span>{field.name}</span></Tooltip>
                        <span className={styles.AddIconPanel}>
                        <OutlinedIcon data-index={index} className={styles.AddIcon}
                                      onClick={(event) => handleAddField(event, field)}>add_circle</OutlinedIcon>
                    </span>
                    </li>
                }
            )
        }
            { tooMany && <span className={styles.PIMSearchFieldTooManyMessage}>Too many match, Keep refine your search</span>}
        </>
    },[lsiFields,fieldFilter,columns,searchParams,currentCore]);

    function loadERP(response: AxiosResponse<any>) {
        if (response.data.response.docs.length > 0) {
            let productIds: string[] = [];
            for (let doc of response.data.response.docs as any[]) {
                let t = doc as any;
                let key = t['_lsi_id_'] as string;
                if(erpData.has(key) === false) {
                    productIds.push(key);
                }
            }

            if(portal.isReady() && productIds.length > 0) {
                portal.product.list(productIds).then(response => {
                    let newMap = new Map(erpData);
                    let products = response.data.dtos as PortalProductDTO[];
                    for (let product of products) {
                        newMap.set(product.partNumber, product);
                    }
                    setErpData(newMap);
                })
            }
        }
    }

    return <React.Fragment>

        <div id="pim" className={commonStyle.ContentContainer}>
            <PageSummary type={"pim"} title={"Product Discovery"} desc={""}/>
            <div className={styles.PIMSearchPanel}>
                <ProductSearchField
                    key={searchParams ? searchParams.searchId : nanoid()}
                    searchFields={searchFields.options}
                    generalSearch={searchParams ? searchParams.generalSearch : ""}
                    filters={searchParams ? searchParams.filters : []}
                    onChange={(generalSearch,filters) => {
                            setSearchParams({searchId: searchParams ? searchParams.searchId : nanoid(), generalSearch: generalSearch, filters: filters})
                            lsi.search.query(generalSearch,0,200,"",currentCore,"ec2PWeb","ec2PWeb", lsi.util.filters2fqs(filters)).then((response) => {

                                // loadERP(response);

                                setLoadedData({
                                    numFound: response.data.response.numFound,
                                    numLoaded: response.data.response.docs.length,
                                    docs: response.data.response.docs,
                                    id: nanoid(),
                                })
                            })
                        }
                    }
                    />
            </div>
            <div className={styles.PIMSearchDetail}>
                <div className={styles.PIMSearchOption}>
                    <div>
                        { currentCore !== "" && memberCores.length > 0 && <TextField
                            label="Core"
                            fullWidth
                            size={"small"}
                            variant="outlined"
                            value={currentCore}
                            InputLabelProps={{style: {fontSize: 12}}}
                            InputProps={{style: {fontSize: 12}}}
                            select
                            onChange={(event) => {
                                setCurrentCore(event.target.value)
                            }}
                        >
                            {memberCores.map((memberCore) => (
                                <MenuItem style={{fontSize: 12}} key={memberCore} value={memberCore}>
                                    {memberCore}
                                </MenuItem>
                            ))}
                        </TextField>}
                    </div>
                    <div className={styles.PIMAvailableField}>
                        <TextField
                            label="Search field names"
                            fullWidth
                            size={"small"}
                            variant="outlined"
                            value={fieldFilter.field}
                            InputLabelProps={{ style: { fontSize: 12 } }}
                            InputProps={{style: {fontSize: 12}}}
                            onChange={(event) => {
                                try {
                                    setFieldFilter({
                                        field: event.target.value,
                                        regex: new RegExp(event.target.value.toLowerCase())
                                    });
                                } catch {
                                    setFieldFilter({
                                        field: event.target.value,
                                        regex: new RegExp("_don't_want_to_match_anything_")
                                    });
                                }
                            }}
                        />
                    </div>
                    <ul className={styles.PIMFieldPanel}>
                        { showFields }
                    </ul>
                </div>
                <div className={styles.PIMSearchResult} onScroll={handleScrollFromParent}>
                    { (loadedData === undefined || loadedData.docs.length === 0) && false && <div className={styles.PIMSearchNotFound}>
                        <OutlinedIcon>support</OutlinedIcon><span className={styles.PIMSearchNotFoundLabel}> No results match your search criteria</span>
                    </div> }
                    {
                        loadedData !== undefined &&  loadedData.docs.length !== 0 && <table ref={tableRef} className={styles.PIMTable}>
                            <thead><tr>
                                <th></th>
                                { columns.map((column,cIndex) => {
                                    return <th key={`header_${cIndex}`} onMouseEnter={handleEnterTableTitle} onMouseLeave={handleLeaveTableTitle} >
                                        <span>{column}
                                            { cIndex > 0 && <OutlinedIcon  id="removeColumnButton" data-action={`removeColumn_${column}`} data-index={cIndex} onClick={handleClickClear} className={styles.HeaderIcon}>clear</OutlinedIcon>}
                                            { cIndex > 1 && <OutlinedIcon id="moveColumnToLeftButton" data-action={`moveColumnToLeft_${column}`} data-index={cIndex} onClick={handleClickMoveFieldBack} className={styles.HeaderIcon}>keyboard_double_arrow_left</OutlinedIcon>}
                                            { cIndex >= 1 && cIndex + 1 < columns.length && <OutlinedIcon id={`moveColumnToRightButton_${column}`} data-action="moveColumnToRight" data-index={cIndex} onClick={handleClickMoveFieldNext} className={styles.HeaderIcon}>keyboard_double_arrow_right</OutlinedIcon>}
                                        </span>
                                    </th>
                                })}
                            </tr></thead>
                            <tbody>
                            {loadedData.docs.map((doc,rIndex) => {
                                    let isSelected =  selectedItems.has(doc['_lsi_id_']);
                                    return <React.Fragment  key={`row_${rIndex}`}>
                                        <tr>
                                            <td><IconButton onClick={() => handleAddToSelectedList(doc['_lsi_id_'])}
                                                            style={{
                                                                padding: "3px",
                                                                transform: isSelected ? "rotate(90deg)" : "rotate(0deg)"
                                                            }}><OutlinedIcon
                                                style={{fontSize: "14px"}}>chevron_right</OutlinedIcon></IconButton></td>
                                            {
                                                columns.map((column, cIndex) => {
                                                    return <td key={`column_${rIndex}_${cIndex}`}>{doc[column]}</td>
                                                })
                                            }
                                        </tr>
                                        {
                                            isSelected && <tr><td colSpan={columns.length+1}>
                                                <table className={styles.PIMDetail}>
                                                    <tbody>

                                                    {
                                                        Object.entries(doc)
                                                            .sort(([a,],[b,]) => a.localeCompare(b))
                                                            .map(([key,value],index) => {
                                                            let isSearchable = searchFields.fields.has(key);
                                                            return <tr key={`detail_${index}`}>
                                                                <td onMouseEnter={handleEnterTableTitle} onMouseLeave={handleLeaveTableTitle}>
                                                                    <span>
                                                                        <OutlinedIcon data-index={rIndex} data-key={key}  onClick={isSearchable ? handleAddFieldToFilter : undefined} className={isSearchable ? styles.DetailIcon : styles.DetailIconDisable}>content_paste_search</OutlinedIcon>
                                                                        <OutlinedIcon data-index={rIndex} data-key={key}  onClick={handleAddFieldToTable} className={styles.DetailIcon}>add_chart</OutlinedIcon>
                                                                        {key}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    {Array.isArray(doc[key]) ? `[ ${doc[key].join(", ")} ]`: doc[key]}
                                                                </td>
                                                            </tr>
                                                        })
                                                    }
                                                    {
                                                        erpData.has(doc['_lsi_id_']) && <tr>
                                                            <td>
                                                                <span style={{ paddingLeft: "40px"}}>ERP</span>
                                                            </td>
                                                            <td>
                                                                <code>
                                                                    {JSON.stringify(erpData.get(doc['_lsi_id_']),null,'2')}
                                                                </code>
                                                            </td>
                                                        </tr>
                                                    }
                                                    </tbody>
                                                </table>
                                            </td></tr>
                                        }
                                    </React.Fragment>
                                }
                            )}
                            { loadedData.numFound !== loadedData.numLoaded && <tr>
                                    <td ref={loadRef} key={loadedData.id} className={styles.PIMLoadMore} colSpan={columns.length+1}> Loading more products...</td>
                                </tr>
                            }
                            </tbody>
                        </table>
                    }
                    <div id="pim_scroller_panel" className={styles.PIMSearchScrollPanel} onScroll={handleScrollFromScroller}>
                        <div id="pim_scroller"></div>
                    </div>
                </div>
            </div>
        </div>

        <Popover open={fieldEl !== null}
                 anchorEl={fieldEl}
                 anchorOrigin={{
                     vertical: 'center',
                     horizontal: 'right',
                 }}
                 onClose={() => {
                     setFieldEl(null);
                 }}
        ><div className={styles.FacetPanel}>
            { currentFacet.length > 0 && <div className={styles.FacetTitle}>{currentFacet[0].field.name}</div>}
            { currentFacet.length > 0 && <Divider/> }
            { currentFacet.length > 0 && <div className={styles.FacetSubTitle}>{currentFacet[0].total} records</div>}
            { currentFacet.length > 0 && currentFacet.map((facet:FacetStat,index:number) => {
                return <React.Fragment key={`facet_${index}`}>
                    <div className={styles.FacetLabel1}>
                        { searchFields.fields.has(facet.field.name) && <OutlinedIcon className={styles.FacetIcon} onClick={() => handleAddFilter(facet.field,facet.name)}>add_circle</OutlinedIcon> }
                        {facet.name}
                    </div>
                    <div className={styles.FacetLabel2}>{facet.percent.toFixed(0)}%</div>
                    <div className={styles.FacetProgress}>
                        <LinearProgress variant="determinate" value={facet.percent}/>
                    </div>
                </React.Fragment>
            })}
            {currentFacet.length === 0 && <>No data</>}
        </div>
        </Popover>
    </React.Fragment>
}
