import {lsi} from "../../datafetcher/LSIDataFetcher";
import {useSSO} from "../../datafetcher/HttpClient";

interface SalesforceButtonProp {
    className: string
    title: string
    relayUrl?: string
}

export default function SalesforceLink(props: SalesforceButtonProp) {
    const sso = useSSO();

    const handleClick = () => {

        lsi.salesforce.loginToken().then((response:any) => {
            const samlLogin = document.createElement("form");
            samlLogin.method = "POST";
            samlLogin.content = "text/html; charset=utf-8";
            samlLogin.name = "secure_redirect_form";
            samlLogin.id = "samlLogin";
            samlLogin.action = response.data.endpoint;
            samlLogin.target= "_blank";

            let hiddenInput = document.createElement("input");
            hiddenInput.type = "hidden";
            hiddenInput.id = "SAMLResponse";
            hiddenInput.name = "SAMLResponse";
            hiddenInput.value = response.data.token;
            samlLogin.appendChild(hiddenInput);

            if(props.relayUrl) {
                let relayInput = document.createElement("input")
                relayInput.type = "hidden"
                relayInput.id = "RelayState"
                relayInput.name = "RelayState"
                // if (this.user.roleName == "LSIADMIN") {
                //     relayInput.value = this.salesforceService.adminUrl
                // } else {
                //     relayInput.value = this.salesforceService.communityUrl
                // }
                relayInput.value =  props.relayUrl;
                samlLogin.appendChild(relayInput);
            }


            document.body.appendChild(samlLogin);
            samlLogin.submit();
            setTimeout(() => {
                samlLogin.remove();
            },1000);
        }).catch(error => {
            console.log(error)
        })


    }

    if(sso.salesforceId && sso.salesforceId !== "") {
        return <a className={props.className} rel="noreferrer" onClick={handleClick}>{props.title}</a>
    } else {
        return <></>
    }
}