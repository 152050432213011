import styles from "./DAM.module.scss"
import {
    Box,
    Breadcrumbs,
    Divider,
    IconButton, Tooltip,
    Typography
} from "@mui/material";
import Link from "../button/Link";
import LinkButton from "../button/LinkButton";
import NewPopover from "../popover/NewPopover";
import {lsi} from "../../datafetcher/LSIDataFetcher";
import OutlinedIcon from "../icon/OutlinedIcon";
import {useCallback, useEffect, useState} from "react";
import {PDAMFile} from "./PDAM";
import PortalEnvironmentPopover from "../popover/PortalEnvironmentPopover";

export function PDAMFolders(props: {
    portalEnvironment: string,
    siteCode: string
    currentPath: string
    canSwitchEnvironment: boolean,
    onChangePath: (path:string) => void
}) {
    const [folders,setFolders] = useState<PDAMFile[]>([]);

    const updateFolder = useCallback(() => {
        setFolders([]);
        lsi.pdam.listFolder(props.currentPath).then((response) => {
            let data:PDAMFile[] = response.data.response as PDAMFile[];
            setFolders(data);
        })
    },[props.currentPath,props.portalEnvironment])


    let {currentPath,siteCode} = props;
    let folderNames:string[] = [];

    useEffect(() => {
        //if(props.currentPath !== "") {
            updateFolder();
        //}
    },[updateFolder]);



    if(currentPath.endsWith("/") === false) {
        currentPath = currentPath + "/";
    }

    for(let i=0;i<folders.length;i++) {
        folderNames.push(folders[i].name);
    }


    let links:JSX.Element[] = [];

    links.push(<Link key={"dam_b_root"} underline={"none"} onClick={() => {
        setTimeout(() => {
            props.onChangePath("/")
        },100);
    }}>{siteCode}</Link>);

    if(currentPath !== "/") {
        let currentPaths = currentPath.split("/");
        let tempPath = ""
        for (let i = 0; i < currentPaths.length; i++) {
            if(currentPaths[i] === "") continue;
            tempPath += "/" + currentPaths[i];
            let x = tempPath;
            links.push(<Link key={"dam_b_" + i} underline={"none"} onClick={() => {
                setTimeout(() => {
                    props.onChangePath(x)
                },100);

            }}>{currentPaths[i]}</Link>)
        }
    }

    const validator = (value:string): string => {
        if(value === "") {
            return "";
        } else if(folderNames.indexOf(value) >= 0) {
            return "This name is already in use.";
        } else if(!value.match(/^[A-Za-z0-9_ !\-_.*'()&$@=;+,:]+$/)) { //  /^[A-Za-z0-9_ !-_.*'()/&$@=;+,:]+$/
            return "FolderName must contain only [a-z ][A-Z][0-9][ !-_.*'()/&$@=;+,?:]"
        } else {
            return "";
        }
    }

    const handleAddNew = (newValue:string) => {
        let newFolderPath = currentPath  + newValue;
        return lsi.pdam.mkdir(newFolderPath).then((response) => {
            updateFolder();
        });
    }

    const handleRemoveCurrentFolder = () => {
        if(window.confirm("Do you really want to delete this folder?")) {
            if (currentPath !== "/") {
                let nextFolder = currentPath;
                if (nextFolder.endsWith("/")) {
                    nextFolder = nextFolder.substring(0, nextFolder.length - 1);
                    let index = nextFolder.lastIndexOf("/");
                    lsi.pdam.rmdir(currentPath).then(() => {
                        props.onChangePath(nextFolder.substring(0, index + 1));
                    });

                }
            }
        }
    }

    return <Box className={styles.DAMPanel} sx={{boxShadow: 2}}>
        <div>
            <div className={styles.DAMActionPanel}>
                {props.canSwitchEnvironment && <PortalEnvironmentPopover tooltipPlacement={"top"}
                                          tooltipTitle={"Web Environment"}
                                          regularIcon={true}
                                          onChange={() => {
                                              setTimeout(() => {
                                                  props.onChangePath("/")
                                              },100);
                                            }
                                          }
                                          /> }
                <NewPopover icon={"create_new_folder"}
                            title={"New Folder"}
                            id={"newFolderButton"}
                            action={"doCreateFolder"}
                            validator={ validator }
                            onAddNew={handleAddNew}/>
                { currentPath !== "/" && <Tooltip title={`Remove Current Folder ${currentPath}`}>
                    <IconButton onClick={handleRemoveCurrentFolder}><OutlinedIcon>folder_off</OutlinedIcon></IconButton>
                </Tooltip>}
            </div>
            <Breadcrumbs separator="›" aria-label="breadcrumb"  className={styles.DAMBreadcrumb}>
                <OutlinedIcon>storage</OutlinedIcon>
                {links}
            </Breadcrumbs>
        </div>
        {   folderNames.length > 0 &&
            <>
            <Divider/>
            <div className={styles.DAMSubFolder}>
                <Typography className={styles.DAMSubFolderTitle}>Subfolders</Typography>
                {folderNames.map((folderName, index) => <LinkButton key={"dam_folder_" + folderName + "_index"}
                                                                    className={styles.DAMButton} onClick={() => {
                    props.onChangePath(currentPath + folderName)
                }}>{folderName}</LinkButton>)}
            </div>
            </>
        }
    </Box>
}