import {EditableProps} from "../../shared/editable/EditableProps";
import {SectionInterface} from "../../../model/SectionInterface";
import {useAppSelector} from "../../../../redux/hook";
import {selectViewState} from "../../../../redux/features/view/viewSlice";
import {DEFAULT_PADDING_INLINE, doAssignNewIds, doViewGenerator} from "../../../util/UIFactory";
import styles from './DefaultSection.module.scss';
import {NewType} from "../../../model/NewOption";
import {DeleteType} from "../../../model/DeleteOption";
import EditablePanel, {SetFocusLevelStr, SetOverLevel} from "../../shared/editable/EditablePanel";
import {SettingChangeFunction} from "../../setting/SettingChange";
import {StyleUtil} from "../../setting/StyleUtil";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {nanoid} from "nanoid";
import useWidgetState from "../../../../hook/useWidgetState";
import useWindowSize from "../../../hook/useWindowSize";
import _ from "lodash";
import NewLabel from "../../shared/newlabel/NewLabel";
import {DefaultColumnSettingGenerator, DefaultSettingGenerator} from "./DefaultSectionUtil";
import {Box} from "@mui/material";
import DefaultColumn from "./DefaultColumn";
import VariableContext from "../../../../context/VariableContext";
import {SaveCopiedObject, SetCopiedObject} from "../../../../ui/editor/LiveEditor";

export const DEFAULTSECTION_MAX_COLUMNS = 5;
export default function DefaultSection(props: EditableProps) {
    const windowSize = useWindowSize();
    const sectionData:SectionInterface = props.data as SectionInterface;
    const {sectionId,sectionProps} = sectionData;
    const viewState = useAppSelector(selectViewState);
    const [refreshId,setRefreshId] = useState(nanoid());
    const variableContent = useContext(VariableContext);

    useWidgetState(sectionId,(event) => {;
        setRefreshId(nanoid());
    })

    const [views , setViews] = useState<JSX.Element[]|null>(null);


    useEffect(() => {
        setViews(doViewGenerator(sectionData.sectionWidgets,
            props.uiLevel+1,
            props.uiLevelStr,
            props.sharedObjects,
            props.onHandleSetting,
            props.onHandleDeletion,
            props.onHandleAddNewSectionOrWidget,
            props.onHandleSwap,
            props.onHandleUpdate,
            props.onHandleClone,
            props.onHandleCopy,
            props.onHandleCut,
            props.onHandleCreateTemplate,
            props.updateWidgetData,
            props.onInsertSection,
            props.onInsertWidget
        ));
    },[refreshId])

    if(!sectionProps['content']) {
        sectionProps['content'] = {
            layout: "horizontal",
            columns: 1,
        }
    }

    const getDefaultColumnWidth = (col: string,maxCol: number) => {

        if(col === undefined || col === null || col === "") {
            return Math.round(100/maxCol) + "%"
        } else {
            return col;
        }
    }

    const getRealColumnWidth = (colWidth: string,gap: string,columns: number) => {

        if(gap && gap.length > 0 && colWidth.endsWith("%")) {
            return `calc(${colWidth} - (${gap} * ${columns-1})/${columns})`
        } else {
            return colWidth
        }
    }

    const gridTemplate5Columns = (col1:string,col2:string,col3:string,col4:string,col5:string,gap:string) => {
        col1 = getDefaultColumnWidth(col1,5);
        col2 = getDefaultColumnWidth(col2,5);
        col3 = getDefaultColumnWidth(col3,5);
        col4 = getDefaultColumnWidth(col4,5);
        col5 = getDefaultColumnWidth(col5,5);


        if (col1 === col2 && col3 === col1 && col4 === col1 && col5 === col1) {
            return `repeat(auto-fill,${getRealColumnWidth(col1,gap,5)})`
        } else {
            return `${getRealColumnWidth(col1,gap,5)} ${getRealColumnWidth(col2,gap,5)} ${getRealColumnWidth(col3,gap,5)} ${getRealColumnWidth(col4,gap,5)} ${getRealColumnWidth(col4,gap,5)}`;
        }

    }


    const gridTemplate4Columns = (col1:string,col2:string,col3:string,col4:string,gap:string) => {
        col1 = getDefaultColumnWidth(col1,4);
        col2 = getDefaultColumnWidth(col2,4);
        col3 = getDefaultColumnWidth(col3,4);
        col4 = getDefaultColumnWidth(col4,4);

        if (col1 === col2 && col3 === col1 && col4 === col1) {
            return `repeat(auto-fill,${getRealColumnWidth(col1,gap,4)})`
        } else {
            return `${getRealColumnWidth(col1,gap,4)} ${getRealColumnWidth(col2,gap,4)} ${getRealColumnWidth(col3,gap,4)} ${getRealColumnWidth(col4,gap,4)}`;
        }
    }

    const gridTemplate3Columns = (col1:string,col2:string,col3:string,gap:string) => {
        col1 = getDefaultColumnWidth(col1,3);
        col2 = getDefaultColumnWidth(col2,3);
        col3 = getDefaultColumnWidth(col3,3);

        if (col1 === col2 && col3 === col1) {
            return `repeat(auto-fill,${getRealColumnWidth(col1,gap,3)})`
        } else {
            return `${getRealColumnWidth(col1,gap,3)} ${getRealColumnWidth(col2,gap,3)} ${getRealColumnWidth(col3,gap,3)}`;
        }
    }

    const gridTemplate2Columns = (col1:string,col2:string,gap:string) => {
        col1 = getDefaultColumnWidth(col1,2);
        col2 = getDefaultColumnWidth(col2,2);

        if (col1 === col2 ) {
            return `repeat(auto-fill,${getRealColumnWidth(col1,gap,2)})`
        } else {
            return `${getRealColumnWidth(col1,gap,2)} ${getRealColumnWidth(col2,gap,2)}`;
        }
    }

    const getFlowPanel = (sectionProps:any) : string => {
        if(sectionProps['content']['layout'] === "horizontal") {
            if(sectionProps['content']['columns'] === 1) {
                return StyleUtil.toClassFromMap(sectionId, "flow_panel",{
                    "grid-auto-flow": "column",
                    "grid-template-columns" : sectionProps['content']['column1-width'],
                    "gap" : sectionProps['content']['gap'],
                    //"align-items": sectionProps['content']['align-items'],
                    "justify-content": sectionProps['content']['justify-content'],
                })
            } else if(sectionProps['content']['columns'] === 2) {
                return StyleUtil.toClassFromMap(sectionId, "flow_panel",{
                    "grid-auto-flow": "column",
                    "grid-template-columns" : gridTemplate2Columns(sectionProps['content']['column1-width'] ,
                                                        sectionProps['content']['column2-width'],
                                                        sectionProps['content']['gap']),
                    "gap" : sectionProps['content']['gap'],
                    "align-items": sectionProps['content']['align-items'],
                    //"justify-content": sectionProps['content']['justify-content'],
                })
            } else if(sectionProps['content']['columns'] === 3) {
                return StyleUtil.toClassFromMap(sectionId, "flow_panel",{
                    "grid-template-columns" : gridTemplate3Columns(sectionProps['content']['column1-width'] ,
                                                            sectionProps['content']['column2-width'] ,
                                                            sectionProps['content']['column3-width'],
                                                            sectionProps['content']['gap']),
                    "grid-auto-flow":"column",
                    "gap" : sectionProps['content']['gap'],
                    "align-items": sectionProps['content']['align-items'],
                    // "justify-content": sectionProps['content']['justify-content'],
                })
            } else if(sectionProps['content']['columns'] === 4) {
                return StyleUtil.toClassFromMap(sectionId, "flow_panel",{
                    "grid-template-columns" : gridTemplate4Columns(sectionProps['content']['column1-width'] ,
                                                            sectionProps['content']['column2-width'] ,
                                                            sectionProps['content']['column3-width'],
                                                            sectionProps['content']['column4-width'],
                                                            sectionProps['content']['gap']),
                    "grid-auto-flow":"column",
                    "gap" : sectionProps['content']['gap'],
                    "align-items": sectionProps['content']['align-items'],
                    // "justify-content": sectionProps['content']['justify-content'],
                })
            } else if(sectionProps['content']['columns'] >= 5) {
                return StyleUtil.toClassFromMap(sectionId, "flow_panel",{
                    "grid-template-columns" : gridTemplate5Columns(sectionProps['content']['column1-width'] ,
                                                            sectionProps['content']['column2-width'] ,
                                                            sectionProps['content']['column3-width'],
                                                            sectionProps['content']['column4-width'],
                                                            sectionProps['content']['column5-width'],
                                                            sectionProps['content']['gap']
                        ),
                    "grid-auto-flow":"column",
                    "gap" : sectionProps['content']['gap'],
                    "align-items": sectionProps['content']['align-items'],
                    // "justify-content": sectionProps['content']['justify-content'],
                })
            } else {
                return StyleUtil.toClassFromMap(sectionId, "flow_panel",{
                    "grid-template-columns" : gridTemplate3Columns(sectionProps['content']['column1-width'] ,
                                                    sectionProps['content']['column2-width'] ,
                                                    sectionProps['content']['column3-width'],
                                                    sectionProps['content']['gap']),
                    "grid-auto-flow":"column",
                    "gap" : sectionProps['content']['gap']
                })
            }
        } else { // vertical
            return StyleUtil.toClassFromMap(sectionId, "flow_panel",{
                "grid-auto-flow": "row",
                "grid-auto-rows": "minmax(min-content, max-content)",
                "grid-template-columns" : "100%",
                "gap" : sectionProps['content']['gap'],
                //"align-items": sectionProps['content']['align-items'],
                "justify-content": sectionProps['content']['justify-content'],
            })
        }
    }

    if(props.uiLevel == 0) {
        if (StyleUtil.isDefaultStyle(sectionProps['bt_defaultsection'])) {
            sectionProps['bt_defaultsection'] = {
                style: {
                    default: true,
                    padding: {
                        'padding-inline-start': DEFAULT_PADDING_INLINE,
                        'padding-inline-end': DEFAULT_PADDING_INLINE,
                        'padding-block-start': DEFAULT_PADDING_INLINE,
                        'padding-block-end': DEFAULT_PADDING_INLINE,
                    }
                }
            }
        }
    }

    const themeName = "defaultsection";
    const sectionStyles = {
        theme: StyleUtil.toThemeNameByType(sectionProps, themeName),
        current: StyleUtil.toClassFromWidget(sectionId, sectionProps, "current_style"),
        panel: StyleUtil.toClassFromWidget(sectionId , sectionProps, "bt_defaultsection"),
        col1: StyleUtil.toClassFromWidget(sectionId , sectionProps, "bt_ds_col1"),
        col2: StyleUtil.toClassFromWidget(sectionId , sectionProps, "bt_ds_col2"),
        col3: StyleUtil.toClassFromWidget(sectionId , sectionProps, "bt_ds_col3"),
        col4: StyleUtil.toClassFromWidget(sectionId , sectionProps, "bt_ds_col4"),
        col5: StyleUtil.toClassFromWidget(sectionId , sectionProps, "bt_ds_col5"),
        flow_panel: getFlowPanel(sectionProps)
    }

    const handleDeleteColumn = (index: number) => {
        SetOverLevel("");
        SetFocusLevelStr("")
        let newSectionData:any = _.cloneDeep(sectionData);
        let widgetProps = newSectionData['sectionProps'];
        widgetProps['content']['columns']--;
        newSectionData['sectionWidgets'].splice(index, 1);
        updateSize(widgetProps, newSectionData);
    }

    const handleAddColumn = (index: number) => {
        SetOverLevel("");
        SetFocusLevelStr("")
        let newSectionData:any = _.cloneDeep(sectionData);
        let sectionProps = newSectionData['sectionProps'];
        sectionProps['content']['columns']++
        newSectionData['sectionWidgets'].splice(index+1, 0 , null);
        updateSize(sectionProps, newSectionData);
    }

    const handleCloneColumn = (index: number) => {
        SetOverLevel("");
        SetFocusLevelStr("")

        let newSectionData:any = _.cloneDeep(sectionData);
        let sectionProps = newSectionData['sectionProps'];
        let sectionWidgets: any[] = newSectionData['sectionWidgets'];
        if(sectionProps['content']['columns'] < DEFAULTSECTION_MAX_COLUMNS) {
            sectionProps['content']['columns']++;
            newSectionData['sectionWidgets'].splice(index+1, 0 , null);
            newSectionData['sectionWidgets'][index+1] = doAssignNewIds(_.cloneDeep(newSectionData['sectionWidgets'][index]));
            updateSize(sectionProps, newSectionData);
        } else { // replace empty column
            for(let i=index;i<sectionProps['content']['columns'];i++) {
                if(newSectionData['sectionWidgets'][i] === null || newSectionData['sectionWidgets'][i] === undefined) {
                    newSectionData['sectionWidgets'][i] = doAssignNewIds(_.cloneDeep(newSectionData['sectionWidgets'][index]));
                    props.updateWidgetData({
                        widgetId: sectionId,
                        widgetData: newSectionData,
                        action: null,
                        value: null
                    });
                    break;
                }
            }
        }
    }

    const handleCopyColumn = (index: number) => {
        let sectionProps = sectionData['sectionProps'];
        let sectionWidgets: any[] = sectionData['sectionWidgets'];
        SetCopiedObject(sectionWidgets[index]);
        SaveCopiedObject();
    }

    const handleColumnSetting = (index: number) => {

        const suggestTags: string[] = [
            "current",
        ]
        let columns = sectionProps['content']['columns'];
        for(let i=0;i<columns;i++) {
            suggestTags.push(`current .col${i+1}`);
        }
        const {settingOptions, settingGroups} = DefaultColumnSettingGenerator(index);

        props.onHandleSetting(themeName,
            "Column Settings",
            props.uiLevelStr,
            suggestTags,
            settingGroups,
            settingOptions,
            (props.data as SectionInterface).sectionId,
            props.data,
            (props.data as SectionInterface).sectionProps,
            null,
            true,
            variableContent.portalThemeName,
            variableContent.siteCode);
    }

    function updateSize(widgetProps:any, newSectionData: any) {

        let vertical: boolean = widgetProps['content']['layout'] === "vertical";
        let value = widgetProps['content']['columns'];
        if (vertical) {
            widgetProps["content"][`column1-width`] = "100%";
            widgetProps["content"][`column2-width`] = "100%";
            widgetProps["content"][`column3-width`] = "100%";
            widgetProps["content"][`column4-width`] = "100%";
            widgetProps["content"][`column5-width`] = "100%";
        } else {
            if (value === 1) {
                widgetProps["content"][`column1-width`] = "100%";
            } else if (value === 2) {
                widgetProps["content"][`column2-width`] = "50%";
                widgetProps["content"][`column1-width`] = "50%";
            } else if (value === 3) {
                widgetProps["content"][`column3-width`] = "33%";
                widgetProps["content"][`column1-width`] = "33%";
                widgetProps["content"][`column2-width`] = "33%";
            } else if (value === 4) {
                widgetProps["content"][`column4-width`] = "25%";
                widgetProps["content"][`column1-width`] = "25%";
                widgetProps["content"][`column2-width`] = "25%";
                widgetProps["content"][`column3-width`] = "25%";
            } else if (value === 5) {
                widgetProps["content"][`column5-width`] = "20%";
                widgetProps["content"][`column1-width`] = "20%";
                widgetProps["content"][`column2-width`] = "20%";
                widgetProps["content"][`column3-width`] = "20%";
                widgetProps["content"][`column4-width`] = "20%";
            }
        }

        props.updateWidgetData({
            widgetId: sectionId,
            widgetData: newSectionData,
            action: null,
            value: null
        });
    }

    const ensureScreen = (node:any) => {
        let rootElement = node;
        let rootBoundary = rootElement.getBoundingClientRect();
        if(rootBoundary.width !== 0) {
            let layoutBreak = sectionProps['content']['layout-break'];
            if(!layoutBreak) {
                layoutBreak = 700;
            }
            if (rootBoundary.width < layoutBreak) {
                rootElement.style.gridTemplateColumns = '100%'
                rootElement.style.gridAutoFlow = "row"
            } else {
                rootElement.style.gridTemplateColumns = null;
            }
        } else {
            setTimeout(() => {
                ensureScreen(node);
            },100);
        }
    }

    const rootRef = useCallback((node) => {
        if(windowSize && node) {
            ensureScreen(node);
        }
    },[windowSize]);


    if(views === null) return <></>

    const ensureViews = (views: any[],start:number, skip:number) => {
        if(views.length === 0) {
            return createEmptyPanel("empty_sub_view_" + nanoid(),"label_" +nanoid(), start);
        } else {
            return views.map((view, index) => view.key === null ? createEmptyPanel("horizontal_sub_view_" + nanoid(),
                "label_" + nanoid(),
                start + (skip * index)) : view);
        }
    }

    const ensureView = (view: any,index:number) => {
        if(view) {
            if(view.key === null) {
                return createEmptyPanel("vertical_sub_view_" + nanoid(), "label_" + nanoid(),index);
            } else {
                return view;
            }
        } else {
            createEmptyPanel("vertical_empty_sub_view_" + nanoid(),"label_" +nanoid(), index);
        }
    }

    const isEnableSetting = (column: number) => {

        if(column >= sectionData.sectionWidgets.length) {
            return props.uiLevel == 0;
        } else if(sectionData.sectionWidgets[column] === null || sectionData.sectionWidgets[column] === undefined) {
            return props.uiLevel == 0;
        } else {
            if('sectionId' in sectionData.sectionWidgets[column]) {
                return false;
            } else {
                return props.uiLevel == 0;
            }
        }
    }
    const createTwoColumn = () => {
        let columns = Math.min(sectionProps['content']['columns'],DEFAULTSECTION_MAX_COLUMNS);
        let sectionStyle = sectionProps['content']['sectionWidth'] === "boxed" ? "wrap" : "";
        return <Box component={props.uiLevel === 0 ? "section" : "div"}
            key={refreshId}
            ref={rootRef}
            className={
                StyleUtil.combineClasses(styles.Root, sectionStyles.theme, sectionStyles.current, sectionStyles.panel, sectionStyles.flow_panel,sectionStyle)
            }
        >
            <DefaultColumn uiLevelStr={`${props.uiLevelStr}@0`}
                           handleDeleteColumn={() => handleDeleteColumn(0)}
                           handleAddColumn={() => handleAddColumn(0)}
                           handleCloneColumn={() => handleCloneColumn(0)}
                           handleCopyColumn={() => handleCopyColumn(0)}
                           handleColumnSetting={() => handleColumnSetting(0)}
                           column={0}
                           enabledSetting={isEnableSetting(0)}
                           onInsertSection={props.onInsertSection}
                           onInsertWidget={props.onInsertWidget}
                className={StyleUtil.combineClasses(styles.Col,"col1",sectionStyles.col1)}>{views.length >= 1 ? ensureViews(views.filter((view, index) => index % 2 === 0  && index < columns), 0, 2) : createEmptyPanel("horizontal_sub_view_1", "1st column", 0)}</DefaultColumn>
            <DefaultColumn uiLevelStr={`${props.uiLevelStr}@1`}
                           handleDeleteColumn={() => handleDeleteColumn(1)}
                           handleAddColumn={() => handleAddColumn(1)}
                           handleColumnSetting={() => handleColumnSetting(1)}
                           handleCloneColumn={() => handleCloneColumn(1)}
                           handleCopyColumn={() => handleCopyColumn(1)}
                           enabledSetting={isEnableSetting(1)}
                           column={1}
                           onInsertSection={props.onInsertSection}
                           onInsertWidget={props.onInsertWidget}
                className={StyleUtil.combineClasses(styles.Col,"col2",sectionStyles.col2)}>{views.length >= 2 ? ensureViews(views.filter((view, index) => index % 2 === 1  && index < columns), 1, 2) : createEmptyPanel("horizontal_sub_view_2", "2nd column", 1)}</DefaultColumn>
        </Box>
    }


    const createThreeColumn = () => {
        let columns = Math.min(sectionProps['content']['columns'],DEFAULTSECTION_MAX_COLUMNS);
        let sectionStyle = sectionProps['content']['sectionWidth'] === "boxed" ? "wrap" : "";
        return <Box component={props.uiLevel === 0 ? "section" : "div"}
            key={refreshId}
            ref={rootRef}
            id={"test"}
            className={
                StyleUtil.combineClasses(styles.Root, sectionStyles.theme, sectionStyles.current, sectionStyles.panel, sectionStyles.flow_panel,sectionStyle)
            }
        >
            <DefaultColumn uiLevelStr={`${props.uiLevelStr}@0`} handleDeleteColumn={() => handleDeleteColumn(0)}
                           handleAddColumn={() => handleAddColumn(0)}
                           handleColumnSetting={() => handleColumnSetting(0)}
                           handleCloneColumn={() => handleCloneColumn(0)}
                           handleCopyColumn={() => handleCopyColumn(0)}
                           enabledSetting={isEnableSetting(0)}
                           column={0}
                           onInsertSection={props.onInsertSection}
                           onInsertWidget={props.onInsertWidget}
                className={StyleUtil.combineClasses(styles.Col,"col1",sectionStyles.col1)}>{views.length >= 1 ? ensureViews(views.filter((view, index) => index % 3 === 0 && index < columns), 0, 3) : createEmptyPanel("horizontal_sub_view_1", "1st column", 0)}</DefaultColumn>
            <DefaultColumn uiLevelStr={`${props.uiLevelStr}@1`} handleDeleteColumn={() => handleDeleteColumn(1)}
                           handleAddColumn={() => handleAddColumn(1)}
                           handleColumnSetting={() => handleColumnSetting(1)}
                           handleCloneColumn={() => handleCloneColumn(1)}
                           handleCopyColumn={() => handleCopyColumn(1)}
                           enabledSetting={isEnableSetting(1)}
                           column={1}
                           onInsertSection={props.onInsertSection}
                           onInsertWidget={props.onInsertWidget}
                className={StyleUtil.combineClasses(styles.Col,"col2",sectionStyles.col2)}>{views.length >= 2 ? ensureViews(views.filter((view, index) => index % 3 === 1 && index < columns), 1, 3) : createEmptyPanel("horizontal_sub_view_2", "2nd column", 1)}</DefaultColumn>
            <DefaultColumn uiLevelStr={`${props.uiLevelStr}@2`} handleDeleteColumn={() => handleDeleteColumn(2)}
                           handleAddColumn={() => handleAddColumn(2)}
                           handleColumnSetting={() => handleColumnSetting(2)}
                           handleCloneColumn={() => handleCloneColumn(2)}
                           handleCopyColumn={() => handleCopyColumn(2)}
                           enabledSetting={isEnableSetting(2)}
                           column={2}
                           onInsertSection={props.onInsertSection}
                           onInsertWidget={props.onInsertWidget}
                className={StyleUtil.combineClasses(styles.Col,"col3",sectionStyles.col3)}>{views.length >= 3 ? ensureViews(views.filter((view, index) => index % 3 === 2 && index < columns), 2, 3) : createEmptyPanel("horizontal_sub_view_3", "3rd column", 2)}</DefaultColumn>
        </Box>
    }

    const createFourColumn = () => {
        let columns = Math.min(sectionProps['content']['columns'],DEFAULTSECTION_MAX_COLUMNS);
        let sectionStyle = sectionProps['content']['sectionWidth'] === "boxed" ? "wrap" : "";
        return <Box component={props.uiLevel === 0 ? "section" : "div"}
            key={refreshId}
            ref={rootRef}
            id={"test"}
            className={
                StyleUtil.combineClasses(styles.Root, sectionStyles.theme, sectionStyles.current, sectionStyles.panel, sectionStyles.flow_panel, sectionStyle)
            }
        >
            <DefaultColumn uiLevelStr={`${props.uiLevelStr}@0`} handleDeleteColumn={() => handleDeleteColumn(0)}
                           handleAddColumn={() => handleAddColumn(0)}
                           handleColumnSetting={() => handleColumnSetting(0)}
                           handleCloneColumn={() => handleCloneColumn(0)}
                           handleCopyColumn={() => handleCopyColumn(0)}
                           enabledSetting={isEnableSetting(0)}
                           column={0}
                           onInsertSection={props.onInsertSection}
                           onInsertWidget={props.onInsertWidget}
                className={StyleUtil.combineClasses(styles.Col,"col1",sectionStyles.col1)}>{views.length >= 1 ? ensureViews(views.filter((view, index) => index % 4 === 0 && index < columns), 0, 4) : createEmptyPanel("horizontal_sub_view_1", "1st column", 0)}</DefaultColumn>
            <DefaultColumn uiLevelStr={`${props.uiLevelStr}@1`} handleDeleteColumn={() => handleDeleteColumn(1)}
                           handleAddColumn={() => handleAddColumn(1)}
                           handleColumnSetting={() => handleColumnSetting(1)}
                           handleCloneColumn={() => handleCloneColumn(1)}
                           handleCopyColumn={() => handleCopyColumn(1)}
                           enabledSetting={isEnableSetting(1)}
                           column={1}
                           onInsertSection={props.onInsertSection}
                           onInsertWidget={props.onInsertWidget}
                className={StyleUtil.combineClasses(styles.Col,"col2",sectionStyles.col2)}>{views.length >= 2 ? ensureViews(views.filter((view, index) => index % 4 === 1 && index < columns), 1, 4) : createEmptyPanel("horizontal_sub_view_2", "2nd column", 1)}</DefaultColumn>
            <DefaultColumn uiLevelStr={`${props.uiLevelStr}@2`} handleDeleteColumn={() => handleDeleteColumn(2)}
                           handleAddColumn={() => handleAddColumn(2)}
                           handleColumnSetting={() => handleColumnSetting(2)}
                           handleCloneColumn={() => handleCloneColumn(2)}
                           handleCopyColumn={() => handleCopyColumn(2)}
                           enabledSetting={isEnableSetting(2)}
                           column={2}
                           onInsertSection={props.onInsertSection}
                           onInsertWidget={props.onInsertWidget}
                className={StyleUtil.combineClasses(styles.Col,"col3",sectionStyles.col3)}>{views.length >= 3 ? ensureViews(views.filter((view, index) => index % 4 === 2 && index < columns), 2, 4) : createEmptyPanel("horizontal_sub_view_3", "3rd column", 2)}</DefaultColumn>
            <DefaultColumn uiLevelStr={`${props.uiLevelStr}@3`} handleDeleteColumn={() => handleDeleteColumn(3)}
                           handleAddColumn={() => handleAddColumn(3)}
                           handleColumnSetting={() => handleColumnSetting(3)}
                           handleCloneColumn={() => handleCloneColumn(3)}
                           handleCopyColumn={() => handleCopyColumn(3)}
                           enabledSetting={isEnableSetting(3)}
                           column={3}
                           onInsertSection={props.onInsertSection}
                           onInsertWidget={props.onInsertWidget}
                className={StyleUtil.combineClasses(styles.Col,"col4",sectionStyles.col4)}>{views.length >= 4 ? ensureViews(views.filter((view, index) => index % 4 === 3 && index < columns), 3, 4) : createEmptyPanel("horizontal_sub_view_4", "4th column", 3)}</DefaultColumn>
        </Box>
    }

    const createFiveColumn = () => {
        let columns = Math.min(sectionProps['content']['columns'],DEFAULTSECTION_MAX_COLUMNS);
        let sectionStyle = sectionProps['content']['sectionWidth'] === "boxed" ? "wrap" : "";
        return <Box component={props.uiLevel === 0 ? "section" : "div"}
            key={refreshId}
            ref={rootRef}
            id={"test"}
            className={
                StyleUtil.combineClasses(styles.Root, sectionStyles.theme, sectionStyles.current, sectionStyles.panel, sectionStyles.flow_panel, sectionStyle)
            }
        >
            <DefaultColumn uiLevelStr={`$${props.uiLevelStr}@0`}
                           handleDeleteColumn={() => handleDeleteColumn(0)}
                           handleColumnSetting={() => handleColumnSetting(0)}
                           handleCloneColumn={() => handleCloneColumn(0)}
                           handleCopyColumn={() => handleCopyColumn(0)}
                           enabledSetting={isEnableSetting(0)}
                           column={0}
                           onInsertSection={props.onInsertSection}
                           onInsertWidget={props.onInsertWidget}
                           className={StyleUtil.combineClasses(styles.Col,"col1",sectionStyles.col1)}>{views.length >= 1 ? ensureViews(views.filter((view, index) => index % 5 === 0 && index < columns), 0, 5) : createEmptyPanel("horizontal_sub_view_1", "2nd column", 0)}</DefaultColumn>
            <DefaultColumn uiLevelStr={`$${props.uiLevelStr}@1`}
                           handleDeleteColumn={() => handleDeleteColumn(1)}
                           handleColumnSetting={() => handleColumnSetting(1)}
                           handleCloneColumn={() => handleCloneColumn(1)}
                           handleCopyColumn={() => handleCopyColumn(1)}
                           enabledSetting={isEnableSetting(1)}
                           column={1}
                           onInsertSection={props.onInsertSection}
                           onInsertWidget={props.onInsertWidget}
                className={StyleUtil.combineClasses(styles.Col,"col2",sectionStyles.col2)}>{views.length >= 2 ? ensureViews(views.filter((view, index) => index % 5 === 1 && index < columns), 1, 5) : createEmptyPanel("horizontal_sub_view_2", "2nd column", 1)}</DefaultColumn>
            <DefaultColumn uiLevelStr={`${props.uiLevelStr}@2`}
                           handleDeleteColumn={() => handleDeleteColumn(2)}
                           handleColumnSetting={() => handleColumnSetting(2)}
                           handleCloneColumn={() => handleCloneColumn(2)}
                           handleCopyColumn={() => handleCopyColumn(2)}
                           enabledSetting={isEnableSetting(2)}
                           column={2}
                           onInsertSection={props.onInsertSection}
                           onInsertWidget={props.onInsertWidget}
                className={StyleUtil.combineClasses(styles.Col,"col3",sectionStyles.col3)}>{views.length >= 3 ? ensureViews(views.filter((view, index) => index % 5 === 2 && index < columns), 2, 5) : createEmptyPanel("horizontal_sub_view_3", "3rd column", 2)}</DefaultColumn>
            <DefaultColumn uiLevelStr={`${props.uiLevelStr}@3`}
                           handleDeleteColumn={() => handleDeleteColumn(3)}
                           handleColumnSetting={() => handleColumnSetting(3)}
                           handleCloneColumn={() => handleCloneColumn(3)}
                           handleCopyColumn={() => handleCopyColumn(3)}
                           enabledSetting={isEnableSetting(3)}
                           column={3}
                           onInsertSection={props.onInsertSection}
                           onInsertWidget={props.onInsertWidget}
                className={StyleUtil.combineClasses(styles.Col,"col4",sectionStyles.col4)}>{views.length >= 4 ? ensureViews(views.filter((view, index) => index % 5 === 3 && index < columns), 3, 5) : createEmptyPanel("horizontal_sub_view_4", "4th column", 3)}</DefaultColumn>
            <DefaultColumn uiLevelStr={`${props.uiLevelStr}@4`}
                           handleDeleteColumn={() => handleDeleteColumn(4)}
                           handleColumnSetting={() => handleColumnSetting(4)}
                           handleCloneColumn={() => handleCloneColumn(4)}
                           handleCopyColumn={() => handleCopyColumn(4)}
                           enabledSetting={isEnableSetting(4)}
                           column={4}
                           onInsertSection={props.onInsertSection}
                           onInsertWidget={props.onInsertWidget}
                className={StyleUtil.combineClasses(styles.Col,"col5",sectionStyles.col5)}>{views.length >= 5 ? ensureViews(views.filter((view, index) => index % 5 === 4 && index < columns), 4, 5) : createEmptyPanel("horizontal_sub_view_5", "5th column", 4)}</DefaultColumn>
        </Box>
    }

    const createVertical = () => {

        let columns = Math.min(sectionProps['content']['columns'],DEFAULTSECTION_MAX_COLUMNS);
        let sectionStyle = sectionProps['content']['sectionWidth'] === "boxed" ? "wrap" : "";
        return <Box component={props.uiLevel === 0 ? "section" : "div"} key={refreshId} data-ui-type={props.uiType}
                 className={StyleUtil.combineClasses(styles.Root,sectionStyles.theme,sectionStyles.current,sectionStyles.panel,sectionStyles.flow_panel,sectionStyle)}
                 ref={rootRef}
        >
            {
                _.range(0,columns).map(index => {
                    if(views[index]) {
                        return <DefaultColumn uiLevelStr={`${props.uiLevelStr}@${index}`}
                                              handleAddColumn={() => handleAddColumn(index)}
                                              handleDeleteColumn={columns >= 1 ? () => handleDeleteColumn(index) : undefined}
                                              handleColumnSetting={() => handleColumnSetting(index)}
                                              handleCloneColumn={() => handleCloneColumn(index)}
                                              handleCopyColumn={() => handleCopyColumn(index)}
                                              enabledSetting={isEnableSetting(index)}
                                              column={index}
                                              onInsertSection={props.onInsertSection}
                                              onInsertWidget={props.onInsertWidget}
                            key={"vertical_sub_view_" + index}
                            className={StyleUtil.combineClasses(styles.Col,`col${index + 1}`, (sectionStyles as any)[`col${index + 1}`])}
                            style={{
                                width: getDefaultColumnWidth(sectionProps['content'][`column${(index % columns) + 1}-width`], columns),
                                justifySelf: sectionProps['content'][`justify-content`]
                            }
                            }>{ensureView(views[index], index)}</DefaultColumn>
                    } else {
                        return <DefaultColumn uiLevelStr={`${props.uiLevelStr}@${index}`}
                                              handleAddColumn={() => handleAddColumn(index)}
                                              handleDeleteColumn={columns >= 1 ? () => handleDeleteColumn(index) : undefined}
                                              handleColumnSetting={() => handleColumnSetting(index)}
                                              handleCloneColumn={() => handleCloneColumn(index)}
                                              handleCopyColumn={() => handleCopyColumn(index)}
                                              enabledSetting={isEnableSetting(index)}
                                              column={index}
                                              onInsertSection={props.onInsertSection}
                                              onInsertWidget={props.onInsertWidget}
                                              key={"vertical_sub_view_" + index}
                                              className={StyleUtil.combineClasses(styles.Col,`col${index + 1}`, (sectionStyles as any)[`col${index + 1}`])}
                                              style={{
                                                  width: getDefaultColumnWidth(sectionProps['content'][`column${(index % columns) + 1}-width`], columns),
                                                  justifySelf: sectionProps['content'][`justify-content`]
                                              }
                                              }>
                            {createEmptyPanel("vertical_sub_view_" + index, index + "th column", index)}
                        </DefaultColumn>
                    }
                })
            }
            {/*{*/}
            {/*    views.filter((view,index) => index < columns).map((view,index) => {*/}
            {/*            return <div*/}
            {/*                key={"vertical_sub_view_" + index}*/}
            {/*                className={StyleUtil.combineClasses(`col${index + 1}`, (sectionStyles as any)[`col${index + 1}`])}*/}
            {/*                style={{*/}
            {/*                    width: getDefaultColumnWidth(sectionProps['content'][`column${(index % columns) + 1}-width`], columns),*/}
            {/*                    justifySelf: sectionProps['content'][`justify-content`]*/}
            {/*                }*/}
            {/*                }>{ensureView(view, index)}</div>*/}
            {/*        }*/}
            {/*    )*/}
            {/*}*/}
            {/*{views && views.length < columns && _.range(views.length,columns).map(index => {*/}
            {/*    return createEmptyPanel("vertical_sub_view_" + index,"Next View",index)*/}
            {/*}) }*/}
        </Box>
    }

    const createEmptyPanel = (key:string,label:string,index:number) => {
        if(viewState.isEdit) {
            return <NewLabel key={`newlabel_${key}`}
                             label={label}
                             onHandleCut={props.onHandleCut}
                             uiLevelStr={props.uiLevelStr + "_" + (index)}
                             onInsertSection={props.onInsertSection}
                             onInsertWidget={props.onInsertWidget}/>
        } else {
            return <React.Fragment key={key}></React.Fragment>
        }
    }

    const createDefaultSection = () => {

        let layout = sectionProps['content']['layout'];
        let columns = Math.min(sectionProps['content']['columns'],DEFAULTSECTION_MAX_COLUMNS);
        return <>
            {layout === "vertical" && createVertical()}
            {layout === "horizontal" && columns === 1 && createVertical()}
            {layout === "horizontal" && columns === 2 && createTwoColumn()}
            {layout === "horizontal" && columns === 3 && createThreeColumn()}
            {layout === "horizontal" && columns === 4 && createFourColumn()}
            {layout === "horizontal" && columns >= 5 && createFiveColumn()}
        </>
    }

    if(viewState.isEdit) {
        const onChangeSettings: SettingChangeFunction|null = null;
        const suggestTags: string[] = [
            "current",
        ]

        let columns = Math.min(sectionProps['content']['columns'],DEFAULTSECTION_MAX_COLUMNS);
        for(let i=0;i<columns;i++) {
            suggestTags.push(`current .col${i+1}`);
        }
        const {settingOptions, settingGroups} = DefaultSettingGenerator(columns);

        return (
            <EditablePanel {...props}
                           themeName={themeName}
                           newUILevelStr={props.uiLevelStr + "_" + (views.length)}
                           newOption={{ type: NewType.widget_or_section, tag: null}}
                           onHandleAddNewSectionOrWidget={props.onHandleAddNewSectionOrWidget}
                           suggestTags={suggestTags}
                           settingOptions={settingOptions}
                           settingGroups={settingGroups}
                           onChangeSettings={onChangeSettings}
                           deleteOption={{type: DeleteType.delete_with_rearrange, tag: null}}
                           >
                {createDefaultSection()}
            </EditablePanel>
        )
    } else {
        return createDefaultSection();
    }
}
