import {CardCmd} from "../../../model/CardCmd";
import {Grid, TextField} from "@mui/material";
import React, {useState} from "react";
import AssetField from "../../../../ui/assetfield/AssetField";
import {nanoid} from "nanoid";
import TinyField from "../../../../ui/editor/TinyField";


let globalTimeout:NodeJS.Timeout|null = null;
let globalRefreshId:string = "";
let globalBlur: any;
let globalData: CardCmd;
let globalIndex: number = 0;
export default function CardActionSetting(props: {
        value: CardCmd,
        onChange?: (newValue: CardCmd) => void,
        onBlur?: (newValue: CardCmd) => void,
        index: number
    }
) {
    const [cmd,setCmd] = useState<CardCmd>(props.value);
    const [refreshId] = useState<string>(nanoid());

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        globalTimeout = setTimeout(() => {
            if(globalBlur) globalBlur(globalData)
        },300);

    }

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        if(globalTimeout) {
            clearTimeout(globalTimeout);
            globalTimeout = null;
        }
        if(globalIndex != props.index) {
            if (globalBlur) globalBlur(globalData)
        }
        globalIndex = props.index;
        if(globalRefreshId !== ""  && refreshId !== globalRefreshId) {
            if(globalBlur) globalBlur(globalData)
        }
        globalBlur = props.onBlur;
        globalData = cmd;
    }

    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <TextField
                size={"small"}
                fullWidth
                value={cmd.title}
                inputProps={{sx: {paddingBlock: "5px"}}}
                InputLabelProps={{shrink: true}}
                label={"Title"}
                onChange={(event) => {
                    let newCmd = {...cmd , title: event.target.value}
                    setCmd(newCmd);
                    if(props.onChange) {
                        props.onChange(newCmd);
                        globalData = newCmd;
                    }
                }}
                onBlur={handleBlur}
                onFocus={handleFocus}
            />
        </Grid>
        <Grid item xs={12}>
            <AssetField size={"small"}
                        InputLabelProps={{shrink: true}}
                        fullWidth label={"Image URL"}
                        value={cmd.image}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        onChange={(event) => {
                            let newCmd = {...cmd , image: event.target.value}
                            setCmd(newCmd);
                            if(props.onChange) {
                                props.onChange({...cmd , image: event.target.value.trim()});
                                globalData = newCmd;
                            }
                        }}/>
        </Grid>

        <Grid item xs={12}>
            <TextField size={"small"}
                        InputLabelProps={{shrink: true}}
                        fullWidth label={"Image Link URL"}
                        value={cmd.image_href}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        onChange={(event) => {
                            let newCmd = {...cmd , image_href: event.target.value}
                            setCmd(newCmd);
                            if(props.onChange) {
                                props.onChange({...cmd , image_href: event.target.value.trim()});
                                globalData = newCmd;
                            }
                        }}/>
        </Grid>
        <Grid item xs={12}>
            <TinyField value={cmd.description}
                    onChange={(value:string) => {
                        let newCmd = {...cmd , description: value}
                        setCmd(newCmd);
                        if(props.onChange) {
                            props.onChange({...cmd , description: value.trim()});
                            globalData = newCmd;
                        }
                    }}/>
            {/*<TextField*/}
            {/*    size={"small"}*/}
            {/*    InputLabelProps={{shrink: true}}*/}
            {/*    fullWidth*/}
            {/*    multiline={true}*/}
            {/*    value={cmd.description}*/}
            {/*    rows={4}*/}
            {/*    inputProps={{sx: {paddingBlock: "5px",paddingInline: "10px"}}}*/}
            {/*    label={"Description"}*/}
            {/*    onBlur={handleBlur}*/}
            {/*    onFocus={handleFocus}*/}
            {/*    onChange={(event) => {*/}
            {/*        let newCmd = {...cmd , description: event.target.value}*/}
            {/*        setCmd(newCmd);*/}
            {/*        if(props.onChange) {*/}
            {/*            props.onChange(newCmd);*/}
            {/*            globalData = newCmd;*/}
            {/*        }*/}
            {/*    }}*/}
            {/*/>*/}
        </Grid>
    </Grid>
}