
import React, {useState} from "react";
import styles from "./Theme.module.scss";
import {Autocomplete, Divider, Grid, GridSize, Tab, TextField} from "@mui/material";
import KeyValuePanel from "../seo/KeyValuePanel";
import MonacoEditor, {addCSSVariables} from "../editor/MonacoEditor";
import ColorPickerField from "../colorpicker/ColorPickerField";
// import SolidIcon from "../icon/SolidIcon";
// import ReverseSolidLinkButton from "../button/ReverseSolidLinkButton";
import { Tabs } from "@mui/material";

interface InputCmd {
    id: string,
    type: string,
    name: string,
    span?: GridSize,

}
export default function ThemeEditor(props: {
    path: string,
    type: string,
    id: string,
    name: string,
    themePros: {[key:string]: string}
    // loadData: () => Promise<any>,
    saveData: (name:string, data: any) => void}) {

    const [tab, setTab] = useState<number>(0);
    const [themeProps,setThemeProps] = useState<{[key:string]: string}>(props.themePros);
    const [themeName, setThemeName] = useState<string>(props.name);

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    }



    // when check these, please change these on CMSTheme.tsx also
    const widgetStyles = [
        {label: "Header widget", value: "header", title: "Header Widget"},
        {label: "Footer widget", value: "footer", title: "Footer Widget"},
        {label: "Text widget", value: "text", title: "Text Widget"},
        {label: "Image widget", value: "image", title: "Image Widget"},
        {label: "Suggestion widget", value: "suggestion", title: "Suggestion Widget"},
        {label: "Card widget", value: "card", title: "Card Widget"},
        {label: "Hero Section widget", value: "herosection", title: "Hero Section Widget"},
        {label: "Image list widget", value: "image_list", title: "Image list Widget"},
        {label: "Gallery widget", value: "gallery", title: "Gallery Widget"},
        {label: "Product drilldown widget", value: "product_drilldown", title: "Product drilldown Widget"},
        {label: "Product query widget", value: "product_query", title: "Product query Widget"},
        {label: "Plugin widget", value: "plugin", title: "Plugin Widget"},
        {label: "divider",value:"divider", title: "divider"},
        {label: "Default section", value: "defaultsection", title: "Default Section"},
        {label: "Rows section", value: "vertical", title: "Rows Section"},
        {label: "Horizontal section", value: "horizontal", title: "Horizontal Section"},
        {label: "Two columns section", value: "two_columns", title: "Two columns Section"},
        {label: "Three columns section", value: "three_columns", title: "Three columns Section"},
    ];
    const [widgetStyle,setWidgetStyle] = useState(widgetStyles[0]);
    const updateVariables = (variable:{}) => {
        if(variable) {
            let keys = [];
            for(let key in variable) {
                if(key.startsWith("--")) {
                    keys.push(`${key}`);
                } else if(key.startsWith("-")) {
                    keys.push(`-${key}`);
                } else {
                    keys.push(`--${key}`);
                }
            }
            keys.push("--btcms-background-color");
            keys.push("--btcms-primary-color");
            keys.push("--btcms-secondary-color");
            keys.push("--btcms-font-family");
            keys.push("--btcms-panel-primary-color");
            keys.push("--btcms-panel-secondary-color");
            addCSSVariables(keys);
        }
    }


    let colorCustom:InputCmd[] = [
        {id: "background-color" , type: "color", name: "Background Color"},
        {id: "primary-color" , type: "color" , name: "Primary Color"},
        {id: "secondary-color" , type: "color", name: "Secondary Color"},
        {id: "panel-primary-color" , type: "color", name: "Panel Primary Color"},
        {id: "panel-secondary-color" , type: "color", name: "Panel Secondary Color"}
    ]


    let typoCustom:InputCmd[] = [

        {id: "font-family", type:"text", name: "font-family" , span: 12},
        {id: "font-url-1", type:"text", name: "font-url-1", span: 12},
        {id: "font-url-2", type:"text", name: "font-url-2", span: 12},
        {id: "font-url-3", type:"text", name: "font-url-3", span: 12},
        {id: "font-url-4", type:"text", name: "font-url-4", span: 12},
    ]

    const tagStyles = [
        {label: "body", value: "body", title: "body Tag"},
        {label: "h1", value: "h1", title: "h1 tag"},
        {label: "h2", value: "h2", title: "h2 tag"},
        {label: "h3", value: "h3", title: "h3 tag"},
        {label: "h4", value: "h4", title: "h4 tag"},
        {label: "h5", value: "h5", title: "h3 tag"},
        {label: "h6", value: "h6", title: "h4 tag"},
        {label: "p", value: "p", title: "p tag"},
        {label: "a", value: "a", title: "a tag"},
        {label: "wrapper", value: "wrapper", title: "wrapper class"},
        {label: "btn (buttons)", value: "btn", title: "button classes"},
        {label: "input", value: "input", title: "input classes"},
        {label: "other", value: "other", title: "other"},
    ];

    const [tagStyle, setTagStyle] = useState(tagStyles[0]);

    let metaCustom:InputCmd[] = [
        {id: "meta", type: "keyvalue" , name : "Metadata", span: 12}
    ]

    let variableCustom:InputCmd[] = [
        {id: "variable", type: "keyvalue"  , name : "CSS Variables", span: 12}
    ]

    const onHandle = (id: string, value: any) => {
        let newThemeProps = {...themeProps};

        if(value === "") {
            delete newThemeProps[id];
        } else {
            newThemeProps[id] = value;
        }

        if(id === "variable") {
            updateVariables(value);
        }
        setThemeProps(newThemeProps);
    }

    const createColorInput = (custom:any) => {
        return <ColorPickerField
            size={"small"}
            key={"color_" + custom.id}
            // label={custom.name}
            variant={"outlined"}
            margin="dense"
            type="text"
            value={themeProps && themeProps[custom.id] !== undefined && themeProps[custom.id] !== null? themeProps[custom.id] : ""}
            fullWidth
            onChangeColor={(color) => {
                onHandle(custom.id,color);
            }}
        />
    }

    const createWidthInput = (custom:any) => {
        return <TextField
            size={"small"}
            key={"width_" + custom.id}
            // label={custom.name}
            variant={"outlined"}
            InputLabelProps={{ shrink: true }}
            value={themeProps && themeProps[custom.id] !== undefined && themeProps[custom.id] !== null? themeProps[custom.id] : ""}
            margin="dense"
            type="text"
            fullWidth
            onChange={(event) => {
                onHandle(custom.id,event.target.value);
            }}
        />
    }

    const createCssInput = (custom:any) => {
        return <MonacoEditor
            language={"css"}
            // title={custom.name}
            title={""}
            // rows={5}
            caption={custom.caption}
            key={"style_" + custom.id}
            defaultValue={themeProps &&themeProps[custom.id] !== undefined && themeProps[custom.id] !== null? themeProps[custom.id] : ""}
            className={styles.CssInputPanel}
            onChange={(newValue:string) => {
                        onHandle(custom.id,newValue);
                    }}
        />
    }

    const createKeyValue = (custom:any) => {
        return <KeyValuePanel
                key={"keyvalue_" + custom.id}
                id={custom.id}
                defaultValue={themeProps &&themeProps[custom.id] !== undefined && themeProps[custom.id] !== null ? themeProps[custom.id] : {}}
                onChange={(data) => {
                    onHandle(custom.id,data);
                }}
            />
    }

    const createTextInput = (custom:any) => {
        return <TextField
            size={"small"}
            key={"text_" + custom.id}
            variant={"outlined"}
            margin="dense"
            type="text"
            // label={custom.name}
            InputLabelProps={{ shrink: true }}
            value={themeProps && themeProps[custom.id] !== undefined && themeProps[custom.id] !== null ? themeProps[custom.id] : ""}
            fullWidth
            onChange={(event) => {
                onHandle(custom.id,event.target.value);
            }}
        />
    }

    const createInput = (custom: InputCmd) => {
        if(custom.type === "color") {
            return createColorInput(custom);
        } else if(custom.type === "style") {
            return createCssInput(custom);
        } else if(custom.type === "width") {
            return createWidthInput(custom);
        } else if(custom.type === "text") {
            return createTextInput(custom);
        } else if(custom.type === "keyvalue") {
            return createKeyValue(custom)
        } else {
            return <></>;
        }
    }

    // const saveButton = <ReverseSolidLinkButton id="saveThemeButton"
    //                                            data-action="saveTheme"
    //                                            variant={"contained"}
    //                                            onClick={() => props.saveData(themeProps)}>
    //     <SolidIcon className={styles.Icon}>save</SolidIcon>Save
    // </ReverseSolidLinkButton>;

    const createSingleForm = (customs: InputCmd[],size: GridSize,size2: GridSize) => {
        return customs.map(custom => {
            if (custom.type === "text" || custom.type === "color") {
                return <React.Fragment key={custom.id}>
                    <Grid item xs={size} md={size}>
                        <span className={styles.FormLabel}>{custom.name}</span>
                    </Grid>
                    <Grid item xs={size2}
                          md={size2}>
                        {createInput(custom)}
                    </Grid></React.Fragment>
            } else {
                return <Grid item key={custom.id} xs={12}
                             md={custom.span === undefined ? 12 : custom.span}>
                    {createInput(custom)}
                </Grid>
            }
        })
    }
    return <>
        <div id="themeEditor">
            {/*<PageSummary type={"themes"}*/}
            {/*             name={props.name}*/}
            {/*             title={`"${props.name}" style`}*/}
            {/*             desc={""}*/}
            {/*             titleActions={saveButton}*/}
            {/*/>*/}

            <Tabs value={tab}
                  className={styles.TabContainer}
                  onChange={handleChangeTab}
                  variant="scrollable"
                  scrollButtons="auto">
                <Tab label={"General"}/>
                <Tab label={"Typography"}/>
                <Tab label={"Colors"}/>
                <Tab label={"CSS Variables"}/>
                <Tab label={"HTML Tag Styling"}/>
                <Tab label={"Element Styling"}/>
                <Tab label={"Metadata"}/>
            </Tabs>

            <div className={styles.EditorRoot}>
            {
                tab === 0 && <Grid container className={styles.Container} spacing={1} role="tabpanel" hidden={true} justifyContent={"center"} alignItems={"center"}>
                    <Grid item xs={1}>
                        <span className={styles.FormLabel}>Name</span>
                    </Grid>
                    <Grid item xs={11}>
                        <TextField fullWidth
                                   value={themeName}
                                   size={"small"}
                                    onChange={ (event) => {
                                            setThemeName(event.target.value.trim());
                                        }
                                    }/>
                    </Grid>
                </Grid>
            }
            { tab === 1 && <Grid container className={styles.Container} spacing={1} role="tabpanel" hidden={true} justifyContent={"center"} alignItems={"center"}>
                    {
                        // typoCustom.map(custom => <Grid item key={custom.id} xs={12}
                        //                              md={custom.span === undefined ? 4 : custom.span}>
                        //         {createInput(custom)}
                        //     </Grid>
                        // )
                        createSingleForm(typoCustom,2,10)
                    }
                </Grid>
            }

            { tab === 2 && <Grid container className={styles.Container} spacing={1} justifyContent={"center"} alignItems={"center"}>
                    {
                        // colorCustom.map(custom => <Grid item key={custom.id} xs={12}
                        //                          md={custom.span === undefined ? 12 : custom.span}>
                        //         {createInput(custom)}
                        //     </Grid>
                        // )
                        createSingleForm(colorCustom,3,9)
                    }
                </Grid>
            }
            { tab === 3 && <Grid container className={styles.Container} spacing={1}>
                    {variableCustom.map(custom => <Grid item key={custom.id} xs={12}
                                                 md={custom.span === undefined ? 12 : custom.span}>
                            {createInput(custom)}
                        </Grid>
                    )
                    }
                </Grid>
            }
            { tab === 4 && <Grid container className={styles.Container} spacing={1} alignItems={"center"}>
                <Grid item xs={2} sx={{textAlign: "center"}}><span className={styles.FormLabel}>Tag Style</span></Grid>
                <Grid item xs={10}>
                    <Autocomplete fullWidth
                                  autoHighlight
                                  size={"small"}
                                  renderInput={(props) => <TextField {...props}/>}
                                  getOptionLabel={(obj) => obj.label}
                                  value={tagStyle}
                                  isOptionEqualToValue={(option) => option.value === tagStyle.value}
                                  onChange={(event, value: any) => {
                                      setTagStyle(value);
                                  }
                                  }
                                  options={tagStyles}/>
                </Grid>
                <Grid item xs={12}>
                    {createCssInput({
                        id: tagStyle.value,
                        type: "css",
                        name: tagStyle.title,
                    })}
                </Grid>
            </Grid>}

            { tab === 5 && <Grid container className={styles.Container} spacing={1} alignItems={"center"}>
                <Grid item xs={2} sx={{textAlign: "center"}}><span className={styles.FormLabel}>CSS Object</span></Grid>
                <Grid item xs={10}>
                    <Autocomplete fullWidth
                                  autoHighlight
                                  size={"small"}
                                  renderInput={(props) => <TextField
                                                                     // label={"CMS Object"}
                                                                     {...props}/>}
                                  getOptionLabel={(obj) => obj.label}
                                  value={widgetStyle}
                                  isOptionEqualToValue={(option) => option.value === widgetStyle.value}
                                  renderOption={(props, option) => {
                                      if (option.label === "divider") {
                                          return <Divider/>;
                                      }
                                      return <li {...props}>{option.label}</li>;
                                  }
                                  }
                                  onChange={(event, value: any) => {
                                      setWidgetStyle(value);
                                  }
                                  }
                                  options={widgetStyles}/>
                </Grid>
                <Grid item xs={12}>
                    {createCssInput({
                        id: widgetStyle.value,
                        type: "css",
                        name: widgetStyle.title,
                        caption: "Use \"current\" to represent this element"
                    })}
                </Grid>
            </Grid>}

            { tab === 6 && <Grid container className={styles.Container} spacing={1}>
                {   metaCustom.map(custom => {
                        return <Grid item key={custom.id} xs={12}
                              md={custom.span === undefined ? 12 : custom.span}>
                            {createInput(custom)}
                        </Grid>
                    }
                )
                }
            </Grid>
            }
                <button id="idSaveTheme"
                        onClick={() => props.saveData(themeName,themeProps)}
                        className={styles.HiddenButton}>SAVE</button>
            </div>
        </div>
    </>
}
