import {SectionInterface} from "../model/SectionInterface";
import React from "react";
import {WidgetInterface} from "../model/WidgetInterface";
import {SettingGroup, SettingOption} from '../model/SettingOption';
import {DeleteOption} from "../model/DeleteOption";
import {parseInt} from "lodash";
import {NewOption} from "../model/NewOption";
import {SwapOption} from "../model/SwapOption";
import {SettingChangeFunction} from "../ui/setting/SettingChange";
import SharedObjects from "../ui/shared/editable/ShareObjects";
import Horizontal from "../ui/section/horizontal/Horizontal";
import TwoColumns from "../ui/section/twocolumns/TwoColumns";
import ThreeColumns from "../ui/section/threecolumns/ThreeColumns";
import Vertical from "../ui/section/vertical/Vertical";
import Image from "../ui/widget/image/Image";
import Text from "../ui/widget/text/Text";
import Card from "../ui/widget/card/Card";
import Empty from "../ui/widget/empty/Empty";
import FieldGroup from "../ui/widget/fieldgroup/FieldGroup";
import Header from "../ui/widget/header/Header";
import Footer from "../../ui/footer/Footer";
import Jumbotron from "../ui/widget/jumbotron/Jumbotron";
import Login from "../ui/widget/login/Login";
import Plugin from "../ui/widget/plugin/Plugin";
import ProductDrilldown from "../ui/widget/productdrilldown/ProductDrilldown";
import ProductQuery from "../ui/widget/productquery/ProductQuery";
import ImageList from "../ui/widget/imagelist/ImageList";
import AdvanceContent from "../ui/widget/advancecontent/AdvanceContent";
import ProductList from "../ui/widget/productlist/ProductList";
import DefaultSection from "../ui/section/defaultsection/DefaultSection";
import {SectionDesc} from "../model/desc/SectionDesc";
import {WidgetDesc} from "../model/desc/WidgetDesc";
import {GetPortalEnvironment} from "../../ui/popover/PortalEnvironmentPopover";
import {nanoid} from "nanoid";
import Button from "../ui/widget/button/Button";
import {lorem} from "../ui/shared/lorem/lorem";

function getInitImageListProps() {
    return { content: {
            "searchType": "BY_IDS",
            "searchString": "",
            "maxItems": "5",
            "title": lorem.sentences(1),
            "images": [
                {
                    "image_url": "https://demo.secondphaselive.net/images/content/slide_001.jpg",
                    "image_href": ""
                },
                {
                    "image_url": "https://demo.secondphaselive.net/images/content/slide_002.jpg",
                    "image_href": ""
                },
                {
                    "image_url": "https://demo.secondphaselive.net/images/content/slide_001.jpg",
                    "image_href": ""
                },
                {
                    "image_url": "https://demo.secondphaselive.net/images/content/slide_001.jpg",
                    "image_href": ""
                }
            ],
            "displayType": "QUILTED",
            "gap": "10px",
            "columns": 2,
            "rows": 3,
            "columnHeight": "auto",
            "columnWidth": "100%"
        }
    }
}
function getInitProductListProps() {
    let cards = [];
    for(let i=0;i<5;i++) {
        cards.push({
            "title": "",
            "image": `https://demo.silvereclipse.net/images/content/product${i}.jpg`,
            "image_href": "",
            "description": `<h4>${lorem.sentences(1)}</h4>\n<div style=\"display: flex; align-items: center;\">Price:&nbsp;<span style=\"font-weight: bold;\">$99.99 / EA</span></div>\n<div>Availability: <span style=\"font-weight: bold;\">999 EA</span></div>`,
            "actions": []
        })
    }
    return { content: {
        "searchType": "BY_IDS",
        "searchString": "",
        "maxItems": "5",
        "title": lorem.sentences(1),
        "searchProfiledId": -1,
        "imageField": "_lsi_cn_ImageThumb",
        "descField": "_lsi_cn_Description",
        "titleField": "_lsi_cn_ProductID",
        "priceField": "_lsi_cn_Price",
        "uomField": "_lsi_cn_PriceUOM",
        "datasource": "CUSTOM",
        "cards": cards
    }};
}

export function getInitWidgetProps(widgetName: string) {
    if(widgetName === "ProductList") {
        return getInitProductListProps();
    } else if(widgetName === "ImageList") {
        return getInitImageListProps();
    } else {
        return {};
    }

}
function getId(data: WidgetInterface | SectionInterface) {
    if ('sectionId' in data) {
        let section = data as SectionInterface
        return "section_" + section.sectionId;
    } else if('widgetId' in data) {
        let widget = data as WidgetInterface;
        return "widget_" + widget.widgetId;
    } else {
        return "";
    }
}

function sectionId(id:string, data:SectionInterface) {
    let idArray:string[] = [];
    idArray.push(id);
    for(let sectionWidget of data.sectionWidgets) {
        if(sectionWidget) {
            if ('sectionId' in sectionWidget) {
                idArray.push((sectionWidget as SectionInterface).sectionId)
            } else if ('widgetId' in sectionWidget) {
                idArray.push((sectionWidget as WidgetInterface).widgetId);
            }
        }
    }
    return idArray.join("_");

}

function generateView(  data: WidgetInterface | SectionInterface,
                        uiLevel: number,
                        uiLevelStr: string,
                        sharedObjects: SharedObjects,
                        onHandleSetting: (  themeName:string,
                                            title: string,
                                            uiLevelStr: string,
                                            suggestTags:string[],
                                            groups: SettingGroup[],
                                            settings: SettingOption[],
                                            widgetId: string,
                                            widgetData: any,
                                            settingProps: any,
                                            onChange: SettingChangeFunction|null,
                                            // saveWidgetState: () => void,
                                            required: boolean,
                                            portalThemeName:string,
                                            siteCode: string) => void,
                        onHandleDeletion: (uiLevelStr: string, setting: DeleteOption) => void | undefined,
                        onHandleAddNewSectionOrWidget: (levelString: string, setting: NewOption) => void | undefined,
                        onHandleSwap: (fromLevelStr: string , toLevelStr: string, setting: SwapOption) => void | undefined,
                        onHandleUpdate: (levelString: string) => void | undefined,
                        onHandleClone: (level: string) => void,
                        onHandleCopy: (level: string) => void,
                        onHandleCut: (level: string) => void,
                        onHandleCreateTemplate: (templateData: string) => void,
                        updateWidgetData: (value: {widgetId: string, widgetData: any , action: string|null, value: any}) => void,
                        onInsertSection?: (uiLevelStr: string, desc: SectionDesc) => void,
                        onInsertWidget?: (uiLabelStr: string, desc: WidgetDesc) => void,

) {
    if(data === null || data === undefined) return <></>;
    let portalEnvironment = GetPortalEnvironment();
    let id = getId(data)  + "_" + uiLevelStr + "_" + portalEnvironment;
    if ('sectionType' in data) {
        let section = data as SectionInterface
       // console.log("CREATE SECTION ",section.sectionName,sharedObjects);
       //  let View = React.lazy(() => import(`../ui/${section.sectionType}/${section.sectionName.toLowerCase()}/${section.sectionName}`));
       //  return <View key={id}
       //               data={section}
       //               uiLevel={uiLevel}
       //               uiLevelStr={uiLevelStr}
       //               uiType={"section"}
       //               sharedObjects={sharedObjects}
       //               onHandleAddNewSectionOrWidget={onHandleAddNewSectionOrWidget}
       //               onHandleSetting={onHandleSetting}
       //               onHandleDeletion={onHandleDeletion}
       //               onHandleSwap={onHandleSwap}
       //               onHandleUpdate={onHandleUpdate}
       //  ></View>
        let name = section.sectionName.toLowerCase();
        let properties:any = {
            data: section,
            uiLevel: uiLevel,
            uiLevelStr: uiLevelStr,
            uiType: "section",
            sharedObjects: sharedObjects,
            onHandleAddNewSectionOrWidget: onHandleAddNewSectionOrWidget,
            onHandleSetting: onHandleSetting,
            onHandleDeletion: onHandleDeletion,
            onHandleSwap: onHandleSwap,
            onHandleUpdate: onHandleUpdate,
            onInsertSection: onInsertSection,
            onInsertWidget: onInsertWidget,
            onHandleClone: onHandleClone,
            onHandleCopy: onHandleCopy,
            onHandleCut: onHandleCut,
            onHandleCreateTemplate: onHandleCreateTemplate,
            updateWidgetData: updateWidgetData
        }
        if(name === "defaultsection") {
            return <DefaultSection key={sectionId(id,section)} {...properties}/>
        }if(name === "horizontal") {
            return <Horizontal key={sectionId(id,section)} {...properties}/>
        } else if(name === "vertical") {
            return <Vertical key={sectionId(id,section)} {...properties}/>
        } else if(name === "twocolumns") {
            return <TwoColumns key={sectionId(id,section)} {...properties}/>
        } else if(name === "threecolumns") {
            return <ThreeColumns key={sectionId(id,section)}  {...properties}/>
        } else if(name === "empty") {
            return <Empty key={sectionId(id,section)}  {...properties}/>
        } else {
            return <></>
        }
    } else if ('widgetType' in data) {
        let widget = data as WidgetInterface
      //  console.log("CREATE WIDGET ",widget.widgetName,sharedObjects);

        let name = widget.widgetName.toLowerCase();
        let properties:any = {
            data: widget,
            uiLevel: uiLevel,
            uiLevelStr: uiLevelStr,
            uiType: "widget",
            sharedObjects: sharedObjects,
            onHandleAddNewSectionOrWidget: onHandleAddNewSectionOrWidget,
            onHandleSetting: onHandleSetting,
            onHandleDeletion: onHandleDeletion,
            onHandleSwap: onHandleSwap,
            onHandleUpdate: onHandleUpdate,
            onHandleClone: onHandleClone,
            onHandleCopy: onHandleCopy,
            onHandleCut: onHandleCut,
            onHandleCreateTemplate: onHandleCreateTemplate
        }
        if(name === "text") {
            return <Text key={id} {...properties}/>
        } else if(name === "image") {
            return <Image key={id} {...properties}/>
        } else if(name === "button") {
            return <Button key={id} {...properties}/>
        } else if(name === "imagelist") {
            return <ImageList key={id} {...properties}/>
        } else if(name === "advancecontent") {
            return <AdvanceContent key={id} {...properties}/>
        } else if(name === "card") {
            return <Card key={id} {...properties}/>
        } else if(name === "empty") {
            return <Empty key={id} {...properties}/>
        } else if(name === "fieldgroup") {
            return <FieldGroup key={id} {...properties}/>
        } else if(name === "header") {
            return <Header key={id} {...properties}/>
        } else if(name === "footer") {
            return <Footer key={id} {...properties}/>
        } else if(name === "herosection") {
            return <Jumbotron key={id} {...properties}/>
        } else if(name === "login") {
            return <Login key={id} {...properties}/>
        } else if(name === "plugin") {
            return <Plugin key={id} {...properties}/>
        } else if(name === "productdrilldown") {
            return <ProductDrilldown key={id} {...properties}/>
        } else if(name === "productquery") {
            return <ProductQuery key={id} {...properties}/>
        } else if(name === "gallery") {
            return <ProductList key={id} {...properties}/>
        } else if(name === "empty") {
            return <Empty  key={id} {...properties}/>
        } else {
            return <React.Fragment key={id}></React.Fragment>
        }
        // let View = React.lazy(() => import(`../ui/${widget.widgetType}/${widget.widgetName.toLowerCase()}/${widget.widgetName}`));
        // return <View key={id}
        //              data={widget}
        //              uiLevel={uiLevel}
        //              uiLevelStr={uiLevelStr}
        //              uiType={"widget"}
        //              sharedObjects={sharedObjects}
        //              onHandleAddNewSectionOrWidget={onHandleAddNewSectionOrWidget}
        //              onHandleSetting={onHandleSetting}
        //              onHandleDeletion={onHandleDeletion}
        //              onHandleSwap={onHandleSwap}
        //              onHandleUpdate={onHandleUpdate}
        //         />
    } else {
        return <React.Fragment></React.Fragment>;
    }
}

export function doAssignNewIds(object: SectionInterface | WidgetInterface) {
    // console.log(object);
    if(object === null || object === undefined) return object;
    if('sectionId' in object) {
        let section = object as SectionInterface;
        section.sectionId = "section_" + nanoid();
        section.sectionWidgets.forEach(obj => doAssignNewIds(obj));
    } else if('widgetId' in object) {
        let widget = object as WidgetInterface;
        widget.widgetId = "widget_" + nanoid();
    }
    return object;
}

export function doViewGenerator(array: (WidgetInterface|SectionInterface)[],
                                uiLevel: number,
                                uiLevelStr: string,
                                sharedObjects: SharedObjects,
                                onHandleSetting: (themeName:string,
                                                  title: string,
                                                  levelStr : string,
                                                  suggestTags: string[],
                                                  groups: SettingGroup[],
                                                  settings: SettingOption[],
                                                  widgetId: string,
                                                  widgetData: any,
                                                  settingProps: any,
                                                  onChange: SettingChangeFunction|null,
                                                  // saveWidgetState: () => void,
                                                  required: boolean,
                                                  portalThemeName:string,
                                                  siteCode: string) => void,
                                onHandleDeletion: (levelStr: string, setting: DeleteOption) => void,
                                onHandleAddNewSectionOrWidget: (levelStr: string, setting: NewOption) => void,
                                onHandleSwap: (fromLevelStr: string, toLevelStr: string, setting: SwapOption) => void,
                                onHandleUpdate: (levelStr: string) => void,
                                onHandleClone: (level: string) => void,
                                onHandleCopy: (level: string) => void,
                                onHandleCut: (level: string) => void,
                                onHandleCreateTemplate: (templateData: string) => void,
                                updateWidgetData: (value: {widgetId: string, widgetData: any , action: string|null, value: any}) => void,
                                onInsertSection?: (uiLevelStr: string, desc: SectionDesc) => void,
                                onInsertWidget?: (uiLabelStr: string, desc: WidgetDesc) => void
                                 ):JSX.Element[] {

    let views = array.map((data,index) => {
        return generateView(data, uiLevel, (uiLevelStr + "_" + index),
                                sharedObjects,
                                onHandleSetting,
                                onHandleDeletion,
                                onHandleAddNewSectionOrWidget,
                                onHandleSwap,
                                onHandleUpdate,
                                onHandleClone,
                                onHandleCopy,
                                onHandleCut,
                                onHandleCreateTemplate,
                                updateWidgetData,
                                onInsertSection,
                                onInsertWidget);
    });
    return views;
}

export function findViewObjectByLevelString(array: (WidgetInterface|SectionInterface)[],
                                            uiLevelStr: string): [findObject: WidgetInterface|SectionInterface|null , parentSection: SectionInterface|null, findIndex: number|null] {
    if(!array || array.length === 0) return [null,null,null];
    let uiArray = uiLevelStr.split("_"); // R_0_0...
    let currentArray:(WidgetInterface|SectionInterface)[]|null = array;
    let rValue:WidgetInterface|SectionInterface|null = null;
    let pValue:SectionInterface|null = null;
    let rIndex:number|null = null;

    for(let i=1;i<uiArray.length;i++) {

        if(currentArray === null) {
            rValue = null;
            break;
        }
        let index = parseInt(uiArray[i]);
        let  object = currentArray[parseInt(uiArray[i])];


        if(rValue !== null) {
            pValue = rValue as SectionInterface;
        }

        if(object === null || object === undefined) {
            return [null,pValue,index];
        }

        if ('sectionType' in object) { // section
            let section = object as SectionInterface;
            currentArray = section.sectionWidgets;
            rValue = section;
            rIndex = index;
        } else {
            let widget = object as WidgetInterface;
            currentArray = null;
            rValue = widget;
            rIndex = index;
        }
    }
    return [rValue, pValue, rIndex];
}

export function findParentIdFromChildId(root: SectionInterface[], id:string) : string {
    let array = [...root];
    while(array.length > 0) {
        let object = array.pop();
        if(object === null) {
            continue;
        }
        let section = object as SectionInterface;
        if(section.sectionId === id) return "";
        let children = section.sectionWidgets
        for(let child of children) {
            if(child === null || child === undefined) continue;
            if ('sectionType' in child) {
                let childSection = child as SectionInterface;
                if (childSection.sectionId === id) return section.sectionId;
                array.push(childSection);
            } else {
                let childWidget = child as WidgetInterface;
                if (childWidget && childWidget.widgetId && childWidget.widgetId === id) {
                    if(section.sectionId) {
                        return section.sectionId;
                    } else {
                        return "";
                    }
                }
            }
        }
    }
    return "";
}

export function findViewObjectById(array: (WidgetInterface|SectionInterface)[],
                                   id: string): (WidgetInterface|SectionInterface|null) {

    if(!array || array.length === 0) return null;

    let allArray:(WidgetInterface|SectionInterface)[] = [...array];
    while(allArray.length > 0) {
        let object = allArray.pop();
        if (object === undefined || object === null) continue;
        if ('sectionType' in object) {
            let section = object as SectionInterface;
            if (section.sectionId === id) {
                return section;
            } else {
                section.sectionWidgets.forEach(obj => allArray.push(obj));
            }
        } else {
            let widget = object as WidgetInterface;
            if (widget.widgetId === id) {
                return widget;
            }
        }
    }
    return null;
}

export const DEFAULT_PADDING_INLINE = "16px";
export const DEFAULT_PADDING_BLOCK = "16px";
