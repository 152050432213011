import {useAppSelector} from "../../../../redux/hook";
import {selectViewState} from "../../../../redux/features/view/viewSlice";
import EditablePanel from "../../shared/editable/EditablePanel";
import {DeleteType} from "../../../model/DeleteOption";
import {SettingGroupType, SettingOption, SettingType} from "../../../model/SettingOption";
import React, {useContext, useState} from "react";
import {StyleUtil} from "../../setting/StyleUtil";
import {WidgetInterface} from "../../../model/WidgetInterface";
import {lorem} from "../../shared/lorem/lorem";
import styles from "./Card.module.scss";
import {nanoid} from "nanoid";
import useWidgetState from "../../../../hook/useWidgetState";
import VariableContext from "../../../../context/VariableContext";

export default function Card(props: any) {
    const widgetData:WidgetInterface = props.data as WidgetInterface;
    const {widgetId,widgetProps} = widgetData;
    let viewState = useAppSelector(selectViewState);
    const [refreshId,setRefreshId] = useState(nanoid());
    const variableContext = useContext(VariableContext);
    useWidgetState(widgetId,(event:CustomEvent) => {
        setRefreshId(nanoid());
    });

    if(!widgetProps['content']) {
        widgetProps['content'] = {
            image_url: "https://demo.silvereclipse.net/images/content/slide_001.jpg",
            card_title: lorem.sentences(1),
            card_desc: lorem.paragraphs(1),
        }
    }
    //if(!widgetProps['card']) {
    if(StyleUtil.isDefaultStyle(widgetProps['bt_card_panel'])) {
        widgetProps['bt_card_panel'] = {
            style: {
                default: true,
                margin: {
                    'margin-inline-start' : 'auto',
                    'margin-inline-end' : 'auto',
                    'margin-block-start' : '16px',
                    'margin-block-end' : '16px',
                },
                padding: {
                    'padding-inline-start' : '16px',
                    'padding-inline-end' : '16px',
                    'padding-block-start' : '16px',
                    'padding-block-end' : '16px',
                }
            }
        };
    }

    if(StyleUtil.isDefaultStyle(widgetProps['bt_card'])) {
        widgetProps['bt_card'] = {
            style: {
                default: true,
                dimension: {
                    // 'min-width': "250px",
                    'min-height': "300px",
                    'max-width': "250px",
                },
                margin: {
                    'margin-inline-start' : 'auto',
                    'margin-inline-end' : 'auto',
                    'margin-block-start' : '16px',
                    'margin-block-end' : '16px',
                },
                padding: {
                    'padding-inline-start' : '16px',
                    'padding-inline-end' : '16px',
                    'padding-block-start' : '16px',
                    'padding-block-end' : '16px',
                },
                'boxshadow': {
                    'box-shadow-color' : '#eaeaea',
                    'box-shadow-horizontal' : '0px',
                    'box-shadow-vertical' : '2px',
                    'box-shadow-blur' : '8px',
                    'box-shadow-spread' : '0px',
                    'box-shadow': '0px 2px 8px 0px #eaeaea'
                },

                background: {

                }
            }
        };
    }

    //if(!widgetProps['card_icon']) {
    if(StyleUtil.isDefaultStyle(widgetProps['c_icon'])) {
        widgetProps['c_icon'] = {
            style: {
                default: true,
                position: {
                    display: "block"
                },
                dimension: {
                    width: "100%",
                    height: "150px",
                    'max-height': "150px",
                },
                background: {
                    'background-color': 'transparent',
                },

                object: {
                    'object-fit': 'cover'
                }
            }
        };
    }

    //if(!widgetProps['title']) {
    if(StyleUtil.isDefaultStyle(widgetProps['c_title'])) {
        widgetProps['c_title'] = {
            style: {
                default: true,
                position: {
                    display: "block"
                },
                dimension: {
                    width: "100%",
                },
                typography: {
                    'font-weight' : "bold"
                },
                padding: {
                    'padding-block-start': "8px",
                    'padding-block-end': "8px",
                    'padding-inline-start': '16px',
                    'padding-inline-end': '16px',
                }

            }
        };
    }

    //if(!widgetProps['desc']) {
    if(StyleUtil.isDefaultStyle(widgetProps['c_desc'])) {
        widgetProps['c_desc'] = {
            style: {
                position: {
                    display: "block"
                },
                dimension: {
                    width: "100%",
                },
                typography: {
                    'font-weight' : "bold"
                },
                padding: {
                    'padding-block-start': "8px",
                    'padding-block-end': "8px",
                    'padding-inline-start': '16px',
                    'padding-inline-end': '16px',
                }
            }
        };
    }

    const themeName = "card";
    const suggestTags: string[] = [
        "current",
        "current .c_icon",
        "current .c_title",
        "current .c_desc",
    ]
    const widgetStyles = {
        theme: StyleUtil.toThemeNameByType(widgetProps, themeName),
        current: StyleUtil.toClassFromWidget(widgetId, widgetProps, "current_style"),
        card_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "bt_card_panel"),
        card: StyleUtil.toClassFromWidget(widgetId , widgetProps, "bt_card"),
        card_icon: StyleUtil.toClassFromWidget(widgetId , widgetProps, "c_icon"),
        title: StyleUtil.toClassFromWidget(widgetId , widgetProps, "c_title"),
        desc: StyleUtil.toClassFromWidget(widgetId , widgetProps, "c_desc"),
    }

    const createCard = () => {
        let image_url = variableContext.updateVariables(widgetProps['content']['image_url']);
        let title = widgetProps['content']['title'];
        let desc = variableContext.updateVariables(widgetProps['content']['desc']);
        let image_href = variableContext.updateVariables(widgetProps['content']['image_href']);
        let hasHref = false;
        let openLink = widgetProps['content']['openLink'];
        if(openLink === null) {
            openLink = "_self";
        }

        if(image_href !== null && image_href !== undefined && image_href !== "") {
            hasHref = true;
        }

        return <div key={refreshId} className={StyleUtil.combineClasses(styles.Container,widgetStyles.theme, widgetStyles.current,widgetStyles.card_panel)}>
            <div className={widgetStyles.card} style={viewState.isEdit ? {pointerEvents: "none"}: undefined}>
                { title && title !== "" && <h4 className={StyleUtil.combineClasses("c_title",widgetStyles.title)}>{title}</h4>}
                { hasHref && image_url && image_url !== "" && <a href={image_href} target={openLink}><img className={StyleUtil.combineClasses("c_image",styles.Image,widgetStyles.card_icon)} src={image_url}/></a>}
                { hasHref === false && image_url && image_url !== "" && <img className={StyleUtil.combineClasses("c_image",styles.Image,widgetStyles.card_icon)} src={image_url}/>}
                { desc && desc !== "" && <div className={StyleUtil.combineClasses("c_desc",widgetStyles.desc)} dangerouslySetInnerHTML={{__html: desc}}></div>}
            </div>
        </div>
    }

    if(viewState.isEdit) {
        let deleteOption = {type: DeleteType.delete_with_rearrange, tag: null};
        let settingOptions:SettingOption[] = [
            { title: "", type: SettingType.widget,  editor: "cardsetting" , group: "content",
                groupType: SettingGroupType.layout, additions: {siteCode: variableContext.siteCode, portalThemeName: variableContext.portalThemeName}},

            { type: SettingType.padding, group: "bt_card_panel", groupType: SettingGroupType.advanced},
            { type: SettingType.margin, group: "bt_card_panel", groupType: SettingGroupType.advanced},

            { type: SettingType.dimension, group: "bt_card", groupType: SettingGroupType.style},
            { type: SettingType.advance_background, group: "bt_card", groupType: SettingGroupType.style},
            { type: SettingType.border, group: "bt_card", groupType: SettingGroupType.style},
            { type: SettingType.border_radius, group: "bt_card", groupType: SettingGroupType.style},
            { type: SettingType.box_shadow, group: "bt_card", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "bt_card", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "bt_card", groupType: SettingGroupType.advanced},

            { type: SettingType.dimension, group: "c_icon", groupType: SettingGroupType.style},
            { type: SettingType.object, group: "c_icon", groupType: SettingGroupType.style},
            { type: SettingType.advance_background, group: "c_icon", groupType: SettingGroupType.style},
            { type: SettingType.border, group: "c_icon", groupType: SettingGroupType.style},
            { type: SettingType.border_radius, group: "c_icon", groupType: SettingGroupType.style},
            { type: SettingType.box_shadow, group: "c_icon", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "c_icon", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "c_icon", groupType: SettingGroupType.advanced},

            { type: SettingType.typography, group: "c_title", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "c_title", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "c_title", groupType: SettingGroupType.advanced},

            { type: SettingType.typography, group: "c_desc", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "c_desc", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "c_desc", groupType: SettingGroupType.advanced},
        ];

        return <EditablePanel className={styles.Root}
                              {...props}
                              themeName={themeName}
                              suggestTags={suggestTags}
                              settingOptions={settingOptions}
                              settingGroups={[
                                      {group: "content", groupName: "Content", groupType: SettingGroupType.layout},
                                      {group: "bt_card_panel", groupName: "Panel", groupType: SettingGroupType.style},
                                      {group: "bt_card", groupName: "Card", groupType: SettingGroupType.style},
                                      {group: "c_icon", groupName: "Image", groupType: SettingGroupType.style},
                                      {group: "c_title", groupName: "Title", groupType: SettingGroupType.style},
                                      {group: "c_desc", groupName: "Description", groupType: SettingGroupType.style},
                                  ]
                              }
                              deleteOption={deleteOption}>
                    {createCard()}
                </EditablePanel>
    } else {
        return createCard();
    }
}