import SettingProps from "../SettingProps";
import GenericSetting, {StyleSettingType} from "../genericsetting/GenericSetting";

export default function FlexSetting(props: SettingProps) {
    // return <CSSSetting {...props}
    //                    title={"Flex"}
    //                    type={"flex"}
    //                    targets={["justify-content","align-items","flex-wrap","gap"]}/>

    return <GenericSetting {...props}
                           title={"Flex"}
                           type={"flex"}
                           targets={[
                                    { name: "Justify Content", target: 'justify-content' , type: StyleSettingType.selections, options: [
                                            {label: "normal", value: "normal"},
                                            {label: "unset", value: "unset"},
                                            {label: "none", value: ""},
                                            {label: "center", value: "center"},
                                            {label: "start", value: "start"},
                                            {label: "end", value: "end"},
                                            {label: "flex-start", value: "flex-start"},
                                            {label: "flex-end", value: "flex-end"},
                                            {label: "left", value: "left"},
                                            {label: "right", value: "right"},
                                        ]},
                                    { name: "Align Items", target: 'align-items' , type: StyleSettingType.selections, options: [
                                            {label: "normal", value: "normal"},
                                            {label: "unset", value: "unset"},
                                            {label: "none", value: ""},
                                            {label: "stretch", value: "stretch"},
                                            {label: "center", value: "center"},
                                            {label: "center", value: "center"},
                                            {label: "start", value: "start"},
                                            {label: "end", value: "end"},
                                            {label: "flex-start", value: "flex-start"},
                                            {label: "flex-end", value: "flex-end"},
                                            {label: "baseline", value: "baseline"},
                                        ]},
                                    { name: "Flex Wrap", target: 'flex-wrap' , type: StyleSettingType.selections, options: [
                                            {label: "none", value: ""},
                                            {label: "wrap", value: "wrap"},
                                            {label: "nowrap", value: "nowrap"},
                                        ]},
                                    { name: "Gap", target: 'gap' , type: StyleSettingType.default_size}
                                ]}
    />
}