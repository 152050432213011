import styles from "./BaseIcon.module.scss";
import {Tooltip} from "@mui/material";

export interface BaseIconProp {
    tooltip : string
    label: string
    children?: JSX.Element|JSX.Element[]
}

export default function BaseIcon(props:BaseIconProp) {
    return <Tooltip title={props.tooltip} placement={"top"} arrow><div className={styles.Root}>
                <div className={styles.IconPanel}>
                    {props.children}
                </div>
                <div className={styles.Label}>
                    {props.label}
                </div>
    </div></Tooltip>

}

