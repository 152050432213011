import {TextFieldProps} from "@mui/material/TextField/TextField";
import {TextField, Tooltip} from "@mui/material";
import React, {useState} from "react";
import { IconButton } from "@mui/material";
import OutlinedIcon from "../icon/OutlinedIcon";
import styles from "./PasswordField.module.scss";

export default function PasswordField(props: TextFieldProps & {nullable?: boolean, onChangeToNull?: () => void}) {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const newProps = {...props}
    delete newProps['nullable'];
    return <TextField {...props}
                      type={showPassword ? "text" : "password"}
                      placeholder={showPassword? props.placeholder : "[hidden]"}
                      InputProps={{
                          endAdornment:<>
                              { props.nullable && <Tooltip title={"Reset"}><IconButton onClick={() => {
                                  if(props.onChangeToNull) {
                                      props.onChangeToNull();
                                  }
                              }}>
                                  <OutlinedIcon className={styles.IconSize}>public</OutlinedIcon>
                              </IconButton></Tooltip>}
                              <IconButton
                              onClick={() => {setShowPassword(!showPassword)}}>
                                  {showPassword && <OutlinedIcon className={styles.IconSize}>visibility</OutlinedIcon>}
                                  {!showPassword && <OutlinedIcon className={styles.IconSize}>visibility_off</OutlinedIcon>}
                              </IconButton>
                          </>
                      }}
    />
}