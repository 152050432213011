
import { MenuItem } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import {useHistory, useLocation} from "react-router";
import OutlinedIcon from "../icon/OutlinedIcon";
import styles from "./DrawerMenuItem.module.scss";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "2.5rem",
        fontSize: "14px",
        color: "white",
        borderRadius: "0rem",
        border: "none",
        "&:hover": {
            backgroundColor: "var(--bt-cms-color-drawer-menu-item-background)",
            border: "none",
        }
    },

    iconRoot: {
        paddingRight: "0.3rem",
        fontSize: "1rem",
        color: "var(--standard-jade-500)"
    },
}));

export interface DrawerMenuItemProps {
    id: string,
    action: string,
    icon: string,
    title: string,
    href: string,
    doCloseDrawer: () => void
    icon_flip_y?: boolean
}

export default function DrawerMenuItem(props: DrawerMenuItemProps) {
    let history = useHistory();
    let location = useLocation();
    const classes = useStyles();
    const handleOnClick = () => {

        if(location.pathname !== props.href) {

            if(props.href.startsWith("http")) {
                window.location.href = props.href;
            } else {
                history.push(props.href);
            }
        } else {
            props.doCloseDrawer();
        }
    }

    return <MenuItem key={props.id} id={props.id} data-action={props.action} onPointerDown={handleOnClick} classes={
        {root : classes.root}
    }  className={styles.Root}>
        <OutlinedIcon classes={{root: classes.iconRoot}} flipY={props.icon_flip_y}>{props.icon}</OutlinedIcon>
        {props.title}
    </MenuItem>
}