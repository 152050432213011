import React, {useCallback, useEffect, useRef, useState} from 'react';
import styles from "./Drilldowns.module.scss";
import commonStyle from "../../config/Common.module.scss";
import StyledTable from "../table/StyledTable";
import {DrilldownDataFetcher} from "../../datafetcher/DrilldownDataFetcher";
import {useHistory, useLocation} from "react-router";
import {useParams} from "react-router-dom";
import {InputModel} from "../../model/InputModel";
import HttpClient from "../../datafetcher/HttpClient";
import {nanoid} from "nanoid";
import InputDialog from "../dialog/InputDialog";
import {useOrganization} from "../../hook/useOrganization";
import SolidIcon from "../icon/SolidIcon";
import IconLinkButton from "../button/IconLinkButton";
import ReverseSolidLinkSmallButton from '../button/ReverseSolidLinkSmallButton';
import PageSummary from "../pagesummary/PageSummary";
import OutlinedIcon from "../icon/OutlinedIcon";
import {jcr} from "../../datafetcher/JCRDataFetcher";
import {CurrentISODate} from "../../util/DateUtil";

export default function Drilldowns() {

    const history = useHistory();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const params: any = useParams<{did: string}>();
    const [newOpen, setNewOpen] = useState(false);
    const [updateData, setUpdateData] = useState<any>({});
    const [updateDrillId, setUpdateDrillId] = useState("");
    const [formKey , setFormKey] = useState("");
    const [dataFetcher, setDataFetcher] = useState<DrilldownDataFetcher|null>(null);
    const [deleteList,setDeleteList] = useState<string[]>([]);
    const  orgCmdProp = useOrganization();
    const inputFile = useRef<HTMLInputElement>(null);

    const handleCloseNewDialog = () => {
        setNewOpen(false);
    }

    const handleOpenNewDialog = () => {
        setNewOpen(true);
    }

    const handleUploadDialog = () => {
        if(inputFile !== null && inputFile.current !== null) {
            inputFile.current.click();
        }
    }

    const doUploadFile = () => {
        if(inputFile && inputFile.current) {
            if(inputFile.current.files) {
                jcr.drilldown.upload(inputFile.current.files).then((response) => {
                    if(!(orgCmdProp && orgCmdProp.currentOrg !== null && orgCmdProp.orgDetail !== null)) return;
                    setDataFetcher(new DrilldownDataFetcher(orgCmdProp.currentOrg));
                    setFormKey(nanoid());
                })
            }
        }
    }

    const handleCloseUpdateDialog = () => {
        setUpdateData({});
        setUpdateDrillId("");
        history.push({
                        pathname: '/drill-downs',
                        search: location.search});
    }

    const handleUpdateDialog = (values: InputModel[]) => {
        let data: any = updateData;
        values.forEach(object => {
            data[object.id] = object.defaultValue;
        })
        data['createdAt'] = updateData.createdAt;
        data['modifiedAt'] = new Date().getTime();

        HttpClient.post("/api/v0/jcr/org/" + orgCmdProp?.currentOrg!.orgCode + "/full/drilldowns", data).then((response) => {
            // dataFetcher!.load().then((xxxx) => {
            //     resetScreen();
            // });
            resetScreen();
            setNewOpen(false);
        }).catch((e) => {
        })
    }

    const handleSaveDialog = (values: InputModel[]) => {
        let data: any = {};
        values.forEach(object => {
            data[object.id] = object.defaultValue;
        })
        data['createdAt'] = new Date().getTime();
        data['modifiedAt'] = new Date().getTime();

        HttpClient.post("/api/v0/jcr/org/" + orgCmdProp?.currentOrg!.orgCode + "/full/drilldowns", data).then((response) => {
            resetScreen();
        }).catch((e) => {

        })
        setNewOpen(false);
    }

    const handleDelete = () => {
        if(window.confirm("Are you sure to remove?")) {
            HttpClient.delete("/api/v0/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/drilldowns/" + deleteList.join(",")).then((response) => {
                resetScreen();
                setDeleteList([]);
            }).catch((e) => {
                setDeleteList([]);
            })
        }
    }

    const handleDownloadList = () => {
        HttpClient.download(`Drilldowns-${CurrentISODate()}.gz`,
            "/api/v0/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/download/drilldowns/" + deleteList.join(","));
        setDeleteList([]);
        setFormKey(nanoid());
    }


    if (params.tid && updateDrillId !== params.tid) {
        setTimeout(() => {
            setUpdateDrillId(params.tid);
        }, 100);
    }

    const resetScreen = useCallback(() => {
        history.replace({
            pathname: '/drill-downs',
            search: location.search
        });
        setUpdateDrillId("");
        setUpdateData({});
        setFormKey(nanoid());
    },[history,location]);

    if (params.did && updateDrillId !== params.did) {
        setTimeout(() => {
            setUpdateDrillId(params.did);
        }, 100);
    }

    useEffect(() => {
        if(!(orgCmdProp && orgCmdProp.currentOrg !== null && orgCmdProp.orgDetail !== null)) return;
        if(updateDrillId !== "") {
            HttpClient.get("/api/v0/jcr/org/" + orgCmdProp?.currentOrg!.orgCode + "/drilldown/" + updateDrillId).then((response) => {
                if(response.data.response !== null) {
                    setUpdateData(response.data.response);
                } else {
                    resetScreen();
                }

            }).catch((e) => {
                resetScreen();
            })
        }
        setDataFetcher(new DrilldownDataFetcher(orgCmdProp.currentOrg));
        setFormKey(nanoid());
    },[updateDrillId,orgCmdProp,resetScreen])

    const handleAction = (tag:string, tagObject: any) => {
        if(tag === "DUPLICATE") {
            HttpClient.get("/api/v0/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/clone/drilldowns/" +  tagObject.id)
                .then((response) => {
                    let id = response.data.response;
                    history.push({
                        pathname: `${history.location.pathname}/${tagObject.drillName}/${id}`,
                        search: history.location.search
                    })
                })
        } else if(tag === "DOWNLOAD") {
            HttpClient.download(`Drilldown-${tagObject.viewName}-${tagObject.id}.gz`,
                "/api/v0/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/download/drilldowns/" +  tagObject.id);
        }
    }

    const newButton = <IconLinkButton tooltip={"New Drill Down"} key="newDrilldownButton" id="newDrilldownButton" data-action="createNewDrilldown" variant={"contained"} onClick={handleOpenNewDialog}>
        <SolidIcon className={styles.Icon} >add</SolidIcon>
    </IconLinkButton>;

    const uploadButton = <IconLinkButton tooltip={"Upload File"} key="uploadDrilldownButton" id="uploadDrilldownButton" data-action="uploadNewDrilldown" variant={"contained"} onClick={handleUploadDialog}>
        <OutlinedIcon className={styles.Icon} >cloud_upload</OutlinedIcon>
    </IconLinkButton>;

    const deleteButton = <ReverseSolidLinkSmallButton id="deleteDrilldownButton" data-action="deleteDrilldown" variant={"contained"} onClick={handleDelete}>
        <OutlinedIcon className={styles.Icon} >delete</OutlinedIcon>
        Remove ({deleteList.length})
    </ReverseSolidLinkSmallButton>;

    const downloadButton = <ReverseSolidLinkSmallButton id="downloadRoleButton" data-action="downloadRoles" variant={"contained"} onClick={handleDownloadList}>
        <OutlinedIcon className={styles.Icon} >download</OutlinedIcon>Download ({deleteList.length})
    </ReverseSolidLinkSmallButton>;

    return <React.Fragment>
        <div className={commonStyle.ContentContainer}>
            <PageSummary type={"drill-downs"} title={"Drill-Downs"} desc={"Description...."}/>

            <InputDialog
                id={"drilldown"}
                title={newOpen? "New Drill Down" : "Update Drill Down"}
                handleClose={newOpen ? handleCloseNewDialog : handleCloseUpdateDialog}
                handleSave={newOpen ? handleSaveDialog : handleUpdateDialog}
                open={newOpen || (updateData.id ? true : false)}
                inserted={newOpen}
                fields={[
                    {id: "drillName", label: "Name", defaultValue: updateData.id? updateData.drillName : "", required: true , editable: true},
                    {id: "drillImageURL", label: "Image URL (prefix)", defaultValue: updateData.id? updateData.drillImageURL : "", required: false , editable: true},
                    {id: "drillRootURL", label: "Root URL", defaultValue: updateData.id? updateData.drillRootURL : "", required: false , editable: true},
                    {id: "drillPrefixURL", label: "Drilldown URL (prefix)", defaultValue: updateData.id? updateData.drillPrefixURL : "", required: false , editable: true},
                    {id: "drillAbbr", label: "Abbr", defaultValue: updateData.id? updateData.drillAbbr : "", required: true , editable: true},
                ]}
                layouts={[
                    {label: "Main Info", ids:["drillName","drillAbbr","drillImageURL","drillRootURL","drillPrefixURL"]},
                ]}
                contentText={null}/>

            <StyledTable key={formKey}
                         handleAction={handleAction}
                         handleDeleteLists={(ids) => setDeleteList(ids)}
                         hasCheckBox={true}
                         dataFetcher={dataFetcher}
                         rowsPerPage={20}

                         title={"Drill Downs"}
                         actionComponents={[uploadButton,newButton]}
                         deleteButton={deleteButton}
                         downloadButton={downloadButton}

                         page={parseInt((query.get("page") === null ? "0" : query.get("page")!))}
                         rowsPerPageOptions={[5,10,20,40,60,{label: "All" , value: -1}]}/>
            <input key={`upload_${formKey}`} type='file' id='upload-content'
                   accept=".gzip,.gz"
                   onChange={() => doUploadFile()}
                   ref={inputFile} style={{display: 'none'}}/>
        </div>

    </React.Fragment>
}