import styles from "./NewLabel.module.scss"
import OutlinedIcon from "../../../../ui/icon/OutlinedIcon";
import React, {useState} from "react";
import {Tooltip} from "@mui/material";
import {SectionDesc} from "../../../model/desc/SectionDesc";
import {WidgetDesc} from "../../../model/desc/WidgetDesc";
import {StyleUtil} from "../../setting/StyleUtil";
import {useSSO} from "../../../../datafetcher/HttpClient";
import {GetCopiedObject} from "../../../../ui/editor/LiveEditor";
import {WidgetGroupType} from "../../../model/desc/WidgetGroupType";
import {GetDragLevelStr, GetDragType} from "../editable/EditablePanel";
import SectionOrWidgetPopover from "../../../../ui/popover/SectionOrWidgetPopover";

export default function NewLabel(props: {
                                label: string,
                                uiLevelStr: string,
                                onHandleCut?: (uiLevelStr: string) => void,
                                onInsertSection?: (uiLevelStr: string, desc: SectionDesc) => void,
                                onInsertWidget?: (uiLabelStr: string, desc: WidgetDesc) => void
}) {
    const ssoCmd = useSSO();
    const [anchorEl, setAnchorEl] = useState<HTMLSpanElement | null>(null);
    const [hasSection] = useState(props.uiLevelStr.split("_").length <= 3);
    const [isDrag, setIsDrag] = useState<boolean>(false);
    const handleOnClickAdd = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleOnClose = (event:any) => {
        setAnchorEl(null);
        event.stopPropagation();
        event.preventDefault();
    }
    //
    // const handleChooseWidget = (action: string) => {
    //     if(props.onInsertWidget) {
    //         let widgetDataFetcher = widgetDescriptionsDataFetcher();
    //         let widgetDesc = widgetDataFetcher.find((item) => item.widgetName === action);
    //         if(widgetDesc) {
    //             props.onInsertWidget(props.uiLevelStr, widgetDesc);
    //         }
    //     }
    // }

    const handleChooseClipboard = () => {
        let clonedObject = GetCopiedObject()
        if(props.onInsertWidget && clonedObject) {

            let widgetDesc:WidgetDesc = {
                widgetType: "",
                widgetName: "",
                widgetNickName: "",
                widgetDescription: "",
                widgetIcon: "",
                widgetGroupType: WidgetGroupType.unspecfic,
                widgetExperimental: false,
                clonedObject: clonedObject
            }
            props.onInsertWidget(props.uiLevelStr,widgetDesc);
        }
    }
    //
    // const handleChooseRegularSection = (action: string) => {
    //     if(props.onInsertSection) {
    //         let sectionDataFetcher = sectionDescriptionsDataFetcher();
    //         let sectionDesc = sectionDataFetcher.find((item) => item.sectionName === action);
    //         if(sectionDesc) {
    //             props.onInsertSection(props.uiLevelStr, sectionDesc);
    //         }
    //     }
    // }
    // const handleChooseSection = (columns: number, columnWidths: string[]) => {
    //     if(props.onInsertSection) {
    //         let sectionDataFetcher = sectionDescriptionsDataFetcher();
    //         let sectionDesc = sectionDataFetcher.find((item) => item.sectionName === "DefaultSection");
    //         if(sectionDesc) {
    //             let widthArray:any = {};
    //             columnWidths.forEach((width,index) => {
    //                 widthArray[`column${index+1}-width`] = columnWidths[index];
    //             })
    //             props.onInsertSection(props.uiLevelStr, { ...sectionDesc ,
    //                 sectionInitProps: {
    //                     content: {
    //                         'layout-break': 300,
    //                         columns: columns,
    //                         layout: "horizontal",
    //                         'justify-content': 'center',
    //                         'align-items': 'start',
    //                         ...widthArray
    //                     }
    //                 }});
    //         }
    //     }
    // }

    const handleOnDrop = (event: React.DragEvent) => {

        if(GetDragType().endsWith("widget")) {
            let level = event.dataTransfer.getData("text");
            let type = event.dataTransfer.getData("type");
            if (props.onHandleCut) props.onHandleCut(level)
            handleChooseClipboard();
        } else if(props.uiLevelStr.startsWith(GetDragLevelStr())) { // parent

        } else if(props.uiLevelStr.split("_").length === 3) {
            let level = event.dataTransfer.getData("text");
            let type = event.dataTransfer.getData("type");
            if (props.onHandleCut) props.onHandleCut(level)
            handleChooseClipboard();
        }

        setIsDrag(false);
        event.preventDefault();
        event.stopPropagation();
    }

    const handleDragOver = (event: React.DragEvent) => {
        event.dataTransfer.effectAllowed = "copyMove";
        event.dataTransfer.dropEffect = "move";
        let dragType = GetDragType();
        if(dragType === "widget") {
            setIsDrag(true);
        } else { // section
            if(props.uiLevelStr.startsWith(GetDragLevelStr())) { // parent
                setIsDrag(false)
            } else {
                setIsDrag(props.uiLevelStr.split("_").length === 3);
            }
        }
        event.preventDefault();
        event.stopPropagation();
    }
    const handleDragLeave = (event: React.DragEvent) => {
        setIsDrag(false);
        event.preventDefault();
        event.stopPropagation();
    }
    const handleDragExit = (event: React.DragEvent) => {
        setIsDrag(false)
        event.preventDefault();
        event.stopPropagation();
    }

    const open = Boolean(anchorEl);
    let copiedObject = GetCopiedObject();
    if(copiedObject && hasSection === false && 'sectionId' in copiedObject) {
        copiedObject = undefined;
    }
    return <div className={styles.Root}
                onDrop={handleOnDrop}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDragExit={handleDragExit}
    >
        {/*<div className={styles.EmptyLabel}>&pi;</div>*/}
        <Tooltip title={"Add widget" + (hasSection ? " or section" : "")}><span tabIndex={-1}
              className={StyleUtil.combineClasses(styles.Icon,hasSection ? styles.IconWithParent : "", isDrag ? styles.DragOver : "")}
              // style={anchorEl !== null ? { pointerEvents: 'none' } : {}}
              onClick={handleOnClickAdd}
        >
                        <OutlinedIcon>add</OutlinedIcon>
        </span></Tooltip>
        { open && <SectionOrWidgetPopover
            open={open}
            anchorEl={anchorEl}
            onClose={handleOnClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center"
            }}
            className={styles.Popover}
            uiLevelStr={props.uiLevelStr}
            onInsertSection={props.onInsertSection}
            onInsertWidget={props.onInsertWidget}
        />}
        {/*<Popover*/}
        {/*    open={open}*/}
        {/*    anchorEl={anchorEl}*/}
        {/*    onClose={handleOnClose}*/}
        {/*    anchorOrigin={{*/}
        {/*        vertical: 'bottom',*/}
        {/*        horizontal: 'center',*/}
        {/*    }}*/}
        {/*    transformOrigin={{*/}
        {/*        vertical: "top",*/}
        {/*        horizontal: "center"*/}
        {/*    }}*/}
        {/*    className={styles.Popover}*/}
        {/*    onDoubleClick={(event) => {event.stopPropagation(); event.preventDefault();}}*/}
        {/*    onClick={(event) => {event.stopPropagation(); event.preventDefault();}}*/}
        {/*>*/}
        {/*    <Grid container spacing={1} className={styles.Card}>*/}
        {/*        {widgetDescriptionsDataFetcher().filter(data => data.widgetExperimental === false).map((data,index) => <Grid key={`newlabel_grid_${index}`}item xs={3}>*/}
        {/*            <WidgetIcon tooltip={data.widgetNickName} label={data.widgetNickName} icon={data.widgetIcon} action={data.widgetName} onClick={handleChooseWidget}/>*/}
        {/*            </Grid>*/}
        {/*        )}*/}
        {/*        {copiedObject && <Grid item xs={3}>*/}
        {/*            <WidgetIcon tooltip={"Clipboard"} label={"Clipboard"} icon={"content_paste"}*/}
        {/*                        action={"Cliboard"} onClick={handleChooseClipboard}/>*/}
        {/*        </Grid>}*/}
        {/*    </Grid>*/}
        {/*    {ssoCmd.roleName === "LSIADMIN" && <><Divider/>*/}
        {/*        <span className={styles.CardTitle}>Experimental Widgets <span className={styles.CardSubTitle}>(internal)</span></span>*/}

        {/*        <Grid container spacing={1} className={styles.Card}>*/}
        {/*            {widgetDescriptionsDataFetcher().filter(data => data.widgetExperimental === true).map((data, index) =>*/}
        {/*                <Grid key={`newlabel_grid_${index}`} item xs={3}>*/}
        {/*                    <WidgetIcon tooltip={data.widgetNickName} label={data.widgetNickName} icon={data.widgetIcon}*/}
        {/*                                action={data.widgetName} onClick={handleChooseWidget}/>*/}
        {/*                </Grid>*/}
        {/*            )}*/}
        {/*        </Grid></>}*/}
        {/*    { hasSection && <><Divider/>*/}
        {/*        <span className={styles.CardTitle}>Predefined Sections</span>*/}
        {/*        <Grid container spacing={1} className={styles.Card}>*/}
        {/*            <Grid item xs={3}><ColumnIcon onClick={handleChooseSection} columns={1} columnWidths={["100%"]} tooltip={"1 column"} label={"100"} /></Grid>*/}
        {/*            <Grid item xs={3}><ColumnIcon onClick={handleChooseSection} columns={2} columnWidths={["50%","50%"]} tooltip={"2 columns"} label={"2*50"} /></Grid>*/}
        {/*            <Grid item xs={3}><ColumnIcon onClick={handleChooseSection} columns={3} columnWidths={["33%","33%","33%"]}  tooltip={"3 columns"} label={"3*33"} /></Grid>*/}
        {/*            <Grid item xs={3}><ColumnIcon onClick={handleChooseSection} columns={2} columnWidths={["40%","60%"]} tooltip={"2 columns"} label={"40+65"} /></Grid>*/}
        {/*            <Grid item xs={3}><RowIcon onClick={() => handleChooseRegularSection("Vertical")} rows={3} rowHeights={["33%","33%","33%"]} tooltip={"Rows"} label={"Rows"} /></Grid>*/}
        {/*            /!*{sectionDescriptionsDataFetcher().filter(data => data.sectionName === "Vertical").map((data,index) => <Grid key={`newsection_grid_${index}`}item xs={3}>*!/*/}
        {/*            /!*        <WidgetIcon tooltip={data.sectionNickName} label={data.sectionNickName} icon={data.sectionIcon} action={data.sectionName} onClick={handleChooseRegularSection}/>*!/*/}
        {/*            /!*    </Grid>*!/*/}
        {/*            /!*)}*!/*/}
        {/*        </Grid></>*/}
        {/*    }*/}
        {/*</Popover>*/}
    </div>
}