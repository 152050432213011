import {WidgetInterface} from "../../../model/WidgetInterface";
import {useAppSelector} from "../../../../redux/hook";
import {selectViewState} from "../../../../redux/features/view/viewSlice";
import React, {useCallback, useEffect, useState} from "react";
import {nanoid} from "nanoid";
import {DeleteType} from "../../../model/DeleteOption";
import {SettingGroupType, SettingOption, SettingType} from "../../../model/SettingOption";
import EditablePanel from "../../shared/editable/EditablePanel";
import styles from "./ProductQuery.module.scss";
import DrillItemCmd from "../../../model/DrillItemCmd";
import useWindowSize from "../../../hook/useWindowSize";
import {jcr} from "../../../../datafetcher/JCRDataFetcher";
import {StyleUtil} from "../../setting/StyleUtil";
import {lsi} from "../../../../datafetcher/LSIDataFetcher";
import {FieldNameToName} from "../../../../util/StringUtil";
import {useOrganization} from "../../../../hook/useOrganization";
import gridIcon from "./grid.png";
import listIcon from "./list.png";
import {QueryType} from "../../../model/QueryType";
import useWidgetState from "../../../../hook/useWidgetState";

export default function ProductQuery(props:any) {
    const widgetData:WidgetInterface = props.data as WidgetInterface;
    const {widgetId,widgetProps} = widgetData;
    const viewState = useAppSelector(selectViewState);
    const orgCmd = useOrganization();
    const [imagePrefix,setImagePrefix] = useState<string>("");
    const windowSize = useWindowSize();
    const [isGridDisplay,setGridDisplay] = useState<boolean>(true);
    const [fqAll,setFQAll] = useState<Set<string>>(new Set<string>());
    const [refreshId, setRefreshId] = useState<string>(nanoid());
    const [defaultNumberOfItem,setDefaultNumberOfItem] = useState<number>(widgetProps['content'] && widgetProps['content']['defaultNumberOfItem'] !== undefined ? widgetProps['content']['defaultNumberOfItem']: 12);

    useWidgetState(widgetId, (event) => {
        if(event && event.detail) {
            if("preview" === event.detail.action) {
                let drilldown = event.detail.value.drilldown;
                let previewId = event.detail.value.previewId;
                widgetData.widgetProps["content"]['previewDrilldown'] = drilldown;
                widgetData.widgetProps["content"]['previewId'] = previewId;
                setDrillInfo({drilldown: drilldown, previewId: previewId})
            } else {
                let id = nanoid();
                setRefreshId(id);
                setTimeout(() => {
                    let element = document.getElementById("pq_" + id);
                    updateScreen(element);
                },200);
            }
        } else {
            let id = nanoid();
            setRefreshId(id);
            setTimeout(() => {
                let element = document.getElementById("pq_" + id);
                updateScreen(element);
            },200);
        }
    })
    const [search, setSearch] = useState<any>(null);
    const expectedWidth = isGridDisplay ? 800 : 650;

    const hasProduct = search && search.response && search.response.docs && search.response.docs.length > 0;

    if(StyleUtil.isDefaultStyle(widgetProps['panel'])) {
        widgetProps['panel'] = {
            style: {
                default: true,
                margin: {
                    "margin-inline-start": "5%",
                    "margin-inline-end": "5%"
                }
            }
        };
    }

    if(StyleUtil.isDefaultStyle(widgetProps['facet_panel'])) {
        widgetProps['facet_panel'] = {
            style: {
                default: true,
            },
        };
    }

    if(StyleUtil.isDefaultStyle(widgetProps['query_panel'])) {
        widgetProps['query_panel'] = {
            style: {
                default: true,
            }
        };
    }

    if(StyleUtil.isDefaultStyle(widgetProps['refinement_panel'])) {
        widgetProps['refinement_panel'] = {
            style: {
                default: true,
                background : {
                    'background-color': '#ebeef4'
                },
                dimension: {
                    'min-width': '250px'
                },
                typography : {
                    color: 'black',
                    'font-size': '14px'
                },
                border: {
                  'border-radius': '3px',
                },
                padding : {
                    'padding-inline-start' : '0.8rem',
                    'padding-inline-end' : '0.8rem',
                    'padding-block-start' : '0.5rem',
                    'padding-block-end' : '0.5rem'
                }
            }
        };
    }

    if(StyleUtil.isDefaultStyle(widgetProps['pagination_panel'])) {
        widgetProps['pagination_panel'] = {
            style: {
                default: true,
                typography : {
                    color: 'black',
                    'font-size': '14px'
                }
            }
        };
    }

    if(StyleUtil.isDefaultStyle(widgetProps['action_panel'])) {
        widgetProps['action_panel'] = {
            style: {
                default: true,
                background : {
                    'background-color': '#ebeef4'
                },
                border: {
                    'border-radius': '3px',
                },
                padding : {
                    'padding-inline-start' : '0.8rem',
                    'padding-inline-end' : '0.8rem',
                    'padding-block-start' : '0.5rem',
                    'padding-block-end' : '0.5rem'
                },
                typography : {
                    color: 'black',
                    'font-size': '14px'
                },
            }
        };
    }

    if(StyleUtil.isDefaultStyle(widgetProps['result_panel'])) {
        widgetProps['result_panel'] = {
            style: {
                default: true,

                padding : {
                    'padding-inline-start' : '0.8rem',
                    'padding-inline-end' : '0.8rem',
                    'padding-block-start' : '0.5rem',
                    'padding-block-end' : '0.5rem'
                }
            }
        };
    }

    if(StyleUtil.isDefaultStyle(widgetProps['image'])) {
        widgetProps['image'] = {
            style: {
                default: true,
                position: {
                    display: "block"
                },
                dimension: {
                    width: "100%",
                    height: "100px",
                    'max-height': "100px",
                },
                background: {
                    'background-color': 'transparent',
                },
                object: {
                    'object-fit': 'contain'
                }
            }
        };
    }

    if(StyleUtil.isDefaultStyle(widgetProps['breadcrumb_panel'])) {
        widgetProps['breadcrumb_panel'] = {
            style: {
                default: true,
                typography: {
                    "font-size": "0.8rem",
                    "line-height": "1.8rem"
                },
                margin: {
                    "margin-block-start":"20px",
                    "margin-block-end": "15px",
                }
            }
        };
    }

    const [currentDrill,setCurrentDrill] = useState<{
        root: DrillItemCmd,
        current: DrillItemCmd,
        path: DrillItemCmd[],
    }|null>(null);

    const [drillInfo, setDrillInfo] = useState<{
        drilldown : string,
        previewId: string
    }>({
        drilldown: widgetData.widgetProps && widgetData.widgetProps['content'] && widgetData.widgetProps['content']['previewDrilldown'] ? widgetData.widgetProps['content']['previewDrilldown'] : "",
        previewId: widgetData.widgetProps && widgetData.widgetProps['content'] && widgetData.widgetProps['content']['previewId'] ? widgetData.widgetProps['content']['previewId'] : ""
    });



    const cols:number = widgetProps['content'] && widgetProps['content']['maxCols']  ? parseInt(widgetProps['content']['maxCols']) : 4;
    const hasBreadCrumb:boolean = widgetProps['content'] && widgetProps['content']['hasBreadCrumb'] !== undefined ? widgetProps['content']['hasBreadCrumb']: true;
    const fullSizeBreadcrumb:boolean = widgetProps['content'] && widgetProps['content']['fullSizeBreadcrumb'] !== undefined ? widgetProps['content']['fullSizeBreadcrumb']: false;
    const hasFacet:boolean = widgetProps['content'] && widgetProps['content']['hasFacet'] !== undefined ? widgetProps['content']['hasFacet']: true;
    const breadcrumbSeparator:string = widgetProps['content'] && widgetProps['content']['breadcrumbSeparator'] !== undefined ? widgetProps['content']['breadcrumbSeparator']: "/";
    const breadcrumbSpace:string = widgetProps['content'] && widgetProps['content']['breadcrumbSpace'] !== undefined ? widgetProps['content']['breadcrumbSpace']: "0.5rem";
    const numberOfItems:number[] = widgetProps['content'] && widgetProps['content']['numberOfItems'] !== undefined ? widgetProps['content']['numberOfItems']: [12,30,60];
    // const defaultNumberOfItem:number = widgetProps['content'] && widgetProps['content']['defaultNumberOfItem'] !== undefined ? widgetProps['content']['defaultNumberOfItem']: 12;
    const productPrefix:string = widgetProps['content'] && widgetProps['content']['defaultNumberOfItem'] !== undefined ? widgetProps['content']['productPrefix']: 12;
    const idField = widgetProps['content'] && widgetProps['content']['idField'] ? widgetProps['content']['idField'] : "_lsi_cn_ProductID";
    const imageField = widgetProps['content'] && widgetProps['content']['imageField'] ? widgetProps['content']['imageField'] : "_lsi_cn_ImageThumb";
    const titleField = widgetProps['content'] && widgetProps['content']['titleField'] ? widgetProps['content']['titleField']: "_lsi_cn_ProductID";
    const descField = widgetProps['content'] && widgetProps['content']['descField'] ? widgetProps['content']['descField'] : "_lsi_cn_Description";
    const priceField = widgetProps['content'] && widgetProps['content']['priceField'] ? widgetProps['content']['priceField'] : "_lsi_cn_Price";
    const uomField = widgetProps['content'] && widgetProps['content']['uomField'] ? widgetProps['content']['uomField'] : "_lsi_cn_PriceUOM";
    const emptyMessage = widgetProps['content'] && widgetProps['content']['emptyMessage'] ? widgetProps['content']['emptyMessage'] : "There are no stocked products for this category.";

    const findDrillDown = (drillData: DrillItemCmd) => {
        let drillArray: {data:DrillItemCmd,path:DrillItemCmd[]}[] = [];
        drillArray.push({data: drillData, path: [drillData]});
        let found:boolean = false;
        while (drillArray.length > 0) {
            let item = drillArray.pop();
            if (item) {
                if (item.data.indexId === drillInfo.previewId) {
                    found = true;
                    if(currentDrill === null) {
                        setCurrentDrill({root: drillData, current: item.data, path: item.path});
                    } else if(currentDrill.current.indexId !== item.data.indexId) {
                        setCurrentDrill({root: drillData, current: item.data, path: item.path});
                    }
                    break;
                } else {
                    item.data.subItems.forEach(i => drillArray.push({data: i, path: [...item!.path, i]}));
                }
            }
        }
        if(found === false) {
            setCurrentDrill({root: drillData, current: drillData, path: [drillData]});
        }
    }

    const loadDrill = () => {
        if(drillInfo.drilldown && drillInfo.drilldown !== "") {
            console.log("DRILL DOWN SAERACH");
            jcr.drilldown.query(drillInfo.drilldown).then((response) => {
                setImagePrefix(response.data.response.drillImageURL);
                let drillData: DrillItemCmd = JSON.parse(response.data.response.drillData);
                findDrillDown(drillData);
            });
        } else if(drillInfo.drilldown === "" && drillInfo.previewId !== "") {
            // console.log("PREVIEW ID " + drillInfo.previewId);
            lsi.search.widgetFacetSearch(
                QueryType.search,
                drillInfo.previewId,
                0,
                defaultNumberOfItem,
                "")?.then(response => {
                    setSearch(response.data);
            })
        }

    }

    useEffect(() => {
        if(currentDrill !== null) {
            let current = currentDrill.current;
            lsi.search.widgetFacetSearch(
                current.searchType,
                current.searchString,
                0,
                defaultNumberOfItem,
                current.searchProfile,
            )?.then(response => {
                // console.log(response.data);
                setSearch(response.data);
            })
        }
    },[currentDrill,defaultNumberOfItem]);

    useEffect(() => {
        if(currentDrill === null) {
            loadDrill();
        } else if(currentDrill.root.indexId === drillInfo.drilldown) {
            findDrillDown(currentDrill.root);
        } else {
            loadDrill();
        }
    },[drillInfo])

    function updateScreen(node:any) {
        if(node === null) return;
        let rootElement = node;
        let rootBoundary = rootElement.getBoundingClientRect();
        let left_panel = rootElement.querySelector("[id='left_panel']");
        let query_panel = rootElement.querySelector("[id='query_panel']")
        let refinement_panel = rootElement.querySelector("[id='refinement_panel']");
        let hasFacet = widgetProps && widgetProps.content && widgetProps.content.hasFacet !== undefined ? widgetProps.content.hasFacet : true;
        if (rootBoundary.width < expectedWidth) {
            if (isGridDisplay === false) setGridDisplay(true);
            if (rootElement.getAttribute("data-panel-size") !== "small") {
                rootElement.setAttribute("data-panel-size", "small")
                rootElement.querySelectorAll("[id^='facet_panel']").forEach((node: any) => node.style.maxHeight = "0px")
            }
            if (refinement_panel) {
                refinement_panel.classList.add(styles.FacetPanelSmall);
            }
            if (hasFacet && left_panel) {
                left_panel.classList.add(styles.FacetListFullPanel)
                left_panel.classList.add(styles.LeftPanelTop);
            }
            if (query_panel) {
                query_panel.classList.add(styles.QueryPanelFull);
            }
        } else {
            if (rootElement.getAttribute("data-panel-size") !== "large") {
                rootElement.querySelectorAll("[id^=facet_panel]").forEach((node: any) => node.style.maxHeight = "unset")
                rootElement.setAttribute("data-panel-size", "large")
            }
            if (refinement_panel) {
                refinement_panel.classList.remove(styles.FacetPanelSmall);
            }
            if (hasFacet && left_panel) {
                left_panel.classList.remove(styles.FacetListFullPanel)
                left_panel.classList.remove(styles.LeftPanelTop)
            }

            if (query_panel) {
                if (hasFacet === false) {
                    query_panel.classList.add(styles.QueryPanelFull);
                } else {
                    query_panel.classList.remove(styles.QueryPanelFull);
                }
            }
        }
    }

    const rootRef = useCallback((node) => {
        if(windowSize && node) {
            updateScreen(node);
        }
    },[windowSize]);

    const themeName = "product_query"
    let widgetStyles:any = {
        theme: StyleUtil.toThemeNameByType(widgetProps, themeName),
        current: StyleUtil.toClassFromWidget(widgetId , widgetProps, "current_style"),
        panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "panel"),
        breadcrumb_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "breadcrumb_panel"),
        facet_list_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "facet_list_panel"),
        facet_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "facet_panel"),
        query_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "query_panel"),
        refinement_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "refinement_panel"),
        pagination_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "pagination_panel"),
        action_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "action_panel"),
        result_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "result_panel"),
        item_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "item_panel"),
        image_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "image_panel"),
        image: StyleUtil.toClassFromWidget(widgetId , widgetProps, "image"),
        banner_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "banner_panel"),
        banner_image: StyleUtil.toClassFromWidget(widgetId , widgetProps, "banner_image"),
        title: StyleUtil.toClassFromWidget(widgetId , widgetProps, "title"),
        description: StyleUtil.toClassFromWidget(widgetId , widgetProps, "description"),
        drilldown_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "drilldown_panel"),
        drilldown_items_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "drilldown_items_panel"),
        drilldown_link: StyleUtil.toClassFromWidget(widgetId , widgetProps, "drilldown_link"),
        drilldown_title: StyleUtil.toClassFromWidget(widgetId , widgetProps, "drilldown_title"),
        drilldown_items_link: StyleUtil.toClassFromWidget(widgetId , widgetProps, "drilldown_items_link"),
        drilldown_small_level1: StyleUtil.toClassFromWidget(widgetId , widgetProps, "drilldown_small_level1"),
        empty_panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "empty_panel"),
        product_panel_custom: StyleUtil.toClassFromMap(widgetId, "product_panel_custom", {
            'flex': `1 1 calc((100% - ((${cols} - 1) * 20px))/ ${cols})`
        }),
        breadcrumb_style1: StyleUtil.toClassFromMap(widgetId, "breadcrumb_panel li::after", {
            'content': "'" + breadcrumbSeparator + "'",
            'padding-inline': breadcrumbSpace
        }),
        breadcrumb_style2: StyleUtil.toClassFromMap(widgetId, "breadcrumb_panel li:last-child::after", {
            'content': "unset",
            'padding-inline': 'unset'
        }),
        product_list_custom: StyleUtil.toClassFromMap(widgetId, "product_list_custom", {
            'flex-direction': isGridDisplay ? "row" : "column"
        }),
    }

    const handleClickAccordion = (event:React.MouseEvent<HTMLElement>) => {
        let current = event.currentTarget;
        let element:HTMLDivElement = event.currentTarget.parentElement?.querySelector(`[id='facet_panel']`) as HTMLDivElement;
        if(element) {
            if(element.style.maxHeight === "0px" || element.style.maxHeight === "") {
                element.style.maxHeight = element.scrollHeight + "px";
            } else {
                element.style.maxHeight = "0px"
            }
        }
    }

    const handleClickFacetAccordion = (event:React.MouseEvent<HTMLLIElement>) => {
        let index = event.currentTarget.getAttribute("data-index");
        let element:HTMLDivElement = event.currentTarget.querySelector(`[id=${index}]`) as HTMLDivElement;
        if(element) {
            let firstA:any = event.currentTarget.getElementsByTagName("a");
            if(firstA && firstA.length > 0) {
                firstA = firstA[0];
            }
            if(element.style.maxHeight === "0px" || element.style.maxHeight === "") {
                element.style.maxHeight = element.scrollHeight + "px";
                firstA.classList.add(styles.FacetPanelActive)
                firstA.classList.add("active");
            } else {
                element.style.maxHeight = "0px"
                firstA.classList.remove(styles.FacetPanelActive)
                firstA.classList.remove("active");
            }
        }
    }

    const handleClickFacetItem = (event:React.MouseEvent<HTMLLIElement>) => {
        let value = event.currentTarget.getAttribute("data-value") as string;
        let newFQAll:Set<string> = new Set<string>(fqAll);
        if(newFQAll.has(value)) {
            newFQAll.delete(value);
        } else {
            newFQAll.add(value);
        }
        setFQAll(newFQAll);
        event.stopPropagation();
        event.preventDefault();
    }

    const handleUpdateFacets = (event:React.MouseEvent<HTMLAnchorElement>) => {

        event.stopPropagation();
        event.preventDefault();
    }

    const handleRemoveAllFacetFilters = () => {
        setFQAll(new Set<string>());
    }

    const handleRemoveFacetFilter = (data: string) => {
        let newFQAll = new Set(fqAll);
        newFQAll.delete(data);
        setFQAll(newFQAll);
    }

    const createFacet = () => {
        if(search === null || hasProduct === false) return <></>
        let facetFields:any = search.facet_counts.facet_fields;
        let allFacets:any[] = [];
        for(const [key,value] of Object.entries(facetFields)) {
            let array = value as any[];
            if(array!.length > 0) {
                let newArray:any = [];
                for(let i=0;i<array.length;i+=2) {
                    newArray.push({name: array[i],count: array[i+1]});
                }
                allFacets.push({ id: key, array: newArray});
            }
        }

        let fieldNameToDisplayName = orgCmd?.orgDetail?.fieldNameToDisplayName;
        return <div id={"left_panel"} className={StyleUtil.combineClasses("pq_facet_list_panel",widgetStyles.facet_list_panel,styles.FacetListPanel)}>
                <h3 className={StyleUtil.combineClasses("pq_refinement_title")} onClick={handleClickAccordion}>Refine Your Search</h3>

                {   fqAll && fqAll.size > 0 &&
                    <div className={StyleUtil.combineClasses("pq_facet_filter_panel",styles.FacetFilterPanel)}>
                        <a title="Clear All Filters" onClick={handleRemoveAllFacetFilters}><i className="fa fa-times-circle"></i> Clear All</a>
                        <h4>Refined by</h4>
                        {
                            Array.from(fqAll).map((obj,index) => {
                                let cIndex = obj.indexOf(':')
                                if(cIndex < 0) return <></>
                                let filterName = obj.substring(0,cIndex);
                                let filterValue = obj.substring(cIndex+1);
                                return <a key={"filter_" + index} onClick={() => handleRemoveFacetFilter(obj)} title="Remove Filter">
                                    <i className="fa fa-times-circle"></i> <span className="field-name">{filterName}&nbsp;&gt;</span> <span className="field-value">{filterValue}</span>
                                </a>
                            })
                        }
                    </div>
                }

                <ul id={"facet_panel"} className={StyleUtil.combineClasses("pq_facet_panel",styles.FacetPanel)}>
                    {allFacets.map((facet,index) => {
                        let name = null;
                        if(fieldNameToDisplayName) {
                            name = fieldNameToDisplayName.get(facet.id);
                        }
                        if(name === null || name === undefined) {
                            name = FieldNameToName(facet.id)
                        }
                        return <li key={`facet_${index}`} data-index={`facet_subpanel_${index}`}
                            onClick={handleClickFacetAccordion}><a rel="nofollow"
                                                                   ><span>{name}</span></a>
                            <div className={styles.AccordionClosed} id={`facet_subpanel_${index}`}>
                                <ul>
                                    {facet.array.map((option: any, index2: number) => {
                                        let dataValue = facet.id + ":" + option.name;
                                        return <li
                                            onClick={handleClickFacetItem}
                                            data-value={dataValue}
                                            key={`facet_${index}_option_${index2}`} className={styles.FacetItem}>
                                            <i className={StyleUtil.combineClasses("fa", fqAll.has(dataValue) ? "fa-check-square-o" : "fa-square-o")}>&nbsp;</i>
                                            <a className="facet-link" id="lnk1" rel="nofollow" title="">{FieldNameToName(option.name)} ({option.count})
                                        </a>
                                    </li>
                                    })
                                    }
                                </ul>
                                <div className={styles.FacetButtonPanel}><a rel="nofollow"
                                                                            onClick={handleUpdateFacets}
                                                                            className="btn btn_update">Update Search</a></div>
                            </div>
                        </li>
                    })}
                </ul>
        </div>
    }

    const createActionBar = () => {
        return <div className={StyleUtil.combineClasses("pg_action_panel",widgetStyles.action_panel)}>
            <a   className="btn btn_estimate" title="Add to Active Estimate'my first estimate'">Add to Active Estimate</a>
            <a  className="btn btn_compare" title="Compare Products">Compare</a>
            <a  className="btn btn_checkout">Checkout</a>
        </div>
    }


    const createProductList = () => {
        if(search !== null) {
            let docs:any[] = search.response.docs;
            return <div className={StyleUtil.combineClasses(styles.ProductListPanel,"pq_product_list_panel",widgetStyles.product_list_custom)}>
                {
                    docs.map(doc => createProductItem(doc))
                }
            </div>
        } else {
            return <></>
        }
    }
    const createProductItem = (doc:any) => {
        let img = doc[imageField];
        let title = doc[titleField];
        let desc = doc[descField];
        let id = doc[idField];

        return <div key={`product_${id}`} className={StyleUtil.combineClasses(isGridDisplay ? styles.ProductPanel : styles.ListProductPanel, "pq_product_panel", widgetStyles.product_panel_custom)}>
            <div className={StyleUtil.combineClasses(isGridDisplay ? styles.ProductImagePanel : styles.ListProductImagePanel,"pq_product_image_panel")}>
                <a  title="">
                    <img className={StyleUtil.combineClasses(styles.ProductImage, "pq_product_image", widgetStyles.image)}
                         src={img}
                         alt=""/>
                </a>
            </div>
            <div className={StyleUtil.combineClasses(isGridDisplay ? styles.ProductInfoPanel : styles.ListProductInfoPanel ,"pq_product_info_panel")}>
                <a   title="" className={"pq_product_info_title"}>{title}</a>
                <div className="pq_product_info_desc">
                    {desc}
                </div>
            </div>
            {/*<div className="stars">*/}
            {/*    <i>*/}
            {/*        <img src="images/site/star.png" alt="star"/>*/}
            {/*        <img src="images/site/star.png" alt="star"/>*/}
            {/*        <img src="images/site/star.png" alt="star"/>*/}
            {/*        <img src="images/site/star.png" alt="star"/>*/}
            {/*        <img src="images/site/star.png" alt="star"/>*/}
            {/*    </i>*/}
            {/*    <span className="view"><a href="" className="view">(0reviews)</a></span>*/}
            {/*</div>*/}
            <div className={StyleUtil.combineClasses(isGridDisplay ? styles.ProductQty : styles.ListProductQty,"pq_product_qty")} >
                <input readOnly className="inputQtyBody addqty" size={2} type="text" name="addqty0" id="addqty0" value=""/> Qty
            </div>
            <div className={StyleUtil.combineClasses(isGridDisplay ? styles.ProductPrice : styles.ListProductPrice,"pq_product_price")}>
                <span><b>Price:</b></span><span> $35.86</span><span>/EA</span><br/>
                <span><b>Availability:</b></span><span> 535 </span><span>EA</span><br/>
                <span className="prodbutton">
                    <a   className="btn btn_add_to_card"
                       title="Add Selected Products to My Cart">Add to Cart</a>
                    </span>
            </div>
            <div className={StyleUtil.combineClasses(isGridDisplay ? styles.ProductCompare : styles.ListProductCompare,"pq_product_compare")}>
                <input readOnly type="checkbox" name="compare0"  value="yes"/> compare
            </div>
        </div>
    }

    const createNavigation = () => {
        let isSmall = windowSize.width < expectedWidth;
        return <div className={StyleUtil.combineClasses(isSmall ? "" : styles.PaginationPanel,"pq_pagination_panel",widgetStyles.pagination_panel)}>
            <div className={StyleUtil.combineClasses(isSmall ? styles.PaginationSectionFill : styles.PaginationSection)}>1 - 3 of {search ? search.response.numFound : 3} Items</div>
            <div className={StyleUtil.combineClasses(isSmall ? styles.PaginationSectionHidden: styles.PaginationSection)}>
                <a onClick={() => {setGridDisplay(true)}} className={StyleUtil.combineClasses(styles.PaginationButton , isGridDisplay ? styles.Active : "", "pq_grid_button" ,isGridDisplay ? "active" : "")} title=""><img src={gridIcon}/></a>
                <a onClick={() => {setGridDisplay(false)}}className={StyleUtil.combineClasses(styles.PaginationButton, isGridDisplay ? "" : styles.Active,"pq_list_button",isGridDisplay ? "" : "active")} title="View Items as List"><img src={listIcon}/></a>
            </div>
            <div className={StyleUtil.combineClasses(isSmall ? styles.PaginationSectionHidden: styles.PaginationSection)}>
                <select name="itemsperpage" className={styles.SelectInput} value={defaultNumberOfItem} onChange={(event) => { setDefaultNumberOfItem(parseInt(event.currentTarget.value))}}>
                    { numberOfItems.map(item => <option key={item} value={item}>{item}</option>) }
                </select>
                <span> Items per page</span>
            </div>
            <div className={StyleUtil.combineClasses(isSmall ? styles.PaginationSectionHidden: styles.PaginationSection)}>
                Page 1 of 1
            </div>
        </div>
    }
    const createRefinement = () => {
        return <div className={StyleUtil.combineClasses("pq_refinement_panel",widgetStyles.refinement_panel)}>
            <div className={windowSize.width > 500 ? styles.NarrowPanel : styles.NarrowPanelFill}>
                Narrow Results By:
            </div>
            <div className={windowSize.width > 500 ? styles.NarrowPanel : styles.NarrowPanelFill}>
                <div className={styles.RefinementSection}>
                    <select className={styles.SelectInput}>
                        <option value="string">Keyword</option>
                    </select>
                </div>
                <div className={styles.RefinementSection}>
                    <input readOnly className={styles.TextInput} type="text" value=""/>
                </div>
                <div className={styles.RefinementSection}>
                    <a>Go ▸</a>
                </div>
            </div>
            <div className={styles.SortByPanel}>
                <div className={styles.RefinementSection}>
                    Sort By:
                </div>
                <div className={styles.RefinementSection}>
                    <select className={styles.SelectInput}>
                        <option value="default">Closest Match</option>
                        <option value="descriptionAZ">Description</option>
                        <option value="priceLH">Price</option>
                        <option value="mostPop">Most Popular</option>
                        <option value="availSort">Availability</option>
                    </select>
                </div>
            </div>
        </div>
    }

    const createQueryPanel = () => {
        if(currentDrill !== null || search !== null) {
            let current = currentDrill ? currentDrill.current : null;
            let navigation = hasProduct ? createNavigation() : <></>;
            let actionBar = hasProduct ? createActionBar() : <></>;
            let emptyPanel = hasProduct ? <></> : <div className={StyleUtil.combineClasses(styles.EmptyPanel,"empty_panel")}>{emptyMessage}</div>;
            return <div id={"query_panel"} className={StyleUtil.combineClasses("pq_query_panel",widgetStyles.query_panel,styles.QueryPanel)}>
                {currentDrill && fullSizeBreadcrumb === false  && hasBreadCrumb && createDrilldownBreadCrumb(currentDrill.path)}
                {current && <h1 className={StyleUtil.combineClasses("pq_title",widgetStyles.title)}>{current.name}</h1> }
                {!current && <h1 className={StyleUtil.combineClasses("pq_title",widgetStyles.title)}>{"Product Search Results"}</h1> }

                {current && current.description && current.description !== "" &&
                    <h3 className={StyleUtil.combineClasses("pq_desc",widgetStyles.description)}>{current.description}</h3>}
                {current && current.bannerImage && current.bannerImage !== "" && <div className={StyleUtil.combineClasses("pq_banner_panel",widgetStyles.banner_panel)}><img
                    src={current.bannerImage.startsWith("http") ? current.bannerImage : imagePrefix + "/" + current.bannerImage}
                    className={widgetStyles.banner_image}/></div>}

                {hasProduct && createRefinement()}
                {navigation}
                {actionBar}
                {hasProduct && createProductList()}
                {actionBar}
                {navigation}
                {emptyPanel}
            </div>
        } else {
            return <></>
        }
    }

    const createDrilldownBreadCrumb = (path: DrillItemCmd[]) => {
        return <ul className={StyleUtil.combineClasses(styles.BreadCrumb,"pq_breadcrumb",widgetStyles.breadcrumb_panel,widgetStyles.breadcrumb_style1,widgetStyles.breadcrumb_style2)}>
            {
                path.map((item,index) => <li key={`breadcrumb_${index}`}><a href={"javascript:void()"}>{item.name}</a></li>)
            }
        </ul>
    }


    const createProductQuery = () => {
        if(currentDrill !== null || search !== null) {
            return <div ref={rootRef}
                        id={"pq_" + refreshId}
                        className={StyleUtil.combineClasses(widgetStyles.theme, widgetStyles.current, widgetStyles.panel, styles.Root)}>
                {currentDrill && fullSizeBreadcrumb && hasBreadCrumb && createDrilldownBreadCrumb(currentDrill.path)}
                {hasFacet && createFacet()}
                {createQueryPanel()}
            </div>
        } else {
            return <div className={StyleUtil.combineClasses(widgetStyles.panel,styles.Root)}></div>
        }
    }

    if(!orgCmd || !orgCmd.orgDetail) {
        return <></>
    }

    if(viewState.isEdit) {
        let deleteOption = {type: DeleteType.delete_with_rearrange, tag: null};
        const suggestTags: string[] = [
            "current",
            "current .pq_breadcrumb",
            "current .pq_query_panel",
            "current .pq_title",
            "current .pq_desc",
            "current .pq_title",
            "current .pq_banner_panel",
            "current .pq_banner_panel img",
            "current .pq_refinement_panel",
            "current .pq_pagination_panel", // might need to add more configuration
            "current .pq_grid_button",
            "current .pq_list_button",
            "current .pg_action_panel",
            "current .pg_action_panel .btn_checkout",
            "current .pg_action_panel .btn_compare",
            "current .pg_action_panel .btn_estimate",
            "current .pq_product_list_panel",
            "current .pq_product_panel",
            "current .pq_product_image_panel",
            "current .pq_product_image_panel a",
            "current .pq_product_image_panel img",
            "current .pq_product_info_panel",
            "current .pq_product_info_title",
            "current .pq_product_info_desc",
            "current .pq_product_info_price",
            "current .pq_product_info_compare",
            "current .pq_product_qty",
            "current .pq_product_price",
            "current .pq_product_price .btn_add_to_card",
            "current .pq_product_compare",
            "current .pq_refinement_panel",
            "current .pq_refinement_title",
            "current .pq_facet_list_panel",
            "current .pq_facet_panel",
            "current .pq_facet_panel li",
            "current .pq_facet_panel ui",
            "current .pq_facet_panel .btn_update",
        ]
        let settingOptions:SettingOption[] = [
            { title: "", type: SettingType.widget,group: "content",
                editor: "productquerysetting",
                additions: {
                    displayOptions: orgCmd.orgDetail.displayFieldOptions ? orgCmd.orgDetail.displayFieldOptions : [],
                    imageOptions: orgCmd?.orgDetail?.imageFieldOptions ? orgCmd?.orgDetail?.imageFieldOptions : [],
                    sharedObject: props.shareObjects
                }, groupType: SettingGroupType.layout
                //     <ProductQuerySetting
                //                             displayOptions={orgCmd.orgDetail.displayFieldOptions}
                //                             imageOptions={orgCmd?.orgDetail?.imageFieldOptions}
                //                             widgetData={widgetData}
                //                             saveWidgetState={() => {
                //                                  saveWidgetState();
                //                              }}
                //                              sharedObject={props.sharedObjects}
                //                              onChangePreview={(drilldown:string, previewId:string) => {
                //                                  widgetData.widgetProps["content"]['previewDrilldown'] = drilldown;
                //                                  widgetData.widgetProps["content"]['previewId'] = previewId;
                //                                  saveWidgetState();
                //                                  setDrillInfo({drilldown: drilldown, previewId: previewId})
                //
                //                              }}
                // />
            },
            { type: SettingType.flex, group: "panel", groupType: SettingGroupType.style},
            { type: SettingType.dimension, group: "panel", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "panel", groupType: SettingGroupType.style},
            { type: SettingType.border, group: "panel", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "panel", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "panel", groupType: SettingGroupType.advanced},

            { type: SettingType.dimension, group: "banner_panel", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "banner_panel", groupType: SettingGroupType.style},
            { type: SettingType.border, group: "banner_panel", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "banner_panel", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "banner_panel", groupType: SettingGroupType.advanced},

            { type: SettingType.dimension, group: "banner_image", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "banner_image", groupType: SettingGroupType.style},
            { type: SettingType.border, group: "banner_image", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "banner_image", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "banner_image", groupType: SettingGroupType.advanced},

            { type: SettingType.dimension, group: "feature", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "feature", groupType: SettingGroupType.style},
            { type: SettingType.border, group: "feature", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "feature", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "feature", groupType: SettingGroupType.advanced},

            { type: SettingType.typography, group: "title", groupType: SettingGroupType.style},
            { type: SettingType.align, group: "title", groupType: SettingGroupType.style},
            { type: SettingType.dimension, group: "title", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "title", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "title", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "title", groupType: SettingGroupType.advanced},

            { type: SettingType.typography, group: "description", groupType: SettingGroupType.style},
            { type: SettingType.align, group: "description", groupType: SettingGroupType.style},
            { type: SettingType.dimension, group: "description", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "description", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "description", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "description", groupType: SettingGroupType.advanced},

            { type: SettingType.typography, group: "breadcrumb_panel", groupType: SettingGroupType.style},
            { type: SettingType.dimension, group: "breadcrumb_panel", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "breadcrumb_panel", groupType: SettingGroupType.style},
            { type: SettingType.border, group: "breadcrumb_panel", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "breadcrumb_panel", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "breadcrumb_panel", groupType: SettingGroupType.advanced},


            { type: SettingType.typography, group: "refinement_panel", groupType: SettingGroupType.style},
            { type: SettingType.dimension, group: "refinement_panel", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "refinement_panel", groupType: SettingGroupType.style},
            { type: SettingType.border, group: "refinement_panel", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "refinement_panel", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "refinement_panel", groupType: SettingGroupType.advanced},

            { type: SettingType.typography, group: "pagination_panel", groupType: SettingGroupType.style},
            { type: SettingType.dimension, group: "pagination_panel", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "pagination_panel", groupType: SettingGroupType.style},
            { type: SettingType.border, group: "pagination_panel", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "pagination_panel", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "pagination_panel", groupType: SettingGroupType.advanced},

            { type: SettingType.typography, group: "action_panel", groupType: SettingGroupType.style},
            { type: SettingType.dimension, group: "action_panel", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "action_panel", groupType: SettingGroupType.style},
            { type: SettingType.border, group: "action_panel", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "action_panel", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "action_panel", groupType: SettingGroupType.advanced},

        ];

        return <EditablePanel className={styles.Root}
                              {...props}
                              themeName={themeName}
                              suggestTags={suggestTags}
                              settingOptions={settingOptions}
                              settingGroups={[
                                  {group: "content", groupName: "Content", groupType: SettingGroupType.layout},
                                  {group: "panel", groupName: "Top Panel", groupType: SettingGroupType.style},
                                  {group: "breadcrumb_panel", groupName: "Breadcrumb Panel", groupType: SettingGroupType.style},
                                  {group: "title", groupName: "Title", groupType: SettingGroupType.style},
                                  {group: "description", groupName: "Description", groupType: SettingGroupType.style},
                                  {group: "banner_panel", groupName: "Banner Panel", groupType: SettingGroupType.style},
                                  {group: "banner_image", groupName: "Banner Image", groupType: SettingGroupType.style},
                                  {group: "refinement_panel", groupName: "Refinement Panel", groupType: SettingGroupType.style},
                                  {group: "pagination_panel", groupName: "Pagination Panel", groupType: SettingGroupType.style},
                              ]}
                              deleteOption={deleteOption}>
            {createProductQuery()}
        </EditablePanel>
    } else {
        return createProductQuery();
    }
}