import {useParams} from "react-router-dom";
import {useOrganization} from "../../hook/useOrganization";
import {useEffect, useRef, useState} from "react";
import HttpClient from "../../datafetcher/HttpClient";
import DrilldownEditor from "./DrilldownEditor";
import {nanoid} from "nanoid";


export default function Drilldown() {
    const params = useParams<{dname: string, did: string}>();
    const orgCmdProp = useOrganization();
    const [hasData, setHasData] = useState<boolean>(false);
    const [drilldown, setDrilldown] = useState<any>({});
    const inputFile = useRef<HTMLInputElement>(null);
    const [refreshId, setRefreshId] = useState<string>(nanoid());
    const [drillData, setDrillData] = useState<{[key:string]: string}>({});
    // const loadData = async (): Promise<any> => {
    //     try {
    //         if(!drilldown['drillData']) {
    //             let response = await HttpClient.get("/api/v0/jcr/org/" + orgCmdProp!.currentOrg!.orgCode + "/drilldown/" + params.did);
    //             let data = response.data.response;
    //             setDrilldown(data);
    //             if (data.drillData === null) return {};
    //             else return JSON.parse(data.drillData);
    //         } else {
    //             return JSON.parse(drilldown.drillData);
    //         }
    //
    //     } catch (e) {
    //         history.replace('/drill-downs')
    //     }
    // }

    const saveData = async (data :any ) => {
        let drillContent = JSON.stringify(data);
        drilldown.drillData = drillContent;
        if(orgCmdProp === undefined || orgCmdProp?.orgCmds === null) return;
        await HttpClient.post("/api/v0/jcr/org/" + orgCmdProp!.currentOrg!.orgCode + "/full/drilldowns",drilldown).then((response) => {

        })
        setDrilldown({...drilldown});
    }

    const uploadFile = async () => {
        if(inputFile !== null && inputFile.current !== null) {
            inputFile.current.click();
        }
    }


    const doUploadFile = async () => {
        if(inputFile && inputFile.current) {
            HttpClient.postForm("/api/jcr/orgs/" + orgCmdProp!.currentOrg!.orgCode + "/drilldowns/" +  params.did + "/drilldata", {
                file: inputFile.current.files
            }).then((response) => {
                let data = response.data.response;
                setDrilldown(data);
                setRefreshId(nanoid());
            })
        }
    }

    useEffect(() => {
        if(orgCmdProp === undefined || orgCmdProp?.orgCmds === null || orgCmdProp?.currentOrg === null || orgCmdProp?.orgCmds.length === 0) return;
        if(hasData) {
            //history.replace("/drill-downs");
        } else {
            // setHasData(true);
            if(!drilldown['drillData']) {
                HttpClient.get("/api/v0/jcr/org/" + orgCmdProp!.currentOrg!.orgCode + "/drilldown/" + params.did).then(response => {
                    let data = response.data.response;
                    setDrilldown(data);

                    if (data.drillData === null) {
                        setDrillData({});
                    } else {
                        setDrillData(JSON.parse(data.drillData));
                    }
                    setHasData(true)
                }).catch(() => {

                })
            } else {
                setDrillData(JSON.parse(drilldown.drillData))
                setHasData(true)
            }
        }
    },[orgCmdProp,hasData,drilldown,params.did]);

    return <>{hasData &&  <DrilldownEditor  key={refreshId}
                                            id={params.did}
                                            abbr={drilldown.drillAbbr}
                                        name={params.dname}
                                        drillData={drillData}
                                        saveData={saveData}
                                        uploadFile={uploadFile}
                                        path={"/drill-downs"}
                                        type={"Drill Downs"}/>}
        <input key={`upload_${refreshId}`} type='file' id='upload-drilldown'
               accept=".xml,.json,.csv"
               onChange={() => doUploadFile()}
                ref={inputFile} style={{display: 'none'}}/>
    </>
}