import {Link as RouterLink} from 'react-router-dom';
import {Link as MaterialLink} from "@mui/material";
import styles from "./Link.module.scss";

export default function Link(props:any): JSX.Element {
    if('to' in props) {
        return <MaterialLink {...props} className={props.className} component={RouterLink} onClick={props.onClick}>{props.children}</MaterialLink>
    } else if('href' in props) {
        return <MaterialLink {...props} component={RouterLink} to={props.href} className={styles.Root} onClick={props.onClick}>{props.children}</MaterialLink>
    } else {
        return <MaterialLink {...props} component={RouterLink} to={"#"} className={styles.Root} onClick={props.onClick}>{props.children}</MaterialLink>
    }

}