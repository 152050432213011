import {Divider, Grid, TextField, ToggleButtonGroup} from "@mui/material";
import SettingProps from "../SettingProps";
import styles from "./AdvanceBackgroundSetting.module.scss";
import React, {useState} from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ColorPickerField from "../../../../ui/colorpicker/ColorPickerField";
import SuggestInput from "../../../../ui/setting/input/SuggestInput";
import AssetField from "../../../../ui/assetfield/AssetField";
import {htmlUtil} from "../../../../util/HTMLUtil";


export default function AdvanceBackgroundSetting(props: SettingProps & {type: string}) {
    const data = props.settingProps;
    const group = props.settingOption.group;
    const {type} = props;

    if(!data[group]) {
        data[group] = { style : {} };
    }

    if(!data[group]['style']) {
        data[group]['style'] = {};
    }

    if(!data[group]['style'][type]) {
        data[group]['style'][type] = {}
    }

    const handleChange = (value: any, key: string) => {
        if(!data[group]) {
            data[group] = { style : { position: {}}}
        }

        if(!data[group]['style']) {
            data[group]['style'] = { position: {}}
        }

        if(value === "" || value === null) {
            delete data[group]['style'][type][key];
        } else {
            data[group]['style'][type][key] = value;
        }

        data[group]['style']['default'] = false; // user did change the component

        props.saveWidgetState();
        if(props.onChange) props.onChange(props.settingOption,props.settingOptions,props.index);
    }

    const generateTextField = (target: string) => {
        return <TextField
            size={"small"}
            fullWidth
            InputLabelProps={{shrink: true}}
            inputProps={{sx: {paddingBlock: "5px"}}}
            defaultValue={data[group]['style'][type][target] ? data[group]['style'][type][target] : ""}
            onChange={(event) => {
                handleChange(event.target.value, target)
                event.stopPropagation();
                event.preventDefault();
            }} label={""}
            />;
    }

    const [tabType, setTabType] = useState<"color"|"image">("color");
    return <div className={styles.Root}>
        <ToggleButtonGroup fullWidth size={"small"}  onChange={(event,value) => { setTabType(value[0]) }}>
            <ToggleButton value={"color"} className={styles.ToggleButton} selected={tabType === "color"}>Background Color</ToggleButton>
            <ToggleButton value={"image"} className={styles.ToggleButton} selected={tabType === "image"}>Background Image</ToggleButton>
        </ToggleButtonGroup>
        {
            tabType === "color" && <Grid container className={styles.GridContainer}>
                <Grid item xs={4}>Color</Grid>
                <Grid item xs={8}><ColorPickerField fullWidth
                                                    defaultValue={data[group]['style'][type]["background-color"]}
                                                    onChangeColor={(color) => {
                    handleChange(color, "background-color")
                }}/></Grid>
            </Grid>
        }
        {
            tabType === "image" && <Grid container className={styles.GridContainer}>
                <Grid item xs={4} className={styles.TargetLabel}>Image</Grid>
                <Grid item xs={8} className={styles.TargetInput}>
                    <AssetField size="small" fullWidth inputProps={{style: {fontSize: "12px"}}}
                                defaultValue={htmlUtil.removeURL(data[group]['style'][type]["background-image"])}
                                onChange={(event) => {
                                    let value = event.target.value;
                                    handleChange(`url("${value}")`, "background-image")
                                }}/>
                </Grid>
                <Grid item xs={4} className={styles.TargetLabel}>Blend Mode</Grid>
                <Grid item xs={8} className={styles.TargetInput}>
                    <SuggestInput options={ [
                        {label: "none" , value: ""},
                        {label: "normal" , value: "normal"},
                        {label: "multiply" , value: "multiply"},
                        {label: "screen" , value: "screen"},
                        {label: "overlay" , value: "overlay"},
                        {label: "darken" , value: "darken"},
                        {label: "lighten" , value: "lighten"},
                        {label: "color-dodge" , value: "color-dodge"},
                        {label: "saturation" , value: "saturation"},
                        {label: "color" , value: "color"},
                        {label: "luminosity" , value: "luminosity"},
                    ]}
                    value={data[group]['style'][type]["background-blend-mode"] ? data[group]['style'][tabType]["background-blend-mode"] : ""}
                   onChange={(value) => {
                       handleChange(value, "background-blend-mode")
                   }}
                    label={""}></SuggestInput>
                </Grid>
                <Grid item xs={4} className={styles.TargetLabel}>Position</Grid>
                <Grid item xs={8} className={styles.TargetInput}>{generateTextField("background-position")}</Grid>
                <Grid item xs={4} className={styles.TargetLabel}>Clip</Grid>
                <Grid item xs={8} className={styles.TargetInput}>{generateTextField("background-clip")}</Grid>
                <Grid item xs={4} className={styles.TargetLabel}>Repeat</Grid>
                <Grid item xs={8} className={styles.TargetInput}>{generateTextField("background-repeat")}</Grid>
            </Grid>
        }
        <Divider className={styles.Divider}/>
    </div>
}