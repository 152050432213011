import {Button, Typography} from "@mui/material";
import styles from "./IconMenuItem.module.scss";
import OutlinedIcon from "../icon/OutlinedIcon";
import {lsi} from "../../datafetcher/LSIDataFetcher";
import {useOrganization} from "../../hook/useOrganization";

export interface IconMenuItemProps {
    id: string
    icon:string
    title: string
    href: string
    action: string
    onClick?: () => void,
    selected?: boolean,
    active: boolean,
    className?: string,
    ssoExchanged?: string
}

export default function IconMenuItem(props: IconMenuItemProps) {
    const orgCmd = useOrganization();
    let className = props.className ? props.className : "";
    let ssoExchange:string|undefined = props.ssoExchanged;
    if(props.href !==  undefined && props.href.startsWith(process.env.REACT_APP_LSI_HOST!)) {
        ssoExchange = undefined;
    }
    return <Button id={props.id}
                   data-action={props.action}
                   className={(props.selected ? styles.ContainerSelected : styles.Container) + " " + className }
                   href={ssoExchange ? undefined : props.href}
                   onClick={(event) => {
        if(props.onClick) {
            props.onClick();
        } else {
            if(ssoExchange) {
                let exchangeName = ssoExchange;
                lsi.token.loginToken(exchangeName).then(response => {
                    let data = response.data as any;
                    let EXCHANGE_SERVER = data[exchangeName + "URL"];
                    const EXCHANGE_SSO = data[exchangeName + "SSO"];

                    if(process.env.REACT_APP_OVERRIDE_SSO_URL === "TRUE") {
                        let newExchangeURL = process.env[`REACT_APP_OVERRIDE_${exchangeName.toUpperCase()}_HOST`];
                        if(newExchangeURL !== undefined) {
                            EXCHANGE_SERVER = newExchangeURL
                        }
                    }

                    if(orgCmd && orgCmd.currentOrg) { // override default orgCode with current org
                        EXCHANGE_SSO.orgCode = orgCmd?.currentOrg?.orgCode
                    }

                    if(EXCHANGE_SSO.shortToken) {
                        window.open(`${EXCHANGE_SERVER}/sso_exchange.html?refreshToken=${EXCHANGE_SSO.refreshToken}&orgCode=${EXCHANGE_SSO.orgCode}`, "_self");
                    } else {
                        const exchange_frame: HTMLIFrameElement | null = document.getElementById(exchangeName + "_frame") as HTMLIFrameElement;
                        if (exchange_frame === null) {
                            const iframe: HTMLIFrameElement = document.createElement('iframe');
                            iframe.id = exchangeName + "_frame"
                            iframe.width = "1px";
                            iframe.height = "1px";
                            iframe.onload = function () {
                                if (iframe.contentWindow) {
                                    iframe.contentWindow.postMessage(JSON.stringify(EXCHANGE_SSO), EXCHANGE_SERVER);
                                    setTimeout(function () {
                                        window.open(EXCHANGE_SERVER, "_self");
                                    }, 100);
                                } else {
                                    console.log("contentWindow is undefined (1)");
                                }

                            };
                            iframe.src = EXCHANGE_SERVER + "/sso_exchange.html"
                            document.body.appendChild(iframe);

                        } else {
                            if (exchange_frame.contentWindow) {
                                exchange_frame.contentWindow.postMessage(JSON.stringify(EXCHANGE_SSO), EXCHANGE_SERVER);
                                setTimeout(function () {
                                    window.open(EXCHANGE_SERVER, "_self");
                                }, 100);
                            } else {
                                console.log("contentWindow is undefined (2)");
                            }
                        }
                    }
                })
            } else {
                window.open(props.href, "_self");
            }
        }
    }
    }><div className={styles.Content}>
        {
            props.selected && props.active && <div className={styles.DrawerPointer}></div>
        }
        <OutlinedIcon className={styles.Icon}>{props.icon}</OutlinedIcon>
        <Typography className={styles.Title}>{props.title}</Typography>
    </div>
    </Button>
}