
export interface MenuConfig {
    id: string,
    action: string,
    icon: string,
    icon_flip_y?: boolean,
    title: string,
    pageTitle: string,
    href: string,
    desc: string
    detailDesc?: string,
    inHome: boolean,
    inPageMenu: boolean,
    roles: string[],
}

export interface ContentConfig {
    id: string,
    action: string,
    icon: string,
    title: string,
    href: string
    roles: string[],
}

export const PortalConfig: ContentConfig[] = [
    {
        id: "portalStorefronts",
        action: "openPortalStorefronts",
        icon: "storefront",
        title: "Storefronts",
        href: "/storefronts",
        roles: ["LSIADMIN", "DISTRIBUTOR","DISTRIBUTORADMIN", "PORTAL"]
    },
    {
        id: "portalBranches",
        action: "openPortalBranches",
        icon: "place",
        title: "Branches",
        href: "/branches",
        roles: ["LSIADMIN", "DISTRIBUTORADMIN", "PORTAL"]
    },
    {
        id: "portalCustomers",
        action: "openPortalCustomer",
        icon: "people",
        title: "Customers",
        href: "/customers",
        roles: ["LSIADMIN", "DISTRIBUTORADMIN", "PORTAL"]
    },{
        id: "portalCompanies",
        action: "openPortalCompanies",
        icon: "switch_account",
        title: "Companies",
        href: "/companies",
        roles: ["LSIADMIN", "DISTRIBUTORADMIN", "PORTAL"]
    },{
        id: "portalFeatures",
        action: "openPortalFeatures",
        icon: "fact_check",
        title: "Features",
        href: "/features",
        roles: ["LSIADMIN", "DISTRIBUTORADMIN", "PORTAL"]
    },{
        id: "portalOrders",
        action: "openPortalOrders",
        icon: "paid",
        title: "Orders",
        href: "/orders",
        roles: ["LSIADMIN", "DISTRIBUTORADMIN", "PORTAL"]
    },{
        id: "portalProducts",
        action: "openPortalProducts",
        icon: "sell",
        title: "Products",
        href: "/products",
        roles: ["LSIADMIN", "DISTRIBUTORADMIN", "PORTAL"]
    },{
        id: "portalLogs",
        action: "openPortalLogs",
        icon: "summarize",
        title: "Logs / Reports",
        href: "/logs",
        roles: ["LSIADMIN", "DISTRIBUTORADMIN", "PORTAL"]
    }
]

export const MobileAnalyticConfig: ContentConfig[] = [
    {
        id: "mobileMetrics",
        action: "openMobileMetrics",
        icon: "summarize",
        title: "Metrics",
        href: "/metrics",
        roles: ["LSIADMIN", "DISTRIBUTORADMIN", "MOBILE"]
    },
]

export const MobileMessageConfig: ContentConfig[] = [
    {
        id: "mobileNotifications",
        action: "openMobileNotifications",
        icon: "textsms",
        title: "Notifications",
        href: "/push-notifications",
        roles: ["LSIADMIN", "DISTRIBUTORADMIN", "MOBILE"]
    },{
        id: "mobileAudience",
        action: "openMobileAudience",
        icon: "group",
        title: "Audience",
        href: "/audience-manager",
        roles: ["LSIADMIN", "DISTRIBUTORADMIN", "MOBILE"]
    }
]

export const LSIAdminMenuConfig: ContentConfig[] = [
    {
        id: "lsiOrganization",
        action: "openLSIOrganization",
        icon: "info",
        title: "Organizations",
        href: "/organizations",
        roles: ["LSIADMIN"]
    },
    {
        id: "lsiConnectorTypes",
        action: "openLSIConnectorTypes",
        icon: "info",
        title: "Connector Types",
        href: "/connectors",
        roles: ["LSIADMIN"]
    },
    {
        id: "lsiGroupTypes",
        action: "openLSIGroupTypes",
        icon: "info",
        title: "Group Types",
        href: "/CollectionsAdmin",
        roles: ["LSIADMIN"]
    },
    {
        id: "lsiAdministration",
        action: "openLsiAdministration",
        icon: "info",
        title: "Administration",
        href: "/admin",
        roles: ["LSIADMIN"]
    }
]

export const LSIDistributorAdminMenuConfig: ContentConfig[] = [
    {
        id: "lsiDistributorAdmin",
        action: "openLSIDistributorAdmin",
        icon: "info",
        title: "User Administration",
        href: "/usersadmin",
        roles: ["LSIADMIN","DISTRIBUTORADMIN"]
    }
]

export const LSICatalogAdminConfig: ContentConfig[] = [
    {
        id: "lsiDatasource",
        action: "openLSIDatasource",
        icon: "info",
        title: "Data Sources",
        href: "/catalogs/datasources",
        roles: ["LSIADMIN","DISTRIBUTORADMIN","DISTRIBUTOR"]
    },
    {
        id: "lsiMember",
        action: "openLSIMember",
        icon: "info",
        title: "Member",
        href: "/catalogs/member",
        roles: ["LSIADMIN","DISTRIBUTORADMIN","DISTRIBUTOR"]
    }
]

export const LSIProduceInformationConfig: ContentConfig[] = [
    {
        id: "lsiNewProduct",
        action: "openLSINewProduct",
        icon: "info",
        title: "New",
        href: "/products/new",
        roles: ["LSIADMIN","DISTRIBUTORADMIN","DISTRIBUTOR"]
    },
    {
        id: "lsiSingleEdit",
        action: "openLSISingleEdit",
        icon: "info",
        title: "Single Edit",
        href: "/products/single-edit",
        roles: ["LSIADMIN","DISTRIBUTORADMIN","DISTRIBUTOR"]
    },
    {
        id: "lsiBulkEdit",
        action: "openLSIBulkEdit",
        icon: "info",
        title: "Bulk Edit",
        href: "/products/bulk-edit/standardsearch",
        roles: ["LSIADMIN","DISTRIBUTORADMIN","DISTRIBUTOR"]
    },
    {
        id: "lsiTableEdit",
        action: "openLSITableEdit",
        icon: "info",
        title: "Table Edit",
        href: "/products/table-edit/tablestandardsearch",
        roles: ["LSIADMIN","DISTRIBUTORADMIN","DISTRIBUTOR"]
    },
    {
        id: "lsiExport",
        action: "openLSIExport",
        icon: "info",
        title: "Export",
        href: "/products/export",
        roles: ["LSIADMIN","DISTRIBUTORADMIN","DISTRIBUTOR"]
    },
    {
        id: "lsiDisableEnable",
        action: "openLSIDisableEnable",
        icon: "info",
        title: "Disable/Enable",
        href: "/products/enable-disable-products",
        roles: ["LSIADMIN","DISTRIBUTORADMIN","DISTRIBUTOR"]
    },
    {
        id: "lsiDataCleansing",
        action: "openLSIDataCleansing",
        icon: "info",
        title: "Data Cleansing",
        href: "/products/data-cleansing",
        roles: ["LSIADMIN","DISTRIBUTORADMIN","DISTRIBUTOR"]
    }

]

export const LSIMerchandisingGroupConfig: ContentConfig[] = [
    {
        id: "lsiManualMerch",
        action: "openLSIManualMerch",
        icon: "info",
        title: "Manual",
        href: "/merchandising/groups",
        roles: ["LSIADMIN","DISTRIBUTORADMIN","DISTRIBUTOR"]
    },
    {
        id: "lsiAutoMerchSettings",
        action: "openLSIAutoMerchSettings",
        icon: "info",
        title: "Automated Settings",
        href: "/discovery/settings",
        roles: ["LSIADMIN","DISTRIBUTORADMIN","DISTRIBUTOR"]
    },
    {
        id: "lsiAutoMerchRelation",
        action: "openLSIAutoMerchRelation",
        icon: "info",
        title: "Product Relationships",
        href: "/discovery/search",
        roles: ["LSIADMIN","DISTRIBUTORADMIN","DISTRIBUTOR"]
    },
]

export const LSIProductDataModelConfig: ContentConfig[] = [
    {
        id: "lsiStandardFields",
        action: "openLSIStandardFields",
        icon: "info",
        title: "Standard Fields",
        href: "/pdm/fields/standard",
        roles: ["LSIADMIN","DISTRIBUTORADMIN","DISTRIBUTOR"]
    },
    {
        id: "lsiDynamicFields",
        action: "openLSIDynamicFields",
        icon: "info",
        title: "Dynamic Fields",
        href: "/pdm/fields/dynamic",
        roles: ["LSIADMIN","DISTRIBUTORADMIN","DISTRIBUTOR"]
    },
    {
        id: "lsiSmartFields",
        action: "openLSISmartFields",
        icon: "info",
        title: "Smart Fields",
        href: "/pdm/fields/smart",
        roles: ["LSIADMIN","DISTRIBUTORADMIN","DISTRIBUTOR"]
    },
    {
        id: "lsiFieldGroups",
        action: "openLSIFieldGroups",
        icon: "info",
        title: "Field Groups",
        href: "/pdm/groups",
        roles: ["LSIADMIN","DISTRIBUTORADMIN","DISTRIBUTOR"]
    },
    {
        id: "lsiSearchProfiles",
        action: "openLSISearchProfiles",
        icon: "info",
        title: "Search Properties",
        href: "/pdm/search_properties/search_profiles",
        roles: ["LSIADMIN","DISTRIBUTORADMIN","DISTRIBUTOR"]
    },
    {
        id: "lsiSynonyms",
        action: "openLSISynonyms",
        icon: "info",
        title: "Synonyms",
        href: "/pdm/search_properties/synonyms",
        roles: ["LSIADMIN","DISTRIBUTORADMIN","DISTRIBUTOR"]
    },
    {
        id: "lsiConnectorConfigurations",
        action: "openLSIConnectorConfigurations",
        icon: "info",
        title: "Connector Configuration",
        href: "/pdm/conconfig",
        roles: ["LSIADMIN","DISTRIBUTORADMIN","DISTRIBUTOR"]
    },
]


export const LSIDAMMenuConfig: ContentConfig[] = [
    {
        id: "lsiFileBrowser",
        action: "openLSIFileBrowser",
        icon: "info",
        title: "File Browser",
        href: "/dambrowser",
        roles: ["LSIADMIN","DISTRIBUTORADMIN","DISTRIBUTOR"]
    },
    {
        id: "lsiFileTransformations",
        action: "openLSIFileTransformations",
        icon: "info",
        title: "Transformations",
        href: "/damsettings",
        roles: ["LSIADMIN","DISTRIBUTORADMIN","DISTRIBUTOR"]
    },
]

export const LSIReportModelConfig: ContentConfig[] = [
    {
        id: "lsiVarianceReport",
        action: "openLSIVarianceReport",
        icon: "info",
        title: "Variance Reports",
        href: "/merchandising/variance-report",
        roles: ["LSIADMIN","DISTRIBUTORADMIN","DISTRIBUTOR"]
    },
    {
        id: "lsiFieldMetaData",
        action: "openLSIFieldMetaData",
        icon: "info",
        title: "Fields Metadata",
        href: "/meta-data/fields",
        roles: ["LSIADMIN","DISTRIBUTORADMIN","DISTRIBUTOR"]
    },
    {
        id: "lsiRedinessReport",
        action: "openLSIRedinessReport",
        icon: "info",
        title: "Readiness Report",
        href: "/data-quality/readiness",
        roles: ["LSIADMIN","DISTRIBUTORADMIN","DISTRIBUTOR"]
    },
    {
        id: "lsiReportAdmin",
        action: "openLSIReportAdmin",
        icon: "info",
        title: "Reports Admin",
        href: "/data-quality/reports-administration",
        roles: ["LSIADMIN","DISTRIBUTORADMIN","DISTRIBUTOR"]
    },
    {
        id: "lsiRunReport",
        action: "openLSIRunReport",
        icon: "info",
        title: "Run Report",
        href: "/data-quality/run-report",
        roles: ["LSIADMIN","DISTRIBUTORADMIN","DISTRIBUTOR"]
    },
]



export const CMSContentMenuConfig: ContentConfig[] = [
    {
        id: "productSubMenu",
        action: "openProductDiscover",
        icon: "content_paste_search",
        title: "Product Discovery",
        href: "/pim",
        roles: ["LSIADMIN", "DISTRIBUTOR","DISTRIBUTORADMIN","MARKETING"]
    },
    {
        id: "damSubMenu",
        action: "openDAM",
        icon: "folder_copy",
        title: "Assets ",
        href: "/assets",
        roles: ["LSIADMIN", "DISTRIBUTOR","DISTRIBUTORADMIN"]
    },
    {
        id: "drilldownSubMenu",
        action: "openDrilldownEditor",
        icon: "segment",
        title: "Drill Downs",
        href: "/drill-downs",
        roles: ["LSIADMIN", "DISTRIBUTORADMIN"]
    },
    {
        id: "blockSubMenu",
        action: "openBlockEditor",
        icon: "view_agenda",
        title: "Blocks",
        href: "/content/blocks",
        roles: ["LSIADMIN", "DISTRIBUTORADMIN","MARKETINGS"]
    },
    {
        id: "faqSubMenu",
        action: "openFAQEditor",
        icon: "quiz",
        title: "FAQs",
        href: "/content/faqs",
        roles: ["LSIADMIN", "DISTRIBUTORADMIN","MARKETINGS"]
    },
    {
        id: "gridSubMenu",
        action: "openGridEditor",
        icon: "grid_view",
        title: "Grids",
        href: "/content/grids",
        roles: ["LSIADMIN", "DISTRIBUTORADMIN","MARKETINGS"]
    },
    {
        id: "smallgridSubMenu",
        action: "openSmallgridEditor",
        icon: "apps",
        title: "Small Grids",
        href: "/content/smallgrids",
        roles: ["LSIADMIN", "DISTRIBUTORADMIN","MARKETINGS"]
    },
    {
        id: "gallerySubMenu",
        action: "openGalleryEditor",
        icon: "collections",
        title: "Galleries",
        href: "/content/galleries",
        roles: ["LSIADMIN", "DISTRIBUTORADMIN","MARKETINGS"]
    },
    {
        id: "eventSubMenu",
        action: "openEventEditor",
        icon: "event",
        title: "Events",
        href: "/content/events",
        roles: ["LSIADMIN", "DISTRIBUTORADMIN","MARKETINGS"]
    },
    {
        id: "slideshowSubMenu",
        action: "openSlideEditor",
        icon: "slideshow",
        title: "Slideshows",
        href: "/content/slideshows",
        roles: ["LSIADMIN", "DISTRIBUTORADMIN","MARKETINGS"]
    }
]
export const CMSMainMenuConfig: MenuConfig[] = [
    {
        id: "siteSubMenu",
        action: "openSiteEditor",
        icon: "storefront",
        title: "Manage Sites",
        pageTitle: "Sites Management",
        href: "/sites",
        desc: "Manage your website portfolio and storefronts",
        inHome: true,
        inPageMenu: true,
        roles: ["LSIADMIN"]
    },
    {
        id: "routeSubMenu",
        action: "openRouteEditor",
        icon: "alt_route",
        title: "Adjust Routes",
        pageTitle: "Routings Management",
        href: "/routes",
        desc: "Manage your URL routing strategy and settings.",
        inHome: true,
        inPageMenu: true,
        roles: ["LSIADMIN","DISTRIBUTORADMIN"]
    },
    {
        id: "themeSubMenu",
        action: "openThemeEditor",
        icon: "palette",
        title: "Adjust Styling",
        pageTitle: "Adjust Styling",
        href: "/themes",
        desc: "Create & edit themes, color schemes, and styles. ",
        inHome: true,
        inPageMenu: true,
        roles: ["LSIADMIN"]
    },
    {
        id: "headerSubmenu",
        action: "openHeaderEditor",
        icon: "web_asset",
        title: "Create Headers",
        pageTitle: "Create Headers",
        href: "/headers",
        desc: "Manage your header library ",
        inHome: true,
        inPageMenu: true,
        roles: ["LSIADMIN"]
    },
    {
        id: "footerSubMenu",
        action: "openFooterEditor",
        icon: "web_asset",
        icon_flip_y: true,
        title: "Create Footers",
        pageTitle: "Create Footers",
        href: "/footers",
        desc: "Manager your footer library ",
        inHome: true,
        inPageMenu: true,
        roles: ["LSIADMIN"]
    },
    {
        id: "pageSubMenu",
        action: "openPagesEditor",
        icon: "wysiwyg",
        title: "Create Pages",
        pageTitle: "Create Pages",
        href: "/pages",
        desc: "Create landing pages and content pages",
        detailDesc: "Begin by selecting a template or building from scratch. Access your library on the My Pages tab.",
        inHome: true,
        inPageMenu: true,
        roles: ["LSIADMIN", "DISTRIBUTOR","DISTRIBUTORADMIN", "MARKETING"]
    },
    // {
    //     id: "policySubMenu",
    //     action: "openPolicyEditor",
    //     icon: "policy",
    //     pageTitle: "User Policies",
    //     title: "User Policies",
    //     href: "/userpolicies",
    //     desc: "Manage user policies and vitae lorepsum ntum vulputate, tellus leo mattis mi.",
    //     inHome: false,
    //     inPageMenu: true,
    //     roles: ["LSIADMIN"]
    // },
    {
        id: "pagestemplateubMenu",
        action: "openPageTemplateEditor",
        icon: "source",
        title: "Pages Template",
        pageTitle: "Pages Templates",
        href: "/pagestemplate",
        desc: "",
        inHome: false,
        inPageMenu: true,
        roles: ["LSIADMIN"]
    },
    {
        id: "sectionTemplateSubMenu",
        action: "openSectionTemplateEditor",
        icon: "source",
        title: "Section Template",
        pageTitle: "Section Templates",
        href: "/sectiontemplate",
        desc: "",
        inHome: false,
        inPageMenu: true,
        roles: ["LSIADMIN"]
    },
];

export const findRolesFromPath = (path:string) : string[] => {

    for(let item of CMSMainMenuConfig) {
        if(path.startsWith(item.href)) {
            return item.roles;
        }
    }
    for(let item of CMSContentMenuConfig) {
        if(path.startsWith(item.href)) {
            return item.roles;
        }
    }
    if(path.startsWith("/live")) {
        return ["LSIADMIN","DISTRIBUTOR","DISTRIBUTORADMIN","MARKETING","PORTAL"]
    } else {
        return ["LSIADMIN"];
    }
}

