import portalClient, {getPortalResources} from "./PortalClient";


function PortalUserDataFetcher() {
    return {
        resources: () => {
            return portalClient.get("/api/user/resources")
        }
    };
}

function PortalProductFetcher() {
    return {
        list: (productIds:string[]) => {
            let resources = getPortalResources();
            let supplierId = resources.supplierId;
            let websiteId = resources.websiteId;
            let storefrontId = resources.storefrontId;
            let branchId = resources.defaultBranchId;

            let q = ""
            for(let productId of productIds) {
                if(q.length > 0) {
                    q += "&"
                }
                q += "productIds=" + productId;
            }
            return portalClient.get(`/api/product/all/supplier/${supplierId}/website/${websiteId}/storefront/${storefrontId}/branch/${branchId}?${q}&erpCompanyId=1`)
        }
    }
}
function PortalDataFetcher() {
    return {
        isReady: () => portalClient.isReady(),
        user: PortalUserDataFetcher(),
        product: PortalProductFetcher()
    }
}

export const portal = PortalDataFetcher();
