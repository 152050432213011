import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../store";
import RouteInterface from "../../../cms/model/RouteInterface";

interface CMSRouteState {
    isEdit: boolean;
    cmsRouteData: RouteInterface | null;
}

const initialState:CMSRouteState = {
    isEdit: false,
    cmsRouteData: null
}

export const cmsRouteSlice = createSlice( {
    name: 'cmsroute',
    initialState,
    reducers: {
        cmsRouteEditAction: (state: CMSRouteState, action: PayloadAction<{ isEdit: boolean}>) => {
            state.isEdit = action.payload.isEdit;
        },
        cmsRouteUpdateDataAction: (state: CMSRouteState , action: PayloadAction<{cmsRouteData:RouteInterface | null}>) => {
            state.cmsRouteData = action.payload.cmsRouteData;
        },
        cmsRouteResetAction: state => {
            state.isEdit = false;
        }
    }

})

export const { cmsRouteEditAction, cmsRouteResetAction, cmsRouteUpdateDataAction } = cmsRouteSlice.actions

export const selectCMSRouteState = (state: RootState) => state.cmsRouteReducerState;

export default cmsRouteSlice.reducer;