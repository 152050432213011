import React , {useEffect} from 'react';
import PageMenu from "../menu/PageMenu";
import commonStyle from "../../config/Common.module.scss"
import MainMenuItem from "../menu/MainMenuItem";
import {findRolesFromPath, CMSMainMenuConfig} from "../../config/CMSMainMenuConfig";
import {Grid} from "@mui/material";
import styles from "./Home.module.scss";
import {hasCMSModule, useSSO} from "../../datafetcher/HttpClient";
import {useHistory} from "react-router";
import AccessDenied from "../accessdenied/AccessDenied";

export default function Home() {
    const sso = useSSO();
    const history = useHistory();
    useEffect(() => {
        //if(sso.roleName !== "LSIADMIN") {
        if(!["LSIADMIN","DISTRIBUTORADMIN","DISTRIBUTOR","MARKETING"].includes(sso.roleName)){
            let roles = findRolesFromPath("/pages")
            if(roles.indexOf(sso.roleName) >= 0) {
                history.push("/pages");
            }
        }
    },[sso,history])

    if(hasCMSModule() === false) return <AccessDenied/>
    return <React.Fragment>
        <PageMenu type={"home"}>
            <div className={commonStyle.LeftPanel}>
                {/*<LinkButton variant={"contained"} onClick={onHandleBuild}><Icon className={styles.Icon} >build</Icon>Build</LinkButton>*/}
                {/*<BuildButton/>*/}
            </div>
        </PageMenu>
            <div className={commonStyle.ContentContainer + " " + styles.Container}>
                <Grid container className={styles.GridContainer}>
                    <Grid item xs={12} md={12} sm={12} className={styles.Title}>Welcome back to your CMS, {sso.name}</Grid>
                    <Grid item xs={12} md={12} sm={12} className={styles.Title2}>Welcome back, {sso.name}</Grid>
                    <Grid item xs={12} md={12} sm={12} className={styles.SubTitle}>&nbsp;</Grid>
                    { CMSMainMenuConfig.filter(item => item.inHome).filter(item=>item.roles.indexOf(sso.roleName)>=0).map((item, index) =>
                            <Grid item className={styles.GridItem} key={"main_menu_" + index}  xs={12} sm={6} md={4}>
                                <MainMenuItem config={item} />
                            </Grid>
                        )
                    }
                </Grid>
            </div>
        </React.Fragment>;
}
