import {Checkbox, FormControlLabel, Grid, TextField} from "@mui/material";
import styles from "./HeaderSetting.module.scss";
import LocationSetting from "./LocationSetting";
import {SettingLabel} from "../../setting/settinglabel/SettingLabel";
import YesNoSwitch from "../../setting/onoffswitch/YesNoSwitch";

export default function SearchSetting(props: { widgetData: any, saveWidgetState: () => void }) {
    let {widgetData} = props;
    if(!widgetData.widgetProps['search']) {
        widgetData.widgetProps['search'] = {}
    }
    let location = (widgetData.widgetProps['search'] && widgetData.widgetProps['search']['location'] ? widgetData.widgetProps['search']['location'] : "");
    let order = (widgetData.widgetProps['search'] && widgetData.widgetProps['search']['order'] ? widgetData.widgetProps['search']['order'] : "");
    let hasBarcode = (widgetData.widgetProps['search'] && widgetData.widgetProps['search']['hasBarcode'] ? widgetData.widgetProps['search']['hasBarcode'] : false);

    const handleChange = (value: any, key: string) => {
        props.widgetData.widgetProps['search'][key] = value;
        props.saveWidgetState();
    }

    return <>

        <Grid container spacing={2}>
            <LocationSetting  size={"small"}  defaultValue={location} label="Location"
                              onChange={(event) => {
                                  let value = event.target.value;
                                  handleChange(value, "location");
                              }}
            />
            <Grid item xs={4}>
                <SettingLabel title={"#order"}/>
            </Grid>
            <Grid item xs={8}>
                <TextField size="small" fullWidth={true}
                           defaultValue={order.toString()}
                           onChange={(event) => {
                               let value = parseInt(event.target.value);
                               if(!isNaN(value)) {
                                   handleChange(value,"order");
                               }
                           }}/>
            </Grid>
            <Grid item xs={4}>
                <SettingLabel title={"Include Barcode Scanner"}/>
            </Grid>
            <Grid item xs={8}>
                <YesNoSwitch defaultValue={hasBarcode} onChange={(value) => {
                    handleChange(value, "hasBarcode")
                }}/>
            </Grid>
        </Grid>
    </>

}