import {Grid, TextField} from "@mui/material";
import LocationSetting from "./LocationSetting";
import {SettingLabel} from "../../setting/settinglabel/SettingLabel";

export default function HeaderLoginSetting(props: { widgetData: any, saveWidgetState: () => void }) {
    let {widgetData} = props;
    let location = (widgetData.widgetProps['login'] && widgetData.widgetProps['login']['location'] ? widgetData.widgetProps['login']['location'] : "");
    let order = (widgetData.widgetProps['login'] && widgetData.widgetProps['login']['order'] ? widgetData.widgetProps['login']['order'] : "");

    if(!widgetData.widgetProps['login']) {
        widgetData.widgetProps['login'] = {}
    }

    const handleChange = (value: any, key: string) => {
        props.widgetData.widgetProps['login'][key] = value;
        props.saveWidgetState();
    }

    return <>

        <Grid container spacing={2}>
            <LocationSetting  size={"small"}  defaultValue={location} label=""
                              onChange={(event) => {
                                  let value = event.target.value;
                                  handleChange(value, "location");
                              }}
            />
            <Grid item xs={4}>
                <SettingLabel title={"#order"}/>
            </Grid>
            <Grid item xs={8}>
                <TextField size="small" fullWidth={true}
                            defaultValue={order.toString()}
                            onChange={(event) => {
                                let value = parseInt(event.target.value);
                                if(!isNaN(value)) {
                                    handleChange(value,"order");
                                }
                            }}/>
            </Grid>
        </Grid>
        </>

}