import styles from "./EditorFloatMenu.module.scss";
import OutlinedIcon from "../icon/OutlinedIcon";
import Link from "../button/Link";
import {Breadcrumbs, Typography} from "@mui/material";
import React from "react";
import {CMSMainMenuConfig} from "../../config/CMSMainMenuConfig";
import {CapitalizeString} from "../../util/StringUtil";

export interface DefaultBreadcrumbProps {
    name?: string,
    type: string,
    title?: string,
    link?: string,
    small?: boolean,
    className?: string,
}

export function EditorBreadcrumbs(props: DefaultBreadcrumbProps) {
    let pageTitle: string = "";
    // let desc: string = "";
    let title: string = "";

    const searchHref = "/" + props.type.toLowerCase();
    const configs = CMSMainMenuConfig.filter(config => config.href === searchHref);
    if(configs && configs.length > 0) {
        title = configs[0].title;
        if(props.title !== undefined) {
            pageTitle = props.title;
        } else {
            pageTitle = configs[0].pageTitle;
        }
    } else {
        title = props.title ? props.title : CapitalizeString(props.type);
        pageTitle = props.title ? props.title : CapitalizeString(props.type);
    }

    return <Breadcrumbs className={props.className ? props.className : styles.Breadcrumbs}
                        separator={<OutlinedIcon className={styles.BreadcrumbSeparator}>navigate_next</OutlinedIcon>}
    >
        { props.name !== "home" && <Link id={`homeBreadcrumb`} data-action={"openHome"} href={"/home"}>
            <Typography className={props.small ? styles.BreadcrumbLabelSmall : styles.BreadcrumbLabel}>{"CMS"}</Typography>
        </Link>}
        { props.name && <Link id={`${props.type}Breadcrumb`} data-action={`open${props.type}`} href={"/" + (props.link ? props.link : props.type.toLowerCase())}>
            <Typography  className={props.small ? styles.BreadcrumbLabelSmall : styles.BreadcrumbLabel}>{title}</Typography>
        </Link>}
        { !props.name && <Typography className={styles.BreadcrumbLabel}>{pageTitle}</Typography> }
        { props.name && props.name !== "" && <Typography className={props.small ? styles.BreadcrumbLabelSmall : styles.BreadcrumbLabel}>{props.name}</Typography> }
    </Breadcrumbs>
}