
declare global {
    interface  Window {
        dataLayer: any;
    }
}

export function TrackData(event:string, value: { [key:string]: any|undefined }) {
    if('dataLayer' in window) {
        let dataLayer: any = window.dataLayer as any;
        if (dataLayer) {
            dataLayer.push({...value, event: event});
        }
    }
}
