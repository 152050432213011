import styles from "./Roles.module.scss"
import commonStyle from "../../config/Common.module.scss";
import InputDialog from "../dialog/InputDialog";
import StyledTable from "../table/StyledTable";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {InputModel} from "../../model/InputModel";
import HttpClient from "../../datafetcher/HttpClient";
import {nanoid} from "nanoid";
import {useOrganization} from "../../hook/useOrganization";
import {useHistory, useLocation} from "react-router";
import {RoleDataFetcher} from "../../datafetcher/RoleDataFetcher";
import {useParams} from "react-router-dom";
import SolidIcon from "../icon/SolidIcon";
import IconLinkButton from "../button/IconLinkButton";
import ReverseSolidLinkSmallButton from "../button/ReverseSolidLinkSmallButton";
import PageSummary from "../pagesummary/PageSummary";
import {CurrentISODate} from "../../util/DateUtil";
import OutlinedIcon from "../icon/OutlinedIcon";
import {jcr} from "../../datafetcher/JCRDataFetcher";


export default function Roles() {
    const params: any = useParams();
    const [deleteList,setDeleteList] = useState<string[]>([]);
    const [newOpen, setNewOpen] = useState<boolean>(false);
    const [updateData, setUpdateData] = useState<any>({});
    const [formKey , setFormKey] = useState("");
    const [roleId, setRoleId] = useState("");
    const orgCmdProp = useOrganization();
    const history = useHistory();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const [dataFetcher, setDataFetcher] = useState<RoleDataFetcher|null>(null);
    const inputFile = useRef<HTMLInputElement>(null);

    if (params.rid && roleId !== params.rid) {
        setTimeout(() => {
            setRoleId(params.rid);
        }, 100);
    }

    const resetScreen = useCallback(() => {
        history.push({
            pathname: '/userpolicies',
            search: location.search
        });
        setUpdateData({});
        setRoleId("");
        setFormKey(nanoid());
    },[history,location]);

    const handleCloseNewDialog = () => {
        setNewOpen(false);
    }

    const handleOpenNewDialog = () => {
        setNewOpen(true);
    }

    const handleUploadDialog = () => {
        if(inputFile !== null && inputFile.current !== null) {
            inputFile.current.click();
        }
    }

    const doUploadFile = () => {
        if(inputFile && inputFile.current) {
            if(inputFile.current.files) {
                jcr.role.upload(inputFile.current.files).then((response) => {
                    if(!(orgCmdProp && orgCmdProp.currentOrg !== null && orgCmdProp.orgDetail !== null)) return;
                    setDataFetcher(new RoleDataFetcher(orgCmdProp.currentOrg));
                    setFormKey(nanoid());
                })
            }
        }
    }

    const handleCloseUpdateDialog = () => {
        resetScreen();
    }

    const handleAction = (tag:string, tagObject: any) => {
        if(tag === "DUPLICATE") {
            HttpClient.get("/api/v0/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/clone/roles/" +  tagObject.id)
                .then((response) => {
                    let id = response.data.response;
                    history.push({
                        pathname: `${history.location.pathname}/${id}`,
                        search: history.location.search
                    })
                    if(!(orgCmdProp && orgCmdProp.currentOrg !== null && orgCmdProp.orgDetail !== null)) return;
                    setDataFetcher(new RoleDataFetcher(orgCmdProp.currentOrg));
                    setFormKey(nanoid());
                })
        } else if(tag === "DOWNLOAD") {
            HttpClient.download(`Role-${tagObject.viewName}-${tagObject.id}.gz`,
                "/api/v0/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/download/roles/" +  tagObject.id);
        }
    }

    const handleNewDialog = (values: InputModel[]) => {
        let data: any = {};
        values.forEach(object => {
            data[object.id] = object.defaultValue;
        })
        data['createdAt'] = new Date().getTime();
        data['modifiedAt'] = new Date().getTime();
        HttpClient.post("/api/v0/jcr/org/" + orgCmdProp?.currentOrg!.orgCode + "/full/roles", data).then((response) => {
            resetScreen();
        }).catch((e) => {

        })

        setNewOpen(false);
    }

    const handleUpdateDialog = (values: InputModel[]) => {
        let data: any = updateData;
        values.forEach(object => {
            data[object.id] = object.defaultValue;
        })
        data['createdAt'] = updateData.createdAt;
        data['modifiedAt'] = new Date().getTime();

        console.log(data)
        HttpClient.post("/api/v0/jcr/org/" + orgCmdProp?.currentOrg!.orgCode + "/full/roles", data).then((response) => {
            dataFetcher!.load().then((refresh) => {
                resetScreen();

            });
        }).catch((e) => {
        })
    }

    const handleDelete = () => {
        if(window.confirm("Are you sure to remove?")) {
            HttpClient.delete("/api/v0/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/roles/" + deleteList.join(",")).then((response) => {
                resetScreen();
                setDeleteList([]);
            }).catch((e) => {
                setDeleteList([]);
            })
        }
    }

    const handleDownloadList = () => {
        HttpClient.download(`Roles-${CurrentISODate()}.gz`,
            "/api/v0/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/download/roles/" + deleteList.join(","));
        setDeleteList([]);
        setFormKey(nanoid());
    }

    useEffect(() => {
        if(roleId !== "" &&  orgCmdProp && orgCmdProp.currentOrg !== null) {
            HttpClient.get("/api/v0/jcr/org/" + orgCmdProp?.currentOrg!.orgCode + "/role/" + roleId).then((response) => {
                if(response.data.response !== null) {
                    setUpdateData(response.data.response);
                } else {
                    resetScreen();
                }
            }).catch((e) => {
                resetScreen();
            })
        }
    },[roleId,orgCmdProp,resetScreen])

    useEffect(() => {
        if(!(orgCmdProp && orgCmdProp.currentOrg !== null && orgCmdProp.orgDetail !== null)) return;
        setDataFetcher(new RoleDataFetcher(orgCmdProp.currentOrg));
        setFormKey(nanoid());
    }, [orgCmdProp]);

    const newButton = <IconLinkButton tooltip={"New Policy"} key="newRoleButton" id="newRoleButton" data-action="createNewRole" variant={"contained"} onClick={handleOpenNewDialog}>
        <SolidIcon className={styles.Icon} >add_circle_outline</SolidIcon>
    </IconLinkButton>;

    const uploadButton = <IconLinkButton tooltip={"Upload File"} key="uploadRoleButton" id="uploadRoleButton" data-action="uploadNewRole" variant={"contained"} onClick={handleUploadDialog}>
        <OutlinedIcon className={styles.Icon} >cloud_upload</OutlinedIcon>
    </IconLinkButton>;

    const deleteButton = <ReverseSolidLinkSmallButton id="deleteRoleButton" data-action="deleteRoles" variant={"contained"} onClick={handleDelete}>
        <OutlinedIcon className={styles.Icon} >delete</OutlinedIcon>
        Remove ({deleteList.length})
    </ReverseSolidLinkSmallButton>;

    const downloadButton = <ReverseSolidLinkSmallButton id="downloadRoleButton" data-action="downloadRoles" variant={"contained"} onClick={handleDownloadList}>
        <OutlinedIcon className={styles.Icon} >download</OutlinedIcon>Download ({deleteList.length})
    </ReverseSolidLinkSmallButton>;

    return <React.Fragment>
        <div className={commonStyle.ContentContainer+ " " + commonStyle.ContentContainerOverflowX}>
            <PageSummary type={"userpolicies"}/>
            {   newOpen &&
                <InputDialog
                    id={"role"}
                    title={"New User Policy"}
                    handleClose={handleCloseNewDialog}
                    handleSave={handleNewDialog}
                    open={newOpen}
                    inserted={true}
                    fields={[
                        {id: "roleName", label: "Name", defaultValue: "", required: true , editable: true},
                        {id: "roleData", label: "Roles", type: "role", defaultValue: "", required: false , editable: true},
                        {id: "roleActive", label: "Active", type: "checkbox", defaultValue: true, required: false , editable: true},
                    ]}
                    contentText={null}/>
            }
            {   updateData.id &&
                <InputDialog
                    id={"role"}
                    title={"Update User Policy"}
                    handleClose={handleCloseUpdateDialog}
                    handleSave={handleUpdateDialog}
                    open={true}
                    inserted={false}
                    fields={[
                        {id: "roleName", label: "Name", defaultValue: updateData.roleName, required: true , editable: true},
                        {id: "roleData", label: "Roles", type: "role", defaultValue: updateData.roleData, required: true , editable: true},
                        {id: "roleActive", label: "Active", type: "checkbox", defaultValue: updateData.roleActive, required: false , editable: true},
                    ]}
                    contentText={null}/>
            }

            <StyledTable
                key={formKey}
                handleAction={handleAction}
                handleDeleteLists={(ids) => setDeleteList(ids)}
                hasCheckBox={true}
                dataFetcher={dataFetcher}
                rowsPerPage={20}

                title={"Policies"}

                actionComponents={[uploadButton,newButton]}
                deleteButton={deleteButton}
                downloadButton={downloadButton}



                page={parseInt((query.get("page") === null ? "0" : query.get("page")!))}
                rowsPerPageOptions={[5,10,20,40,60,{label: "All" , value: -1}]}/>
            <input key={`upload_${formKey}`} type='file' id='upload-content'
                   accept=".gzip,.gz"
                   onChange={() => doUploadFile()}
                   ref={inputFile} style={{display: 'none'}}/>
        </div>

    </React.Fragment>
}