import {imageUtil} from "./ImageUtil";

function HTMLUtil() {
    return {
        removeURL: (url: string) => {
            if(url !== null && url !== undefined && url.startsWith("url(\"")) {
                url = url.substring(5);
                if(url.endsWith("\")")) {
                    url = url.substring(0,url.length-2);
                }
                return url;
            } else {
                return url;
            }
        },

        process: (portalURL:string|undefined, html: string) => {
            if(!html) return "";
            let buffer: string[] = [];
            let bufferTag: string[] = [];
            for(let i=0;i<html.length;i++) {
                let char = html.charAt(i);
                if(char === '<') {
                    if(i+1 < html.length) {
                        let nextChar = html.charAt(i+1);
                        if(nextChar !== "/") { // beginning of tag
                            bufferTag = [char]
                            for(let j=i+1;j<html.length;j++) {
                                let tagChar = html.charAt(j);
                                if(html.charAt(j) === '>') {
                                    bufferTag.push(">");
                                    i = j;
                                    let tag = bufferTag.join("");
                                    if(tag.startsWith("<a")) {
                                        let index = tag.indexOf("href=\"")
                                        if(index > 0) {
                                            let lastIndex = tag.indexOf("\"",index+6);
                                            let href = tag.substring(index+6,lastIndex);
                                            tag = tag.substring(0,index+6) + href + tag.substring(lastIndex);
                                        }
                                    } else if(tag.startsWith("<img")) {
                                        let index = tag.indexOf("src=\"")
                                        if(index > 0) {
                                            let lastIndex = tag.indexOf("\"",index+5);
                                            let src = tag.substring(index+5,lastIndex);
                                            //console.log("before tag => ",tag);
                                            tag = tag.substring(0,index+5) + imageUtil.toPath(portalURL,src) + tag.substring(lastIndex);
                                            //console.log("src => ",src);
                                            //console.log("after tag => ",tag);
                                        }
                                    }
                                    buffer.push(tag)
                                    break;
                                } else {
                                    bufferTag.push(tagChar)
                                }
                            }
                        } else {
                            buffer.push(char);
                        }
                    } else {
                        buffer.push(char);
                    }
                } else {
                    buffer.push(char);
                }
            }
            //return buffer.join("")
            //console.log("HTML",html);
            //console.log("OUTPUT",buffer.join(""))
            //return html
            // console.log(buffer.join(""));
            return buffer.join("");
        }

    }
}

export const htmlUtil = HTMLUtil();