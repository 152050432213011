import './App.css';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import View from "./ui/view/View";
import NoMatch from "./ui/nomatch/NoMatch";
import React, {useEffect, useState} from "react";
import Home from "./ui/home/Home";
import UserMenu from "./ui/menu/UserMenu";
import Login from "./ui/login/Login";
import Logout from "./ui/logout/Logout";
import ProtectedRoute from './ui/protectedroute/ProtectedRoute';
import Views from "./ui/view/Views";
import withStyles from '@mui/styles/withStyles';
import Themes from "./ui/theme/Themes";
import Drilldowns from "./ui/drilldown/Drilldowns";
import CMSRoutes from "./ui/cmsroute/CMSRoutes";
import Headers from './ui/header/Headers';
import Header from "./ui/header/Header";
import Footers from "./ui/footer/Footers";
import Footer from "./ui/footer/Footer";
import Storefronts from "./ui/storefront/Storefronts";
import Organizations from "./ui/organization/Organizations";
import {printLogo} from "./util/LogoConfig";
import Theme from "./ui/theme/Theme";
import AppFooter from "./ui/appfooter/AppFooter"
import {UploadProvider} from "./context/UploadProvider";
import Assets from "./ui/assets/Assets";
import Drilldown from "./ui/drilldown/Drilldown";
import Roles from "./ui/role/Roles";
import {Contents} from "./ui/content/Contents";
import PIM from "./ui/pim/PIM";
import GoogleTagProvider from "./context/GoogleTagProvider";
import {StyledEngineProvider, ThemeProvider, useMediaQuery} from "@mui/material";
import {createLightTheme} from "./util/ThemeUtil";
import LiveEditor from "./ui/editor/LiveEditor";
import PageTemplate from "./ui/template/PageTemplate";
import SectionTemplate from "./ui/template/SectionTemplate";



function App() {
  const [isLogin, setLogin] = useState(false);
  const [showMenu, setShowMenu] = useState(true);
  const [showFooter, setShowFooter] = useState(true);
  const preferDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(() => preferDarkMode ? createLightTheme() : createLightTheme(),[preferDarkMode])


  useEffect(() => {
    setTimeout(printLogo,2000);
  },[]);
  return (
      <StyledEngineProvider injectFirst><ThemeProvider theme={theme}>
      <GoogleTagProvider>
        <UploadProvider>
          <Router>
            {isLogin && showMenu && <UserMenu/>}
            <Switch>

              <ProtectedRoute isLogin={isLogin} exact path="/home" component={Home}/>

              <ProtectedRoute exact isLogin={isLogin} path="/sites" component={Storefronts} />
              <ProtectedRoute isLogin={isLogin} path="/sites/:evid" component={Storefronts}/>

              <ProtectedRoute exact isLogin={isLogin} path="/pagestemplate" component={PageTemplate} />
              <ProtectedRoute isLogin={isLogin} path="/pagestemplate/:evid" component={PageTemplate}/>

              <ProtectedRoute exact isLogin={isLogin} path="/sectiontemplate" component={SectionTemplate} />
              <ProtectedRoute isLogin={isLogin} path="/sectiontemplate/:evid" component={SectionTemplate}/>


              <ProtectedRoute exact isLogin={isLogin} path="/organizations" component={Organizations} />
              <ProtectedRoute isLogin={isLogin} path="/organization/:oname" component={Organizations}/>

              <ProtectedRoute exact isLogin={isLogin} path="/themes" component={Themes} />
              <ProtectedRoute exact isLogin={isLogin} path="/themes/:tid" component={Themes} />
              <ProtectedRoute exact isLogin={isLogin} path="/themes/:tname/:tid" component={Theme}/>

              <ProtectedRoute exact isLogin={isLogin} path="/drill-downs" component={Drilldowns} />
              <ProtectedRoute exact isLogin={isLogin} path="/drill-downs/:did" component={Drilldowns} />
              <ProtectedRoute exact isLogin={isLogin} path="/drill-downs/:dname/:did" component={Drilldown}/>


              <ProtectedRoute exact isLogin={isLogin} path="/routes"  component={CMSRoutes}/>
              <ProtectedRoute exact isLogin={isLogin} path="/routes/:nid" component={CMSRoutes}/>

              <ProtectedRoute exact isLogin={isLogin} path="/pages" component={Views} />
              <ProtectedRoute exact isLogin={isLogin} path="/pages/:vid" component={Views} />
              <ProtectedRoute isLogin={isLogin} path="/pages/:vname/:vid" component={View}/>
{/*//http://localhost:3003/live/editor/shocasddev/view/86e824ba-b18c-4336-98fb-59d09964e3c1/sfId=86a20150-84a0-4252-9c90-df3866c194a3*/}
              <ProtectedRoute isLogin={isLogin} path={"/live/:type/:orgCode/:viewType/:viewId/:sfId"} doLogin={setLogin} doShowMenu={setShowMenu} doShowFooter={setShowFooter} component={LiveEditor}/>

              <ProtectedRoute exact isLogin={isLogin} path="/headers"  component={Headers}/>
              <ProtectedRoute exact isLogin={isLogin} path="/headers/:hid"  component={Headers}/>
              <ProtectedRoute isLogin={isLogin} path="/headers/:hname/:hid"  component={Header}/>

              <ProtectedRoute exact isLogin={isLogin} path="/footers"  component={Footers}/>
              <ProtectedRoute exact isLogin={isLogin} path="/footers/:fid"  component={Footers}/>
              <ProtectedRoute isLogin={isLogin} path="/footers/:fname/:fid"  component={Footer}/>

              <ProtectedRoute exact isLogin={isLogin} path="/assets/"  component={Assets}/>
              <ProtectedRoute exact isLogin={isLogin} path="/assets/:type"  component={Assets}/>

              <ProtectedRoute exact isLogin={isLogin} path="/content"  component={Contents}/>
              <ProtectedRoute exact isLogin={isLogin} path="/content/:group"  component={Contents}/>
              <ProtectedRoute exact isLogin={isLogin} path="/content/:group/:groupId"  component={Contents}/>
              <ProtectedRoute exact isLogin={isLogin} path="/content/:group/:groupId/:itemId"  component={Contents}/>

              <ProtectedRoute exact isLogin={isLogin} path="/userpolicies" component={Roles} />
              <ProtectedRoute isLogin={isLogin} path="/userpolicies/:rid" component={Roles}/>
              <ProtectedRoute exact isLogin={isLogin} path="/pim" component={PIM} />


              {/*<ProtectedRoute exact isLogin={isLogin} path="/assets/dam"  component={DAM}/>*/}
              {/*<ProtectedRoute exact isLogin={isLogin} path="/assets/pdam"  component={DAM}/>*/}


              <ProtectedRoute exact isLogin={isLogin}  path="/" component={Home} />

              {/*<Route exact path={"/test/slick"}>*/}
              {/*  <Slick infinite={true}*/}
              {/*         itemsPerPage={10}*/}
              {/*         scrollItems={20}*/}
              {/*         autoplaySpeed={100}*/}
              {/*         animationSpeedInMs={300}*/}
              {/*         animationType={"slide"}*/}
              {/*         targetItemWidth={200}*/}
              {/*         contentInlinePadding={20}*/}
              {/*         slickStyle={""}*/}
              {/*         arrowProps={ {width: 10, height: 20, strokeSize: 2, color: "black", inlineMargin: "10px", blockMargin: "20%" }}*/}
              {/*   >*/}
              {/*    <div style={{height: "100px", lineHeight: "100px", textAlign: "center", background: "lightgray"}}><img src="https://demo.silvereclipse.net/images/blog/Acme-website.jpg" width={80} height={80} loading="lazy"/></div>*/}
              {/*    <div style={{height: "100px", lineHeight: "100px", textAlign: "center"}}><img src="https://demo.silvereclipse.net/images/blog/Halco-website.jpg" width={80} height={80} loading="lazy"/></div>*/}
              {/*    <div style={{height: "100px", lineHeight: "100px", textAlign: "center", background: "lightgray"}}><img src="https://demo.silvereclipse.net/images/blog/Acme-website.jpg" width={80} height={80} loading="lazy"/></div>*/}
              {/*  </Slick>*/}
              {/*</Route>*/}
              <Route exact path={"/test"}>
                {/*<PublishSteps/>*/}
              </Route>

              {/*<Route exact path={"/test/css"}>*/}
              {/*  <MonacoEditor title={"Monaco"} language={"css"} defaultValue={"body {}"}/>*/}
              {/*</Route>*/}
              {/*<Route exact path={"/test/javascript"}>*/}
              {/*  <MonacoEditor title={"Javascript"} language={"javascript"} defaultValue={"function method() {}"}/>*/}
              {/*</Route>*/}
              {/*<Route exact path={"/test/example01"}>*/}
              {/*  <Example01></Example01>*/}
              {/*</Route>*/}
                {/*<MonacoEditor/>*/}
                {/*<TinyEditor/>*/}
                {/*  <ProductSearchField*/}
                {/*      searchFields={[*/}
                {/*        {label: "_lsi_id_", value: "_lsi_id_"}*/}
                {/*      ]}*/}
                {/*      generalSearch={"This is a test"}*/}
                {/*      filters={[*/}
                {/*        { field: "_lsi_id_", value: "456", operator: "is", isEnabled: true}*/}
                {/*      ]}*/}
                {/*    />*/}
                {/*  <RoleField filters={[]}/>*/}
                {/*<ChipField inputProps={{label: "Text"}}*/}
                {/*           chips={["test1"]}*/}
                {/*           options={[{label: "Test 1", value: "test1"},{label: "Test 2", value: "test2"},{label: "Test 3", value: "test3"},{label: "Test 4", value: "test4"}]}*/}
                {/*           onChange={ (values) => {console.log(values)}}/>*/}
              {/*</Route>*/}
              <Route exact path="/login"  >
                <Login doLogin={setLogin} doShowMenu={setShowMenu} doShowFooter={setShowFooter}/>
              </Route>
              <Route exact path="/logout" >
                <Logout doLogin={setLogin}/>
              </Route>
              <Route path="*" component={NoMatch}/>
            </Switch>
            {isLogin && showMenu && showFooter && <AppFooter /> }
          </Router>
        </UploadProvider>
      </GoogleTagProvider>
      </ThemeProvider></StyledEngineProvider>
  );
}

const cssVariables = (theme:any) => ({
  '@global': {
    ':root': {

      // '--bt-cms-color-primary-dark': "#005d3a",
      // '--bt-cms-color-secondary': "#6ecaa7",
      // '--bt-cms-color-secondary-dark': "#04be77",

      '--standard-spruce-100': '#dcebed',
      '--standard-spruce-200': '#a2bfc4',
      '--standard-spruce-300': '#55878e',
      '--standard-spruce-400': '#025b62',
      '--standard-spruce-500': '#013035',
      '--standard-spruce-600': '#00262a',
      '--standard-spruce-700': '#001d20',
      '--standard-spruce-800': '#001316',

      '--standard-jade-100': '#e5f3ee',
      '--standard-jade-200': '#bfe1d5',
      '--standard-jade-300': '#7fc2ab',
      '--standard-jade-400': '#40a482',
      '--standard-jade-500': '#008658',
      '--standard-jade-600': '#00764a',
      '--standard-jade-700': '#00663b',
      '--standard-jade-800': '#00562d',

      '--standard-lime-100': '#eef8d2',
      '--standard-lime-200': '#ddf1a5',
      '--standard-lime-300': '#cce977',
      '--standard-lime-400': '#bbe24b',
      '--standard-lime-500': '#abdb1e',
      '--standard-lime-600': '#9cd318',
      '--standard-lime-700': '#8dcb10',
      '--standard-lime-800': '#7fc30c',
      '--standard-column-hover': '#9614E1',
      '--standard-widget-hover': '#006BE6',
      '--standard-coal-500': '#001821',
      '--standard-coal-400': '#4d6069',
      '--standard-coal-300': '#9fa8ac',
      '--standard-coal-200': '#d2d6d8',
      '--standard-coal-100': '#edeeee',

      '--standard-white': '#ffffff',
      '--standard-black': '#000000DE',
      '--standard-555555': '#555555',

      '--standard-smoke-400': '#f9f9f9',
      '--standard-smoke-500': '#f4f4f4',
      '--standard-smoke-600': '#edeeee',
      '--standard-smoke-700': '#d2d6d8',
      '--standard-smoke-800': '#9fa8ac',
      

      '--text-color-coal-500': '#001821',
      '--text-color-coal-400': '#4d6069',
      '--text-color-light-gray': '#66737a',
      '--secondary-text-color': '#6F787B',
      '--text-color-coal-800': '#8fa8cac',

      '--primary-color-spruce-500': 'var(--standard-spruce-500)',
      '--primary-color-jade-500': 'var(--standard-jade-500)',
      '--primary-color-smoke-500': 'var(--standard-smoke-500)',
      '--primary-color-white': 'var(--standard-white)',


      '--info-status-dark-color': '#003f87',
      '--info-status-default-color': '#006be6',
      '--info-status-light-color': '#f0f5ff',

      '--success-status-dark-color': '#005c00',
      '--success-status-default-color': '#3da946',
      '--success-status-light-color': '#ecfdf4',

      '--error-status-dark-color': '#8f1100',
      '--error-status-default-color': '#cf1f07',
      '--error-status-light-color': '#fef212',

      '--warning-status-dark-color': '#9c6300',
      '--warning-status-default-color': '#fca700',
      '--warning-status-light-color': '#fef7ea',


      // '--bt-cms-color-drawer': '#004475',
      // '--bt-cms-color-drawer-menu-item-background': '#009387',
      // '--bt-cms-color-primary': theme.palette.primary.main,
      // '--bt-cms-color-primary-dark': "#00365d",
      // '--bt-cms-color-sidebar-active-dark': "#004475",
      // '--bt-cms-color-secondary': "#6ea1ca",
      // '--bt-cms-color-secondary-dark': "#04aebe",


      '--bt-cms-color-primary': 'var(--standard-spruce-500)',
      '--bt-cms-color-primary-normal': 'var(--standard-jade-500)',//"#00365d",
      '--bt-cms-color-primary-dark': 'var(--standard-spruce-500)',//"#00365d",
      '--bt-cms-color-sidebar-dark': "var(--standard-spruce-500)",
      '--bt-cms-color-sidebar-active-dark': "var(--standard-jade-500)",
      '--bt-cms-color-drawer': '#FFFFFF',
      '--bt-cms-color-drawer-menu-item-background': 'var(--standard-smoke-500)',
      '--bt-cms-color-secondary': "var(--standard-spruce-500)",
      '--bt-cms-color-secondary-dark': "var(--standard-spruce-500)",

      '--bt-cms-color-error': 'red',// theme.palette.error.main,
      '--bt-cms-color-info': 'black',// theme.palette.info.main,
      '--bt-cms-org-width': '160px', // width

      '--bt-cms-normal-space': '0.6rem',
      '--bt-cms-small-space': '0.3rem',
      '--bt-cms-large-space': '0.9rem',
      '--bt-cms-no-space': '0rem',
      '--bt-cms-appbar-user-height': '64px',//,//'65px', //this component will merge with page height.
      '--bt-cms-appbar-page-height': '0px', //'65px'
      '--bt-cms-appbar-action-height': '55px', //'65px'
      '--bt-cms-drawer-width': '248px',
      '--bt-cms-search-panel-width': '90%',
      '--bt-cms-dam-panel-width': '90%',
      '--bt-cms-drawer-padding' : '0px',
      '--bt-cms-drawer-z-index': '1000',
      '--bt-cms-drawer-backdrop-z-index': '1009',
      '--bt-cms-action-panel-bg-color': 'white',
      '--bt-cms-appbar-z-index': '1000',
      '--bt-cms-setting-drawer-width': '400px',
      '--bt-cms-appbar-action-z-index': '1001',
      '--bt-cms-sidebar-main-width': '72px', //5rem
      '--bt-cms-sidebar-move-left': '5rem', // should be the same as --bt-cms-sidebar-main-width or zero value
      '--bt-cms-org-footer-height': '65px',
      '--bt-cms-footer-height': 'var(--bt-cms-org-footer-height)',
      '--bt-cms-footer-z-index': '950',
      '--bt-cms-timeline-z-index':'2000',
      '--bt-cms-footer-background-color': 'transparent',
    }
  }
});

export default withStyles(cssVariables)(App);
