import styles from "./TemplateDialog.module.scss";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField} from "@mui/material";

import React, {useEffect, useState} from "react";
import ChipField from "../chipfield/ChipField";
import {useOrganization} from "../../hook/useOrganization";
import {InputOption} from "../../model/InputModel";
import ReverseSolidLinkButton from "../button/ReverseSolidLinkButton";
import SolidLinkButton from "../button/SolidLinkButton";
import {StyleUtil} from "../../cms/ui/setting/StyleUtil";
import YesNoSwitch from "../../cms/ui/setting/onoffswitch/YesNoSwitch";
import {useSSO} from "../../datafetcher/HttpClient";
import {TemplateCmd} from "../../model/TemplateCmd";
import {jcr} from "../../datafetcher/JCRDataFetcher";
import {Tag1Options} from "./TemplateConst";
import OutlinedIcon from "../icon/OutlinedIcon";

export interface PageTemplateDialogProps {
    id: string|null,
    header: string,
    title: string,
    open: boolean,
    internalComment: string,
    active: boolean,
    onClose: (update?:boolean) => void,
    subTitle: string,
    // ids: string[]
    viewIds?: string[] // for new object only
    viewNames: string[]
    viewData: string[]
    viewLogos: string[]
    tags1: string[]
    logo: string,
    allowOrgs: string[]
    inserted: boolean
}

export default function PageTemplateDialog(props: PageTemplateDialogProps) {

    const orgCmd = useOrganization();
    const ssoCmd = useSSO();
    const [disable, setDisable] = useState<boolean>(true);
    const [allowOrgs, setAllowOrgs] = useState<string[]>(props.allowOrgs);
    const [internalComment, setInternalComment] = useState<string>(props.internalComment);
    const [tags1, setTags1] = useState<string[]>(props.tags1);
    const [logo, setLogo] = useState<string>(props.logo);
    const [title, setTitle] = useState<string>(props.title);
    const [subTitle, setSubTitle] = useState<string>(props.subTitle);
    const [active, setActive] = useState<boolean>(props.active);
    const [viewNames, setViewNames] = useState<string[]>(props.viewNames);
    const [viewLogos, setViewLogos] = useState<string[]>(props.viewLogos)



    useEffect(() => {
        if(title.trim() === "") {
            setDisable(true);
        } else if(tags1.length === 0) {
            setDisable(true)
        } else if(allowOrgs.length === 0) {
            setDisable(true)
        } else {
            setDisable(false);
        }
    },[title, tags1, allowOrgs])

    if(orgCmd === null || orgCmd === undefined) return <></>

    let orgOptions:InputOption[] = [];

    if(ssoCmd.roleName === "LSIADMIN") {
        orgOptions.push({label: "Every Organizations", value: "all_orgs"});
        orgCmd.orgCmds?.map(org => {
            orgOptions.push({value: org.orgCode, label: org.orgName});
        })
    } else {
        orgCmd.orgCmds?.filter(org => org.orgCode === ssoCmd.orgCode).map(org => {
            orgOptions.push({value: org.orgCode, label: org.orgName});
        })
    }


    let tag1Options:InputOption[] = Tag1Options;


    const handleClickSave = () => {

        let data: TemplateCmd = {
            id: props.id,
            title: title.trim(),
            subTitle: subTitle.trim(),
            type: "page",
            logo: logo.trim(),
            tags1: tags1.map(value => value.trim()),
            tags2: [], // page did not have tag2
            internalComment: internalComment.trim(),
            views: viewNames.map(value => value.trim()), // viewnames
            viewCaches: props.viewData.map(value => value.trim()),
            viewLogos: viewLogos.map(value => value.trim()),
            allowOrgs,
            active,
            modifiedAt: 0
        }
        if(props.viewIds) { // for new object only
            data['viewIds'] = props.viewIds;
        }
        
        jcr.pageTemplate.update(data).then((response) => {
            props.onClose(true);
        })
    }


    return <Dialog
        open={props.open}
        onClose={() => props.onClose(false)}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"xl"}
    >
        <DialogTitle>{props.header}
            <IconButton className={styles.CloseIcon}
                        onClick={() => {
                            props.onClose(false)
                        }}>
                <OutlinedIcon>close</OutlinedIcon>
            </IconButton>
        </DialogTitle>
        <DialogContent dividers={true} className={styles.DialogContent}>
            <Grid container spacing={1}>
                <Grid item xs={3}><span  className={styles.FormLabel}>Title *</span></Grid>
                <Grid item xs={9}><TextField fullWidth size={"small"} value={title} onChange={(event) => { setTitle(event.target.value)}}/></Grid>
                <Grid item xs={3}><span  className={styles.FormLabel}>Sub Title</span></Grid>
                <Grid item xs={9}><TextField fullWidth size={"small"} value={subTitle} onChange={(event) => { setSubTitle(event.target.value)}}/></Grid>
                <Grid item xs={3}><span  className={styles.FormLabel}>Internal Comment</span></Grid>
                <Grid item xs={9}><TextField multiline={true} rows={3} fullWidth size={"small"} value={internalComment} onChange={(event) => { setInternalComment(event.target.value)}}/></Grid>
                <Grid item xs={3}><span  className={styles.FormLabel}>Concepts *</span></Grid>
                <Grid item xs={9}><ChipField inputProps={{}}
                                             chips={tags1}
                                             options={tag1Options}
                                             onChange={(value:string[]) => {
                                                 setTags1(value);
                                             }
                                             }/></Grid>
                <Grid item xs={3}><span  className={styles.FormLabel}>Allow Access *</span></Grid>
                <Grid item xs={9}><ChipField inputProps={{}}
                                             chips={allowOrgs}
                                             options={orgOptions}
                                             onChange={(value:string[]) => {
                                                 setAllowOrgs(value);
                                                }
                                             }/></Grid>
                <Grid item xs={3}><span  className={styles.FormLabel}>Cover Image URL</span></Grid>
                <Grid item xs={9}><TextField fullWidth size={"small"} value={logo} onChange={(event) => { setLogo(event.target.value)}}/></Grid>
                <Grid item xs={3}><span  className={styles.FormLabel}>Active</span></Grid>
                <Grid item xs={9}><YesNoSwitch defaultValue={active} onChange={(value) => {
                    setActive(value)
                }}/></Grid>
                {viewLogos.map((logo, index) => {
                    return <React.Fragment key={`template_view_name_${index}`}>
                        <Grid item xs={3}><span  className={styles.FormLabel}><b>#{index+1}</b> Name</span></Grid>
                        <Grid item xs={9}><TextField fullWidth size={"small"} value={viewNames[index]} onChange={(event) => {
                            let newNames = [...viewNames];
                            newNames[index] = event.target.value;
                            setViewNames(newNames);
                        }}/></Grid>
                        <Grid item xs={3}><span  className={styles.FormLabel}><b>#{index+1}</b> Covert Image URL</span></Grid>
                        <Grid item xs={9}><TextField fullWidth size={"small"} value={viewLogos[index]} onChange={(event) => {
                            let newLogos = [...viewLogos];
                            newLogos[index] = event.target.value;
                            setViewLogos(newLogos);
                        }}/></Grid>
                    </React.Fragment>
                })}
            </Grid>
        </DialogContent>
        <DialogActions>
            <ReverseSolidLinkButton id={`pageTemplateDialogCancelButton`} data-action={`doCloseDialog`} onClick={() => props.onClose(false)} color="secondary" className={styles.Button}>
                Cancel
            </ReverseSolidLinkButton>
            <SolidLinkButton id={props.inserted ? `pageTemplateDialogCreateButton` : `pageTemplateDialogUpdateButton`}
                             data-action={props.inserted ? "doCreatePageTemplate" : "doUpdatePageTemplate"}
                             size={"small"}
                             onClick={handleClickSave}
                             disabled={disable} color="primary"
                             className={StyleUtil.combineClasses(styles.Button, styles.ButtonInlineSpace) }>
                {props.inserted ? "Create" : "Update"}
            </SolidLinkButton>
        </DialogActions>
    </Dialog>
}
