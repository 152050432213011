import {useEffect, useState} from "react";
import HttpClient from "../datafetcher/HttpClient";
import {singletonHook} from "react-singleton-hook";
import {nanoid} from "nanoid";
import OrgFullCmd from "../model/jcr/OrgFullCmd";
import {getCurrentMemberCore, lsi} from "../datafetcher/LSIDataFetcher";
import {jcr} from "../datafetcher/JCRDataFetcher";
import LSIFieldCmd from "../model/lsi/LSIFieldCmd";
import {InputOption} from "../model/InputModel";
import ConnectorCmd from "../model/lsi/ConnectorCmd";
import portalClient from "../datafetcher/PortalClient";
import {AxiosResponse} from "axios";
import {TrackData} from "../util/TrackUtil";
import {ProxyOrgLiteCmd} from "../model/proxy/cloudlsi-app";
import AttributeGroupCmd from "../model/lsi/AttributeGroupCmd";

export interface OrgCmdHookProps {
    orgCmds: ProxyOrgLiteCmd[] | null,
    orgDetail: OrgFullCmd | null,
    currentOrg: ProxyOrgLiteCmd| null,
    setCurrentOrg: ((currentOrg: ProxyOrgLiteCmd|null) => void) | null,
    updateCurrentOrg: () => void
    refresh: () => void;
}


const useOrgCmdImp = (): OrgCmdHookProps => {
    const [orgCmds, setOrgCmds] = useState([]);
    const [currentOrg,__setCurrentOrg] = useState<ProxyOrgLiteCmd|null>(null);
    const [orgDetail,__setOrgDetail] = useState<OrgFullCmd|null>(null);
    const [refreshId, setRefreshId] = useState<string>(nanoid());

    function populateExternalObjects(orgFullCmd: OrgFullCmd) {
        lsi.cdnURL = orgFullCmd.cdnURL;
        lsi.lsiURL = orgFullCmd.lsiConfig.searchURL;
        lsi.orgCode = orgFullCmd.orgCode;
        jcr.orgCode = orgFullCmd.orgCode;
        lsi.siteCode = orgFullCmd.siteCode;
        lsi.memberCore = orgFullCmd.lsiConfig.memberCore;
        lsi.connectorName = orgFullCmd.lsiConfig.connectorName;
        lsi.connectorType = orgFullCmd.lsiConfig.connectorType;
        lsi.searchProfile = orgFullCmd.lsiConfig.searchProfile;
        lsi.ppa = orgFullCmd.ppaConfig;
    }

    const populateOrgCmd = (response: AxiosResponse<any>) : OrgFullCmd => {

        let orgFullCmd = response.data as OrgFullCmd;
        populateExternalObjects(orgFullCmd);
        portalClient.setPortalBackEndURL(orgFullCmd.portalURL,orgFullCmd.orgCode)

        return orgFullCmd;
    }

    // Update current organization.
    const updateCurrentOrg = async () => {
        if(currentOrg && currentOrg?.orgCode) {
            HttpClient.get("/api/proxy/orgs/" + currentOrg?.orgCode).then( async (response) => {
                let newOrgFullCmd = {...orgDetail, ...populateOrgCmd(response)}
                __setOrgDetail(newOrgFullCmd);
            });

            // HttpClient.get("/api/jcr/org/" + currentOrg?.orgCode).then( async (response) => {
            //     let newOrgFullCmd = {...orgDetail, ...populateOrgCmd(response)}
            //     __setOrgDetail(newOrgFullCmd);
            // });
        }
    }


    async function populateOrgFullCmd(orgFullCmd: OrgFullCmd) {
        //if (portalClient.isReady()) portalClient.anonymousSSO(1);
        let asyncArray = [];



        if(getCurrentMemberCore() == null) {
            orgFullCmd.fieldNameToDisplayName = new Map();
            orgFullCmd.searchFieldOptions = []
            orgFullCmd.displayFieldOptions = []
            orgFullCmd.imageFieldOptions = []
            orgFullCmd.lsiFields = [];
            orgFullCmd.lsiFieldsNameToIndex = new Map();
            __setOrgDetail(orgFullCmd);
            return;
        }

        asyncArray.push(lsi.schema.allfields());
        asyncArray.push(lsi.connector.connectorNames("ec2PWeb"));
        asyncArray.push(lsi.schema.membercores());
        asyncArray.push(lsi.attributegroup.groups());

        let results = await Promise.all(asyncArray);
        let displayFields: InputOption[] = [];
        let imageFields: InputOption[] = [];
        let ignoreFields = new Set<string>();
        let fieldNameToDisplayName = new Map<string, string>();

        //load membercore
        let memberCores = results[2].data;
        if(memberCores.length > 0) {
            orgFullCmd.memberCores = [...memberCores];
        }

        let attributegroups:AttributeGroupCmd[] = results[3].data;
        if(attributegroups.length > 0) {
            let attributeGroupOptions: InputOption[] = [];
            for (let attributegroup of attributegroups) {
                attributeGroupOptions.push({
                    label: attributegroup.name,
                    value: attributegroup.name,
                    object: attributegroup
                })
            }
            orgFullCmd.attributeGroupOptions = attributeGroupOptions.sort((option1, option2) => {
                return option1.label.toUpperCase().localeCompare(option2.label.toUpperCase());
            });
        }

        //load connector
        let connectorNames = results[1].data;
        if (connectorNames.length > 0) {
            let connectorDetail: ConnectorCmd[] = (await lsi.connector.connectorDetail(connectorNames[0].id)).data as ConnectorCmd[];
            for (let connector of connectorDetail) {
                displayFields.push({label: connector.name, value: connector.name});
                if (connector.name.indexOf("Image") >= 0) {
                    imageFields.push({label: connector.name, value: connector.name});
                }
                if (connector.showMappingField === false) {
                    ignoreFields.add(connector.lsiFieldName);
                }
            }
        }

        //load lsi fields
        let lsiFieldsNameToIndex = new Map<string, number>();
        let fields: LSIFieldCmd[] = results[0].data as LSIFieldCmd[];
        let searchFields: InputOption[] = [];

        for (let i = 0; i < fields.length; i++) {
            let name = fields[i].name;
            if (fields[i].type === "dynamic") continue;
            fieldNameToDisplayName.set(fields[i].name, fields[i].displayName);
            if (fields[i].searchable || fields[i].docValues) {
                if (fields[i].mode !== "dynamic") {
                    searchFields.push({label: name, value: name});
                }
            }
            if (fields[i].displayable && ignoreFields.has(fields[i].name) === false) {
                if (fields[i].tabIndex === 3) {
                    imageFields.push({label: fields[i].displayName, value: name})
                }
                displayFields.push({label: fields[i].displayName, value: name})
            }
            lsiFieldsNameToIndex.set(name, i);
        }

        orgFullCmd.fieldNameToDisplayName = fieldNameToDisplayName;
        orgFullCmd.searchFieldOptions = searchFields;
        orgFullCmd.displayFieldOptions = displayFields;
        orgFullCmd.imageFieldOptions = imageFields;
        orgFullCmd.lsiFields = fields;
        orgFullCmd.lsiFieldsNameToIndex = lsiFieldsNameToIndex;

        let nextIndex = window.location.pathname.indexOf("/", 1);
        if (nextIndex > 0 && orgDetail != null) {
            let prevURL = window.location.pathname.substring(0, nextIndex);
            if (prevURL.endsWith("/content")) {
                nextIndex = window.location.pathname.indexOf("/", nextIndex + 1);
                if (nextIndex > 0) {
                    window.location.href = window.location.pathname.substring(0, nextIndex);
                }
            } else {
                window.location.href = prevURL;
            }
        }
        __setOrgDetail(orgFullCmd);
    }

    const setCurrentOrg =  (orgCmd: ProxyOrgLiteCmd|null) => {
        if(orgCmd !== null && orgCmd !== undefined) {
            localStorage.setItem("orgCode",orgCmd.orgCode);
            __setCurrentOrg(orgCmd); // before moving this statement, please check with editor ui
            TrackData("Change Organization",{orgCode: orgCmd.orgCode});
            if(currentOrg === null || currentOrg.orgCode !== orgCmd.orgCode) {
                // let _orgDetail = localStorage.getItem("_orgDetail");
                // if(_orgDetail === null) {
                    HttpClient.get("/api/proxy/orgs/" + orgCmd?.orgCode).then(async (response) => {
                            let orgFullCmd = populateOrgCmd(response);
                            if (orgFullCmd.proxyStatus.fromJcr === false) {
                                HttpClient.post("/api/proxy/orgs/" + orgCmd?.orgCode, orgFullCmd).then(async (response) => {
                                    let orgFullCmd = populateOrgCmd(response);
                                    await populateOrgFullCmd(orgFullCmd);
                                })
                            } else {
                                await populateOrgFullCmd(orgFullCmd);
                            }
                        }
                    )
                // } else {
                //     let newOrgFullCmd = JSON.parse(_orgDetail);
                //     populateExternalObjects(newOrgFullCmd);
                //     __setOrgDetail(newOrgFullCmd);
                //     localStorage.removeItem("_orgDetail")
                // }
            }
        } else {
            __setCurrentOrg(null)
            __setCurrentOrg(null);
        }
    }

    function populateOrgCmds(orgs:any) {
        setOrgCmds(orgs);
        let orgCode = localStorage.getItem("orgCode");
        if (orgCode !== null) {
            let index = orgs.findIndex((data: any) => data.orgCode === orgCode);
            if (index < 0) {
                setCurrentOrg(orgs[0]);
            } else {
                setCurrentOrg(orgs[index]);
            }
        } else {
            setCurrentOrg(orgs[0]);
        }
    }

    useEffect(() => {
        let _orgCmdsStr = localStorage.getItem("_orgCmds");
        if(_orgCmdsStr === null) {
            HttpClient.get("/api/proxy/orgs").then((response) => {
                let orgs = response.data;
                populateOrgCmds(orgs);
            }).catch((err) => {
                console.log(err)
            });
        } else {
            let orgs = JSON.parse(_orgCmdsStr);
            populateOrgCmds(orgs);
            localStorage.removeItem("_orgCmds");
        }
    },[refreshId]);

    return {orgCmds,
            currentOrg,
            orgDetail,
            setCurrentOrg,
            updateCurrentOrg,
            refresh: () => { setRefreshId(nanoid()) }};
};

export const useOrganization = singletonHook<OrgCmdHookProps|undefined>(undefined, useOrgCmdImp);

export function RestOrganizationHook() {

}

