export default interface VersionCmd {
    major: number,
    minor: number,
    patch: number,
}

export function getNextMajorVersion(version: VersionCmd) : VersionCmd {
    return {
        major: version.major+1,
        minor: 0,
        patch: 0
    }
}

export function getNextMinorVersion(version: VersionCmd) : VersionCmd {
    return {
        major: version.major,
        minor: version.minor+1,
        patch: 0
    }
}

export function getNextPatchVersion(version: VersionCmd) : VersionCmd {
    return {
        major: version.major,
        minor: version.minor,
        patch: version.patch+1
    }
}

export function VersionEquals(version: VersionCmd , anotherVersion: VersionCmd) {
    return version.major === anotherVersion.major &&
        version.minor === anotherVersion.minor &&
        version.patch === anotherVersion.patch
}

export function getStartVersion() : VersionCmd {
    return {
        major: 1,
        minor: 0,
        patch: 0,
    }
}