import styles from "./DAM.module.scss";
import {Grid} from "@mui/material";
import SingleMetric from "../metric/SingleMetric";
import React, {useCallback, useEffect, useState} from "react";
import {IByteToString} from "../../util/BytesUtil";
import {lsi} from "../../datafetcher/LSIDataFetcher";

export interface DAMDashboard {
    totalFileSize:number,
    totalFiles: number,
    totalFileSizeInCurrentFolder:number,
    totalSyncedFiles:number
}

export function DAMMetrics(props: {
    orgCode: string,
    currentPath: string,
}) {

    const [metrics,setMetrics]  = useState<DAMDashboard>({
        totalFileSize: 0,
        totalFiles: 0,
        totalFileSizeInCurrentFolder: 0,
        totalSyncedFiles: 0
    });

    const updateDashboard = useCallback(() => {
        if(props.currentPath !== "") {
            lsi.dam.stat(props.currentPath, true).then((response) => {
                let totalSyncedFiles = response.data[1].stats.stats_fields.ORIGINAL_URL.count;
                let totalFiles = response.data[1].stats.stats_fields.SIZE.count;
                let totalFileSize = response.data[1].stats.stats_fields.SIZE.sum;
                let totalFileSizeInCurrentFolder = response.data[0].stats.stats_fields.SIZE.sum;
                setMetrics({totalFiles, totalSyncedFiles, totalFileSize, totalFileSizeInCurrentFolder});
            })
        }
    },[props.currentPath]);

    useEffect(() => {
        updateDashboard();
    },[updateDashboard]);

    let {totalFiles,
        totalFileSize,
        totalFileSizeInCurrentFolder,
        totalSyncedFiles} = metrics;

    return <Grid container className={styles.StatPanel} >
        <Grid item xs={6} sm={6} md={3}>
            <SingleMetric title={"TOTAL FILE SIZE"} value={IByteToString(totalFileSize,1)} color={"#9a4af3"} icon={"save"}/>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
            <SingleMetric title={"FILES IN DAM"} value={totalFiles.toString()} color={"#67d481"} icon={"article"}/>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
            <SingleMetric title={"CURRENT FOLDER SIZE"} value={IByteToString(totalFileSizeInCurrentFolder,1)} color={"#0275bc"} icon={"folder_open"}/>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
            <SingleMetric title={"AUTOMATED SYNC FILES"} value={totalSyncedFiles.toString()} color={"#fbc224"} icon={"sync"}/>
        </Grid>
    </Grid>
}