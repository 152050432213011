import {Card, CardContent, Grid, Typography} from "@mui/material";
import styles from "./MainMenuItem.module.scss";
import {useHistory} from "react-router";
import {MenuConfig} from "../../config/CMSMainMenuConfig";
import {useState} from "react";
import OutlinedIcon from "../icon/OutlinedIcon";

export interface MainMenuItemProps {
    config: MenuConfig
}

const defaultElevation = 0;
const activeElevation = 4;

export default function MainMenuItem(props: MainMenuItemProps) {
    const history = useHistory();
    const [elevation,setElevation] = useState<number>(defaultElevation);

    return <Card id={props.config.id.replace("Submenu","Mainmenu")}
                 data-action={props.config.action}
                 style={
                    elevation === 0 ? {
                        border: "1px solid #00000024",
                        boxShadow: "unset"
                    } : {
                        border: "1px solid #00000000",
                        boxShadow: "0px 2px 12px #00000029"
                    }
                 }
                 sx={{ padding:"0.0rem" ,
                     textAlign: "center" ,
                     borderRadius: "8px",
                     transition: "all 0.3s ease-out"}}
                 className={styles.Container}
                 onClick={(event) => {
                     history.push(props.config.href);
                     event.preventDefault();
                     event.stopPropagation()
                 }
                 }
                 onPointerEnter={() => {
                     setElevation(activeElevation);
                 }
                 }
                 onPointerLeave={() => {
                     setElevation(defaultElevation);
                 }
                 }
                 onPointerDown={(event) => {
                     event.currentTarget.focus();
                     event.preventDefault();
                     event.stopPropagation()
                 }
                 }
                 onContextMenu={(event) => {
                    event.preventDefault()
                 }
                 }

                 tabIndex={-1}
    >
        <CardContent className={styles.Content}>
            <Grid container direction="row" alignItems="center" className={elevation === 2 ? styles.ContentTitle : styles.ContentTitleHighlight}>
                <Grid item xs={1} className={styles.Icon}>
                    <OutlinedIcon flipY={props.config.icon_flip_y}>{props.config.icon}</OutlinedIcon>
                </Grid>
                <Grid item xs={11} className={styles.Title}>
                    <Typography sx={{textAlign: "inherit", fontSize: "inherit",lineHeight:"inherit"}}>
                        {props.config.title}
                    </Typography>
                </Grid>
                <Grid item xs={12} className={styles.ContentBody}>
                    <Typography variant="body1" sx={{textAlign: "start", fontSize: "inherit",lineHeight:"inherit"}}>
                        {props.config.desc}
                    </Typography>
                </Grid>
            </Grid>

        </CardContent>
    </Card>
}