import React, {ChangeEvent, useEffect, useState} from "react";
import styles from "./Login.module.scss";
import logo from '../../assets/logo.svg';
import {TextField} from "@mui/material";
import { withStyles } from '@mui/styles';
import {useHistory, useLocation} from "react-router";
import {useAppDispatch} from "../../redux/hook";
import {loginAction} from "../../redux/features/login/loginSlice";
import {AxiosResponse} from "axios";
import client from '../../datafetcher/HttpClient';
import ResponseCmd from "../../cms/model/ReponseCmd";
import SSOCmd from "../../model/lsi/SSOCmd";
import SolidIcon from "../icon/SolidIcon";
import {TrackData} from "../../util/TrackUtil";
import SolidLinkButton from "../button/SolidLinkButton";


const StyledTextField = withStyles({
    root: {
        marginTop: "0.5rem",
        marginBottom: "0.5rem"
    }
})(TextField);


type LoginState = {
    login: string,
    password: string,
    showPassword: boolean
}


export default function Login(props:any) {
    const [disabled, setDisabled] = useState<any>(null);
    const [values, setValues] = useState<LoginState>({
        login: '',
        password: '',
        showPassword: false,
    });

    const [visible,setVisible] = useState<boolean>(false);

    let location = useLocation<any>();
    let history = useHistory();
    let dispatch = useAppDispatch();


    const processSSOCmd = (ssoCmd: SSOCmd) => {
        if (ssoCmd.verify) {
            localStorage.setItem("userName", ssoCmd.name);
            localStorage.setItem("refreshToken", ssoCmd.refreshToken);
            localStorage.setItem("userProfileImage", "");
            dispatch(loginAction({
                userName: ssoCmd.name,
                userProfileImage: ""
            }));

            TrackData("Login",{userId: ssoCmd.email, userName: ssoCmd.name, role: ssoCmd.roleName, orgCode: ssoCmd.orgCode})
            props.doLogin(true);
            if (location.state === undefined || location.state.pathname === "/login") {
                history.push("/home");
            } else {

                props.doShowMenu(location.state.from.startsWith("/live") === false);
                if(location.state.pathname.startsWith("/pages/") === true ||
                    location.state.pathname.startsWith("/headers/") === true ||
                    location.state.pathname.startsWith("/footers/") === true) {
                    props.doShowFooter(false);
                } else {
                    props.doShowFooter(true);
                }
                history.push(location.state.from);
            }

        } else {
            setDisabled(false);
        }
    }

    // might trigger refresh_token multiple times
    useEffect(() => {
        let temp= client.refreshSSO();;
        if(temp === undefined) {
            if(window.location.hostname !== "localhost") {
                window.location.href = process.env.REACT_APP_LSI_HOST! + "/api/sso_redirect_to_cms";
            } else {
                setVisible(true);
            }
        } else {
            temp.then(
                (axiosResponse: AxiosResponse<any>) => {
                    let responseCmd: ResponseCmd<SSOCmd> = axiosResponse.data;
                    let ssoCmd = responseCmd.response;
                    if(ssoCmd.verify) {
                        dispatch(loginAction({
                            userName: ssoCmd.name,
                            userProfileImage: ""
                        }));

                        TrackData("Login", {
                            userId: ssoCmd.email,
                            userName: ssoCmd.name,
                            role: ssoCmd.roleName,
                            orgCode: ssoCmd.orgCode
                        })
                        props.doLogin(true);
                        if(location.state === undefined || (location.state && location.pathname === "/login")) {
                            if(location.state) {
                                props.doShowMenu(location.state.from.startsWith("/live") === false);
                                history.push(location.state.from);
                            } else {
                                history.push("/home");
                            }
                        }
                    }
                    // processSSOCmd(ssoCmd);
                }
            ).catch((error) => {

            })
        }
    },[props,history,location,dispatch]);

    if(localStorage.getItem("refreshToken") !== null) {
        return <div></div>;
    }


    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        let value2: LoginState = {
           ...values
        }
        if(event.target.id === "loginInput") {
            value2.login = event.target.value;
        } else {
            value2.password = event.target.value;
        }
        setValues(value2);
        event.preventDefault();
    }


    const handleClickShowPassword = ( e: React.MouseEvent<HTMLAnchorElement>) => {
        setValues({ ...values, showPassword: !values.showPassword });
    };


    const doSignIn = () => {
        setDisabled(true);

        client.sso(values.login, values.password).then((axiosResponse) => {
            let responseCmd:ResponseCmd<SSOCmd> = axiosResponse.data;
            let ssoCmd = responseCmd.response;
            processSSOCmd(ssoCmd);
        }).catch(() => {
        }).then(()=> {
            // setDisabled(false);
        })

    }
    const handleSignin = async (event: React.MouseEvent<HTMLButtonElement>) => {
        doSignIn();
    }


    const handleEnter = (event:React.KeyboardEvent) => {

        if(event.key === "Enter" && !disabled) {
            doSignIn();
        }
    }
    if(visible === false) return <></>
    return <div className={styles.LoginBackground}>
        <div className={styles.LoginPanel}>
            <img src={logo} className={styles.CompanyLogo} alt="logo"/>
            <h4>Sign in with your ID</h4>
            <fieldset className={styles.LoginSubPanel}>
                <legend className={styles.LoginSubPanelLegend}>
                    {new Date().getHours() > 12 ? "Good Afternoon" : "Good Morning"}
                </legend>
                <form autoComplete="off" noValidate>
                    <StyledTextField
                        required
                        label="ID"
                        id="loginInput"
                        value={values.login}
                        onChange={handleInputChange}
                        variant="outlined"
                        fullWidth={true}
                        color="primary"
                        disabled={disabled}
                        onKeyDown={handleEnter}
                    />
                    <StyledTextField
                        required
                        type={values.showPassword ? "text" : "password"}
                        label="Password"
                        id="passwordInput"
                        autoComplete="on"
                        value={values.password}
                        onChange={handleInputChange}
                        variant="outlined"
                        fullWidth={true}
                        color="primary"
                        disabled={disabled}
                        onKeyDown={handleEnter}
                    ></StyledTextField>
                    <span className={styles.ViewIcon}
                          id={"password_visibility"}
                          data-action={values.showPassword ? "doHidePassword" : "doShowPassword"}
                          onClick={handleClickShowPassword}>
                        {values.showPassword === false&& <SolidIcon>visibility</SolidIcon>}
                        {values.showPassword && <SolidIcon>visibility_off</SolidIcon>}
                    </span>
                    <SolidLinkButton id="loginButton" data-action="doLogin" disabled={disabled} fullWidth={true} style={{marginTop: "0.5rem"}} onClick={handleSignin}>Sign in</SolidLinkButton>
                    {/*<LinkButton disabled={disabled} style={{marginTop: "0.5rem"}}>Forgot Password</LinkButton>*/}
                </form>
            </fieldset>
        </div>
    </div>;
}