import styles from "./TinyField.module.scss"
import {useContext, useRef, useState} from "react";
import {Dialog, DialogContent, DialogTitle, IconButton} from "@mui/material";
import OutlinedIcon from "../icon/OutlinedIcon";
import TinyEditor from "./TinyEditor";
import MonacoEditor from "./MonacoEditor";
import {useSSO} from "../../datafetcher/HttpClient";
import {GetPortalHostName} from "../popover/PortalEnvironmentPopover";
import {nanoid} from "nanoid";
import VariableContext from "../../context/VariableContext";

export default function TinyField(props) {
    const [opened, setOpened] = useState({active: false, html: false});
    const [html, setHTML] = useState(props.value ? props.value : "");
    const siteCode = props.siteCode;
    const portalThemeName = props.portalThemeName;
    const ssoCmd = useSSO();
    const textRef = useRef(null);
    const variableContext = useContext(VariableContext);
    return <div className={styles.Root} onDoubleClick={(event) => {
        if(event.altKey) {
            if(ssoCmd.roleName === "LSIADMIN") {
                setOpened({active: true, html: true})
            } else {
                setOpened({active: true, html: false})
            }
        } else {
            setOpened({active: true, html: false})
        }
    }}>
        <div className={styles.OverFlowText}>Double-Click to edit</div>
        <div ref={textRef} dangerouslySetInnerHTML={{__html: html}}>
        </div>

        <Dialog open={opened.active}
                onClose={() => {
                    setHTML(textRef.current.innerHTML);
                    if(props.onChange) {
                        props.onChange(textRef.current.innerHTML);
                    }
                    setOpened({active: false, html: false})}
                }
                PaperProps={{ sx: { width: "200%"} }}
                disableEnforceFocus={true}
        >
            <DialogTitle>
                {opened.html && "Detail editor"}
                {opened.html === false && "Editor"}
                <IconButton className={styles.CloseIcon}
                            onClick={() => {
                                setHTML(textRef.current.innerHTML);
                                if(props.onChange) {
                                    props.onChange(textRef.current.innerHTML);
                                }
                                setOpened({active: false, html: false})
                            }}>
                    <OutlinedIcon>close</OutlinedIcon>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {opened.html && <MonacoEditor language={"html"}
                                              defaultValue={html}
                                              onChange={(value) => {
                                                  textRef.current.innerHTML = value;
                                              }
                                              }
                />}
                {opened.html === false &&<TinyEditor initialValue={html}
                            onEditorChange={(value, editor) => {
                                // widgetData.widgetProps.html = value;
                                textRef.current.innerHTML = value;

                            }}
                            init={{
                                height: 500,
                                content_style: props.content_style ? props.content_style : "",
                                content_css: [
                                    `https://${GetPortalHostName(siteCode)}/themes/${portalThemeName}/css/main.css?name=${nanoid()}`,
                                    `https://${GetPortalHostName(siteCode)}/themes/${portalThemeName}/css/responsive.css?name=${nanoid()}`,
                                ],
                                init_instance_callback: (editor) => {
                                    editor.focus();
                                    // props.onHandleUpdate(widgetData.widgetId);
                                }}}
                />}
            </DialogContent>
        </Dialog>
    </div>
}
