import React from 'react';

export interface UploadProgress {
    title:string;
    loaded: number;
    total: number;
}

export interface UploadContextInterface {
    uploadList: {[key: string] : UploadProgress}
    appendUpload: (id:string, title:string) => ((progressEvent: ProgressEvent) => void) | undefined
}

const UploadContext = React.createContext<UploadContextInterface>({
    uploadList: {},
    appendUpload: (id:string, title:string) => {
        return undefined;
    }
});



export default UploadContext;