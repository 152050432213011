import {useAppSelector} from "../../../../redux/hook";
import {selectViewState} from "../../../../redux/features/view/viewSlice";
import EditablePanel from "../../shared/editable/EditablePanel";
import {DeleteType} from "../../../model/DeleteOption";
import {SettingGroupType, SettingOption, SettingType} from "../../../model/SettingOption";
import React, {useState} from "react";
import {StyleUtil} from "../../setting/StyleUtil";
import {WidgetInterface} from "../../../model/WidgetInterface";
import {lorem} from "../../shared/lorem/lorem";
import styles from "./Jumboton.module.scss";
import {nanoid} from "nanoid";
import useWidgetState from "../../../../hook/useWidgetState";

export default function Jumbotron(props: any) {
    const widgetData:WidgetInterface = props.data as WidgetInterface;
    const {widgetId,widgetProps} = widgetData;
    const viewState = useAppSelector(selectViewState);
    const [refreshId,setRefreshId] = useState(nanoid());
    useWidgetState(widgetId,(event:CustomEvent) => {
        setRefreshId(nanoid());
    });


    if(!widgetProps['content']) {
        widgetProps['content'] = {
            image_url: "",
            title: lorem.sentences(1),
            desc: lorem.paragraphs(3)
        }
    }
    //if(!widgetProps['panel']) {
    if(StyleUtil.isDefaultStyle(widgetProps['bt_jumbotron_panel'])) {
        widgetProps['bt_jumbotron_panel'] = {
            style: {
                default: true,
                flex: {
                    'justify-content': 'center',
                    'align-items':'center'
                },
                background: {
                    'background-color': 'gray'
                },
                position: {
                    display: "flex",
                    'flex-wrap':'wrap',
                    'background-size': 'cover'
                },
                dimension: {
                    'width': "100%",
                    'height': "300px"
                },
            }
        };
    }

    //if(!widgetProps['title']) {
    if(StyleUtil.isDefaultStyle(widgetProps['j_title'])) {
        widgetProps['j_title'] = {
            style: {
                default: true,
                align: {
                    "text-align": "center"
                },
                margin: {
                    'margin-inline-start': 'auto',
                    'margin-inline-end': 'auto',
                },
                dimension: {
                    "width": "80%",
                    "min-width": "80%"
                },
                typography: {
                    'color': 'white',
                    'font-weight' : "bold",
                    'font-size': "2rem"
                },
                padding: {
                    'padding-block-start': "0.5rem",
                    'padding-block-end': "0.5rem",
                    'padding-inline-start' : '0.3rem',
                    'padding-inline-end' : '0.3rem',
                }
            }
        };
    }

    //if(!widgetProps['desc']) {
    if(StyleUtil.isDefaultStyle(widgetProps['j_desc'])) {
        widgetProps['j_desc'] = {
            style: {
                default: true,
                position: {
                    display: "block"
                },
                margin: {
                    "margin-inline-start": "auto",
                    "margin-inline-end":"auto"
                },
                dimension: {
                    "width": "80%",
                    "min-width": "80%"
                },
                typography: {
                    'color': 'white',
                    'font-size': "1.2rem"
                },
                padding: {
                    'padding-block-start': "0.5rem",
                    'padding-block-end': "0.5rem",
                    'padding-inline-start' : '0.3rem',
                    'padding-inline-end' : '0.3rem',
                }

            }
        };
    }

    const themeName = "jumbotron";
    const widgetStyles = {
        theme: StyleUtil.toThemeNameByType(widgetProps, themeName),
        current: StyleUtil.toClassFromWidget(widgetId , widgetProps, "current_style"),
        panel: StyleUtil.toClassFromWidget(widgetId , widgetProps, "bt_jumbotron_panel"),
        panel_background: StyleUtil.toClassFromMap(widgetId, "panel_background", {"background-image":`url(${widgetProps['content']['image_url']})`}),
        title: StyleUtil.toClassFromWidget(widgetId , widgetProps, "j_title"),
        desc: StyleUtil.toClassFromWidget(widgetId , widgetProps, "j_desc"),
    }

    const createJumboton = () => {
        let title = widgetProps['content']['title'];
        let desc = widgetProps['content']['desc'];
        return <div key={refreshId} className={StyleUtil.combineClasses(styles.Container,widgetStyles.theme,widgetStyles.current,widgetStyles.panel,widgetStyles.panel_background)}
        >
            <div style={{width: "100%"}}>
                { title && title !== "" && <div className={StyleUtil.combineClasses("j_title", widgetStyles.title)}>{title}</div>}
                { desc && desc !== "" && <div className={StyleUtil.combineClasses("j_desc",widgetStyles.desc)}>{desc}</div>}
            </div>
        </div>
    }

    if(viewState.isEdit) {
        let deleteOption = {type: DeleteType.delete_with_rearrange, tag: null};
        const suggestTags: string[] = [
            "current",
            "current .j_title",
            "current .j_desc",
        ]
        let settingOptions:SettingOption[] = [
            { title: "", type: SettingType.widget,  editor: "jumbotronsetting" , group: "content", groupType: SettingGroupType.layout},
            { type: SettingType.dimension, group: "bt_jumbotron_panel", groupType: SettingGroupType.style},
            { type: SettingType.background, group: "bt_jumbotron_anel", groupType: SettingGroupType.style},
            { type: SettingType.advance_background, group: "bt_jumbotron_panel", groupType: SettingGroupType.style},
            { type: SettingType.border, group: "bt_jumbotron_panel", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "bt_jumbotron_panel", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "bt_jumbotron_panel", groupType: SettingGroupType.advanced},
            { type: SettingType.dimension, group: "j_title", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "j_title", groupType: SettingGroupType.style},
            { type: SettingType.typography, group: "j_title", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "j_title", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "j_title", groupType: SettingGroupType.advanced},

            { type: SettingType.dimension, group: "j_desc", groupType: SettingGroupType.style},
            { type: SettingType.typography, group: "j_desc", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "j_desc", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "j_desc", groupType: SettingGroupType.advanced},
        ];

        return <EditablePanel className={styles.Root} {...props}
                              themeName={themeName}
                              suggestTags={suggestTags}
                              settingOptions={settingOptions}
                              settingGroups={[
                                  {group: "content", groupName: "Content", groupType: SettingGroupType.layout},
                                  {group: "bt_jumbotron_panel", groupName: "Panel", groupType: SettingGroupType.style},
                                  {group: "j_title", groupName: "Title", groupType: SettingGroupType.style},
                                  {group: "j_desc", groupName: "Description", groupType: SettingGroupType.style},
                              ]
                              }
                              deleteOption={deleteOption}>
            {createJumboton()}
        </EditablePanel>
    } else {
        return createJumboton();
    }
}