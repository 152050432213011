import {Grid, TextField} from "@mui/material";
import React, {useState} from "react";

export interface KeyValuePanelProps {
    id: string,
    defaultValue: any,
    onChange: (seo:any) => void,
}

export default function KeyValuePanel(props: KeyValuePanelProps) {
    const [value, setValue] = useState(props.defaultValue);
    const [newKey, setNewKey] = useState("");
    const [newValue, setNewValue] = useState("");
    const {id} = props;

    const handleNewKey = (event:React.ChangeEvent<HTMLInputElement>) => {
        setNewKey(event.target.value.trim());
    }

    const handleNewValue = (event:React.ChangeEvent<HTMLInputElement>) => {
        setNewValue(event.target.value.trim());
    }

    function collectSEOData() {
        let array = document.querySelectorAll(`input[id^='${id}_seo_']`);
        let count = array.length / 2;
        let rValue: any = {}
        for (let i = 0; i < count; i++) {
            let key = (array[2 * i] as HTMLInputElement).value.trim();
            let value = (array[2 * i + 1] as HTMLInputElement).value.trim();
            if (key !== "") {
                rValue[key] = value;
            }
        }
        return rValue;
    }

    const handleBlurOnNewItem = () => {
        if(newKey !== "" && newValue !== "") {
            let rValue = collectSEOData();
            rValue[newKey] = newValue;
            props.onChange(rValue);
            setValue(rValue);
            setNewKey("");
            setNewValue("");
        }
    }

    const handleBlur = (event:React.FocusEvent<HTMLInputElement>) => {
        let rValue = collectSEOData();
        props.onChange(rValue);
        setValue(rValue);
    }

    const entries = Object.entries(value);
    return <Grid container spacing={1} >
        {
            entries.map((key,index) => {
                return <React.Fragment key={"SEO_" + index}>
                <Grid item xs={4}>
                    <TextField
                        id={`${id}_seo_name_${index}`}
                        size={"small"}
                        variant={"outlined"}
                        margin="dense"
                        label={key[0]}
                        type="text"
                        defaultValue={key[0]}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        onBlur={handleBlur}
                    />
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        id={`${id}_seo_name_${index}`}
                        size={"small"}
                        variant={"outlined"}
                        margin="dense"
                        label={"Value"}
                        type="text"
                        defaultValue={key[1]}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        onBlur={handleBlur}
                    />
                </Grid>
            </React.Fragment>})
        }
            <Grid item xs={4}>
                <TextField
                    size={"small"}
                    variant={"outlined"}
                    margin="dense"
                    label={"Key"}
                    type="text"
                    value={newKey}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    onChange={handleNewKey}
                    onBlur={handleBlurOnNewItem}
                />
            </Grid>
            <Grid item xs={8}>
                <TextField
                    size={"small"}
                    variant={"outlined"}
                    margin="dense"
                    label={"Value"}
                    type="text"
                    value={newValue}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    onChange={handleNewValue}
                    onBlur={handleBlurOnNewItem}
                />
            </Grid>
        </Grid>;
}

