import styles from './Image.module.scss';
import {useAppSelector} from "../../../../redux/hook";
import {selectViewState} from "../../../../redux/features/view/viewSlice";
import EditablePanel from "../../shared/editable/EditablePanel";
import {DeleteType} from "../../../model/DeleteOption";
import {SettingGroupType, SettingOption, SettingType} from "../../../model/SettingOption";
import React, {useContext, useState} from "react";
import {StyleUtil} from "../../setting/StyleUtil";
import {WidgetInterface} from "../../../model/WidgetInterface";
import {nanoid} from "nanoid";
import VariableContext from "../../../../context/VariableContext";
import useWidgetState from "../../../../hook/useWidgetState";

export default function Image(props: any) {

    const widgetData:WidgetInterface = props.data as WidgetInterface;
    const {widgetId,widgetProps} = widgetData;
    const variableContext = useContext(VariableContext);
    const viewState = useAppSelector(selectViewState);
    const [refreshId,setRefreshId] = useState(nanoid());
    useWidgetState(widgetId,(event:CustomEvent) => {
        setRefreshId(nanoid());
    });


    if(!widgetProps['content']) {
        widgetProps['content'] = {
            src_url: "https://demo.silvereclipse.net/images/content/slide_001.jpg"
        }
    }

    if(StyleUtil.isDefaultStyle(widgetProps['bt_image'])) {
        widgetProps['bt_image'] = {
            style: {
                default: true,
                dimension: {
                    width: "100%",
                    height: "auto",
                },
                margin: {
                    'margin-inline-start': 'auto',
                    'margin-inline-end': 'auto',
                }
            }
        };
    }

    const themeName = "image";

    const widgetStyle = {
        theme: StyleUtil.toThemeNameByType(widgetProps, themeName),
        current: StyleUtil.toClassFromWidget(widgetId, widgetProps, "current_style"),
        image: StyleUtil.toClassFromWidget(widgetId , widgetProps, "bt_image")
    }

    const createImage = () => {
        let src = variableContext.updateVariables(widgetProps["content"]['src_url']);
        let href = variableContext.updateVariables(widgetProps['content']['href']);
        let openLink = widgetProps['content']['openLink'];
        if(!openLink) {
            openLink = "_self";
        }

        if(href && href !== "" && viewState.isEdit === false) {
            return <div className={styles.Root}>
                <a href={href} target={openLink}><img key={refreshId}
                                                     src={`${src}`}
                                                     className={StyleUtil.combineClasses(widgetStyle.theme, widgetStyle.current, widgetStyle.image)}
            /></a></div>;
        } else {
            return <div className={styles.Root}><img key={refreshId}
                                                     src={`${src}`}
                                                     className={StyleUtil.combineClasses(widgetStyle.theme, widgetStyle.current, widgetStyle.image)}
            /></div>;
        }

    }

    if(viewState.isEdit) {
        let deleteOption = {type: DeleteType.delete_with_rearrange, tag: null};
        const suggestTags: string[] = [
            "current",
        ]
        let settingOptions:SettingOption[] = [
            { title: "", type: SettingType.widget,  editor: "imagesetting" , group: "content", groupType: SettingGroupType.layout},
            { type: SettingType.dimension, group: "bt_image", groupType: SettingGroupType.style},
            { type: SettingType.object, group: "bt_image", groupType: SettingGroupType.style},
            { type: SettingType.border, group: "bt_image", groupType: SettingGroupType.style},
            { type: SettingType.border_radius, group: "bt_image", groupType: SettingGroupType.style},
            { type: SettingType.box_shadow, group: "bt_image", groupType: SettingGroupType.style},
            { type: SettingType.margin, group: "bt_image", groupType: SettingGroupType.advanced},
            { type: SettingType.padding, group: "bt_image", groupType: SettingGroupType.advanced},
        ];
        return <EditablePanel {...props}
                              themeName={themeName}
                              suggestTags={suggestTags}
                              settingOptions={settingOptions}
                              settingGroups={[
                                      {group: "content", groupName: "Content", groupType: SettingGroupType.layout},
                                      {group: "bt_image", groupName: "Style", groupType: SettingGroupType.style},
                                  ]
                              }
                              deleteOption={deleteOption}>
                    {createImage()}
                </EditablePanel>
    } else {
        return createImage();
    }
}