import EditablePageMenu from "../../../../ui/menu/EditablePageMenu";
import React, {useCallback, useEffect, useReducer, useState} from "react";
import {Box, Dialog,  IconButton} from "@mui/material";
import {nanoid} from "nanoid";
import {NewType} from "../../../model/NewOption";
import {SettingGroup, SettingOption} from "../../../model/SettingOption";
import {SettingChangeFunction} from "../../setting/SettingChange";
import {Prompt, useHistory} from "react-router";
import {useOrganization} from "../../../../hook/useOrganization";
import HttpClient, {useSSO} from "../../../../datafetcher/HttpClient";
import styles from "./CMSEditorWithFrame.module.scss";
import Icon from "@mui/material/Icon";
import ViewSetting from "../../setting/viewsetting/ViewSetting";
import {NewSetting} from "../../../../ui/setting/NewSetting";
import {SectionDesc} from "../../../model/desc/SectionDesc";
import {WidgetDesc} from "../../../model/desc/WidgetDesc";
import {
    sectionDescriptionsDataFetcher,
    widgetDescriptionsDataFetcher
} from "../../../../datafetcher/SectionAndWidgetDataFetcher";
import Loading from "../../../../ui/loading/Loading";
import StorefrontButton from "../../../../ui/popover/StorefrontButton";
import PortalEnvironmentPopover from "../../../../ui/popover/PortalEnvironmentPopover";
import TemplateSelection from "../../../../ui/templateselection/TemplateSelection";
import SectionTemplateDialog from "../../../../ui/template/SectionTemplateDialog";
import {SetLocalItem} from "../../../../util/LocalStorageUtil";
import ItemsButton from "../../../../ui/popover/ItemsButton";




export interface CMSEditorWithFrameProps {
    path: string;
    type: string;
    viewType: string,
    id: string;
    name: string;

    items: any,
    nameField: string,
}

interface DrawerState {
    drawerId: string;
    drawerType: number,
    themeName: string,
    settingObjects: {
        level: string,
        title: string,
        suggestTags:string[],
        groups: SettingGroup[],
        options: SettingOption[],
        id: string,
        data: any,
        props: any,
        x: number,
        y: number,
        onChange:SettingChangeFunction | null,
        saveState: ()=>void},
    newType: {type: NewType, uiLevelStr: string}
}

interface Action {
    type: 'settingDrawer' | 'newDrawer' | 'reset'
    themeName?: string
    data: any
}


function reducer(state:DrawerState ,action:Action): DrawerState {
    let newState:DrawerState = {...state};
    switch(action.type) {
        case 'settingDrawer' :
            newState.drawerId = nanoid();
            newState.drawerType = 1;
            newState.themeName = action.themeName!;
            newState.settingObjects = action.data;
            break;
        case 'newDrawer' :
            newState.drawerType = 2;
            newState.newType = action.data;
            break;
        case 'reset':
        default:
            newState.drawerType = 0;
            break;
    }
    return newState;
}

let currentThemeProp:any;
let themeProps:any;
const LEAVE_MESSAGE="Are you sure you want to leave this page?"

/** Maintina Drawer state and iFrame to Live Editor*/
export default function CMSEditorWithFrame(props: CMSEditorWithFrameProps) {
    const ssoCmd = useSSO();
    const history = useHistory();
    const organization = useOrganization();
    const [sectionDescriptions, setSectionDescriptions] = useState<SectionDesc[]>([]);
    const [widgetDescriptions, setWidgetDescriptions] = useState<WidgetDesc[]>([]);
    const [iframeRef, setIFrameRef] = useState<HTMLIFrameElement|null>()
    const [edit,setEdit]= useState(false);
    // const [refreshId, setRefreshId] = useState<string>(nanoid());
    const [storefronts, setStorefronts] = useState<any[]>();
    const [storefrontId, setStorefrontId] = useState<string>("");
    const [defaultSfId,setDefaultSfId] = useState<string>("");
    const [iframeSize,setIFrameSize] = useState<number[]>([-1,-1]);
    const [isLoaded, setLoaded] = useState<boolean>(false);
    const [openTemplate, setOpenTemplate] = useState<boolean>(false);



    const [templateData, setTemplateData] = useState<{
        capture: string,
        data: string,
    }>();
    const [undoInfo, setUndoInfo] = useState({
        redoLength: 0,
        undoLength: 0
    });

    useEffect(useCallback(() => {
        let func = (event:any) => {
            if(undoInfo.undoLength > 0) {
                event.returnValue = 'Cancel'
                return 'Cancel'
            }
        };
        window.addEventListener('beforeunload',func)
        return () => {
            window.removeEventListener("beforeunload",func);
        }
    },[undoInfo]))
    const [drawerState,drawerDispatch] = useReducer(reducer,{
        drawerId: nanoid(),
        drawerType: 0,
        themeName: "",
        settingObjects: {
            level: "",
            title: "",
            suggestTags: [],
            groups: [],
            options: [],
            id: "",
            x: 0,
            y: 0,
            data: {},
            props: {},
            onChange: null,
            saveState: () => {},
        },
        newType: {type: NewType.widget_or_section, uiLevelStr: ""}
    })

    const handleCLoseTemplate = () => {
        setOpenTemplate(false);
    }

    const setEditMode = (value: boolean) => {
        iframeRef?.contentWindow?.postMessage({
            action: "EDIT_MODE",
            value: value
        },"*");
        setEdit(value);
        hideDrawer();
    }

    const onCancelEdit = () => {
        iframeRef?.contentWindow?.postMessage( {
            action: "PREVIEW"
        },"*");

        // currentThemeProp = null;
        // themeProps = {}
        // setEditMode(false);

    }

    const onEdit = () => {
        currentThemeProp = null;
        themeProps = {}
        setEditMode(true);
    }

    // this is unreachable function
    const onCreateNew = () => {
        hideDrawer();
    }

    const onSave = () => {
        iframeRef?.contentWindow?.postMessage({
            action: "SAVE",
            value: true
        },"*");
        hideDrawer();
    }

    const onRedo = () => {
        iframeRef?.contentWindow?.postMessage({
            action: "REDO",
        },"*");
    }

    const onUndo = () => {
        iframeRef?.contentWindow?.postMessage({
            action: "UNDO",
        },"*");
    }

    const onAddTemplate = (data : string) => {
        iframeRef?.contentWindow?.postMessage({
            action: "ADD_TEMPLATE",
            value: data
        },"*");
    }

    const hideDrawer = () => {
        drawerDispatch({type: 'reset' , data: null});
        iframeRef?.contentWindow?.postMessage({
            action: "DRAWER_STATE",
            value: false
        },"*");
    }

    const onClickCloseDrawer = () => {
        hideDrawer();
    }

    const handleLoadIframe = () => {
        setTimeout(() => {
            setEditMode(edit);
        },1000);

    }

    const createPageSettingMenu = () => {
        return <></>
        // return <TopIconButton tooltipTitle={"Page Properties"} tooltipPlacement={"top"}>
        //         <OutlinedIcon>settings</OutlinedIcon>
        //     </TopIconButton>
    }


    const createEnvironmentMenu = () => {
        return <PortalEnvironmentPopover
                    tooltipPlacement={"bottom"}
                    tooltipTitle={"Change Environment"}
                    onChange={(value) => {
                            iframeRef?.contentWindow?.postMessage({
                                action: "CHANGE_ENVIRONMENT",
                            },"*");
                        }
                    }
                    />
    }

    const createItemMenu = () => {
        let selectedItem = null;
        selectedItem = props.items.find((item:any) => item["id"] === props.id);
        return <ItemsButton
            key={"items_" + props.items.length + nanoid()}
         icon={"description"}
         nameField={props.nameField}
         idField={"id"}
         items={props.items}
         selectedItem={selectedItem}
         tooltipPlacement={"bottom"}
         tooltipTitle={props.type}
         onChangeItem={(item:any) => {
                history.push(`${props.path}/${item[props.nameField]}/${item["id"]}`)
            }
         }
        />;
    }

    const createStorefrontMenu = () => {
        return <StorefrontButton
            tooltipPlacement={"left"}
            tooltipTitle={"Storefront"}
            storefronts={storefronts}
            onChangeStorefront={(storefront) => {
                hideDrawer();
                setStorefrontId(storefront.id)
                currentThemeProp = null;
                themeProps = {}
                iframeRef?.contentWindow?.postMessage({
                    action: "CHANGE_STOREFRONT",
                    value: storefront.id
                },"*");
            }}
        />
        // if(storefronts) {
        //     return <StorefrontPopover
        //                 tooltipPlacement={"right"}
        //                 tooltipTitle={"Storefront"}
        //                 storefronts={storefronts}/>
            // return <TextField
            //     select={true}
            //     size={"small"}
            //     fullWidth={true}
            //     margin={"dense"}
            //     id="select-sf"
            //     value={storefrontId}
            //     label="Site"
            //     className={styles.StorefrontCombobox}
            //     onChange={(event) => {
            //         hideDrawer();
            //         setStorefrontId(event.target.value)
            //         currentThemeProp = null;
            //         themeProps = {}
            //         iframeRef?.contentWindow?.postMessage({
            //             action: "CHANGE_STOREFRONT",
            //             value: event.target.value
            //         },"*");
            //     }}
            // >   {
            //     storefronts && storefronts.map((storefront,index) => <MenuItem key={"storefront_" + index} value={storefront.id}>{storefront.sfName}</MenuItem>)
            // }
            // </TextField>
        // } else {
        //     return <></>;
        // }
    }


    useEffect(() => {
        //if(currentOrgCode === "") {
        if(organization && organization.currentOrg && organization.orgDetail && organization.orgCmds &&  organization.currentOrg!) {

            setSectionDescriptions(sectionDescriptionsDataFetcher());
            setWidgetDescriptions(widgetDescriptionsDataFetcher());

            localStorage.setItem("_orgCmds",JSON.stringify(organization.orgCmds));
            localStorage.setItem("_orgDetail",JSON.stringify(organization.orgDetail));

            HttpClient.get("/api/v0/jcr/org/" + organization?.currentOrg!.orgCode + "/lite/storefronts").then((response) => {
                if (response.data.response !== null) {
                    setStorefronts(response.data.response);
                    setStorefrontId(response.data.response[0].id);
                    setDefaultSfId(response.data.response[0].id);
                } else {
                    setStorefronts([]);
                    setStorefrontId("quicksilver");
                    setDefaultSfId("quicksilver");

                }
            }).catch((e) => {
                setStorefronts([]);
                setStorefrontId("quicksilver");
                setDefaultSfId("quicksilver");
            })

        } else {
            if(!(organization && organization.currentOrg!)) {
                history.push(props.path);
            }
        }
    },[organization?.orgDetail])

    useEffect(() => {
        if(iframeRef) {
            let search = "";
            if (history.location.search) {
                if (history.location.search.startsWith("?")) {
                    search = history.location.search.substring(1);
                } else {
                    search = history.location.search;
                }
                const searchString = new URLSearchParams(search);
                if (search === "" || searchString.has("view") === false || searchString.get("view") !== "true") {
                    setEditMode(false);
                }
            } else {
                setEditMode(true);
            }
        }
    },[history,iframeRef])

    useEffect(() => {
        const listener = (event:MessageEvent<{action: string, value: any}>) => {
            if (event.isTrusted) {
                let payload = event.data;
                let {action,value} = payload;
                if(action === "CREATE_TEMPLATE") {
                    setTemplateData(JSON.parse(value));
                } else if(action === "OPEN_TEMPLATE") {
                    setOpenTemplate(true)
                } else if(action === "UNDO_REDO_STATE") {
                    setUndoInfo(value);
                } else if(action === "HIDE_DRAWER") {
                    hideDrawer();
                } else if(action === "LOADED") {
                    setLoaded(true);
                } else if(action === "CHANGE_THEME_DATA") {
                    currentThemeProp = value.currentThemeProp;
                    themeProps = value.themeProps;
                } else if (action === "NEW_SECTION_OR_WIDGET") {
                    drawerDispatch({
                        type: 'newDrawer',
                        data: {
                            type: value.type,
                            uiLevelStr: value.level
                        }
                    })
                } else if(action === "SECTION_OR_WIDGET_SETTING") {
                    let autoMove = drawerState.drawerType === 0 && value.type !== 0
                    drawerDispatch({
                        type: value.type,
                        themeName: value.themeName,
                        data: {
                            title: value.data.title,
                            level: value.data.level,
                            suggestTags: value.data.suggestTags,
                            groups: value.data.groups,
                            options: value.data.options,
                            id: value.data.id,
                            data: value.data.data,
                            props: value.data.props,
                            x: value.data.x,
                            y: value.data.y,
                            onChange: null,
                            saveState: saveState,
                            portalThemeName: value.data.portalThemename,
                            siteCode: value.data.siteCode,
                        }
                    });
                    iframeRef?.contentWindow?.postMessage({
                        action: "DRAWER_STATE",
                        value: true
                    },"*");

                    if(autoMove) {
                        let frame = document.getElementById("frame")!;
                        let width = parseInt(frame.getAttribute("data-frame-width")!);
                        let height = parseInt(frame.getAttribute("data-frame-height")!);
                        if(width === -1) {
                            setTimeout(() => {
                                window.scrollTo({left: value.data.x, top: 0, behavior: "smooth"})
                            }, 400);
                        } else {
                            let shift = (window.innerWidth - width - 300)/2;
                            if(width > height) {
                                setTimeout(() => {
                                    window.scrollTo({left: shift, top: 0, behavior: "smooth"})
                                }, 400);
                            } else {
                                setTimeout(() => {
                                    window.scrollTo({left: shift, top: 0, behavior: "smooth"})
                                }, 400);
                            }
                        }
                    }
                }
            }
        }

        window.addEventListener("message", listener);
        return () => {
            window.removeEventListener("message", listener)
        }
    },[drawerDispatch,iframeRef])

    const onInsertSection = (uiLevelStr: string, desc: SectionDesc) => {
        iframeRef?.contentWindow?.postMessage({
            action: "INSERT_SECTION",
            value: {
                uiLevelStr, desc
            }
        },"*");
    }


    const onInsertWidget = (uiLevelStr: string, desc: WidgetDesc) => {
        iframeRef?.contentWindow?.postMessage({
            action: "INSERT_WIDGET",
            value: {
                uiLevelStr, desc
            }
        },"*");
    }

    const doChangeTheme = (data: string) => {
        iframeRef?.contentWindow?.postMessage({
            action: "CHANGE_THEME",
            value: {
                themeName: drawerState.themeName,
                drawerType: drawerState.drawerType,
                data: data
            }
        },"*");
    }

    const doUpdateWidget = (widgetId: string, widgetData: any , action: string|null, value: any) => {
        iframeRef?.contentWindow?.postMessage({
            action: "UPDATE_WIDGET",
            value: {
                widgetId,
                widgetData,
                action,
                value
            }
        },"*");
    }

    const saveState = () => {
        iframeRef?.contentWindow?.postMessage({
            action: "SAVE_STATE",
            value: true
        },"*");
    }

    const handleCreateSectionTemplate = () => {
        iframeRef?.contentWindow?.postMessage({
            action: "START_SAVE_TEMPLATE",
        },"*");
    }

    // console.log("drawerState",drawerState);
    return <React.Fragment>
        <Prompt when={undoInfo.undoLength > 0} message={LEAVE_MESSAGE}/>;
        {templateData && <SectionTemplateDialog active={true}
                                                allowOrgs={ssoCmd.roleName === "LSIADMIN" ? ["all_orgs"] : [ssoCmd.orgCode]}
                                                header={"Saved Template"}
                                                id={null}
                                                inserted={true}
                                                captureData={templateData.capture}
                                                viewData={templateData.data}
                                                internalComment={""}
                                                title={""}
                                                subTitle={""}
                                                logo={""}
                                                onClose={() => {setTemplateData(undefined)}}
                                                tags1={["all_concepts"]}
                                                tags2={["all_categories"]}
                                                open={true}/>}
        <Box boxShadow={8} className={styles.Drawer} style={ drawerState.drawerType !== 0 ? {left: "calc(0px + var(--bt-cms-sidebar-move-left))"} : {left: "calc(-1 * var(--bt-cms-setting-drawer-width) + var(--bt-cms-sidebar-move-left))"}}>
            <IconButton
                className={styles.DrawerCloseIcon}
                onClick={onClickCloseDrawer}
                size="large"><Icon>close</Icon></IconButton>
            {drawerState.drawerType === 1 && <ViewSetting
                                                        key={drawerState.drawerId}
                                                        title={drawerState.settingObjects.title}
                                                        suggestTags={drawerState.settingObjects.suggestTags}
                                                        settingGroups={drawerState.settingObjects.groups}
                                                        settingOptions={drawerState.settingObjects.options}
                                                        widgetId={drawerState.settingObjects.id}
                                                        widgetData={drawerState.settingObjects.data}
                                                        settingProps={drawerState.settingObjects.props}
                                                        themeName={drawerState.themeName}
                                                        themeProps={currentThemeProp ? currentThemeProp : themeProps}
                                                        onChangeSettings={drawerState.settingObjects.onChange}
                                                        // saveWidgetState={drawerState.settingObjects.saveState}
                                                        onChangeThemeProp={doChangeTheme}
                                                        onUpdateWidget={doUpdateWidget}
            />}
            {drawerState.drawerType === 2 && <NewSetting key={drawerState.drawerId} newType={drawerState.newType.type}
                                                         uiLevelStr={drawerState.newType.uiLevelStr}
                                                         sectionDescriptions={sectionDescriptions}
                                                         widgetDescriptions={widgetDescriptions}
                                                         onInsertSection={onInsertSection}
                                                         onInsertWidget={onInsertWidget} />}
        </Box>
        <div className={styles.TopMenuPanel}>
            {createStorefrontMenu()}
            {createItemMenu()}
            {createEnvironmentMenu()}
            {createPageSettingMenu()}
        </div>
        <EditablePageMenu
            // menus={createStorefrontMenu()}
            parentName={props.type}
            currentId={props.id}
            currentName={props.name}
            edit={edit}
            onChangeFit={(value) => {
                    if (value) {
                        SetLocalItem("previewWrapper", "");
                    } else {
                        SetLocalItem("previewWrapper", "wrap");
                    }
                    iframeRef?.contentWindow?.postMessage({
                        action: "WRAPPER_STATE",
                        value: value
                    },"*");
                }
            }

            onCreateSectionTemplate={handleCreateSectionTemplate}
            onChangeDisplay={(display) => {
                if(display === "desktop") {
                    setIFrameSize([-1, -1]);
                } else if(display === "tablet") {
                    setIFrameSize([1024,768]);
                } else if(display === "landscape") {
                    setIFrameSize([667,375]);
                } else {
                    setIFrameSize([375,667]);
                }
            }}
            onCancelEdit={onCancelEdit}
            onSave={onSave}
            onEdit={onEdit}
            onUndo={onUndo}
            onRedo={onRedo}
            undoInfo={undoInfo}
            onCreateNew={onCreateNew}
            style={drawerState.drawerType != 0 ? {
                marginLeft: "calc(var(--bt-cms-setting-drawer-width) + var(--bt-cms-sidebar-move-left) - 75px)"
                // width: "calc(20px + 100% - var(--bt-cms-sidebar-move-left) - (var(--bt-cms-sidebar-main-width) + var(--bt-cms-drawer-width)))"
            } : undefined}
        />
        <div id="editor_frame" className={styles.Root} style={drawerState.drawerType !== 0 ?
                        {
                            // width: "calc(100% - 301px - var(--bt-cms-sidebar-move-left))",
                            width: "calc(100% - var(--bt-cms-sidebar-move-left))",
                            marginLeft: "calc(var(--bt-cms-setting-drawer-width) + var(--bt-cms-sidebar-move-left))"
                        } :
                        {
                            width: "calc(100% - var(--bt-cms-sidebar-move-left))",
                            marginLeft: "calc(0px + var(--bt-cms-sidebar-move-left))"
                        }}>
            {
                isLoaded === false && <div key="loading" className={styles.LFrame}>
                    <Loading/>
                </div>
            }
            {storefrontId != "" && <iframe ref={(el) => setIFrameRef(el)}
                                            onLoad={handleLoadIframe}
                                            id={"frame"}
                                            src={`/live/edit/orgCode/${props.viewType}/${props.id}/${defaultSfId}`}
                                            className={styles.IFrame}
                                            data-frame-width={iframeSize[0]}
                                            data-frame-height={iframeSize[1]}
                                            style={{
                                                width: iframeSize[0] !== -1 ? `${iframeSize[0]}px` : undefined,
                                                height: iframeSize[1] !== -1 ? `${iframeSize[1]}px` : undefined,
                                                marginBlockEnd: iframeSize[1] !== -1 ? "20px" : undefined,
                                                borderBottomRightRadius: iframeSize[1] !== -1 ? "4px" : undefined,
                                                borderBottomLeftRadius: iframeSize[1] !== -1 ? "4px" : undefined,
                                                marginBlockStart: iframeSize[1] !== -1 ? "10px" : undefined,
                                                // marginInlineStart: iframeSize[0] !== -1 && drawerState.drawerType !== 0 ? "100px" : undefined,
                                            }}
            ></iframe>}
            <Dialog
                className={styles.TemplateDialog}
                fullWidth={true}
                maxWidth={"xl"}
                open={openTemplate}
                onClose={handleCLoseTemplate}
            >
                <TemplateSelection doClose={() => { setOpenTemplate(false)}}
                                   orgCode={organization?.currentOrg!.orgCode!}
                                    doSelectTemplate={ (data:string) => {
                                        setOpenTemplate(false);
                                        onAddTemplate(data);
                                    }}/>
            </Dialog>
        </div>

    </React.Fragment>
}
