import {useState} from "react";
import OutlinedIcon from "../icon/OutlinedIcon";
import TopIconButton from "../button/TopIconButton";
import {TooltipPlacement} from "../../util/ThemeUtil";
import {Box, List, ListItem, ListItemButton, Popover} from "@mui/material";
import styles from "./PortalEnvironmentPopover.module.scss"

let globalTimeout:NodeJS.Timeout|null = null;
export interface PortalEnvironmentProps {
    tooltipPlacement: TooltipPlacement;
    tooltipTitle: string
    onChange?: (newValue:string) => void,
    regularIcon?: boolean
}

export interface AssetFieldStorage {
    orgCode: string,
    siteCode: string,
    env: string,
    path: string
    cdnPath: string,
}

let globalPortalEnvironment:string = "";
let globalAssetField : AssetFieldStorage = {
    orgCode: "",
    siteCode: "",
    env: "dev",
    path: "",
    cdnPath: ""
}

export const GetPortalEnvironment = () => {
    if(globalPortalEnvironment === "") {
        let env = localStorage.getItem("portalEnvironment");
        if (env === null) globalPortalEnvironment = "dev";
        else globalPortalEnvironment = env;
    }
    return globalPortalEnvironment
}

export const GetAssetFieldStorage = () => {
    if(globalPortalEnvironment === "") {
        let data = localStorage.getItem("assetFieldStorage");
        if (data === null) globalAssetField = { orgCode: "", siteCode : "" , env: "dev" , path: "/", cdnPath: "/"};
        else globalAssetField = JSON.parse(data);
    }
    return globalAssetField
}

export const SetAssetFieldStorage = (storge: AssetFieldStorage) => {
    localStorage.setItem("assetFieldStorage",JSON.stringify(storge));
    globalAssetField = {...storge}
}


export const GetPortalHostName = (sitecode: string) => {
    let portalEnvironment = GetPortalEnvironment();
    let hostName = null;
    if(portalEnvironment === "dev") {
        hostName = `${sitecode}.silvereclipse.net`;
    } else if(portalEnvironment === "prod") {
        hostName = `${sitecode}.secondphaselive.net`
    } else {
        hostName = `${sitecode}-staging.secondphaselive.net`
    }
    return hostName;
}


export const GetPortalDAMEnvironment = () => {
    let env = GetPortalEnvironment();

    if(env === "dev") {
        return "development"
    } else if(env === "prod") {
        return "production"
    } else {
        return "staging"
    }
}

export const ReloadPortalEnvironment = () => {
    globalPortalEnvironment = "";
    GetPortalEnvironment();
}

export const SetPortalEnvironment = (env: string) => {
    globalPortalEnvironment = env;
    localStorage.setItem("portalEnvironment",env);
}

const portalEnvironments = ["dev","staging","prod"]
export default function PortalEnvironmentPopover(props: PortalEnvironmentProps) {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [selectedEnv, setSelectedEnv] = useState<string>(GetPortalEnvironment());
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
        event.preventDefault();
    }

    const handleClickOnListItem = (value: any) => {

        if(globalTimeout !== null) {
            clearTimeout(globalTimeout);
            globalTimeout = null;
        }
        SetPortalEnvironment(value);

        setSelectedEnv(value);
        if(props.onChange) {
            props.onChange(value);
        }
    }
    const handleBlur = (event: React.FocusEvent) => {
        setAnchorEl(null)
        event.stopPropagation();
        event.preventDefault();
    }

    const open = Boolean(anchorEl);
    return <>
        <TopIconButton tooltipTitle={props.tooltipTitle} tooltipPlacement={props.tooltipPlacement}
                       onClick={handleClick}
                       regularIcon={props.regularIcon}
                       style={{pointerEvents: open ? "none" : "auto"}}>
            <OutlinedIcon>api</OutlinedIcon>
            {/*<span className={styles.Label}>{selectedEnv}</span>*/}
        </TopIconButton>
        <Popover
            tabIndex={-1}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center"
            }}
            style={{pointerEvents: "none"}}
        >
            <Box sx={{boxShadow: 3, padding: 0, maxWidth: "250px"}} style={{pointerEvents: "auto" , padding: "0px"}}>
                <List dense sx={{maxHeight: "250px",
                    overflowY: "auto"}}>
                    {
                        portalEnvironments.map((env,index) => {
                                let selected = false;
                                if(selectedEnv === null && index === 0) {
                                    selected = true;
                                } else {
                                    if(selectedEnv !== null) {
                                        selected = env === selectedEnv;
                                    }
                                }
                                return <ListItem autoFocus={index === 0}
                                                 disablePadding
                                                 tabIndex={-1}
                                                 onBlur={handleBlur}
                                                 key={"current_value_" + index}
                                                 value={selectedEnv}
                                                 onClick={() => handleClickOnListItem(env)}
                                ><ListItemButton selected={selected} sx={ selected ? { color: "var(--standard-jade-600)" , fontWeight: "bold"} : undefined}>{env}</ListItemButton></ListItem>
                            }
                        )
                    }
                </List>
            </Box>
        </Popover>
    </>
}