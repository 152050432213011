import makeStyles from '@mui/styles/makeStyles';
import {useEffect} from "react";
import {VariableProvider} from "../../../../context/VariableProvider";


export function LightenDarkenColor(col:string, amt:number) {

    let usePound = false;

    if (col[0] === "#") {
        col = col.slice(1);
        usePound = true;
    }

    let num = parseInt(col,16);

    let r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if  (r < 0) r = 0;

    let b = ((num >> 8) & 0x00FF) + amt;

    if (b > 255) b = 255;
    else if  (b < 0) b = 0;

    let g = (num & 0x0000FF) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound?"#":"") + ("000000" + (g | (b << 8) | (r << 16)).toString(16)).slice(-6);

}

export function removeCMSTheme() {
    document.querySelectorAll(`style[data-meta="CMSTheme"]`).forEach(element => element.remove());
    document.querySelectorAll(`style[id="btcms-global"]`).forEach(element => element.remove());
}

function addCSSVariable(themeProps:any,inputName: string, outputName: string):string {
    if(themeProps && outputName && inputName && inputName in themeProps && outputName) {
        return "\n" + outputName + ": " + themeProps[inputName] + ";";
    } else {
        return "";
    }
}

function UpdateWindowHeight() {
    let cmsVariable = "btcms-variables";

    // Remove old styles sheets and replace with current one
    document.querySelectorAll(`style[id="${cmsVariable}"]`).forEach(element => element.remove());
    let sElement = document.createElement("style");
    sElement.id = cmsVariable;
    document.head.append(sElement);
    sElement.textContent = `:root {
                --bt-global-frame-height: ${window.innerHeight}px;
                --bt-global-frame-width: ${window.innerWidth}px;
            }
        `
}

function addLink(themeProps:any, fontURL: string) {
    if(themeProps[fontURL]) {
        let url = themeProps[fontURL];
        let nodeList = document.querySelectorAll(`link[href="${url}"]`);
        if (nodeList.length === 0) {
            let newLink = document.createElement("link");
            newLink.rel = "stylesheet";
            newLink.href = url;
            document.head.append(newLink);
        }
    }
}

export function LoadCMSTheme(themeProps:any) {

    let cmsGlobal = "btcms-global";

    // Remove old styles sheets and replace with current one
    document.querySelectorAll(`style[id="${cmsGlobal}"]`).forEach(element => element.remove());
    let sElement = document.createElement("style");
    sElement.id = cmsGlobal;
    document.head.append(sElement);


    let buffer = '';

    if(themeProps !== undefined && themeProps !== null) {

        addLink(themeProps,"font-url-1");
        addLink(themeProps,"font-url-2");
        addLink(themeProps,"font-url-3");
        addLink(themeProps,"font-url-4");

        buffer += addCSSVariable(themeProps,'background-color','--btcms-background-color');
        buffer += addCSSVariable(themeProps,'primary-color','--btcms-primary-color');
        buffer += addCSSVariable(themeProps,'secondary-color','--btcms-secondary-color');
        buffer += addCSSVariable(themeProps,'font-family','--btcms-font-family');
        buffer += addCSSVariable(themeProps,'panel-primary-color','--btcms-panel-primary-color');
        buffer += addCSSVariable(themeProps,'panel-secondary-color','--btcms-panel-secondary-color');

        let variable:{[key:string]: any} = themeProps['variable'];
        if(variable) {
            for(const [key,value] of Object.entries(variable)) {
                if(key.startsWith("--")) {
                    buffer += "\n" + key + ": " + value + ";";
                } else if(key.startsWith("-")) {
                    buffer += "\n-" + key + ": " + value + ";";
                } else {
                    buffer += "\n--" + key + ": " + value + ";";
                }
            }
        }
        buffer = `:root {
                ${buffer}
            }
        `

        if(themeProps['background-color']) {
            buffer += `body { background-color: var(--btcms-background-color); }`
        }
        if(themeProps['primary-color']) {
            buffer += `body { color: var(--btcms-primary-color); }`
        }
        if(themeProps['font-family']) {
            buffer += `body { font-family: var(--btcms-font-family); }`
        }


        if(themeProps['body']) {
            buffer += themeProps['body'];
        }


        let types = ['h1',
            'h2',
            'h3',
            'h4',
            'h5',
            'h6',
            'a',
            'btn',
            'input',
            'wrapper',
            'other']; // see ThemeEditor
        types.forEach(type => {
            if(themeProps[type]) {
                buffer += themeProps[type] + "\n";
            }
        });

        // when check this, please change these on ThemeEditor also
        let themes = [
            'header',
            'footer',
            'text',
            'image',
            'card',
            'suggestion',
            'jumbotron',
            'image_list',
            'product_list',
            'product_drilldown',
            'product_query',
            'product_detail',
            'product_query',
            'plugin',
            'vertical',
            'horizontal',
            'two_columns',
            'three_columns'
        ]


        themes.forEach(type => {
            if(themeProps[type]) {
                let value = themeProps[type];
                if(value !== null) {
                    buffer += value.replaceAll("current", ".btcms_theme_" + type);
                    buffer += "\n";
                }
            }
        });

        sElement.textContent = buffer;

    }
}

export default function CMSTheme(props: {children: any, themeProps:any, websiteURL: string, cdnURL: string, siteCode: string, portalThemeName: string}) {

    LoadCMSTheme(props.themeProps);

    let themeName = "CMSTheme";
    document.querySelectorAll(`style[data-meta="${themeName}"]`).forEach(element => element.remove());

    let primaryColor = "var(--standard-jade-500)";
    let secondaryColor = "var(--standard-jade-700)";
    let darkerAmount = -20;
    let lighterAmount = 20;

    const useStyles = makeStyles(theme => ({
        '@global': {
            ':root': {
                '--bc-background-color': 'white',
                '--bc-primary-color': primaryColor,
                '--bc-primary-color-lighter': LightenDarkenColor(primaryColor,lighterAmount),
                '--bc-primary-color-darker': LightenDarkenColor(primaryColor,darkerAmount),

                '--bc-secondary-color': secondaryColor,
                '--bc-secondary-color-lighter': LightenDarkenColor(secondaryColor,lighterAmount),
                '--bc-secondary-color-darker': LightenDarkenColor(secondaryColor,darkerAmount),

                '--bc-danger-color': 'red',//theme.palette.primary.main,
                '--bc-danger-color-lighter': LightenDarkenColor(secondaryColor,lighterAmount),
                '--bc-danger-color-darker': LightenDarkenColor(secondaryColor,darkerAmount),

                '--bc-editor-border-width' : "1px",
                '--bc-editor-border-width-2x' : "3px",
                '--bc-editor-border-width-rearrange' : "3px",
                '--bc-editor-border-color' : 'var(--standard-coal-200)',
                '--bc-editor-section-border-color' : 'var(--standard-coal-200)',
                '--bc-editor-border-focus-color': 'var(--standard-jade-600)',
                '--bc-editor-section-border-focus-color': 'var(--standard-jade-600)',
                '--bc-editor-column-border-focus-color': '#9614E1',
                '--bc-editor-widget-border-focus-color': '#006BE6',
                '--bc-editor-widget-sub-section-focus-color': '#9614E1',
                '--bc-editor-border-drag-to-move-color': 'blue',
                '--bc-editor-border--drag-to-rearrange-color': 'red',
                '--bc-editor-border--drag-to-swap-color': 'green',
            }
        }
    }),{ name: themeName });

    useStyles();

    useEffect(() => {
        let listener = (event:any) => {
            UpdateWindowHeight();
        }
        UpdateWindowHeight();
        window.addEventListener("resize", listener);
        return () => window.removeEventListener("resize",listener);
    })

    return <VariableProvider websiteURL={props.websiteURL}
                             cdnURL={props.cdnURL}
                             siteCode={props.siteCode}
                             portalThemeName={props.portalThemeName}
    >
        <div>
            {props.children}
        </div>
    </VariableProvider>;
}

