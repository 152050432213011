import {Grid, Icon, IconButton, TextField} from "@mui/material";
import styles from "./MenuSetting.module.scss";
import React, {useState} from "react";
import CMSMenuItem from "./CMSMenuItem";

export default function MenuSetting(props: {initMenu:CMSMenuItem[],
                                            onChange: (newMenu: CMSMenuItem[]) => void}) {
    const [subMenu, setSubMenu] = useState<CMSMenuItem[]>(props.initMenu);

    const handleMenuChange = (index: number, subIndex: number , type: string , value: string) => {
        let newSubMenu = [...subMenu];
        if(subIndex === -1) {
            if (type === "link") {
                newSubMenu[index].link = value;
            } else {
                newSubMenu[index].label = value;
            }
        } else {
            if(type === "link") {
                newSubMenu[index].menuItems[subIndex].link = value;
            } else {
                newSubMenu[index].menuItems[subIndex].label = value;
            }
        }

        // calculate real menu
        let realMenus:CMSMenuItem[] = [];
        newSubMenu.forEach(menu => {
            let realMenu:CMSMenuItem = {label: menu.label, link: menu.link , menuItems: []};
            if(realMenu.link.trim() !== "" || realMenu.label.trim() !== "") {
                realMenus.push(realMenu);
                menu.menuItems?.forEach(subMenu => {
                    if(subMenu.link.trim() !== "" || subMenu.link.trim() !== "") {
                        realMenu.menuItems.push({label: subMenu.label, link: subMenu.link, menuItems: []});
                    }
                });
            }
        })

        props.onChange(realMenus);
        setSubMenu(newSubMenu);

    }


    return <>
        <span style={{lineHeight: "2rem"}}>Menus</span> <IconButton className={styles.AddMenuIcon} size={"small"} onClick={() => {
            let newSubMenu = [...subMenu];
            newSubMenu.push({label: "", link: "", menuItems:[]});
            setSubMenu(newSubMenu);
        }
        }><Icon>add</Icon></IconButton>
            {
                subMenu.map((menu, index) => <Grid key={"menu_setting_" +index} container >
                    <Grid  key={'menu_setting_name_' + index} item xs={1}>{index+1}</Grid>
                    <Grid  key={'menu_setting_detail_' + index} item xs={11}>
                        <div className={styles.MenuPanel}><TextField size="small" fullWidth={true} defaultValue={menu.label} label={"label"} onChange={(event) => {handleMenuChange(index,-1,"label",event.target.value)}}></TextField></div>
                        <div className={styles.MenuPanel}><TextField size="small" fullWidth={true} defaultValue={menu.link} label={"url"} onChange={(event) => {handleMenuChange(index,-1,"link",event.target.value)}}></TextField></div>
                        <IconButton className={styles.AddMenuIcon} size={"small"} onClick={() => {
                            let newSubMenu = [...subMenu];
                            newSubMenu[index].menuItems.push({label: "", link: "", menuItems:[]});
                            setSubMenu(newSubMenu);
                        }
                        }><Icon>add</Icon></IconButton><span style={{float: "right",lineHeight: "2rem"}}>sub-menus</span>
                        {
                            menu.menuItems &&  menu.menuItems.map((subMenu, subIndex) => <Grid key={"menu_setting_" + index + "_" + subIndex} container>
                                <Grid  key={"menu_setting_name_" + index + "_" + subIndex} item xs={2}>{index+1}.{subIndex+1}</Grid>
                                <Grid  key={"menu_setting_detail_" + index + "_" + subIndex} item xs={10}>
                                    <div className={styles.MenuPanel}><TextField size="small" fullWidth={true} defaultValue={subMenu.label} label={"label"} onChange={(event) => {handleMenuChange(index,subIndex,"label",event.target.value)}}></TextField></div>
                                    <div className={styles.MenuPanel}><TextField size="small" fullWidth={true} defaultValue={subMenu.link} label={"url"} onChange={(event) => {handleMenuChange(index,subIndex,"link",event.target.value)}}></TextField></div>
                                </Grid>
                            </Grid>)
                        }
                    </Grid></Grid>)
            }
    </>
}