import React, {useCallback, useEffect, useState} from "react";
import {SettingGroup, SettingGroupType, SettingOption, SettingType} from "../../../model/SettingOption";
import PaddingSetting from "../paddingsetting/PaddingSetting";
import MarginSetting from "../marginsetting/MarginSetting";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Tab,
    Tabs,
    Tooltip,
    Typography
} from "@mui/material";
import SettingPanel from "../../../../ui/panel/SettingPanel";
import {SettingChangeFunction} from "../SettingChange";
import styles from "./ViewSetting.module.scss";
import SettingProps from "../SettingProps";
import DimensionSetting from "../dimensionsetting/DimensionSetting";
import PositionSetting from "../positionsetting/PositionSetting";
import AlignSetting from "../alignsetting/AlignSetting";
import BackgroundSetting from "../backgroundsetting/BackgroundSetting";
import ObjectSetting from "../objectsetting/ObjectSetting";
import TypoGraphySetting from "../typographysetting/TypoGraphySetting";
import BorderSetting from "../bordersetting/BorderSetting";
import FlexSetting from "../flexsetting/FlexSetting";
import OutlinedIcon from "../../../../ui/icon/OutlinedIcon";
import {nanoid} from "nanoid";
import MonacoEditor, {addCSSVariables} from "../../../../ui/editor/MonacoEditor";
import useWindowSize from "../../../hook/useWindowSize";
import HorizontalSetting from "../../section/horizontal/HorizontalSetting";
import ThreeColumnsSetting from "../../section/threecolumns/ThreeColumnSetting";
import TwoColumnsSetting from "../../section/twocolumns/TwoColumnSetting";
import CardSetting from "../../widget/card/CardSetting";
import SearchSetting from "../../widget/header/SearchSetting";
import LogoSetting from "../../widget/header/LogoSetting";
import ShopcartSetting from "../../widget/header/ShopcartSetting";
import HeaderLoginSetting from "../../widget/header/HeaderLoginSetting";
import MainMenuSetting from "../../widget/header/MainMenuSetting";
import ImageSetting from "../../widget/image/ImageSetting";
import JumbotronSetting from "../../widget/jumbotron/JumbotronSetting";
import LoginSetting from "../../widget/login/LoginSetting";
import HeaderSetting from "../../widget/header/HeaderSetting";
import ImageListSetting from "../../widget/imagelist/ImageListSetting";
import ProductListSetting from "../../widget/productlist/ProductListSetting";
import PluginSetting from "../../widget/plugin/PluginSetting";
import ProductQuerySetting from "../../widget/productquery/ProductQuerySetting";
import ProductDrilldownSetting from "../../widget/productdrilldown/ProductDrilldownSetting";
import FieldGroupSetting from "../../widget/fieldgroup/FieldGroupSetting";
import AdvanceContentSetting from "../../widget/advancecontent/AdvanceContentSetting";
import DefaultSectionSetting from "../../section/defaultsection/DefaultSectionSetting";
import _ from "lodash";
import {VerticalAlignSetting} from "../verticalalignsetting/VerticalAlignSetting";
import {TextAlignSetting} from "../textalignsetting/TextAlignSetting";
import WidthSetting from "../widthsetting/WidthSetting";
import HeightSetting from "../heightsetting/HeightSetting";
import AdvanceBackgroundSetting from "../advancebackgroundsetting/AdvanceBackgroundSetting";
import BorderRadiusSetting from "../borderradiussetting/BorderRadiusSetting";
import BoxShadowSetting from "../boxshadowsetting/BoxShadowSetting";
import OutlineSetting from "../outlinesetting/OutlineSetting";
import ButtonSetting from "../../widget/button/ButtonSetting";
import {GetSessionItem, SetSessionItem} from "../../../../util/LocalStorageUtil";
import PixelDimensionSetting from "../pixeldimensionsetting/PixelDimensionSetting";
import VerticalSectionSetting from "../../section/vertical/VerticalSectionSetting";

export default function ViewSetting(props: {
                                            title: string,
                                            themeName: string,
                                            themeProps: any,
                                            suggestTags: string[],
                                            settingOptions: SettingOption[],
                                            widgetId: string,
                                            widgetData:any,
                                            settingProps: any,
                                            settingGroups: SettingGroup[],
                                            onChangeSettings: SettingChangeFunction|null,
                                            onChangeThemeProp?: (value:string) => void,
                                            onUpdateWidget: (widgetId: string, widgetData: any, action: string|null,value: any) => void, // proxy when someting change
                                            //saveWidgetState: () => void
} ) {

    const findDefaultGroups = (settingOptions: SettingOption[]) : string[] => {
        let rValues:string[] = [];
        Array.of(SettingGroupType.layout, SettingGroupType.style, SettingGroupType.advanced).forEach(
            type =>  {
                let index = settingOptions.findIndex(settingGroup => settingGroup.groupType === type)
                if(index >= 0) {
                    rValues.push(settingOptions[index].group);
                }
            }
        );
        rValues.push("editors");
        return rValues
    }

    const hasGroup = (type: SettingGroupType) => {
        return props.settingOptions.findIndex(setting => setting.groupType === type) >= 0;
    }

    const GetDefaultGroupType = () => {
        let type = parseInt(GetSessionItem("viewSettingTab",
            (props.settingOptions &&
                props.settingOptions.length > 0) ? SettingGroupType.layout.toString() : SettingGroupType.unknown.toString()))
        if(type === SettingGroupType.layout && hasGroup(SettingGroupType.layout) === false) {
            return SettingGroupType.style;
        } else {
            return type;
        }
    }
    const themeProps = props.themeProps ? props.themeProps : {};
    const windowSize = useWindowSize();
    const [refreshId,setRefreshId] = useState<string>(nanoid());
    const [currentStyle, setCurrentStyle] = useState<string>(props.settingProps['current_style'] ? props.settingProps['current_style'] : "");
    const [themeStyle, setThemeStyle] = useState<string>(themeProps[props.themeName] ? themeProps[props.themeName] : "");
    const [themeDisabled,setThemeDisabled] = useState<boolean>(props.settingProps && props.settingProps['theme_disabled'] !== undefined && props.settingProps['theme_disabled']);
    const [currentGroupType, setCurrentGroupType] = useState<SettingGroupType>(GetDefaultGroupType());
    const [currentGroups, setCurrentGroups] = useState<Set<String>>(new Set(findDefaultGroups(props.settingOptions)));
    const [customSetting, setCustomSetting] = useState({
        settingGroups: props.settingGroups,
        settingOptions: props.settingOptions,
    })

     const saveWidgetState = useCallback(() => {
         props.onUpdateWidget(props.widgetId,props.widgetData,null,null);
     },[props.widgetId,props.widgetData,props.onUpdateWidget]);

    const widgetCallback = useCallback((action: string, value: any) => {
        props.onUpdateWidget(props.widgetId,props.widgetData,action,value);
    },[props.widgetId,props.widgetData,props.onUpdateWidget]);

    const handleChange = (value: any, key: string) => {

        if(key === "current_style") {
            props.settingProps['current_style'] = value;
            setCurrentStyle(value);
        } else if(key === "theme_style") {
            if(props.onChangeThemeProp) {
                props.onChangeThemeProp(value);
            }
            setThemeStyle(value);
        } else if(key === "theme_disabled") {
            props.settingProps['theme_disabled'] = value;
            setThemeDisabled(value);
        }


        saveWidgetState();
    }

    const doSuggestTabs = (data:string):string => {
        let tags = [...props.suggestTags]
        let lines = data.split("\n");

        for(let line of lines) {
            line = line.trim();
            let index = tags.findIndex((tag) => line.startsWith(tag + " {") || line.startsWith(tag + "{") || line === tag)
            if(index >= 0) {
                tags.splice(index,1);
            }
        }
        let buffer: string[] = []
        for(const tag of tags) {
            buffer.push(`${tag} {
    
}`);
        }

        if(data.length > 0) {
            return data + "\n" + buffer.join("\n");
        } else {
            return data + buffer.join("\n");
        }

    }
    const handleSuggestTags = (key:string) => {
        if(key === "current_style") {
            let newData = doSuggestTabs(currentStyle);
            //console.log(newData);
            props.settingProps['current_style'] = newData;
            setCurrentStyle(newData);
            setRefreshId(nanoid());
        } else {
            let newData = doSuggestTabs(themeStyle);
            //console.log(newData);
            if(props.onChangeThemeProp) {
                props.onChangeThemeProp(newData);
            }
            setThemeStyle(newData);
            setRefreshId(nanoid());
        }
        saveWidgetState();
    }

    function createSetting(setting: SettingOption,index: number) {
        let settingProps: SettingProps = {
            title: setting.title,
            settingOption: setting,
            onChange :  props.onChangeSettings,
            index: index,
            widgetId: props.widgetId,
            widgetData: props.widgetData,
            settingProps: props.settingProps,
            settingOptions: customSetting.settingOptions,
            saveWidgetState: saveWidgetState,
            widgetCallback: widgetCallback,
            ...setting.additions
        }
        let editorUI = (typeof setting.editor === "string")? null : setting.editor;
        if(setting.type === SettingType.vertical_align) {
            return <VerticalAlignSetting key={`setting_${index}`} {...settingProps}/>
        } else if (setting.type === SettingType.text_align) {
            return <TextAlignSetting key={`setting_${index}`} {...settingProps}/>
        } else if (setting.type === SettingType.margin) {
            return <MarginSetting key={`setting_${index}`} {...settingProps}/>
        } else if (setting.type === SettingType.padding) {
            return <PaddingSetting key={`setting_${index}`} {...settingProps}/>
        } else if (setting.type === SettingType.position) {
            return <PositionSetting key={`setting_${index}`} {...settingProps}/>
        } else if (setting.type === SettingType.dimension) {
            return <DimensionSetting key={`setting_${index}`} {...settingProps}/>
        } else if (setting.type === SettingType.pixeldimension) {
            return <PixelDimensionSetting key={`setting_${index}`} {...settingProps}/>
        } else if (setting.type === SettingType.width) {
            return <WidthSetting key={`setting_${index}`} {...settingProps}/>
        } else if (setting.type === SettingType.height) {
            return <HeightSetting key={`setting_${index}`} {...settingProps}/>
        }  else if (setting.type === SettingType.flex) {
            return <FlexSetting key={`setting_${index}`} {...settingProps}/>
        } else if (setting.type === SettingType.align) {
            return <AlignSetting key={`setting_${index}`} {...settingProps}/>
        } else if (setting.type === SettingType.border) {
            return <BorderSetting key={`setting_${index}`} {...settingProps}/>
        } else if (setting.type === SettingType.outline) {
            return <OutlineSetting key={`setting_${index}`} {...settingProps}/>
        } else if (setting.type === SettingType.border_radius) {
            return <BorderRadiusSetting key={`setting_${index}`} {...settingProps}/>
        } else if (setting.type === SettingType.box_shadow) {
            return <BoxShadowSetting key={`setting_${index}`} {...settingProps}/>
        } else if (setting.type === SettingType.background) {
            return <BackgroundSetting key={`setting_${index}`} {...settingProps}/>
        } else if (setting.type === SettingType.advance_background) {
            return <AdvanceBackgroundSetting type="background" key={`setting_${index}`} {...settingProps}/>
        } else if (setting.type === SettingType.object) {
            return <ObjectSetting key={`setting_${index}`} {...settingProps}/>
        } else if (setting.type === SettingType.typography) {
            return <TypoGraphySetting key={`setting_${index}`} {...settingProps}/>
        } else if(setting.type === SettingType.widget && editorUI) {
            return <SettingPanel key={`setting_${index}`} title={setting.title ? setting.title : "Widget"}>
                    {   editorUI }
                </SettingPanel>
        } else if(setting.editor === "horizontalsetting") {
            return <SettingPanel key={`setting_${index}`} title={setting.title ? setting.title : "Widget"}>
                <HorizontalSetting {...settingProps}/>
            </SettingPanel>
        } else if(setting.editor === "defaultsectionsetting") {
            return <SettingPanel key={`setting_${index}`} title={setting.title ? setting.title : "Widget"}>
                <DefaultSectionSetting {...settingProps} onCustomSetting={setCustomSetting}/>
            </SettingPanel>
        } else if(setting.editor === "verticalsetting") {
            return <SettingPanel key={`setting_${index}`} title={setting.title ? setting.title : "Widget"}>
                <VerticalSectionSetting {...settingProps}/>
            </SettingPanel>
        } else if(setting.editor === "threecolumnssetting") {
            return <SettingPanel key={`setting_${index}`} title={setting.title ? setting.title : "Widget"}>
                <ThreeColumnsSetting {...settingProps}/>
            </SettingPanel>
         } else if(setting.editor === "twocolumnssetting") {
            return <SettingPanel key={`setting_${index}`} title={setting.title ? setting.title : "Widget"}>
                <TwoColumnsSetting {...settingProps}/>
            </SettingPanel>
        } else if(setting.editor === "cardsetting") {
            return <SettingPanel key={`setting_${index}`} title={setting.title ? setting.title : "Widget"}>
                <CardSetting {...settingProps}/>
            </SettingPanel>
        } else if(setting.editor === "searchsetting") {
            return <SettingPanel key={`setting_${index}`} title={setting.title ? setting.title : "Widget"}>
                <SearchSetting {...settingProps}/>
            </SettingPanel>
        } else if(setting.editor === "logosetting") {
            return <SettingPanel key={`setting_${index}`} title={setting.title ? setting.title : "Widget"}>
                <LogoSetting {...settingProps}/>
            </SettingPanel>
        } else if(setting.editor === "shopcartsetting") {
            return <SettingPanel key={`setting_${index}`} title={setting.title ? setting.title : "Widget"}>
                <ShopcartSetting {...settingProps}/>
            </SettingPanel>
        } else if(setting.editor === "headerloginsetting") {
            return <SettingPanel key={`setting_${index}`} title={setting.title ? setting.title : "Widget"}>
                <HeaderLoginSetting {...settingProps}/>
            </SettingPanel>
        } else if(setting.editor === "mainmenusetting") {
            return <SettingPanel key={`setting_${index}`} title={setting.title ? setting.title : "Widget"}>
                <MainMenuSetting {...settingProps}/>
            </SettingPanel>
        } else if(setting.editor === "imagesetting") {
            return <SettingPanel key={`setting_${index}`} title={setting.title ? setting.title : "Widget"}>
                <ImageSetting {...settingProps}/>
            </SettingPanel>
        } else if(setting.editor === "buttonsetting") {
            return <SettingPanel key={`setting_${index}`} title={setting.title ? setting.title : "Widget"}>
                <ButtonSetting {...settingProps}/>
            </SettingPanel>
        } else if(setting.editor === "jumbotronsetting") {
            return <SettingPanel key={`setting_${index}`} title={setting.title ? setting.title : "Widget"}>
                <JumbotronSetting {...settingProps}/>
            </SettingPanel>
        } else if(setting.editor === "loginsetting") {
            return <SettingPanel key={`setting_${index}`} title={setting.title ? setting.title : "Widget"}>
                <LoginSetting {...settingProps}/>
            </SettingPanel>
        } else if(setting.editor === "headersetting") {
            return <SettingPanel key={`setting_${index}`} title={setting.title ? setting.title : "Widget"}>
                <HeaderSetting {...settingProps}/>
            </SettingPanel>
        } else if(setting.editor === "imagelistsetting") {
            return <SettingPanel key={`setting_${index}`} title={setting.title ? setting.title : "Widget"}>
                <ImageListSetting {...settingProps}/>
            </SettingPanel>
        } else if(setting.editor === "productlistsetting") {
            return <SettingPanel key={`setting_${index}`} title={setting.title ? setting.title : "Widget"}>
                <ProductListSetting {...settingProps}/>
            </SettingPanel>
        } else if(setting.editor === "pluginsetting") {
            return <SettingPanel key={`setting_${index}`} title={setting.title ? setting.title : "Widget"}>
                <PluginSetting {...settingProps}/>
            </SettingPanel>
        } else if(setting.editor === "productquerysetting") {
            return <SettingPanel key={`setting_${index}`} title={setting.title ? setting.title : "Widget"}>
                <ProductQuerySetting {...settingProps}/>
            </SettingPanel>
        } else if(setting.editor === "productdrilldownsetting") {
            return <SettingPanel key={`setting_${index}`} title={setting.title ? setting.title : "Widget"}>
                <ProductDrilldownSetting {...settingProps}/>
            </SettingPanel>
        } else if(setting.editor === "fieldgroupsetting") {
            return <SettingPanel key={`setting_${index}`} title={setting.title ? setting.title : "Widget"}>
                <FieldGroupSetting {...settingProps}/>
            </SettingPanel>
        } else if(setting.editor === "advancecontentsetting") {
            return <SettingPanel key={`setting_${index}`} title={setting.title ? setting.title : "Widget"}>
                <AdvanceContentSetting {...settingProps}/>
            </SettingPanel>
        }
    }

    useEffect(() => {
        let variable:{} = themeProps && themeProps['variable'] ? themeProps['variable'] : null;
        if(variable) {
            let keys = [];
            for(let key in variable) {
                if(key.startsWith("--")) {
                    keys.push(`${key}`);
                } else if(key.startsWith("-")) {
                    keys.push(`-${key}`);
                } else {
                    keys.push(`--${key}`);
                }
            }
            keys.push("--btcms-background-color");
            keys.push("--btcms-primary-color");
            keys.push("--btcms-secondary-color");
            keys.push("--btcms-font-family");
            keys.push("--btcms-panel-primary-color");
            keys.push("--btcms-panel-secondary-color");
            addCSSVariables(keys);
        }
    },[])


    function createSuggestTag(type: string) {
        return <Tooltip title={"Suggested Tags"}>
            <IconButton onClick={() => handleSuggestTags(type)}>
                <OutlinedIcon className={styles.SuggestIcon}>assistant</OutlinedIcon>
            </IconButton>
        </Tooltip>;
    }

    const createCurrentStyle = () => {
        // console.log("currentStyle",currentStyle);
        return <>
            <div className={styles.AdvanceEditorTitle}>
                Custom CSS (Default) {createSuggestTag("current_style")}
            </div>
            <div className={styles.AdvanceEditorPanel}>
                <MonacoEditor
                    language={"css"}
                    key={"local_style_" + refreshId}
                    // className={styles.CodeParentPanel}
                    //style={{height: `calc(${windowSize.height}px - 23rem)`}}
                    style={{height: "400px"}}
                    codeClassName={styles.CodePanel}
                    title={""}
                    // title={"Current scope"}
                    //caption={"Use \"current\" to represent this element"}
                    defaultValue={currentStyle}
                    onChange={(newValue: string) => {
                        handleChange(newValue, "current_style");
                    }
                    }
                />
            </div>
            <div className={styles.AdvanceEditorSubTitle}>
                Use "current" to represent this element
            </div>
        </>
    }



    const createAccordions = (settingGroups: SettingGroup[],allSettings:SettingOption[]) => {
        return <div className={styles.AccordingPanel}>
            {
                settingGroups.map(settingGroup => {
                    let expanded = currentGroups.has(settingGroup.group)
                   return <Accordion disableGutters expanded={expanded}
                                     key={`setting_${settingGroup.group}`}
                                     onChange={(event,value) => {
                                            let set = new Set<string>();//_.clone(currentGroups);
                                            if(value) {
                                                set.add(settingGroup.group);
                                            } else {
                                                set.delete(settingGroup.group);
                                            }
                                            setCurrentGroups(set);
                                        }
                                     }>
                       <AccordionSummary className={styles.AccordingLabelPanel} expandIcon={<OutlinedIcon>expand_more</OutlinedIcon>}>
                           <span className={expanded ? styles.AccordingLabelActive : styles.AccordingLabel}>{settingGroup.groupName}</span>
                       </AccordionSummary>
                       { currentGroups.has(settingGroup.group) && <AccordionDetails className={styles.AccordingDetailPanel}>
                           {allSettings.filter(setting => setting.group === settingGroup.group).map((setting,index) => createSetting(setting,index))}
                       </AccordionDetails>}
                   </Accordion>
                })
            }
        </div>
    }

    const createThemeStyle = () => {
        // console.log(themeStyle);
        return <>
            <Grid container>
                <Grid item xs={12}>
                    <FormGroup sx={{alignItems: "start"}} className={styles.AdvanceEditorTitle}>
                        <FormControlLabel control={<Checkbox size={"small"}
                                                             checked={themeDisabled}
                                                             onChange={() => {
                                                                 let value = !themeDisabled;
                                                                 handleChange(value,"theme_disabled");
                                                             }}/>} label="Ignore style from theme" />
                    </FormGroup>
                </Grid>
                <Grid item xs={12}>
                    {themeDisabled === false && <div className={styles.AdvanceEditorTitle}>Custom CSS (Theme) {createSuggestTag("theme_style")}</div>}
                </Grid>
            </Grid>
            {themeDisabled === false && <div className={styles.AdvanceEditorPanel}><MonacoEditor
                language={"css"}
                key={"theme_style_" + refreshId}
                // className={styles.CodeParentPanelTheme}
                // style={{height: `calc(${windowSize.height}px - 23rem)`}}
                style={{height: "400px"}}
                codeClassName={styles.CodePanel}
                title={""}
                // title={"Theme scope"}
                // caption={"Use \"current\" to represent this element"}
                defaultValue={themeStyle}
                onChange={(newValue: string) => {
                    handleChange(newValue,"theme_style");
                }}/></div>
            }
            <div className={styles.AdvanceEditorSubTitle}>
                Use "current" to represent this element
            </div>
        </>
    }

    if(customSetting.settingGroups) {
        let settings:any = undefined;

        //let groups = props.settingGroups.filter(item => item.groupType === currentGroupType);
        //let allSettings = props.settingOptions.filter(item => groups.findIndex(group => group.group === item.group) >= 0);
        let allSettings = customSetting.settingOptions.filter(item => item.groupType === currentGroupType);
        let groups = customSetting.settingGroups.filter(group => allSettings.findIndex(setting => setting.group === group.group) >= 0);

        // settings = data.map((item, index) => createSetting(item, index));
        // console.log("settings",settings);
        return <>
            <div id="view_drawer" className={styles.Header}><Typography variant={"h6"}>{props.title}</Typography></div>
            <Box className={styles.SelectBox}>
                {/*<Tabs scrollButtons="auto" className={styles.Tabs} value={currentGroup} onChange={(event,value) => { setCurrentGroup(value)}}>*/}
                {/*{*/}
                {/*    setting.settingGroups.map((group,index) => <Tab key={"menu_group_item" + index} value={group.group} label={group.groupName} className={styles.Tab}/>)*/}
                {/*}*/}
                {/*    <Tab value="advance" label="Advanced" className={styles.Tab}/>*/}
                {/*</Tabs>*/}
                <Tabs scrollButtons="auto" className={styles.Tabs}
                      value={currentGroupType}
                      onChange={(event,value) => {
                          setCurrentGroupType(value)
                          SetSessionItem("viewSettingTab",value.toString())
                      }}>
                    {hasGroup(SettingGroupType.layout) && <Tab value={SettingGroupType.layout} label="Layout" className={styles.Tab}/>}
                    <Tab value={SettingGroupType.style} label="Style" className={styles.Tab}/>
                    <Tab value={SettingGroupType.advanced} label="Advanced" className={styles.Tab}/>
                </Tabs>
            </Box>
            <Box className={styles.SettingPanel}>
                {   createAccordions(groups,allSettings) }
                {
                    currentGroupType === SettingGroupType.advanced && <Accordion disableGutters expanded={currentGroups.has("editors")}
                                                                                onChange={(event,value) => {
                                                                                    let set = new Set<string>();//_.clone(currentGroups);
                                                                                    if(value) {
                                                                                        set.add("editors");
                                                                                    } else {
                                                                                        set.delete("editors");
                                                                                    }
                                                                                    setCurrentGroups(set);
                                                                                }
                                                                                }>
                            <AccordionSummary className={styles.AccordingLabelPanel} expandIcon={<OutlinedIcon>expand_more</OutlinedIcon>}>
                                <span className={currentGroups.has("editors") ? styles.AccordingLabelActive : styles.AccordingLabel}>CSS Editors</span>
                            </AccordionSummary>
                            <AccordionDetails className={styles.AccordingDetailPanel}>
                                {createCurrentStyle()}
                                {createThemeStyle()}
                            </AccordionDetails>
                        </Accordion>
                }
            </Box>
        </>
    } else {
        return <></>
    }
}