import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../store";

interface LoginState {
    isLogin: boolean;
    userName: string;
    userProfileImage: string | null;
}

const initialState: LoginState = {
    isLogin: false,
    userName: "",
    userProfileImage: null
}


export const loginSlice = createSlice({
    name : 'login',
    initialState,
    reducers: {
        loginAction: (state: LoginState, action: PayloadAction<{ userName: string, userProfileImage: string|null}>) => {
            state.isLogin = true;
            state.userName = action.payload.userName;
            state.userProfileImage = action.payload.userProfileImage
        },
        logoutAction: state => {
            state.isLogin = false;
            state.userName = "";
            state.userProfileImage = "";
        },
    }

});

export const { loginAction, logoutAction } = loginSlice.actions

// // Other code such as selectors can use the imported `RootState` type
export const selectLoginState = (state: RootState) => state.loginReducerState;

export default loginSlice.reducer;