import {SettingInterface} from "./SettingInterface";

export enum SettingType {
    position,
    margin,
    padding,
    dimension,
    pixeldimension,
    width,
    height,
    border,
    border_radius,
    box_shadow,
    typography,
    align,
    background,
    object,
    outline,
    widget,
    sections,
    flex,
    nothing,
    vertical_align,
    text_align,
    advance_background,
}

export enum SettingGroupType {
    layout, style, advanced, unknown
}

//
export interface SettingOption {
    type: SettingInterface|SettingType,
    title?: string,
    editor?: any,
    group: string
    additions?: {[key:string]: any}
    groupType: SettingGroupType
}

export interface SettingGroup {
    group: string,
    groupName: string
}