import {Grid, MenuItem, TextField} from "@mui/material";
import React, {useContext} from "react";
import {SettingLabel} from "../../setting/settinglabel/SettingLabel";
import AssetField from "../../../../ui/assetfield/AssetField";
import TinyField from "../../../../ui/editor/TinyField";

export default function CardSetting(props: { widgetData: any, saveWidgetState: () => void , portalThemeName?: string, siteCode?: string}) {
    let {widgetData} = props;
    const handleChange = (value: any, key: string) => {
        widgetData.widgetProps["content"][key] = value;
        props.saveWidgetState();
    }

    return <Grid container spacing={2}>
        <Grid item xs={4}><SettingLabel title={"Open Link In"}/></Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                       fullWidth={true}
                       select={true}
                       label={""}
                       defaultValue={widgetData.widgetProps['content']['openLink'] ? widgetData.widgetProps['content']['openLink'] : "_self"}
                       onChange={(event) => {
                           handleChange(event.target.value.trim(), "openLink")
                       }}>
                <MenuItem value={"_self"}>Current Window</MenuItem>
                <MenuItem value={"_blank"}>New Window</MenuItem>
            </TextField>
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"Image URL"}/>
        </Grid>
        <Grid item xs={8}>
            <AssetField size={"small"}
                       fullWidth={true}
                       label={""}
                       defaultValue={widgetData.widgetProps['content']['image_url']}
                       onChange={(event) => {
                           handleChange(event.target.value, "image_url")
                       }}/>
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"Image Link URL"}/>
        </Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={""}
                       defaultValue={widgetData.widgetProps['content']['image_href']}
                       onChange={(event) => {
                           handleChange(event.target.value, "image_href")
                       }}/>
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"Title"}/>
        </Grid>
        <Grid item xs={8}>
            <TextField size={"small"}
                                fullWidth={true}
                                label={""}
                                defaultValue={widgetData.widgetProps['content']['title']}
                                onChange={(event) => {
                           handleChange(event.target.value, "title")
                       }}/>
        </Grid>
        <Grid item xs={4}>
            <SettingLabel title={"Description"}/>
        </Grid>
        <Grid item xs={8}>
            <TinyField value={widgetData.widgetProps['content']['desc']}
                       portalThemeName={props.portalThemeName}
                       siteCode={props.siteCode}
                       onChange={(value:string) => handleChange(value as string, "desc")}/>
            {/*<MultiLineTextField size={"small"}*/}
            {/*                    fullWidth={true}*/}
            {/*                    label={""}*/}
            {/*                    rows={4}*/}
            {/*                    defaultValue={widgetData.widgetProps['content']['desc']}*/}
            {/*                    onChange={(event) => {*/}
            {/*               handleChange(event.target.value, "desc")*/}
            {/*           }}/>*/}
        </Grid>

    </Grid>
}