import {SectionDesc} from "../cms/model/desc/SectionDesc";
import {SectionGroupType} from "../cms/model/desc/SectionGroupType";
import {WidgetDesc} from "../cms/model/desc/WidgetDesc";
import {WidgetGroupType} from "../cms/model/desc/WidgetGroupType";

export function sectionDescriptionsDataFetcher():SectionDesc[] {
    return [
        {   sectionType: "section",
            sectionName: "DefaultSection",
            sectionNickName: "Section",
            sectionDescription: "Vertical or Horizontal Arrangement",
            sectionIcon: "grid_view",
            sectionGroupType: SectionGroupType.unspecfic
        },
        {   sectionType: "section",
            sectionName: "Vertical",
            sectionNickName: "Rows",
            sectionDescription: "Row Arrangement",
            sectionIcon: "table_rows",
            sectionGroupType: SectionGroupType.unspecfic
        },
        {   sectionType: "section",
            sectionName: "Horizontal",
            sectionNickName: "Horizontal Section",
            sectionDescription: "Horizontal Arrangement",
            sectionIcon: "view_column",
            sectionGroupType: SectionGroupType.unspecfic
        },
        {   sectionType: "section",
            sectionName: "TwoColumns",
            sectionNickName: "Two Columns Section",
            sectionDescription: "Two Columns Arrangement",
            sectionIcon: "view_compact",
            sectionGroupType: SectionGroupType.unspecfic
        },
        {   sectionType: "section",
            sectionName: "ThreeColumns",
            sectionNickName: "Three Columns Section",
            sectionDescription: "Three Columns Arrangement",
            sectionIcon: "view_compact",
            sectionGroupType: SectionGroupType.unspecfic
        }
    ];
}

//auto_awesome_mosaic
//view_compact
//crop_portrait
//grid_view
//sub-subtitles
//call_to_action
export function widgetDescriptionsDataFetcher():WidgetDesc[] {
    return [
        {
            widgetType: "widget",
            widgetName: "Text",
            widgetNickName: "Text",
            widgetDescription: "Text Element",
            widgetIcon: "text_snippet",
            widgetGroupType: WidgetGroupType.unspecfic,
            widgetExperimental: false
        },
        {
            widgetType: "widget",
            widgetName: "Image",
            widgetNickName: "Image",
            widgetDescription: "Image Element",
            widgetIcon: "image",
            widgetGroupType: WidgetGroupType.unspecfic,
            widgetExperimental: false
        },
        {
            widgetType: "widget",
            widgetName: "Button",
            widgetNickName: "Button",
            widgetDescription: "Button Element",
            widgetIcon: "ads_click",
            widgetGroupType: WidgetGroupType.unspecfic,
            widgetExperimental: false
        },
        {
            widgetType: "widget",
            widgetName: "Card",
            widgetNickName: "Card",
            widgetDescription: "Card Element",
            widgetIcon: "subtitles",
            widgetGroupType: WidgetGroupType.unspecfic,
            widgetExperimental: false
        },
        {
            widgetType: "widget",
            widgetName: "HeroSection",
            widgetNickName: "Hero Section",
            widgetDescription: "Hero Section Element",
            widgetIcon: "title",
            widgetGroupType: WidgetGroupType.unspecfic,
            widgetExperimental: false
        },
        {
            widgetType: "widget",
            widgetName: "ImageList",
            widgetNickName: "Image List",
            widgetDescription: "Image List Element",
            widgetIcon: "filter",
            widgetGroupType: WidgetGroupType.unspecfic,
            widgetExperimental: false
        },
        {
            widgetType: "widget",
            widgetName: "Gallery",
            widgetNickName: "Gallery",
            widgetDescription: "Gallery Element",
            widgetIcon: "view_list",
            widgetGroupType: WidgetGroupType.unspecfic,
            widgetExperimental: false
        },
        {
            widgetType: "widget",
            widgetName: "Plugin",
            widgetNickName: "Plugin",
            widgetDescription: "3rd party plugin",
            widgetIcon: "extension",
            widgetGroupType: WidgetGroupType.unspecfic,
            widgetExperimental: false
        },
        {
            widgetType: "widget",
            widgetName: "ProductSuggestion",
            widgetNickName: "Suggestion",
            widgetDescription: "Suggestion Element",
            widgetIcon: "smart_button",
            widgetGroupType: WidgetGroupType.unspecfic,
            widgetExperimental: true
        },
        {
            widgetType: "widget",
            widgetName: "ProductDrilldown",
            widgetNickName: "Product Drilldown",
            widgetDescription: "Product Drilldown Element",
            widgetIcon: "segment",
            widgetGroupType: WidgetGroupType.unspecfic,
            widgetExperimental: true
        },
        {
            widgetType: "widget",
            widgetName: "Header",
            widgetNickName: "Header",
            widgetDescription: "Header Element",
            widgetIcon: "extension",
            widgetGroupType: WidgetGroupType.unspecfic,
            widgetExperimental: true
        },
        {
            widgetType: "widget",
            widgetName: "ProductQuery",
            widgetNickName: "Product Query",
            widgetDescription: "Product Query Element",
            widgetIcon: "manage_search",
            widgetGroupType: WidgetGroupType.unspecfic,
            widgetExperimental: true
        },
        {
            widgetType: "widget",
            widgetName: "AdvanceContent",
            widgetNickName: "Advance Content",
            widgetDescription: "Advance Content Element",
            widgetIcon: "ballot",
            widgetGroupType: WidgetGroupType.unspecfic,
            widgetExperimental: true
        },
        {
            widgetType: "widget",
            widgetName: "Login",
            widgetNickName: "Login",
            widgetDescription: "Login Element",
            widgetIcon: "login",
            widgetGroupType: WidgetGroupType.unspecfic,
            widgetExperimental: true
        },
        {
            widgetType: "widget",
            widgetName: "FieldGroup",
            widgetNickName: "Field Group",
            widgetDescription: "Field Group Element",
            widgetIcon: "topic",
            widgetGroupType: WidgetGroupType.unspecfic,
            widgetExperimental: true
        }

    ]
}
