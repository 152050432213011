import {
    Grid, GridSize,
    ToggleButton, ToggleButtonGroup
} from "@mui/material";
import React, {useState} from "react";
import styles from "./DefaultSectionSetting.module.scss";
import SizeInput from "../../../../ui/setting/input/SizeInput";
import OnOffSwitch from "../../setting/onoffswitch/OnOffSwitch";
import _ from "lodash";
import OutlinedIcon from "../../../../ui/icon/OutlinedIcon";
import PixelInput from "../../../../ui/setting/input/PixelInput";
import {nanoid} from "nanoid";
import {SettingGroup, SettingOption} from "../../../model/SettingOption";
import {DefaultSettingGenerator} from "./DefaultSectionUtil";
import SectionWidthSetting from "../../setting/sectionwidthsetting/SectionWidthSetting";
import SectionHeightSetting from "../../setting/sectionheightsetting/SectionHeightSetting";
import {DEFAULTSECTION_MAX_COLUMNS} from "./DefaultSection";

export default function DefaultSectionSetting(props: {
    widgetData: any,
    saveWidgetState: () => void ,
    onCustomSetting: (data:{
        settingOptions: SettingOption[],
        settingGroups: SettingGroup[],
    }) => void}) {
    const widgetProps = props.widgetData.sectionProps;
    const [columns,setColumns] = useState<number>(Math.min(widgetProps['content']['columns'],DEFAULTSECTION_MAX_COLUMNS));
    const [vertical,setVertical] = useState<boolean>(widgetProps['content']['layout'] === "vertical");
    const [horizontalAlign,setHorizontalAlign] = useState<number>(widgetProps['content']['justify-content']);
    const [verticalAlign,setVerticalAlign] = useState<number>(widgetProps['content']['align-items']);
    const [refreshId,setRefreshId] = useState<string>(nanoid());

    const handleChange = (value: any, key: string) => {
        widgetProps["content"][key] = value;
        props.saveWidgetState();
    }

    const handleChangeHeight = (height: string) => {
        props.widgetData.sectionProps['bt_defaultsection']['style'].default = false;
        props.widgetData.sectionProps['bt_defaultsection']['style']['dimension'] = {...props.widgetData.sectionProps['bt_defaultsection']['style']['dimension'] , height: height}
        props.saveWidgetState();
    }

    if(!(widgetProps['content'])) {
        widgetProps['content'] = {
            equalSpace: true,
            gap: "0px",
            "justify-content": "center",
            "align-content": "top",
        }
    }

    if(!widgetProps['content']['gap']) {
        widgetProps['content']['gap'] = "0.0px";
    }

    if(!widgetProps['bt_defaultsection']) {
        widgetProps['bt_defaultsection'] = {}
    }

    if(!widgetProps['bt_defaultsection']['style']) {
        widgetProps['bt_defaultsection']['style'] = {dimension : {}}
    }

    if(!widgetProps['bt_defaultsection']['style']['dimension']) {
        widgetProps['bt_defaultsection']['style']['dimension'] = {height: "auto"};
    }

    const [evenLayout, setEvenLayout] = useState<boolean>(widgetProps['content']['equalSpace']);
    const labelWidth:GridSize = 5;
    const objectWidth:GridSize = 7;
    const label = widgetProps['content']['layout'] === "horizontal" ? "Column" : "Row";
    const updateSize = (value: number,vertical:boolean) => {
        if(vertical) {
            widgetProps["content"][`column1-width`] = "100%";
            widgetProps["content"][`column2-width`] = "100%";
            widgetProps["content"][`column3-width`] = "100%";
            widgetProps["content"][`column4-width`] = "100%";
            widgetProps["content"][`column5-width`] = "100%";
        } else {
            if (value === 1) {
                widgetProps["content"][`column1-width`] = "100%";
            } else if (value === 2) {
                widgetProps["content"][`column2-width`] = "50%";
                widgetProps["content"][`column1-width`] = "50%";
            } else if (value === 3) {
                widgetProps["content"][`column3-width`] = "33%";
                widgetProps["content"][`column1-width`] = "33%";
                widgetProps["content"][`column2-width`] = "33%";
            } else if (value === 4) {
                widgetProps["content"][`column4-width`] = "25%";
                widgetProps["content"][`column1-width`] = "25%";
                widgetProps["content"][`column2-width`] = "25%";
                widgetProps["content"][`column3-width`] = "25%";
            } else if (value === 5) {
                widgetProps["content"][`column5-width`] = "20%";
                widgetProps["content"][`column1-width`] = "20%";
                widgetProps["content"][`column2-width`] = "20%";
                widgetProps["content"][`column3-width`] = "20%";
                widgetProps["content"][`column4-width`] = "20%";
            }
        }
        setRefreshId(nanoid());
    }
    return <Grid container spacing={2}>
        <Grid item xs={labelWidth} >
            {label}s
        </Grid>
        <Grid item xs={objectWidth}>
            <ToggleButtonGroup
                exclusive
                value={columns}
                className={styles.ColumnToggleButton}
                onChange={(event,value) => {
                    updateSize(value,vertical);
                    setColumns(value);
                    props.onCustomSetting(DefaultSettingGenerator(value));
                    handleChange(value, "columns")
                }}
            >
                <ToggleButton value={1}>1</ToggleButton>
                <ToggleButton value={2}>2</ToggleButton>
                <ToggleButton value={3}>3</ToggleButton>
                <ToggleButton value={4}>4</ToggleButton>
                <ToggleButton value={5}>5</ToggleButton>
            </ToggleButtonGroup>
        </Grid>
        <SectionWidthSetting defaultValue={widgetProps['content']['sectionWidth']} onChange={(value) => {
            handleChange(value,"sectionWidth")
        }}/>
        <SectionHeightSetting defaultValue={widgetProps['bt_defaultsection']['style']['dimension']['height']} onChange={(value) => {
            handleChangeHeight(value);

        }}/>
        <Grid item xs={labelWidth} >Vertical Orientation</Grid>
        <Grid item xs={objectWidth}>
            <OnOffSwitch defaultValue={vertical} onChange={(value) => {
                setVertical(value);
                updateSize(columns,value);
                handleChange(value ? "vertical" : "horizontal", "layout");
            }}/>
        </Grid>

        { _.range(1,columns+1).map(column => <React.Fragment key={`column${column}_panel_${refreshId}`}>
                <Grid item xs={labelWidth}>
                    {label} {column} Width
                </Grid>
                <Grid item xs={objectWidth}>
                    <SizeInput auto={false} value={widgetProps['content'][`column${column}-width`]} onChangeSize={(value) => {
                        handleChange(value, `column${column}-width`);
                    }}/>
                </Grid>
            </React.Fragment>
        )
        }

        <Grid item xs={labelWidth}>
            {label} Space
        </Grid>
        <Grid item xs={objectWidth}>
            <SizeInput auto={false} value={widgetProps['content'][`gap`]} onChangeSize={(value) => {
                handleChange(value, `gap`);
            }}/>
        </Grid>

        { vertical === false && <><Grid item xs={labelWidth}>
            Layout Break
        </Grid>
        <Grid item xs={objectWidth}>
            <PixelInput value={widgetProps['content'][`layout-break`]} onChangeSize={(value) => {
                handleChange(value, `layout-break`);
            }}/>
        </Grid></>}
        { (vertical || columns === 1) && <><Grid item xs={labelWidth}>
            Alignment
        </Grid>
        <Grid item xs={objectWidth}>
            <ToggleButtonGroup
                exclusive
                value={horizontalAlign}
                className={styles.ColumnToggleButton}
                onChange={(event,value) => {
                    setHorizontalAlign(value);
                    handleChange(value, "justify-content")
                }}
            >
                <ToggleButton value={"start"}><OutlinedIcon>align_horizontal_left</OutlinedIcon></ToggleButton>
                <ToggleButton value={"center"}><OutlinedIcon>align_horizontal_center</OutlinedIcon></ToggleButton>
                <ToggleButton value={"end"}><OutlinedIcon>align_horizontal_right</OutlinedIcon></ToggleButton>
            </ToggleButtonGroup>
        </Grid></>}
        { (vertical === false && columns > 1) && <><Grid item xs={labelWidth}>
            Alignment
        </Grid>
        <Grid item xs={objectWidth}>
            <ToggleButtonGroup
                exclusive
                value={verticalAlign}
                className={styles.ColumnToggleButton}
                onChange={(event,value) => {
                    setVerticalAlign(value);
                    handleChange(value, "align-items")
                }}
            >
                <ToggleButton value={"start"}><OutlinedIcon>align_vertical_top</OutlinedIcon></ToggleButton>
                <ToggleButton value={"center"}><OutlinedIcon>align_vertical_center</OutlinedIcon></ToggleButton>
                <ToggleButton value={"end"}><OutlinedIcon>align_vertical_bottom</OutlinedIcon></ToggleButton>
            </ToggleButtonGroup>
        </Grid></>}
    </Grid>
}