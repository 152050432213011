import {Divider, Grid, Popover, TextField} from "@mui/material";
import SettingProps from "../SettingProps";
import styles from "./GenericSetting.module.scss"
import OutlinedIcon from "../../../../ui/icon/OutlinedIcon";
import {InputOption} from "../../../../model/InputModel";
import {useRef, useState} from "react";
import SizeInput from "../../../../ui/setting/input/SizeInput";
import React from "react";
import PixelInput from "../../../../ui/setting/input/PixelInput";
import ColorPickerField from "../../../../ui/colorpicker/ColorPickerField";
import SuggestInput from "../../../../ui/setting/input/SuggestInput";
import AssetField from "../../../../ui/assetfield/AssetField";
import {htmlUtil} from "../../../../util/HTMLUtil";
import {SettingLabel} from "../settinglabel/SettingLabel";

export interface GenericSettingProps {
    title: string
    type: string
    targets: SettingTarget[]
    afterUpdate?: (data:any,value:any,key:string) => void
}

export enum StyleSettingType {
    default_size, default_and_auto_size, px_only_size, color, selections, text, dam
}

export interface SettingTarget {
    name: string,
    target: string, // target name => match with css
    type: StyleSettingType // style name
    options?: InputOption[]
}

let globalTimeout:NodeJS.Timeout|null = null;

export default function GenericSetting(props: GenericSettingProps & SettingProps) {
    const data = props.settingProps;
    const {type} = props;
    const afterUpdate = props.afterUpdate;
    const group = props.settingOption.group;
    const panelRef = useRef<any>()
    const [anchorEl, setAnchorEl] = useState<HTMLSpanElement | null>(null);

    if(!data[group]) {
        data[group] = { style : {} };
    }

    if(!data[group]['style']) {
        data[group]['style'] = {};
    }

    if(!data[group]['style'][type]) {
        data[group]['style'][type] = {}
    }

    const handleChange = (value: any, key: string) => {
        if(!data[group]) {
            data[group] = { style : { position: {}}}
        }

        if(!data[group]['style']) {
            data[group]['style'] = { position: {}}
        }

        if(value === "" || value === null) {
            delete data[group]['style'][type][key];
        } else {
            data[group]['style'][type][key] = value;
        }

        data[group]['style']['default'] = false; // user did change the component

        if(afterUpdate) {
            afterUpdate(data[group]['style'][type],value,key);
        }

        props.saveWidgetState();
        if(props.onChange) props.onChange(props.settingOption,props.settingOptions,props.index);
    }

    const hasChanged = (targets:SettingTarget[]) => {
        if(targets) {
            for (let i = 0; i < targets.length; i++) {
                if(data[group]['style'][type][targets[i].target] && data[group]['style'][type][targets[i].target] !== "") {
                    return true;
                }
            }
            return false;
        } else {
            return true;
        }

    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
        event.preventDefault();
        setTimeout(() => {
            if(panelRef.current) {
                panelRef.current.focus();
            }
        },100);
    }

    const handleOnClose = () => {
        setAnchorEl(null);
    }

    const handleOnBlur = (event:React.FocusEvent) => {
        globalTimeout = setTimeout(() => {
            setAnchorEl(null);
            globalTimeout = null;
        }, 100);
        event.stopPropagation()
        event.preventDefault()
    }

    const handleCancelAutoClose = (event:React.FocusEvent) => {
        if(globalTimeout !== null) {
            clearTimeout(globalTimeout);
            globalTimeout = null;
        }
        event.stopPropagation()
        event.preventDefault()
    }

    const generateSettingWidget = (settingTarget: SettingTarget) => {
        let target = settingTarget.target;
        if(settingTarget.type === StyleSettingType.default_size) {
            return <SizeInput auto={false} onChangeSize={(size) => {
                                    handleChange(size, settingTarget.target)
                                }}
                              value={data[group]['style'][type][target] ? data[group]['style'][type][target] : ""}
            />
        } else if(settingTarget.type === StyleSettingType.default_and_auto_size) {
            return <SizeInput auto={true} onChangeSize={(size) => {
                handleChange(size, settingTarget.target)
            }}
                              value={data[group]['style'][type][target] ? data[group]['style'][type][target] : ""}
            />
        } else if(settingTarget.type === StyleSettingType.px_only_size) {
            return <PixelInput onChangeSize={(size) => {
                                    handleChange(size, settingTarget.target)
                                }}
                               value={data[group]['style'][type][target] ? data[group]['style'][type][target] : ""}
            />
        } else if(settingTarget.type === StyleSettingType.color) {
            return <ColorPickerField
                value={data[group]['style'][type][target] ? data[group]['style'][type][target] : ""}
                onChangeColor={(color) => {
                    handleChange(color, target)
                }} label={""}
                placeholder={target}/>
        } else if(settingTarget.type === StyleSettingType.dam) {
            return <AssetField size="small" fullWidth inputProps={{style: {fontSize: "12px"}}}
                               defaultValue={htmlUtil.removeURL(data[group]['style'][type]["background-image"])}
                               onChange={(event) => {
                                   let value = event.target.value;
                                   handleChange(`url("${value}")`, target)
                               }}/>
        }  else if(settingTarget.type === StyleSettingType.text) {
            return <TextField
                size={"small"}
                fullWidth
                inputProps={{sx: {paddingBlock: "5px"}}}
                InputLabelProps={{shrink: true}}
                defaultValue={data[group]['style'][type][target] ? data[group]['style'][type][target] : ""}
                onChange={(event) => {
                    handleChange(event.currentTarget.value, target)
                }} label={""}
                placeholder={target}/>
        }  else if(settingTarget.type === StyleSettingType.selections && settingTarget.options) {
            return <SuggestInput options={ settingTarget.options}
                                 value={data[group]['style'][type][target] ? data[group]['style'][type][target] : ""}
                                 onChange={(value) => {
                                     handleChange(value, target)
                                    }
                                 }
                          label={""}/>
        } else {
            return <></>
        }
    }

    const open = Boolean(anchorEl);
    let changed = hasChanged(props.targets);
    return <Grid container className={styles.Root}  key={group}>
        <Grid item xs={11} sx={{margin: "auto"}} className={`${styles.Title}  ${changed ? styles.Bold : "" }`}>{props.title}</Grid>
        <Grid item xs={1}>
            <button className={styles.Button} onClick={handleClick}><OutlinedIcon>drive_file_rename_outline</OutlinedIcon></button>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleOnClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                sx={{
                    marginBlockStart: "5px",
                    pointerEvents: "none"
                }}
            >
                    <div ref={panelRef}
                         className={styles.Popover}
                         tabIndex={-1}
                         style={{pointerEvents: "auto"}}
                         onBlur={handleOnBlur}
                         onFocus={handleCancelAutoClose}
                    >
                        <div className={styles.PopoverTitle}>{props.title}</div>
                        <Divider/>
                        <Grid container className={styles.PopoverDetail}>
                            {
                                props.targets.map((target,index) => <React.Fragment key={group + "_" + target + "_" + index}>
                                    <Grid item xs={4}><SettingLabel small={true} title={target.name}/></Grid>
                                    <Grid item xs={8} className={styles.TargetInput}>
                                        {generateSettingWidget(target)}
                                    </Grid>
                                </React.Fragment>)
                            }
                        </Grid>
                    </div>
            </Popover>
        </Grid>
    </Grid>
}