import {
    Autocomplete, Box, Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid, Icon, IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import React, {useEffect, useState} from "react";
import SharedObjects from "../../shared/editable/ShareObjects";
import {InputOption} from "../../../../model/InputModel";
import styles from "../header/HeaderSetting.module.scss";
import {jcr} from "../../../../datafetcher/JCRDataFetcher";
import {AutocompleteRenderInputParams} from "@mui/material/Autocomplete/Autocomplete";
import DrillItemCmd from "../../../model/DrillItemCmd";
import OutlinedIcon from "../../../../ui/icon/OutlinedIcon";

interface AutoCompleteOption {
    label: string,
    value: string,
    depth: number
    index: number
}

export default function ProductDrilldownSetting(props: {
                                                    widgetData: any,
                                                    saveWidgetState: () => void,
                                                    // onChangePreview: (drilldown: string,previewId:string) => void,
                                                    widgetCallback: (action:string, value:any) => void,
                                                    sharedObject?: SharedObjects
                                           }
                                                    // onChange: (searchType: string,
                                                //             searchData: string,
                                                //             start: number,
                                                //             rows: number,
                                                //             searchProfileId: number) => void}
) {
    const {widgetData} = props;
    //const {widgetProps} = widgetData;

    if(!widgetData.widgetProps['content']) {
        widgetData.widgetProps['content'] = {}
    }

    const [categoryPrefix,setCategoryPrefix] = useState<string>(widgetData.widgetProps['content']['categoryPrefix'] ? widgetData.widgetProps['content']['categoryPrefix'] : "");
    const [shopPrefix,setShopPrefix] = useState<string>(widgetData.widgetProps['content']['shopPrefix'] ? widgetData.widgetProps['content']['shopPrefix'] : "");
    const [emptyMessage,setEmptyMessage] = useState<string>(widgetData.widgetProps['content']['emptyMessage'] ? widgetData.widgetProps['content']['emptyMessage'] : "There are no stocked products for this category.");
    const [varName,setVarName] = useState<string>(widgetData.widgetProps['content']['widget_drilldown_varname'] ? widgetData.widgetProps['content']['widget_drilldown_varname'] : "");
    const [maxCol,setMaxCol] = useState<string>(widgetData.widgetProps['content']['maxCols'] ? widgetData.widgetProps['content']['maxCols'] : "4");
    const [count,setCount] = useState<string>(widgetData.widgetProps['content']['count'] ? widgetData.widgetProps['content']['count'] : "");
    const [hasBreadCrumb,setHasBreadCrumb] = useState<boolean>(widgetData.widgetProps['content']['hasBreadCrumb'] ? widgetData.widgetProps['content']['hasBreadCrumb'] : true);
    const [showSubDrilldown,setShowSubDrilldown] = useState<boolean>(widgetData.widgetProps['content']['showSubDrilldown'] !== undefined ? widgetData.widgetProps['content']['showSubDrilldown'] : true);
    const [breadcrumbSeparator,setBreadcrumbSeparator] = useState<String>(widgetData.widgetProps['content']['breadcrumbSeparator'] !== undefined ? widgetData.widgetProps['content']['breadcrumbSeparator'] : "/");
    const [breadcrumbSpace,setBreadcrumbSpace] = useState<String>(widgetData.widgetProps['content']['breadcrumbSpace'] !== undefined ? widgetData.widgetProps['content']['breadcrumbSpace'] : "0.1rem");
    const [drilldown, setDrilldown] = useState<string>(widgetData.widgetProps['content']['widget_drilldown'] ? widgetData.widgetProps['content']['widget_drilldown'] : "");
    const [previewList,setPreviewList] = useState<AutoCompleteOption[]>([]);
    const [currentPreview,setCurrentPreview] = useState<AutoCompleteOption|undefined>();

    const handleChange = (value: string, key: string) => {
        widgetData.widgetProps["content"][key] = value;
        props.saveWidgetState();
    }

    useEffect(() => {
        if(drilldown !== "") {
            jcr.drilldown.query(drilldown).then((response) => {
                let drillData:DrillItemCmd = JSON.parse(response.data.response.drillData);
                let collectName:AutoCompleteOption[] = [];
                let drillArray:{data: DrillItemCmd , depth: number}[] = [];
                let count:number = 0;
                let exitsNames: string[] = [];
                let previewId = widgetData.widgetProps['content']['previewId'];
                drillArray.push({data: drillData, depth: 0});
                let previewOption:AutoCompleteOption|undefined = undefined;
                while(drillArray.length > 0) {
                    let currentDrill = drillArray.pop();
                    if(currentDrill && currentDrill.data.subItems.length > 0) {
                        currentDrill.data.subItems.forEach(item => drillArray.push({data: item , depth: currentDrill!.depth+1}));
                        if(exitsNames.indexOf(currentDrill.data.name) < 0) {
                            let option = {
                                label: currentDrill.data.name,
                                value: currentDrill.data.indexId,
                                depth: currentDrill.depth,
                                index: count++
                            };

                            if(currentDrill.data.indexId === previewId) {
                                previewOption = option
                            }
                            collectName.push(option);
                            exitsNames.push(currentDrill.data.name);
                        }
                    }
                }
                if(collectName.length > 0) {
                    if(previewOption) {
                        setCurrentPreview(previewOption)
                    } else {
                        setCurrentPreview(collectName[0])
                        //props.onChangePreview(drilldown,collectName[0].value);
                        props.widgetCallback('preview',{drilldown: drilldown, previewId: collectName[0].value})
                    }
                }
                setPreviewList(collectName);

            });
        }
    },[drilldown]);

    function createDrilldown(props: {options: InputOption[]}) {
        return <FormControl size={"small"} fullWidth>
            <InputLabel id={"drilldown_selection"}>Drilldown</InputLabel>
            <Select
                label={"Drilldown"}
                id={"drilldown_selection"}
                onChange={(event) => {
                    handleChange(event.target.value.trim(), "widget_drilldown")
                    setDrilldown(event.target.value)
                }}
                value={drilldown}
            >
                {
                    props.options.map((option,index) => {
                        return <MenuItem key={`drilldown_selection_${index}`} value={option.value}>{option.label}</MenuItem>
                    })
                }
            </Select>
        </FormControl>
    }

    return <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={12}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={"Catalog URL Prefix"}
                       value={categoryPrefix}
                       InputLabelProps={{shrink: true}}
                       onChange={(event) => {
                           handleChange(event.target.value.trim(), "categoryPrefix")
                           setCategoryPrefix(event.target.value);
                       }}/>
        </Grid>
        <Grid item xs={12}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={"Shop URL Prefix"}
                       value={shopPrefix}
                       InputLabelProps={{shrink: true}}
                       onChange={(event) => {
                           handleChange(event.target.value.trim(), "shopPrefix")
                           setShopPrefix(event.target.value);
                       }}/>
        </Grid>
        <Grid item xs={12}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={"Empty Message"}
                       value={emptyMessage}
                       InputLabelProps={{shrink: true}}
                       onChange={(event) => {
                           handleChange(event.target.value.trim(), "emptyMessage")
                           setEmptyMessage(event.target.value);
                       }}/>
        </Grid>
        <Grid item xs={12}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={"Variable Name"}
                       value={varName}
                       InputLabelProps={{shrink: true}}
                       onChange={(event) => {
                           handleChange(event.target.value.trim(), "widget_drilldown_varname")
                           setVarName(event.target.value);
                       }}/>
        </Grid>
        <Grid item xs={12}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={"Max Columns"}
                       value={maxCol}
                       InputLabelProps={{shrink: true}}
                       onChange={(event) => {
                           handleChange(event.target.value.trim(), "maxCols")
                           setMaxCol(event.target.value);
                       }}/>
        </Grid>
        <Grid item xs={12}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={"Number of Display Items"}
                       value={count}
                       InputLabelProps={{shrink: true}}
                       onChange={(event) => {
                           handleChange(event.target.value.trim(), "count")
                           setCount(event.target.value);
                       }}/>
        </Grid>
        <Grid item xs={12}>
            { createDrilldown({options: props.sharedObject!.drillDownOptions}) }
        </Grid>
        <Grid item xs={12}  style={{paddingBlockStart: "0px"}}>
            <FormControlLabel className={styles.DefaultFontColor} control={<Checkbox
                checked={hasBreadCrumb}
                onChange={(event: any,value:boolean) => {
                    handleChange(event.target.checked, "hasBreadCrumb")
                    setHasBreadCrumb(event.target.checked);
                }}/>} label="Show Breadcrumb"/>
        </Grid>
        {hasBreadCrumb && <>
            <Grid item xs={12}>
                <TextField size={"small"}
                           fullWidth={true}
                           label={"Breadcrumb separator"}
                           value={breadcrumbSeparator}
                           InputLabelProps={{shrink: true}}
                           onChange={(event) => {
                               handleChange(event.target.value.trim(), "breadcrumbSeparator")
                               setBreadcrumbSeparator(event.target.value);
                           }}/>
            </Grid>
            <Grid item xs={12}>
                <TextField size={"small"}
                           fullWidth={true}
                           label={"Space between Breadcrumb"}
                           value={breadcrumbSpace}
                           InputLabelProps={{shrink: true}}
                           onChange={(event) => {
                               handleChange(event.target.value.trim(), "breadcrumbSpace")
                               setBreadcrumbSpace(event.target.value);
                           }}/>
            </Grid>
            </>
        }
        <Grid item xs={12}>
            <FormControlLabel className={styles.DefaultFontColor} control={<Checkbox
                checked={showSubDrilldown}
                onChange={(event: any,value:boolean) => {
                    handleChange(event.target.checked, "showSubDrilldown")
                    setShowSubDrilldown(event.target.checked);
                }}/>} label="Show sub-drilldown"/>
        </Grid>
        <Grid item xs={10}>
            <Autocomplete
                autoHighlight
                key={currentPreview ? currentPreview.label : ""}
                fullWidth
                disablePortal
                size={"small"}
                id="drilldown_preview"
                options={previewList}
                value={currentPreview}
                getOptionLabel={(option) => option.label}
                renderOption={(props,option) => {
                    return <Box key={"key_example_" + option.index} component="li" {...props}>
                        <div style={{paddingInlineStart:`calc(${option.depth} * 10px)`}}>{option.label}</div>
                    </Box>
                }}
                onChange={(event,option: any) => {
                    if(option) {
                        setCurrentPreview(option);
                        //props.onChangePreview(drilldown, option.value);
                        props.widgetCallback('preview',{drilldown: drilldown, previewId: option.value})
                    }
                }}
                renderInput={(params:AutocompleteRenderInputParams) =>
                    <TextField {...params}
                               label="Preview"
                    />
                }
            />
        </Grid>
        <Grid item xs={2} style={{paddingInlineStart: "5px"}}>
            <IconButton onClick={() => {
                let value = widgetData.widgetProps['content']['previewId'];
                if(!value) {
                    value = "";
                }
                navigator.clipboard.writeText(value);
                document.execCommand("copy");

            }
            }><OutlinedIcon>content_copy</OutlinedIcon></IconButton>
        </Grid>
    </Grid>
}