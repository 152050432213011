import {
    Box,
    Button,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    Popover,
    Tab,
    Tabs,
    TextField
} from "@mui/material";
import styles from "./TemplateSelection.module.scss"
import React, {useEffect, useRef, useState} from "react";
import {TemplateCmd} from "../../model/TemplateCmd";
import TemplateItem from "./TemplateItem";
import OutlinedIcon from "../icon/OutlinedIcon";
import {jcr} from "../../datafetcher/JCRDataFetcher";
import {FindTagName, Tag1ToName, Tag2ToName} from "../template/TemplateConst";
import SolidLinkButton from "../button/SolidLinkButton";
import ReverseSolidLinkButton from "../button/ReverseSolidLinkButton";
import {SetLocalItem} from "../../util/LocalStorageUtil";
import StorefrontPopover from "../popover/StorefrontPopover";
import {lsi} from "../../datafetcher/LSIDataFetcher";


export default function TemplateSelection(props: {
                            orgCode: string,
                            doClose : () => void ,
                            doSelectTemplate: (template: string) => void,
                            focusTemplate?: TemplateCmd
                            onNewPage?: (name:string, data: string) => void,
                            onlyFocusTemplate?: boolean,
                        }
) {
    const textRef = useRef<HTMLInputElement>()
    const [newEl, setNewEl] = useState<null | HTMLElement>(null);
    const [storefrontEl, setStorefrontEl] = useState<null | HTMLElement>(null);
    const [tag1ToName] = useState(Tag1ToName());
    const [tag2ToName] = useState(Tag2ToName());
    const [searchStr, setSearchStr] = useState<string>("");
    const [templates, setTemplates] = useState<TemplateCmd[]>();
    const [sections, setSections] = useState<TemplateCmd[]>();
    const [saveds, setSaveds] = useState<TemplateCmd[]>();
    const [storefronts, setStorefronts] = useState<any[]>([]);
    const [selectedData, setSelectedData] = useState<{ index: number, data: string, name: string }>({
        index: -1,
        data: "",
        name: "",
    });

    const [pageNameInput, setPageNameInput] = useState<string>("");
    const [tab, setTab] = useState<string>("page");
    const [selectedTag, setSelectedTag] = useState<string>("all2"); // selectedTag don't need to exist
    const [focusTemplate, setFocusTemplate] = useState<TemplateCmd|null>(props.focusTemplate ? props.focusTemplate : null); // keep only focusTemplate

    useEffect( () => {
        if(tab === "page" && templates === undefined) {
            setTemplates([]);
            jcr.pageTemplate.globalLite().then((response) => {
                setTemplates(response.data.response);
            });
        } else if(tab === "section" && sections === undefined) {
            setSections([]);
            jcr.sectionTemplate.globalLite().then((response) => {
                setSections(response.data.response);
            });
        } else if(tab === "saved" && saveds === undefined) {
            setSaveds([]);
            jcr.sectionTemplate.lite().then((response) => {
                setSaveds(response.data.response);
            });

        }
    },[tab])

    useEffect(() => {
        jcr.storefront.full().then(response => {
            setStorefronts(response.data.response.filter((storefront:any) => storefront.sfThemeId !== null && storefront.sfThemeId !== ""));
        })
    },[]);


    function showSelectedTemplate(storefront: any) {
        let previewData: any = {
            id: "previewData",
            viewName: selectedData.name,
            viewData: selectedData.data
        }
        SetLocalItem("previewData", JSON.stringify(previewData));
        let id = storefront.id;
        window.open(`/live/preview/orgName/view/internal/${id}`, '_blank', 'noreferrer');
    }

    function GetSecondLevelLogo(template:TemplateCmd,index:number) {
        if(template.viewLogos[index] === "") {
            return `${process.env.REACT_APP_WEBC_HOST}/thumbnail/${process.env.REACT_APP_GLOBAL_ORGCODE}/${template.id}-${index}.jpg`
        } else {
            return template.viewLogos[index]!
        }
    }

    function GetPageOrSectionLogo(template:TemplateCmd) {
        if(template.logo === "") {
            if(tab === "saved") {
                return `${process.env.REACT_APP_WEBC_HOST}/thumbnail/${props.orgCode}/${template.id}.jpg`
            } else {
                if(template.type === "page") {
                    return `${process.env.REACT_APP_WEBC_HOST}/thumbnail/${process.env.REACT_APP_GLOBAL_ORGCODE}/${template.id}-0.jpg`
                } else {
                    return `${process.env.REACT_APP_WEBC_HOST}/thumbnail/${process.env.REACT_APP_GLOBAL_ORGCODE}/${template.id}.jpg`
                }
            }
        } else {
            return template.logo;
        }
    }
    function createSecondScreen() {
        const handleAddNewWhenClick = () => {
            if(textRef) {
                let value = textRef?.current?.value;
                if(value && value !== "") {
                    if(props.onNewPage) {
                        props.onNewPage(value,selectedData.data);
                    }
                    setNewEl(null);
                }
            }
        }
        const handleAddNewFromKeyboard = (event:any) => {
            if(event.key === "Enter") {
                let value = event.target.value;
                if(value && value !== "") {
                    if(props.onNewPage) {
                        props.onNewPage(value,selectedData.data);
                    }
                    setNewEl(null);
                }

                event.stopPropagation();
                event.preventDefault()
            }
        }
        return <div className={styles.Root}>
                <div className={styles.DefaultPanel}>
                    <div className={styles.Button}>
                        <IconButton onClick={() => {
                            props.doClose()
                        }}><OutlinedIcon>close</OutlinedIcon></IconButton>
                    </div>
                </div>
                { props.onlyFocusTemplate !== true && <div className={styles.LinkPanel}>
                    <span onClick={() => setFocusTemplate(null)}><OutlinedIcon>arrow_back_ios_new</OutlinedIcon> Back to Concepts</span>
                </div>}
                <div className={styles.TitlePanel}>
                    <div className={styles.Title}>{focusTemplate?.title}</div>
                    <div className={styles.SecondSubTitle}>
                        <div className={styles.SecondSubTitleCount}>{focusTemplate?.views.length} Layouts</div>
                        {   FindTagName(focusTemplate!) }
                    </div>
                </div>
                <div className={styles.SecondContainer}>
                    <div className={styles.SecondContainerPreview}>
                        <img src={focusTemplate?.logo === "" ? `${process.env.REACT_APP_WEBC_HOST}/thumbnail/${process.env.REACT_APP_GLOBAL_ORGCODE}/${focusTemplate?.id!}-0.jpg` : focusTemplate?.logo}
                             onError={(event:any) => {
                                 let object:any = event.currentTarget;
                                 object.onerror = "";
                                 object.src = "/images/transparent.png";
                                 return true;
                             }}
                        />
                    </div>
                    <div className={styles.SecondContainerSelection}><div className={styles.Selection}>
                        {focusTemplate?.views.map((view,index) => <div className={styles.ItemSmall} key={`grid_template_item_${index}`}>
                            {/*for section item*/}
                            <TemplateItem title={view}
                                          subTitle={""}
                                          selected={selectedData.index === index}
                                          type={focusTemplate?.type}
                                          logo={GetSecondLevelLogo(focusTemplate!,index)}
                                          data={focusTemplate?.viewCaches[index]!}
                                          modifiedAt={focusTemplate?.modifiedAt!}
                                          size={"small"}
                                          doSelectTemplate={(data) => {
                                              setSelectedData({
                                                  index: index,
                                                  data: data,
                                                  name: view
                                              })
                                          }}
                                          totalLayout={0}
                                             />
                        </div>)
                        }</div>
                    </div>
                </div>

            <div className={styles.SecondContainerTemplatePreview}>
                &nbsp;
                {selectedData.index >= 0 && storefronts.length > 0 && <>
                    <ReverseSolidLinkButton onClick={(event:any) => {
                            if(storefronts.length === 1) {
                                showSelectedTemplate(storefronts[0]);
                            } else {
                                setStorefrontEl(event.currentTarget);
                            }
                        }}>Template Preview</ReverseSolidLinkButton>
                    <StorefrontPopover
                        popoverProps={
                            {
                                tabIndex: -1,
                                open: Boolean(storefrontEl),
                                anchorEl: storefrontEl,
                                anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right',
                                },
                                transformOrigin: {
                                    vertical: "bottom",
                                    horizontal: "right"
                                },
                                style: {
                                    pointerEvents: "none"
                                },
                            }
                        }
                        onBlur={(event) => setStorefrontEl(null)}
                        storefronts={storefronts}
                        onChangeStorefront={showSelectedTemplate}/>
                    {/*<Popover*/}
                    {/*    anchorOrigin={{*/}
                    {/*        vertical: 'top',*/}
                    {/*        horizontal: 'right',*/}
                    {/*    }}*/}
                    {/*    transformOrigin={{*/}
                    {/*        vertical: 'bottom',*/}
                    {/*        horizontal: 'right',*/}
                    {/*    }}*/}
                    {/*    anchorEl={storefrontEl}*/}
                    {/*    open={storefrontEl != null}*/}
                    {/*    onClose={() => setStorefrontEl(null)}*/}
                    {/*>*/}
                    {/*    <Box sx={{boxShadow: 3, padding: 0, maxWidth: "250px"}} style={{pointerEvents: "auto" , padding: "0px"}}>*/}
                    {/*        <List dense sx={{maxHeight: "250px",*/}
                    {/*            overflowY: "auto"}}>*/}
                    {/*            {*/}
                    {/*                storefronts && storefronts.map((storefront,index) => {*/}
                    {/*                        return <ListItem autoFocus={index === 0}*/}
                    {/*                                         disablePadding*/}
                    {/*                                         tabIndex={-1}*/}
                    {/*                                         onBlur={() => setStorefrontEl(null)}*/}
                    {/*                                         key={"current_storefront_" + index}*/}
                    {/*                                         value={storefront.id}*/}
                    {/*                                         onClick={() => showSelectedTemplate(index)}*/}
                    {/*                        ><ListItemButton>{storefront.sfName}</ListItemButton></ListItem>*/}
                    {/*                    }*/}
                    {/*                )*/}
                    {/*            }*/}
                    {/*        </List>*/}
                    {/*    </Box>*/}
                    {/*</Popover>*/}
                </>
                }
            </div>

            <div className={styles.SecondContainerAction}>
                {!props.onNewPage && <SolidLinkButton disabled={selectedData.data === ""} onClick={(event:any) => {
                        props.doSelectTemplate(selectedData.data);
                }}>Import</SolidLinkButton>}
                {props.onNewPage && <>
                        <SolidLinkButton disabled={selectedData.data === "" || newEl} onClick={(event:any) => setNewEl(event.currentTarget)}>Next</SolidLinkButton>
                        <Popover
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            sx={{ transform: "translate(11px,-15px)"}}
                            anchorEl={newEl}
                            open={newEl != null}
                            onClose={() => setNewEl(null)}
                        >
                            <Grid container spacing={1} className={styles.NewLabelPanel}>
                                <Grid item xs={2}><span className={styles.NewLabelTitle}>Page Name</span></Grid>
                                <Grid item xs={8}><TextField inputRef={textRef} autoFocus={true} size={"small"} onChange={(event) => setPageNameInput(event.target.value)} onKeyPress={handleAddNewFromKeyboard}/></Grid>
                                <Grid item xs={2} onClick={handleAddNewWhenClick}><IconButton className={pageNameInput != '' ? styles.nextArrowBtn : ''}><OutlinedIcon>navigate_next</OutlinedIcon></IconButton></Grid>
                            </Grid>
                        </Popover>
                    </>
                }
            </div>
        </div>
    }

    function createTopScreen() {
        let tags1: any = new Set();
        let tags2: any = new Set();
        let currentTitle = "";
        let currentTemplates: TemplateCmd[] = [];
        if (tab === "page") {
            currentTitle = "Pages";
            currentTemplates = templates ? templates : [];
        } else if (tab === "section") {
            currentTitle = "Sections";
            currentTemplates = sections ? sections : [];
        } else if (tab === "saved") {
            currentTitle = "Saved";
            currentTemplates = saveds ? saveds : [];
        }

        let tagCounts: any = new Map();
        let showTemplates: TemplateCmd[] = [];

        let foundTag = false;
        currentTemplates.forEach(template => {
            template.tags1.forEach(tag => {
                tags1.add(tag)
                if (selectedTag === tag) {
                    showTemplates.push(template);
                    foundTag = true;
                }
                if (tagCounts.has(tag)) {
                    tagCounts.set(tag, tagCounts.get(tag) + 1);
                } else {
                    tagCounts.set(tag, 1);

                }
            });
            if(template.type === "section") {
                template.tags2.forEach(tag => {
                    tags2.add(tag)
                    if (selectedTag === tag) {
                        showTemplates.push(template);
                        foundTag = true;
                    }
                    if (tagCounts.has(tag)) {
                        tagCounts.set(tag, tagCounts.get(tag) + 1);
                    } else {
                        tagCounts.set(tag, 1);
                    }
                });
            }
        })

        if (showTemplates.length === 0) {
            showTemplates = currentTemplates;
        }
        let tag1Arrays = Array.from(tags1);
        tag1Arrays = tag1Arrays.filter(tag => searchStr === "" || (tag as string).toLowerCase().indexOf(searchStr.toLowerCase()) >= 0)

        let tag2Arrays = Array.from(tags2);
        tag2Arrays = tag2Arrays.filter(tag => searchStr === "" || (tag as string).toLowerCase().indexOf(searchStr.toLowerCase()) >= 0)

        return <div className={styles.Root}>
            <div className={styles.RootSideBar}>
                <div className={styles.SideBarTitle}>
                    TEMPLATE LIBRARY
                </div>
                <div className={styles.SideBarTabPanel}>
                    <Tabs className={styles.Tabs} centered={true} value={tab}
                          onChange={(event, value) => setTab(value)}>
                        <Tab label={"Pages"} value={"page"} className={styles.Tab}/>
                        <Tab label={"Sections"} value={"section"} className={styles.Tab}/>
                        <Tab label={"Saved"} value={"saved"} className={styles.Tab}/>
                    </Tabs>
                </div>
                <div className={styles.SideBarSearchPanel}>
                    <TextField fullWidth
                               size={"small"}
                               placeholder={"search"}
                               value={searchStr}
                               onChange={(event) => setSearchStr(event.target.value)}
                        // InputProps={{endAdornment: <OutlinedIcon>search</OutlinedIcon>}}
                    />
                </div>

                {tag1Arrays.length > 0 && <ol className={styles.SideBarList}>
                    <li className={styles.SideBarTopicItem}>CONCEPTS
                        <hr/>
                    </li>
                    {
                        tag1Arrays.map((tag, index) => {
                            if (typeof tag === 'string') {
                                if (tag === selectedTag || (index === 0 && foundTag === false)) {
                                    return <li key={`tag1_item_${index}`}
                                        className={styles.SideBarListItem + "  " + styles.SelectedItem}>{tag1ToName[tag]}
                                        <span
                                            className={styles.SideBarCount + " , " + styles.SelectSideBarCount}>{tagCounts.get(tag)}</span>
                                    </li>
                                } else {
                                    return <li key={`tag1_item_${index}`} className={styles.SideBarListItem}
                                               onClick={() => setSelectedTag(tag)}>{tag1ToName[tag]} <span
                                        className={styles.SideBarCount}>{tagCounts.get(tag)}</span></li>
                                }
                            } else {
                                return <></>
                            }
                        })
                    }
                </ol>}
                {tag2Arrays.length > 0 && <ol className={styles.SideBarList}>
                    <li className={styles.SideBarTopicItem}>CATEGORIES
                        <hr/>
                    </li>
                    {
                        tag2Arrays.map((tag, index) => {
                            if (typeof tag === 'string') {
                                if (tag === selectedTag || (index === 0 && foundTag === false)) {
                                    return <li
                                        key={`tag2_item_${index}`}
                                        className={styles.SideBarListItem + "  " + styles.SelectedItem}>{tag2ToName[tag]}
                                        <span
                                            className={styles.SideBarCount + " , " + styles.SelectSideBarCount}>{tagCounts.get(tag)}</span>
                                    </li>
                                } else {
                                    return <li key={`tag2_item_${index}`} className={styles.SideBarListItem}
                                               onClick={() => setSelectedTag(tag)}>{tag2ToName[tag]} <span
                                        className={styles.SideBarCount}>{tagCounts.get(tag)}</span></li>
                                }
                            } else {
                                return <></>
                            }
                        })
                    }
                </ol>}
            </div>
            <div className={styles.ItemRoot}>
                <div className={styles.ItemTitlePanel}>
                    <div className={styles.Button}>
                        <IconButton onClick={() => {
                            props.doClose()
                        }}><OutlinedIcon>close</OutlinedIcon></IconButton>
                    </div>
                    {currentTitle}
                </div>
                <div className={styles.ItemsContentPanel}>
                    {showTemplates.map((template,index) => <div className={styles.Item} key={`grid_template_item_${index}`}>
                        {/*for page item*/}
                        <TemplateItem
                                    modifiedAt={template.modifiedAt!}
                                     title={template.title}
                                     subTitle={FindTagName(template)}
                                     logo={GetPageOrSectionLogo(template)}
                                     type={template.type}
                                     data={template.type === "section" && template.viewCaches.length > 0 ? template.viewCaches[0] : ""} // no date on the root node
                                      doSelectTemplate={(data) => {
                                          if(template.type === "section") { // try to query the real data again
                                              if(tab === "section") { // query from global organization
                                                  jcr.sectionTemplate.globalQuery(template.id!).then((res => {
                                                      let x: TemplateCmd = res.data.response;
                                                      if (x.viewCaches.length > 0) {
                                                          props.doSelectTemplate(x.viewCaches[0]);
                                                      } else {
                                                          props.doSelectTemplate("");
                                                      }
                                                  }))
                                              } else { // saved , query from current organization
                                                  jcr.sectionTemplate.query(template.id!).then((res => {
                                                      let x: TemplateCmd = res.data.response;
                                                      if (x.viewCaches.length > 0) {
                                                          props.doSelectTemplate(x.viewCaches[0]);
                                                      } else {
                                                          props.doSelectTemplate("");
                                                      }
                                                  }))
                                              }
                                          } else {
                                              props.doSelectTemplate(data);
                                          }
                                      }}
                                      totalLayout={template.views.length}
                                      doFocusTemplate={template.type === "page" ? () => {
                                            setSelectedData({
                                                index: -1,
                                                data: "",
                                                name: ""
                                            })
                                          jcr.pageTemplate.globalQuery(template.id!).then(response => {
                                              setFocusTemplate(response.data.response)
                                          });
                                      } : undefined} />
                    </div>)
                    }
                </div>
            </div>

        </div>
    }

    if(focusTemplate !== null) { // focus template
        return createSecondScreen();
    } else { // choose parent template
        return createTopScreen();
    }

}
