import SettingProps from "../SettingProps";
import GenericSetting, {StyleSettingType} from "../genericsetting/GenericSetting";
// import CSSSetting from "../csssetting/CSSSetting";

export default function TypoGraphySetting(props: SettingProps,
) {
    // return <CSSSetting {...props} title={"Typography"} type={"typography"}
    //                    targets={["color", "font-size" , "font-style" ,"font-variant", "font-weight",
    //                    "line-height" , "font-weight", "letter-spacing"]}/>
    return <GenericSetting {...props}
                           title={"Typography"}
                           type={"typography"}
                           targets={[
            { name: "Color",target: 'color' , type: StyleSettingType.color},
            { name: "Font Size", target: 'font-size' , type: StyleSettingType.default_size},
            { name: "Font Style", target: 'font-style' , type: StyleSettingType.selections, options: [
                    {label: "none", value: ""},
                    {label: "normal", value: "normal"},
                    {label: "italic", value: "italic"},
                ]},
            { name: "Font Weight", target: 'font-weight' , type: StyleSettingType.selections , options: [
                    {label:"none", value: ""},
                    {label:"100", value: "100"},
                    {label:"200", value: "200"},
                    {label:"300", value: "300"},
                    {label:"400", value: "400"},
                    {label:"500", value: "500"},
                    {label:"600", value: "600"},
                    {label:"700", value: "700"},
                    {label:"800", value: "800"},
                    {label:"900", value: "900"},
                    {label:"bold", value: "bold"},
                    {label:"bolder", value: "bolder"},
                    {label:"unset", value: "unset"}
                ]},
            { name: "Line Height", target: 'line-height' , type: StyleSettingType.default_size},
            { name: "Line Spacing", target: 'line-spacing' , type: StyleSettingType.default_size}
]}
    />
}