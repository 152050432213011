import {useEffect} from "react";

// callback will get call when we focus move from one object to other object
export default function useFocusInEditable(callback: (event:CustomEvent<{newFocus: string, oldFocus:string}>) => void) {
    useEffect(() => {
        document.addEventListener("_focusInEditable" , callback as (evt: Event) => void)
        return () => {
            document.removeEventListener("_focusInEditable", callback as (evt: Event) => void);
        }
    },[]);
    return {

        fireNewFocus: (newFocus:string,oldFocus: string) => {
            if(oldFocus !== newFocus) {
                document.dispatchEvent(new CustomEvent("_focusInEditable", {detail: {newFocus, oldFocus}}));
            }
        }
    }
}

