// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import Editor from "@ckeditor/ckeditor5-build-balloon";

//https://stackoverflow.com/questions/57576441/how-to-configure-ckeditor-toolbar-in-react-js
//*["undo", "redo", "bold", "italic", "blockQuote", "ckfinder",
// "imageTextAlternative",
// "imageUpload",
// "heading",
// "imageStyle:full",
// "imageStyle:side",
// "link", "numberedList",
// "bulletedList", "mediaEmbed",
// "insertTable", "tableColumn",
// "tableRow", "mergeTableCells"]*
import TinyEditor from "../editor/TinyEditor";

export default function InputHTML(props) {
    // return <CKEditor
    //     editor={ Editor }
    //     {...props}
    //     style={{backgroundColor: 'white'}}
    //     config={{
    //         toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote',
    //                     'numberedList', 'bulletedList', 'outdent', 'indent', 'link', '|','insertTable',
    //                      'tableColumn', 'tableRow', 'mergeTableCells', '|', 'undo', 'redo']
    //     }}
    //
    // />
    return <TinyEditor {...props}/>
}