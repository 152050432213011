import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Icon} from "@mui/material";

export interface ConfirmStyle {
    title: string;
    value: string;
    color: 'inherit' | 'primary' | 'secondary' | 'default';
    icon?: string | null;
}

export interface ConfirmData {
    open: boolean,
    title:string,
    body: string,
    tag: any,
    choices: ConfirmStyle[]
}

export interface ConfirmProps {
    data: ConfirmData,
    onClose: () => void,
    onSelected:(tag:any,type:string) => void
}

export default function ConfirmDialog(props:ConfirmProps ) {
    const onHandleClick = (label: string) => {
        props.onSelected(props.data.tag,label);
    };

    return (
        <Dialog
            open={props.data.open}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.data.title}</DialogTitle>
            <DialogContent style={{minWidth:"270px"}}>
                <DialogContentText id="alert-dialog-description">
                    {props.data.body}
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{paddingBottom: "20px"}}>
                {
                    props.data.choices.map((style,index) => {
                        return (
                            <Button key={index} onClick={() => onHandleClick(style.value)}  style={{textTransform: "none",paddingRight:"15px"}}>
                                {style.icon !== null && <Icon style={{paddingRight: "3px"}}>{style.icon}</Icon>}
                                {style.title}
                            </Button>
                        );
                    })
                }
            </DialogActions>
        </Dialog>
    )
}