import {TextFieldProps} from "@mui/material/TextField/TextField";
import {IconButton, Popover, Tab, Tabs, TextField} from "@mui/material";
import OutlinedIcon from "../icon/OutlinedIcon";
import React, {useEffect, useRef, useState} from "react";
import {DAMFilter, DAMSearchFilter} from "../dam/DAMFilter";
import {useOrganization} from "../../hook/useOrganization";
import {DAMFileList} from "../dam/DAMFileList";
import {DAMFolders} from "../dam/DAMFolders";
import {PDAMFileList} from "../dam/PDAMFileList";
import {PDAMFolders} from "../dam/PDAMFolders";
import {
    GetAssetFieldStorage,
    GetPortalDAMEnvironment,
    GetPortalEnvironment,
    SetAssetFieldStorage
} from "../popover/PortalEnvironmentPopover";
import styles from "../../cms/ui/shared/editable/CMSEditorWithFrame.module.scss";
import Icon from "@mui/material/Icon";


let globalTimeout:NodeJS.Timeout|null = null;
export interface AssetFieldProps {
    targetAnchorId?: string;
}

export default function AssetField(props:AssetFieldProps & TextFieldProps) {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement|null>(null);
    const orgCmd = useOrganization();
    const [currentCDNPath,setCurrentCDNPath] = useState<string>("");
    const [currentPortalPath,setCurrentPortalPath] = useState<string>("");
    const [searchFilter, setSearchFilter] = useState<DAMSearchFilter>({query: "", recursive: false, byName: true});

    const orgCode = orgCmd?.currentOrg?.orgCode!;
    const siteCode = orgCmd?.currentOrg?.siteCode!;
    const panelRef = useRef<any>()
    const textRef = useRef<any>();
    const [tab, setTab] = useState<string>("pdam");

    const handleOnBlur = (event:React.FocusEvent) => {
        globalTimeout = setTimeout(() => {
            // setAnchorEl(null);
            globalTimeout = null;
        }, 100);
        event.stopPropagation()
        event.preventDefault()
    }

    const handleCancelAutoClose = (event:React.FocusEvent) => {
        if(globalTimeout !== null) {
            clearTimeout(globalTimeout);
            globalTimeout = null;
        }
        event.stopPropagation()
        event.preventDefault()
    }

    useEffect(() => {
        let orgCode = orgCmd?.currentOrg?.orgCode;
        let siteCode = orgCmd?.currentOrg?.orgCode;
        let env = GetPortalEnvironment();
        if(orgCode && orgCode !== "" && siteCode && siteCode !== "") {
            let assetFieldStorage = GetAssetFieldStorage();
            if(assetFieldStorage.orgCode === orgCode &&
                assetFieldStorage.siteCode === siteCode) {
                // same cdn
                setCurrentCDNPath(assetFieldStorage.cdnPath);

                if(assetFieldStorage.env === env) {
                    // same portal environment
                    setCurrentPortalPath(assetFieldStorage.path);
                } else {
                    // different portal environment
                    setCurrentPortalPath("/");
                    SetAssetFieldStorage({...assetFieldStorage , env: env, path: "/"});
                }
            } else { // change orgCode or siteCode, reset path on portal and cdnPath
                SetAssetFieldStorage({orgCode , siteCode, env: env, path: "/" , cdnPath: "/"});
                setCurrentCDNPath("/");
                setCurrentPortalPath("/");
            }
            //setDataFetcher(new DAMDataFetcher("/",query));
        }
    },[orgCmd?.orgDetail,orgCmd?.currentOrg]);

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        if(newValue === 1) {
            setTab("dam");
        } else {
            setTab("pdam");
        }
    }

    let targetElement = document.getElementById("view_drawer");
    if(targetElement) {
        targetElement = targetElement.parentElement;
    } else {
        if(props.targetAnchorId) {
            targetElement = document.getElementById(props.targetAnchorId)
        }
        if(targetElement === null) {
            targetElement = document.getElementById("root");
        }
    }
    let tempProps = {...props};
    delete tempProps['targetAnchorId'];
    return <><TextField {...tempProps}
                        inputRef={textRef}
                        autoComplete={"off"}
                        InputProps={{
                            endAdornment: <IconButton tabIndex={-1} onClick={(e:React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)}><OutlinedIcon>folder</OutlinedIcon></IconButton>
                        }}
    />
    {   anchorEl !== null && <Popover
            open={true}
            anchorEl={anchorEl ? targetElement : anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            style={{pointerEvents: "none"}}
        >
            <div ref={panelRef} tabIndex={-1} style={{pointerEvents: "auto"}}
                 onBlur={handleOnBlur}
                 onFocus={handleCancelAutoClose}
            >
                <div style={{display:"flex", justifyContent: "flex-end"}}>
                    <IconButton className={styles.CloseIcon}
                                onClick={() => { setAnchorEl(null); }}>
                        <Icon>close</Icon>
                    </IconButton>
                </div>
                <Tabs
                      value={tab === "dam" ? 1 : 0}
                      onChange={handleChangeTab}
                >
                    <Tab label="Website"/>
                    <Tab label="DAM"/>
                </Tabs>

                { tab === "dam" && <>
                    <DAMFilter
                        mode={"select"}
                        orgCode={orgCode}
                        currentPath={currentCDNPath}
                        defaultFilter={searchFilter}
                        onSearch={setSearchFilter}
                        onFocus={handleCancelAutoClose}
                        onBlur={handleOnBlur}
                    />
                    <DAMFolders orgCode={orgCode}
                                currentPath={currentCDNPath}
                                onChangePath={(path) => {
                                    SetAssetFieldStorage({...GetAssetFieldStorage(),cdnPath: path})
                                    setCurrentCDNPath(path);
                                }}
                    />
                    <DAMFileList orgCode={orgCode}
                                 currentPath={currentCDNPath}
                                 searchFilter={searchFilter}
                                 mode={"select"}
                                 onSelectFile={(file) => {
                                     // eslint-disable-next-line
                                     textRef.current.value = "${CDN_URL}" + file
                                     if(props.onChange) {
                                         const evt:any = {target: {
                                                 name: "",
                                                 value:  textRef.current.value,
                                                 getAttribute: (name:string) => {
                                                     return textRef.current.getAttribute(name);
                                                 }
                                             },
                                             currentTarget: {
                                                 name: "",
                                                 value:  textRef.current.value,
                                                 getAttribute: (name:string) => {
                                                     return textRef.current.getAttribute(name);
                                                 }
                                             },
                                         }
                                         evt.stopPropagation = () => {

                                         };
                                         evt.preventDefault = () => {

                                         };
                                         props.onChange(evt);
                                         setAnchorEl(null);
                                     }
                                     setTimeout(() => {
                                         textRef.current.focus();
                                     },10);
                                 }}
                                 onChangePath={(path) => {
                                     SetAssetFieldStorage({...GetAssetFieldStorage(),cdnPath: path})
                                     setCurrentCDNPath(path);
                                 }}/>
                </>}
                { tab === "pdam" && <>
                    <DAMFilter
                        mode={"select"}
                        orgCode={siteCode}
                        currentPath={currentPortalPath}
                        defaultFilter={searchFilter}
                        onSearch={setSearchFilter}
                        onFocus={handleCancelAutoClose}
                        onBlur={handleOnBlur}
                    />
                    <PDAMFolders siteCode={siteCode}
                                 portalEnvironment={GetPortalDAMEnvironment()}
                                 currentPath={currentPortalPath}
                                 onChangePath={(path) => {
                                     SetAssetFieldStorage({...GetAssetFieldStorage(),path: path})
                                     setCurrentPortalPath(path);

                                 }}
                                 canSwitchEnvironment={false}
                    />
                    <PDAMFileList siteCode={siteCode}
                                  portalEnvironment={GetPortalDAMEnvironment()}
                                  currentPath={currentPortalPath}
                                  searchFilter={searchFilter}
                                  mode={"select"}
                                  onSelectFile={(file) => {
                                     // eslint-disable-next-line
                                     textRef.current.value = "${WEBSITE_URL}/images" + file
                                     if(props.onChange) {
                                         const evt:any = {target: {
                                                 name: "",
                                                 value:  textRef.current.value,
                                                 getAttribute: (name:string) => {
                                                     return textRef.current.getAttribute(name);
                                                 }
                                             },
                                             currentTarget: {
                                                 name: "",
                                                 value:  textRef.current.value,
                                                 getAttribute: (name:string) => {
                                                     return textRef.current.getAttribute(name);
                                                 }
                                             },
                                         }
                                         evt.stopPropagation = () => {

                                         };
                                         evt.preventDefault = () => {

                                         };

                                         props.onChange(evt);
                                         setAnchorEl(null);
                                     }
                                     setTimeout(() => {
                                         textRef.current.focus();
                                     },10);
                                 }}
                                 onChangePath={(path) => {
                                     SetAssetFieldStorage({...GetAssetFieldStorage(),path: path})
                                     setCurrentPortalPath(path)
                                 }}/>
                </>}
            </div>
        </Popover>
    }
    </>
}
