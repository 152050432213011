import styles from "./DAM.module.scss"
import {
    Grid, Icon, IconButton, Stack, TextField, ToggleButton, ToggleButtonGroup, Tooltip,
} from "@mui/material";
import OutlinedIcon from "../icon/OutlinedIcon";
import React, {useRef, useState} from "react";



export interface DAMSearchFilter {
    query: string,
    recursive: boolean,
    byName: boolean
}

export function DAMFilter(props: {
    mode: "select" | "edit"
    orgCode: string
    currentPath: string
    defaultFilter: DAMSearchFilter,
    onSearch: (filter:DAMSearchFilter) => void
    onFocus?: (event:React.FocusEvent) => void
    onBlur?: (event:React.FocusEvent) => void
}) {
    const [query , setQuery] = useState<string>(props.defaultFilter.query);
    const [filterType] = useState(props.defaultFilter.byName ? "by_name" : "by_product");
    const [searchDepth, setSearchDepth] = useState(props.defaultFilter.recursive ? "sub_folders" : "current_folder");
    const textRef = useRef<HTMLInputElement>(null);

    const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        setQuery(event.currentTarget.value);
    }

    const handleClickSearch = () => {
        props.onSearch({query,recursive: searchDepth === "sub_folders",byName: filterType === "by_name"})
        textRef.current?.focus();
    }

    const handleResetSearch = () => {
        setQuery("");
        textRef.current?.focus();
    }

    // const handleSearchFile = (event: React.MouseEvent) => {
    //     setFilterType("by_name");
    //     textRef.current?.focus();
    //     event.preventDefault();
    //     event.stopPropagation();
    // }
    //
    // const handleSearchProduct = (event: React.MouseEvent) => {
    //     setFilterType("by_product");
    //     textRef.current?.focus();
    //     event.preventDefault();
    //     event.stopPropagation();
    // }

    const handleSearchCurrentFolder = (event: React.MouseEvent) => {
        setSearchDepth("current_folder");
        props.onSearch({query, recursive: false, byName: filterType === "by_name"})
        textRef.current?.focus();
        event.preventDefault();
        event.stopPropagation();
    }

    const handleSearchSubFolder = (event: React.MouseEvent) => {
        setSearchDepth("sub_folders");
        props.onSearch({query, recursive: true, byName: filterType === "by_name"})
        textRef.current?.focus();
        event.preventDefault();
        event.stopPropagation();
    }

    return <div className={styles.DAMPanel}>
        <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
                <Stack direction="row" spacing={2}>
                    <ToggleButtonGroup exclusive size="small" color={"primary"} value={searchDepth}>
                        <ToggleButton onClick={handleSearchCurrentFolder} value={"current_folder"}><Tooltip title={"Current folder : " + props.currentPath}><span style={{lineHeight: "0px"}}><OutlinedIcon>folder</OutlinedIcon></span></Tooltip></ToggleButton>
                        <ToggleButton onClick={handleSearchSubFolder} value={"sub_folders"}><Tooltip title={"All subfolders : " + props.currentPath}><span style={{lineHeight: "0px"}}><OutlinedIcon>account_tree</OutlinedIcon></span></Tooltip></ToggleButton>
                    </ToggleButtonGroup>
                    {/*<ToggleButtonGroup exclusive size="small" color={"primary"} value={filterType}>*/}
                    {/*    <ToggleButton onClick={handleSearchFile} value={"by_name"}><OutlinedIcon>image</OutlinedIcon></ToggleButton>*/}
                    {/*    <ToggleButton onClick={handleSearchProduct} value={"by_product"}><OutlinedIcon>inventory_2</OutlinedIcon></ToggleButton>*/}
                    {/*</ToggleButtonGroup>*/}
                </Stack>
            </Grid>
            <Grid item md={12} xs={12}>
            <TextField autoFocus={true}
                       variant={"outlined"}
                       value={query}
                       size={props.mode === "select" ? "small" : "medium"}
                       onFocus={props.onFocus}
                       onBlur={props.onBlur}
                       sx={{fontSize: "0.5rem"}}
                       onChange={handleChange}
                       label={filterType === "by_name" ? "Search by image name" : "Search by product id"}
                       fullWidth
                       inputRef={textRef}
                       InputProps={
                           {
                               endAdornment: <><IconButton tabIndex={-1} onClick={handleClickSearch}><Icon>search</Icon></IconButton>
                                   <IconButton tabIndex={-1} onClick={handleResetSearch}><Icon>clear</Icon></IconButton></>
                           }
                       }
            />
            </Grid>
        </Grid>
    </div>
}