import {useParams} from "react-router-dom";
import {useOrganization} from "../../hook/useOrganization";
import {useEffect, useState} from "react";
import HttpClient from "../../datafetcher/HttpClient";
import ThemeEditor from "./ThemeEditor";
import {useHistory} from "react-router";


export default function Theme() {
    const history = useHistory();
    const params = useParams<{tname: string, tid: string}>();
    const orgCmdProp = useOrganization();
    const [hasData, setHasData] = useState<boolean>(false);
    const [themeInfo, setThemeInfo] = useState<any>({});
    const [themeProps, setThemeProps] = useState<{[key:string]: string}>({});
    // const loadData = async (): Promise<any> => {
    //     try {
    //         let response = await HttpClient.get("/api/v0/jcr/org/" + orgCmdProp!.currentOrg!.orgCode + "/theme/" + params.tid);
    //         let data = response.data.response;
    //         setThemeInfo(data);
    //         setThemeProps(JSON.parse(data.themeData));
    //         // if (data.themeData === null) return {};
    //         // else return JSON.parse(data.themeData);
    //     } catch (e) {
    //         history.replace('/themes')
    //     }
    // }

    const saveData = async (data :any ) => {
        let themeContent = JSON.stringify(data);
        themeInfo.themeData = themeContent;
        if(orgCmdProp === undefined || orgCmdProp?.orgCmds === null) return;
        await HttpClient.post("/api/v0/jcr/org/" + orgCmdProp!.currentOrg!.orgCode + "/full/themes",themeInfo).then((response) => {
            setThemeInfo({...themeInfo});
        })
    }

    useEffect(() => {
        if(orgCmdProp === undefined || orgCmdProp?.orgCmds === null || orgCmdProp?.currentOrg === null|| orgCmdProp?.orgCmds.length === 0 || params.tid === undefined) return;
        if(hasData) {
            //history.replace("/themes");
        } else {
            HttpClient.get("/api/v0/jcr/org/" + orgCmdProp!.currentOrg!.orgCode + "/theme/" + params.tid).then(response => {
                let data = response.data.response;
                setThemeInfo(data);
                setThemeProps(JSON.parse(data.themeData));
                setHasData(true);
            }).catch(() => {
                history.replace('/themes')
            });

            // loadData().then(() => {
            //     setHasData(true);
            // })
        }

    },[orgCmdProp,hasData,history,params.tid]);


    return <>{hasData &&  <ThemeEditor  id={params.tid}
                                        name={themeInfo.themeName}
                                        // loadData={loadData}
                                        themePros={themeProps}
                                        saveData={saveData}
                                        path={"/themes"}
                                        type={"Themes"}/>}</>
}