import {Checkbox, FormControlLabel, Grid, TextField} from "@mui/material";
import React, {useState} from "react";
import styles from "./ThreeColumns.module.scss";

export default function ThreeColumnSetting(props: { widgetData: any, saveWidgetState: () => void }) {
    let widgetProps = props.widgetData.sectionProps;


    const handleChange = (value: any, key: string) => {
        widgetProps["content"][key] = value;
        props.saveWidgetState();
    }

    if(!(widgetProps['content'])) {
        widgetProps['content'] = {
            'column1-width': "30%",
            'column2-width': "1fr",
            'column3-width': "1fr",
            gap: "0px",
        }
    }

    if(!widgetProps['content']['gap']) {
        widgetProps['content']['gap'] = "0.0px";
    }


    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={"column1-width"}
                       defaultValue={widgetProps['content']['column1-width']}
                       onChange={(event) => {
                           handleChange(event.target.value, "column1-width")
                       }}/>
        </Grid>
        <Grid item xs={12}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={"column2-width"}
                       defaultValue={widgetProps['content']['column2-width']}
                       onChange={(event) => {
                           handleChange(event.target.value, "column2-width")
                       }}/>
        </Grid>
        <Grid item xs={12}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={"column3-width"}
                       defaultValue={widgetProps['content']['column3-width']}
                       onChange={(event) => {
                           handleChange(event.target.value, "column3-width")
                       }}/>
        </Grid>
        <Grid item xs={12}>
            <TextField size={"small"}
                       fullWidth={true}
                       label={"gap"}
                       defaultValue={widgetProps['content']['gap']}
                       onChange={(event) => {
                           handleChange(event.target.value, "gap")
                           event.preventDefault();
                           props.saveWidgetState();
                       }}/>

        </Grid>
    </Grid>
}