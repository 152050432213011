import {Grid, GridSize, MenuItem, TextField} from "@mui/material";
import React, {useState} from "react";
import SizeInput from "../../../../ui/setting/input/SizeInput";

function GetValue(value : string):string {
    if(value === "var(--bt-global-frame-height)") {
        return "full";
    } else if(value === "auto" || value === null || value === undefined || value === "") {
        return "auto"
    } else {
        return "custom"
    }
}

export default function SectionHeightSetting(props: {
    defaultValue: string,
    onChange: (value: string) => void
}) {
    const [type, setType] = useState<string>(GetValue(props.defaultValue));
    const [value, setValue] = useState<string>(props.defaultValue);

    const labelWidth:GridSize = 5;
    const objectWidth:GridSize = 7;
    return <>
        <Grid item xs={labelWidth}>
            Height
        </Grid>
        <Grid item xs={objectWidth}>
            <TextField
                size={"small"}
                fullWidth
                InputLabelProps={{shrink: true}}
                inputProps={{sx: {paddingBlock: "5px"}}}
                value={type}
                onChange={(event) => {
                    setType(event.target.value);
                    if(event.target.value === "full") {
                        setValue("100%");
                        props.onChange("var(--bt-global-frame-height)");
                    } else if(event.target.value === "auto") {
                        setValue("auto");
                        props.onChange("auto");
                    } else {
                        setValue("")
                        props.onChange(value);
                    }
                    event.stopPropagation();
                    event.preventDefault();
                }} label={""}
                select
            >
                <MenuItem value={"auto"}>Auto</MenuItem>
                <MenuItem value={"full"}>Full Height</MenuItem>
                <MenuItem value={"custom"}>Custom</MenuItem>
            </TextField>
        </Grid>
        { type === "custom" && <>
            <Grid item xs={labelWidth}>
            </Grid>
            <Grid item xs={objectWidth}>
                <SizeInput
                    fullWidth
                    // InputLabelProps={{shrink: true}}
                    // inputProps={{sx: {paddingBlock: "5px"}}}
                    value={value}
                    onChange={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                    }} label={""}
                    auto={false}
                    onChangeSize={(value: string) => {
                        props.onChange(value);
                        setValue(value);
                    }}/>
            </Grid>
        </>}
    </>
}