import React, {useCallback, useEffect, useRef, useState} from 'react';
import styles from "./CMSRoutes.module.scss";
import commonStyle from "../../config/Common.module.scss";
import StyledTable from "../table/StyledTable";
import {CMSRouteDataFetcher} from "../../datafetcher/CMSRouteDataFetcher";
import {useHistory} from "react-router";
import {useParams} from "react-router-dom";
import {InputModel, InputOption} from "../../model/InputModel";
import HttpClient from "../../datafetcher/HttpClient";
import {nanoid} from "nanoid";
import InputDialog from "../dialog/InputDialog";
import {useOrganization} from "../../hook/useOrganization";
import PageSummary from "../pagesummary/PageSummary";
import IconLinkButton from "../button/IconLinkButton";
import ReverseSolidLinkSmallButton from '../button/ReverseSolidLinkSmallButton';
import OutlinedIcon from "../icon/OutlinedIcon";
import {CurrentISODate} from "../../util/DateUtil";
import {jcr} from "../../datafetcher/JCRDataFetcher";

export default function CMSRoutes() {

    const history = useHistory();
    const query = new URLSearchParams(history.location.search);
    const params: any = useParams();
    const [newOpen, setNewOpen] = useState(false);
    const [updateData, setUpdateData] = useState<any>({});
    const [routeId, setRouteId] = useState("");
    const [formKey , setFormKey] = useState("");
    const [dataFetcher, setDataFetcher] = useState<CMSRouteDataFetcher|null>(null);
    const [deleteList,setDeleteList] = useState<string[]>([]);
    const [storefronts, setStoreFronts] = useState<InputOption[]>();
    const [roles,setRoles] = useState<InputOption[]>();
    const [views, setViews] = useState<InputOption[]>();
    const  orgCmdProp = useOrganization();
    const inputFile = useRef<HTMLInputElement>(null);
    const [envs,setEnvs] = useState<InputOption[]>();

    const handleCloseNewDialog = () => {
        setNewOpen(false);
    }

    const handleOpenNewDialog = () => {
        setNewOpen(true);
    }

    const handleUploadDialog = () => {
        if(inputFile !== null && inputFile.current !== null) {
            inputFile.current.click();
        }
    }

    const doUploadFile = () => {
        if(inputFile && inputFile.current) {
            if(inputFile.current.files) {
                jcr.route.upload(inputFile.current.files).then((response) => {
                    if(!(orgCmdProp && orgCmdProp.currentOrg !== null && orgCmdProp.orgDetail !== null)) return;
                    setDataFetcher(new CMSRouteDataFetcher(orgCmdProp.currentOrg));
                    setFormKey(nanoid());
                })
            }
        }
    }

    const handleCloseUpdateDialog = () => {
        setUpdateData({});
        setRouteId("");
        history.push({
            pathname: '/routes',
            search: history.location.search});
    }

    const handleUpdateDialog = (values: InputModel[]) => {
        let data: any = updateData;
        values.forEach(object => {
            data[object.id] = object.defaultValue;
        })
        data['createdAt'] = updateData.createdAt;
        data['modifiedAt'] = new Date().getTime();

        if(data['accessList'] === null || data['accessList'].length === 0) {
            data['accessList'] = ["PUBLIC"];
        }
        HttpClient.post("/api/v0/jcr/org/" + orgCmdProp?.currentOrg!.orgCode + "/full/routes", data).then((response) => {
            dataFetcher!.load().then((xxxx) => {
                resetScreen();

            });
        }).catch((e) => {
        })
    }

    const handleNewDialog = (values: InputModel[]) => {
        let data: any = {};
        values.forEach(object => {
            data[object.id] = object.defaultValue;
        })
        if(data['accessList'] === undefined || data['accessList'] === null || data['accessList'].length === 0) {
            data['accessList'] = ["PUBLIC"];
        }
        data['createdAt'] = new Date().getTime();
        data['modifiedAt'] = new Date().getTime();
        HttpClient.post("/api/v0/jcr/org/" + orgCmdProp?.currentOrg!.orgCode + "/full/routes", data).then((response) => {
            resetScreen();
            history.push({
                pathname: history.location.pathname + "/" + response.data.response.id ,
                search: history.location.search
            })
        }).catch((e) => {

        })
        setNewOpen(false);
    }

    const handleDelete = () => {

        if(window.confirm("Are you sure to remove?")) {
            HttpClient.delete("/api/v0/jcr/org/" + orgCmdProp?.currentOrg!.orgCode + "/route/" + deleteList.join(",")).then((response) => {
                resetScreen();
                setDeleteList([]);
            }).catch((e) => {
                setDeleteList([]);
            })
        }
    }

    const handleDownloadList = () => {
        HttpClient.download(`Routes-${CurrentISODate()}.gz`,
            "/api/v0/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/download/routes/" + deleteList.join(","));
        setDeleteList([]);
        setFormKey(nanoid());
    }

    const handleAction = (tag:string, tagObject: any) => {
        if(tag === "DUPLICATE") {
            HttpClient.get( "/api/v0/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/clone/routes/" + tagObject.id)
                .then((response) => {
                    let id = response.data.response;
                    history.push({
                        pathname: `${history.location.pathname}/${id}`,
                        search: history.location.search
                    })
                    if(!(orgCmdProp && orgCmdProp.currentOrg !== null && orgCmdProp.orgDetail !== null)) return;
                    setDataFetcher(new CMSRouteDataFetcher(orgCmdProp.currentOrg));
                    setFormKey(nanoid());
                })
        } else if(tag === "DOWNLOAD") {
            HttpClient.download(
                `Route-${tagObject.routeName}-${tagObject.id}.gz`,
                 "/api/v0/jcr/orgs/" + orgCmdProp?.currentOrg!.orgCode + "/download/routes/" +  tagObject.id,
                    );
        }
    }

    if (params.nid && routeId !== params.nid) {
        setTimeout(() => {
            setRouteId(params.nid);
        }, 100);
    }

    const resetScreen = useCallback(() => {
        history.replace({
            pathname: '/routes',
            search: history.location.search
        });
        setRouteId("");
        setUpdateData({});
        setFormKey(nanoid());
    },[history]);


    useEffect(() => {
        if(routeId !== "" && orgCmdProp && orgCmdProp.currentOrg !== null) {
            HttpClient.get("/api/v0/jcr/org/" + orgCmdProp?.currentOrg!.orgCode + "/route/" + routeId).then((response) => {
                if(response.data.response !== null) {
                    setUpdateData(response.data.response);
                } else {
                    resetScreen();
                }

            }).catch((e) => {
                resetScreen();
            })
        }

    },[routeId,orgCmdProp,resetScreen])

    useEffect(() => {
        if(!(orgCmdProp && orgCmdProp.currentOrg !== null && orgCmdProp.orgDetail !== null)) return;
        HttpClient.get("/api/v0/jcr/org/" + orgCmdProp?.currentOrg!.orgCode + "/lite/storefronts").then((response) => {
            if(response.data) {
                let newSfOptions: InputOption[] = [];
                response.data.response.sort( (a:any, b:any) => {
                    let value = a.sfName.toLowerCase().localeCompare(b.sfName.toLowerCase())
                    if(value === 0) {
                        value = a.sfName.localeCompare(b.sfName)
                        if(value === 0) {
                            value = a.id.localeCompare(b.id);
                        }
                    }
                    return value;
                }).forEach((object:any) => {
                    newSfOptions.push({value: object.id , label: object.sfName});
                })
                setStoreFronts(newSfOptions);
            }
        });
        HttpClient.get("/api/v0/jcr/org/" + orgCmdProp?.currentOrg!.orgCode + "/lite/roles").then((response) => {
            if(response.data) {
                let newRoleOptions: InputOption[] = [];
                newRoleOptions.push({value: "PUBLIC", label: "Everyone"});
                if(response.data.response) {
                    response.data.response.sort((a: any, b: any) => {
                        let value = a.roleName.toLowerCase().localeCompare(b.roleName.toLowerCase())
                        if (value === 0) {
                            value = a.roleName.localeCompare(b.roleName)
                            if (value === 0) {
                                value = a.id.localeCompare(b.id);
                            }
                        }
                        return value;
                    }).forEach((object: any) => {
                        newRoleOptions.push({value: object.id, label: object.roleName});
                    })
                }
                setRoles(newRoleOptions);
            }
        });
        HttpClient.get("/api/v0/jcr/org/" + orgCmdProp?.currentOrg!.orgCode + "/lite/views").then((response) => {
            if(response.data) {
                let newViewOptions: InputOption[] = [];
                response.data.response.sort( (a:any, b:any) => {
                    let value = a.viewName.toLowerCase().localeCompare(b.viewName.toLowerCase())
                    if(value === 0) {
                        value = a.viewName.localeCompare(b.viewName)
                        if(value === 0) {
                            value = a.id.localeCompare(b.id);
                        }
                    }
                    return value;
                }).forEach((object:any, index:any) => {
                    newViewOptions.push({value: object.id , label: object.viewName, valueindex: index});
                })
                setViews(newViewOptions);
            }
        });
        HttpClient.get("/api/v0/jcr/org/" + orgCmdProp?.currentOrg!.orgCode + "/"+orgCmdProp?.currentOrg!.siteCode + "/envs").then((response) => {
            if(response.data) {
                let newenvs: InputOption[] = [];
                const data = response.data.response;
                for (const [key, value] of Object.entries(data)) {
                    if (typeof value === "string") {
                        const str: string = value; // No error
                        newenvs.push({value: key , label: value});
                    }
                }
                setEnvs(newenvs);
            }
        });
        // let newEnvs = [{ label: "Production" , value : "prod"},{ label: "Development" , value : "dev"},{ label: "Staging" , value : "staging"}]
        // setEnvs(newEnvs);
        setDataFetcher(new CMSRouteDataFetcher(orgCmdProp.currentOrg));
        setFormKey(nanoid());
    }, [orgCmdProp]);

    const newButton = <IconLinkButton tooltip={"Add New Route"} key={"newRouteButton"} id="newRouteButton" data-action="createNewRoute" variant={"contained"} onClick={handleOpenNewDialog}>
        <OutlinedIcon className={styles.Icon} >add_circle_outline</OutlinedIcon>
    </IconLinkButton>;

    const uploadButton = <IconLinkButton tooltip={"Upload File"} key="uploadRouteButton" id="uploadRouteButton" data-action="uploadNewRoute" variant={"contained"} onClick={handleUploadDialog}>
        <OutlinedIcon className={styles.Icon} >cloud_upload</OutlinedIcon>
    </IconLinkButton>;

    const deleteButton = <ReverseSolidLinkSmallButton id="deleteRouteButton" data-action="deleteRoutes" variant={"contained"} onClick={handleDelete}>
        <OutlinedIcon className={styles.Icon} >delete</OutlinedIcon>
        Remove ({deleteList.length})
    </ReverseSolidLinkSmallButton>;
    const downloadButton = <ReverseSolidLinkSmallButton id="downloadRouteButton" data-action="downloadRoutes" variant={"contained"} onClick={handleDownloadList}>
        <OutlinedIcon className={styles.Icon} >download</OutlinedIcon>Download ({deleteList.length})
    </ReverseSolidLinkSmallButton>;

    return <React.Fragment>

        <div className={commonStyle.ContentContainer + " " + commonStyle.ContentContainerOverflowX}>
            <PageSummary type={"routes"}/>
            {   newOpen && roles && storefronts && views &&
                <InputDialog
                    id={"route"}
                    title={"Add New Route"}
                    handleClose={handleCloseNewDialog}
                    handleSave={handleNewDialog}
                    open={newOpen}
                    inserted={true}
                    fixHeight={false}
                    fields={[
                        {id: "routeName", label: "Route Name", defaultValue: "", required: true , editable: true},
                        // {id: "routePattern", label: "Route Path", defaultValue: "", required: true , editable: true},
                        // {id: "routeTitle", label: "Title", defaultValue: "", required: false , editable: true},
                        // {id: "routeSEO", type: "keyvalue", label: "SEO", defaultValue: "", required: false , editable: true},
                        // {id: "routeParameters", type: "keyvalue", label: "Parameters", defaultValue: "", required: false , editable: true},
                        // {id: "accessList", type: "multi", label: "User Policies", defaultValue: ["PUBLIC"], required: false , editable: true, options: roles},
                        // {id: "storeFrontIds", type: "multi", label: "Sites", defaultValue: [], required: false , editable: true, options: storefronts},
                        // {id: "viewIds", type: "multi", label: "Pages", defaultValue: [], required: false , editable: true, options: views},
                    ]}
                    contentText={null}/>
            }
            {routeId !== "" && updateData.id && roles && storefronts && views &&
                <InputDialog
                    id={"route"}
                    title={`Update Route (${updateData.routeName})`}
                    handleClose={handleCloseUpdateDialog}
                    handleSave={handleUpdateDialog}
                    open={true}
                    inserted={false}
                    fields={[
                        {id: "routeName", group: "info", label: "Route Name", defaultValue: updateData.routeName, required: true , editable: true},
                        {id: "routePattern", group: "info", label: "Page ID", defaultValue: updateData.routePattern, required: true , editable: true},
                        {id: "routeTitle", group: "info", label: "Title", defaultValue: updateData.routeTitle, required: false , editable: true},
                        {id: "routeDesc", group: "info", label: "Page Description", type:"text" , defaultValue: updateData.routeDesc, required: false , editable: true},
                        {id: "routeKeywords", group: "info", label: "Keywords", defaultValue: updateData.routeKeywords, required: false , editable: true},
                        {id: "active", group: "info", type: "yesno", label: "Active", defaultValue: updateData.active, required: false , editable: true},
                        {id: "allowWrap", group: "info", type: "yesno", label: "Boxed", defaultValue: updateData.allowWrap, required: false , editable: true},
                        {id: "showBreadcrumb", group: "info", type: "yesno", label: "Show Breadcrumb", defaultValue: updateData.showBreadcrumb, required: false , editable: true},
                        {id: "showTitle", group: "info", type: "yesno", label: "Show Title", defaultValue: updateData.showTitle, required: false , editable: true},
                        {id: "showDescription", group: "info", type: "yesno", label: "Show Description", defaultValue: updateData.showDescription, required: false , editable: true},

                        {id: "indexedSEO", group: "seo", type: "yesno", label: "SEO Index", defaultValue: updateData.indexedSEO, required: false , editable: true},
                        {id: "sitePriority", group: "seo", type: "select", label: "Site Priority",
                            options: [
                                {label: "0.1", value: "0.1"},
                                {label: "0.2", value: "0.2"},
                                {label: "0.3", value: "0.3"},
                                {label: "0.4", value: "0.4"},
                                {label: "0.5", value: "0.5"},
                                {label: "0.6", value: "0.6"},
                                {label: "0.7", value: "0.7"},
                                {label: "0.8", value: "0.8"},
                                {label: "0.9", value: "0.9"},
                                {label: "1.0", value: "1.0"}
                            ],
                            defaultValue: updateData.sitePriority, required: false , editable: true},
                        {id: "siteChangeFrequency", group: "seo", type: "select", label: "Change Frequency",
                            options: [
                                {label: "always", value: "always"},
                                {label: "hourly", value: "hourly"},
                                {label: "daily", value: "daily"},
                                {label: "weekly", value: "weekly"},
                                {label: "monthly", value: "monthly"},
                                {label: "never", value: "never"},
                                {label: "none", value: "none"},
                            ],
                            defaultValue: updateData.siteChangeFrequency, required: false , editable: true},
                        {id: "canonicalNameOverride", group: "seo", label: "Canonical Name Override", defaultValue: updateData.canonicalNameOverride, required: false , editable: true},
                        {id: "routeSEO", group: "seo", type: "keyvalue", label: "", defaultValue: updateData.routeSEO, required: false , editable: true},
                        {id: "routeParameters", group: "variables", type: "keyvalue", label: "", defaultValue: updateData.routeParameters, required: false , editable: true},
                        // {id: "portalQuery", type: "graphql", label: "Portal Query", defaultValue: updateData.portalQuery, required: false , editable: true},
                        // {id: "cmsQuery", type: "graphql", label: "CMS Query", defaultValue: updateData.cmsQuery, required: false , editable: true},
                        {id: "accessList", group: "policy",type: "multi", label: "", defaultValue: updateData.accessList === null ? ["PUBLIC"] : updateData.accessList, required: false , editable: true, options: roles},
                        {id: "storeFrontIds", group: "site",type: "multi", label: "", defaultValue: updateData.storeFrontIds === null ? [] : updateData.storeFrontIds, required: false , editable: true, options: storefronts},
                        {id: "viewIds", group:"page",type: "multi_ordered", label: "", defaultValue: updateData.viewIds === null ? [] : updateData.viewIds, required: false , editable: true, options: views},
                        {id: "routeEnvs", group:"env",type: "multi", label: "", defaultValue: updateData.routeEnvs === null ? [] : updateData.routeEnvs, required: false , editable: true, options: envs},

                    ]}
                    groups={[
                        {id: "info", name: "General"},
                        {id: "env", name: "Environments"},
                        // {id: "site", name: "Sites"},
                        {id: "page", name: "Page Contents"},
                        // {id: "policy", name: "User Policies"},
                        // {id: "variables", name: "Variables"},
                        // {id: "seo", name: "SEO"},
                    ]}
                    contentText={null}/>
            }
            <StyledTable key={formKey}
                         hasCheckBox={true}
                         dataFetcher={dataFetcher}
                         rowsPerPage={20}

                         title={"Routes"}
                         actionComponents={[uploadButton,newButton]}
                         deleteButton={deleteButton}
                         downloadButton={downloadButton}
                         handleAction={handleAction}
                         handleDeleteLists={(ids) => setDeleteList(ids)}
                         page={parseInt((query.get("page") === null ? "0" : query.get("page")!))}
                         rowsPerPageOptions={[5,10,20,40,60,{label: "All" , value: -1}]}/>
            <input key={`upload_${formKey}`} type='file' id='upload-content'
                   accept=".gzip,.gz"
                   onChange={() => doUploadFile()}
                   ref={inputFile} style={{display: 'none'}}/>
        </div>

    </React.Fragment>

}
