import VariableContext from "./VariableContext";
import {GetPortalHostName} from "../ui/popover/PortalEnvironmentPopover";

export function VariableProvider(props: {
                                    children: any,
                                    websiteURL: string,
                                    cdnURL: string,
                                    siteCode: string,
                                    portalThemeName: string
} ) {
    return <VariableContext.Provider value={
        {
            websiteURL: props.websiteURL,
            cndURL: props.cdnURL,
            siteCode: props.siteCode,
            portalThemeName: props.portalThemeName,
            updateVariables: (src : string) => {
                if(src) {
                    if(src.indexOf("${CDN_URL}") >= 0) {
                        src = src.replaceAll("${CDN_URL}",props.cdnURL);
                    }
                    if(src.indexOf("${WEBSITE_URL}") >= 0) {
                        src = src.replaceAll("${WEBSITE_URL}", `https://${GetPortalHostName(props.siteCode)}`);//props.websiteURL);
                    }
                }
                return src;
            }
        }
    }>
            {props.children}
        </VariableContext.Provider>
}