import {TextFieldProps} from "@mui/material/TextField/TextField";
import styles from './Input.module.scss';
import {Autocomplete, TextField} from "@mui/material";
import {InputOption} from "../../../model/InputModel";


export default function SuggestInput(props: TextFieldProps & {
                                options:InputOption[]
                                onChange: (value: any) => void
                            }) {
    return <Autocomplete
                        size={"small"}
                        autoHighlight
                        fullWidth={true}
                        value={props.options.find(x => x.value === props.value)}
                        isOptionEqualToValue={(option, value) => option.value === props.value}
                        onChange={(event,selected:any) => {
                               if(props.onChange) {
                                   if(selected != null) {
                                       props.onChange(selected.value);
                                   }
                               }
                            }
                        }
                        renderInput={(customProps) => {
                            return <TextField {...customProps}
                                       {...props}
                                       type={"text"}
                                       placeholder=""
                                       className={styles.Input}
                                       size={"small"}
                                       InputLabelProps={{shrink: true}}
                                       fullWidth={true}
                            />
                         }}
                        options={props.options}/>
}